import "./SharedComponents/sharedComponents.css"
import {Component} from 'react';

class InLineTextCard extends Component {
   
    constructor(props) {
        super(props);
        this.totalStringLength = 0;
        this.maxLength = 90;
    }

           
    render() {
       this.printString1 = this.props.textString.text;
    return <>
    
        <span >
            {this.props.textString.clickable == true ? 
                <span>
                    <span>&nbsp;</span>
                    <span
                        className="font-italic text-primary underline-this-text clickable-finger"
                        onClick={() => this.props.onClick(this.props.textString.text)}>
                            {this.props.textString.text}
                      
                    </span>    
                    <span>&nbsp;</span>
                </span>
            :
                <span>
                    {this.props.textString.text}
                </span>
            }
        </span>
       
    </>
    }
}


export default InLineTextCard;