const newEventTracker =
{
    "player": "studentX",
    "gameID": "645db06ee12fb9c27c8b3f63",
    "date": "2023/05/17",
    "time": "5:03:34 PM",
    "process": "ChangeCar",
    "events": [
      {
        "eventCount": 2000,
        "event": "Buy Different Car",
        "gameDate": "2030-07",
        "response": {
          "category": "cash",
          "on": "cash",
          "type": "flat",
          "duration": 1,
          "value": -2847.699999999997,
          "moodPoints": 0
        },
        "afterCash": "94490.49",
        "afterSalary": "7621.59",
        "afterMoodPoints": 18580
      }
    ],
  }

export default newEventTracker;