const PrioritiesChallenge1Dataset = {
   
    items: {
        "item-1": { id: "item-1", content: "Your couch is very old and looks awful. " +
                 "You need to buy a new one", 
            answer: "column-1", order: 4 },
        "item-2": { id: "item-2", content: "You fell off your bike and your arm hurts " +
                 "a lot and is very swollen.  You think you should go somewhere and get x-rays.", 
            answer: "column-1", order: 1 },
        "item-3": { id: "item-3", content:  "You are going to start a new job next month and " + 
                "you need to get some new clothes that will be appropriate for the job.",
            answer: "column-1", order: 3 },
        "item-4": { id: "item-4", content: "You have been thinking it would be fun to go " + 
                "fishing but you need to purchase fishing gear and a fishing license.",  
            answer: "column-1", order: 5 },
        "item-5": { id: "item-5", content: "You need to go grocery shopping to get food " + 
                "for the next couple of weeks", 
            answer: "column-1", order: 2 },
    },

    columns: {
        "column-1": { id: "column-1", title: "Items to Pay For or Purchase", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5"] },
    },
    columnOrder: ["column-1"]
}

export default PrioritiesChallenge1Dataset