const SavingsSetupIntro =[
     "One way to set money aside is to move it from a checking account 'Cash' to a 401K account. " +
     "This has advantages because the money in a 401K earns interest.  In this game, all of the money " +
     "that you deposit in your 401K account will be invested in treasury bonds.  While the interest " +
     "earned on bonds is not usually a large amount, it is a relatively safe investment... and... every " +
     "little bit of extra money helps and builds up over time. ",
                      
    "It is important to know that if your cash amount is insufficient to pay for bills encountered in a " +
    "given month, your cash will become a negative amount which will put your game at risk.  " +
    "If this occurs, you can return to this process to transfer money from the 401K account back " +
    "to your cash (checking) account.  This transfer will cost ||overageAmt|| of the amount transferred " +
    "which can be very expensive. Keep this in mind as you setup your savings policy.  Leave yourself " +
    "enough in your cash account to cover unexpected expenses."
                      
]

export default SavingsSetupIntro;