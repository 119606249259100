const updateExtraCreditInfo = {
    extraCreditTable:  [
        {
        "category": "SavingMoney",
        "score": 100,
        "assetType": "salary",
        "amount": "1000.00",
        "level": "2"
      },
      {
        "category": "Priorities",
        "score": 100,
        "assetType": "cash",
        "amount": "2000.00",
        "level": "1"
      },
      {
        "category": "TestChallenge",
        "score": 999,
        "assetType": "cash",
        "amount": "1000.00",
        "level": "1"
      }
    ]
    
}

export default updateExtraCreditInfo;