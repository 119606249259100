const updateAccountData = {
        "emailAddress": "testAccountUpdate@gmail.com",
        "accountNbr": "88888888",
        "rqstdUsersArr": [
          {
            "role": "teacher",
            "dateRqstd": "2023/10/16",
            "nbrUsersRqstd": "1"
          },
          {
            "role": "testing",
            "dateRqstd": "2023/10/16",
            "nbrUsersRqstd": "10"
          }
        ],
        "authCodeArr": [9,9,9,9],
}

export default updateAccountData;