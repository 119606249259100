import { createTableOfConditionEvents } from "./create_table_of_condition_events";

export const rptEventsWithConditions = (conditionsData, eventsData ) => {
    let rptArr = [];
    
    let primaryConditionArr = [];
    //  get all conditions that are based on gameData (not triggered by events)
    for (let i=0; i<conditionsData.length; i++){
        if (conditionsData[i].primaryFieldName !== "eventID"){
            primaryConditionArr.push(conditionsData[i].condName);
        }
    }
  
    // create a table of all events and their conditions - events will be on table multiple times
    // when they have more than 1 condition or when there is more than one trigger event in the response
    let allEventConditionArr = [];
    allEventConditionArr = createTableOfConditionEvents(eventsData, primaryConditionArr);
    allEventConditionArr.sort((a, b) => (a.title + a.btnText > b.title + b.btnText) ? 1 : -1)
   
    var rptLine = "";
    for (let i=0; i<allEventConditionArr.length; i++){
        if (i === 0){
            rptLine = {title: "Event Title", connector: "Condition Connector", conditions: "Select Condition",
                skipConditions: "Skip Condition", btnText: "BtnText", triggerCond: "Trigger Condition",
                deleteCond: "Delete Condition", deleteClass: "Delete Class", primaryCond: "Primary Condition?"}
            rptArr.push(rptLine);
        }
        rptLine = {title: allEventConditionArr[i].title, connector: allEventConditionArr[i].connector,
              conditions: allEventConditionArr[i].condition, skipConditions: allEventConditionArr[i].skipArr,
              btnText: allEventConditionArr[i].btnText, triggerCond: allEventConditionArr[i].triggerCondition,
              deleteCond: allEventConditionArr[i].deleteCondition, deleteClass: allEventConditionArr[i].deleteClass,
              primaryCond: allEventConditionArr[i].primaryEvent};
        rptArr.push(rptLine);
    }
         
    return rptArr;
   
}
export default rptEventsWithConditions;

