
/* this function is likely extraneous but couldn't figure out how to create this array in
   the drag_n_drop_column process */  

export const createColumnArray = (cookieName, nbrColumns) => {
        var i;
        var columnArray = [];
        var columnContents = "";
        var fullCookieName = "";
        for (i=0; i<nbrColumns; i++) {
            fullCookieName = cookieName + "column-" + (i+1);
            columnContents = JSON.parse(sessionStorage.getItem(fullCookieName));
            columnArray.push(columnContents);
        }

    return columnArray;
 }




