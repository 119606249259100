
export const setHeadersForFetch = () => {
  const authToken = JSON.parse(sessionStorage.getItem('sessionToken'));
  let headers = {'Content-Type': 'application/json',
      'Authorization': JSON.stringify(authToken),
  };
                
  return headers;
   
}





