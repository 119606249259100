import {Component} from 'react';
import "./ContactUs/contactUs.css";
import ContactUsModal from './contact_us_modal';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch'
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import ConfigFile from '../../datasets/config_file';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';
//import SendEmail from '../../objects/CommonUse/send_email-save;'

class ContactUsMain extends Component {
    constructor(props) {
        super(props);
        this.state={
          openModal: false,
          headers: setHeadersForFetch(),
          subProcess: "ContactUs",
          environment: Config.dataURL.currentEnv,
                                   
        };   
        this.typeOfModal = "";
    }

    setTypeOfModal(modalName){
        this.typeOfModal = modalName;
        this.setState({openModal: true});
    }

    closeModals() {
        this.typeOfModal = "";
        this.setState({openModal: false});
    }
        
    submitContactUsForm(contactUsObj) {
    //    let message = "Subject: " + contactUsObj.subject + " - " + contactUsObj.messageText;
    //    need to combine subject and messageText if using emailjs...    
        let fromName = contactUsObj.firstName;
        let userName = sessionStorage.getItem("username");
        if (userName === null){
            userName = "NotSignedIn"
        }
        let toName = "MoneyTrain Game"
        this.emailParms={from_name: fromName, userName: userName, to_name: toName, subject: contactUsObj.subject,
            message: contactUsObj.messageText, reply_to: contactUsObj.emailAddress};
        let templateName=ConfigFile.login.contactTemplate;
        let serviceID = ConfigFile.login.contactServiceID;
        let publicID = ConfigFile.login.contactPublicID;
        console.log("sending email --  template: ", templateName, "  serviceID: ", serviceID,
            " publicID: ", publicID);
        // SendEmail(this.emailParms, templateName, serviceID, publicID);
        this.saveContactUs();    
        var alertType = "";
        if (contactUsObj.rqstType === "UserSignon"){
             alertType = "Request for Access"
        }else{
             alertType = "Email"
        }
        alert ("Your " + alertType + " has been sent. " +
            "We will send an email reply within the next couple of days. Thank you!!" )
        this.typeOfModal = "";
        this.setState({openModal: false});
    }
   
    
    render() {
        this.username = sessionStorage.getItem("username");
        let screenParms = setScreenSizeParameters(this.props.screenSize, "about");
        this.className = screenParms.columnClass;
        this.textClass = screenParms.textSize + " ml-4 mr-4 mt-4";
        this.imageClass = screenParms.imageSize;
        if (this.props.screenSize === "narrow"){
              this.column1Class = "col-sm-1 mt-4 ml-3"
              this.column2Class = "col-sm-9"
        }else{
              this.column1Class = "col-sm-3 mt-4 ml-5";
              this.column2Class = "col-sm-7 mt-4"
        }
                                         
    return <>
            <div className={this.className}>
                <div className="card mt-3 rounded-corners-lg height-max container"> 
                    <button className="pages-back-button ml-4 mt-4" 
                            onClick ={()=> this.props.goBack()}>
                                    Back
                    </button>
                    <h3 className="p-0 text-center">Contact Us</h3>
                    <h5 className="mt-0 text-center">Send an Email</h5>
                    <div className="overflow-scroll container mt-2">
                        <h6 className="font-weight-bold text-center">We would love to hear from you!!</h6>
                        
                        <div className={this.textClass}>
                            Just click on the <b>"Send Email"</b> button if you have a 
                            a general comment or a question about the game.  This game is still in early
                            stages of development, so your comments will help us identify things that
                            should change to improve the game or this site.  Also, if you have questions, 
                            we would be happy to provide answers.
                        </div>
                       
                        <span className="row">
                            <div className={this.column1Class}>
                            <img  className={this.imageClass} src={'./assets/ContactUs/pencilPaper.png'} 
                                alt="writingPic"/>

                            </div>
                            <div className={this.column2Class}>
                                
                                {this.typeOfModal !== "SendEmail" ?
                                    <div className="text-center mt-3 ml-3">
                                        <button className="good-looking-button" 
                                            onClick ={()=> this.setTypeOfModal("SendEmail")}>
                                                Send Email
                                        </button>
                                    </div>
                           
                                :
                                    <ContactUsModal openModal={this.state.openModal} 
                                        onClose={() => this.closeModals()} 
                                        onSubmit={(contactUsObj) => this.submitContactUsForm(contactUsObj)}
                                        />
                                }
                        
                            
                            </div>

                        </span>
                    </div>
                </div>        
            </div>
        </>
    }
 
    saveContactUs() {
        let headers = {'Content-Type': 'application/json',
            'Authorization': {},
        };
        if (this.emailParms.userName !== "NotSignedIn"){
            headers = this.state.headers;
        }
        let dateObj = getCurrentDateTime();
        let bodyObj = {
            fromName: this.emailParms.from_name,
            toName: this.emailParms.to_name,
            userName: this.emailParms.userName,
            subject: this.emailParms.subject,
            message: this.emailParms.message,
            replyTo: this.emailParms.reply_to,
            dateAdded: dateObj.date,
            timeAdded: dateObj.time
        };
        const funcName = "saveContactUsEmail";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
      
        fetch(uri,
            {
            method:'post',
            headers: headers,
            body: JSON.stringify(bodyObj)
            })
            .then(response => {
                    if (response.ok){
                        response.json().then(data => {
                            const output = JSON.parse(data);
                            const statusCode = output.statusCode;
                            if (statusCode === 200){
                                //  the call to save the email was successful!
                            }else{
                                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                    status: statusCode, message: output.body.message, errorObject: bodyObj};
                                this.props.processError(errorObj);
                            }
                    });
                };
        });
             
    }
 
}

export default ContactUsMain;