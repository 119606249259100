const ChoosingCareerChallenge2HintsText =
    ["Individuals are usually most successful in their career " +
        "if their skills/interests match the career that they choose.",
     "While making a lot of money is important " +
        "it won't matter if you can't find a job because there is little " +
        "demand for persons in this career.",
     "If you know others who have this job, it might help you to " +
        "determine if the job is a good fit for you.  This seems to be " +
        "more important that knowing that your friends think this " +
        "the job is cool."
      ]
   

export default ChoosingCareerChallenge2HintsText;