const LetterListDataset = [
   {letter: "a", image: "a_letter.png", capImage: "aCap_letter.png"},
   {letter: "b", image: "b_letter.png", capImage: "bCap_letter.png"}, 
   {letter: "c", image: "c_letter.png", capImage: "cCap_letter.png"}, 
   {letter: "d", image: "d_letter.png", capImage: "dCap_letter.png"},  
   {letter: "e", image: "e_letter.png", capImage: "eCap_letter.png"}, 
   {letter: "f", image: "f_letter.png", capImage: "fCap_letter.png"}, 
   {letter: "g", image: "g_letter.png", capImage: "gCap_letter.png"}, 
   {letter: "h", image: "h_letter.png", capImage: "hCap_letter.png"}, 
   {letter: "i", image: "i_letter.png", capImage: "iCap_letter.png"}, 
   {letter: "j", image: "j_letter.png", capImage: "jCap_letter.png"}, 
   {letter: "k", image: "k_letter.png", capImage: "kCap_letter.png"}, 
   {letter: "l", image: "l_letter.png", capImage: "lCap_letter.png"}, 
   {letter: "m", image: "m_letter.png", capImage: "mCap_letter.png"}, 
   {letter: "n", image: "n_letter.png", capImage: "nCap_letter.png"}, 
   {letter: "o", image: "o_letter.png", capImage: "oCap_letter.png"}, 
   {letter: "p", image: "p_letter.png", capImage: "pCap_letter.png"}, 
   {letter: "q", image: "q_letter.png", capImage: "qCap_letter.png"}, 
   {letter: "r", image: "r_letter.png", capImage: "rCap_letter.png"}, 
   {letter: "s", image: "s_letter.png", capImage: "sCap_letter.png"}, 
   {letter: "t", image: "t_letter.png", capImage: "tCap_letter.png"},
   {letter: "u", image: "u_letter.png", capImage: "uCap_letter.png"}, 
   {letter: "v", image: "v_letter.png", capImage: "vCap_letter.png"}, 
   {letter: "w", image: "w_letter.png", capImage: "wCap_letter.png"}, 
   {letter: "x", image: "x_letter.png", capImage: "xCap_letter.png"}, 
   {letter: "y", image: "y_letter.png", capImage: "yCap_letter.png"}, 
   {letter: "z", image: "z_letter.png", capImage: "zCap_letter.png"},  
    
];

export default LetterListDataset;