
export const calcNbrFriendsMet = (friendsList) => {
    let nbrFriendsMet = 0;
    for (let i=0; i<friendsList.length; i++){
          if (friendsList.introDate != ""){
                nbrFriendsMet += 1;
          }
    }
              
    return nbrFriendsMet;
   
}
