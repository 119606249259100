function HousingOption(slctNbr, description, bedrooms, homeAptInd, maxRoommates, minHomeCost, level) {
    this.slctNbr = slctNbr;
    this.description = description;
    this.bedrooms = bedrooms;
    this.homeAptInd = homeAptInd;
    this.maxRoommates = maxRoommates;
    this.minHomeCost = minHomeCost;
    this.level = level;
}

module.exports = HousingOption;