import {Component} from 'react';
import { withRouter } from "react-router";import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DragNDropModal from '../extra_credit/drag_n_drop_modal';
import LoanCalculatorModal from '../extra_credit/loan_calculator_modal';
import SavingsOverTimeModal from '../extra_credit/savings_over_time_modal';
// input data 
import ExtraCreditConfigFile from '../extra_credit/Datasets/extra_credit_config_file';
import SavingMoneyChallenge1ResultsText from './badgesText/saving_money_challenge1_results_text';
import LoanChallenge1ResultsText from './badgesText/loan_challenge1_results_text';
// functions
import { createColumnArray } from '../../objects/DragNDrop/createColumnArray_function';
import { calcColumnScore } from '../../objects/DragNDrop/calcColumnScore_function';
import { calcColumnAmt } from '../../objects/DragNDrop/calcColumnAmount_function';
import { calcItemScore } from '../../objects/DragNDrop/calcItemScore_function';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';
import { createTaxAmtExpense } from '../../objects/AssetsExpenses/create_tax_amt_expense';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { createResultsArray } from '../extra_credit/objects/createResultsArray_function';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import buildBadgeDragNDropDataset from './objects/build_badge_dragNDrop_dataset';
import { addChangeSalaryAsset } from '../../objects/AssetsExpenses/add_change_salary_asset';
import buildUrl from '../../objects/CommonUse/build_URL';
import Config from '../../config';


class EarnBadge extends Component {
  
    constructor(props) {  
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            currentStatus: "",
            calculatorStatus: "",
            headers: setHeadersForFetch(),
            dragNDrop: {},
            openSw: false,
            challengeScore: 0,
            challengeComplete: false,
            modalMessage: "",
            hintTable: [],
            hintNbr: 0,
            tableNbrRows: 0,
            tableObject: [],
            col1Text: "",
            freezeFlag: false,
            displayAlert: false,
            title: ExtraCreditConfigFile.general.title,
            nbrTries: 1,
            modalSw: false,
            calculateOnly: false,
            environment: Config.dataURL.currentEnv,
            subProcess: "earnBadge",
        }
        this.openBadgeChallenge();
    }
       
    openBadgeChallenge() {
        this.state.openSw = true;
        let dragNDropObject = buildBadgeDragNDropDataset(this.props.nextBadge);
        this.state.dragNDrop = dragNDropObject.dragNDropDataset;
        this.hintTable = JSON.parse(JSON.stringify(dragNDropObject.hintTable));
        this.state.hintTable = dragNDropObject.hintTable;
        this.state.currentStatus = "OpenChallenge";
       
    }
    
    closeChallenge() {
        this.state.openSw = false;
        this.props.history.push("/game-board");
    }
    
    processChallengeResponse() {
        this.scoreChallenge();
        if (+this.state.challengeScore >= 100) {
            if (this.props.nextBadge == "9" || this.props.nextBadge == "11"){
                if (this.props.nextBadge == "9"){
                    this.state.col1Text = [
                        "Total Amt Out-of-Pocket", "Total Amt of Savings" ];
                    this.state.dragNDrop.introText = SavingMoneyChallenge1ResultsText;
                    this.state.tableNbrRows = 2;
                }else{
                    this.state.col1Text=["Calculated Amount"];
                    this.state.dragNDrop.introText = LoanChallenge1ResultsText;
                    this.state.tableNbrRows = 1;
                }
                this.state.tableObject=createResultsArray(this.columnArray);
                this.state.dragNDrop.buttonType = "Continue";
                this.state.freezeFlag = true;
           }else{
                this.state.modalMessage = "";
                this.state.openSw = false;
                this.finishChallenge("success");
            }
        } else {
            this.state.nbrTries += 1;
            if (this.state.nbrTries > 3){
                  this.state.openSw = false;
                  this.finishChallenge("failed")
            }else{
                this.state.modalMessage= "Not quite right... Try again.";
                this.state.displayAlert = true;
                if (this.state.hintNbr < this.state.hintTable.length){
                    this.state.hintNbr = this.state.hintTable.length;
                }
                if (this.props.nextBadge == "4"){
                    let hintLine1 = JSON.parse(JSON.stringify(this.hintTable[0]));
                    let totalAmtFmt = doubleToStringMonthly(this.totalColumnAmt);
                    hintLine1 = (hintLine1).replace("||dollarAmt||", totalAmtFmt)
                    this.state.hintTable[0] = hintLine1;
                }
            }
        } 
    }

    scoreChallenge(){ 
        let nbrColumns = this.props.parameters.nbrColumns;
        this.columnArray=createColumnArray(this.state.dragNDrop.cookieNamePfx, nbrColumns); 
        if (this.props.nextBadge == 3 || this.props.nextBadge == 6){
            this.state.challengeScore = calcItemScore(this.columnArray);
        }else{
            this.state.challengeScore = calcColumnScore(this.columnArray);
        }
        // this code is only invoked for badge #4 (priorities challenge #2)
        // but can be applied to other badges if min amount and max amount
        // are provided.
        if (this.state.dragNDrop.inputData.minAmount != undefined &&
            this.state.dragNDrop.inputData.maxAmount != undefined){
                this.minAmount = this.state.dragNDrop.inputData.minAmount;
                this.maxAmount = this.state.dragNDrop.inputData.maxAmount;
                this.totalColumnAmt = calcColumnAmt(this.columnArray, "column-1");
                if (this.totalColumnAmt > this.maxAmount ||
                    this.totalColumnAmt < this.minAmount){
                        this.state.challengeScore = 0;
                }
        }
    }

    openCalculator(){
        this.state.openCalculatorSw = true;
        this.state.calculateOnly = true;
        if (this.state.dragNDrop.calculatorType == "Savings"){
            this.setState({calculatorStatus: "OpenSavingsCalculator"});
        }else{
            this.setState({calculatorStatus: "OpenLoanCalculator"});
        }
        
    }
      
    closeCalculator() {
        this.state.openCalculatorSw = false;
        this.state.calculatorStatus = "CloseCalculator";
    }

    closeAlert(){
        this.state.displayAlert = false;
        this.setState({displayAlert: false});
    }
     
    render() {
        this.gutters = "";
        if (this.state.dragNDrop.modalSize == "xl" && 
            (this.props.screenSize == "narrow" || this.props.screenSize == "medium")){
             this.gutters = "modal-95";
        }
        let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard", "mt-5");
        this.className = screenParms.columnClass;
        this.backgroundURL = this.props.backgroundURL;
     
    return <>
        <div className={this.className}>
            <div className="card mt-3 mr-3 rounded-corners-lg text-center game-board"
                style={{backgroundImage: `url(${process.env.PUBLIC_URL + this.backgroundURL})`}}>
                           <h3 className="mt-2 text-center ml-5 game-board-title-background">Game Board</h3>
      
            {this.state.currentStatus == "OpenChallenge" ?
                <DragNDropModal show={this.state.openSw} 
                        gutters={this.gutters}
                        onClose={() => this.closeChallenge()} 
                        onSave={() => this.processChallengeResponse()}
                        onCalculator={() => this.openCalculator()}
                        onContinue={() => this.finishChallenge("success")}
                        closeAlert={() => this.closeAlert()}
                        inputDataset={this.state.dragNDrop.inputData}
                        modalSize={this.state.dragNDrop.modalSize}
                        amountSw={this.state.dragNDrop.amountSw}
                        modalTitle={this.state.dragNDrop.modalTitle}
                        buttonType={this.state.dragNDrop.buttonType}
                        calcType={this.state.dragNDrop.calculatorType}
                        introText={this.state.dragNDrop.introText}
                        cookieNamePfx={this.state.dragNDrop.cookieNamePfx}
                        maxNbr={this.state.dragNDrop.maxNbrPerColumn}
                        message={this.state.modalMessage}
                        tableNbrRows={this.state.tableNbrRows}
                        tableCol1Text={this.state.col1Text}
                        tableObjectRow={this.state.tableObject}
                        freezeFlag={this.state.freezeFlag}
                        hintTable={this.state.hintTable}
                        hintNbr={this.state.hintNbr}
                        displayAlert={this.state.displayAlert}
                    />
            :
                null
            }
            
            {this.state.calculatorStatus == "OpenSavingsCalculator" ?
                    <SavingsOverTimeModal show={this.state.openCalculatorSw} 
                        gutters={this.gutters}
                        onClose={() => this.closeCalculator()} 
                        calculatorSw ={this.state.calculateOnly}
                        displayInitialAmt={false}/>
                :
                    <div></div>
                }
            {this.state.calculatorStatus == "OpenLoanCalculator" ?
                    <LoanCalculatorModal show={this.state.openCalculatorSw} 
                        screenSize={this.props.screenSize}
                        onClose={() => this.closeCalculator()} 
                        calculatorSw ={this.state.calculateOnly}/>
                :
                    <div></div>
            }

            {this.state.currentStatus == "DisplayBadgeNotEarned" ?
                <Modal show={this.state.modalSw}  onHide={() => this.closeBadgeNotEarned()}
                    size="sm" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Good Try!! Badge Not Earned.</Modal.Title> 
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>You were not able to earn this badge this time.
                        You will have another chance and likely will have
                        more success the next time.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"   onClick={() => this.closeBadgeNotEarned()}>
                        Close
                    </Button>
                </Modal.Footer>
                </Modal>
            :
                null
            } 
       </div>                           
       </div>
        </>
    }

    finishChallenge(status) {
        if (status == "success"){
             this.updateBadgeEarned();
             this.props.updateBadgeArr(this.gameInfo.gameData.badgesEarned);
             let badgeIndx = this.props.nextBadge - 1;
             this.props.openBadge(badgeIndx);
             this.props.history.push("/game-board");
        }else{
             this.state.currentStatus = "DisplayBadgeNotEarned";
             this.state.modalSw = true;
        }    
    }

    closeBadgeNotEarned(){
         this.state.modalSw = false;
         this.state.currentStatus = "";
         this.props.history.push("/game-board");
    }

    updateBadgeEarned(){
        let dateObj = getCurrentDateTime();
        this.determineValue();
        this.newBadgeEarned = {badgeCode: this.props.parameters.badgeCode,
            badgeName: this.props.parameters.badgeName,
            awardType: this.props.parameters.awardType,
            awardAmount: this.awardAmount,
            moodPointsEarned: this.moodPointIncrease,
            dateEarned: this.gameInfo.gameData.currentGameDate}
        this.gameInfo.gameData.badgesEarned.push(this.newBadgeEarned);
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData'); 
        const funcName = "updateBadgesEarned";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        const bodyObj = {"badgesEarned":JSON.parse(JSON.stringify(this.gameInfo.gameData.badgesEarned)),
            "assetTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.assetTable)),
            "expenseTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.expenseTable)),
            "currentCash": this.gameInfo.gameData.currentCash,
            "currentMoodPoints": this.gameInfo.gameData.currentMoodPoints,
            "dateUpdated": dateObj.date
        };
        fetch (uri, 
            {method:'PUT', 
              headers: this.state.headers, 
              body: JSON.stringify(bodyObj)})
              .then(response => {
                if (response.ok){
                  response.json().then(data => {
                  const output = JSON.parse(data);
                  const statusCode = output.statusCode;
                  if (output.statusCode == 200){ 
                    // update badges earned successful
                  }else{
                      const errorObj={function: funcName, subProcess: this.state.subProcess, 
                          status: statusCode, message: output.body.message, errorObject: bodyObj};
                      this.props.processError(errorObj);
                  }
              });
            }
        });        
        return;
        
    }

    determineValue(){
        let pctEarned = 0;
        switch (this.state.nbrTries){
            case 1:
                pctEarned = 100;
                break;
            case 2:
                pctEarned = 80;
                break;
            case 3:
                pctEarned = 60;
                break
        }
        if (this.props.parameters.awardType == "cash"){
             this.cashIncrease = (+this.props.parameters.awardAmount * pctEarned/100).toFixed(2);
             this.gameInfo.gameData.currentCash = +this.gameInfo.gameData.currentCash +
                +this.cashIncrease;
             this.awardAmount = this.cashIncrease;
        }
        if (this.props.parameters.awardType == "salary"){
             this.salaryIncrease = (+this.props.parameters.awardAmount * pctEarned/100).toFixed(2);
             this.updateAssetTable();
             // update the taxes for the new salary amount
             this.updateExpenseTable();
             this.awardAmount = this.salaryIncrease;
        }
        this.moodPointIncrease = (+this.props.parameters.moodPoints * pctEarned/100).toFixed(2);
        this.gameInfo.gameData.currentMoodPoints += +this.moodPointIncrease;
    }

    updateAssetTable(){
        let newAssetObj = addChangeSalaryAsset(this.gameInfo.gameData.assetTable,
            "BadgeBonus", this.gameInfo.gameData.currentGameDate, this.salaryIncrease,
            this.props.parameters.title);
        this.gameInfo.gameData.assetTable = newAssetObj.assetTable;
        this.totalSalary = newAssetObj.totalSalary;
    }

    updateExpenseTable(){
        const revisedExpenseTable = createTaxAmtExpense(this.gameInfo.gameData.expenseTable, 
            this.gameInfo.gameData.assetTable, 
            this.gameInfo.gameData.currentGameDate);
        this.gameInfo.gameData.expenseTable = revisedExpenseTable;
    }
    
    
}
export default withRouter(EarnBadge);