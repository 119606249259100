const updateAssetInfo = 
    {assetTable:
    [
        {
            "assetItem": "Job Salary",
            "assetDesc": "Electrical Engineer --> Annual Salary: $64,000.00",
            "assetAmt": "5333.33",
            "assetStartDate": "2022-10",
            "assetNbrMonths": "-1"
        },
        {
            "assetItem": "Test Asset",
            "assetDesc": "This is the update asset table test",
            "assetAmt": "8888.88",
            "assetStartDate": "2024-02",
            "assetNbrMonths": "-1"
        }
   ]
    }

export default updateAssetInfo;