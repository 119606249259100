const LinkToValues =[
      
        "game-choices",
        "change-skills-survey",
        "change-career",
        "change-home",
        "change-car",
        "setup-fun-stuff",
        "setup-savings",
        "setup-credit-card",
        "earn-badge",
        "status-display",
        "monthly-status",
        "extra-credit-main",
        "money-calculators",
        "money-mgmt-test",          
        "game-feedback",                      
]

export default LinkToValues;