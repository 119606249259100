import {Component} from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';
import "./Special/special.css";
import GameCardFaceUp from '../game-views/game_card_face_up';
import GameCardResponse from './game_card_response';
import EventResponseModal from './event_response_modal';
import EventListDisplayModal from './event_list_display_modal';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import EventParmInputModal from './event_parm_input_modal';
import calculateResponseAmount from '../game-views/objects/calculate_response_amount';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';


class DisplayEvents extends Component {
    constructor(props) { 
        super(props);
        this.specialInfo = this.props.specialInfo();
        this.state = {
            loading: true,
            displayParmModal:  true,
            openEventParameterModal: false,
            openDisplayModal: true,
            headers: setHeadersForFetch(),
            eventDisplayType: "initial",
            sortedCareerTags: [],
            cardsLoaded: false,
            firstTime: true,
            cardImage: false,
            slctParms: {},
            displayType: "",
            gamePhase: "",
            eventCategory: "",
            careerTag: "",
            displayType: "",
            openResponseModal: false,
            eventArr: [],
            eventId: "",
            subProcess: "DisplayEvents",
            environment: Config.dataURL.currentEnv,
        }
    }

    componentDidMount(){
        this.sortConditionsList();
        this.setState({loading: false});
        this.specialInfo.cardList = [];
        this.props.setSpecialInfo(this.specialInfo.cardList, "cardList");
    }
        
    sortConditionsList() {
        var i;
        var condName="";
        var careerCondNameArr=[];
        for (i=0; i<this.specialInfo.conditionsData.length; i++){
            // convert condName from index to string
            condName = JSON.parse(JSON.stringify(this.specialInfo.conditionsData[i].condName));
            if (this.specialInfo.conditionsData[i].conditionClass == "career"){
                careerCondNameArr.push(condName);
            }
        }
        this.state.sortedCareerTags = careerCondNameArr.sort();
    }

    openParameterModal(){
        this.state.openEventParameterModal = true;
        this.state.eventDisplayType = "enterParms";
        this.specialInfo.cardList = [];
        this.props.setSpecialInfo(this.specialInfo.cardList, 'cardList');
        this.setState({cardsLoaded: false});
    }

    closeParameterModal(){
        this.state.openEventParameterModal = false;
    }

    closeEventDisplayModal(){
        this.state.openDisplayModal = false;
    }

    displayEventItem(eventParameters){
        this.state.openEventParameterModal = false;
        this.state.slctParms = eventParameters;
        this.state.displayType = eventParameters.displayType;
        if (this.state.displayType == "list"){
            this.state.openDisplayModal = true;
        }
        this.state.gamePhase = eventParameters.gamePhase;
        this.state.eventCategory = eventParameters.eventCategory;
        this.state.careerTag = eventParameters.careerTag;
        this.getSelectedEvents();
        this.setState({firstTime: true});
        this.eventNbr = eventParameters.eventNbr;
    }
         
    addLastCard(){
       
        if (this.state.displayType == "cards"){
            this.nbrEventsFound = this.specialInfo.cardList.length;
            let lastCard = {
                title: "Enter New Select Parameters",
                description: "Click the button below to clear Cards",
                color: "green",
                btnText: "notNull",
                lastCardButtons: [{type: "Regular", btnText: "OK", activity: ""}]
            };
            this.specialInfo.cardList.push(lastCard);
            this.props.setSpecialInfo(this.specialInfo.cardList, "cardList");
            this.determineCardIdx();
            this.props.setSpecialInfo(this.specialInfo.cardIdx, "cardIdx");
            this.displayEvent();
        }else{
            this.state.eventDisplayType = "eventList";
        }
        this.setState({firstTime: false});
    }

    determineCardIdx(){
        this.specialInfo.cardIdx = 0;
        if (this.eventNbr != ""){
            if (+this.eventNbr > this.specialInfo.cardList.length){
                this.specialInfo.cardIdx = this.specialInfo.cardList.length - 1;
            }else{
                this.specialInfo.cardIdx = +this.eventNbr - 1;
            }
        }
    }

    displayEvent(){
        this.cardNbrDisplayed = this.specialInfo.cardIdx + 1;
        this.cardDisplayMsg = "Displaying Card " + this.cardNbrDisplayed + " of " + this.nbrEventsFound +
           "  Total Cards ";
        this.state.eventDisplayType = "faceUpCard";
        this.specialInfo.cardResponses = this.specialInfo.cardList[this.specialInfo.cardIdx];
        this.props.setSpecialInfo(this.specialInfo.cardResponses, "cardResponses");
        let cardTest = this.specialInfo.cardList[this.specialInfo.cardIdx];
        if (cardTest.responses == undefined){
            this.specialInfo.currentCard = this.specialInfo.cardList[this.specialInfo.cardIdx];
        }else{
            this.removeDupBtnText();
            this.specialInfo.currentCard = this.currentCard;
        }
        this.props.setSpecialInfo(this.specialInfo.currentCard, "currentCard");
        this.formatCardHeaderText();
    }

    removeDupBtnText(){
        this.currentCard = JSON.parse(JSON.stringify(this.specialInfo.cardList[this.specialInfo.cardIdx]));
        let saveBtnText = "";
        let responseArr = [];
        for (let i=0; i<this.currentCard.responses.length; i++){
            let response=this.currentCard.responses[i];
            if (response.btnText != saveBtnText){
                responseArr.push(response);
                saveBtnText = response.btnText;
            }
        }
        this.currentCard.responses = responseArr;
    }
    
    displayPreviousEvent(){
        if (this.specialInfo.cardIdx >= 2){
            this.specialInfo.cardIdx -= 2;
        }else{
            this.specialInfo.cardIdx -= 1;
        }
        this.displayEvent();
    }

    formatCardHeaderText(){
        this.title = this.specialInfo.currentCard.title;
        if (this.specialInfo.currentCard.responses != undefined){
            this.conditionList = (this.specialInfo.currentCard.conditionArr).join(",");
            this.skipConditionList = (this.specialInfo.currentCard.skipConditionArr).join(",");
            this.frequency = this.specialInfo.currentCard.frequency;
            this.backgroundType = this.specialInfo.currentCard.backgroundType;
            this.selectMonthArr = (this.specialInfo.currentCard.selectMonthArr).join(",");
            if (this.specialInfo.currentCard.imageDir != undefined){
                this.imageList = this.specialInfo.currentCard.imageDir;
            }else{
                this.imageList = "";
            }
            this.monthList = (this.specialInfo.currentCard.selectMonthArr).join(",");
            this.descLength = (this.specialInfo.currentCard.description.length);
        }else{
            this.state.displayType = "";
        }
    }
    
    calculateResponseValue(response){
        //  have to change values back from list to a single value for this display
        //  always use the first value in the list
        let currentCard = JSON.parse(JSON.stringify(this.specialInfo.currentCard));
        let valueArr = currentCard.responses[response].valueList.split(",");
        let slctValue = +valueArr[0];
        currentCard.responses[response] = {...currentCard.responses[response],  value: slctValue };
        let responseValue = calculateResponseAmount(currentCard, 
                response, this.specialInfo.gameData);
        this.response = response;
        this.slctdResponse = response;
        return responseValue;
    }

    displayResponse(response){
       this.title = this.specialInfo.currentCard.title; 
       this.response = response;
       this.state.eventDisplayType = "faceUpResponse";
    }

    onResultDisplay(){
       
    }

    processEventResponse(replyNbr){
        this.specialInfo.cardIdx += 1;
        this.state.eventDisplayType = "displayNextEvent";
        this.props.setSpecialInfo(this.specialInfo.cardIdx, "cardIdx");
     }

    lastCardDisplayed(){
        this.state.eventDisplayType = "initial";
        this.specialInfo.cardList = [];
        this.props.setSpecialInfo(this.specialInfo.cardList, 'cardList');
        this.setState({cardsLoaded: false});
    }

    openResponseModal(){
        this.state.openResponseModal = true;
        this.title = this.specialInfo.currentCard.title;
        this.state.eventDisplayType = "responseDetail";
    }

    closeResponseModal(){
        this.state.openResponseModal = false;
        this.state.eventDisplayType = "faceUpCard";
    }

    updateResponse(responseArr){
        for (let i=0; i<responseArr.length; i++){
            if (responseArr[i].recordChanged == true &&
                responseArr[i].btnText == this.specialInfo.cardResponses.responses[i].btnText){
                   this.state.eventId = this.specialInfo.cardResponses._id;
                   if (responseArr[i].newMoodPoints != ""){
                        this.specialInfo.cardResponses.responses[i].moodPoints = responseArr[i].newMoodPoints;
                   }
                   if (responseArr[i].newClosureText != ""){
                        this.specialInfo.cardResponses.responses[i].closureText = responseArr[i].newClosureText;
                   } 
            }    
        }
        this.updateEvent();
    }
      
    render() {
        if (this.state.cardsLoaded == true && this.state.firstTime == true){
            this.addLastCard();  
        }
        let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard");
        this.className = screenParms.columnClass;
                                 
    return <>
        <div className={this.className}>
        <div className="card mt-3 mr-5 rounded-corners-lg text-center game-board">
            <h2 className="mt-2 text-center">Special Processes</h2>
            <h5 className="text-center mt-0">Display Events</h5>
            {this.state.loading ?
                    <WaitTimeSpinner/>
                :
                null
            }
           <div className="row mt-0">
                <div className="col-md ml-4 text-left mb-0">
                    <div className="py-0"><b>Game Phase:</b> &nbsp;&nbsp;&nbsp;&nbsp; {this.state.gamePhase}</div>
                    <div className="py-0"><b>Event Category:</b> &nbsp;&nbsp;{this.state.eventCategory}</div>
                    <div className="py-0"><b>Job Name:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.careerTag}</div>
                    <div className="font-weight-bold py-0">{this.cardDisplayMsg}</div>
                </div>
                <div className="col-md">
                        <div className="ml-4 mr-4">
                            <div className="text-right">
                                <Button variant="primary"  onClick={() => this.openParameterModal()}>
                                    Select Events
                                </Button>
                            </div>
                        </div>
                </div>  
            </div>               
           
            {this.state.eventDisplayType == "enterParms" ?
                    <EventParmInputModal  openModal={this.state.openEventParameterModal} 
                        careerTags={this.state.sortedCareerTags}
                        onClose={() => this.closeParameterModal()} 
                        onSubmit={(eventParameters) => this.displayEventItem(eventParameters)}
                    />
            :
                    null
            }

            {this.state.eventDisplayType == "responseDetail" ?
                    <EventResponseModal  openModal={this.state.openResponseModal} 
                        title={this.title}
                        responses={this.specialInfo.cardResponses.responses}
                        onClose={() => this.closeResponseModal()} 
                        updateResponse={(responseArr) => this.updateResponse(responseArr)}
                        displayResponse ={(response)=> this.displayResponse(response)}
                    />
            :
                null
            }

            {this.state.eventDisplayType == "eventList"  ?
                 <EventListDisplayModal  openModal={this.state.openDisplayModal} 
                    slctParms={this.state.slctParms}
                    cardList={this.specialInfo.cardList}
                    onClose={() => this.closeEventDisplayModal()} 
                />
            :
                null
            }

            {this.state.eventDisplayType  == "faceUpCard" || this.state.eventDisplayType == "faceUpResponse" ?
                <span>
                    <div className="row mt-0">
                        <div className="col-md">
                            {this.state.eventDisplayType == "faceUpCard" ?
                            <div>
                                <GameCardFaceUp currentCard={this.specialInfo.currentCard}
                                    onLastCard={(selectedType, activity) => this.lastCardDisplayed(selectedType, activity)}
                                    onComplete={(selectedInd) => this.processEventResponse(selectedInd)}
                                    calcResponseValue={(response) => this.calculateResponseValue(response)}
                                    onResultDisplay = {() => this.onResultDisplay()}/>
                            </div>
                            :
                            <div>
                            <GameCardResponse 
                                    title={this.title}
                                    response={this.response}
                                    onComplete={(selectedInd) => this.processEventResponse(selectedInd)}
                            />
                               
                        </div>
                            }
                        </div>
                    <div className="col-md">
                        <span className="mt-0 ml-4">
                            <span>
                                <table className="text-left">
                                    <tr>
                                        <th className="p-0">Title:</th>
                                        <td className="p-0">{this.title}&nbsp;(length: {this.title.length}) </td>
                                    </tr>
                                    <tr>
                                        <th className="p-0">Conditions: </th>
                                        <td className="p-0">{this.conditionList}</td>
                                    </tr>
                                    <tr>
                                        <th className="p-0">Skip Conditions:</th>
                                        <td className="p-0">{this.skipConditionList}</td>
                                    </tr>
                                    <tr>
                                        <th className="p-0">Frequency:</th>
                                        <td className="p-0">{this.frequency}</td>
                                    </tr>
                                    <tr>
                                        <th className="p-0">Background Type:&nbsp;&nbsp;</th>
                                        <td className="p-0">{this.backgroundType}</td>
                                    </tr>
                                    <tr>
                                        <th className="p-0">Image List:</th>
                                        <td className="p-0">{this.imageList}</td>
                                    </tr>
                                    <tr>
                                        <th className="p-0">Month List:</th>
                                        <td className="p-0">{this.monthList}</td>
                                    </tr>
                                    <tr>
                                        <th className="p-0">Length of Text:</th>
                                        <td className="p-0">{this.descLength}</td>
                                    </tr>
                                </table>
                                <Button variant="primary"  onClick={() => this.openResponseModal()}>
                                        Response Detail
                                </Button>
                               
                                </span>
                           
                        </span>
                    </div>
                  </div>
       
                </span>
            : 
                null
            }
        
            {this.state.eventDisplayType == "displayNextEvent"   ?
                <div className="row">
                    <div className="col-md text-center mt-4 mx-2">
                        <Button className="mx-2" variant="primary"  onClick={() => this.displayPreviousEvent()}>
                            Previous Card
                        </Button>
                        <Button className="mx-2" variant="primary"  onClick={() => this.displayEvent()}>
                            Next Card
                        </Button>
                    </div>
                    <div className="col-md"></div>
                </div>
            :
                null
            }
     
           </div>        
        </div>
     </>
    }

    async getSelectedEvents(){
        this.setState({loading: true});
        const gamePhase = this.state.gamePhase;
        const category = this.state.eventCategory;
        const jobName = this.state.careerTag;
        const funcName = "loadEvents";
        const urlParm = "?gamePhase=" + gamePhase + "&eventCategory=" + category +
            "&jobName=" + jobName;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch(uri,
              {method:'GET', headers: this.state.headers})
              .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            this.state.eventArr = JSON.parse(JSON.stringify(output.body));
                            for (const card of this.state.eventArr)
                            {
                                this.addEffectsToResponse(card);
                                this.specialInfo.cardList.push(this.cardReformat);
                            }
                            this.props.setSpecialInfo(this.specialInfo.cardList, 'cardList');
                            this.setState({cardsLoaded: true});
                            this.setState({loading: false});
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message};
                            this.props.processError(errorObj);
                        }
                    });
                };
          });
      
    }
    
    addEffectsToResponse(card){
        this.responseArr = []
        for (let i=0; i<card.responses.length; i++){
            let response = card.responses[i];
            for  (let j=0; j<response.possibleEffects.length; j++){
                let effect = response.possibleEffects[j];
                let valueList = (effect.possibleValues).join(", ");
                let responseObj = {btnText: response.btnText, closureText: effect.closureText,
                    itemDesc: response.itemDesc,
                    category: effect.category, on: effect.on, type: effect.type,
                    duration: effect.duration, replaceAmtSw: effect.replaceAmtSw,
                    moodPoints: effect.moodPoints, triggerCondition: effect.triggerCondition,
                    deleteCondition: effect.deleteCondition, deleteClass: effect.deleteClass,
                    odds: effect.odds, valueList: valueList}
                this.responseArr.push(responseObj);
            }
            delete card.responses[i].possibleEffects;
        }
        card.responses = this.responseArr;
        this.cardReformat = card;
    }

updateEvent(){
    this.reformatCardResponses();
    let currentDateTime = getCurrentDateTime();
    let dateUpdated = currentDateTime.date;
    for (let i=0; i<this.state.eventArr.length; i++ ){
        if (this.state.eventArr[i]._id == this.state.eventId){
            if (this.cardResponseArr.length != this.state.eventArr[i].responses.length){
                alert("something is wrong - reformatted card responses " +
                      "does not match original response length.");
            }
            this.moveValueToResponses(this.state.eventArr[i].responses);
            this.bodyObj={eventID: this.state.eventId,  responses: this.state.eventArr[i].responses,
                dateUpdated: dateUpdated};
            this.updateEventResponse();
        }
    }
}

moveValueToResponses(responses){
    for (let i=0; i<responses.length; i++){
        if (responses[i].btnText == this.cardResponseArr[i].btnText){
            let response = responses[i];
            let updateResponse = this.cardResponseArr[i];
        //  error in following line - possibleEffects no longer found in responses due to reformatting
        //  need to figure out how to rework this!
            for (let j=0; j<response.possibleEffects.length; j++){
                if (response.possibleEffects[j].closureText = updateResponse.effect[j].closureText){
                    response.possibleEffects[j].moodPoints = updateResponse.effect[j].moodPoints;
                }
            }
        }
    }
}

reformatCardResponses(){
    let previousBtnText = "";
    let effectArr = [];
    this.cardResponseArr = [];
    let responseObj = {};
    for (let i=0; i<this.specialInfo.cardResponses.responses.length; i++){
        let response = this.specialInfo.cardResponses.responses[i];
        if (response.btnText != previousBtnText){
            if (previousBtnText != ""){
                this.cardResponseArr.push(responseObj);
            }
            effectArr = [];
            responseObj = {btnText: response.btnText, 
                    effect:  effectArr};
            previousBtnText = response.btnText;
        }
        effectArr.push({closureText: response.closureText, moodPoints: response.moodPoints});
    }
    this.cardResponseArr.push(responseObj);
}

updateEventResponse(){
    // Update responses in events collection
    const funcName = "updateEventResponse";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
          {method:'PUT', headers: this.state.headers,
          body: JSON.stringify(this.bodyObj)})
          .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        alert("Update successful! Confirm events collection has been updated as expected.")
                        this.setState({eventDisplayType: "faceUpCard"});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
    }


}

export default withRouter (DisplayEvents);