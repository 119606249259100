const friendVacationActivityDialogDataset = [
                       {activityPreference: "2", 
                        comments: ["This place reminds me a lot of other places I've been.", 
                        "This is sort of cool!  But I think the pictures I've seen make it seem better than it is.", 
                        "Well, I probably could have skipped seeing this one.  I mean, it's OK but not amazing or anything.",
                        "What's next on your list?  Perhaps we can check several off the list today.",
                        "We should take some pictures so I can show them to people I know.",
                        "Guess we're headed home tomorrow.  It'll be good to sleep in my own bed."
                        ]},
                {activityPreference: "3", 
                        comments: ["This is really cool!  What are we going to go see next?", 
                        "I really like it here.  Maybe we can hang out for a bit before moving on.", 
                        "This is really great, thanks for asking me to come along.",
                        "I've seen pictures of this before.  But is it so cool to see it in person!",
                        "I'm really glad that I came along with you.  This is fun!",
                        "I can't believe this is almost over.  I think I'll have to come back sometime."
                ]},
                {activityPreference: "4",
                        comments: ["There is so much to see.  It's hard to know where to begin.",
                        "This is a great day!  I am so excited to be here.",
                        "I just want to stand here and take it all in for awhile. It looks amazing!",
                        "Have you ever seen anything like this?  It looks so different than where I come from.",
                        "I've always wanted to come here.  I've seen pictures before, but it is much better to see this in person!",
                        "My brother was so jealous when I told him I was coming here. I'm so glad that I got to see it!"
                        ]},
               
]
 
export default friendVacationActivityDialogDataset;