export const doubleToStringMonthly = (salary) => {
    let salaryAddDecimals = Number.parseFloat(salary).toFixed(2);
    return `$${format(salaryAddDecimals)}`;
}

export const doubleToStringAnnual = (salary) => {
    let annualSalary = Math.round(salary * 12);
    let annualSalaryAddDecimals = Number.parseFloat(annualSalary).toFixed(2)
    return `$${format(annualSalaryAddDecimals)}`;
}


export const doubleToStringWholeDollars = (salary) => {
    let salaryRemoveDecimals = Number.parseFloat(salary).toFixed(0);
    return `$${format(salaryRemoveDecimals)}`;
}


export const doubleToStringAnnualDollars = (salary) => {
    let annualSalary = Math.round(salary * 12);
    let annualSalaryRemoveDecimals = Number.parseFloat(annualSalary).toFixed(0)
    return `$${format(annualSalaryRemoveDecimals)}`;
}

export const standardNumberFormat = (number) => {
    let unformattedNumber = number
    return `${format(unformattedNumber)}`;
}

const format = num => 
    String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
;