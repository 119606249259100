import {Component} from 'react';
import { withRouter } from "react-router";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {username: "", password: ""};
    }

    render() { 
        return <>
            <form className="login-form px-2 pb-2" id="loginForm" onSubmit={()=>this.handleSubmit()}>
                <h6 className="mt-2">Logged in as {this.props.username}</h6>
                <div className="form-group my-0 text-right">
                    <button type="submit" className="btn btn-primary">Log out</button>
                </div>
            </form>
            </>
    }

    handleSubmit()
    {
        this.props.onLogout();
        this.props.history.push('/');
    }
}

export default withRouter(LoginForm);