const HowToUpdate401KSavings =[
        <p>The entries in the fields below will be used to setup, change or decline a 401K savings account. 
        <ul>
            <li>Do you want to setup a 401k Account?: Select "Yes, setup/retain 401K Account" to 
                start or change your 401K account. Select "No, do not setup a 401K account 
                if you want to skip setting up a 401K account or stop the monthly deposits to an existing
                account.</li>
            <li>One Time Amount:  The amount you enter will
                be subtracted from cash and added to your 401K account.
            </li>
            <li>Monthly Amount:  The amount you enter will be subtracted monthly from your Monthly Net Income
                and deposited into your 401K account. </li>
        </ul>
        Once you have completed the entries above, click the "Submit Change" button.
        </p>

]
export default HowToUpdate401KSavings;