const newEventsCollection = 
{
      "responses": [
      {
        "btnText": "Full Car Replacement",
        "itemDesc": "Auto Insurance - Full Car Replacement",
        "possibleEffects": [
          {
            "category": "expense",
            "closureText": "You decide to buy coverage that will cover the full car cost if it needs to be replaced.  This will cost $value per month.",
            "on": "Automobile Insurance",
            "type": "flat",
            "duration": "-1",
            "odds": "100",
            "replaceAmtSw": true,
            "triggerCondition": "sports-car-insurance",
            "deleteCondition": "",
            "deleteClass": "",
            "moodPoints": "-3",
            "possibleValues": [
              "180"
            ]
          }
        ]
      },
      {
        "btnText": "Partial Car Replacement",
        "itemDesc": "Auto Insurance - Partial Car Replacement",
        "possibleEffects": [
          {
            "category": "cash",
            "closureText": "You have decided to buy insurance that will only cover a part of the car's replacement cost.  This will not change the cost of your current auto insurance.",
            "on": "cash",
            "type": "flat",
            "duration": "1",
            "odds": "100",
            "replaceAmtSw": "false",
            "triggerCondition": "sports-car-insurance",
            "deleteCondition": "",
            "deleteClass": "",
            "moodPoints": "1",
            "possibleValues": [
              "0"
            ]
          }
        ]
      }
    ],
    "conditionArr": [
      "sports-car"
    ],
    "skipConditionArr": [
      "sports-car-insurance"
    ],
    "title": "Test Event ",
    "description": "Since you own a very cool sports car, you decided you should contact your insurance agent to ask about coverage.  Your agent said that you have a couple of coverage options.",
    "frequency": "500",
    "conditionConnector": "AND",
    "backgroundType": "home",
    "imageDir": "",
    "dateUpdated": "2022/10/29",
    "gamePhase": "all",
    "careerTag": "",
    "eventCategory": "car",
    "selectMonthArr": [],
  }
  
export default newEventsCollection;