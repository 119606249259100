import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import {rollForwardDate} from './objects/roll_forward_date_function';

class ThreeColumnTableCard extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false};
    }


    render() {
        if (this.props.outInfo.amount1 != undefined){
            if (this.props.outInfo.amount1 == ""){
                this.amount1 = "---";
            }else{
                this.amount1 = doubleToStringMonthly(this.props.outInfo.amount1);
            }
        }
        if (this.props.outInfo.amount2 != undefined){
            if (this.props.outInfo.amount2 == ""){
                this.amount2 = "---";
            }else{
                this.amount2 = doubleToStringMonthly(this.props.outInfo.amount2);
            }
        }
        switch (this.props.infoType){
              case "date-amount-amount":
                  this.reformatStartDate = reformatDateToDisplay(this.props.outInfo.startDate);
                  this.reformatEndDate = reformatDateToDisplay(this.props.outInfo.endDate);
                  break;
              case "label-date-amount":
                  this.class = "text-left p-0 ";
                  if (this.props.outInfo.class != undefined){
                       this.class = this.class + this.props.outInfo.class;
                  }
                  this.reformatEndDate = reformatDateToDisplay(this.props.outInfo.endDate);
                  break;
              case "label-amount-amount":
                   if (this.props.className == undefined){
                        this.className1 = "p-0 text-right";
                        this.className2 = "p-0 text-right";
                   }else{ 
                        this.className1 = this.props.className;
                        this.className2 = this.props.className;
                   } 
                   if (this.props.outInfo.color1 == "red"){
                        this.className1 = this.className1 + " text-danger";
                   }
                   if (this.props.outInfo.color2 == "red"){
                        this.className2 = this.className2 + " text-danger";
                   }
                   break;
            }
                  
    return <>
       {this.props.infoType == "label-date-amount" ?
            <tr>
              <td className="text-left p-0">{this.props.outInfo.label}</td>
              <td className="p-0 text-right">{this.reformatEndDate}</td>
              <td className="p-0 text-right">{this.amount1}</td>
            </tr>
       :
            null
       }
       {this.props.infoType == "date-amount-amount"  ?
            <tr>
              <td className={this.class}>{this.reformatStartDate}-{this.reformatEndDate}</td>
              <td className="p-0 text-right">{this.amount1}</td>
              <td className="p-0 text-right">{this.amount2}</td>
            </tr>
       :
          null
       }
       {this.props.infoType == "label-amount-amount" ?
          <tr>
              <td className="text-left p-0">{this.props.outInfo.label}</td>
              <td className={this.className1}>{this.amount1}</td>
              <td className={this.className2}>{this.amount2}</td>
          </tr>
       :
          null
       }
        
        
    </>
    }
}

export default ThreeColumnTableCard;