const SideHustleInformationText =[
    {activityName: "DeliveryDriver", rules: "Try to deliver to as many houses as possible " +
    "You will earn bonus points if you can complete all of the scheduled deliveries in the " +
    "time that is allotted.  You will have ||nbrTimes|| attempts to complete the deliveries. " +
    "Good luck!", rulesBrief: "Use the arrow keys to drive to the homes and make deliveries."},
    {activityName: "TutorGrammar", rules: "The student that you are helping is " +
    "currently learning about parts of speech.  They are learning about nouns, verbs, adjectives " +
    "and adverbs.  The types of words are noted on each of their assignments." + 
    "Can you help them complete the assignment?  You will have ||nbrTimes|| minute(s) " +
    "to complete as many words as possible. " +
    "Good luck!", rulesBrief: "Click the 'start' button and then click on letters. Use the hint to help " +
    "determine the word."},
    {activityName: "GroceryShopper", rules: "You are working to fill the grocery orders " +
    "for customers.  You will be shopping for one customer at a time but you will be asked " +
    "to fill ||nbrTimes|| orders.  Fill the orders completely and correctly.  Good luck!",
    rulesBrief: ""},
    {activityName: "DogWalker", rules: "You have decided to walk dogs in order to make a little " +
    "extra money.  Your goal is to walk the dog to the home.  You should try to avoid the fire " +
    "hydrants that you encounter along the way. You will have ||nbrTimes|| attempts to complete the " +
    "walks. Good luck!", rulesBrief: "Use the arrow keys to walk the dogs back home.  Avoid the fire hydrants."},
  
]
        
export default SideHustleInformationText 
 