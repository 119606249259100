const updateGameInfoGeneralData = {
    currentCash: "55555.55",
    currentMoodPoints: "55555",
    conditionsTable: [
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "electrical-engineer",
          "conditionClass": "career"
        },
        {
          "condName": "test-condition-update-general-info",
          "conditionClass": "game"
        }
      ],
    expenseTable: [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": "2291.83",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Meadow Hills-1 bedroom, 0 roommates",
          "expAmount": 1751,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "115.56",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 380,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Test Expense",
          "expDesc": "This expense is added using the update general info router.",
          "expAmount": 9999,
          "expStartDate": "2024-02",
          "expNbrMonths": "-1"
        }
      ],
    loanTable: [
        {
          "loanType": "Car Loan",
          "loanDesc": "Mid-Size Car - 3 to 5 Years Old",
          "loanAmt": "300.21",
          "loanStartDate": "2022-10",
          "loanNbrMonths": 72,
          "loanTotAmt": 16205,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2022-10",
          "loanNbrMonths": 120,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        },
        {
          "loanType": "Test Loan",
          "loanDesc": "Test loan for the update general info router",
          "loanAmt": "9999.99",
          "loanStartDate": "2024-02",
          "loanNbrMonths": 999,
          "loanTotAmt": "99999.99",
          "loanIntRate": 0.099,
          "loanTerm": 99
        }
      ],
    unlockedFeatures: [
        {
          "featureName": "funStuff",
          "setup": "none",
          "level": 3,
          "lastNotifyDate": "2049-02"
        },
        {
          "featureName": "savings",
          "setup": "active",
          "level": 1,
          "lastNotifyDate": "2033-02"
        },
        {
          "featureName": "creditCard",
          "setup": "active",
          "level": 7,
          "lastNotifyDate": "2049-02"
        },
        {
          "featureName": "testFeatureUpdateGeneral",
          "setup": "active",
          "level": 7,
          "lastNotifyDate": "2024-02"
        }
      ],
    iconTable: [
        {
          "iconName": "gameBasicHelp2",
          "iconCategory": "information",
          "className": "small_sign_thumbnail_img information-sign-position",
          "imageSrc": "gameHelp.png",
          "validInd": true,
          "firstTime": false,
          "additionalInfo": "",
          "dateAdded": "2023/07/27"
        },
        {
          "iconName": "funStuffNewLevel",
          "iconCategory": "goodNews",
          "className": "small_sign_thumbnail_img good-news-funStuff-position",
          "imageSrc": "FunStuff.jpg",
          "validInd": true,
          "firstTime": false
        },
        {
          "iconName": "testUpdageGeneralInfo",
          "iconCategory": "goodNews",
          "className": "small_sign_thumbnail_img good-news-funStuff-position",
          "imageSrc": "FunStuff.jpg",
          "validInd": true,
          "firstTime": false
        }
      ]

}

export default updateGameInfoGeneralData;