const updateEventResponseData = {
    eventID: "65cee7952246ae82ec3aa1ee",
    responses: [
        {
          "btnText": "Full Car Replacement",
          "itemDesc": "Auto Insurance - Full Car Replacement",
          "possibleEffects": [
            {
              "category": "expense",
              "closureText": "You decide to buy coverage that will cover the full car cost if it needs to be replaced.  This will cost $value per month.",
              "on": "Automobile Insurance",
              "type": "flat",
              "duration": "-1",
              "odds": "100",
              "replaceAmtSw": true,
              "triggerCondition": "sports-car-insurance",
              "deleteCondition": "",
              "deleteClass": "",
              "moodPoints": "-3",
              "possibleValues": [
                "180"
              ]
            }
          ]
        },
        {
          "btnText": "Test of update event responses",
          "itemDesc": "Test description",
          "possibleEffects": [
            {
              "category": "cash",
              "closureText": "You have decided to buy insurance that will only cover a part of the car's replacement cost.  This will not change the cost of your current auto insurance.",
              "on": "cash",
              "type": "flat",
              "duration": "1",
              "odds": "100",
              "replaceAmtSw": "false",
              "triggerCondition": "sports-car-insurance",
              "deleteCondition": "",
              "deleteClass": "",
              "moodPoints": "1",
              "possibleValues": [
                "0"
              ]
            }
          ]
        }
      ],
    
}

export default updateEventResponseData;