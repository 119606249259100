const homeInsAmountsDataset =[
  {homeCost: 300000, monthInsAmt: 110},
  {homeCost: 350000, monthInsAmt: 117},
  {homeCost: 400000, monthInsAmt: 124},
  {homeCost: 450000, monthInsAmt: 151},
  {homeCost: 500000, monthInsAmt: 179},
  {homeCost: 550000, monthInsAmt: 200},
  {homeCost: 600000, monthInsAmt: 221},
  {homeCost: 650000, monthInsAmt: 249},
  {homeCost: 700000, monthInsAmt: 277},
  {homeCost: 750000, monthInsAmt: 291},
  {homeCost: 800000, monthInsAmt: 305},
    
 
];

export default homeInsAmountsDataset;