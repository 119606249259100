import "./GameBoardActivities/dragNDrop.css";
import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'

// in background-color... the second color is blank which means it doesn't wrap the item in this color prior to it being moved
const Container = styled.div`
    padding:0px;
    margin-bottom:0px;
    white-space: pre-wrap;
    background-color:${props => (props.isDragging ? 'white' : '')}; 

`
function DragNDropItemsCommon(props) {
    return (
        <Draggable draggableId={props.item.id} index={props.index}>
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}  >
                    
                        <span>  
                                <span>  
                                    <div>    
                                          <img src={props.item.picture} className={props.itemClass} 
                                            alt="itemPicture"/>
                                    </div>
                                </span>
                            
                            <div>    
                                {props.item.content} 
                            </div>
                        </span>
        
                </Container>
            )}
        </Draggable>
    )
}

export default DragNDropItemsCommon
