import { doubleToStringMonthly } from "../../../objects/CommonUse/double_functions";

export const setResponseResultText = (moodPoints, rltnshpPoints, friendName, title, cashAmt) => {
   
        let dialogResultText = "";
        if (cashAmt != undefined){
           let positiveCashAmt = +cashAmt;
           if (+positiveCashAmt < 0){
                positiveCashAmt = -1 * +cashAmt;
           }
           dialogResultText = "";
           if (cashAmt < 0){
                    dialogResultText = "This cost " + doubleToStringMonthly(positiveCashAmt) + "."; 
           }else{
                if (cashAmt > 0){
                    dialogResultText = "This added " + doubleToStringMonthly(positiveCashAmt) + " to your cash.";
                }
           }
        }
        if (moodPoints > 0){
            dialogResultText = dialogResultText + " You have increased your mood score by " + moodPoints + " points. ";
        }else{
            let reducedPoints = -1 * +moodPoints;
            dialogResultText = dialogResultText + " Your mood score has been reduced by " + reducedPoints + " points. ";
        }
        if (rltnshpPoints > 0){
            dialogResultText = dialogResultText + " You are happy that this has " +
              "improved your relationship with " + friendName + ".";
        }else{
            dialogResultText = dialogResultText + " Unfortunately, you think this has " +
              "hurt your relationship with " + friendName + ".";  
        }
                
    return dialogResultText;
   
}
