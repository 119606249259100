import {Component} from 'react';
//import './Special/special.css';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import { standardNumberFormat } from '../../objects/CommonUse/double_functions';


class PlayerProfileDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
    }    
}
  
render() {
     /*  the following code can be turned 'on' to display unlocked feature detail for each player
     this.unlockedFeatures = this.props.playerProfile.unlockedFeatures.map((unlockedFeatures, index) =>
     <UnlockedFeaturesDisplayCard unlockedFeatures={unlockedFeatures}  key={index}  
       index={index}/>
         );
     */
return <>
      <tr>
            <td className="text-left font-weight-bold text-primary text-pointer"
                 onClick ={()=> this.props.onClick(this.props.playerProfile.user)}>   
                 <u>{this.props.playerProfile.user}</u>
            </td>
            <td className="mx-2">
                 {this.props.playerProfile.gameNbrMonths}
            </td>
            <td className="mx-2">
                 {doubleToStringMonthly(this.props.playerProfile.currentCash)}
            </td>
            <td>
                 {doubleToStringMonthly(this.props.playerProfile.monthlyIncome)}
            </td>
            <td>
                 {doubleToStringMonthly(this.props.playerProfile.savingsAmt)}
            </td>
            <td>
                 {doubleToStringMonthly(this.props.playerProfile.monthlySavingsAmt)}
            </td>
            <td>
                 {standardNumberFormat(this.props.playerProfile.moodPoints)}
            </td>
                    
      </tr>        
      <tr>
          <td className="text-white pt-0"> blank</td>
          <td className="text-left pt-0" colSpan="6">
               <b>Job:</b> {this.props.playerProfile.jobName} 
               &nbsp;&nbsp; <b>Salary:</b>&nbsp; {doubleToStringMonthly(this.props.playerProfile.salary)}
               &nbsp;&nbsp; <b>Nbr Badges Earned:</b>&nbsp; {this.props.playerProfile.nbrBadges}
               
          </td>
      </tr>
      <tr >
          <td className="text-white pt-0"> blank</td>
          <td className="text-left pt-0" colSpan="6">
               <b>Car:</b>&nbsp; {this.props.playerProfile.carName}
               &nbsp;&nbsp; <b>Home:</b> {this.props.playerProfile.homeName}
          </td>
     </tr>
     <tr>
          <td className="text-white pt-0"> blank</td>
          <td className="text-left pt-0" colSpan="6">
               <b>Credit Card Balance:</b>&nbsp;
                    {doubleToStringMonthly(this.props.playerProfile.creditCardBalance)}
               &nbsp;&nbsp; <b>Credit Card Policy:</b>&nbsp;
                 {this.props.playerProfile.creditCardPolicy}
          </td>
      </tr> 
                    {this.unlockedFeatures}
                 
   </>
  }
}

export default PlayerProfileDisplayCard;