import ConfigFile from "../../../datasets/config_file";

export const calculateOneTimeCosts = (homeType, rentAmt, cashAmt, currentBedrooms, profitFromHomeSale,
        newHome, oneTimeCosts) => {
    oneTimeCosts.newFurnitureCost = 0;
    oneTimeCosts.newTools = 0;
    let baseMovingCost = +ConfigFile.homeItems.baseMovingCost;
    let movingCostPerBedroom = +ConfigFile.homeItems.costPerBedroom;
    oneTimeCosts.movingCost = baseMovingCost + (movingCostPerBedroom * +newHome.Bedrooms);
    oneTimeCosts.damageDeposit = 0;
    if (newHome.DownPaymentAmt === undefined){
        newHome.DownPaymentAmt = 0;
    }
    oneTimeCosts.depositRefundAmt = sessionStorage.getItem("ChangeHomeDepositRefund");
    let checkDepositRefund = false;
    if (oneTimeCosts.depositRefundAmt === null){
        checkDepositRefund = true;
        oneTimeCosts.depositRefundAmt = 0;
    }else{
        checkDepositRefund = isNaN(+oneTimeCosts.depositRefundAmt);
    }
    if (homeType === "apartment" && checkDepositRefund === true){ 
        let randomNbr = (Math.floor(Math.random() * 11))/10;
        oneTimeCosts.depositRefundAmt = (rentAmt * randomNbr).toFixed(2);
        sessionStorage.setItem("ChangeHomeDepositRefund", oneTimeCosts.depositRefundAmt);
    }

    let nbrNewBedrooms = +newHome.Bedrooms - +currentBedrooms;  
    if (nbrNewBedrooms > 0){
        oneTimeCosts.newFurnitureCost = 300 * nbrNewBedrooms;
    }

    if (newHome.HomeType === "apartment"){ 
        oneTimeCosts.damageDeposit = newHome.RentAmt;
    }else{
        //  new tools - lawn equip, shovels, hand tools, small power tools, etc. needed for a home
        if (homeType !== "house"){
                oneTimeCosts.newTools = 500;
        } 
    }
    oneTimeCosts.totalOneTimeCost = +oneTimeCosts.movingCost + +oneTimeCosts.newFurnitureCost + 
        +oneTimeCosts.damageDeposit - +oneTimeCosts.depositRefundAmt + oneTimeCosts.newTools + 
        newHome.DownPaymentAmt - profitFromHomeSale;

    newHome.CashAmt = (cashAmt - oneTimeCosts.totalOneTimeCost).toFixed(2);
}
export default calculateOneTimeCosts;