import { calcNbrMonthsBtwnDates } from "../../../objects/CommonUse/nbr_months_btwn_dates_calc";
import {calcRandomNumberWithMax} from "../../../objects/CommonUse/calc_random_number_with_max";

export const determineIfTimeToMeetNewFriend = (friendsTable, nbrFriendsMet, currentGameDate) => {
      let slctFriendIdx = 99;
     
      for (let i=0; i<friendsTable.length; i++){
          if (friendsTable[i].introDate != ""){
              let friendDate = friendsTable[i].introDate;
              let nbrMonthsSinceMet = calcNbrMonthsBtwnDates(friendDate, currentGameDate);
              if (+nbrMonthsSinceMet < 6){
                  slctFriendIdx = i;
              }
          }
      }
      let totalFriends = friendsTable.length;
      if (nbrFriendsMet == 0){
          slctFriendIdx = 0;
      }else{
          // time to meet a new friend - but random could also return 
          // friend that has already been met - which is OK
          if (slctFriendIdx == 99){
             slctFriendIdx = calcRandomNumberWithMax(totalFriends, true);
          }
      };
    
              
    return slctFriendIdx;
   
}
