import ExtraCreditConfigFile from "../../components/extra_credit/Datasets/extra_credit_config_file";

export const dragNDropBefore = (modalTitle, introText, modalSize, buttonType, calculatorType, inputData, cookieNamePfx,
      maxNbrPerColumn, amountSw) => {
//*  
//* Input parameters are as follows:
//*   modalTitle:  (required) - This is the text that appears in the title box of the modal.
//*   introText - This is text that should be displayed before the drag-n-drop boxes.
//*   modalSize - this is used in the "size" parameter of the modal.  If it is not provided, then it will default
//*    to the value in the extraCreditConfig file (currently default is "xl")
//*   buttonType (optional) - This is used to indicate which buttons should be displayed on the modal. If 
//*     not present, it will be pulled from the config file. 
//*   calculatorType 
//*   inputData (required) - This provides the data for the columns and items that is to be
//*     used in the drag-n-drop boxes.  Refer to other 'dataset' components for format.
//*   cookieNamePfx (required-conditional) - This is required if the results from the 'drag
//*     and drop' are going to be graded.
//*   maxPerColumn - This indicates the maximum number of items for each column.  This number is 
//*     applied to all columns except the first column.  (EX: each column can only have 1 item)
//*   amountSw - this indicates if there is a dollar amount that is to be included with the item content
//*

      var outModalSize = ""; 
      var outButtonType = "";
      var outCalculatorType = "";
      var outMaxPerColumn;
      var outAmountSw = false;
            
      let outModalTitle = modalTitle;
      let outIntroText = introText;
      if (modalSize != undefined && modalSize != ""){
            outModalSize = modalSize;
      }else{
            outModalSize = ExtraCreditConfigFile.dragNDropDefaults.modalSize;
      }
      if (buttonType != undefined && buttonType != ""){
            outButtonType = buttonType
      }else{
            outButtonType = ExtraCreditConfigFile.dragNDropDefaults.buttonType;
      }
      if (calculatorType != undefined){ 
            outCalculatorType = calculatorType;
      }
      let outInputData = inputData;
      let outCookieNamePfx = cookieNamePfx;
      if (maxNbrPerColumn != undefined){
            outMaxPerColumn = maxNbrPerColumn;
      }
      if (amountSw != undefined && amountSw != ""){
            outAmountSw = amountSw;
      }

      let dragNDropObj = {modalTitle: outModalTitle, introText: outIntroText, modalSize: outModalSize,
            buttonType: outButtonType, calculatorType: outCalculatorType, inputData: outInputData, 
            cookieNamePfx: outCookieNamePfx, maxNbrPerColumn: outMaxPerColumn, amountSw: outAmountSw}
              
   return dragNDropObj;
   
}





