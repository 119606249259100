import calcRandomNumberWithMax from "../../../objects/CommonUse/calc_random_number_with_max";

export const getAuthCodes = (nbrAuthCodesRqstd, currentAuthCodeArr)=>{ 

   let nbrAuthCodesFound = 0;
   if (currentAuthCodeArr === undefined){
      currentAuthCodeArr = [];
   }
   let authCodeArr = [];
   while (nbrAuthCodesRqstd > nbrAuthCodesFound){
         let newAuthCode = calcRandomNumberWithMax(9, false, 4);
         let authCodeDup = authCodeArr.includes(newAuthCode);
         let currentAuthCodeDup = currentAuthCodeArr.includes(newAuthCode);
         if (authCodeDup === false && currentAuthCodeDup === false){
              authCodeArr.push(newAuthCode);
              nbrAuthCodesFound += 1;
         }
   }
   
   return authCodeArr;
   
}
export default getAuthCodes;

