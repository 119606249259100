import "./SharedComponents/sharedComponents.css"
import {Component} from 'react';

class HomeButtonCard extends Component {
    photosArr = [];

    constructor(props) {
        super(props);
    }
           
    render() {
    return <>
        <div className="text-center">
            <button className="btn btn-primary w-75 text-center mt-2"
                onClick={() => this.props.onClick(this.props.slctHome)}>
                    {this.props.slctHome.description}
            </button>    
        </div>
    </>
    }
}


export default HomeButtonCard;