const WordPuzzleDataset = [
      {listType: "homeBuilding", titleText: "All words are tools", wordListArr: [
         {word: "drill", hint: "You would use this to make a hole in a board."},
         {word: "pliers", hint: "Pull a small tack out of a board with these."},
         {word: "hammer", hint: "You would use this to hit the nail on a head."},
         {word: "chisel", hint: "Use this if you need to make hinges fit into a door."},
         {word: "belt sander", hint: "Could use this to smooth a very rough board."},
         {word: "hand plane", hint: "Reduce the width of a door by 1/4 inch with this tool." },
         {word: "ladder", hint: "It can help you get on the roof."},
         {word: "screwdriver", hint: "Regular and phillips head are two types of these."},
         {word: "wrench", hint: "Used to loosen or tighten a nut."},
         {word: "level", hint: "Used to ensure a board does not have a slope."},
         {word: "phillips head", hint: "One type of screwdriver, named after its inventor."},
         {word: "jigsaw", hint: "Used to do complicated cuts in boards.  Also type of puzzle."},
         {word: "tape measure", hint: "Used to determine the length of something."},
         {word: "hand saw", hint: "Used to cut a board in half."},
         {word: "clamps", hint: "Helps to keep something in place while the glue dries."},
         {word: "flashlight", hint: "Makes it easier to see what you are working on."},
      ]},
      {listType: "homeBuildingMaterials", titleText: "All words are building materials.", wordListArr: [
         {word: "window", hint: "You can see through this."},
         {word: "shingle", hint: "This is often used to put a roof on a house."},
         {word: "board", hint: "These are made from trees."},
         {word: "doorway", hint: "You walk in and out of these from one room to another."},
         {word: "bricks", hint: "A mason is usually required to build a wall with these."},
         {word: "shutter", hint: "This goes on the outside of a window."},
         {word: "windowsill", hint: "This is part of a window."},
         {word: "nails", hint: "Can use this to fasten two boards together."},
         {word: "bolts", hint: "These are used with nuts to fasten things together."},
         {word: "screws", hint: "This is often a good alternative to nails."},
         {word: "drywall", hint: "This was invented as an alternative to plaster walls."},
         {word: "cement", hint: "Add gravel, sand and water to make this into a very surface (when dry)."},
         {word: "baseboard", hint: "Trim that goes between the floor and wall."},
         {word: "light switch", hint: "Use this to turn a lamp on in a room."},
         {word: "paint", hint: "This can change the color of a room."},
         {word: "pvc pipe", hint: "Plumbers often use this for channeling the water under sinks."},
      ]},   

      {listType: "homeBuildingJobs", titleText: "All words jobs required to build a home.", wordListArr: [
         {word: "architect", hint: "A person who determines the plan and design for the home."},
         {word: "plumber", hint: "Designs and connects the pipes in the home."},
         {word: "electrician", hint: "Determines how to wire the home."},
         {word: "bricklayer", hint: "This person makes walls out of blocks."},
         {word: "roofer", hint: "Puts the shingles on top of the house."},
         {word: "scaffolder", hint: "This person makes it possible for people to easily work on high walls."},
         {word: "carpenter", hint: "Responsible for designing and assembling wooden structures."},
         {word: "inspector", hint: "A person who checks to make sure everything is done according to code."},
         {word: "structural engineer", hint: "This person ensures the building will stay standing."},
         {word: "superintendent", hint: "Responsible for hiring, managing schedules, ensuring safety."},
         {word: "safety manager", hint: "This person makes sure that people are wearing the right gear."},
         {word: "equipment operator", hint: "This person drives construction vehicles including cranes."},
         {word: "foreperson", hint: "On site construction supervisor."},
         {word: "welder", hint: "Assemble, fuse, attach and fabricate metal structures."},
         {word: "painter", hint: "This person changes the colors of rooms and outside walls."},
      ]},   

      {listType: "nouns", titleText: "All words will be nouns.", wordListArr: [
         {word: "television", hint: "You watch me."},
         {word: "window", hint: "You can see through me."},
         {word: "telephone", hint: "You might use this to talk to friends."},
         {word: "light fixture", hint: "You would need this in a dark room."},
         {word: "Abraham Lincoln", hint: "He was president of the United States."},
         {word: "family room", hint: "This is a place that may be in your home."},
         {word: "picture", hint: "You might post one of these online."},
         {word: "candy bar", hint: "Something good to eat."},
         {word: "cookie", hint: "Something that might come with nuts."},
         {word: "river", hint: "Where water flows."},
         {word: "meadow", hint: "Green pasture."},
         {word: "bald eagle", hint: "Predatory bird."},
         {word: "turkey", hint: "Think thanksgiving."},
         {word: "waterfall", hint: "A favorite thing to go see."},
         {word: "ski slope", hint: "You might find one of these in Colorado."},
         {word: "professor", hint: "Someone who knows a lot."}
         ]},

      {listType: "verbs",   titleText:  "All words will be verbs.", wordListArr: [
         {word: "run", hint: "Move fast."},
         {word: "walk", hint: "Go outdoors and enjoy the scenery."},
         {word: "skip", hint: "A fun way to move forward."},
         {word: "somersault", hint: "Roll around."},
         {word: "sneeze", hint: "Do you have a cold?"},
         {word: "yawn", hint: "Are you sleepy?"},
         {word: "jump", hint: "Get off the ground."},
         {word: "calculate", hint: "Determine the value of something using numbers."},
         {word: "abbreviate", hint: "Shorten a word."},
         {word: "breath", hint: "Bring in air and let it go."},
         {word: "blows", hint: "What wind does."},
         {word: "stretch", hint: "Make something longer."},
         {word: "illuminate", hint: "Bring light to an area."},
         {word: "sprinkle", hint: "Dust something with sugar."},
         {word: "douse", hint: "Put water on a fire."},
         {word: "speak", hint: "How people usually communicate."}
         ]},

      {listType: "adjectives",   titleText:  "All words will be adjectives.", wordListArr: [
         {word: "violet", hint: "A shade of purple."},
         {word: "beautiful", hint: "Someone who is very pretty."},
         {word: "perfect", hint: "Something that does not have any flaws."},
         {word: "active", hint: "Someone who moves around a lot."},
         {word: "adventurous", hint: "This person might like to explore a cave."},
         {word: "breakable", hint: "Something that is made of glass is this."},
         {word: "confused", hint: "I really don't understand."},
         {word: "difficult", hint: "Something that is very hard to do."},
         {word: "embarassed", hint: "Another word for being ashamed."},
         {word: "fantastic", hint: "Another word for wonderful."},
         {word: "grumpy", hint: "One of the seven dwarfs."},
         {word: "inexpensive", hint: "Something that doesn't cost too much."},
         {word: "mysterious", hint: "It is very difficult to determine."},
         {word: "outrageous", hint: "Beyond the bounds of good taste."},
         {word: "sparkling", hint: "Something that has a lot seems to glitter."},
         {word: "victorious", hint: "The team that won the championship."}
         ]},

      {listType: "adverbs",   titleText:  "All words will be adverbs.", wordListArr: [
         {word: "bravely", hint: "Going into a cave without fear."},
         {word: "cheerfully", hint: "Doing something happily."},
         {word: "eagerly", hint: "Being anxious to do something."},
         {word: "fortunately", hint: "Similar to luckily."},
         {word: "gracefully", hint: "A way to describe a person who is dancing."},
         {word: "honestly", hint: "Assuring someone that you are speaking the truth."},
         {word: "obediently", hint: "The way a well-trained dog responds to a command."},
         {word: "hopelessly", hint: "Feeling like it really isn't possible."},
         {word: "painfully", hint: "It really hurts to do this."},
         {word: "eventually", hint: "At some point it may happen."},
         {word: "occasionally", hint: "Once in a great while."},
         {word: "regularly", hint: "It happens every day."},
         {word: "powerfully", hint: "A way of expressing opinions in a strong way."},
         {word: "boastfully", hint: "An attitude of bragging."},
         {word: "brightly", hint: "One might say a star shines in this way."},
         {word: "sometimes", hint: "Things that happen from time to time."}
         ]},
   

      {listType: "ingredients", titleText: "All words will be common ingredients used in cooking.", wordListArr: [
         {word: "tomato", hint: "Main ingredient in pasta sauce."},
         {word: "onion", hint: "It just might make you cry."},
         {word: "chicken stock", hint: "Basic ingredient when making chicken noodle soup."},
         {word: "mushroom", hint: "Popular fungus used in cooking."},
         {word: "celery", hint: "Crunchy green vegetable."},
         {word: "carrots", hint: "Good for the eyes"},
         {word: "egg noodles", hint: "Key ingredient in chicken noodle soup."},
         {word: "spices", hint: "These make foods taste even better."},
         {word: "olive oil", hint: "This is often used to create salad dressing."},
         {word: "salt and pepper", hint: "These are a very common pair of spices."},
         {word: "green pepper", hint: "A common cooking vegetable that is mostly hollow in the middle."},
         {word: "lemons", hint: "This is often used when fixing fish."},
         {word: "potatoes", hint: "This is a staple and adds as starch to a meal."},
         {word: "oregano", hint: "This is a common spice used in italian dishes."},
         {word: "parmesan", hint: "A type of cheese that is often used on top of pasta."},
         {word: "couscous", hint: "This is a good alternative to rice."}
      ]},
      {listType: "kitchenUtensils", titleText: "All words will be kitchen utensils or appliances used in cooking.", wordListArr: [
         {word: "whisk", hint: "Used to beat eggs and other ingredients."},
         {word: "spatula", hint: "Helps to lift cooked food off a griddle."},
         {word: "stand mixer", hint: "Used to blend ingredients for baked items such as cookies."},
         {word: "chef knife", hint: "A sharp implement used to chop ingredients into small pieces."},
         {word: "frying pan", hint: "Used to brown onions, green peppers and other ingredients."},
         {word: "double boiler", hint: "A cooking pot that is usually used when melting chocolate."},
         {word: "convection oven", hint: "A way to bake food that surrounds the pan with heat."},
         {word: "refrigerator", hint: "A place to put food in order to keep it cold."},
         {word: "air fryer", hint: "A small appliance that can be used to make fried foods without using oil."},
         {word: "can opener", hint: "A device used to help open black olive containers."},
         {word: "crock pot", hint: "A special type of slow cooker."},
         {word: "cutting board", hint: "This protects your counters when cutting vegetables."},
         {word: "food processor", hint: "Can be used to finely chop ingredients."},
         {word: "dutch oven", hint: "A heavy, wide, iron pot with a tight fitting lid."},
         {word: "toaster oven", hint: "An electrical appliance that can be used as a toaster."},
      ]},
      {listType: "bakingIngredients", titleText: "All words will be ingredients often used in baking.", wordListArr: [
         {word: "flour", hint: "A main ingredient in most cookies and cakes."},
         {word: "chocolate chips", hint: "Used in many cookie recipes and also great in brownies."},
         {word: "brown sugar", hint: "An sweet ingredient."},
         {word: "powdered sugar", hint: "Required for most frosting recipes."},
         {word: "cinnamon", hint: "A baking spice that smells really good."},
         {word: "vanilla", hint: "An extract prepared from the cured seedpods of a plant."},
         {word: "egg whites", hint: "Used to make meringue topping."},
         {word: "cornstarch", hint: "Often used to make gravy from meat juices."},
         {word: "baking powder", hint: "An ingredient in biscuits that help them rise."},
         {word: "applesauce", hint: "This can be a great alternative to sugar."},
         {word: "cocoa powder", hint: "This is a good way to add a chocolate flavor."},
         {word: "molasses", hint: "Made from sugar beets, used to make gingerbread."},
         {word: "pecans", hint: "A type of nut often used in cookies."},
         {word: "butter", hint: "A basic dairy ingredient."},
         {word: "raisins", hint: "Dried fruit used in many cookie recipes."},
      ]}

   ];
export default WordPuzzleDataset;