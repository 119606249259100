import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import "./Admin/admin.css";
import Button from 'react-bootstrap/Button';
import JobListDisplayCard from './job_list_display_card';

class JobListModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      
      }    
}


sortProfileReport(){
  this.sortParm = document.getElementById("sortParm").value;
  switch (this.sortParm){
      case "default":
        this.props.jobListArr.sort((a, b) => (a.jobName > b.jobName) ? 1 : -1)
        break;
      case "startSalary":
        this.props.jobListArr.sort((a, b) => (+a.minSalary > +b.minSalary) ? 1 : -1)
        break;
      case "avgSalary":
        this.props.jobListArr.sort((a, b) => (+a.avgSalary > +b.avgSalary) ? 1 : -1)
        break;
      case "maxSalary":
        this.props.jobListArr.sort((a, b) => (+a.maxSalary > +b.maxSalary) ? 1 : -1)
        break;
      case "degreeType":
        this.props.jobListArr.sort((a, b) => (a.degreeType > b.degreeType) ? 1 : -1)
        break;
      default:
        break;
  }
  this.setState({waitState: false});
}
   
render() {
        this.jobListInfo = this.props.jobListArr.map((jobInfo, index) =>
          <JobListDisplayCard jobInfo={jobInfo}  key={index}  
           index={index}/>
              );
              
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="lg" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Job List - Number of Jobs: &nbsp;
              {this.props.jobListArr.length}&nbsp;&nbsp;
              <label className="ml-5 font-weight-bold" htmlFor="player">Sort by:&nbsp;&nbsp;</label>
                      <select className="btn btn-sm btn-outline-secondary dropdown-toggle" id="sortParm"
                            onChange={() => this.sortProfileReport()}>
                        <option value="default">Job Name (default)</option>
                        <option value="startSalary">Starting Salary</option>
                        <option value="avgSalary">Average Salary</option>
                        <option value="maxSalary">Maximum Salary</option>
                        <option value="degreeType">Degree Type</option>
                      </select>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
              <span>
              <table className="table m-0">
                <tbody>
                  <tr className="header-sticky">
                    <th>Job Name</th>
                    <th>Starting Salary</th>
                    <th>Average Annual Salary</th>
                    <th>Maximum Annual Salary</th>
                    <th>Degree Type</th>
                    <th>Degree Specialty</th>
                  </tr>
                  {this.jobListInfo}
                </tbody>
              </table>
                             
              </span>
                  
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
             
              <Button variant="primary"  onClick={() => this.props.onClose()}>
                   Close
              </Button>
              
        </Modal.Footer>
       
    </Modal>
    
  </>
  }
}

export default JobListModal;