import "./GameBoardActivities/gameBoardActivities.css";
import {Component} from 'react';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import { Button } from "react-bootstrap";
import "./GameBoardActivities/gameBoardActivities.css";

class SelectActivityCard extends Component {
    constructor(props) { 
        super(props);
        this.state = {}       
    }

    render() {
        let defaultImgSrc = "./assets/Activities/" + this.props.activityInfo.activityCategory + "/" +
            this.props.activityInfo.imageName;
        switch (this.props.activityInfo.activityCategory){
            case "SideHustle":
                this.amountText = "Earn up to " + doubleToStringMonthly(this.props.activityInfo.maxEarnedAmt) + ".";
                break;
            case "Volunteer":
                this.amountText = "Earn up to " + this.props.activityInfo.maxMoodPoints + " mood points";
                break;
            case "HaveFun":
                this.amountText = "Earn up to " + this.props.activityInfo.estMaxMoodPoints + " mood points. "
                this.amountText2 = "Maximum cost  " +  doubleToStringMonthly(this.props.activityInfo.maxCost) + ".";
                break; 
            case "Vacation":
                this.amountText = "Earn up to " + this.props.activityInfo.maxMoodPoints + " mood points. Maximum cost " + 
                doubleToStringMonthly(this.props.activityInfo.maxCost) + ".";
                break; 
            default:
                break;
        }
        this.textClass = this.props.textSize + " mb-0 mt-2 font-weight-bold";
        this.textClass2 = this.props.textSize + " mb-0";
        this.imageClass = "activity_thumbnail_img";
        this.btnClass = "mx-2";
        if (this.props.screenSize === "narrow" || this.props.screenSize === "medium"){
            this.imageClass = this.imageClass + "_small";
        }
          
    return <>
        <div className="card mx-4 my-2 drop-shadow" >
        <div className="card-header text-left">
        
            <span className="m-0 p-0">
                <span className="row w-100 mx-0">
                    <div className="col-sm-4 p-0 text-left">
                            <img src={defaultImgSrc} className={this.imageClass} alt="activityPicture"/>
                     </div>
                     <div className="col-sm-6 ml-2">
                           <p className={this.textClass}>{this.props.activityInfo.activityName}</p>
                           <p className={this.textClass2}>{this.amountText}</p>
                           <p className={this.textClass2}>{this.amountText2}</p>
                          
                     </div>
                     <div className="col-sm-1  text-right">
                        <Button size="sm" variant="primary"
                            onClick={this.props.onSelected.bind(this, this.props.activityInfo)}> 
                                Select 
                        </Button>
                    </div>   
                </span>                      
            </span>
                
        </div>
        </div>
    </>
    }
}

export default SelectActivityCard;