export const editPassword = (password, confirmPassword) => {
  
    let passwordErrorTable = [
        {field: "password", error: false, message: "", class: "font-weight-bold"},
        {field: "confirmPassword", error: false, message: "", class: "font-weight-bold"}
    ]
    let passwordValid = "";
    let message = "";
    if (password == ""){
        passwordValid = false;
        message = "Password cannot be blank.";
        passwordErrorTable[0].error = true;
        passwordErrorTable[0].message = message;
    }else{
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        let specialCharacter = specialChars.test(password);
     //   const capitalLetters = /[ABCDEFGHIJKLMNOPQRSTUVWXYZ]/;
     //   let capitalLetter = capitalLetters.test(password);
     //   const numbers = /[0123456789]/;
     //   let numberFound = numbers.test(password);
        if (specialCharacter && password.length > 7)
        {
            // password is valid
        }else{
            passwordValid = false;
            message = "Password must be at least 8 characters long and must " +
                "contain at least one special character."
            passwordErrorTable[0].error = true;
            passwordErrorTable[0].message = message;
            passwordErrorTable[0].class = passwordErrorTable[0].class + " text-danger";
        }
    }

    if (confirmPassword != password){
            passwordValid = false;
            message = "Confirm password does not match password";
            passwordErrorTable[1].error = true;
            passwordErrorTable[1].message = message;
            passwordErrorTable[1].class = passwordErrorTable[0].class + " text-danger";
    }
    return passwordErrorTable;
}
export default editPassword;