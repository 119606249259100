import { Component } from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DragNDropModal from './drag_n_drop_modal';
import SavingMoneyChallenge1InitialText from '../../dragNDropText/saving_money_challenge1_initial_text';
import SavingMoneyChallenge1HintsText from './text/saving_money_challenge1_hints_text';
import SavingMoneyChallenge1Dataset from './Datasets/saving_money_challenge1_dataset';
import SavingsOverTimeModal from './savings_over_time_modal';
import SavingMoneyChallenge2InitialText from '../../dragNDropText/saving_money_challenge2_initial_text';
import SavingMoneyChallenge2HintsText from './text/saving_money_challenge2_hints_text';
import SavingMoneyChallenge1ResultsText from './text/saving_money_challenge1_results_text';
import SavingMoneyChallenge2Dataset from './Datasets/saving_money_challenge2_dataset';
import { createColumnArray } from '../../objects/DragNDrop/createColumnArray_function';
import { calcColumnScore } from '../../objects/DragNDrop/calcColumnScore_function';
import { createResultsArray } from './objects/createResultsArray_function';
import { dragNDropBefore } from '../../objects/DragNDrop/drag_n_drop_before_function';
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';

class SavingMoney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callRender: false,
            openSw: false,
            openCalculatorSw: false,
        }
        this.dragNDrop = {};
        this.modalMessage = "";
        this.hintTable = [];
        this.hintNbr = 0;
        this.displayAlert = false;
        this.tableObject = [];
        this.tableNbrRows = 0;
        this.freezeFlag = false;
        this.currentStatus = "";
        this.calculatorStatus = "DisplayCalculator";
        this.challenge1Score = 0;
        this.challenge2Score = 0;
        this.averageScore = 0;
        this.col1Text = "";
        this.calculateOnly = false;
        this.getSavingMoneyChallenge1Score();
    }


    getSavingMoneyChallenge1Score() {
        var challenge1Score = parseFloat(sessionStorage.getItem("SavingMoneyChallenge1Score"));
        if (!isNaN(challenge1Score)) {
             this.calculatorStatus = "";
             this.currentStatus = "DisplayChallenge2";
             this.challenge1Score = challenge1Score;
        }
    }

    openSavingsCalculator() {
        this.calculatorStatus = "OpenCalculator"
        if ( this.currentStatus === "OpenChallenge1" ||
             this.currentStatus === "OpenChallenge2") {
             this.calculateOnly = true;
        } else {
             this.calculateOnly = false;
        }
        this.setState({openCalculatorSw: true});
    };

    closeSavingsCalculator() {
        this.calculatorStatus = "CloseCalculator";
        if ( this.currentStatus !== "OpenChallenge1") {
            this.currentStatus =  "DisplayChallenge1";
        }
        this.setState({openCalculatorSw: true});
    }

    openSavingMoneyChallenge1() {
         this.currentStatus = "OpenChallenge1";
         this.dragNDrop = dragNDropBefore("Categorize the Savings Scenarios",
            SavingMoneyChallenge1InitialText, "", "DoneCalculator", "Savings",
            SavingMoneyChallenge1Dataset, "SavingMoneyChallenge1", 1);

         this.hintTable = SavingMoneyChallenge1HintsText;
         this.setState({openSw: true});
    }

    closeSavingMoneyChallenge1() {
        this.currentStatus = "DisplayChallenge1";
        this.setState({openSw: false});
    }

    saveSavingMoneyChallenge1() {
        var columnArray = createColumnArray( this.dragNDrop.cookieNamePfx, 5);
         this.challenge2Score = calcColumnScore(columnArray);
        if ( this.challenge2Score > 85) {
             this.modalMessage = "";
             this.tableObject = createResultsArray(columnArray);
            sessionStorage.setItem("SavingMoneyChallenge1Score",  this.challenge1Score);
             this.dragNDrop.introText = SavingMoneyChallenge1ResultsText;
             this.col1Text = [
                "Total Amt Out-of-Pocket", "Total Amt of Savings"
            ]
             this.tableNbrRows = 2;
             this.dragNDrop.buttonType = "Continue";
             this.freezeFlag = true;
             this.setState({openSw: true});
        } else {
             this.modalMessage = "Not quite right... Try again.";
             this.displayAlert = true
            if ( this.hintNbr <  this.hintTable.length) {
                 this.hintNbr += 1;
            }
            this.setState({callRender: true});
        }
    }

    finishChallenge1() {
        this.currentStatus = "DisplayChallenge2";
        this.freezeFlag = false;
        this.setState({openSw: false});
    }

    openSavingMoneyChallenge2() {
         this.currentStatus = "OpenChallenge2";
          this.modalMessage = "";
         this.dragNDrop = dragNDropBefore("Review Saving Advice",
            SavingMoneyChallenge2InitialText, "", "DoneCalculator", "Savings",
            SavingMoneyChallenge2Dataset, "SavingMoneyChallenge2", 99)
         this.tableNbrRows = 0;
         this.hintTable = SavingMoneyChallenge2HintsText;
         this.setState({openSw: true});
    }

    saveSavingMoneyChallenge2() {
        var columnArray = createColumnArray( this.dragNDrop.cookieNamePfx, 2);
        if (columnArray[1].items.length === 0) {
             this.modalMessage = "Drag items from the Considerations column to the Advantages column."
        } else {
             this.challenge1Score = calcColumnScore(columnArray);
            if ( this.challenge1Score > 90) {
                this.finishSavingMoney();
            } else {
                 this.modalMessage = "Not quite right... Try again.";
                 this.displayAlert = true;
                if ( this.hintNbr <  this.hintTable.length) {
                     this.hintNbr += 1;
                }
                this.setState({callRender: true});
            }

        }
    }

    closeSavingMoneyChallenge2() {
        this.currentStatus = "DisplayChallenge2";
        this.setState({openSw: true});
    }



    closeAlert() {
        this.displayAlert = false;
        this.setState({callRender: true});
    }

    render() {

        this.gutters = "";
        if ( this.dragNDrop.modalSize === "xl" &&
            (this.props.screenSize === "narrow" || this.props.screenSize === "medium")) {
            this.gutters = "modal-95";
        }
      
        return <>

            <Modal show={this.props.show} onHide={() => this.props.onClose()} size="med" scrollable="true"
                animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Extra Credit - Saving Money</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    { this.calculatorStatus === "DisplayCalculator" ?
                        <span>
                            <div className="m-4">
                                <h6 className="text-center">Savings Calculator</h6>
                                <p>Click on the "Savings Calculator" button to learn how the savings amount,
                                    and interest rate impact the amount that you can save over a period
                                    of time.  Once you understand the basic concepts, you can tackle the next
                                    challenge exercise that will be available.</p>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary w-75 mt-4"
                                    onClick={() => this.openSavingsCalculator()}>
                                    Savings Calculator
                                </button>
                            </div>
                        </span>
                        :
                        <div></div>
                    }

                    { this.calculatorStatus === "OpenCalculator" ?
                        <SavingsOverTimeModal show={this.state.openCalculatorSw}
                            gutters={this.gutters}
                            onClose={() => this.closeSavingsCalculator()}
                            calculatorSw={ this.calculateOnly} 
                            displayInitialAmt={false}/>
                        :
                        <div></div>
                    }

                    { this.currentStatus === "DisplayChallenge1" ?
                        <span>
                            <div className="m-4">
                                <p>Now that you've had a chance to experiment with the savings calculator,
                                    click on the "Challenge #1" button to check your understanding of
                                    how the key factors affect the amount you can save over time.
                                </p>
                            </div>

                            <div className="text-center">
                                <button className="btn btn-primary w-75 mt-4"
                                    onClick={() => this.openSavingMoneyChallenge1()}>
                                    Saving Money Challenge #1
                                </button>
                            </div>
                        </span>
                        :
                        <div></div>
                    }
                    { this.currentStatus === "OpenChallenge1" ?
                        <DragNDropModal show={this.state.openSw}
                            gutters={this.gutters}
                            onClose={() => this.closeSavingMoneyChallenge1()}
                            onSave={() => this.saveSavingMoneyChallenge1()}
                            onCalculator={() => this.openSavingsCalculator()}
                            onContinue={() => this.finishChallenge1()}
                            closeAlert={() => this.closeAlert()}
                            inputDataset={ this.dragNDrop.inputData}
                            modalSize={ this.dragNDrop.modalSize}
                            modalTitle={ this.dragNDrop.modalTitle}
                            buttonType={ this.dragNDrop.buttonType}
                            calcType={ this.dragNDrop.calculatorType}
                            introText={ this.dragNDrop.introText}
                            cookieNamePfx={ this.dragNDrop.cookieNamePfx}
                            maxNbr={ this.dragNDrop.maxNbrPerColumn}
                            tableNbrRows={ this.tableNbrRows}
                            tableCol1Text={ this.col1Text}
                            tableObjectRow={ this.tableObject}
                            freezeFlag={ this.freezeFlag}
                            message={ this.modalMessage}
                            hintTable={ this.hintTable}
                            hintNbr={ this.hintNbr}
                            displayAlert={ this.displayAlert}
                        />
                        :
                        <div></div>
                    }

                    { this.currentStatus === "DisplayChallenge2" ?
                        <span>
                            <div className="m-4" >
                                <p>Great job!  You successfully completed the first challenge.  Click
                                    on the "Challenge2" button to test your understanding of key
                                    concepts about saving money.
                                </p>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-primary w-75 mt-4"
                                    onClick={() => this.openSavingMoneyChallenge2()}>
                                    Saving Money Challenge #2
                                </button>
                            </div>
                        </span>
                        :
                        <div></div>
                    }
                    { this.currentStatus === "OpenChallenge2" ?
                        <DragNDropModal show={this.state.openSw}
                            gutters={this.gutters}
                            onClose={() => this.closeSavingMoneyChallenge2()}
                            onSave={() => this.saveSavingMoneyChallenge2()}
                            onCalculator={() => this.openSavingsCalculator()}
                            onContinue={() => this.finishChallenge1()}
                            closeAlert={() => this.closeAlert()}
                            inputDataset={ this.dragNDrop.inputData}
                            modalSize={ this.dragNDrop.modalSize}
                            modalTitle={ this.dragNDrop.modalTitle}
                            introText={ this.dragNDrop.introText}
                            cookieNamePfx={ this.dragNDrop.cookieNamePfx}
                            maxNbr={ this.dragNDrop.maxNbrPerColumn}
                            buttonType={ this.dragNDrop.buttonType}
                            calcType={ this.dragNDrop.calculatorType}
                            message={ this.modalMessage}
                            tableNbrRows={ this.tableNbrRows}
                            tableCol1Text={ this.col1Text}
                            tableObjectRow={ this.tableObject}
                            freezeFlag={ this.freezeFlag}
                            hintTable={ this.hintTable}
                            hintNbr={ this.hintNbr}
                            displayAlert={ this.displayAlert}
                        />
                        :
                        <div></div>
                    }
                </Modal.Body>
                <Modal.Footer className="text-right">
                    <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"
                        alt="train" />
                    <Button variant="secondary" onClick={() => this.props.onClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    }

    finishSavingMoney() {
         this.averageScore = (+ this.challenge1Score + + this.challenge2Score) / 2;
        this.calculateSavingMoneyPoints();
        sessionStorage.setItem("previousExtraCreditProcess", "Living Below Means");
        this.props.onCompleted();
    }

    calculateSavingMoneyPoints() {
        var earnedAmt = 0;
        let processName = "SavingMoney";
        let earningsObj = ExtraCreditConfigFile.exercises.find(elem => elem.category === processName);
        earnedAmt = (( this.averageScore * +earningsObj.amt) / 100).toFixed(2);

        const SavingMoneyMeansObj = {
            processName: processName, score:  this.averageScore,
            assetType: earningsObj.type, amount: earnedAmt, level: earningsObj.level
        }

        sessionStorage.setItem("extraCreditCompleted", JSON.stringify(SavingMoneyMeansObj));
    }

}


export default withRouter(SavingMoney);