import {Component} from 'react';
import Button from 'react-bootstrap/Button';


class TestButtonDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
                    };
        this.answerArr = [];
    }
   
    render() {
      if (this.props.nextButton === true){
          this.disabled = false;
      }else{
          this.disabled = true;
      }
        
    return <>
        <span>
            <Button className="mr-4 button-float-right" variant="primary"
                  disabled={this.disabled} onClick={()=>this.props.getNextQuestion(this.props.buttonText)}>
                      {this.props.buttonText}
            </Button>
            {this.props.questionNbr > 1  && this.props.questionNbr !== 99 ?
                <Button className="mr-4 button-float-right" variant="primary"
                    onClick={()=>this.props.getPreviousQuestion()}>
                        Back 
                </Button>
            :
                  null
            }
            {this.props.buttonText2 !== "none" ?
                 <Button className="mr-4 button-float-right" variant="primary"
                     onClick={()=>this.props.getNextQuestion(this.props.buttonText2)}>
                     {this.props.buttonText2}
                </Button>
            :
                null
            }
        </span>
        
    </>
    }

}

export default TestButtonDisplay;