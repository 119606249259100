import {Component} from 'react';

class GameCardFaceDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.lastCard = false;
    }

    render() {
        let firstRight = 120;
        let offset = 15;
        if (this.props.screenSize == "narrow"){
             firstRight = 40;
             offset = 10;
        }
        let rightNbr = firstRight - (offset * parseInt(this.props.index));
        let bottomNbr = 120 - (offset * parseInt(this.props.index));
        let cardCount = this.props.index + 1;
        if (cardCount == this.props.nbrCards && this.props.cardDisabled == false){
            this.lastCard = true;
            let positionObj = {right: rightNbr, bottom: bottomNbr};
            this.props.lastCard(positionObj);
        }
        let right = +rightNbr + "px";
        let bottom = bottomNbr + "px";
   
      
           
    return <>
        <div className="facedown-game-card game-card-blue" style={{right: right, bottom: bottom}} 
            disabled={this.props.cardDisabled} onClick={() => this.props.onClick()}>
         
            <div className="d-flex align-items-center h-100">
                <img src="./assets/sidebar_icon.png" className="w-100"/>
            </div>
        </div> 
    </>
    }
}

export default GameCardFaceDown;    