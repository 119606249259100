export const editConditionConnector = (condConnector, conditionArr, responseLit) => {
  var errorObj="";
  var connectorValid = true;
  var errMessage = "";  

  if (conditionArr !== undefined && conditionArr.length > 0){
        if (condConnector === "OR" || condConnector === "AND"){
            connectorValid = true;
        }else{
             connectorValid = false;
             errMessage = "Connector m/b And or Or when more than 1 Condition";
        }
  }

  if (connectorValid === false){
      errorObj = {fieldClass: responseLit, fieldName: "Condition Connector", fieldValue: condConnector, message: errMessage};
  }
  
  return errorObj;
 
}