import {Component} from 'react';
import "./Special/openEnvelope.css";

class EnvelopeDisplay extends Component {
    constructor(props) {
        super(props);
        this.state={
            displayOpenEnvelope: false,
        }
    this.determineEventCategoryText();
    }

    
determineEventCategoryText(){
    const eventCategoryObj = this.props.eventCategories.find(elem => elem.number === this.props.cardDetails.eventNumber);
    this.eventText = eventCategoryObj.textName;
    this.color = eventCategoryObj.colorCode;
}

    render() {
       let firstLeft = 75;
       let firstTop = 45; 
       let horizontalOffset = 8;
       let verticalOffset = 20;
       let leftPosition = 0;
       let top = 0;
       if (this.props.index <= 3){
          leftPosition = parseInt(this.props.index);
          top = firstTop + "%";
       }else{
            top = firstTop + verticalOffset + "%";
            leftPosition = parseInt(this.props.index - 4);
       }
       let left = firstLeft + (horizontalOffset * leftPosition) + "%";
       this.style = {left: left, top: top, background: this.color};
    return <>
  <div className="container">
 
    {this.state.displayOpenEnvelope  ?
        <span>
             <div className="contact2" style={this.style}>
            <div className="text-center font-weight-bold very-small-text mt-2">{this.eventText}</div>
            <div className="envelope2">
                <div className="top">
                    <div className="outer">
                        <div className="text-white"></div>
                        <div className="inner"></div>
                    </div>
                </div>
                <div className="bottom"></div>
                <div className="left"></div>
                <div className="right"></div>
                <div className="cover"></div>
            </div>
            </div>
     </span>
    :
        <span>
            <div className="contact" style={this.style}>
            <div className="text-center font-weight-bold mt-2"></div>
            <div className="envelope">
                    <div className="top">
                        <div className="outer">
                            <div className="text-white"></div>
                            <div className="inner"></div>
                        </div>
                    </div>
                    <div className="bottom"></div>
                    <div className="left"></div>
                    <div className="right"></div>
                    <div className="cover"></div>
                    <div className="paper">
                    <div className="d-flex align-items-center h-100">
                        <img src="./assets/sidebar_icon.png" 
                                className="w-100"/>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    }

</div>


 
    </>
    }
}

export default EnvelopeDisplay;    