
export const editCondName = (condName, allConditionNames, requiredSw, responseLit, source, fieldName) => {

    var errorObj="";
    var returnObj = "";
    var validCondName = false;
    var saveIdx = undefined;
    var matchIdx = undefined;

    if (fieldName === undefined){
        fieldName = "Condition Name";
    }

    var i;
    if (condName === undefined || condName.length === 0){
        if (requiredSw === true){
            errorObj = {fieldClass: responseLit, fieldName: fieldName, 
                fieldValue: condName, message: "Condition is undefined"}
        }else{
            validCondName = true;
        }
    }else{
        for (i=0; i < allConditionNames.length && validCondName === false; i++){
           let result = condName.localeCompare(allConditionNames[i]);
           if (result === 0){
                validCondName = true;
                matchIdx = i;
            }
            if (result === -1 && saveIdx === undefined){
                saveIdx = i;

            }
        }
        if (validCondName === true){
            //no error message
        }else{
            if (saveIdx === undefined){
                saveIdx = allConditionNames.length - 1;
            }
            errorObj = {fieldClass: responseLit, fieldName: fieldName, 
                fieldValue: condName, message: "Not found on Condition List"}
        }
    }       
        
    if (source === "online"){
        returnObj = {saveIdx: saveIdx, matchIdx: matchIdx, errorArray: errorObj};
    }else{
        returnObj = errorObj;    
    }
    return returnObj;
     
}