const newArchiveActivityTracker =
{
   "activityTable": [
      {
        "activityName": "DogWalker",
        "nbrTimesPlayed": 1,
        "lastTimePlayed": "2023-12",
        "level": 1
      }
    ],
    "gameID": "88888888",
    "dateAdded": "2023-12",
    "category": "SideHustle",
    "dateArchived": "2023/12/06",
    "__v": 0
  }

export default newArchiveActivityTracker;