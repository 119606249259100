const friendVolunteerActivityDetailDialogDataset = 
        [
                {activityPreference: "2", 
                        comments: ["OK, now that you've completed some, can we go?", 
                                   "I'm really bored, can we leave?", 
                                   "This is kinda lame, maybe I'll just wait for you in the car.",
                                   "This place smells kind of funny to me.",
                                   "I'm really tired of standing on my feet.",
                        ]},
                {activityPreference: "3", 
                        comments: ["You know, this isn't so bad.",
                                   "What do you want to do when we're done with this?.",
                                   "This is OK, but I'm hoping we'll be done pretty soon.",
                                   "They have a pretty efficient operation here, don't you think?",
                        ]},
                {activityPreference: "4",
                        comments: ["This is kind of fun.  Time is going by so quickly.", 
                                   "I think I have a system so I'm getting faster.", 
                                   "This is really fun, thanks for asking me to come along.",
                                   "I could do this all day long. How about you?",
                                   "It makes me feel really good when I do volunteer work."
                        ]},
               
        ]

 
export default friendVolunteerActivityDetailDialogDataset;