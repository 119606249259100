export const calcNbrMonthsBtwnDates = (startDate, endDate) => {
     var yearMonthVals = [];
     yearMonthVals = startDate.split('-'); 
     var startYear = +yearMonthVals[0];
     var startMonth = +yearMonthVals[1];
     yearMonthVals = endDate.split('-'); 
     var endYear = +yearMonthVals[0];
     var endMonth = +yearMonthVals[1];   
     var nbrYearsDiff = endYear - startYear;  
     var nbrMonthsDiff = 0
     if (startMonth > endMonth){
          nbrYearsDiff -= 1;
          nbrMonthsDiff = (12 - startMonth) + endMonth;
     }else{
          nbrMonthsDiff = endMonth - startMonth;
     }
     nbrMonthsDiff = (nbrYearsDiff * 12) + nbrMonthsDiff;
         
     return nbrMonthsDiff;           
 }

