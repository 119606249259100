import {Component} from 'react';
import { screenSizeFunction } from '../../objects/CommonUse/screen_size_function';
import "./GameViews/spinningBadge.css";

class BadgesRibbon extends Component {
    constructor(props) {
      super(props); 
      this.state={
          
      }
      this.setBadgePositions();
      this.setBadgeAttributes();
    }

    setBadgePositions(){
        let initial = this.props.initialLeft;
        let offset = 45;
        let left = initial + (this.props.index * offset) + "px";
        this.style={position: "absolute", top: this.props.initialTop + "px", left: left};
    }

    setBadgeAttributes(){
        this.imageURL = "./assets/Badges/" + this.props.badgeInfo.pictureName;
    }
   
    render() { 
                           
    return <>
        <div className="" style={this.style}>
            {this.props.badgeInfo.badgeEarned ?
              <img  
                  src={this.imageURL} 
                  className="badgeImageSmall cursor-is-pointer" alt={'badge'} 
                  onClick={() => this.props.openBadge(this.props.index)}
              />
            :
                <img  
                    src={this.imageURL} 
                    className="badgeImageSmall badgeDisabled" alt={'badge'} 
                />
            }
        </div>
    </>
  }

}

 
export default BadgesRibbon;