import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { findRemoveTableItem} from "../../objects/AssetsExpenses/find_remove_table_item";
import ConfigFile from '../../datasets/config_file';
import TwoColumnTableCard from './two_column_table_card';
import ThreeColumnTableCard from './three_column_table_card';
import ChangeHomeText from './text/change_home_text';
import FriendDisplayModal from '../shared-components/friend_display_modal';
import HomePurchaseText from './text/home_purchase_text';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';
import determineMoodPoints from '../../objects/CommonUse/determine_mood_points';
import createMoodPointArr from '../shared-components/objects/create_mood_point_arr';
import HomeDownPaymentText from './text/home_downpayment_text';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import { buildMonthlyAmountsArr } from './objectsHome/build_monthly_amounts_arr';
import { buildOneTimeCostArr } from './objectsHome/build_one_time_cost_arr';
import { calculateNewMonthlyPaymentAmt } from './objectsHome/calculate_new_monthly_payment_amt';
import calculateOneTimeCosts from './objectsHome/calculate_one_time_costs';

class HomeChangeModal extends Component {
  constructor(props) {
    super(props);
    this.gameInfo = this.props.gameInfo();
    this.state={
         gameDataSw: false,
         homeDataSw: false,
         calculationsCompleteSw: false,
         amtErrorsFound: false,
         allowSubmitBtn: true,
         modalType: "trainVideo",
         downPaymentText: HomeDownPaymentText,
         downPaymentTextExpanded: false,
         openFriendModal: false,
         timeout: 0,
         headers: setHeadersForFetch(),
         subProcess: "homeChangeModal",
         environment: Config.dataURL.currentEnv,
    };   
    this.gameData = null;
    this.monthlyCostArr = [];
    this.oneTimeCostArr = [];
    this.totalMonthlyCost = 0;
    this.downPaymentMax = 0;
    this.downPaymentChanged = false;
    this.currentHome = {HomeId: "", CashAmt: 0, RentAmt: "", RentInsAmt: "", HomeInsAmt: "",
            HomeHOAAmt: "", FullRentAmt: "", Roommates: 0, Bedrooms: 0, HomeType: "",
            RoommateRentAmt: 0, RoommateShareOfMortgage: 0, MortgageAmt: "", HomeFullCost: 0,
            PMIMonthly: "", MonthlyLoanAmt: 0, totLoanAmt: 0, GameDate: ""};
    this.newHome = {HomeId: "", CashAmt: 0, RentAmt: 0, RentInsAmt: 0, HomeInsAmt: 0,
            HomeHOAAmt: 0, FullRentAmt: 0, Roommates: 0, Bedrooms: 0, HomeType: "",
            RoommateRentAmt: 0, RoommateShareOfMortgage: 0, MortgageAmt: "", HomeFullCost: 0,
            PMIMonthly: "", MonthlyLoanAmt: 0, totLoanAmt: 0, HomeLevel: "", DownPaymentAmt: 0,
            DownPaymentPct: 0};
    this.oneTimeCosts = {newFurnitureCost: 0, newTools: 0, movingCost: 0,
                damageDeposit: 0, depositRefundAmt: 0, totalOneTimeCost: 0, profitFromHomeSale: 0, 
                downPaymentAmt: 0}
    this.getCurrentHomeData(); 
  }

  calculateAmounts(){
      this.changeType = sessionStorage.getItem("homeChangeType");  
      this.downPaymentMax = +this.gameInfo.gameData.currentCash + +this.props.homeProfit;
      if (this.gameInfo.savingsInfo.totalSavings !== undefined){
            this.downPaymentMax += +this.gameInfo.savingsInfo.totalSavings;
      }
      if (this.changeType !== "roommate-change"){
        this.getNewHomeData();
      }
      this.getGameData();
      if (this.changeType === "roommate-change"){
            this.newHome.Roommates = sessionStorage.getItem("homeRoommates");
            this.newHome.HomeId = this.currentHome.HomeId;
            if (this.currentHome.HomeType === "apartment"){
                let currentRentObj = findRemoveTableItem(this.gameInfo.gameData.expenseTable, 
                         "rent", false);
                if (currentRentObj.expAmount !== undefined){
                    this.currentHome.FullRentAmt = +currentRentObj.expAmount * 
                            (+this.currentHome.Roommates + 1);
                    this.currentHome.RoommateRentAmt = +this.currentHome.FullRentAmt - 
                             +currentRentObj.expAmount;
                }else{
                    this.currentHome.FullRentAmt = 0;
                }
                this.newHome.FullRentAmt = this.homeDataCurrent.rent;
            }
      }
      
      this.selectionOK = true;
      if (this.currentHome.HomeId === this.newHome.HomeId){ 
          if (this.currentHome.Roommates === this.newHome.Roommates) {  
             this.sameRoommateSw = true;
             this.selectionOK = false;
             this.setState({modalType: "SameHouse"});
          }else{
             this.sameRoommateSw = false;
             if (this.changeType !== "roommate-change"){
                this.setState({modalType: "SameHouse"});
                this.selectionOK = false;
             }
          }
      }
      if (this.selectionOK === true){  
            if (this.currentHome.HomeType === "apartment"){
                this.getCurrentRent(); 
            }
            this.processHomeChange();
            this.prepareInfoForModal(); 
            this.editAmounts();
            if (this.amtError === false){
                if (+this.newHome.DownPaymentAmt > 0  ||
                    this.newHome.HomeType === "apartment" ||
                    this.changeType === "roommate-change"){
                        this.setState({calculationsCompleteSw: true});
                }
            }
            this.setState({modalType: "CalculationsComplete"});
      }
  }

  getNewHomeData(){
        this.newHome.HomeId = sessionStorage.getItem("homeChoice");
        this.newHome.HomeType = sessionStorage.getItem("homeType");
        this.newHome.HomeLevel = sessionStorage.getItem("homeLevel");
        this.newHome.Roommates = sessionStorage.getItem("homeRoommates");
        if (this.newHome.HomeType === "house"){
          this.newHome.HomeFullCost = sessionStorage.getItem("homeCost");
          this.newHome.HomeHOAAmt = sessionStorage.getItem("homeHOA");
        }else{
          this.newHome.FullRentAmt = sessionStorage.getItem("homeCost");
          this.newHome.HomeFullCost = "";
        }
     
        this.newHome.Bedrooms =  sessionStorage.getItem("homeBedrooms");
        this.newHome.HomeName= sessionStorage.getItem("homeName");
       
  }

  getGameData() {
      this.gameData = this.gameInfo.gameData;
      this.assetTable = this.gameData.assetTable;
      this.expenseTable = this.gameData.expenseTable;
      this.loanTable = this.gameData.loanTable;
      this.currentHome.HomeType = this.gameData.homeInfo.homeType;
      this.currentHome.Roommates = this.gameData.homeInfo.roommates;
      this.currentHome.Bedrooms = this.gameData.homeInfo.bedrooms;
      this.currentHome.GameDate = this.gameData.currentGameDate; 
      this.startGameDate = this.gameData.startGameDate;
      this.currentHome.CashAmt = this.gameData.currentCash;
      this.setState({gameDataSw: true});
  }
 
  getCurrentRent(){
      this.currentHome.RentAmt = 0;
      let currentRentObj = findRemoveTableItem(this.expenseTable, "rent", false);
      if (currentRentObj.expAmount !== undefined){
        this.currentHome.RentAmt = +currentRentObj.expAmount;
        if (this.currentHome.Roommates > 0){
            this.currentHome.FullRentAmt =
                 +this.currentHome.RentAmt * (+this.currentHome.Roommates + 1);
        }else{
            this.currentHome.FullRentAmt = +this.currentHome.RentAmt;
        }
      }    
  }

  processHomeChange(){
    this.updateDefaultValues();
    this.profitFromHomeSale = this.props.homeProfit;
    this.homeInflationCost = this.props.currentHomeSellingPrice;
    this.currentHome.totLoanAmt = this.props.currentLoanOutstandingAmt;
    if (this.changeType == "roommate-change"){
        this.newHome.HomeName = this.homeDataCurrent.title;
    }
    calculateNewMonthlyPaymentAmt(this.changeType, this.startGameDate,
        this.gameInfo.gameData.currentCash, this.currentHome, this.newHome, this.newExpenseTable, this.newLoanTable,
        this.newAssetTable);
    if (this.changeType === "roommate-change"){
        this.updateSessionStorage();
    }else{
        // make the following into a function!
        calculateOneTimeCosts(this.currentHome.HomeType, this.currentHome.RentAmt,
            this.currentHome.CashAmt, this.homeDataCurrent.bedrooms, this.profitFromHomeSale, this.newHome,
            this.oneTimeCosts);
    }    
    this.determineHomeChangeMoodPoints();
    this.saveUpdatedValues();
  }  

  updateDefaultValues(){
     this.newExpenseTable = JSON.parse(JSON.stringify(this.expenseTable));
     this.newLoanTable = JSON.parse(JSON.stringify(this.loanTable));
     this.newAssetTable = JSON.parse(JSON.stringify(this.assetTable));
     if (this.changeType === null){
        this.changeType = this.currentHome.HomeType + "-" + this.newHome.HomeType;
     }
  }
    
    getCurrentRoommateRent(){
        if (+this.currentHome.Roommates > 0){
            let shortName = "roommateRent";
            if (+this.currentHome.Roommates > 1){
                shortName = "roommatesRent";
            }
            let currentRentObj = findRemoveTableItem(this.newAssetTable, shortName, true);
            this.currentHome.RoommateShareOfMortgage = currentRentObj.assetAmt;
            
        }else{
            this.currentHome.RoommateShareOfMortgage = 0;
        }
    }
   
  determineHomeChangeMoodPoints(){
    let gameChoiceArr = [];
    let roommateMood = false;
    this.startingMoodPoints = 0;
    if (this.changeType === "roommate-change"){
        if (this.newHome.Roommates > this.currentHome.Roommates){
            this.startingMoodPoints = ConfigFile.gameChoice.roommateChangeMood;
            gameChoiceArr = createMoodPointArr("roommate");
            roommateMood = true;
        }
    }else{
        this.startingMoodPoints = ConfigFile.gameChoice.homeChangeMood;
        let homeChangeType = "home";
        if (this.newHome.Roommates <= this.currentHome.Roommates){
            homeChangeType = "homeNoRoommate"
        }
        gameChoiceArr = createMoodPointArr(homeChangeType);
    }
   
    this.moodPointStartArr = determineMoodPoints(gameChoiceArr);
    this.totalMoodPoints = +this.startingMoodPoints;
    for (let i=0; i<this.moodPointStartArr.length; i++){
         this.totalMoodPoints += +this.moodPointStartArr[i].number;
    }
    this.determineMoodPointText(roommateMood);
    sessionStorage.setItem("homeChangeMoodPoints", this.totalMoodPoints);
  }

  determineMoodPointText(roommateMood){
    if (this.changeType === "roommate-change"){
        if (roommateMood === false){
            this.moodPointText = "You have reduced the number of roommates that you will have. " +
                "This will not increase your mood score."
        }else{
            this.moodPointText = "Increasing the number of roommates will increase your mood score."
        }
    }else{
        if (this.totalMoodPoints === 0){
            this.moodPointText = "You are excited to be changing your home but the choices " +
                "that you have made will not increase your mood score.";
        }else{
            this.moodPointText = "You are very excited to be moving to a new home.  This " +
                "will increase your mood score."
        }
    }
  }

   updateSessionStorage(){
        sessionStorage.setItem("homeChoice", this.currentHome.HomeId);
        sessionStorage.setItem("homeType", this.currentHome.HomeType);
        sessionStorage.setItem("homeBedrooms", this.currentHome.Bedrooms);
        let homePhotosArr = this.homeDataCurrent.additionalPhotosArr;
        sessionStorage.setItem("homeDirectory", this.homeDataCurrent.directory)
        sessionStorage.setItem("homeAdditionalPhotosArr", JSON.stringify(homePhotosArr));
   }

   saveUpdatedValues(){
        sessionStorage.setItem("changeHomeExpenseObjects", JSON.stringify(this.newExpenseTable));
        sessionStorage.setItem("changeHomeLoanObjects", JSON.stringify(this.newLoanTable));
        sessionStorage.setItem("changeHomeAssetObjects", JSON.stringify(this.newAssetTable));
        sessionStorage.setItem("changeHomeNewCashAmt", this.newHome.CashAmt);
   } 

   editAmounts(){
        this.setState({amtErrorsFound: false});
        this.amountErrorText1 = "";
        this.amountErrorText2 = "";
        this.amtError = false;
        
        if (this.newHome.HomeType === "house" && this.changeType !== "roommate-change"){ 
            if (+this.newHome.DownPaymentAmt === 0){
                this.amountErrorText1 = "Down Payment Amount is required and cannot be 0.";
                this.amtError = true;
            }else{
                let downPaymentPct = 0;
                if (isNaN(this.newHome.DownPaymentAmt)){
                    this.amountErrorText1 = "Down Payment Must be Numeric (no commas or $).";
                    this.amtError = true;
                }else{
                    downPaymentPct = ((+this.newHome.DownPaymentAmt / 
                        (+this.newHome.HomeFullCost - +this.newHome.DownPaymentAmt)) 
                        * 100).toFixed(1);
                    if (+downPaymentPct < 5){
                        this.amountErrorText1 = "Down Payment Amount must be greater than 5%";
                        this.amtError = true;
                    }
                    if (+this.newHome.DownPaymentAmt > +this.downPaymentMax){
                        this.amountErrorText1 = "Down Payment Amount exceeds amount available.";
                        this.amtError = true;
                    }
                }
            }
            if (this.amtError === true){
                this.amountErrorText2 = "Enter/correct the down payment amount and then click " +
                    "the 'Submit' or 'Accept/Continue' button."
                }
        }
        if (this.amtError === false){
            if (+this.currentHome.CashAmt < +this.oneTimeCosts.totalOneTimeCost){
                if (this.newHome.HomeType === "apartment"){
                    this.determineErrorText("notEnoughCash", 1);
                }
                if (this.newHome.HomeType === "house" &&
                        this.newHome.DownPaymentAmt < this.downPaymentMax){
                        this.determineErrorText("transferCashLit1", 1);
                }
                if (this.amtError === true &&
                    this.gameInfo.savingsInfo.totalSavings !== undefined){
                        this.determineErrorText("transferCashLit2", 2);
                }
            }
            let monthlyIncome = this.props.monthlyIncomeMinusHomeCosts;
            if (this.changeType === "roommate-change"){
                monthlyIncome = this.props.monthlyIncomeMinusRoommateAmts;
            }
            if (this.totalMonthlyCost > monthlyIncome){ 
                if (this.changeType === "roommate-change" ||
                    this.newHome.HomeType !== "house"){
                        if (this.changeType === "apartment-apartment" &&
                            this.currentHome.FullRentAmt >= this.newHome.FullRentAmt){
                            this.determineErrorText("monthlyIncomeError", 1)
                        }else{
                            if (this.amtError === false){
                                this.errorType = "warning";
                            }
                            this.determineErrorText("monthlyIncomeErrorOK", 1);
                        }
                }else{ 
                    if (this.newHome.DownPaymentAmt !== 0){
                        this.determineErrorText("loanNotApprovedText", 1)
                    }
                }
            }
        }
        if (this.amtError === true){
             this.setState({amtErrorsFound: true});
        }
        if (this.errorType === "warning"){
            this.setState({calculationsCompleteSw: true});
        }
   }
  
   prepareInfoForModal(){
        const monthlyCostObj = buildMonthlyAmountsArr(this.changeType, this.currentHome, this.newHome,
            this.homeInflationCost);
        this.monthlyCostArr = monthlyCostObj.monthlyCostArr;
        this.totalMonthlyCost = monthlyCostObj.totalMonthlyCost;
        if (this.changeType !== "roommate-change"){
            this.oneTimeCostArr = buildOneTimeCostArr(+this.props.homeProfit, this.oneTimeCosts, 
                this.currentHome, this.newHome );
        }
        this.determineText();
   }
    
    determineText(){
        let changeHomeModalText = ChangeHomeText;
        for (let i=0; i<changeHomeModalText.length; i++){
            if(this.changeType === changeHomeModalText[i].changeHomeType){
                this.changeHomeText = changeHomeModalText[i].text;
                break;
            }
        }
        if (this.changeType === "roommate-change"){
            let roommateText = "You have requested to change the number of roommates from " +
                this.currentHome.Roommates + " to " + this.newHome.Roommates + ". ";
            this.changeHomeText = roommateText + this.changeHomeText;
        }
    }

    determineErrorText(textType, textNbr){
        let homePurchaseText = HomePurchaseText.textTable;
        const textObj =  homePurchaseText.find(elem => elem.textType === textType);
        this.setState({allowSubmitBtn: textObj.allowSubmitBtn});
        this.amtError = true;
        if (textNbr === 1){
            this.amountErrorText1 = textObj.text;
        }else{
            this.amountErrorText2 = textObj.text;
        }
    }
  
  render() { 
     if ((this.state.homeDataSw === true && this.state.modalType !== "CalculationsComplete") ||
        this.downPaymentChanged === true){
            this.calculateAmounts();
            this.downPaymentChanged = false;
     }
 
     if (this.state.modalType === "CalculationsComplete"){
        let infoType = "label-amount-amount";
        this.monthlyAmounts = this.monthlyCostArr.map((monthAmts, key) =>
            <ThreeColumnTableCard key={key} outInfo={monthAmts} 
                infoType={infoType}   />
            );
        infoType = "label-amount"
        this.oneTimeAmounts = this.oneTimeCostArr.map((oneTimeAmts, key) =>
            <TwoColumnTableCard key={key} outInfo={oneTimeAmts} 
                infoType={infoType}  />
            );
        if (this.totalMoodPoints !== this.startingMoodPoints){
            this.moodPointList = this.moodPointStartArr.map((moodPoints,key) =>
                <TwoColumnTableCard key={key} infoType="label-number" outInfo={moodPoints}  />
            );
        }
    }
                       
  return <>

  
    {this.state.modalType === "trainVideo"  ?
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="lg" 
            animation={false}>
        <Modal.Header  className="gameChoiceModalHdrFtrBackground" closeButton>
            <Modal.Title>Home Change Costs</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <span>
                <div className="text-white mt-4">xxxxxx</div>
                    <WaitTimeSpinner />
                <div className="text-white mt-4">xxxxxx</div>
                <div className="text-white mt-4">xxxxxx</div>
                <div className="text-white mt-4">xxxxxx</div>
                <div className="text-white mt-4">xxxxxx</div>
          </span>
        </Modal.Body>
        </Modal>
    :
      null  
    }

    {this.state.modalType === "CalculationsComplete" ?
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="lg" scrollable="true"
            animation={false}>
        <Modal.Header  className="gameChoiceModalHdrFtrBackground" closeButton>
            <Modal.Title>Home Change Costs</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
                <div>
                    <div className="font-weight-bold text-center">
                        <div>New Home Choice: </div>
                        <div> 
                            {this.newHome.HomeName} - &nbsp;&nbsp;{this.newHome.Roommates} roommate(s)
                        </div>
                    </div>
                    <div className="mt-2">
                        {this.changeHomeText}
                    </div>
                    {this.state.calculationsCompleteSw ?
                        <div className="mt-2">Click the 'Continue/Accept' button to accept the 
                            terms of this new home choice.
                        </div> 
                    :   
                        <span>
                            {this.newHome.HomeType === "house" ?
                               <div className="mt-2">Enter a downpayment amount and then click the 
                                    "Submit" button to calculate amounts. 
                                </div>
                            :
                                <div className="mt-2">Click the "close" button to make
                                    another home selection or click "Return to GameBoard" to 
                                    cancel the roommate change and continue playing the game
                                </div>
                            }
                        </span>
                    }
                </div>
                {this.newHome.HomeType === "house" && this.changeType !== "roommate-change" ?
                  
                    <div className="mt-2" >
                   
                        <label className="mt-2 font-weight-bold" htmlFor="downPayment">
                            Down Payment Amt:&nbsp;&nbsp;</label>
                    <span>
                    {this.state.downPaymentTextExpanded ?
                        <div className="m-2 text-left">
                            {this.state.downPaymentText}  &nbsp;&nbsp;&nbsp;
                            <Button variant="outline-info" size="sm"
                                onClick={() => this.collapseDownPaymentText()}>
                                    Collapse
                            </Button>
                        </div>
                    :                       
                        <span>
                            <Button variant="outline-info" size="sm"
                                onClick={() => this.expandDownPaymentText()}>
                                    What's This?
                            </Button>
                        </span>
                    }
                    </span>
                    <div>
                        <div><b>Amount Available for Down Payment: &nbsp;&nbsp;</b>
                            {doubleToStringMonthly(this.downPaymentMax)}&nbsp;&nbsp;&nbsp;&nbsp;
                            <b>Current Cash:</b>&nbsp;&nbsp; {doubleToStringMonthly(this.gameInfo.gameData.currentCash)}
                        </div>
                        <div>(Amount Available for Down Payment is Current Cash plus
                            savings plus profit from sale of current home.)</div>
                        <input type="text" className="col-med" id="downPayment" name="downPayment"
                            onChange={(val) => this.handleDownPaymentChange(val.target.value)}>
                            </input>
                        <span>&nbsp;&nbsp;&nbsp;{this.newHome.DownPaymentPct}%</span> 
                       </div>
                    </div>
                :
                    null
                }
      
            {this.state.amtErrorsFound  ?
                <span>
                    <div className="text-danger">
                        {this.amountErrorText1}
                    </div>
                    <div className="text-danger mt-1">
                        {this.amountErrorText2}
                    </div>
                </span>
            :
                null
            }
            
            <table className="table table-hover">
                <tbody>
                    <tr>
                        <th className="text-center">Monthly Amounts</th>
                        <th className="text-right px-0">Current Home</th>
                        <th className="text-right px-0">New Home Choice</th>
                    </tr>
                    {this.monthlyAmounts}
                </tbody>
            </table>

            {this.oneTimeCostArr.length > 0 ?
                <div>
                    <div className="text-left font-weight-bold">One-Time Amounts (will reduce Current Cash Amount)</div>
                    <table className="table table-hover">
                        <tbody>
                            {this.oneTimeAmounts}
                        </tbody>
                    </table>
                </div>
            :
                null
            }

            {this.state.openFriendModal ?
            <FriendDisplayModal  show={this.state.openFriendModal} 
                    gameProcess="GameViews"
                    title={this.newHome.HomeName}
                    roommates={this.newHome.Roommates + " roommate(s)"}
                    askAFriendInfo={this.askAFriendInfo}
                    onClose={() => this.closeFriendModal()} 
                    gameInfo = {() => this.props.gameInfo()}
                    />
            :
                null
            }
             <h5>Mood Point Calculation</h5>
             <div>{this.moodPointText}</div>
             {this.totalMoodPoints === this.startingMoodPoints  ?
                <table className="table table-hover">
                    <tbody className="row-100">
                        <tr>
                            <th className="text-left p-0">Mood Point Change</th>
                            <th className="text-right p-0">{this.totalMoodPoints}</th>
                        </tr>
                    </tbody>
                </table>
              :
                <table className="table table-hover">
                  <tbody className="row-100">
                    <tr>
                      <th className="text-left p-0">Description</th>
                      <th className="text-right p-0">Mood Points</th>
                    </tr>
                    <tr>
                      <th className="text-left p-0">Base Home Change Mood Points</th>
                      <th className="text-right p-0">{this.startingMoodPoints}</th>
                    </tr>
                    {this.moodPointList}
                  
                    <tr>
                      <th className="text-left p-0">Mood Point Change</th>
                      <th className="text-right p-0">{this.totalMoodPoints}</th>
                    </tr>
                  </tbody>
                </table>
            }
            
            </Modal.Body>
            <Modal.Footer  className="gameChoiceModalHdrFtrBackground">
                <Button variant="info"  onClick={() => this.displayFriendModal()}>
                    Ask a Friend
                </Button>
               
                {this.state.calculationsCompleteSw ? 
                     <Button variant="primary"  onClick={() => this.confirmHomeChange("Confirm")}>
                         Continue/Accept
                    </Button>
                :
                    <div>
                        {this.state.allowSubmitBtn ?
                            <Button variant="primary"  onClick={() => this.confirmHomeChange("Submit")}>
                                Submit
                            </Button>
                        :
                            null
                        }
                    </div>
                }
                
                <Button variant="secondary"   onClick={() => this.props.onClose()}>
                    Close
                </Button>
                <Button variant="secondary"   onClick={() => this.props.onCancel()}>
                    Return To GameBoard
                </Button>
                
            </Modal.Footer>
        </Modal>
      :
        <div></div>
      }
    </>
  }

    componentDidMount(){
        this._isMounted = true;
    }
    
    displayFriendModal(){
        this.askAFriendInfo = {topic: "homeChange", roommates: this.newHome.Roommates,
            bedRooms: this.newHome.Bedrooms, homeType: this.newHome.HomeType, 
            homeLevel: this.newHome.HomeLevel};
        this.setState({openFriendModal: true});
    }

    closeFriendModal(){
        this.setState({openFriendModal: false});
    }

    
    expandDownPaymentText(){
        this.setState({downPaymentTextExpanded: true});
    }

    collapseDownPaymentText(){
        this.setState({downPaymentTextExpanded: false});
    }

    handleDownPaymentChange(downPaymentAmt){
        this.newHome.DownPaymentAmt = +downPaymentAmt;
        //  the time delay gives time for entry to be complete before doing setstate logic
        if(this.state.timeout) clearTimeout(this.state.timeout);
            this.state.timeout = setTimeout(() => {
                this.downPaymentChanged = true;
                this.setState({allowSubmitBtn: true});
        }, 1000);
    }

    confirmHomeChange(buttonName){
        this.setState({amtErrorsFound: false});
        this.amountErrorText1 = "";
        if (buttonName === "Confirm"){
            this.props.onConfirmHomeChange();
        }else{
            this.setState({modalType: ""});
        }

    }
  
    getCurrentHomeData() {
        this.currentHome.HomeId = this.gameInfo.gameData.homeInfo.homeID;  
        const funcName = "homeInfo";
        const urlParm = "?homeId=" + this.currentHome.HomeId;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,
            {method:'GET', headers: this.state.headers})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.homeDataCurrent = output.body;
                        if (this._isMounted === true){
                            this.setState({homeDataSw: true});
                        }    
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            }
    });
    }
 
}
export default HomeChangeModal;