
export const removeDupsFromMoneyMgmtTest = (testData ) => {
    let noDupsTestData = [];
    for (let i=0; i<testData.length; i++){
        let j = i + 1;
        let skipRecord = false;
        if (j < testData.length){
           if (testData[i].user === testData[j].user){
               const test1 = testData[i];
               const test2 = testData[j];
               const testTime1 = +test1.timeAdded.replace(/:/g, "");
               const testTime2 = +test2.timeAdded.replace(/:/g, "");
               let timeDiff = 0;
               if (testTime1 > testTime2){
                   timeDiff = testTime1 - testTime2;
               }else{
                   timeDiff = testTime2 - testTime1;
               }
               if (test1.dateAdded === test2.dateAdded && timeDiff < 1000){
                  skipRecord = true;
               }
           }
        }
        if (skipRecord === false){
           noDupsTestData.push(testData[i]);
        }
    }
    
    return noDupsTestData;
   
}
export default removeDupsFromMoneyMgmtTest;

