
export const buildCardFromDialog = (friendName, dialog) => {
    let dialogCardObj = "";
    let responses = dialog.responses;
    let responseArr = [];
    let gamePhase = "all";
    
    for (let i=0; i<responses.length; i++){
        let cashAmt = 0;
        if (responses[i].cashAmt != undefined){
            cashAmt = responses[i].cashAmt;
        }
        let onValue = "cash";
        if (dialog.on != undefined){
            onValue = dialog.on;
        }
        let responseObj =  {btnText: responses[i].rspText, rltnshpScore: responses[i].rltnPoints,
            closureText: "", category: 'cash', on: onValue, type: 'flat', itemDesc: responses[i].itemDesc,
            duration: '1', replaceAmtSw: 'false', triggerCondition: responses[i].triggerCondition,
            deleteCondition: responses[i].deleteCondition, deleteClass: '', moodPoints: responses[i].moodPoints,
            value: cashAmt};
        responseArr.push(responseObj); 
    }
    dialogCardObj = {cardType: "dialog", friendName: friendName, imageArr: [], selectMonthArr: [], _id: '',
        responses: responseArr, title: dialog.title, description: dialog.text,
        frequency: '', conditionConnector: 'AND', conditionArr: dialog.conditionList,
        skipConditionArr: [], backgroundType: 'home', imageDir: '', 
        dateUpdated: '2022/10/29',  gamePhase: gamePhase, careerTag: '', 
        eventCategory: 'friend',  value: undefined};
           
    return dialogCardObj;
   
}
export default buildCardFromDialog;

