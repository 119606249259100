const LivingBelowChallenge1Dataset = {
    /*  spaces between content in items ensures that content is lined up correctly on the modal
        the column property is used to indicate the correct answer.   */
    items: {
        "item-1": { id: "item-1", content: "It will impress your friends and family.", 
                answer: "column-1" },
        "item-2": { id: "item-2", content: "It will reduce your stress level if you " +
                "don’t have to worry about whether you can pay your bills.", 
                answer: "column-2" },
        "item-3": { id: "item-3", content: "It may help you to begin saving some money each month.",
                answer: "column-2" },
        "item-4": { id: "item-4", content: "It can be very hard to do and everyone loves a challenge.",
                answer: "column-1" },
        "item-5": { id: "item-5", content: "It will make it easier to get a loan.", 
                answer: "column-2" },
        "item-6": { id: "item-6", content: "It is likely that you will not need to panic when you " +
                "encounter unexpected expenses.", 
                answer: "column-2" },   
        "item-7": { id: "item-7", content: "It isn’t really a good idea to live below your means " + 
                "because you have to delay buying things that you want.", 
                answer: "column-1" },     
    },
    columns: {
        "column-1": { id: "column-1", title: "Considerations", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7"] },
        "column-2": { id: "column-2", title: "Advantages of Living Below Your Means", itemIds: [] },
    },
    columnOrder: ["column-1", "column-2",]
}

export default LivingBelowChallenge1Dataset