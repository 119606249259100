import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import isNumeric from '../../objects/CommonUse/checkIfNumeric';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class PlayerProfileParmInputModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      headers: setHeadersForFetch(),
      dataValidSw: true,
      accountLoaded: false,
      userListLoaded: false,
      parmProcessComplete: false,
      waitState: false,
      subProcess: "OtherUpdates",
      environment: Config.dataURL.currentEnv,
    }    
}

editInput(){
    this.setState({dataValidSw: true});
    this.setState({waitState: true});
    this.getFormValues();
    this.editFormFields();
}

getFormValues(){
    this.setState({parmProcessComplete: false});
    this.accountID = document.getElementById("accountID").value;
    this.usernames = document.getElementById("usernames").value;
}

editFormFields(){
    this.dataValidSw = true;
    this.errorMsg = "";
    if (this.accountID === "" && this.usernames === ""){
        this.dataValidSw = false;
        this.errorMsg  = "Must enter either Account ID or User Name List."
    }
    if (this.accountID !== "" && this.usernames !== ""){
        this.dataValidSw = false;
        this.errorMsg  = "Enter either Account ID or User Name List (not both)."
    }
    if (this.dataValidSw === true){
        if (this.accountID !== ""){
            this.editAccountID();
        }
        if (this.usernames !== ""){
            this.editUserNames();
        }
    }
    if (this.dataValidSw === false && this.errorMsg  === ""){
            this.errorMsg  = "Correct errors and re-submit.";
            this.setState({waitState: false});
    }
    if (this.dataValidSw === true){
        this.setState({dataValidSw: true});
    }else{
        this.setState({waitState: false});
    }

   }
 
editAccountID(){
    this.accountIDErrorMsg = "";
    if (isNumeric(this.accountID)){
          this.loadAccount();
    }else{
          this.accountIDErrorMsg = "Account ID must be numeric";
          this.dataValidSw = false;
     }
  }

editUserNames(){
    this.usernames = this.usernames.replace(/\s/g, "");
    this.usernameArr = this.usernames.split(",");
    this.loadUserListById();
}

checkIfAccountFound(){
    if (this.accountFound === false){
        this.accountIDErrorMsg = "Account ID not Found";
        this.dataValidSw = false;
        this.errorMsg  = "Correct errors and re-submit."
    }else{
        this.getUserList();
    }
    this.setState({parmProcessComplete: true});
}

checkIfUserListIsValid(){
    let usernameErrorsArr = []
    this.usernameErrorMsg = "";
    for (let i=0; i<this.usernameArr.length; i++){
           if (this.playerArr.includes(this.usernameArr[i])){
                // username is valid
           }else{
                usernameErrorsArr.push(this.usernameArr[i]);
           }
    }

    if (usernameErrorsArr.length > 0){
        this.setState({dataValidSw: false});
        this.userNameErrorMsg = "Usernames invalid: " + usernameErrorsArr.join();
        this.errorMsg  = "Correct errors and re-submit."
    }else{
        this.props.parmsComplete(this.playerArr);
    }
    this.setState({parmProcessComplete: true});
}


loadAccount(){
    const funcName = "loadAccount";
    const urlParm = "?accountNbr=" + this.accountID + "&test=test";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri, 
        {method:'GET', headers: this.state.headers})
        .then(response => {
 
    if (response.ok){
        response.json().then(data => {
            const output = JSON.parse(data);
            const statusCode = output.statusCode;
            if (statusCode === 200){
                this.accountFound = true;
                this.setState({accountLoaded: true});
            }else{
                if (statusCode === 250){
                    this.accountFound = false;
                    this.accountType = "NotFound";
                    this.setState({accountLoaded: true});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            }
        });
    };
    });
}

getUserList(){
  const funcName = "userCollectionList";
  const urlParm = "?accountNbr=" + this.accountID + "&test=test";
  const uri = buildUrl(this.state.environment, funcName, urlParm);
  fetch(uri,
      {method:'GET', headers: this.state.headers})
      .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode === 200){
                    this.processUserList(output.body);
                    this.props.parmsComplete(this.playerArr);
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    })
      
}

loadUserListById(){
    let usernameArr = (this.usernameArr).join(',');
    this.setState({userListLoaded: false});
    const funcName = "loadUserListById";
    const urlParm = "?userNameArr=" + usernameArr;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.processUserList(output.body);
                        this.setState({userListLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
 }

 processUserList(data) {
    let userArr = data;
    this.playerArr = [];
    for (let i=0; i<userArr.length; i++){
        this.playerArr.push(userArr[i].username); 
        }
}

render() {
    if (this.state.parmProcessComplete === false){
         if (this.state.accountLoaded === true){
            this.checkIfAccountFound();
        }
        if (this.state.userListLoaded === true){
            this.checkIfUserListIsValid();
        }
    }
                           
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="md" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Enter Parameters to Display Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
 
            <div className="text-left">
                  <p>Please complete the fields on this form and then click 
                     'Submit'.</p>
            </div>
            <div>
                <div>
                    <label className="font-weight-bold" htmlFor="accountID">
                        Account Id:&nbsp;&nbsp;</label>
                    <input type="text" className="input-sm" id="accountID" name="accountID"></input> 
                    <div className="text-danger">
                          &nbsp;{this.accountIDErrorMsg}</div>
                </div>
                <h4>......OR.....</h4>

                <div>
                    <label className="mt-4 font-weight-bold" htmlFor="usernames"> 
                        Username List:&nbsp;&nbsp;</label>
                    <input type="text" className="form-control input-lg" id="usernames" name="usernames"></input> 
                    <div className="text-danger">
                          &nbsp;{this.userNameErrorMsg}</div>
                </div>

                <div>
                    When parameters entered are valid, this takes several minutes to load.
                </div>
                {this.state.waitState ?
                    <div className="text-center">
                         <WaitTimeSpinner/>
                          <h5 className="mt-5">This may take a few minutes to load.</h5>
                    </div>
                     
                :
                    null
                }
                    
            </div>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
              {this.state.dataValidSw ?
                <div></div>
              :
                <div className="text-danger">{this.errorMsg }</div>
              }
                         
              <Button variant="primary"  onClick={() => this.editInput()}>
                   Submit
              </Button>
        </Modal.Footer>
    </Modal>
    
  </>
  }
}

export default PlayerProfileParmInputModal;