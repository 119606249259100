import setupConditionsUpdate from './set_up_conditions_update_function';

export const processConditionsTable = (eventConditions, gameData) => {

      let conditionsTable = gameData.conditionsTable;
      // decrement duration in the conditions table - go thru backwards so that splice doesn't mess up the index
      let maxIndx = conditionsTable.length - 1;
      for (let i=maxIndx; i>=0; i--){
            if (conditionsTable[i].duration != undefined){
                if (+conditionsTable[i].duration > 0){
                     conditionsTable[i].duration -= 1;   
                }
                if (+conditionsTable[i].duration == 0){
                     conditionsTable.splice(i,1);
                }
            }
      }

      // add 'monthly' conditions if valid
      var conditionArr = [];
      var conditionFind = undefined;
      for (let i=0; i<eventConditions.length; i++){
          let condition = eventConditions[i];
          if (condition.triggeredBy == "monthly"){
               // check to determine if condition is already on the conditionTable
              conditionFind = conditionsTable.find(elem => elem.condName === condition.condName);
              if (conditionFind == undefined){
                  conditionArr.push(condition);
              }
          }
      }
      let conditionClasses = [];
      let newConditionTable = setupConditionsUpdate(gameData, conditionArr, conditionClasses);
      let newCondition = false;
      for (let j=0; j<newConditionTable.length; j++){
          conditionsTable.push(newConditionTable[j]);
          newCondition = true;
      }
               
    return conditionsTable;
   
}
