import EventConditionRelationTypeList from "../datasets/event_condition_relation_type_dataset";

export const editConditionRelationType = (relationType, source, responseLit) => {
  var errorObj="";
  var relationTypeValid = true;
  var errMessage = "";

  if (relationType === undefined){
    relationTypeValid = false;
    errMessage = "Relation Type is Undefined";
  }else{
    if (source === "online"){
      //  currently there is no online entry for this so no edits required.
    }else{
      var relationTypeFound = false;
      if (relationType === ""){
          relationTypeFound = true;
      }else{  
          if (EventConditionRelationTypeList.includes(relationType)){
                  relationTypeFound = true;
          }
      }
      
      if (relationTypeFound === false){
          relationTypeValid = false;
          errMessage = "Relation Type not found in dataset";
      }
    }
  }
  if (relationTypeValid === false){
     errorObj = {fieldClass: responseLit, fieldName: "Relation Type", fieldValue: relationType, 
     message: errMessage};
  }
 
  return errorObj;
 
}