import './GameBoardActivities/puzzleimg.css'
import {Component} from 'react';


class UnderCoverRadioButtons extends Component {
       
    constructor(props) {
        super(props);
        this.state = {
           
        }; 
        this.onChangeValue = this.onChangeValue.bind(this);
     }

     onChangeValue(event) {
            this.props.onSelect(event.target.value);
    }
         
    render() 
    {
        this.label = "   " + this.props.vacationSlct.label
    return <>
        <div onChange={this.onChangeValue}>
            <div className="ml-4">
                <input type="radio" value={this.props.vacationSlct.answer} name="site" /> 
                    {this.label}
            </div>
        </div>
  
    </>
    }

    

}

export default UnderCoverRadioButtons;