import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { isNumeric } from '../../objects/CommonUse/checkIfNumeric';


class UserSignupModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                callRender: false,                                             
              }  
      this.dataValidSw = true; 
      this.errArray = [{textType: "", message:""},
        {textType: "", message:""},
        {textType: "", message:""},
        {textType: "", message:""},
        {textType: "", message: ""}]
  }
 
  editInput(){
    this.dataValidSw = true;
    this.initializeErrorArray();
    this.editFirstName();
    this.editEmailAddress();
    this.editPrimaryRole();
    this.editNbrPrimary();
    this.editNbrPlayers();
    if ( this.dataValidSw === true){
        this.saveUserSignupInfo();
        this.props.onSubmit(this.contactUsObj);
    }else{
        this.setState({callRender: true});
    }
  }

  initializeErrorArray(){
    this.errArray =[{textType: "", message:""},
        {textType: "", message:""},
        {textType: "", message:""},
        {textType: "", message:""},
        {textType: "", message: ""}]
  }
  
  editFirstName(){
    var validFirstName = true;
    this.firstName = document.getElementById("firstName").value;
    if (this.firstName.length === 0){
        validFirstName = false;
    }else{
        // edit to see if name is a string of same character
        const charArray = this.firstName.split('');
        const firstChar = charArray[0];
        var i;
        var counter = 1;
        for (i=1; i<charArray.length; i++){
            if (charArray[i] === firstChar) {
                counter = counter + 1;
            }
        }
        if (counter === charArray.length){
            validFirstName = false;
        }
    }
    if (validFirstName === false){
        this.errArray[0] = {textType: "text-danger", message: "Required"}
         this.dataValidSw = false;
    }
  }

  editEmailAddress() {
    var validEmailAddress = true;
    this.emailAddress = document.getElementById("emailAddress").value;
    if (this.emailAddress.length === 0){
         validEmailAddress = false;
    }else{
        if (this.emailAddress.indexOf('@') <= 0){
          validEmailAddress = false;
        }
    }
    if (validEmailAddress === false){
       this.errArray[1] = {textType: "text-danger", message: "Enter a valid Email Address"};
        this.dataValidSw = false;
    }
  }

  editPrimaryRole(){
      var validPrimaryRole = true;
      this.primaryRole = document.getElementById("primaryRole").value;
      if (this.primaryRole === "none"){
           validPrimaryRole = false;
      }
      if (validPrimaryRole === false){
          this.errArray[2] = {textType: "text-danger", message: "Select a Primary Role"};
           this.dataValidSw = false;
      }
  }

  editNbrPrimary(){
    var validNbrPrimary = true;
    var errorMsg = "";
    this.nbrPrimary = +document.getElementById("nbrPrimary").value;
    let isItNumeric = isNumeric(this.nbrPrimary);
    if (isItNumeric === false){
        validNbrPrimary = false;
        errorMsg = "must be numeric.";
    }
    if (this.nbrPrimary <= 0 || this.nbrPrimary > 5){
        validNbrPrimary = false;
        errorMsg = "must be greater than 0 and less than or equal to 5."
    }
    if (validNbrPrimary === false){
       this.errArray[3] = {textType: "text-danger", message: errorMsg};
        this.dataValidSw = false;
    }
  }
  
  editNbrPlayers(){
    var validNbrPlayers = true;
    var errorMsg = "";
    this.nbrPlayers = +document.getElementById("nbrPlayers").value;
    let isItNumeric = isNumeric(this.nbrPlayers);
    if (isItNumeric === false){
        validNbrPlayers = false;
        errorMsg = "must be numeric.";
    }
    if (this.nbrPlayers <= 0 || this.nbrPlayers > 35){
        validNbrPlayers = false;
        errorMsg = "must be greater than 0 and less than or equal to 35."
    }
    if (validNbrPlayers === false){
       this.errArray[4] = {textType: "text-danger", message: errorMsg};
        this.dataValidSw = false;
    }
  }

  saveUserSignupInfo(){
    this.comment = "Role of Primary Player: " + this.primaryRole + ", Number of Primary Players: " + 
      this.nbrPrimary + ", Number of Players: " + this.nbrPlayers;
    this.contactUsObj = {rqstType: "UserSignon", firstName: this.firstName, emailAddress: this.emailAddress,
      subject: "Requesting Access to the Game", messageText: this.comment};
    }
   
    render() {
                                        
    return <>
      
      <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
            size="med" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title>Request Access to the Game for a Parent or Teacher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="text-left">
               Access to the game is free at this time.  Use the form below to let us know how many
               primary ids you might like and how many player ids you want.  A primary 
               id is for a parent or teacher.  The primary id will be able to view the status of
               the game for all players that are associated with their account.  A player id is 
               for someone who is going to actually play the game.
            </div>
           
            <div className="text-left mt-2">
                <p>Please complete all fields on this form and then click 
                   'Submit'.</p>
                <div >
                   <label className="font-weight-bold" htmlFor="firstName">
                        First Name:&nbsp;&nbsp;</label>
                   <input type="text" className="col-med" id="firstName" name="firstName"></input> 
                   <span className={this.errArray[0].textType}>
                          &nbsp;{this.errArray[0].message}</span>
                </div>
                <div >
                  <label className="font-weight-bold" htmlFor="emailAddress">Email Address:&nbsp;&nbsp;</label>
                  <span className={this.errArray[1].textType}>
                          &nbsp;{this.errArray[1].message}</span>
                  <input type="text" className="col-lg" id="emailAddress" name="emailAddress"></input>   
                </div>
                <div className="mt-4">
                  <label className="font-weight-bold" htmlFor="primaryRole">Primary Role:&nbsp;&nbsp;</label>
                  
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="primaryRole">
                        <option value="none">no primary role slctd</option>
                        <option value="Parent">Parent</option>
                        <option value="Teacher">Teacher</option>
                  </select>
                  <span className={this.errArray[2].textType}>
                          &nbsp;{this.errArray[2].message}</span>
                </div>
                <div >
                   <label className="font-weight-bold" htmlFor="nbrPrimary">
                        Number of Primary Signons Requested (Max of 5):&nbsp;&nbsp;</label>
                   <input type="text" className="col-med" id="nbrPrimary" name="nbrPrimary"></input> 
                   <span className={this.errArray[3].textType}>
                          &nbsp;{this.errArray[3].message}</span>
                </div>
                <div >
                   <label className="font-weight-bold" htmlFor="nbrPlayers">
                        Number of Player Signons Requested (Max of 20):&nbsp;&nbsp;</label>
                   <input type="text" className="col-med" id="nbrPlayers" name="nbrPlayers"></input> 
                   <span className={this.errArray[4].textType}>
                          &nbsp;{this.errArray[4].message}</span>
                </div>
       
            </div>
           </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
          { this.dataValidSw ?
            <div></div>
          :
            <div className="text-danger">Please Correct errors and try-again.</div>
          }
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="primary"  onClick={() => this.editInput()}>
              Submit
          </Button>
        </Modal.Footer>
        </Modal>
      
        </>
    }

   
   
}

export default UserSignupModal;