const newExtraCredit = 
{
  "user": "studentX",
  "userRole": "admin",
  "dateAdded": "2023/06/14",
  "dateUpdated": "2023/06/15",
  "extraCreditTable": [
    {
      "category": "SavingMoney",
      "score": 100,
      "assetType": "salary",
      "amount": "1000.00",
      "level": "2"
    },
    {
      "category": "Priorities",
      "score": 100,
      "assetType": "cash",
      "amount": "2000.00",
      "level": "1"
    },
    {
      "category": "MoneyMgmt",
      "score": 100,
      "assetType": "cash",
      "amount": "1000.00",
      "level": "1"
    }
  ]
}

export default newExtraCredit