const AccountUpdateEmailText =[
    {rqstType: "NewAccount",   
    text:   "Welcome on board the MoneyTrain.  Your new account has been setup.  You " +
            "will need the following information in order to setup your new signon id. "  +
            "Account Number:  ||accountNbr|| " + 
            "Role:  ||primaryRole||   Nbr Rqstd:  ||nbrPrimary||  Auth Codes:  ||primaryAuthCodes|| " +
            "Role: Player  Nbr Rqstd: ||nbrPlayers|| " +
            "After you have created a signon id using the account number and one of the auth codes " +
            "displayed above, you can view all remaining auth codes by going to the Parent/Teacher Page (from main menu) " +
            "and clicking on the 'Related Players Display' button that is displayed on the right of this page." +
            "Please let us know if you have any questions or difficulties creating your new signon id."
    },
    {rqstType: "ChangeAccountPrimary",   
    text:   "Hello from the MoneyTrain Game.  Your account has been updated.  The number of " +
            "primary ids has been increased. The primary role and number that have been added to " +
            "your account is noted below. "  +
            "Account Number:  ||accountNbr|| " + 
            "Role:  ||primaryRole||  Nbr New Ids Rqstd:  ||nbrPrimary|| " +
            "You can view the new auth codes by going to the Parent/Teacher Page (from main menu) " +
            "and clicking on the 'Related Players Display' button that is displayed on the right of this page." +
            "Please let us know if you have any questions or difficulties creating new signon ids."
    },
    {rqstType: "ChangeAccountPlayers",   
    text:   "Hello from the MoneyTrain Game.  Your account has been updated.  The number of " +
            "players have been increased. The number of players that have been added to your account is noted below. "  +
            "Account Number:  ||accountNbr|| " + 
            "Role:  Player  Nbr New Ids Rqstd:  ||nbrPlayers|| " +
            "You can view the new auth codes by going to the Parent/Teacher Page (from main menu) " +
            "and clicking on the 'Related Players Display' button that is displayed on the right of this page." +
            "Please let us know if you have any questions or difficulties creating new signon ids."
    },
    {rqstType: "ChangeAccountPrimaryPlayers",   
    text:   "Hello from the MoneyTrain Game.  Your account has been updated.  The number of " +
            "ids authorized have been increased. The primary role and number of primary ids and " +
            "the number of players that have been added to your account is noted below. "  +
            "Account Number:  ||accountNbr|| " + 
            "||spaces|| Role:  ||primaryRole||  Nbr New Ids Rqstd:  ||nbrPrimary|| " +
            "Role:  Player  Nbr New Ids Rqstd:  ||nbrPlayers|| " +
            "You can view the new auth codes by going to the Parent/Teacher Page (from main menu) " +
            "and clicking on the 'Related Players Display' button that is displayed on the right of this page." +
            "Please let us know if you have any questions or difficulties creating new signon ids."
    },
                      
]

export default AccountUpdateEmailText;