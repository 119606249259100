const newArchiveSavings =
{
    "gameID": "88888888",
    "monthlySavingsAmt": "1000",
    "currentlyActive": true,
    "currentIntRate": "0.03",
    "intRateStartDate": "2042-01",
    "savingsStartDate": "2025-12",
    "totalDeposits": "207000",
    "totalWithdrawals": "0.00",
    "totalOverDraftFees": "0",
    "totalIntAmt": "41465.5714",
    "totalSavings": "248465.57",
    "dateArchived": "2023/06/14",
  }

export default newArchiveSavings;