const newArchivedFunStuff = 
{
   
    "gameID": "88888888",
    "itemArr": [
      {
        "levelName": "Adopt a Pet",
        "category": "Dog",
        "type": "Beagle",
        "name": "Sandy",
        "condName": "own-a-dog",
        "pictureLabel": "Dog",
        "pictureURL": "UnlockedFeatures/dogBeagle.png",
        "backgroundURL": "/conditionalBackground/Back-Yard-Dog.jpg",
        "pictureClass": "friend-game-img",
        "startDate": "2024-03"
      },
      {
        "levelName": "Buy a Horse",
        "category": "Horse",
        "type": "Patches",
        "condName": "own-horse-patches",
        "pictureLabel": "Horse",
        "pictureURL": "UnlockedFeatures/horse3.png",
        "backgroundURL": "/conditionalBackground/horse-patches.jpg",
        "pictureClass": "horse-game-img",
        "startDate": "2026-01"
      }
    ],
   
  }

export default newArchivedFunStuff;