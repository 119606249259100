import friendVolunteerActivityDialogDataset from '../DatasetsDialog/friend_volunteer_activity_dialog';
import friendVacationActivityDialogDataset from '../DatasetsDialog/friend_vacation_activity_dialog';
import friendHaveFunActivityDialogDataset from '../DatasetsDialog/friend_haveFun_activity_dialog';
import calcRandomNumberWithMax from '../../../objects/CommonUse/calc_random_number_with_max';

export const retrieveActivityDialog = (activityCategory) => {
    let dialogInfo = "";
    switch (activityCategory){
        case "Volunteer":
            dialogInfo = friendVolunteerActivityDialogDataset;
            break;
        case "HaveFun":
            dialogInfo = friendHaveFunActivityDialogDataset;
            break;
        case "Vacation":
            dialogInfo = friendVacationActivityDialogDataset;
            break;
    }
  
    let friendActivityDialog = [];
    for (let i=0; i<dialogInfo.length; i++){
         let checkDialogObj = dialogInfo[i];
         let maxIndx = checkDialogObj.commentTable.length - 1;
         let slctIndx = calcRandomNumberWithMax(maxIndx);
         let comment = checkDialogObj.commentTable[slctIndx];
         let acceptDecline = "accept";
         if (checkDialogObj.activityPreference == "1"){
                acceptDecline = "decline";
         }
         let activityDialogObj = {activityPreference: checkDialogObj.activityPreference, comment: comment,
            acceptDecline: acceptDecline};
         friendActivityDialog.push(activityDialogObj);
    }

    return friendActivityDialog;
   
}
export default retrieveActivityDialog;

