import calcRandomNumberWithMax from "../../../objects/CommonUse/calc_random_number_with_max";
import replaceTextInString from "../../../objects/CommonUse/replace_text_in_string";
import { determinePossiblePurchases } from "./determine_possible_purchases";
import EventImageList from "../datasets/event_image_list";

export const determineImageURL = (currentCard, itemArr, backgroundName, screenSize, purchaseArr) => {

    let cardImageObj={};
    let imageDirArr = [];
    if (currentCard.imageDir != undefined && currentCard.imageDir.length > 0){
        imageDirArr = currentCard.imageDir.split('-');
    }
    let purchaseItem = false;

    //  this adds the image of the funStuff item
    if (imageDirArr[0] == "funStuff") {
        let funStuffCategory = imageDirArr[1];
        const funStuffItem = itemArr.find(elem => elem.category === funStuffCategory);
        const cardImageURL = "/assets/" + funStuffItem.pictureURL;
        const imagePosition = "funStuff-position";
        const imageClass = "mx-auto d-block " + funStuffItem.pictureClass;
        const imageName = funStuffItem.name;
        let imageNamePosition = "funStuff-name-style decorative";
        if (funStuffCategory == "Cat"){
            imageNamePosition = imageNamePosition + " funStuff-name-position-cat";
        }else{
            imageNamePosition = imageNamePosition + " funStuff-name-position";
        }
        cardImageObj = {cardImageURL: cardImageURL,
            imagePosition: imagePosition, imageClass: imageClass, imageName: imageName, 
            imageNamePosition: imageNamePosition, cardImage: true, cardImageType: "funStuff",
            purchaseItem: purchaseItem
        };
      
    // this adds the image defined by the image list on the card (such as friendArr)
    } else {
        if (currentCard.imageDir != undefined && currentCard.imageDir.length > 0){
            let itemObj =  EventImageList.find(elem => elem.imageDir === currentCard.imageDir);
            let itemURL = "";
            let itemName = "";
            // the following supports purchase items
            if (imageDirArr[0] === "item"){
                purchaseItem = true;
                if (purchaseArr === undefined){
                    purchaseArr = [];
                }
                let possiblePurchases = determinePossiblePurchases(purchaseArr, currentCard.imageDir);
                let itemIndx = 0
                if (possiblePurchases.length > 1){
                    const nbrItems = possiblePurchases.length;
                    itemIndx = calcRandomNumberWithMax(nbrItems, true);
                }
                if (possiblePurchases[itemIndx].itemName != ""){
                    itemName = possiblePurchases[itemIndx].itemName;
                    const replaceObj = {string: itemName};
                    currentCard.description = 
                        replaceTextInString(currentCard.description, replaceObj, "itemName", "string");
                }
                itemURL = "./assets/Events/" + possiblePurchases[itemIndx].imageURL;
            }else{
                //  the following supports cards that use an image table other than purchase items (ex: puppies)
                let itemIndx = 0
                if (itemObj.options.length > 1){
                    const nbrItems = itemObj.options.length;
                    itemIndx = calcRandomNumberWithMax(nbrItems, true);
                }
                itemURL = "./assets/Events/" + itemObj.options[itemIndx].imageURL;
            }
            let imagePosition = "other-image-position";
            if (screenSize == "narrow"){
                imagePosition = "other-image-position-narrow";
            }
            const imageClass = "mx-auto d-block other-image-size";
            cardImageObj = { cardImageURL: itemURL, imagePosition: imagePosition, imageClass: imageClass,
                itemName: itemName, cardImage: true, cardImageType: "otherImage", purchaseItem: purchaseItem};

        // this adds the image for a pet (if there is one) to the living room
        }else{
            if (backgroundName == "home" && itemArr != undefined){
                for (let i=0; i<itemArr.length; i++){
                    if (itemArr[i].levelName == "Adopt a Pet"){
                        const pictureURL = itemArr[i].pictureURL;
                        const startPos = pictureURL.length - 4;
                        const petURL = pictureURL.substr(0,startPos);
                        const cardImageURL = "./assets/" + petURL + "LyingDown.png";
                        let imageClass = "pet-game-img" + "-" + screenSize;
                        if (itemArr[i].category == "Cat"){
                             imageClass = "pet-game-img-cat" + "-" + screenSize;
                        }
                        const imagePosition = "pet-position";
                        cardImageObj = {cardImageURL: cardImageURL, imagePosition: imagePosition, 
                            imageClass: imageClass, cardImage: true, cardImageType: "funStuff", 
                            purchaseItem: purchaseItem};
                        break;
                    }
                }
            }else{
                cardImageObj = {cardImage: false};
            }
        }
    }           
    return cardImageObj;
   
}
export default determineImageURL;

