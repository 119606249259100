import addToMonthlyCostArr from "./add_to_monthly_cost_arr";

export const mortgageCostsForHome = (currentHome, newHome, monthlyCostArr, homeInflationCost) => {
        monthlyCostArr = addToMonthlyCostArr("Original Cost of Home", currentHome.HomeFullCost,
             newHome.HomeFullCost,  1, "red", monthlyCostArr);
        if (currentHome.HomeType === "house"){
            monthlyCostArr = addToMonthlyCostArr("Market Value of Current Home", homeInflationCost,
                 "", 2,  "black", monthlyCostArr);
        }
        monthlyCostArr = addToMonthlyCostArr("Monthly Mortgage Payment", currentHome.MortgageAmt,
            newHome.MortgageAmt, 4, "red", monthlyCostArr);
        if (+currentHome.PMIMonthly > 0 || +newHome.PMIMonthly > 0){
            let PMIMonthly = currentHome.PMIMonthly;
            if (currentHome.HomeType === "apartment"){
                PMIMonthly = ""
            }
            monthlyCostArr = addToMonthlyCostArr("Mortgage Insurance (PMI) Amt", PMIMonthly,
                    newHome.PMIMonthly, 5, "red", monthlyCostArr);
        }
        if (+currentHome.RoommateShareOfMortgage > 0 ||
            +newHome.RoommateShareOfMortgage > 0) {
            monthlyCostArr = addToMonthlyCostArr("Monthly Rent Paid by Roommate(s)", 
                currentHome.RoommateShareOfMortgage,
                newHome.RoommateShareOfMortgage, 22, "black", monthlyCostArr);
        }
        return monthlyCostArr;
}

export default mortgageCostsForHome;