import {Component} from 'react';
//import './Special/special.css'
import PlayerCommentCard from './player_comment_card';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

class PlayerReviewDetailCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      displayComment: false,
    }    
}
  

toggleDetailComment(playerName){
      this.setState({displayComment: !this.state.displayComment});
}  

render() {
    if (this.state.displayComment === true){
      this.commentList = this.props.comment.playerCommentArr.map((comments, index) =>
          <PlayerCommentCard comment={comments}  key={index}  
            index={index}/>
            );
    }
    if (this.props.comment.ratingDate !== undefined){
        this.ratingDate = reformatDateToDisplay(this.props.comment.ratingDate, "YYYY/MM/DD");
    }else{
        this.ratingDate = "unknown";
    }
return <>
           
            <tr className="thead-light" onClick={() => this.toggleDetailComment()}>
                <td>{this.props.comment.playerName}</td>
                <td>{this.props.comment.avgRating}</td>
                <td>{this.ratingDate}</td>
                {!this.state.displayComment ?
                    <td><FontAwesomeIcon icon={faCaretDown} className="career_card_caret" /> </td>
                :
                    <td><FontAwesomeIcon icon={faCaretRight} className="career_card_caret" /> </td>
                }
            </tr>
              
           
            {this.state.displayComment ?
                <tr>
                  <td colspan="4">
                    <ol>
                        {this.commentList}
                    </ol>
                  </td>
                </tr>
            :
                null
            }
          
                
   </>
  }
}

export default PlayerReviewDetailCard;