export const calcColumnScore = (columnArr) => {     
     var nbrCorrect=0;
     var columnItems = [];
     var nbrInColumn = 0;
     var pctCorrect = 0;
    
     var totPctCorrect = 0;
     var answerColumnsArr = [];
     var columnAlreadyInTable;
     var gradedColumnsArr = [];
     for (let i=0; i<columnArr.length; i++) {
          columnItems=columnArr[i].items;
              nbrInColumn=columnItems.length;
               for (let j=0; j<nbrInColumn; j++) {
                    // if answer in dataset is "column-0" then it can be in either column and possibly
                    // be correct - this is tested further in the calling module
                    if (columnItems[j].answer == columnArr[i].column ||
                         columnItems[j].answer == "column-0") {
                         nbrCorrect = nbrCorrect + 1;
                    }  
                    /* the answersColumnArr ends up containing the names of the columns that should have some
                       items in them.  Columns that are supposed to end up empty messes up the percentage 
                       calculation if these columns are included in the pctCorrect calculation. */
                    columnAlreadyInTable = false;
                    for (let m=0; (m<answerColumnsArr.length && columnAlreadyInTable == false); m++){
                         if (answerColumnsArr[m] == columnItems[j].answer) {
                              columnAlreadyInTable = true
                         }
                    }     
                    if (columnAlreadyInTable == false) {
                         answerColumnsArr.push(columnItems[j].answer)
                    }
               }    
               pctCorrect = ((nbrCorrect /nbrInColumn) * 100);
               let columnObj = {column: columnArr[i].column, pctCorrect: null}  
               if (nbrInColumn > 0){
                    columnObj.pctCorrect = pctCorrect;
               }
               gradedColumnsArr.push(columnObj);
               nbrCorrect = 0;
     }
     var columnPctCorrect = 0;
     for (let i=0; i<gradedColumnsArr.length; i++){
           for (let j=0; j<answerColumnsArr.length; j++){
                if (gradedColumnsArr[i].column == answerColumnsArr[j]){
                  if(gradedColumnsArr[i].pctCorrect == null){
                         gradedColumnsArr[i].pctCorrect = 0;
                   } 
                   break;                          
                }
           }
           if (gradedColumnsArr[i].pctCorrect == null){
                gradedColumnsArr[i].pctCorrect = 100;
           }
           columnPctCorrect = gradedColumnsArr[i].pctCorrect + columnPctCorrect;
     }
     totPctCorrect = (columnPctCorrect / columnArr.length).toFixed(2);
     
     return totPctCorrect;
 }




