
export const getDialogForFriendList = (friendDialog, friendArr ) => {
       
    for (let i=0; i<friendArr.length; i++){
         let friendName = friendArr[i].friendName;   
         let friendDialogObj = friendDialog.find(elem => elem.friendName === friendName);
         if (friendDialogObj == undefined){
                friendArr[i].comment = "I don't have an opinion about your choice."
         }else{
                friendArr[i].comment = friendDialogObj.comment;
         }        
    }
      
    return friendArr;
   
}
export default getDialogForFriendList;

