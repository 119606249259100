export const reformatDateToDisplay = (inputDate, dateType) => {
    let reformatDate = "";
    if (dateType == undefined){
        dateType = "YYYY-MM";
    }
    if (dateType == "YYYY/MM/DD"){
        const dateArr = inputDate.split('/');
        const yearString = dateArr[0];
        const monthString = dateArr[1];
        const dayString = dateArr[2];
        reformatDate = monthString + "/" + dayString + "/" + yearString;   
    }else{
        const yearMonthVals = inputDate.split('-'); 
        const yearString = yearMonthVals[0];
        const monthString = yearMonthVals[1];
        reformatDate = monthString + "/" + yearString;
    }
    return reformatDate;
 }

