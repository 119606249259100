export const processTickForRecurringGameDataObjects = (objectType, objectTable) => {

    var itemNbrMonths;
    var deleteIdxArr = [];
    var i;

        for (i = 0; i < objectTable.length; ++i)
        {
            if (objectType == "asset"){
                itemNbrMonths = objectTable[i].assetNbrMonths
            }
            if (objectType == "expense"){
                itemNbrMonths = objectTable[i].expNbrMonths
            }
            if (objectType == "loan"){
                itemNbrMonths = objectTable[i].loanNbrMonths
            }
            if (+itemNbrMonths === 0)
            {
               deleteIdxArr.push(i);
            }
        }
        
        // Going thru the deleteIdx table backwards in case deleting multiple elements in same table. 
        // This way, the last entry will be removed first which does not impact the index of the
        // previous element that is to be deleted.
        for (i=(deleteIdxArr.length - 1); i >= 0; i--){
            objectTable.splice(deleteIdxArr[i],1);
        }
                 
    return objectTable;
   
}





