


export const buildFunStuffPictureObj = (funStuff) => {
    
    let funStuffPictureObject = {};
    let itemArr = funStuff.itemArr;   
    
    for (let i=0; i<itemArr.length; i++){
            let pictureLabel = "funStuff-" + itemArr[i].pictureLabel;
            let URLValue = "./assets" + itemArr[i].backgroundURL;
            // using pictureLabel with braces results in using the value of the pictureLabel variable as the object name
            funStuffPictureObject = {...funStuffPictureObject, [pictureLabel]: URLValue};
    }
        
    return funStuffPictureObject;
        
}

export default buildFunStuffPictureObj;

