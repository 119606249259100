import React from 'react';
import {Component} from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

class ExportToExcelButton extends Component {
    constructor(props) {
        super(props);
        this.state={
            exportComplete: false
        }
    }

    ExportDataToExcel() {
        let headerArr = [];
        headerArr.push(this.props.rptHeader)
        const worksheet = XLSX.utils.json_to_sheet(this.props.data, {  
            skipHeader: true,
            origin: 0});
        //  const worksheet = XLSX.utils.json_to_sheet(this.props.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `${this.props.fileName}.xlsx`);
        this.setState({exportComplete: true});
    };

    render() {
        if (this.state.exportComplete === true){
            this.props.exportComplete();
        }
    return <>
        <div className="mt-4">
            <button  class="w-100"  onClick ={()=> this.ExportDataToExcel()}>
                Export to Excel</button>
        </div>
    </>;
    };

};


export default ExportToExcelButton;