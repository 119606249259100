import ConfigFile from "../../../datasets/config_file";
import getEventsInfo from "./get_events_info";
import isNumeric from "../../../objects/CommonUse/checkIfNumeric"

export const rptEventsAudit = (eventsTracker, eventsData, rptParameters ) => {
    let playerName = rptParameters.player;
    let gameID = "";
    if (rptParameters.gameID ===  undefined || rptParameters.gameID === ""){
        gameID = "*****"
    }else{
        gameID = rptParameters.gameID;
    }
    var rptArr = [];
    let moodPointMultiplier = ConfigFile.gameBoard.moodPointMultiplier;
    let cashAmt = 0;
    let salaryAmt = 0;
    let saveCashAmt = 0;
    let saveMoodPoints = 0;
    let saveSalary = 0;
    let calcCashAmt = 0;
    let calcSalary = 0;
    let calcMoodPoints = 0;
    let eventAuditString = "";
    let firstRecord = true;
    let selectRecord = false;
    for (let i=0; i<eventsTracker.length; i++){
        if (eventsTracker[i].player === playerName){
            if (gameID ===  "*****" || gameID ===  eventsTracker[i].gameID){
                  selectRecord = true;
            }else{
                  selectRecord = false;
            }
        }else{
            selectRecord = false;
        }
        if (i === 0){
            let eventAuditString = {player: "Player", sequence: "Sequence", process: "Process",
                date: "Date", time: "Time", gameEventNbr: "Game Event Nbr", title: "Event Title",
                eventNbr: "Event Nbr", frequency: "Frequency", condition: "Event Condition",
                skipCondition: "Event Skip Condition", btnText: "Button Text",
                closureTxt: "Closure Text", category: "Category", onField: "On Field", type: "Type",
                duration: "Duration", replaceAmtSw: "Replace Amount Sw", triggerCond: "Trigger Condition",
                deleteCond: "Delete Condition", deleteClass: "Delete Class", value: "Value",
                cashAmt: "CashAmt", moodPointChg: "MoodPoint Change", afterCashAmt: "After Cash Amt",
                calcCashAmt: "Calc Cash Amt", afterSalaryAmt: "After Salary Amt", calcSalaryAmt: "Calc Salary Amt",
                afterMoodPoints: "After Mood Points", calcMoodPoints: "Calc Mood Points", errorFlag: "Error Flag"};
            rptArr.push(eventAuditString);
        
        }
        if (selectRecord ===  true){
            let moodPoints = 0;
            let eventsArray = eventsTracker[i].events;
            for (let j=0; j<eventsArray.length; j++){
                let eventObj = eventsArray[j];
                if (firstRecord ===  true){
                    saveCashAmt = eventObj.afterCash;
                    saveMoodPoints = eventObj.afterMoodPoints;
                    saveSalary = eventObj.afterSalary;
                    firstRecord = false;
                }
                let sequence = j+1;
                let eventTitle = eventsArray[j].event;
                let response = eventsArray[j].response;
                let isMoodPointsNumeric = isNumeric(response.moodPoints);
                if (isMoodPointsNumeric ===  true){
                    moodPoints = +response.moodPoints;
                }else{
                    moodPoints = 0;
                }

                if (eventsTracker[i].process ===  "GameBoard"){
                    moodPoints = +response.moodPoints * moodPointMultiplier;
                }
                cashAmt = 0;
                if (response.on ===  "cash" || response.on ===  "cashOnly"){
                    cashAmt = +response.value;
                }else{
                    if (response.duration ===  1){
                        if (response.type ===  "flat"){
                            cashAmt = +response.value
                        }else{
                            if (response.on ===  "Job Salary"){
                                cashAmt = (+eventObj.afterSalary * +response.value/100).toFixed(2);
                            }
                        }
                    }
                }
                salaryAmt = 0;
                if (response.on ===  "Job Salary" && response.duration !==  1){
                        if (response.type ===  "flat"){
                            salaryAmt = +response.value;
                        }else{
                            salaryAmt = (+saveSalary * +response.value/100).toFixed(2);
                        }
                }
                calcMoodPoints = +saveMoodPoints + +moodPoints;
                calcCashAmt = (+saveCashAmt + +cashAmt).toFixed(2);
                calcSalary = (+saveSalary + +salaryAmt).toFixed(2);
                let errorFlag = "";
                if (calcMoodPoints !==  +eventObj.afterMoodPoints ||
                    calcCashAmt !==  +eventObj.afterCash ||
                    calcSalary !==  +eventObj.afterSalary){
                        errorFlag = "*****"
                 }    

                let eventInfoObj = getEventsInfo(eventTitle, eventsData); 
                eventAuditString = {player: eventsTracker[i].player, sequence:  sequence, process: eventsTracker[i].process,
                    date: eventsTracker[i].date, time: eventsTracker[i].time, gameEventNbr: eventObj.eventCount,
                    title: eventTitle, eventNbr: eventInfoObj.eventNbr, frequency: eventInfoObj.eventFrequency,
                    condition: eventInfoObj.eventCondition, skipCondition: eventInfoObj.eventSkipCondition,
                    btnText: response.btnText, closureTxt: response.closureText, category: response.category,  
                    onField: response.on, type: response.type, duration: response.duration, replaceAmtSw: response.replaceAmtSw,
                    triggerCond: response.triggerCondition, deleteCond: response.deleteCondition, 
                    deleteClass: response.deleteClass, value: response.value, cashAmt: cashAmt, 
                    moodPointChg: moodPoints, afterCashAmt: eventObj.afterCash, calcCashAmt: calcCashAmt, 
                    afterSalaryAmt: eventObj.afterSalary, calcSalaryAmt: calcSalary, afterMoodPoints: eventObj.afterMoodPoints,
                    calcMoodPoints: calcMoodPoints, errorFlag: errorFlag};
                rptArr.push(eventAuditString);
                saveCashAmt = calcCashAmt;
                saveMoodPoints = calcMoodPoints;
                saveSalary = calcSalary;
            } 
        }
    }
    return rptArr;
} 

export default rptEventsAudit;

