
const LoanCalculatorText =
    {introText:
        "Use this loan calculator to experiment with how these factors affect the loan. " +
        "Here are some specific things to understand (scroll down to see the " +
        "result of the selections that you make.)",
    
     numberedListArr: [
        "If the amount of the loan increases, how much does the monthly pay amount increase?",
        "If the interest rate increases or decreases, how does this affect the total amount " +
            "that will be paid on the loan? ",
        "If the loan term (nbr years) of the loan increases, how does this impact both the monthly " +
           "pay amount and the total amount that will be paid on the loan? "
    ]
}

export default LoanCalculatorText;