
const EventInputTemplateDataset =
  {
    "frequency": "60",
    "conditionConnector": "",
    "conditionArr": [],
    "skipConditionArr": [],
    "backgroundType": "home",
    "imageDir": "",
    "careerTag": "",
    "selectMonthArr": ["03","04", "05" ],
    "gamePhase": "2",
    "eventCategory": "other",
    "itemDesc": "",
    "rltnshpScore": "+0",
    "category": "cash",
    "on": "cash",
    "type": "flat",
    "duration": "1",
    "odds": "100",
    "replaceAmtSw": false,
    "triggerCondition": "",
    "deleteCondition": "",
    "deleteClass": "",
    "possibleValues": [ "0" ]
};

export default EventInputTemplateDataset;