
import {calcRandomNumberWithMax} from "../../../objects/CommonUse/calc_random_number_with_max";
import { shuffleArray } from "../../../objects/CommonUse/shuffleArray";
import lifeStyleAssessmentDataset from "../datasets/lifeStyle_assessment_dataset";
import LifeStyleButtonChoices from "../datasets/life_style_button_choices";

export const lifestyleCard = () => {
    let lifeStyleChoices = lifeStyleAssessmentDataset;
    let maxNumber = lifeStyleChoices.length - 1;
    let indx = calcRandomNumberWithMax(maxNumber);
    let lifeStyleCard = JSON.parse(JSON.stringify(lifeStyleChoices[indx]));
    if (lifeStyleCard.buttonCategory != undefined && lifeStyleCard.buttonCategory != ""){
         let buttonList = LifeStyleButtonChoices;
         let buttonArr = [];
         for (let i=0; i<buttonList.length; i++){
              if (buttonList[i].category == lifeStyleCard.buttonCategory){
                   let maxNumber = buttonList[i].options.length - 1;
                   let btnIndx = calcRandomNumberWithMax(maxNumber);
                   let buttonText = buttonList[i].options[btnIndx];
                   let btnActivity = buttonList[i].activity;
                   switch (btnActivity){
                         case 1: 
                              buttonText = buttonText + " $";
                              break;
                         case 2:
                              buttonText = buttonText + " $$";
                              break;
                         case 3:
                              buttonText = buttonText + " $$$";
                              break;
                         case 4:
                              buttonText = buttonText + " $$$$";
                              break;
                   }
                   let btnObject = {type: "LifeStyle", btnText: buttonText, activity: btnActivity};
                   buttonArr.push(btnObject);
              }
         }
         lifeStyleCard.lastCardButtons = buttonArr;
    }
    lifeStyleCard.lastCardButtons = shuffleArray(lifeStyleCard.lastCardButtons);
    
    return lifeStyleCard;
   
}





