const newActivityTracker = {
    "activityTable": [
      {
        "activityName": "Paris",
        "nbrTimesPlayed": 1,
        "lastTimePlayed": "2023-07",
        "level": 1
      }
    ],
    "gameID": "888888888",
    "dateAdded": "2023-07",
    "category": "Test3",
  }

export default newActivityTracker;