const ChoosingCareerChallenge2Dataset = {
   
    items: {
        "item-1": { id: "item-1", content: "You know several people who chose this job.", 
            answer: "column-1", order: 5 },
        "item-2": { id: "item-2", content: "You can make a lot of money if you choose this job.", 
            answer: "column-1", order: 4 },
        "item-3": { id: "item-3", content: "It will be fairly easy to get this job because there is " +
            "a high demand.", 
            answer: "column-1", order: 3 },
        "item-4": { id: "item-4", content: "You believe your skills match the skills required for this job.",
            answer: "column-1", order: 1 },
        "item-5": { id: "item-5", content: "Your friends think this job is really cool.", 
            answer: "column-1", order: 6 },
        "item-6": { id: "item-6", content: "You think this job sounds like it might be fun.", 
            answer: "column-1", order: 2 },
    },

    columns: {
        "column-1": { id: "column-1", title: "Considerations", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6"] },
        
        
    },
    columnOrder: ["column-1"]
}

export default ChoosingCareerChallenge2Dataset