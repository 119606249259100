const updateLoanInfo =
  {loanTable: 
[
    {
      "loanType": "Car Loan",
      "loanDesc": "Mid-Size Car - 3 to 5 Years Old",
      "loanAmt": "300.21",
      "loanStartDate": "2022-10",
      "loanNbrMonths": 72,
      "loanTotAmt": 16205,
      "loanIntRate": 0.1,
      "loanTerm": 6
    },
    {
      "loanType": "College Loan",
      "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
      "loanAmt": "474.65",
      "loanStartDate": "2022-10",
      "loanNbrMonths": 120,
      "loanTotAmt": "48000.00",
      "loanIntRate": 0.035,
      "loanTerm": 10
    },
    {
        "loanType": "Test Loan",
        "loanDesc": "Test loan for the update loans router",
        "loanAmt": "999.99",
        "loanStartDate": "2024-02",
        "loanNbrMonths": 360,
        "loanTotAmt": "99999.99",
        "loanIntRate": 0.065,
        "loanTerm": 10
      }
  ]
}
export default updateLoanInfo;