
export const createAdditionalSpace = (nbrColumns, nbrRows, skipArray, pctSelect, avatarPos) => {
    var nextRow = 0;
    var nextColumn = 0;
    var newSquares = 0;
    var newCell = "";
    var whiteSpace = skipArray;
    var totalWhiteSpace = (((+nbrColumns * +nbrRows) - skipArray.length) * (pctSelect)).toFixed(0);
    let rowColumn = avatarPos.split('|');
    let avatarRow = +rowColumn[0];
    let avatarColumn = +rowColumn[1];
    let bufferArray = [(avatarRow - 1) + "|" + (avatarColumn), (avatarRow +1) + "|" + (avatarColumn),
                       (avatarRow) + "|" + (avatarColumn - 1), (avatarRow) + "|" + (avatarColumn + 1),
                       avatarPos ]
    for (let i=0; (newSquares < totalWhiteSpace && i <= 30); i++){
     
        nextColumn = Math.floor((Math.random() * (nbrColumns - 2)) + 1);
        nextColumn = nextColumn + 1;
        nextRow = Math.floor((Math.random() * (nbrRows - 2)) + 1);
        nextRow = nextRow + 1;
        newCell = nextRow + "|" + nextColumn;
        if (!bufferArray.includes(newCell) &&
            !skipArray.includes(newCell))
        {
            whiteSpace.push(newCell);
            newSquares += 1;
        }
    }
   
return whiteSpace;
}

