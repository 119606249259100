import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import "./Special/special.css";
import Button from 'react-bootstrap/Button';
import EventCategoryList from '../game-views/datasets/event_category_list';
import EventResponseCard from './event_response_card';
import { determineTextMaxLength } from '../admin/editEventCollectionProcesses/determineTextMaxLength';

class EventResponseModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      dataValidSw: true,
      categoryErrorMsg: "",
      jobErrorMsg: "",
      eventCategoryArr: EventCategoryList,
      status: "display",
      newResponseArr: [],
    }    
    this.addUpdateFieldsToResponse();
}

addUpdateFieldsToResponse(){
      for (let i=0; i<this.props.responses.length; i++){
            this.state.newResponseArr[i] = {...this.props.responses[i], newMoodPoints: "", moodPointErrorMsg: "", 
              newClosureText: "", closureTextErrorMsg: "", recordChanged: false, index: i};
      }
}

updateStatus(){
    this.state.status = "update";
}

processResponseChange(changeObj){ 
    switch (changeObj.changeType){
        case "moodPoints":
            this.state.newResponseArr[changeObj.index].newMoodPoints = changeObj.newMoodPoints;
            this.state.newResponseArr[changeObj.index].recordChanged = true;
            break;
        case "closureText": 
            this.state.newResponseArr[changeObj.index].newClosureText = changeObj.newClosureText;
            this.state.newResponseArr[changeObj.index].recordChanged = true;
            break;
    }
}

editResponseInputs(){
    this.dataChanged = false;
    this.dataValid = true;
    for (let i=0; i<this.state.newResponseArr.length; i++){
      let response = this.state.newResponseArr[i];
      if (response.recordChanged == true){
          this.dataChanged = true;
          this.editMoodPoints(response);
          this.editClosureText(response);
      }
    }
    if (this.dataChanged == false){
      for (let i=0; i<this.state.newResponseArr.length; i++){
        this.state.newResponseArr[i].moodPointsErrorMsg = "No Changes Made";
        this.dataValid = false;
      }
  }
  if (this.dataValid == false){
      this.setState({validDataSw: this.dataValid});
  }else{
      this.props.updateResponse(this.state.newResponseArr);
  }
}

editMoodPoints(response){
       response.moodPointsErrorMsg = "";
       if (response.newMoodPoints != ""){
          if (response.newMoodPoints == response.moodPoints){
              this.dataValid = false;
              response.moodPointsErrorMsg  = "New Mood Points same as existing Mood Pts";
          }
          if (+response.newMoodPoints >= -10 &&
              +response.newMoodPoints <= 10){
                    // entry is valid
          }else{
              this.dataValid = false;
              response.moodPointsErrorMsg = "Mood Pts must be between -10 and 10";
          }
        
       } 
 }

editClosureText(response){
  response.closureTextErrorMsg = "";
  if (response.newClosureText != ""){
    
      let buttonList = ["OK"];
      let maxTextLength = determineTextMaxLength(this.props.title, buttonList).closureTextMaxLength;
      if (response.newClosureText.length > maxTextLength ||
        response.closureText == response.newClosureText){
        this.dataValid = false;
        if (response.newClosureText == response.closureText){
           response.closureTextErrorMsg = "New Closure Text same as existing Closure Text";
        }else{
           response.closureTextErrorMsg  = "New Closure Text is more than " + maxTextLength + 
            " characters - (" + response.newClosureText.length + ")"  ;
        }
    }
 } 
}
   
render() {
   
    this.responseInfo = this.state.newResponseArr.map((responseList, index) =>
        <EventResponseCard response={responseList} slctdResponse={this.slctdResponse} 
            key={index} status={this.state.status} 
            displayResponse ={(index)=> this.props.displayResponse(index)}
            processChange ={(changeObj)=> this.processResponseChange(changeObj)} />
            );
      
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="lg" scrollable="true">
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Event Response Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <span>
                  <table id="responseTable">
                        {this.responseInfo}
                  </table>
              </span>
                  
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
             
              {this.state.dataValidSw  && this.state.status == "update" ?
                <Button variant="secondary"  onClick={() => this.editResponseInputs()}>
                    Submit
                </Button>
              :
                  null
              }
              {this.state.status == "display" ?
                <Button variant="secondary"  onClick={() => this.updateStatus()}>
                   Update
                </Button>
              :
                null
              }
               <Button variant="primary"  onClick={() => this.props.onClose()}>
                   Close
              </Button>
              
        </Modal.Footer>
       
    </Modal>
    
  </>
  }
}

export default EventResponseModal;