
export const calculateJobSalary = (assetTable, effectiveDate, baseOnly) => {
 
   var monthlySalary = 0;

   if (baseOnly == undefined){
      baseOnly = false;
   }
    // determine current monthly salary
    for (let i=0; i< assetTable.length; i++){
          if (assetTable[i].assetNbrMonths == -1 &&
              assetTable[i].assetStartDate <= effectiveDate){
                  if ((baseOnly == false) || 
                        (baseOnly == true && assetTable[i].assetItem == "Job Salary")) {
                              monthlySalary += +assetTable[i].assetAmt;
                        }
                  }
    }
            
   return monthlySalary;
   
}
