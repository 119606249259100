import { findRemoveTableItem } from "../../../objects/AssetsExpenses/find_remove_table_item";

export const closeOutCurrentHomeLoan = (loanTable) => {
    let loanObj = findRemoveTableItem(loanTable, "mortgage", true);
    // following is done to clear out the mortgage PMI 
    let loanPMIObj = findRemoveTableItem(loanTable, "mortgagePMI", true);
    let PMIMonthly = 0;
    if (loanPMIObj.loanAmt !== undefined){
        PMIMonthly = loanPMIObj.loanAmt;
    }
    const returnObj = {mortgageAmt: loanObj.loanAmt, homeFullCost: loanObj.loanTotAmt, 
        PMIMonthly: PMIMonthly, loanTable: loanTable}
    return returnObj;
}

export default closeOutCurrentHomeLoan;