import newGameDataObj from '../datasetsLambda/newGameData';
import newLeaderBoardObj from '../datasetsLambda/newLeaderBoardData';
import newEventsCollection from '../datasetsLambda/new_event_collection';
import newActivityTracker from '../datasetsLambda/new_activity_tracker';
import newGameFeedback from '../datasetsLambda/new_game_feedback';
import newArchiveGameData from '../datasetsLambda/new_archive_game_data';
import newArchiveSavings from '../datasetsLambda/new_archive_savings';
import newArchiveCreditCard from '../datasetsLambda/new_archive_credit_card';
import newArchiveActivityTracker from '../datasetsLambda/new_archive_activity_tracker';
import newEventTracker from '../datasetsLambda/new_event_tracker';
import newExtraCredit from '../datasetsLambda/new_extra_credit';
import newLoginUser from '../datasetsLambda/newLoginUser';
import newAccount from '../datasetsLambda/new_account';
import newContactUs from '../datasetsLambda/new_contact_us';
import newPlayerFeedback from '../datasetsLambda/new_player_feedback';
import newArchivedFunStuff from '../datasetsLambda/new_archived_fun_stuff';
import newMoneyMgmtTest from '../datasetsLambda/new_money_mgmt_test';
import updateHomeInfo from '../datasetsLambda/update_home_info';
import updateCarInfo from '../datasetsLambda/update_car_info';
import updateAssetInfo from '../datasetsLambda/update_asset_table';
import updateExpenseInfo from '../datasetsLambda/update_expense_table';
import updateLoanInfo from '../datasetsLambda/update_loan_info';
import updateConditions from '../datasetsLambda/update_conditions';
import updateCashAssetExpenseData from '../datasetsLambda/update_cash_asset_expense';
import updateAllGameData from '../datasetsLambda/update_all_game_data';
import updateCareerInfoData from '../datasetsLambda/update_career_info_data';
import updateUnlockedFeaturesInfo from '../datasetsLambda/update_unlocked_features_info';
import updateSavingsInfo from '../datasetsLambda/update_savings_info';
import updateIconTableData from '../datasetsLambda/update_icon_table';
import updateCreditCardData from '../datasetsLambda/update_credit_card_data';
import updatePlayerFriendInfo from '../datasetsLambda/update_player_friend_info';
import updateActivityTrackerData from '../datasetsLambda/update_activity_tracker_data';
import updateSkillSurveyInfo from '../datasetsLambda/update_skill_survey';
import updateCashMoodFriendData from '../datasetsLambda/update_cash_mood_friend_data';
import updateSpendingHistoryData from '../datasetsLambda/update_spending_history_data';
import updateMoodPointData from '../datasetsLambda/update_mood_point_data';
import updateExtraCreditInfo from '../datasetsLambda/update_extra_credit_info';
import updateAccountData from '../datasetsLambda/update_account_data';
import updateLoginData from '../datasetsLambda/update_login_data';
import updateEventData from '../datasetsLambda/update_event_data';
import updateEventResponseData from '../datasetsLambda/update_event_response_data';
import updateFunStuffData from '../datasetsLambda/update_fun_stuff_data';
import updateGameInfoGeneralData from '../datasetsLambda/update_game_info_general_data';
import updateBadgesEarnedData from '../datasetsLambda/update_badges_earned_data';
import newLogFile from '../datasetsLambda/new_log_file';
import updateGamePurchases from '../datasetsLambda/update_game_purchases';
import updateGamePurchases2 from '../datasetsLambda/update_game_purchases2';
import newArchivedGamePurchases from '../datasetsLambda/new_archived_game_purchases';


export const buildLambdaTestData = (funcName, functionList, testCase) => {
   
    let urlParm = "";
    let testBody = "";
    let method = "";
    const functionObj = functionList.find(elem => elem.funcName === funcName);
    if (functionObj !== undefined){
        const testObj = functionObj.testCases.find(elem => elem.testNbr === testCase);
        if (testObj !== undefined){
            urlParm = testObj.urlParm;
            if (testObj.body !== undefined){
                testBody = testObj.body;
            }
            // the following sets the value of fileName to the variableName label in the funcTest File
            // this.gameInfo.backgroundURL = this.gameInfo.pictureInfo[variableName];
       
            if (testObj.bodyFile !== undefined){
                testBody = [testObj.bodyFile];
                let fileName = testObj.bodyFile;
                switch (fileName){
                    case "newGameObj":
                        testBody = newGameDataObj;
                        if (testObj.missingParm === true){
                            testBody.startingCash = undefined;
                        }
                        break;
                    case "newLeaderBoard":
                        testBody = newLeaderBoardObj;
                        if (testObj.missingParm === true){
                            testBody.moodScore = undefined;
                        }
                        break;
                    case "newEventsCollection":
                                testBody = newEventsCollection;
                                if (testObj.missingParm === true){
                                    testBody.title = undefined;
                                }
                                break;
                            case "newGameFeedback":
                                testBody = newGameFeedback;
                                if (testObj.missingParm === true){
                                    testBody.role = undefined;
                                }
                                break;
                            case "newArchiveGameData":
                                testBody = newArchiveGameData;
                                if (testObj.missingParm === true){
                                   testBody.startingCash = undefined;
                                }
                                break;
                            case "newActivityTracker":
                                testBody = newActivityTracker;
                                if (testObj.missingParm === true){
                                    testBody.category = undefined;
                                }
                                break;
                            case "newArchiveSavings":
                                testBody = newArchiveSavings;
                                if (testObj.missingParm === true){
                                    testBody.monthlySavingsAmt = undefined;
                                }
                                break;
                            case "newArchiveCreditCard":
                                testBody = newArchiveCreditCard;
                                if (testObj.missingParm === true){
                                    testBody.cardLevel = undefined;
                                }
                                break;
                            case "newArchiveActivityTracker":
                                testBody = newArchiveActivityTracker;
                                if (testObj.missingParm === true){
                                    testBody.category = undefined;
                                }
                                break;
                            case "newEventTracker":
                                testBody = newEventTracker;
                                if (testObj.missingParm === true){
                                    testBody.player = undefined;
                                }
                                break;
                            case "newExtraCredit":
                                testBody = newExtraCredit;
                                if (testObj.missingParm === true){
                                    testBody.userRole = undefined;
                                }
                                break;
                            case "newLoginUser":
                                testBody = newLoginUser;
                                if (testObj.missingParm === true){
                                    testBody.role = undefined;
                                }
                                break;
                            case "newAccount":
                                testBody = newAccount;
                                if (testObj.missingParm === true){
                                    testBody.accountNbr = undefined;
                                }    
                                break;
                            case "newContactUs":
                                testBody = newContactUs;
                                if (testObj.missingParm === true){
                                    testBody.toName = undefined;
                                }   
                                break;
                            case "newPlayerFeedback":
                                testBody = newPlayerFeedback;
                                if (testObj.missingParm === true){
                                    testBody.process = undefined;
                                }       
                                break;     
                            case "newArchivedFunStuff":
                                testBody = newArchivedFunStuff;
                                if (testObj.missingParm === true){
                                    testBody.gameID = undefined;
                                }                
                                break;
                            case "newLogFile":
                                testBody = newLogFile;
                                if (testObj.missingParm === true){
                                    testBody.mainProcess = undefined;
                                }                
                                break;
                            case "newMoneyMgmtTest":
                                testBody = newMoneyMgmtTest;
                                if (testObj.missingParm === true){
                                   testBody.testVersion = undefined;
                                }
                                break;
                            case "updateHomeInfo":
                                testBody = updateHomeInfo;
                                if (testObj.missingParm === true){
                                    testBody.currentMoodPoints = undefined;
                                }  
                                break;
                            case "updateCarInfo":
                                testBody = updateCarInfo;
                                if (testObj.missingParm === true){
                                    testBody.currentMoodPoints = undefined;
                                }  
                                break;
                            case "updateAssetInfo":
                                testBody = updateAssetInfo;
                                if (testObj.missingParm === true){
                                    testBody.assetTable = undefined;
                                }  
                                break;
                            case "updateExpenseInfo":
                                testBody = updateExpenseInfo;
                                if (testObj.missingParm === true){
                                    testBody.expenseTable = undefined;
                                }  
                                break;
                            case "updateLoanInfo":
                                testBody = updateLoanInfo;
                                if (testObj.missingParm === true){
                                    testBody.loanTable = undefined;
                                }
                                break;
                            case "updateConditions":
                                testBody = updateConditions;
                                if (testObj.missingParm === true){
                                    testBody.conditionsTable = undefined;
                                }
                                break;
                            case "updateAllGameData":
                                testBody = updateAllGameData;
                                if (testObj.missingParm === true){
                                    testBody.assetTable = undefined;
                                }
                                break;
                            case "updateCashAssetExpenseData":
                                testBody = updateCashAssetExpenseData
                                if (testObj.missingParm === true){
                                    testBody.assetTable = undefined;
                                }
                                break;
                            case "updateSkillSurveyData":
                                testBody = updateSkillSurveyInfo;
                                if (testObj.missingParm === true){
                                    testBody.jobInfo = undefined;
                                }
                                break;
                            case "updateCareerInfoData":
                                testBody = updateCareerInfoData;
                                if (testObj.missingParm === true){
                                    testBody.jobInfo = undefined;
                                }
                                break;
                            case "updateUnlockedFeaturesInfo":
                                testBody = updateUnlockedFeaturesInfo;
                                if (testObj.missingParm === true){
                                    testBody.unlockedFeatures = undefined;
                                }
                                break;
                            case "updateSavingsInfo":
                                testBody = updateSavingsInfo;
                                if (testObj.missingParm === true){
                                    testBody.gameID = undefined;
                                }
                                if (testObj.upsert !== undefined && testObj.upsert === true){
                                    testBody.gameID = "77777777";
                                }
                                break;
                            case "updateIconTableData":
                                testBody = updateIconTableData;
                                if (testObj.missingParm === true){
                                    testBody.iconTable = undefined;
                                }
                                break;
                            case "updateCreditCardData":
                                testBody = updateCreditCardData;
                                if (testObj.missingParm === true){
                                    testBody.gameID = undefined;
                                }
                                if (testObj.upsert !== undefined && testObj.upsert === true){
                                    testBody.gameID = "77777777";
                                }
                                break;
                            case "updatePlayerFriendInfo":
                                testBody = updatePlayerFriendInfo
                                if (testObj.missingParm === true){
                                    testBody.friendList = undefined;
                                }
                                break;
                            case "updateActivityTrackerData":
                                testBody = updateActivityTrackerData;
                                if (testObj.missingParm === true){
                                    testBody.activityTable = undefined;
                                }
                                break;
                            case "updateCashMoodFriendData":
                                testBody = updateCashMoodFriendData;
                                if (testObj.missingParm === true){
                                    testBody.friendList = undefined;
                                }
                                break;
                            case "updateSpendingHistoryData":
                                testBody = updateSpendingHistoryData;
                                if (testObj.missingParm === true){
                                    testBody.spendingHistory = undefined;
                                }
                                break;
                            case "updateMoodPointData":
                                testBody = updateMoodPointData;
                                if (testObj.missingParm === true){
                                    testBody.currentMoodPoints = undefined;
                                }
                                break;
                            case "updateExtraCreditInfo":
                                testBody = updateExtraCreditInfo;
                                if (testObj.missingParm === true){
                                    testBody.extraCreditTable = undefined;
                                }
                                break;
                            case "updateAccountData":
                                testBody = updateAccountData;
                                if (testObj.missingParm === true){
                                    testBody.authCodeArr = undefined;
                                }
                                break;
                            case "updateLoginData":
                                testBody = updateLoginData
                                if (testObj.missingParm === true){
                                    testBody.password = undefined;
                                }
                                break;
                            case "updateEventData":
                                testBody = updateEventData
                                if (testObj.missingParm === true){
                                    testBody.backGroundType = undefined;
                                }
                                break;
                            case "updateEventResponseData":
                                testBody = updateEventResponseData
                                if (testObj.missingParm === true){
                                    testBody.responses = undefined;
                                }
                                break;
                            case "updateFunStuffData":
                                testBody = updateFunStuffData;
                                if (testObj.missingParm === true){
                                    testBody.gameID = undefined;
                                }
                                if (testObj.upsert !== undefined && testObj.upsert === true){
                                    testBody.gameID = "77777777";
                                }
                                break;
                            case "updateGameInfoGeneralData":
                                testBody = updateGameInfoGeneralData;
                                if (testObj.missingParm === true){
                                    testBody.iconTable = undefined;
                                }
                                break;
                            case "updateBadgesEarnedData":
                                testBody = updateBadgesEarnedData;
                                if (testObj.missingParm === true){
                                    testBody.badgesEarned = undefined;
                                }
                                break;
                            case "updateGamePurchases":
                                testBody = updateGamePurchases;
                                if (testObj.missingParm === true){
                                    testBody.purchaseTable = undefined;
                                }
                                break;
                            case "updateGamePurchases2":
                                testBody = updateGamePurchases2;
                                if (testObj.missingParm === true){
                                    testBody.purchasesTable = undefined;
                                }
                                break;
                            case "newArchivedGamePurchases":
                                testBody = newArchivedGamePurchases;
                                if (testObj.missingParm === true){
                                    testBody.gameID = undefined;
                                }                
                                break;
                            default:
                                break;
                            }
                    }
                   }
       
               console.log("body is: ", testBody);
               method = functionObj.method;
               testBody = JSON.stringify(testBody);
        }   
        let returnObj = {urlParm: urlParm, testBody: testBody, method: method};
        return returnObj;
   
}
export default buildLambdaTestData;

