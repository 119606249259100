import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FiveColumnDisplayCard from '../shared-components/five_column_display_card';


class ActivityTrackerAnalysisModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         
    };   
    this.activityTrackerArr = [];
    this.createActivityTrackerExtract();
    this.createActivityAnalysisRpt();
  }

  createActivityTrackerExtract(){
      let beginDate = this.props.dateRange.beginDate;
      let endDate = this.props.dateRange.endDate;
      for (let i=0; i<this.props.activityTrackerData.length; i++){
          const trackerRecord = this.props.activityTrackerData[i];
          if (trackerRecord.dateAdded >= beginDate && trackerRecord.dateAdded <= endDate){
              for (let k=0; k<trackerRecord.activityTable.length; k++){
                  const activityDetail = trackerRecord.activityTable[k];
                  const trackerObj = {category: trackerRecord.category, activityName: activityDetail.activityName,
                    nbrTimesPlayed: activityDetail.nbrTimesPlayed, levelNbr: activityDetail.level, 
                      gameID: trackerRecord.gameID };
                  this.activityTrackerArr.push(trackerObj);
              }
          }
      };
  }

  createActivityAnalysisRpt(){
      (this.activityTrackerArr).sort((a, b) => 
          (a.category + a.activityName > b.category + b.activityName) ? 1 : -1);
      let saveCategory = "";
      let saveActivityName = "";
      this.activityRptArr = [];
      let nbrTimesPlayed = 0;
      let nbrPlayersPlayed = 0;
      let maxLevel = 0;
      for (let i=0; i<this.activityTrackerArr.length; i++){
            if (this.activityTrackerArr[i].category !== saveCategory ||
                this.activityTrackerArr[i].activityName !== saveActivityName){
                    if (saveCategory !== "" && saveActivityName !== ""){
                        const activitySummaryObj = {category: saveCategory, activityName: saveActivityName,
                            nbrTimesPlayed: nbrTimesPlayed, nbrPlayersPlayed: nbrPlayersPlayed, maxLevel: maxLevel};
                         this.activityRptArr.push(activitySummaryObj);
                    }
                    saveCategory = this.activityTrackerArr[i].category;
                    saveActivityName = this.activityTrackerArr[i].activityName;
                    nbrTimesPlayed = 0;
                    nbrPlayersPlayed = 0;
                    maxLevel = 0;
            }
            nbrTimesPlayed += this.activityTrackerArr[i].nbrTimesPlayed;
            nbrPlayersPlayed += 1;
            if (this.activityTrackerArr[i].levelNbr > maxLevel){
                 maxLevel = this.activityTrackerArr[i].levelNbr;
            }
      }
  }


  render() { 
      this.activityAnalysisInfo = this.activityRptArr.map((tracker,index) =>
          <FiveColumnDisplayCard key={index} tableInfo={tracker} 
              />
          ); 
  return <>
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} 
            size="lg" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Activity Tracker Report</Modal.Title> 
          </Modal.Header>
          <Modal.Body>
                <table className="row-100  ml-2 mt-3 overflow-scroll table">   
                  <tbody>
                      <tr>
                        <th>Category</th>
                        <th>Activity Name</th>
                        <th>Nbr Times Played</th>
                        <th>Nbr Players Played</th>
                        <th>Max Level</th>
                      </tr>
                      {this.activityAnalysisInfo}
                    </tbody>
                </table>
            
          </Modal.Body>
          <Modal.Footer className="backgroundTopBottom">
              <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"
                alt="train"/>
              <Button  variant="info"   onClick={() => this.props.onClose()}>
                   Close
              </Button>
          </Modal.Footer>
          </Modal>
    </>
  }

 
}
export default ActivityTrackerAnalysisModal;