const carGameChoiceDialogDataset = [
// treat these as separate attributes
      {bedRooms: 1, homeType: "apartment", homeLevel: "10", commentTable: [
            {friendName:"Jennifer",  comment:"The 1 bedroom is all you need. " +
            "But it kind of gives me the willies.  I mean the paint is chipping and there are bugs in the kitchen."},
            {friendName:"Charlie", comment: "You know, I just realized that if I stand on that chair over " +
            "there, I can touch the ceiling."}, 
            {friendName:"Cindy", comment: "The size of this apartment seems good. But the building looks sort of run down. " +
            "I'm not sure if you could get the landlord to help if you need something fixed." },
            {friendName:"Cameron", comment: "Too small for me. I would never " +
            "choose this place.  I would be embarassed to have anyone come over. " }],
     },
     {bedRooms: 1, homeType: "apartment", homeLevel: "20", commentTable: [
          {friendName:"Jennifer",  comment:"This place seems nice.  It looks like it's maintained " +
          "fairly well."},
          {friendName:"Charlie", comment: "I am thinking I'll have pizza for dinner tonight.  I really " +
          "like sausage and pepperoni pizza.  You can even join me if you'd like."}, 
          {friendName:"Cindy", comment: "I like this place but as long as you are renting, you're " +
          "just giving your money to a landlord instead of making an investment." },
          {friendName:"Cameron", comment: "This place is OK.  I mean, for an apartment it's not too bad."}],
     },
     {bedRooms: 1, homeType:"apartment", homeLevel:"30", commentTable: [
          {friendName:"Jennifer",  comment:"This whole complex is really nice.  Did you check out the " +
         "swimming pool and the workout room?"},
          {friendName:"Charlie", comment:"I think I can climb that tree that's out front, wouldn't that " +
          "be cool?"}, 
          {friendName:"Cindy", comment:"This must be very expensive.  Instead of paying this much " +
         "on an apartment, you might want to get something smaller and save enough to buy a home." },
          {friendName:"Cameron", comment:"If you have to get an apartment, this is the one to get " +
         "I think it's really snazy."}]
     },
     {bedRooms: 2, homeType: "apartment", homeLevel: "10", commentTable: [
          {friendName:"Jennifer",  comment:"So you will have an extra bedroom. I guess that's good. " +
          "But this place looks pretty cheap to me."},
          {friendName:"Charlie", comment:"Well... if it doesn't work out, you can always move."}, 
          {friendName:"Cindy", comment:"It might be nice to have an extra bedroom to use as an office. " +
          "But this apartment looks a little ragged to me. The paint and carpet really need some work."},
          {friendName:"Cameron", comment:"Ugh! This place is really not one I would choose."}]
     },
     {bedRooms: 2, homeType: "apartment", homeLevel: "20", commentTable: [
            {friendName:"Jennifer",  comment:"This is pretty nice.  It looks like it even has brand " +
            "new carpet."},
            {friendName:"Charlie", comment:"I like the front door on this place and the bathroom seems " +
           "to work."}, 
            {friendName:"Cindy", comment:"I think it would be nice to have the extra bedroom, but " +
            "I'm guessing this is quite a bit more expensive." },
            {friendName:"Cameron", comment:"I guess the extra bedroom is nice.  Gives me a place to stay, " +
            "right?  Still, this looks pretty ordinary."}]
     },
     {bedRooms: 2, homeType: "apartment", homeLevel: "30", commentTable: [
          {friendName:"Jennifer",  comment:"This is really nice. It looks like it even has brand " +
          "new carpet."},
          {friendName:"Charlie", comment:"I really like this couch.  I'm going to just sit here and " +
          "wait while you look around."}, 
          {friendName:"Cindy", comment:"I think it would be nice to have the extra bedroom, but " +
          "I'm guessing this is a fairly expensive option." },
          {friendName:"Cameron", comment:"Now this is living!! Very plush and talk about a game room. " +
          "I would love to come over and play some ppol with you." }]
     },
     {bedRooms: 3, homeType: "house", homeLevel: "10", commentTable: [
          {friendName:"Jennifer",  comment: "This house is old but it really has character. " +
          "The rooms seems so cozy and warm." },
          {friendName:"Charlie", comment: "No way I'd get this place.  The toilet paper is just way " +
          "too rough."}, 
          {friendName:"Cindy", comment: "It might be nice to have the 3 bedrooms, but this place " +
          "doesn't look very good. I think I'd go for a higher quality home." },
          {friendName:"Cameron", comment: "No and just no... This place looks awful and the " +
          "neighborhood doesn't even look safe for my car."}]
     },  
     {bedRooms: 3, homeType: "house", homeLevel: "20", commentTable: [
          {friendName:"Jennifer",  comment: "I like this house.  I noticed that some of the plugs " +
          "are pretty old, but I can definitely help with that!"},
          {friendName:"Charlie", comment: "I like the towels in the bathroom.  Have you noticed? " +
          "They are really soft and fluffy."}, 
          {friendName:"Cindy", comment: "This is a nice place.  You'll have to check the price and the " +
          "monthly costs to make sure it's within your budget." },
          {friendName:"Cameron", comment:"Hmmmm... It is nice to have 3 bedrooms and the home "+
          "doesn't look too bad."}]
     }, 
     {bedRooms: 3, homeType: "house", homeLevel: "30", commentTable: [
          {friendName:"Jennifer",  comment: "This is very 'posh'.  Talk about move-in ready. I don't " +
          "see any things that need to be fixed."},
          {friendName:"Charlie", comment:"I just checked... I found out that I can run from one side " +
          "of the yard to the other in just 10 seconds. Pretty fast, eh?"}, 
          {friendName:"Cindy", comment:"Well this house is very up-to-date.  It's beautiful inside and out. " +
          "It does seem a tad expensive though, don't you think?" },
          {friendName:"Cameron", comment:"This is pretty nice.  I can see you in a place like this."}]
     }, 
     {bedRooms: 4, homeType: "house", homeLevel: "10", commentTable: [
          {friendName:"Jennifer",  comment: "This neighborhood looks kind of rough to me. Also, the " +
          "house needs a lot of work. But it could be a really nice place with a bit of remodeling."},
          {friendName:"Charlie", comment:"If you knocked down a few walls in this place, you could have " +
         "an indoor track. I've got a hammer ready to go."}, 
          {friendName:"Cindy", comment: "This house is a good price but you will need to set aside some " +
          "money for repairs." },
          {friendName:"Cameron", comment:"I wouldn't choose this house.  It looks cheap."}]
     }, 
     {bedRooms: 4, homeType: "house", homeLevel: "20", commentTable: [
          {friendName:"Jennifer",  comment:"This place is really too big, but maybe that's a good thing " +
         "if you're going to have a roommate."},
          {friendName:"Charlie", comment:"Did you see their TV?  It was huge!  If the place comes with " +
         "the TV, I'd say go for it!"}, 
          {friendName:"Cindy", comment: "This seems like a good choice.  The price is about right for the size " +
          "and I don't think it's going to need a lot of updating."  },
          {friendName:"Cameron", comment:"I guess this isn't too bad.  It doesn't really "}]
     }, 
     {bedRooms: 4, homeType: "house", homeLevel: "30", commentTable: [
          {friendName:"Jennifer",  comment:"This place is big and gorgeous.  I actually think it's " +
          "a way too big for my liking even if you decide to have roommates."  },
          {friendName:"Charlie", comment:"Whoa someone is walking their dog by your house.  That dog " +
         "is like huge."}, 
          {friendName:"Cindy", comment:"This is a very nice home.  Everything looks to be updated. " +
          "But... it's very expensive."},
          {friendName:"Cameron", comment: "Now this is what I call a home.  Everyone will be " +
          "impressed when they come to see this house. You can have amazing parties!!"}]
     }, 

     {roommates: 0, commentTable: [
          {friendName:"Jennifer",  comment: "It's great if you can afford this without having to contend " +
          "with a roommate."  },
          {friendName:"Charlie", comment: "I can't believe you don't want a roommate?  I would think " +
          "it would be nice to have someone to talk to."}, 
          {friendName:"Cindy", comment: "Also, make sure " +
          "you can afford the monthly payment amounts if you don't want a roommate."},
          {friendName:"Cameron", comment: "... no roommates?  Whoohoo!!!"}]
     },
     {roommates: 1, commentTable: [
          {friendName:"Jennifer",  comment: "You are going to have a roommate?  I tried that once " +
          "it really didn't work for me."  },
          {friendName:"Charlie", comment: "And...I think having a roommate is a fine idea!"}, 
          {friendName:"Cindy", comment: "Having a roommate seems like a really smart choice. " +
          "It will really reduce the cost of rent."},
          {friendName:"Cameron", comment: "Are you really thinking about having a roommate?  I mean a roommate " +
          "can be such a pain."}
     ]},
     {roommates: 2, commentTable: [
          {friendName:"Jennifer",  comment:"Having 2 roommates may not be a great idea but perhaps it will be fun!" },
          {friendName:"Charlie", comment:"Having 2 roommates seems like a good idea.  That way if I come over " +
          "we'll have enough for a game night."}, 
          {friendName:"Cindy", comment:"But, I don't know about the idea of having 2 roommates.  It could make " +
          "things difficult."},
          {friendName:"Cameron", comment: "You know two roommates just sounds like it's twice as bad as 1 roommate."}

     ]},
     {roommates: 3, commentTable: [
          {friendName:"Jennifer",  comment:"And... 3 roommates is way too many!!!"  },
          {friendName:"Charlie", comment:"OK... well having 3 roommates may be stretching it a bit, " +
          "but why not give it a try for awhile and see if it works for you."}, 
          {friendName:"Cindy", comment:"Three roommates sounds like a good idea from a money perspective " +
          "but I really think there would be a lot of problems with this."},
          {friendName:"Cameron", comment: "3 roommates is 3 too many for me."}]
     }
   
];

export default carGameChoiceDialogDataset;