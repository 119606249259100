const updateSavingsInfo = {
    gameID:  "65d22010fd5d67dd2c04f374",           
    monthlySavingsAmt: 999.99,
    currentlyActive: true,
    savingsStartDate: "2024-02",
    currentIntRate: ".99",
    intRateStartDate: "2024-02",
    totalDeposits: 999.99,
    totalWithdrawals: 9999.99,
    totalOverDraftFees: 999.99,
    totalIntAmt: 9999.99,
    totalSavings: 999.99,
    dateAdded: "2024-02-18",
    dateUpdated: "2024-02-18"
}

export default updateSavingsInfo;