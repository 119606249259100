
export const calculatePMITerm = (monthPayAmt, loanInterest, loanBaseAmt, nbrMonthsRqstd) => {
   //  the outstanding loan amount returned by this function is equal to the
   //  amount of principal outstanding on a loan after 'x' months of payments 
   //  confirmed this is accurate by using an online calculator  
   //  the result is the same calculcateOutstandingLoanPrincipalAmt function
    let percentOutstanding = 100;
    let loanBalance = loanBaseAmt;
    let interestAmt = 0;
    let principleAmt = 0;
    let monthlyInterestPct = (loanInterest / 12).toFixed(4);
    let nbrMonths = 0;
    while (percentOutstanding > 80 &&
        nbrMonths < nbrMonthsRqstd){
          interestAmt = (loanBalance * monthlyInterestPct).toFixed(2);
          principleAmt = monthPayAmt - interestAmt;
          loanBalance = (loanBalance - principleAmt).toFixed(2);
          percentOutstanding = (loanBalance / loanBaseAmt) * 100;
          nbrMonths = nbrMonths + 1;  
   }  
   let returnObj = {nbrMonths: nbrMonths, outstandingLoanAmt: loanBalance}
   return returnObj;
   
}

