const MoneyMgmtChallenge1Dataset = {
    items: {
        "item-1": { id: "item-1", content: "Create a monthly plan (budget).  This provides an estimate of the " +
                "amount you earn and what you expect to spend each month.", 
                answer: "column-2" },
        "item-2": { id: "item-2", content: "You can buy things that you want as long as you leave a " +
                "few dollars in the account.  There probably won’t be any unexpected things that you need.", 
                answer: "column-1" },
        "item-3": { id: "item-3", content: "Retirement is a long, long ways away.  You can start thinking " +
                "about that when you are close to the age that you want to retire.",
                answer: "column-1" },
        "item-4": { id: "item-4", content: "The amount that you spend each month should be less than the " +
                "amount you earn.  In fact, it is good to be able to set aside dollars for savings each month.",
                answer: "column-2" },
        "item-5": { id: "item-5", content: "Create a plan for the future.  When do you hope to retire and how " +
                "much do you need in savings in order to retire?", 
                answer: "column-2" },   
        "item-6": { id: "item-6", content: "Ensure that there is a growth path within the career that you have " +
                "chosen and that the expected salary for your career will allow you to meet your financial goals.", 
                answer: "column-2" },  
        "item-7": { id: "item-7", content: "There is no need to plan because things change all of the time anyway.", 
                answer: "column-1" },
        "item-8": { id: "item-8", content: "Put off buying things that you don’t need until you have enough money to " +
                "cover day-to-day expenses and a cushion for unexpected expenses",
                answer: "column-2" }, 
        "item-9": { id: "item-9", content: "It doesn’t really matter how much money you make on the job.  " +
                "You should be able to make extra money on the stock market or perhaps you will win the lottery.",
                answer: "column-1" },   
    },

    columns: {
        "column-1": { id: "column-1", title: "Considerations", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7", "item-8", "item-9"] },
        "column-2": { id: "column-2", title: "Good Money Management Practices", itemIds: [] },
    },
    columnOrder: ["column-1", "column-2",]
}

export default MoneyMgmtChallenge1Dataset