const LivingBelowChallenge1Dataset = {
    /*  spaces between content in items ensures that content is lined up correctly on the modal
        the column property is used to indicate the correct answer.   */
    items: {
        "item-1": { id: "item-1", content: "You can rest-easy knowing that you won't go broke if you encounter an unexpected expense.", 
                answer: "column-2" },
        "item-2": { id: "item-2", content: "You can spend money whenever you want because you have extra.", 
                answer: "column-1" },
        "item-3": { id: "item-3", content: "You need to worry a lot about losing your job because you don't have money saved for a 'rainy day'.",
                answer: "column-1" },
        "item-4": { id: "item-4", content: "You will likely have an easier time getting a loan if necessary.",
                answer: "column-2" },
        "item-5": { id: "item-5", content: "You might be able to retire early or at least have some money to help in your retirement.", 
                answer: "column-2" },
        "item-6": { id: "item-6", content: "You will never have any fun because you won't ever be able to spend money on non-essential items.", 
                answer: "column-1" },   
        "item-7": { id: "item-7", content: "You will be able to save money each month which will build up over time.", 
                answer: "column-2" },     

       
    },
    columns: {
        "column-1": { id: "column-1", title: "Considerations", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7"] },
        "column-2": { id: "column-2", title: "Advantages of Living Below Your Means", itemIds: [] },
        
              
    },
    columnOrder: ["column-1", "column-2",]
}

export default LivingBelowChallenge1Dataset