const friendHaveFunActivityDialogDataset = [

        {activityPreference: "2", 
                comments: ["This place is OK, but I've definitely seen better.",
                           "I hate to say it, but I'm kind of getting bored.",
                           "Ok, well that was sort of lame don't you think?",
                           "I can't believe that other people think this is fun.",
                           "I have to admit, some of this has been ok.",
                           "Do you think we could leave soon?", 
                ]},
        {activityPreference: "3", 
                comments: ["Wow!  This is really fun don't you think?", 
                           "I can't believe the time is going by so quickly.", 
                           "This is really great and fun to share it with a friend!",
                           "This might be a little expensive, but it's worth every penny!!",
                           "Thank you so much for inviting me.  This is really fun!",
                ]},
        {activityPreference: "4",
                comments: ["This is a lot of fun!",
                           "I can't remember when I've had so much fun!!",
                           "I wish this would go on forever!",
                           "I hope you are enjoying this as much as I am!!",
                           "It would be really fun to do this again some time."
                ]},
              
        
]
 
export default friendHaveFunActivityDialogDataset;