import {Component} from 'react';
import '../game-views/GameViews/spinningBadge.css';
import '../game-views/GameViews/gamePhase2.css';
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';
     
const SpinningBadge  = (props) => {
        const [badgeStatus, setBadgeStatus] = useState("SpinImage");
        const [imageURL] = useState(props.pictureURL);
        const [badgeName] = useState(props.parameters.badgeName);
        const [modalClass] = useState(props.modalClass);
        const [awardType] = useState(props.awardType);
        const [awardAmount] = useState(props.parameters.awardAmount);
        const [awardTitle] = useState(props.parameters.title);
        const [animationOption, setAnimationOption]= useState(props.animationOption);
        const [modalLeft] = useState(props.leftStyle);
        const [textClass] = useState(props.textClass);
        const [moodPoints] = useState(props.parameters.moodPoints);
        const [badgeImgClass] = useState(props.badgeImgClass);
       
    const spinComplete = () => {
        //  trigger secondary animation
        setBadgeStatus("SpinComplete");
    }

    const shrinkComplete = () => {
        let animation = {animation: animationOption};
        setAnimationOption(animation);
        setBadgeStatus ("ShrinkComplete");
    }
    
    const moveComplete = () => {
       //  setBadgeStatus ("MoveComplete");
         props.onClose();
    }
                    
    return (
        <Modal show={props.show} className={modalClass} 
             style={modalLeft} animation={false} >
        <Modal.Body>
        <h5 className="text-center">Congratulations!</h5>
            {modalClass == "badgeModal" ?
                <div className={textClass}>You've earned a Badge</div>
            :
                <span>
                    <div className={textClass}>You've earned a</div>
                    <div className={textClass}>Money Train Badge</div>
                </span>
            }   
            {badgeStatus == "SpinImage" ?
                <span>
                    <span className="mt-2 mx-4">
                    <img src={imageURL} 
                            className={badgeImgClass}  alt={'badge'}
                            onAnimationEnd={() => spinComplete()} 
                    />
                 
                    </span>
                </span>
            :
                null
            }
            {badgeStatus == "SpinComplete" ?
                <span>
                     <img src={imageURL} 
                        className="shrinkPictureSize badgeImage"  alt={'badge'}
                        onAnimationEnd={() => shrinkComplete()}
                     />
                </span>
            :
                null
            }
            {badgeStatus == "ShrinkComplete" ?
                <span>
                    <img src={imageURL} alt={'badge'} 
                        className="badgeImageSmall movePicture"
                        onAnimationEnd={() => moveComplete()}
                    />
                    <div className="text-white">testing text</div>
                    <div className="text-white">testing text</div>
                    <div className="text-white">testing text</div>
                    <div className="text-white">testing text</div>
                    <div className="text-white">testing text</div>
                    <div className="text-white">testing text</div>
                    <div className="text-white">testing text </div>
                    <div className="text-white">testing text</div>
                </span>
                
            :
                null
            }
               <h5 className={textClass}>{badgeName}</h5>
               <div className={textClass}>{awardTitle}</div>
               <div className={textClass}>{awardType} increased by {doubleToStringMonthly(awardAmount)} 
               <div className={textClass}>Mood Points increased by {moodPoints}</div>
               </div>
           
            </Modal.Body>
        </Modal>
       
 
    )
}
    
export default SpinningBadge;  