import './ExtraCredit/extraCredit.css';
import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';

const Container = styled.div`
    border: 1px solid black;
    padding:8px;
    margin-bottom:8px;
    border-radius:2px;
    white-space: pre-wrap;
    background-color:${props => (props.isDragging ? 'white' : '#BAF2F8')};
`
function DragNDropItems(props) {
    var textClass = "m-2";
    if (props.screenSize === "narrow"){
        textClass = "small-text " + textClass;
    } 
    return (
        <Draggable draggableId={props.item.id} index={props.index}>
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                >
                    
                       {props.amountSw ?
                            <div className="row w-100">
                                    <div className="col-sm-10 pl-2" >
                                        {props.item.content}</div>
                                    <div className="col-sm-2 text-right p-0">
                                        {doubleToStringMonthly(props.item.amount)}
                                    </div>
                            </div>   
                        :       
                        <span>
                         {props.pictureSw ?
                                <span>  
                                    <div>    
                                          <img src={props.item.picture} className="ml-2 mt-2 drag_n_drop_thumbnail_img"
                                            alt="itemPicture" />
                                    </div>
                                </span>
                            :
                                <span className="row w-100">            
                                    <div className={textClass}>    
                                        {props.item.content} 
                                    </div>
                                </span>
                            }            
                            
                        </span>
                        }
                   
                    
                </Container>
            )}
        </Draggable>
    )
}

export default DragNDropItems
