const updateActivityTrackerData = {
    category: "testing" ,
    activityTable: [
        {
          "activityName": "TestActivity",
          "nbrTimesPlayed": 1,
          "lastTimePlayed": "2024-02",
          "level": 1
        }
      ],
}

export default updateActivityTrackerData;