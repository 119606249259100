import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DragNDropModal from './drag_n_drop_modal';
import ChoosingCareerChallenge1InitialText from '../../dragNDropText/choosing_career_challenge1_initial_text';
import ChoosingCareerChallenge1Dataset from './Datasets/choosing_career_challenge1_dataset';
import ChoosingCareerChallenge1HintsText from './text/choosing_career_challenge1_hints_text';
import ChoosingCareerChallenge2HintsText from './text/choosing_career_challenge2_hints_text';
import ChoosingCareerChallenge2InitialText from '../../dragNDropText/choosing_career_challenge2_initial_text';
import ChoosingCareerChallenge2Dataset from './Datasets/choosing_career_challenge2_dataset';
import { createColumnArray } from '../../objects/DragNDrop/createColumnArray_function';
import { calcColumnScore } from '../../objects/DragNDrop/calcColumnScore_function';
import { calcItemScore } from '../../objects/DragNDrop/calcItemScore_function';
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';
import {dragNDropBefore} from '../../objects/DragNDrop/drag_n_drop_before_function';

class ChoosingCareer extends Component {
    
    constructor(props) { 
        super(props);
        this.state = {
            openSw: false,
            displayAlert: false,
            title: ExtraCreditConfigFile.general.title,
        }
        this.currentStatus = "DisplayChallenge1";
        this.dragNDrop = {};
        this.hintTable = [];
        this.challenge1Score = 0;
        this.challenge2Score = 0;
        this.modalMessage = "";
        this.hintNbr = 0;
        this.averageScore = 0;
        this.challenge1Complete = false;
        this.challenge2Complete = false;
        this.displayAlert = false;
    }
   
    openChoosingCareerChallenge1() {
        this.currentStatus = "OpenChallenge1";
        this.dragNDrop = dragNDropBefore ("Match the Person to the Job",
            ChoosingCareerChallenge1InitialText, "", "", "", ChoosingCareerChallenge1Dataset,
            "ChoosingCareerChallenge1", 1);
        this.hintTable = ChoosingCareerChallenge1HintsText;
        this.setState({openSw: true});
    }

    closeChoosingCareerChallenge1() {
        this.currentStatus = "DisplayChallenge1"
        this.setState({openSw: false});
    }
    
    saveChoosingCareerChallenge1() {
        var columnArray=createColumnArray(this.dragNDrop.cookieNamePfx, 5);
        this.challenge1Score = calcColumnScore(columnArray);
        if (this.challenge1Score > 85) {
            this.modalMessage = "";
            sessionStorage.setItem("choosingCareerChallenge1Score", this.challenge1Score);
            this.currentStatus = "DisplayChallenge2"
            this.setState({openSw: false});
        } else {
            this.modalMessage= "Not quite right... Try again.";
            if (this.hintNbr < this.hintTable.length){
                this.hintNbr+=1;
            }
            this.setState({displayAlert: true});
        } 
     }
       
    openChoosingCareerChallenge2() {
        this.currentStatus = "OpenChallenge2";
        this.hintNbr = 0;
        this.modalMessage = "";
        this.dragNDrop = dragNDropBefore("Prioritize the Influencing Factors",
            ChoosingCareerChallenge2InitialText, "md", "", "", ChoosingCareerChallenge2Dataset,
            "ChoosingCareerChallenge2", "");
        this.hintTable = ChoosingCareerChallenge2HintsText;
        this.setState({openSw: true});
    }
    
    closeChoosingCareerChallenge2() {
        this.currentStatus = "DisplayChallenge2";
        this.setState({openSw: false});
    }
    
    saveChoosingCareerChallenge2() {
        var columnArray=createColumnArray(this.dragNDrop.cookieNamePfx, 1);
        this.challenge2Score = calcItemScore(columnArray);
        if (this.challenge2Score > 85) {
            this.modalMessage = "";
            this.setState({openSw: false});
            this.finishChoosingCareer();
        } else {
            this.modalMessage= "Not quite right... Try again.";
            if (this.hintNbr < this.hintTable.length){
                this.hintNbr+=1;
            }
            this.displayAlert = true;
            this.setState({displayAlert: true});
        } 
    }
    
    getChoosingCareerScores() {
        var challenge1Score = parseFloat(sessionStorage.getItem("choosingCareerChallenge1Score"));
        if (!isNaN(challenge1Score)) {
           this.challenge1Score = challenge1Score;       
           this.challege1Complete = true;
        }
    }
    
    closeAlert(){
        this.setState({displayAlert: false});
    }
        
    render() {
        this.getChoosingCareerScores();
        if (this.challenge2Complete === true) {
            this.finishChoosingCareer()
        }
        this.gutters = "";
        if (this.dragNDrop.modalSize === "xl" && 
           (this.props.screenSize === "narrow" || this.props.screenSize === "medium")){
             this.gutters = "modal-95";
        }
                    
    return <>
       <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true"
            animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Extra Credit - Choosing a Career</Modal.Title> 
          </Modal.Header>
          <Modal.Body>
            {this.currentStatus === "DisplayChallenge1" ?
                <span>
                <div className="text-center">
                    <p className="text-left m-4">Choosing a career is arguably the most important
                        decision of a young adult.  While it is certainly possible to change
                        careers after you have begun working, it can be very difficult and costly
                        to do so.  The challenges in this section are intended to provide some 
                        guidance on the .... </p>
                </div>         
                <div className="text-center">
                    <button className="btn btn-primary w-75 mt-4" 
                        onClick ={()=> this.openChoosingCareerChallenge1()}>
                          Choosing a Career Challenge #1
                    </button>
                </div>
                </span> 
            :
                <div></div>
            }
            {this.currentStatus === "OpenChallenge1"  ?
                <DragNDropModal show={this.state.openSw} 
                    gutters={this.gutters}
                    screenSize={this.props.screenSize}
                    onClose={() => this.closeChoosingCareerChallenge1()} 
                    onSave={() => this.saveChoosingCareerChallenge1()}
                    closeAlert={() => this.closeAlert()}
                    inputDataset={this.dragNDrop.inputData}
                    modalTitle={this.dragNDrop.modalTitle}
                    modalSize={this.dragNDrop.modalSize}
                    introText={this.dragNDrop.introText}
                    cookieNamePfx={this.dragNDrop.cookieNamePfx}
                    buttonType={this.dragNDrop.buttonType}
                    maxNbr={this.dragNDrop.maxNbr}
                    message={this.modalMessage}
                    hintTable={this.hintTable}
                    hintNbr={this.hintNbr}
                    displayAlert={this.state.displayAlert}
                    title={this.state.title}
                />
            :
                <div></div>
            }
            
            {this.currentStatus === "DisplayChallenge2" ?
                <span>
                <p className="mt-4 ml-4 p-2">Congratulations, you have successfully completed Chthe first
                       challenge, would you like to try another one? If you successfully complete this second 
                       challenge, you will earn points for your game.
                </p>
                    <div className="text-center">
                        <button className="btn btn-primary w-75 mt-6" 
                            onClick ={()=> this.openChoosingCareerChallenge2()}>
                                Choosing a Career Challenge #2
                        </button>
                    </div>
                </span> 
                :
                    <p></p>
            }
         
            {this.currentStatus === "OpenChallenge2"  ?
                <DragNDropModal show={this.state.openSw} 
                    gutters={this.gutters}
                    onClose={() => this.closeChoosingCareerChallenge2()} 
                    onSave={() => this.saveChoosingCareerChallenge2()}
                    closeAlert={() => this.closeAlert()}
                    inputDataset={this.dragNDrop.inputData}
                    modalTitle={this.dragNDrop.modalTitle}
                    modalSize={this.dragNDrop.modalSize}
                    introText={this.dragNDrop.introText}
                    cookieNamePfx={this.dragNDrop.cookieNamePfx}
                    buttonType={this.dragNDrop.buttonType}
                    message={this.modalMessage}
                    hintTable={this.hintTable}
                    hintNbr={this.hintNbr}
                    displayAlert={this.state.displayAlert}
                    title={this.state.title}
                />
            :
                <div></div>
            }
          </Modal.Body>
          <Modal.Footer className="text-right">
                <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"
                    alt="train"/>
                <Button variant="secondary"   onClick={() => this.props.onClose()}>
                    Close
                </Button>
          </Modal.Footer>
          </Modal>
        </>
    }

    finishChoosingCareer() {
        this.averageScore = (+this.challenge1Score + +this.challenge2Score) / 2; 
        this.calculateChoosingCareerPoints();
        sessionStorage.setItem("previousExtraCreditProcess", "Choosing a Career");
        this.props.onCompleted(); 
    }

    calculateChoosingCareerPoints(){
        var earnedAmt = 0;
        let processName = "ChoosingCareer";
        let earningsObj = ExtraCreditConfigFile.exercises.find(elem => elem.category === processName);
        earnedAmt = (this.averageScore/100 * earningsObj.amt).toFixed(2);
        const choosingCareerObj = {processName: processName, score: this.averageScore,
            assetType: earningsObj.type, amount: earnedAmt, level: earningsObj.level};
        sessionStorage.setItem("extraCreditCompleted", JSON.stringify(choosingCareerObj));
    }
}

export default withRouter(ChoosingCareer);