import {Component} from 'react';

class DropDownOptionCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
       
    return <> 
           
           <option value={this.props.options.value}>{this.props.options.selection}</option>
           
    </>
    }
}

export default DropDownOptionCard;