import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import "./Special/special.css";
import Button from 'react-bootstrap/Button';
import EventListDisplayCard from './event_list_display_card';

class EventListDisplayModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      }    
}
   
render() {
        this.eventInfo = this.props.cardList.map((cardList, index) =>
          <EventListDisplayCard cardList={cardList}  key={index}  
           index={index}/>
              );
                
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="lg" scrollable="true">
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Event Display List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <span>
                <h6>Game Phase: &nbsp;{this.props.slctParms.gamePhase} &nbsp;
                  Category: &nbsp; {this.props.slctParms.eventCategory} &nbsp;
                  Job Name: &nbsp; {this.props.slctParms.careerTag}</h6>
                <h6>Total Nbr of Events: &nbsp; {this.props.cardList.length}</h6>
                   {this.eventInfo}
              </span>
                  
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
             
              <Button variant="primary"  onClick={() => this.props.onClose()}>
                   Close
              </Button>
              
        </Modal.Footer>
       
    </Modal>
    
  </>
  }
}

export default EventListDisplayModal;