import {Component} from 'react';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';



class SixColumnDisplayCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }


    render() {
        this.column1 = this.props.index + 1;
        switch (this.props.tableType){
            case "savings":
                this.column2 = this.props.tableInfo.monthlySavingsAmt;
                this.column3 = this.props.tableInfo.yearlySavingsAmt;
                this.column4 = this.props.tableInfo.intRate;
                this.column5 = this.props.tableInfo.years;
                this.column6 = this.props.tableInfo.totalSavingsAmt;
                this.column7 = this.props.tableInfo.totalSavingsWithInt;
                break;
            case "loans":
                this.column2 = this.props.tableInfo.loanAmt;
                this.column3 = "";
                this.column4 = this.props.tableInfo.intRate;
                this.column5 = this.props.tableInfo.loanTerm;
                this.column6 = this.props.tableInfo.monthlyAmt;
                this.column7 = this.props.tableInfo.totalPaid;
                break;
            default:
                break;
        }
         
    return <>
        <tr className="">
                <td className="text-right p-0">{this.column1}</td>
                <th className="text-right p-0">{doubleToStringMonthly(this.column2)}</th>
                {this.props.tableType == "savings" ?
                    <th className="text-right p-0">{doubleToStringMonthly(this.column3)}</th>
                :
                    null
                }
                <th className="text-right p-0">{this.column4}</th>
                <th className="text-right p-0">{this.column5}</th>
                <td className="text-right p-0">{doubleToStringMonthly(this.column6)}</td>
                <td className="text-right p-0">{doubleToStringMonthly(this.column7)}</td>
        </tr>

    </>
    }

   
}

export default SixColumnDisplayCard;