const PrioritiesChallenge1HintsText =
    ["This exercise can be somewhat subjective.  The purchase of a winter coat " +
        "is important, but if you can't get to work, that would be a bigger problem.",
     "Repairing your refrigerator should have the highest priority because " +
        "the food will spoil and it would be expensive to replace all of the food.",
     "Although you might really want to play tennis, you could skip this activity if " +
        "needed, so this is likely the least important item.",
     "You could skip buying candy for Halloween, but think of how sad children will " +
         "be when you don't hand out candy.  This should be prioritized above the tennis racket." 
    ]
   

export default PrioritiesChallenge1HintsText;