import { calcInterestAmt } from "../../../objects/CommonUse/calc_interest_amt";
import { rollForwardDate } from "./roll_forward_date_function";
import {calcRandomNumberWithMax} from "../../../objects/CommonUse/calc_random_number_with_max";

export const moveFromCashToSavings = (savingsInfo, currentCash, currentGameDate, status, nbrMonths) => {
   
    if (nbrMonths == undefined){
        nbrMonths = 1;
    }

    let newCashAmt = +currentCash;
    if (status == "active"){
        newCashAmt = +currentCash - (+savingsInfo.monthlySavingsAmt * nbrMonths);
    }else{
        const amt = 0;
        savingsInfo.monthlySavingsAmt = amt.toFixed(2);
    }

    // interest rate is changed once each year
    let intEndDate = "";
    const intRateStartDate = savingsInfo.intRateStartDate;
    if (intRateStartDate != ""){
        intEndDate = rollForwardDate(intRateStartDate, 12);
    }
    if (currentGameDate > intEndDate || savingsInfo.intRateStartDate == ""){
         let intRate = (calcRandomNumberWithMax(2) + 1)/100;
         savingsInfo.currentIntRate = intRate;
         savingsInfo.intRateStartDate = currentGameDate;
    }
    for (let i=0; i<nbrMonths; i++){
        let intAmt = calcInterestAmt(+savingsInfo.totalSavings, savingsInfo.currentIntRate, "month");
        savingsInfo.totalIntAmt = (+savingsInfo.totalIntAmt + +intAmt).toFixed(4);
        savingsInfo.totalDeposits = +savingsInfo.totalDeposits + +savingsInfo.monthlySavingsAmt;
        savingsInfo.totalSavings = (+savingsInfo.totalIntAmt + +savingsInfo.totalDeposits - 
            +savingsInfo.totalWithdrawals).toFixed(2);
    }
         
    let savingsInfoObj = {savingsInfo: savingsInfo, cashAmt: newCashAmt};
    
    return savingsInfoObj;
   
}





