const PrioritiesChallenge2HintsText =
    ["There are 3 items in the 'Monthly Expenses' column that you " +
         "must pay (select).",
     "The total for the items you selected must be between ||minAmount|| and ||maxAmount||. " +
         "Your current total is ||dollarAmt||.",
     "Other than the 3 required items you can select any of the other " +
         "items that you wish as long as the total amount does not exceed " +
         "the maximum amount (see the first hint)."
    
    ]
   

export default PrioritiesChallenge2HintsText;