const CreditCardExerciseDataset =[
      {exerciseName: "PaymentOption", 
              text:  "In this example, only the Payment Policy has been changed.  The Monthly Charge Amount and Number of Years " +
                     "are the same for each of the lines.  Note the Total Amount Paid and Ending Balance columns for each line. " +
                     "The Total Amount Paid is a sum of all of the monthly payment amounts that the card holder has made. " +
                     "In the first line, where the Payment Policy is set to 'Pay the Minimum Amount', it is true, that the " +
                     "Total Amount Paid is the lowest amount, but the Ending Balance is quite large.  In the 3rd line, the " +
                     "credit card holder is paying as much each month as they are charging, yet the Ending Balance is still " +
                     "over twice as much as the initial outstanding amount of $10,000.  Line 5 shows the results when the " +
                     "credit card holder pays the full outstanding balance each month.  Note that the first month, they would " +
                     "have had a very large amount due of $10,800, but in subsequent months, they paid $800 and, best of all " +
                     "when the 10 years has elapsed, the Ending Balance is $0.  Standard credit card interest of 20% is very high!!!",
              conclusion:  "Paying the minimum amount on a credit card is not a good idea.  The Ending Balance will continue to " +
                     "increase and the individual will pay far more then the actual charge amount (due to interest charged)." ,  
              parametersArr: [{paymentOption: "min", monthlyChargeAmt: 800, nbrYears: 5},
                                   {paymentOption:  750, monthlyChargeAmt: 800, nbrYears: 5},
                            {paymentOption:  800, monthlyChargeAmt: 800, nbrYears: 5},
                            {paymentOption: 1000, monthlyChargeAmt: 800, nbrYears: 5},
                            {paymentOption: "full", monthlyChargeAmt: 800, nbrYears: 5}
                           ]   
      },
      {exerciseName: "PayBalance", 
      text:  "This example demonstrates the challenge of paying off a large credit card balance. " +
             "In this case, the beginning credit card balance is $10,000.  In the first two " +
             "lines, the individual is paying only the minimum amount each month.  Although, after many years, the amount owed " +
             "(Ending Balance) is reduced, look at the Total Amount Paid column.  On line 2, the individual has paid  " +
             "over 4 times the amount of the beginning balance and still owes $2,665.15 after 30 years!  Lines 3 and 4 show " +
             "what would happen if the individual " +
             "pays more than the minimum amount.  In both of these situations, the balance is paid down much faster " +
             "(8 years in line 3 and 2 years in line 4), but the Total Amount Paid is still quite a bit more than the " +
             "initial amount owed.  The final line shows what happens when the individual pays off the entire balance. " +
             "Although this means the individual has to come up with $10,000 at one time to pay the balance, that might be " +
             "more than most people can afford, but at least " +
             "this way the Total Amount Paid is the same as the initial balance amount.  ",
      conclusion:  "If the balance on " +
              "a credit card becomes large, it is best if the individual pays as much as they can afford each month until the " +
              "balance is fully paid off.",
      parametersArr: [{paymentOption: "min", monthlyChargeAmt: 0, nbrYears: 20},
                      {paymentOption: "min", monthlyChargeAmt: 0, nbrYears: 30},
                      {paymentOption: 200, monthlyChargeAmt: 0, nbrYears: 8},
                      {paymentOption: 500, monthlyChargeAmt: 0, nbrYears: 2},
                      {paymentOption: "full", monthlyChargeAmt: 0, nbrYears: 1}
                     ]   
      },
     
]

export default CreditCardExerciseDataset;