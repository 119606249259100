const PrioritiesChallenge2Dataset = {
    minAmount: 700,
    maxAmount: 1400,
    // items with answer of "column-0" can be moved to column-1 as long as total
    // amount is within the range of the minAmount and maxAmount
    items: {
        "item-1": { id: "item-1", content: "Cable TV & premier movie options.", 
                amount: 150, answer: "column-0" },
        "item-2": { id: "item-2", content: "Groceries for the coming Month.",
                amount: 350, answer: "column-2"},
        "item-3": { id: "item-3", content: "Purchase a mountain bike.",
                amount: 550, answer: "column-0" },
        "item-4": { id: "item-4", content: "Buy clothes for an upcoming special occasion.", 
                amount: 120, answer: "column-0"},
        "item-5": { id: "item-5", content: "Monthly Phone Service Charges.",    
                amount: 50, answer: "column-2" },
        "item-6": { id: "item-6", content: "Eat out 4 times per week.",
                amount: 400, answer: "column-0"},
        "item-7": { id: "item-7", content: "Save money for your upcoming vacation.",
                amount: 200, answer: "column-0"},   
        "item-8": { id: "item-8", content: "Purchase a new comfortable rocker-recliner chair.",
                amount: 600, answer: "column-0"},
        "item-9": { id: "item-9", content: "Make a monthly payment for your car loan.", 
                amount: 200, answer: "column-2"},
        "item-10": { id: "item-10", content: "Buy a several golf clubs.", 
                amount: 300, answer: "column-0"},
    },
    columns: {
        "column-1": { id: "column-1", title: "Monthly Expenses", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7",
            "item-8", "item-9", "item-10"] },
        "column-2": { id: "column-2", title: "Items Selected", itemIds: [],
             minAmount: 500, maxAmount: 700    },
        
    },
    columnOrder: ["column-1", "column-2"]
}

export default PrioritiesChallenge2Dataset