const PrioritiesChallenge1HintsText =
    ["This exercise can be somewhat subjective.  Fixing a couch that is badly warn " +
        "is likely more important, than buying fishing gear.",
     "Getting x-rays for your arm should have the highest priority because " +
        "if it is broken, it may need surgery or you need to wear a sling and perhaps a cast " + 
        "to ensure it heals correctly.",
     "Although you might really want fishing gear, you could skip this activity if " +
        "needed, so this is likely the least important item.",
     "You could skip buying groceries, but the alternative of going out to eat " +
         "is likely more expensive. This should be prioritized above buying clothes for work." 
    ]
   

export default PrioritiesChallenge1HintsText;