import {createColumnArray} from '../../../objects/DragNDrop/createColumnArray_function';
import {calcColumnScore} from '../../../objects/DragNDrop/calcColumnScore_function';
import {calcItemScore} from '../../../objects/DragNDrop/calcItemScore_function';

export const calculateDNDScore = (data, cookieNamePfx, scoreType, activityType ) => {
        let errorMessage = "";
        let nbrColumns = data.columnOrder.length;
        let answerCorrect = false;
        var columnArray = createColumnArray(cookieNamePfx, nbrColumns);
        let totalNbrItemsMoved = 0;
        if (activityType === "GroceryShopper"){
            let itemsInShopperBasket1 = columnArray[1].items.length;
            let itemsInShopperBasket2 = 0
            if (columnArray.length === 5){
                itemsInShopperBasket2 = columnArray[3].items.length;
            }
            totalNbrItemsMoved = itemsInShopperBasket1 + itemsInShopperBasket2;
        }else{
            for (let i=0; i<columnArray.length; i++){
                  totalNbrItemsMoved = totalNbrItemsMoved + columnArray[i].items.length;
            }
        }
        let columnScore = calcColumnScore(columnArray);
        if (+columnScore === 100) {
            if (scoreType === "itemOrder"){
                let itemScore = calcItemScore(columnArray);
                if (+itemScore !==100){
                    answerCorrect = false;
                }else{
                    answerCorrect = true;
                }
            }else{
                answerCorrect = true;
            }
        }else{
            answerCorrect = false;
        }
        if (answerCorrect === true){
            let score = totalNbrItemsMoved;
            errorMessage = {correct: true, 
                message: "Good Job! ", score: score}
        } else {
            errorMessage = {correct: false, message: "Not quite right, try again", score: 0}
        } 
    return errorMessage;
   
}
export default calculateDNDScore;

