export const rollForwardDate = (startDate, nbrMonths) => {
     var i;
     var startDateString = "";
     const yearMonthVals = startDate.split('-'); 
     var yearString = yearMonthVals[0];
     var monthString = yearMonthVals[1];
     var monthNbr = parseFloat(monthString);
     var yearNbr = parseFloat(yearString);
   
     let nbrMonthsNbr = parseFloat(nbrMonths);
     if (!isNaN(nbrMonthsNbr) && nbrMonthsNbr > 0) {
          for (i=0; i<nbrMonths; i++) {
               monthNbr += 1;
               // if month is 13 then reset date to new year
               if (monthNbr == 13) {
                    monthString = "01";
                    monthNbr = parseFloat(monthString);
                    yearNbr += 1; 
               }else{
                    if (monthNbr < 10) {
                         monthString = "0" + monthNbr.toString();
                    }else{
                         monthString = monthNbr.toString();
                    }
               }

          }
          yearString=yearNbr.toString();
          startDateString = `${yearString}-${monthString}`;
     }else{
          startDateString = "0000-00"
     }
     return startDateString;
 }

