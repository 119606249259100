const updateIconTableData = {
    iconTable: [
        {
          "iconName": "gameBasicHelp2",
          "iconCategory": "information",
          "className": "small_sign_thumbnail_img information-sign-position",
          "imageSrc": "gameHelp.png",
          "validInd": true,
          "firstTime": false,
          "additionalInfo": "",
          "dateAdded": "2023/07/27"
        },
        {
          "iconName": "funStuffNewLevel",
          "iconCategory": "goodNews",
          "className": "small_sign_thumbnail_img good-news-funStuff-position",
          "imageSrc": "FunStuff.jpg",
          "validInd": true,
          "firstTime": false
        },
         {
          "iconName": "testIconTableUpdate",
          "iconCategory": "goodNews",
          "className": "small_sign_thumbnail_img good-news-funStuff-position",
          "imageSrc": "FunStuff.jpg",
          "validInd": true,
          "firstTime": false
        }
      ],
}

export default updateIconTableData;