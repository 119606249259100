
const SavingsCalculatorText =
    {introText:
        "Use the savings calculator to experiment with how these factors affect the " +
        "amount saved.  Here are some specific things to understand (scroll down to see the result of " +
        "the selections that you make.) ",
     numberedListArr: [
        "If you save less per month but save it over a long period (25 years) do you end up " +
        "making more money in the long term than if you save a lot for short period of time (5 years)? " +
        "Keep the interest rate the same as you test this out.",
        "How do changes to interest rate affect the total amount saved if you save the same " +
        "amount over the same number of years?",
        "If you keep the amount saved each month and the interest rate the same, how much does the " +
        "number of years saved affect the total amount?"
    ]
}

export default SavingsCalculatorText;