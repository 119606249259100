import LinkTab from '../components/shared-components/link_tab';
import { withRouter } from "react-router";
import AdminMain from '../components/admin/admin_main';
import EventsUpdate from '../components/admin/events_update';
import EventReports from '../components/admin/event_reports';
import ConditionReports from '../components/admin/condition_reports';
import OtherUpdates from '../components/admin/other_updates';
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import React from 'react';
import {Link} from "react-router-dom";
import { setHeadersForFetch } from '../objects/CommonUse/set_headers_for_fetch';
import Config from '../config';
import {buildUrl} from '../objects/CommonUse/build_URL';
import WaitTimeSpinner from '../components/shared-components/wait_time_spinner';


class Administration extends React.Component {
    constructor(props) {
        super(props);
          
    this.inputCompleted = (inputID) => {
        this.state.inputArr[inputID].completed = true;
        this.setState({inputArr: this.state.inputArr}); 
    };

    this.inputSelected = (inputID) => {
        for (const input of this.state.inputArr) {
            input.selected = false;
        }
        this.state.inputArr[inputID].selected = true;
        this.setState({inputArr: this.state.inputArr}); 
    };
  
       
     this.state = {
        inputArr: [
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
                                
        ],
        inputCompleted: this.inputCompleted,
        inputSelected: this.inputSelected,
        configurationAvailable: null,
        userName: null,   
        gameConfigLoaded: false,
        headers: setHeadersForFetch(),
        environment: Config.dataURL.currentEnv,
        subProcess: "Administration",
        eventsLoaded: false,
        conditionsLoaded: false,
        adminInfo: {
            loading: true,
            eventsData: [],
            conditionsData: [],
            loadingActivities: true,
        }
    };
     
    this.getCorrespondingStudentName= () => {
        //*  this will include whatever logic is required to get the parent info for the given parent
       
    }
    
    switch(props.selected) {
        case "adminMain":
            this.inputSelected(0);
            break;
        case "eventsUpdate":
            this.inputSelected(1);
            break;
        case "otherUpdates":
            this.inputSelected(2);
            break;
        case "eventReports":
            this.inputSelected(3);
            break;
        case "conditionReports":
            this.inputSelected(4);
            break;
                   
    }
    this.allDataLoaded = false;
    this.loadData();

}

loadData(){
    this.checkForAdminInfo();
    this.getEventsList();
    this.getConditionsList();
}

componentDidMount() {
    /* will need to get student id associated with this parent before continuing */
  //  this.checkForAdminInfo();
    const userRole = sessionStorage.getItem("userRole");
    if (userRole != "admin"){
        alert("Sorry... You are not authorized to use the Admin Processes." +
            "Try another selection:")
        this.props.history.push('/');
    }      
}

           
    render(){
      
        if (this.state.gameConfigLoaded == true && this.state.eventsLoaded ==true &&
            this.state.conditionsLoaded == true ){
                this.allDataLoaded = true;
            }
    return <>
        {!this.allDataLoaded ?
            <WaitTimeSpinner/>
        :
     <span className="container m-0 p-0">
                <span className="row w-100 mx-0">
                <div className="col-sm-2 mt-4 ml-3">
                        <div>
                            <img className="train-track-img" src={'./assets/ExtraCredit.png'} 
                                alt="MoneyTrain Picture"/>
                        </div>   
                </div>

                    <InputCompletedContext.Provider value={this.state}>
                    
                    {this.props.selected === "adminMain" ? <AdminMain 
                        onOpen ={()=> this.inputSelected(0)}
                        selected={this.props.selected}
                        configAvailable={this.state.configurationAvailable}
                        adminInfo = {() => this.getAdminInfo()} 
                        setAdminInfo = {(value, propertyName) => this.setAdminInfo(value, propertyName)}
                        setButtonsActive = {() => this.setButtonsActive()}
                        processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        /> 
                    : <span/>}
                                  
                    {this.props.selected === "eventsUpdate" ? <EventsUpdate
                         onOpen ={()=> this.inputSelected(1)}
                         selected={this.props.selected}
                         configAvailable={this.state.configurationAvailable}
                         adminInfo = {() => this.getAdminInfo()} 
                         processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        /> 
                    : <span/>}

                    {this.props.selected === "otherUpdates" ? <OtherUpdates
                         onOpen ={()=> this.inputSelected(2)}
                         selected={this.props.selected}
                         configAvailable={this.state.configurationAvailable}
                         adminInfo = {() => this.getAdminInfo()} 
                         processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        /> 
                    : <span/>}

                    {this.props.selected === "eventReports" ? <EventReports
                        onOpen ={()=> this.inputSelected(3)}
                        selected={this.props.selected}
                        configAvailable={this.state.configurationAvailable}
                        adminInfo = {() => this.getAdminInfo()} 
                        processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        /> 
                    : <span/>}

                    
                    {this.props.selected === "conditionReports" ? <ConditionReports
                        onOpen ={()=> this.inputSelected(4)}
                        selected={this.props.selected}
                        configAvailable={this.state.configurationAvailable}
                        adminInfo = {() => this.getAdminInfo()} 
                        processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        /> 
                    : <span/>}

                                                                                                           
                    <div className="col-sm-2 ml-5"> 
                        <Link to="admin-main" onClick={() => this.handleClick(0)}>
                            <LinkTab inputArr={this.state.inputArr[0]} name="Main Page" validToComplete="true"/>
                        </Link>
                        <Link to="events-update" onClick={() => this.handleClick(1)}>
                            <LinkTab inputArr={this.state.inputArr[1]} name="Events Update" validToComplete="true"/>
                        </Link>
                        <Link to="other-updates" onClick={() => this.handleClick(2)}>
                            <LinkTab inputArr={this.state.inputArr[2]} name="Other Updates" validToComplete="true"/>
                        </Link>
                        <Link to="event-reports" onClick={() => this.handleClick(3)}>
                            <LinkTab inputArr={this.state.inputArr[3]} name="Event Reports" validToComplete="true"/>
                        </Link>
                        <Link to="condition-reports" onClick={() => this.handleClick(4)}>
                            <LinkTab inputArr={this.state.inputArr[4]} name="Condition and Other Reports" validToComplete="true"/>
                        </Link>
                                                                                                                                        
                    </div>
                    </InputCompletedContext.Provider>
                </span>
        </span>  
    }           
    </>
}

    handleClick = index => {
        this.state.inputSelected(index);
    }

    setButtonsActive(){
       this.state.buttonsActive = true;
    }

    
    getAdminInfo()
    {
        return this.state.adminInfo;
    }

    setAdminInfo(value, propertyName = null)
    {
        if (propertyName)
        {
        this.state.adminInfo[propertyName] = value;
        this.setState({adminInfo: this.state.adminInfo});
        }
        else this.setState({adminInfo: value});
    }

    
async checkForAdminInfo(){
    const funcName = "loadGame";
    const urlParm = "?mode=validateAvailable";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,  
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200 || statusCode == 250){
                        if (output.body.configAvailable === true)
                        {
                            this.setState({configurationAvailable: true});
                        }
                        else
                        {
                            this.setState({configurationAvailable: false});
                        }
                        this.setState({gameConfigLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.processErrorReturn(errorObj);
                    }
                });
            };
        });
    }
        
    getEventsList(){
        //  const findList = (["New Roommate", "Old Car", "Puppy in Apartment"])
          const funcName = "eventsCollectionList";
          const urlParm = "";
          const uri = buildUrl(this.state.environment, funcName, urlParm);
          fetch (uri,  
              {method:'GET', headers: this.state.headers})
              .then(response => {
                  if (response.ok){
                      response.json().then(data => {
                          const output = JSON.parse(data);
                          const statusCode = output.statusCode;
                          if (statusCode == 200){
                              this.setAdminInfo(output.body, "eventsData");
                              this.setState({eventsLoaded: true});
                          }else{
                              const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                  status: statusCode, message: output.body.message};
                              this.processError(errorObj);
                          }
                      });
                  };
              });
      }
  
  
      getConditionsList() {
          const funcName = "eventConditionsList";
          const urlParm = "";
          const uri = buildUrl(this.state.environment, funcName, urlParm);
          fetch (uri, 
              {method:'GET', headers: this.state.headers})
              .then(response => {
                  if (response.ok){
                      response.json().then(data => {
                          const output = JSON.parse(data);
                          const statusCode = output.statusCode;
                          if (statusCode == 200){
                              this.setAdminInfo(output.body, "conditionsData");
                              this.setState({conditionsLoaded: true});
                          }else{
                              const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                  status: statusCode, message: output.body.message};
                              this.processError(errorObj);
                          }
                      });
                  };
              });
      }
          
    

processErrorReturn(errorObj){
    this.addRecordToLogfile(errorObj);
    if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
        alert ("Uh oh!  Something unexpected has occurred " +
            "error has been logged and will be addressed. " +
            "For now click OK, then return to the process " +
            "where you were and try again.");
        this.props.history.push('/');
    }
}

addRecordToLogfile(errorObj){
    if (errorObj.status == 250){
        errorObj.message = "No record found";
    }
    let subProcess = "";  
    if (errorObj.subProcess == undefined){
        subProcess = "unknown"
    }else{
        subProcess = errorObj.subProcess;
    }
    if (errorObj.errorObject == undefined){
        errorObj.errorObject = {};
    }
    console.log("errorObj: ", errorObj);
    const logFileBody =  {
        mainProcess: "Administration",
        subProcess: subProcess,
        function: errorObj.function,
        status: errorObj.status,
        message: errorObj.message,
        errorObj: errorObj.errorObject
    }
    console.log("logFileBody is: ", logFileBody)
    const urlParm = "";
    const uri = buildUrl(this.state.environment, "saveLogFile", urlParm);
    fetch (uri,  
        {method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(logFileBody)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        console.log("logfile create was successful");
                    }else{
                        console.log("Response from save log file not valid!" +
                         " status is: ", output.statusCode,
                         " message is: ", output.body);
                        alert ("Response from save log file not valid!");
                           
                    }
            });
        }
    });
  
}

}

export default withRouter(Administration);