const updateUnlockedFeaturesInfo = {
    currentCash: 77777.77, 
    unlockedFeatures:  [
        {
          "featureName": "funStuff",
          "setup": "none",
          "level": 3,
          "lastNotifyDate": "2049-02"
        },
        {
          "featureName": "savings",
          "setup": "active",
          "level": 1,
          "lastNotifyDate": "2033-02"
        },
        {
          "featureName": "creditCard",
          "setup": "active",
          "level": 7,
          "lastNotifyDate": "2049-02"
        },
        {
           "featureName": "testFeature",
           "setup": "active",
           "level": 7,
           "lastNotifyDate": "2024-02"
          }
      ],
    iconTable: [
        {
          "iconName": "gameBasicHelp2",
          "iconCategory": "information",
          "className": "small_sign_thumbnail_img information-sign-position",
          "imageSrc": "gameHelp.png",
          "validInd": true,
          "firstTime": false,
          "additionalInfo": "",
          "dateAdded": "2023/07/27"
        },
        {
          "iconName": "funStuffNewLevel",
          "iconCategory": "goodNews",
          "className": "small_sign_thumbnail_img good-news-funStuff-position",
          "imageSrc": "FunStuff.jpg",
          "validInd": true,
          "firstTime": false
        },
         {
          "iconName": "testUnlockFeaturesUpdate",
          "iconCategory": "goodNews",
          "className": "small_sign_thumbnail_img good-news-funStuff-position",
          "imageSrc": "FunStuff.jpg",
          "validInd": true,
          "firstTime": false
        }
      ],
}

export default updateUnlockedFeaturesInfo;