export const calcItemScore = (columnArr) => {     
     var nbrCorrect=0;
     var columnItems = [];
     var nbrInColumn = 0;
     var pctCorrect = 0;
     var columnPctCorrect = 0;
     var totPctCorrect = 0;
     var answerColumnsArr = [];
     var columnAlreadyInTable;
     var i;
     var j;  
     var m;  
     for (i=0; i<columnArr.length; i++) {
          columnItems=columnArr[i].items;
          if (columnItems.length >0) {
               nbrInColumn=columnItems.length;
               for (j=0; j<nbrInColumn; j++) {
                    if (columnItems[j].answer == columnArr[i].column && columnItems[j].order == (j+1)) {
                         nbrCorrect = nbrCorrect + 1;
                    }  
                    /* the answersColumnArr ends up containing the names of that should have some
                       items in them.  Columns that are supposed to end up empty messes up the percentage 
                       calculation if these columns are included in the pctCorrect calculation. */
                    columnAlreadyInTable = false;
                    for (m=0; (m<answerColumnsArr.length && columnAlreadyInTable == false); m++){
                         if (answerColumnsArr[m] == columnItems[j].answer) {
                              columnAlreadyInTable = true
                         }
                    }     
                    if (columnAlreadyInTable == false) {
                         answerColumnsArr.push(columnItems[j].answer)
                    }
               }               
               pctCorrect = ((nbrCorrect /nbrInColumn) * 100);  
               nbrCorrect = 0;
               columnPctCorrect = columnPctCorrect + pctCorrect;
          }  
     }
     totPctCorrect = (columnPctCorrect /  answerColumnsArr.length).toFixed(2);    
     return totPctCorrect;
 }




