import { doubleToStringWholeDollars, doubleToStringMonthly } from "./double_functions";
import { reformatDateToDisplay } from "./reformatDateToDisplay_function";

export const replaceTextInString = (textString, replaceObj, searchStr, stringType) => {
     //  textString - required - this is the text that contains the string to be replaced.  
     //  replaceObj - required - this is an object that contains the replacement value.  Valid values
     //     are defined by this routine (ex:  replaceObj.amount used to replace "||Amount||").
     //  searchStr - optional - if the string to be replaced is not one of the standard strings
     //     supported by this process, use this parameter to define the string to be replaced.
     //   stringType - optional - if the searchStr is specified, then this can be used to 
     //      indicate if the replacement string is an amount or a date (special formatting is
     //      done for these types of strings)
      let revisedText = textString;
      let searchItem = "##########";
      if (searchStr != undefined){
           searchItem = "||" + searchStr + "||";
           if (stringType == undefined){
                stringType = "string";
            }
      }
      let stopReplace = false;
      do {   
            let startIdx1 = revisedText.indexOf("||");
            let stringLength = revisedText.length - startIdx1 + 1;
            let remainderString = revisedText.substr(startIdx1 + 2, stringLength);
            let replaceString = "";
            let replaceBy = "";
            let startIdx2 = remainderString.indexOf("||");
            let dollarAmt = 0;
            if (startIdx2 == -1){
                stopReplace = true;
            }else{
                stringLength = startIdx2 + 4;   // adding 4 to pick up the || on either side of string
                replaceString = revisedText.substr(startIdx1, stringLength);
                switch (replaceString){
                  case "||CareerName||":
                      replaceBy = replaceObj.careerName;
                      break;
                  case "||StartDate||":
                      let reformatDate = reformatDateToDisplay(replaceObj.jobStartDate)
                      replaceBy = reformatDate;
                      break
                  case "||StepCareerName||":
                        replaceBy = replaceObj.stepCareerName;
                        break;
                  case "||NbrMonths||":
                      replaceBy = replaceObj.nbrMonths;
                      break;
                  case "||Savings||":
                      dollarAmt = doubleToStringMonthly(replaceObj.savings);
                      replaceBy = dollarAmt;
                      break;
                  case "||MonthlyIncome||":
                      dollarAmt = doubleToStringMonthly(replaceObj.income);
                      replaceBy = dollarAmt;
                      break; 
                  case "||Number||":
                        replaceBy =replaceObj.number;
                        break
                  case "||Amount||":
                        replaceBy = doubleToStringMonthly(replaceObj.amount);
                        break
                  case "||friendName||":
                        replaceBy = replaceObj.friendName;
                        break;  
                  case searchItem:
                      switch (stringType){
                         case "amount":
                            replaceBy = doubleToStringMonthly(replaceObj.amount);
                            break;
                        case "amountWholeDollars":
                            replaceBy = doubleToStringWholeDollars(replaceObj.amountWholeDollars);
                            break;
                        case "date":
                            replaceBy = reformatDateToDisplay(replaceObj.date);
                            break;
                        default:
                            replaceBy = replaceObj.string;
                            break;
                        }
                      break; 
                  default:
                      stopReplace = true;
                }            
            }
            // replace is not working so using another process
            let startString = revisedText.substr(0, startIdx1) + replaceBy;
            let endStringLength = revisedText.length - startIdx1 - startIdx2;
            let endStringStart = startIdx1 + startIdx2 + 4;
            let endString = revisedText.substr(endStringStart, endStringLength);
            revisedText = startString + endString;
          }
      while (revisedText.includes("||") && stopReplace == false);
             
   return revisedText;
   
}
export default replaceTextInString;
