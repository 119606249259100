const newGameFeedback = 
{
      "questionList": [
      {
        "question": "It is easy to determine how to play this game.",
        "questionNbr": 1,
        "answer": 5
      },
      {
        "question": "I would like to play this game again.",
        "questionNbr": 2,
        "answer": 5
      },
      {
        "question": "I liked the pictures and the general 'feel' of the game.",
        "questionNbr": 3,
        "answer": 5
      },
      {
        "question": "I thought the game was fun to play.",
        "questionNbr": 4,
        "answer": 5
      },
      {
        "question": "I could improve my score if I played the game again.",
        "questionNbr": 5,
        "answer": 4
      },
      {
        "question": "The events that are on the cards seem to be mostly bad things.",
        "questionNbr": 6,
        "answer": 3
      }
    ],
    "commentList": [
      {
        "question": "What did you like about playing the game?",
        "answer": "I like the detials and all the things you can do.",
        "questionNbr": 6
      },
      {
        "question": "What didn't you like about playing the game?",
        "answer": "nothing I really like it and it's really fun",
        "questionNbr": 7
      },
      {
        "question": "What did you learn from playing the game?",
        "answer": "I learned how to make basic decisions about money.",
        "questionNbr": 8
      },
      {
        "question": "If you did not activate a credit card and/or did not setup savings (unlocked features), can you explain why you made that decision?",
        "answer": "\\zzkjhguytrj,kuhtdkskheasuweberbje",
        "questionNbr": 9
      },
      {
        "question": "General Comment",
        "answer": "It's really fun ",
        "questionNbr": 99
      }
    ],
    "feedbackVersion": "01-2022.1",
    "role": "4th Grade",
    "emailAddress": "",
    "feedbackDate": "2023/12/6",
}

export default newGameFeedback;