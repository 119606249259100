import addToMonthlyCostArr from "./add_to_monthly_cost_arr";

export const currentLoadRolledIntoNewLoan = (currentHome, newHome, monthlyCostArr) => {
        monthlyCostArr = addToMonthlyCostArr("Total Outstanding Loan Amt", currentHome.totLoanAmt, 
             newHome.totLoanAmt, 3, "red", monthlyCostArr);
        let currentMonthlyHomeCosts = +currentHome.MortgageAmt + +currentHome.PMIMonthly + 
            +newHome.HomeHOAAmt - +currentHome.RoommateShareOfMortgage +
            +currentHome.HomeInsAmt;
        let newMonthlyHomeCosts = +newHome.MortgageAmt + +newHome.PMIMonthly +
            +newHome.HomeHOAAmt - +newHome.RoommateShareOfMortgage +
            +newHome.HomeInsAmt;
        monthlyCostArr = addToMonthlyCostArr("Total Monthly Amount (Mortgage, Mortgage Insurance, HOA, Roommate Rent)", 
            currentMonthlyHomeCosts, newMonthlyHomeCosts, 40, "red", monthlyCostArr);
        return monthlyCostArr;
}

export default currentLoadRolledIntoNewLoan;