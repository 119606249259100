const ChoosingCareerChallenge1Dataset = {
     /*  spaces between content in items ensures that content is lined up correctly on the modal
        the column property is used to indicate the correct answer.   */
        items: {
           
            "item-1": { id: "item-1", content: "Tom enjoys being around people. " +
                    "He really likes playing sports.  " +
                    "He likes variety and surprises. He is able to " +
                    "complete a lot of different work in a limited time.",
                    answer: "column-3" },
            "item-2": { id: "item-2", content: "JoAnna believes that everything has a place. She enjoys " +
                    "being around other people. She does not like technical writing, but she is great at " +
                    "speaking in front of an audience. ",
                    answer: "column-5" },
            "item-3": { id: "item-3", content: "Max enjoys math and working with numbers. " +
                    "He does a great job of presenting things in front of an audience and " +
                    "he is good at writing. He generally likes to work by himself.", 
                    answer: "column-4" },
            "item-4": { id: "item-4", content: "Nancy is good at editing and finding all of the " +
                    "small errors that others miss.  She loves to work " +
                    "on the computer and knows a lot about the apps that she uses.  Nancy also loves " +
                    "solving challenging logic problems.", 
                    answer: "column-2" },
           
        },
        columns: {
            "column-1": { id: "column-1", title: "Job Placement Individuals", 
                itemIds: ['item-1', 'item-2', 'item-3', "item-4"] },
            "column-2": { id: "column-2", title: "Software Developer: Technical expertise, " +
                "problem solving, detail oriented", itemIds: [] },
            "column-3": { id: "column-3", title: "Registered Nurse: Interpersonal skills, " +
                "multi-tasking, physical stamina, job variety", itemIds: [] },
            "column-4": { id: "column-4", title: "Accountant: verbal & written communication " +
                "math & problem solving. ", itemIds: [] },
            "column-5": { id: "column-5", title: "Elementary Teacher:  interpersonal " +
                "skills, verbal communications, organized", itemIds: [] },
                  
        },
        columnOrder: ["column-1", "column-2", "column-3", "column-4", "column-5"]
    }

export default ChoosingCareerChallenge1Dataset