const SavingsExerciseDataset =[
      {exerciseName: "SavingsAmount", 
              text:  "In this example, only the Savings Amount has been changed.  The Interest Rate and Number of Years " +
                     "are the same for each of the lines.  Note that the Total Savings Amount and Total Savings With Interest " +
                     "both increase (which is likely no surprise) as the Savings Amount gets larger. ",
              conclusion: "It is a good idea to save as much as you can afford in order to maximize the total amount that " +
              "you have saved over time.",
              parametersArr: [{intRate: .10, savingsAmount: 100, nbrYears: 10},
                            {intRate: .10, savingsAmount: 200, nbrYears: 10},
                            {intRate: .10, savingsAmount: 500, nbrYears: 10},
                            {intRate: .10, savingsAmount: 1000, nbrYears: 10},
                            {intRate: .10, savingsAmount: 2000, nbrYears: 10}
                           ]   
      },
      {exerciseName: "InterestRate", 
              text:  "In this example, only the Interest Rate has been changed.  The Monthly Savings Amount and Number of Years " +
                     "are the same for each of the lines.  The Total Yearly Savings is the same for all 5 lines but the " +
                     "Total Savings With Interest increases as the Interest Rate is increased.",
              conclusion: "If you are able to increase the amount of interest that your savings earn, this " +
                     "will increase the total amount that you are able to save.",     
              parametersArr: [{intRate: .03, savingsAmount: 500, nbrYears: 10},
                            {intRate: .05, savingsAmount: 500, nbrYears: 10},
                            {intRate: .07, savingsAmount: 500, nbrYears: 10},
                            {intRate: .10, savingsAmount: 500, nbrYears: 10},
                            {intRate: .12, savingsAmount: 500, nbrYears: 10}
                           ]   
      },
      {exerciseName: "NbrYears", 
              text:  "In this example, only the Nbr of Years has been changed.  The Monthly Savings Amount and Interest Rate " +
                     "are the same for each of the lines.  Note that the Yearly Savings Amount is the same for all 5 lines but the " +
                     "Total Savings With Interest increases as the Number of Years is increased.  While this might not be a surprise " +
                     "compare the Total Savings With Interest in line 1 with this amount in line 5.... WOW! ", 
              conclusion: "Start saving when you are young since that will give your money more " +
                     "time to grow which makes a huge difference in the total amount that you have in savings.",
              parametersArr: [{intRate: .10, savingsAmount: 200, nbrYears: 10},
                                   {intRate: .10, savingsAmount: 200, nbrYears: 15},
                                   {intRate: .10, savingsAmount: 200, nbrYears: 20},
                                   {intRate: .10, savingsAmount: 200, nbrYears: 25},
                                   {intRate: .10, savingsAmount: 200, nbrYears: 30}
                                   ]   
              },
     
      
]

export default SavingsExerciseDataset;