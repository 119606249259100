const MoneyMgmtChallenge2Dataset = {
   
items: {
    "item-1": { id: "item-1", content: "You know how much you can spend or save each month.", 
            answer: "column-2" },
    "item-2": { id: "item-2", content: "It lets you practice your basic math skills.",
            answer: "column-1" },
    "item-3": { id: "item-3", content: "It helps you review amounts to identify things that " +
            "you might want to reduce in order to increase the amount you can spend each month " +
            "(Monthly Net Income). ", 
            answer: "column-2" },
    "item-4": { id: "item-4", content: "It helps to ensure that you will have enough money " +
            "to cover monthly expenses.",
            answer: "column-2" },
    "item-5": { id: "item-5", content: "It lets you spend as much as you want because you " +
            "will know that you have overspent.", 
            answer: "column-1" },
    "item-6": { id: "item-6", content: "It allows you to project the amount of money that you " +
            "will have in future years to determine if you will be meeting your financial goals.", 
            answer: "column-2" },   
    "item-7": { id: "item-7", content: "It is a good use of a spreadsheet tool that can " +
            "easily calculate sums of numbers. ",
            answer: "column-1" },  
},

columns: {
    "column-1": { id: "column-1", title: "Considerations", 
        itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7"] },
    "column-2": { id: "column-2", title: "Advantages of Having a Monthly Plan", itemIds: [] },
},
columnOrder: ["column-1", "column-2",]
}

export default MoneyMgmtChallenge2Dataset