import { doubleToStringMonthly } from "./double_functions";
import { reformatDateToDisplay } from "./reformatDateToDisplay_function";

export const parseTextForDictionary = (textString) => {
     //  textString - required - this is the text that contains the string to be examined for dictionary elements  
     //  textType - required - this is just passed through to the return array/object.
      let inputText = textString;
      let stopSearch = false;
      let returnTextArr = [];
      if (textString.includes("<<")){
           //  this text contains a dictionary entry and will be processed by the while-loop below
      }else{
           returnTextArr = [{text: textString, clickable: false}];
      }
      let textObj = {};
      let stringLength = 0;
      do {   
            let startIdx1 = inputText.indexOf("<<");
            stringLength = inputText.length - startIdx1 + 1;
            let dictionaryString = inputText.substr(startIdx1 + 2, stringLength);
            let startIdx2 = dictionaryString.indexOf(">>");
            if (startIdx2 == -1){
                stopSearch = true;
            }else{
                const startText = inputText.substr(0, startIdx1-1);
                textObj = {text: startText, clickable: false,
                     lineNbr: returnTextArr.length};
                returnTextArr.push(textObj);
                stringLength = startIdx2;
                dictionaryString = dictionaryString.substr(0, stringLength);
                textObj = {text: dictionaryString, clickable: true,
                   lineNbr: returnTextArr.length};
                returnTextArr.push(textObj);
                startIdx2 = inputText.indexOf(">>");
                stringLength = inputText.length - startIdx2 + 2;
                let lastText = inputText.substr(startIdx2 + 2, stringLength);
                const startIdx3 = lastText.indexOf("<<");
                if (startIdx3 == -1){
                    //  there are no more dictionary terms in this text
                    stringLength = lastText.length; 
                    lastText = lastText.substr(0, stringLength);
                    textObj = {text: lastText, clickable: false, lineNbr: returnTextArr.length};
                    returnTextArr.push(textObj);
                }
                inputText = lastText;
            }
          }
      while (inputText.includes("<<") && stopSearch == false);
              
   return returnTextArr;
   
}
export default parseTextForDictionary;
