import {Component} from 'react';
import InLineTextCard from '../shared-components/inline_text_card';
import './ExtraCredit/extraCredit.css';


class TestQuestionsAnswerCard extends Component {
       
    constructor(props) {
        super(props);
        this.state = {
           checked: false,
        }; 
        this.answerCount = 0;
        this.onChangeValue = this.onChangeValue.bind(this);
    }
     
    onChangeValue(event) {
        this.checked = event.target.checked;
        let slctChange = {answer: event.target.value, checked: this.checked};
        this.props.onSelect(slctChange);
    }

            
    render() 
    {   
        this.textClass = "checkbox-text";
        switch(this.props.screenSize){
            case "narrow":
                this.textClass += " very-small-text";
                break;
            case "medium":
                this.textClass += " small-text-2";
                break;
            default:
                break;
        }
        if (this.props.dataset.textType === "answer"){
            this.answerText = "   " + this.props.dataset.text;
            if (this.props.dataset.clickable === true){
                this.textClass = this.textClass + " font-italic text-primary underline-this-text"
            }
            this.answerCount += 1;
        }          
        this.inlineText = this.props.dataset.textArr.map((textArr, index) =>
            <InLineTextCard key={index} textString={textArr} 
                onClick={(dictionaryTerm) => this.props.onClicked(dictionaryTerm)}
            />        
    );
   
    return <>
        {this.props.dataset.textType === "question" ?
            <div>
                &nbsp;
                <span className="" >              
                    {this.inlineText}
                    {this.props.answerType === "multiple" ?
                        <span className="text-success font-weight-bold">&nbsp;&nbsp;(Select all that apply.)</span>
                    :
                        <span className="text-success font-weight-bold">&nbsp;&nbsp;(Select only one answer.)</span>
                    }
                </span>
            </div>
        :
            <span>
            {this.props.answerType === "multiple" ?
                <div className="ml-4">
                        <label className="checkbox-label">
                            <input type="checkbox" 
                                onChange={this.onChangeValue} value={this.props.dataset.answer}
                                    checked={this.props.dataset.checked}/>
                                    <span className={this.textClass}>
                                        {this.inlineText}
                                    </span>
                                
                        </label>
                    
                </div>
            :
                <div>
                    <span className="ml-4">
                        <input type="radio" className="radio-format" value={this.props.dataset.answer} name="site" 
                            onChange={this.onChangeValue}
                            checked={this.props.dataset.checked}/> 
                                <span className={this.textClass}>
                                    {this.inlineText}
                                </span>
                    </span>
                </div>
            }
            </span>
        }
     
    </>
    }
}

export default TestQuestionsAnswerCard;