
import DataObjectsTemplate from "../../datasets/data_object_templates";

export const findDataObject = (objectName) => {

      let dataObjectList = DataObjectsTemplate;
      let selectedObject = dataObjectList.find(elem => elem.dataType === objectName);
               
   return selectedObject;
   
}
export default findDataObject;




