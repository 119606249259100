import addToMonthlyCostArr from "./add_to_monthly_cost_arr";

export const monthlyRentAmtPaidByRoommate = (currentHome, newHome, monthlyCostArr) => {
    monthlyCostArr = addToMonthlyCostArr("Monthly Amount Paid by Roommate(s)",
        currentHome.RoommateShareOfMortgage, newHome.RoommateShareOfMortgage,
        11, "black", monthlyCostArr);
    let currentMonthlyRevenueLoss = +currentHome.RoommateShareOfMortgage - 
        +currentHome.MortgageAmt - +currentHome.HomeHOAAmt;
    let newMonthlyRevenueLoss =  +newHome.RoommateShareOfMortgage - 
        +newHome.MortgageAmt - +newHome.HomeHOAAmt;
    if (currentMonthlyRevenueLoss < 0 && newMonthlyRevenueLoss < 0){ 
        monthlyCostArr = addToMonthlyCostArr("Mortgage Amount Deducted each Month", 
            currentMonthlyRevenueLoss, newMonthlyRevenueLoss, 12, "black", monthlyCostArr);
    }
    if (currentMonthlyRevenueLoss < 0 && newMonthlyRevenueLoss > 0){
        monthlyCostArr = addToMonthlyCostArr("Mortgage Amount Deducted each Month", 
            currentMonthlyRevenueLoss, "", 12, "black", monthlyCostArr);
        monthlyCostArr = addToMonthlyCostArr("Rent Amount Greater Than Mortgage", 
            "", newMonthlyRevenueLoss, 13, "black", monthlyCostArr);
    }
    if (currentMonthlyRevenueLoss > 0 && newMonthlyRevenueLoss < 0){
        monthlyCostArr = addToMonthlyCostArr("Rent Amount Greater Than Mortgage", 
            currentMonthlyRevenueLoss, "", 12, "black", monthlyCostArr);
        monthlyCostArr = addToMonthlyCostArr("Mortgage Amount Deducted each Month", 
            "", newMonthlyRevenueLoss, 13, "black", monthlyCostArr);
    }
    if (currentMonthlyRevenueLoss > 0 && newMonthlyRevenueLoss > 0){ 
        monthlyCostArr = addToMonthlyCostArr("Rent Amount Greater Than Mortgage", 
            currentMonthlyRevenueLoss, newMonthlyRevenueLoss, 12, "black", monthlyCostArr);
    }
    return monthlyCostArr;
}

export default monthlyRentAmtPaidByRoommate;