const ConditionClasses = ["car",
                          "home",
                          "roommate",
                          "furniture",
                          "career",
                          "college",
                          "careerRelated",
                          "job",
                          "manager",
                          "game",
                          "cash",
                          "boat",
                          "recreationVehicle",
                          "horse"
                    ]
      

export default ConditionClasses;