function Footer(props) {
    return <>
      <span className="row w-100 footer mx-0 align-content-center">
        <a className="text-center mt-3 w-100 text-secondary" id="footer-link" >
          Images provided by Freepik
          </a>
        <p className="text-center w-100 text-secondary">
          Copyright @ 2021 Moneytrain. All rights reserved.
          </p>
      </span> 
    </>
}
export default Footer;