const LifeStyleButtonChoices =[
  {
    "category": "clothesShopping", "type": "NameBrand", "activity": 4, 
        "options": ["FairDachi", "Gooshie", "Prodo", "Mooie Batan", "Kanzoh"], 
  },
  {
    "category": "clothesShopping", "type": "Department", "activity": 3,
        "options": ["KC Benney", "Trolls Dept Store", "JayCee Panny", "Woorlingtan", "Seers, Cahls", "Bold Army, "],
  },
  {
    "category": "clothesShopping", "type": "Discount", "activity": 2, 
        "options": ["TK Wax", "Parshalls", "Tergat", "Fordstram Pack", "Mallwart", "Dress for More"]
  },
  {
    "category": "clothesShopping", "type": "Used", "activity": 1, 
        "options": ["Goodbill", "Thread-Down", "Donation Central", ]
  },
  {
    "category": "diningOut", "type": "FineDining", "activity": 4, 
       "options": ["Ooth Bliss Steaks", "LoneDish Frill", "McDormack & Gritz", "Horgan's Steakhouse" ]
  },
  {
    "category": "diningOut", "type": "GoodDining", "activity": 3, 
       "options": ["Birribas", "SneezeCakes", "WrongLorn Steaks",  ]
  },
  {
    "category": "diningOut", "type": "AverageDining", "activity": 2,  
       "options": ["Willies", "Molive Larden", "Off the Order", "DappleSees", "Snacker Carrell", "Bennys"]
  },
  {
    "category": "diningOut", "type": "DriveThru", "activity": 1, 
       "options": ["MacDougals", "Bendys", "Burger Prince", "Waco Nell", "Kentrucky Grilled" ]
  },
    
  
];

export default LifeStyleButtonChoices;