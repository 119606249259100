import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EventsRspPossibleModal from './events_rsp_possible_modal';
import { editText } from './editEventCollectionProcesses/editText';
import {initializeErrorArray} from './objects/initialize_error_array';
import { isNumeric } from '../../objects/CommonUse/checkIfNumeric';

class EventsResponseModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      nextTitle: "",
      nextNbrMonths: "",
      dataValidSw: true,
      openResponsePossible: false,  
    }    
    this.oddsArr = [];
    this.totalOdds = 0;
    this.errArray = initializeErrorArray(3);
    this.possibleArr = [];
    this.responseArr = [];
}

editInput(){
    this.dataValid = true;
    this.errArray = initializeErrorArray(3);
    this.getFormValues();
    this.editFormFields();
     
    if (this.dataValid === true){
       this.openResponsePossibleModal();  
       this.setState({dataValidSw: true});
    }else{
       this.setState({dataValidSw: false}); 
    }
}

getFormValues(){
    this.btnText = document.getElementById("btnText").value;
    this.itemDesc = document.getElementById("itemDesc").value;
    this.rltnshpPoints = document.getElementById("rltnshpPoints").value;
}

editFormFields(){
    this.errorMsg = editText(this.btnText, "Response", "Button Text", 50);
    this.checkErrorArray(0);
    if (this.itemDesc !== ""){
        this.errorMsg = editText(this.itemDesc, "Response", "Item Desc", 50 );
        this.checkErrorArray(1);
    }
    if (this.rltnshpPoints !== ""){
        this.editRltnshpPoints();
    }
 }
 
 editRltnshpPoints(){
    const validRltnshpPoints = isNumeric(this.rltnshpPoints);
    if (validRltnshpPoints === false){
      this.errorMsg = "Relationship Points must be numeric";
      this.checkErrorArray(2);
    }
  }
   
  checkErrorArray(msgIndex){
    if (this.errorMsg !== undefined && this.errorMsg !== ""){
        this.errArray[msgIndex] = {textType: "text-danger", 
            message: this.errorMsg.message};
        this.dataValid = false;
    }
 }

closeRspPossibleModal(){
    this.setState({openResponsePossible: false});
}

openResponsePossibleModal(){
    sessionStorage.setItem("eventResponseBtnText", this.btnText);
    this.setState({openResponsePossible: true});
}

saveResponsePossibleForm(possibleObj){
    this.possibleArr.push(possibleObj);
    this.btnText = sessionStorage.getItem("eventResponseBtnText");
    let odds = possibleObj.odds;
    this.oddsArr.push(odds);
    this.totalOdds = this.oddsArr.join();
    this.setState({openResponsePossible: false});
}

nextBtnText(buttonClicked){
    let currentPossible = JSON.parse(JSON.stringify(this.possibleArr));
    const responseObj = {btnText: this.btnText,  rltnshpScore: this.rltnshpPoints, 
        itemDesc: this.itemDesc, possibleEffects: currentPossible}; 
    this.responseArr.push(responseObj);
    this.possibleArr = [];
      
    if (buttonClicked === "done"){
        const dataObj = {dataType: "responses", responses: this.responseArr};
        this.props.onDone(dataObj);
    }
    this.btnText = undefined;
    this.possibleArr = [];
    this.totalOdds = 0;
}

render() {
                           
return <>
    {this.state.openResponsePossible ?
          <EventsRspPossibleModal openModal={this.state.openResponsePossible} 
                totalOdds={this.state.totalOdds}
                eventTitles={this.props.eventTitles}
                condNames={this.props.condNames}
                onClose={() => this.closeRspPossibleModal()} 
                onSubmit={(responseObj) => this.saveResponsePossibleForm(responseObj)}
          />
    :
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="med" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Events Collection Form - Responses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
 
            <div className="text-left">
                  <p>Please complete all fields on this form and then click 
                     'Submit'.</p>
                <div>
                    <label className="font-weight-bold" htmlFor="btnText">
                        BtnText:&nbsp;&nbsp;</label>
                        <span className={this.errArray[0].textType}>
                        &nbsp;{this.errArray[0].message}</span>
                    <input type="text" className="col-lg" id="btnText" name="btnText"
                        value={this.btnText}></input> 
               </div>
               <div>
                    <label className="font-weight-bold" htmlFor="itemDesc">
                        Item Description:&nbsp;&nbsp;</label>
                        <span className={this.errArray[1].textType}>
                        &nbsp;{this.errArray[1].message}</span>
                    <input type="text" className="col-lg" id="itemDesc" name="itemDesc"></input> 
               </div>
               <div className="mt-2">
                   <label className="font-weight-bold" htmlFor="rltnshpPoints">
                        Relationship Points:&nbsp;&nbsp;</label>
                   <input type="text" className="col-small" id="rltnshpPoints" name="rltnshpPoints"></input> 
                   <span className={this.errArray[2].textType}>
                          &nbsp;{this.errArray[2].message}</span>
                </div>
                    
            </div>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
              {this.state.dataValidSw ?
                <div></div>
              :
                <div className="text-danger">Correct errors and try-again.</div>
              }
              <Button variant="secondary"   onClick={() => this.nextBtnText("done")}>
                    Done
              </Button>
              <Button variant="secondary"   onClick={() => this.nextBtnText("next")}>
                    Next BtnText
              </Button>
              <Button variant="primary"  onClick={() => this.editInput()}>
                  Add Possible Values
              </Button>
        </Modal.Footer>
    </Modal>
    }
  </>
}

}

export default EventsResponseModal;