

export const getCurrentDateTime = () => {

   const current = new Date();
   const year = current.getFullYear();
   const day = current.getDate();
   const month = current.getMonth()+1;
   let fullMonth = month.toString();
   if (month < 10){
         fullMonth = "0" + fullMonth;
   }
   let fullDay = day.toString();
   if (day < 10){
        fullDay = "0" + fullDay;
   }
   const date = year + "/" + fullMonth + "/" + fullDay;
   const currTime = new Date().toLocaleTimeString();
   const timeObj = {date: date, time: currTime, dateTime: date + "%" + currTime};
              
   return timeObj;
}
export default getCurrentDateTime;
 




