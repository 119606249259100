const LoanChallenge2InitialText ="You are the loan officer at a bank (woo-hoo). Several individuals have " +
"requested loans over the past week. A summary of the key information in " + 
"their loan application is provided in the 'Loan Request Information' column. " +
"Review this information and drag the loan request to the correct box to " +
"indicate if you would approve or deny the loan.  The monthly 'discretional income' is the " +  
"amount that is leftover each month after the individual has paid their monthly bills. " +
"Feel free to use the loan calculator if you wish to determine if the monthly pay amount " +
"that will be required for each loan. When you have moved all loans into either the approved " +
"or denied column, click the 'Done' button."

export default LoanChallenge2InitialText 
