import {Component} from 'react';

class NumberedListCard extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false};
    }

    render() {
    return <> 
           
         <li>{this.props.text}</li>
           
    </>
    }
}

export default NumberedListCard;