const PrioritiesChallenge1Dataset = {
   
    items: {
        "item-1": { id: "item-1", content: "You need candy because Halloween is in a couple of weeks.", 
            answer: "column-1", order: 4 },
        "item-2": { id: "item-2", content: "Need to buy a monthly transit pass so that you will have a " +
            "way to get to and from work.", 
            answer: "column-1", order: 2 },
        "item-3": { id: "item-3", content:  "The refrigerator is full of food but it is not keeping the food cold. " +
               "You need to pay for someone to come and fix it.", answer: "column-1", order: 1 },
        "item-4": { id: "item-4", content: "Your best friend wants to play tennis in a few weeks. " +
            "You would need to buy a tennis racket in order to play.",  
            answer: "column-1", order: 5 },
        "item-5": { id: "item-5", content: "You do not have a warm coat and it is October.", 
            answer: "column-1", order: 3 },
    },

    columns: {
        "column-1": { id: "column-1", title: "Items to Purchase", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5"] },
        
        
    },
    columnOrder: ["column-1"]
}

export default PrioritiesChallenge1Dataset