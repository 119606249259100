import {healthInsCalcAmt} from "../monthlyCalc/healthInsCalc";


export const createHealthInsExpense = (expenseTable, jobHealthInsInd, currentGameDate, 
      startGameDate) => {
 
      // set current payroll tax expense item to 0 months
      for (let i=0; i<expenseTable.length; i++){
            if (expenseTable[i].expItem == "Health Insurance" &&
                  expenseTable[i].expNbrMonths == -1 &&
                  expenseTable[i].expStartDate <= currentGameDate){
                        expenseTable[i].expNbrMonths = 0;
            }
      }
        
      let healthInsExpenseObj = healthInsCalcAmt(jobHealthInsInd, currentGameDate, startGameDate);
      expenseTable.push(healthInsExpenseObj);
             
   return expenseTable;
   
}

