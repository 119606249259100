
export const setFriendDisplayClasses = (friendNbr) => {
      let defaultClass1 = "";
      let defaultClass2 = "font-weight-bold ";
      let remainder = friendNbr % 2;
      let leftRight = ""
      if (remainder == 1){
          leftRight = "Left";
      }else{
          leftRight = "Right";
      }
      let topBottom = "";
      let rowNbr = (friendNbr / 2).toFixed(0);
      if (rowNbr == 1){
           topBottom = "Top";
      }else{
           topBottom = "Bottom"
      }
      let friendPosition = " friend-position-" + topBottom.toLowerCase() + "-" + leftRight.toLowerCase();
      let displayClass1 = defaultClass1 + friendPosition;
      let displayClass2 = defaultClass2 + "text-" + leftRight.toLowerCase();
      let opinionClass = "speech-advice" + leftRight + topBottom + " " + leftRight.toLowerCase();

      let displayClassObject = {displayClass1: displayClass1, displayClass2: displayClass2, 
            opinionClass: opinionClass};
              
    return displayClassObject;
   
}
