const HaveFunInformationText =[
    {activityName: "Circus", rules: "Let the pictures take you away!!  " +
    "There will be ||nbrTimes|| puzzles.  Try to complete each puzzle in the " +
    "time that has been allotted. " + 
    "Good luck and have fun!", rulesBrief: "Rearrange the puzzle pieces.  Click the " +
     "'New Puzzle' button to get started. Click on one square and then click another " +
     "square and the two pieces will be exchanged."},
    {activityName: "ComedyClub", rules: "There's nothing better than a good laugh!!  " +
    "You will have ||nbrTimes|| minutes to listen to the comedian.  Hope you will have " +
    "fun with this activity.", rulesBrief: ""},
    {activityName: "Skiing", rules: "What a great day to go skiing!  There is a beautiful blue " +
    "sky and lots of fresh powder.  Your goal is to ski down the hill to the chairlift." +
    "You need to avoid the pine trees along the way.  If you run into a pine tree, you will find " +
    "yourself back at the top of the hill.  You will have ||nbrTimes|| attempts to complete the " +
    "ski runs. Good luck!", rulesBrief: "Ski down the hill using the arrow keys.  Avoid the trees."},
    {activityName: "Rafting", rules: "This is a perfect time for a raft trip!  There is a beautiful blue " +
    "sky and the rivers are running really fast.  Your goal is to cruise down the river to the beach." +
    "You need to avoid the rocks along the way.  If you run into a rock, you will find " +
    "yourself back at the top of the river.  You will have ||nbrTimes|| attempts to complete the " +
    "river trips. Good luck!", rulesBrief: "Guide the raft to the beach using the arrow keys.  Avoid the rocks."},
]
        
export default HaveFunInformationText 
 