import {Component} from 'react';
import "./Special/openEnvelope.css";
import EnvelopeLegendCard from './envelope_legend_card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

class EnvelopeLegend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayLegend: true,
        };
    }
    
    toggleLegend(){
        this.setState({displayLegend: !this.state.displayLegend});
    }

   
    render() {
        this.envelopeLegend = this.props.eventCategories.map((eventList, index) =>
            <EnvelopeLegendCard key={index} legendInfo={eventList} index={index}
                jobName={this.props.jobName}/>
        );
             
    return <>
       <span>
            {this.state.displayLegend ?
                <div className="envelope-legend-position"> 
                    <div className="font-weight-bold">Legend
                        <FontAwesomeIcon icon={faCaretDown} 
                            onClick={() => this.toggleLegend()}/> </div>
                            <div>
                                {this.envelopeLegend}
                            </div>
                    </div>
            :
                    <div className="envelope-legend-position">
                            <div className="font-weight-bold">Legend
                            <FontAwesomeIcon icon={faCaretRight}
                                onClick={() => this.toggleLegend()} /> 
                        </div>
                    </div>
            }
        </span>
    </>
    }

}

export default EnvelopeLegend;