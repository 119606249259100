import MoneyMgmtChallenge1Dataset from "../badgesDatasets/money_mgmt_challenge1_dataset";
import MoneyMgmtChallenge2Dataset from "../badgesDatasets/money_mgmt_challenge2_dataset";
import PrioritiesChallenge1Dataset from "../badgesDatasets/priorities_challenge1_dataset";
import PrioritiesChallenge2Dataset from "../badgesDatasets/priorities_challenge2_dataset";
import ChoosingCareerChallenge1Dataset from "../badgesDatasets/choosing_career_challenge1_dataset";
import ChoosingCareerChallenge2Dataset from "../badgesDatasets/choosing_career_challenge2_dataset";
import LivingBelowChallenge1Dataset from "../badgesDatasets/living_below_challenge1_dataset";
import LivingBelowChallenge2Dataset from "../badgesDatasets/living_below_challenge2_dataset";
import SavingMoneyChallenge1Dataset from "../badgesDatasets/saving_money_challenge1_dataset";
import SavingMoneyChallenge2Dataset from "../badgesDatasets/saving_money_challenge2_dataset"
import LoanChallenge1Dataset from "../badgesDatasets/loan_challenge1_dataset";
import LoanChallenge2Dataset from "../badgesDatasets/loan_challenge2_dataset";
// initial text
import MoneyMgmtChallenge1InitialText from "../../../dragNDropText/money_mgmt_challenge1_initial_text";
import MoneyMgmtChallenge2InitialText from '../../../dragNDropText/money_mgmt_challenge2_initial_text';
import PrioritiesChallenge1InitialText from '../../../dragNDropText/priorities_challenge1_initial_text';
import PrioritiesChallenge2InitialText from '../../../dragNDropText/priorities_challenge2_initial_text';
import ChoosingCareerChallenge1InitialText from '../../../dragNDropText/choosing_career_challenge1_initial_text';
import ChoosingCareerChallenge2InitialText from '../../../dragNDropText/choosing_career_challenge2_initial_text';
import LivingBelowChallenge1InitialText from '../../../dragNDropText/living_below_challenge1_initial_text';
import LivingBelowChallenge2InitialText from '../../../dragNDropText/living_below_challenge2_initial_text';
import SavingMoneyChallenge1InitialText from '../../../dragNDropText/saving_money_challenge1_initial_text';
import SavingMoneyChallenge2InitialText from "../../../dragNDropText/saving_money_challenge2_initial_text";
import LoanChallenge1InitialText from '../../../dragNDropText/loan_challenge1_initial_text';
import LoanChallenge2InitialText from '../../../dragNDropText/loan_challenge2_initial_text';
// hints text
import MoneyMgmtChallenge1HintsText    from "../badgesText/money_mgmt_challenge1_hints_text";
import MoneyMgmtChallenge2HintsText    from '../badgesText/money_mgmt_challenge2_hints_text';
import PrioritiesChallenge1HintsText   from '../badgesText/priorities_challenge1_hints_text';
import PrioritiesChallenge2HintsText  from '../badgesText/priorities_challenge2_hints_text';
import ChoosingCareerChallenge1HintsText from '../badgesText/choosing_career_challenge1_hints_text';
import ChoosingCareerChallenge2HintsText from '../badgesText/choosing_career_challenge2_hints_text';
import LivingBelowChallenge1HintsText from '../badgesText/living_below_challenge1_hints_text';
import LivingBelowChallenge2HintsText from '../badgesText/living_below_challenge2_hints_text';
import SavingMoneyChallenge1HintsText from '../badgesText/saving_money_challenge1_hints_text';
import SavingMoneyChallenge2HintsText from '../badgesText/saving_money_challenge2_hints_text';
import LoanChallenge1HintsText from '../badgesText/loan_challenge1_hints_text';
import LoanChallenge2HintsText from '../badgesText/loan_challenge2_hints_text';
//objects
import {dragNDropBefore} from '../../../objects/DragNDrop/drag_n_drop_before_function';
import {doubleToStringMonthly} from '../../../objects/CommonUse/double_functions';

export const buildBadgeDragNDropDataset = (badgeNbr) => {
    let dragNDropDataset = "";
    let hintTable = "";
    switch (badgeNbr){
        case 1:
            dragNDropDataset = dragNDropBefore("Money Management Basics", 
                MoneyMgmtChallenge1InitialText, "", "", "",
                MoneyMgmtChallenge1Dataset, "MoneyMgmtChallenge1", 99); 
            hintTable = MoneyMgmtChallenge1HintsText;
            break;
        case 2:
            dragNDropDataset = dragNDropBefore("Why Should You Develop a Monthly Plan?",
                MoneyMgmtChallenge2InitialText, "", "", "", 
                MoneyMgmtChallenge2Dataset, "MoneyMgmtChallenge2", 99, "");
            hintTable = MoneyMgmtChallenge2HintsText;
            break;
        case 3:
            dragNDropDataset = dragNDropBefore("Prioritize the Purchases",
                PrioritiesChallenge1InitialText, "med", "", "",
                PrioritiesChallenge1Dataset, "PrioritiesChallenge1", 0)
            hintTable = PrioritiesChallenge1HintsText;
            break;
        case 4:
            var initialText = PrioritiesChallenge2InitialText;
            const minAmount = PrioritiesChallenge2Dataset.minAmount;
            const maxAmount = PrioritiesChallenge2Dataset.maxAmount;
            initialText = initialText.replace("||maxAmount||", maxAmount);
            initialText = initialText.replace("||minAmount||", minAmount);
            dragNDropDataset = dragNDropBefore("What Should you buy with Limited Dollars?",
                initialText, "", "", "",
                PrioritiesChallenge2Dataset, "PrioritiesChallenge2", 99, true); 
            hintTable = JSON.parse(JSON.stringify(PrioritiesChallenge2HintsText));
            let maxAmountFmt = doubleToStringMonthly(maxAmount);
            let hintLine1 = PrioritiesChallenge2HintsText[0];
            hintLine1 = (hintLine1).replace("||maxAmount||", maxAmountFmt);
            let minAmountFmt = doubleToStringMonthly(minAmount);
            hintLine1 = (hintLine1).replace("||minAmount||", minAmountFmt);
            hintTable[0] = hintLine1;
            break;
        case 5:
            dragNDropDataset = dragNDropBefore ("Match the Person to the Job",
                ChoosingCareerChallenge1InitialText, "", "", "", ChoosingCareerChallenge1Dataset,
                "ChoosingCareerChallenge1", 1);
            hintTable = ChoosingCareerChallenge1HintsText;
            break;
        case 6:
            dragNDropDataset = dragNDropBefore("Prioritize the Influencing Factors",
                ChoosingCareerChallenge2InitialText, "md", "", "", ChoosingCareerChallenge2Dataset,
                "ChoosingCareerChallenge2", 99);
            hintTable = ChoosingCareerChallenge2HintsText;
            break;
        case 7: 
            dragNDropDataset = dragNDropBefore("What are the Advantages of Living Below Your Means?",
                LivingBelowChallenge1InitialText, "", "", "",
                LivingBelowChallenge1Dataset, "LivingBelowChallenge1", 99);
            hintTable = LivingBelowChallenge1HintsText;
            break;
        case 8:
            dragNDropDataset = dragNDropBefore("Good Ideas and Things to Avoid In Order to Live Below Your Means",
                LivingBelowChallenge2InitialText, "", "", "",
                LivingBelowChallenge2Dataset, "LivingBelowChallenge2", 99) 
            hintTable = LivingBelowChallenge2HintsText; 
            break;
        case 9:
            dragNDropDataset = dragNDropBefore("Categorize the Savings Scenarios",
                SavingMoneyChallenge1InitialText, "", "DoneCalculator", "Savings", 
                SavingMoneyChallenge1Dataset, "SavingMoneyChallenge1", 1);
            hintTable = SavingMoneyChallenge1HintsText;
            break;
        case 10:
            dragNDropDataset = dragNDropBefore ("Most Important Things to Know About Saving Money", 
                SavingMoneyChallenge2InitialText, "", "", "", 
                SavingMoneyChallenge2Dataset, "savingMoneyChallenge2", 99);
            hintTable = SavingMoneyChallenge2HintsText;
            break;
        case 11:
            dragNDropDataset = dragNDropBefore("Categorize the Loan Parameters",
                LoanChallenge1InitialText, "", "DoneCalculator", "Loan", 
                LoanChallenge1Dataset, "LoanChallenge1", 1);
            hintTable = LoanChallenge1HintsText;
            break;
        case 12:
            dragNDropDataset = dragNDropBefore ("Determine Which Loan Requests Should Be Approved", 
                LoanChallenge2InitialText, "", "DoneCalculator", "Loan", 
                LoanChallenge2Dataset, "loanChallenge2", 99);
            hintTable = LoanChallenge2HintsText;
            break;
            
    }
    let badgeDatasetObject = {dragNDropDataset: dragNDropDataset, hintTable: hintTable};
    return badgeDatasetObject;
   
}
export default buildBadgeDragNDropDataset;

