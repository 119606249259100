const CreditCardSetupIntro =[
     "You have been approved for a credit card.  If you accept the credit card, going forward, all amounts that are " +
     "less than $5.00 will be billed to the credit card.  The credit card amounts are due for payment " +
     "at the end of each month.  You can choose whether this monthly payment is made automatically or if you " +
     "wish to pay the credit card bill manually.  If you choose to pay the bill manually, you can do that " +
     "by returning to this process at the end of each month. " ,
                          
    "It is important to know that the bank will charge ||Number|| percent on any unpaid amounts at the end of " +
    "each month.  Also, the maximum credit amount has been set to ||Amount||.  If the outstanding balance  " +
    "on the card exceeds this maximum amount, you will no longer be able to use this credit card and all " +
    "amounts will revert to being paid by cash until a payment is made."                      
]

export default CreditCardSetupIntro;