import {Component} from 'react';
import { withRouter } from "react-router";
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class SpecialProcessMain extends Component {
    constructor(props) { 
        super(props);
        this.specialInfo = this.props.specialInfo();
        this.state = {
            previousProcess:  "",
            allCompleteText: "",
            processingCompleteSw: false,
            headers: setHeadersForFetch(),
            subProcess: "SpecialProcessMain",
            environment: Config.dataURL.currentEnv,
        }
    }

    componentDidMount(){
       //   this.loadGame();
       //   this.getConditionsList();
    
    }
   
  
    render() {
        let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard");
        this.className = screenParms.columnClass;
        this.specialInfo.loading = false;
        this.specialInfo.loadingConditions = false;
                          
    return <>
        <div className={this.className}>
        <div className="card mb-0 mt-4 rounded-corners-lg height-max">
            <h3 className="mt-2 text-center">Special Processes</h3>
            <h5 className="text-center">Main Page</h5>
            {this.specialInfo.loading || this.specialInfo.loadingConditions ?
                   <WaitTimeSpinner/>
            :
                 <span className="mt-2 ml-4 mr-4">
                    <div>This space is setup to allow development of 'experimental processes'. </div>
               
                </span>
            }
           </div>        
        </div>
     </>
    }

loadGame() {
    const funcName = "loadGame";
    const urlParm = "?mode=fullLoad";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
         {method:'GET', headers: this.state.headers})
         .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.props.specialInfo.gameData = output.body;
                        this.props.setSpecialInfo(output.body, 'gameData');
                        this.props.setSpecialInfo(false, "loading");
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
}

getConditionsList() {
    const funcName = "eventConditionsList";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.props.setSpecialInfo(output.body, "conditionsData");
                        this.props.setSpecialInfo(false, "loadingConditions");
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
    }

}

export default withRouter (SpecialProcessMain);