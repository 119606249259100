
export const calcInterestAmt = (amount, intPct, monthYear) => {

   let calcIntPct = 0;
   if (monthYear == "month"){
         calcIntPct = intPct / 12;
   }else{
         calcIntPct = intPct;
   }
   let newAmountRounded = (+amount * +calcIntPct).toFixed(4);
   return newAmountRounded;
}






