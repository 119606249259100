import { isNumeric } from '../../../objects/CommonUse/checkIfNumeric';

export const editSelectMonthArr = (selectedMonths, responseLit) => {

  var errorObj="";
  var validSelectedMonths = true;
  var i;

  if (selectedMonths === undefined){
     //  all is well, it is OK for this to be undefined};
   }else{
    for (i=0; i<selectedMonths.length && validSelectedMonths === true; i++){
        validSelectedMonths = isNumeric(selectedMonths[i]);
        if (validSelectedMonths === false){
            errorObj = {fieldClass: responseLit, fieldName: "Selected Months", fieldValue: selectedMonths.join(),
               message: "Selected Months m/b array of numerics"};
            break;
        }else{
            if (selectedMonths[i] < "01" ||  selectedMonths > "12"){
               errorObj = {fieldClass: responseLit, fieldName: "Selected Months", fieldValue: selectedMonths.join(),
                  message: "Selected months value m/b between 01 and 12"};
               break;
           }
        }
        
  }
    
}
   
  return errorObj;
 
}