import {Component} from 'react';


class WaitTimeSpinner extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true,
           };
    }
        

    render() { 
                 
    return <>
        <div className="wait-spinner-container spinner-position">
            <img   src="./assets/TrainOnCircleTrack.png"
                    className="money-train-spinner" alt="moneyTrain"/>
      
            <span className="decorative spinner-text-size spinner-text-position">MoneyTrain</span>
        </div>
    </>
    }
  
}
 
export default WaitTimeSpinner;