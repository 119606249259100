import assetExpLoanShortnameDataset from "../../datasets/asset_exp_loan__shortname_dataset";

export const findRemoveTableItem = (table, shortName, removeSw) => {
 
      let assetExpLoanType = assetExpLoanShortnameDataset;
      let itemType = "";
      let itemName = "";
      let itemDesc = "";
      let matchItemDesc = "";
      let objectFound = false;
      let tableObj = {};
      for (let i=0; i<assetExpLoanType.length; i++){
            if (shortName == assetExpLoanType[i].shortName){
                  itemType = assetExpLoanType[i].type;
                  itemName = assetExpLoanType[i].fullName;
                  itemDesc = assetExpLoanType[i].desc;
                  break;
            }
      }
   for (let i=0; i<table.length && objectFound == false; i++) {
      switch (itemType){
         case "asset":
               if (itemDesc == ""){
                  matchItemDesc = table[i].assetDesc;
               }else{
                  matchItemDesc = itemDesc;
               }   
               if (table[i].assetItem == itemName && table[i].assetDesc == matchItemDesc && 
                   table[i].assetNbrMonths != 0){
                  tableObj = JSON.parse(JSON.stringify(table[i]));
                  objectFound = true
                  if (removeSw == true){
                        table[i].assetNbrMonths = 0;
                  }
               }
               break;
         case "expense":
            if (itemDesc == ""){
                  matchItemDesc = table[i].expDesc;
            }else{
                  matchItemDesc = itemDesc;
            }
           
            if (table[i].expItem == itemName && table[i].expDesc == matchItemDesc &&
                table[i].expNbrMonths != 0){
                  tableObj = JSON.parse(JSON.stringify(table[i]));
                  objectFound = true;
                  if (removeSw == true){
                        table[i].expNbrMonths = 0;
                  }
               }
               break;
         case "loan":
            if (itemDesc == ""){
                  matchItemDesc = table[i].loanDesc;
            }else{
                  matchItemDesc = itemDesc;
            }
            if (table[i].loanType == itemName && table[i].loanDesc &&
                table[i].loanNbrMonths != 0){
                  tableObj = JSON.parse(JSON.stringify(table[i]));
                  objectFound = true;
                  if (removeSw == true){
                        table[i].loanNbrMonths = 0; 
                  }
               }
               break;
      }
          
  }
             
   return tableObj;
   
}





