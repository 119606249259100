
export const determineFriendComment = (commentTable, currentCommentNbr) => {
   
        let comment = commentTable[currentCommentNbr];
        let outCommentNbr = +currentCommentNbr + 1;
        if (outCommentNbr > commentTable.length - 1){
             outCommentNbr = 0;
        }
        let commentObj = {comment: comment, commentNbr: outCommentNbr};
  
    return commentObj;
}

