
export const rptMoodPointsByEvent = (eventsData, lowPoint, highPoint ) => {
    let rptArr = [];
    
    for (let i=0; i<eventsData.length; i++){
        if (i === 0){
            let headingLine = {eventID: "EventID", title: "EventTitle", description: "Event Description",
                eventCategory: "EventCategory", subCategory: "EventSubCategory", gamePhase: "GamePhase",
                btnText: "BtnText", closureTxt: "ClosureText",
                onString: "On", type: "Type", possibleValues: "Average Possible Values", triggerCond: "TriggerCondition",
                moodPoints: "MoodPoints", relationshipPoints: "Relationship Points" }; 
             rptArr.push(headingLine);
        }
        for (let j=0; j<eventsData[i].responses.length; j++){
            let response = eventsData[i].responses[j];
            if (eventsData[i].eventCategory ===  "job" ){
                for (let k=0; k<response.possibleEffects.length; k++){
                    let possible = response.possibleEffects[k];
            //      if (+possible.moodPoints < lowPoint || +possible.moodPoints > highPoint){
                        let totalValues = 0;
                        let nbrValues = 0;
                        for (let j=0; j<possible.possibleValues.length; j++){
                            totalValues += +possible.possibleValues[j];
                            nbrValues += 1;
                        }
                        let possibleValuesAvg = (totalValues / nbrValues).toFixed(0);
                      //  let possibleValues = possible.possibleValues.join(", ");
                        let rptLine = {eventID: eventsData[i].id, title: eventsData[i].title, description: eventsData[i].description,
                            eventCategory: eventsData[i].eventCategory,
                            subCategory: eventsData[i].careerTag, gamePhase: eventsData[i].gamePhase, 
                            btnText: response.btnText, closureTxt: possible.closureText,
                            onString: possible.on, type: possible.type, possibleValues: possibleValuesAvg,
                            triggerCond: possible.triggerCondition, moodPoints: +possible.moodPoints,
                            relationshipPoints: response.rltnshpPoints};
                        rptArr.push(rptLine);
            //      } 
                }
            }
        }
    }
         
    return rptArr;
   
}
export default rptMoodPointsByEvent;

