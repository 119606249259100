import GameChoiceMoodPoints from "../../../datasets/game_choice_mood_points";

export const createMoodPointArr = (choiceType) => {
    let gameChoiceArr = [];
    let gameChoiceMoodPoints = GameChoiceMoodPoints;
    let selectEntry = false;
    for (let i=0; i<gameChoiceMoodPoints.length; i++){
        selectEntry = false;
        if (choiceType == "all"){
              selectEntry = true;
        }else{
            if (choiceType == gameChoiceMoodPoints[i].choiceName){
                selectEntry = true;
            }
            if (choiceType == "roommate" &&
                gameChoiceMoodPoints[i].choiceType == "HomeRoommate"){
                    selectEntry = true;
            } 
        }
        if (selectEntry == true){
            let gameChoiceObj = {choiceType: gameChoiceMoodPoints[i].choiceType,
                value: "null"};
            switch (gameChoiceMoodPoints[i].choiceType){
                case "JobSalary":
                    gameChoiceObj.value = (+sessionStorage.getItem("salary") * 1).toFixed(2);
                    break;
                case "JobMatchScore":
                    const jobRank = sessionStorage.getItem("jobRank");
                    gameChoiceObj.value = jobRank;
                    break;
                case "HomeRoommate":
                    const roommates = sessionStorage.getItem("homeRoommates");
                    gameChoiceObj.value = roommates;
                    break;
                case "HomeType":
                    const homeLevel = sessionStorage.getItem("homeLevel");
                    gameChoiceObj.value = homeLevel;
                    break;
                case "FurnitureType":
                    const furnitureType = sessionStorage.getItem("furnitureName");
                    gameChoiceObj.value = furnitureType;
                    break;
                case "CarAge":
                    const yearAdj = sessionStorage.getItem("carYearAdj");
                    gameChoiceObj.value = yearAdj;
                    break;
                case "CarType":
                    const carType = sessionStorage.getItem("carSize");
                    gameChoiceObj.value = carType;
                    break;
            }
            gameChoiceArr.push(gameChoiceObj);
        }
    }
    return gameChoiceArr;
   
}
export default createMoodPointArr;
