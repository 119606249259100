
export const addToMonthlyCostArr = (label, amount1, amount2, order, positiveColor, monthlyCostArr) => {
         // positiveColor is the color to use for positive amounts
        let negativeColor = "";
        if (positiveColor === "black"){
            negativeColor = "red";
        }else{
            negativeColor = "black";
        }
        let color1 = positiveColor;
        if (amount1 !== ""  && +amount1 < 0){
            amount1 = -1 * +amount1;
            color1 = negativeColor;
        }
        let color2 = positiveColor;
        if (amount2 !== "" && +amount2 < 0){
            amount2 = -1 * +amount2;
            color2 = negativeColor;
        }
        const monthlyCostItem = {label: label, amount1: amount1, amount2: amount2, 
               order: order, color1: color1, color2: color2};
        monthlyCostArr.push(monthlyCostItem);
 
        return monthlyCostArr;
}
export default addToMonthlyCostArr;
