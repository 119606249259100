import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
//import "./Special/special.css";
import Button from 'react-bootstrap/Button';
import EventListDisplayCard from './event_list_display_card';


class SelectSkipConditionCheckModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         displayReport: false
      }    
    this.conditionFoundArr = [];
}

findCondName(){
    this.condName = document.getElementById("condName").value;
    this.checkConditionsList();
    this.setState({displayReport: true});
}

checkConditionsList(){
     this.conditionFoundArr = [];
     for (let i=0; i<this.props.eventsData.length; i++){
          let eventData = this.props.eventsData[i];
          if (eventData.conditionArr !== undefined){
              if (eventData.conditionArr.includes(this.condName)){
                  let reportObj = {eventTitle: eventData.title, condType: "select", eventID: eventData.id,
                    eventDesc: eventData.description, frequency: eventData.frequency }
                  this.conditionFoundArr.push(reportObj);
              }
          }
          if (eventData.skipConditionArr !== undefined){
            if (eventData.skipConditionArr.includes(this.condName)){
                  let reportObj = {eventTitle: eventData.title, condType: "skip", eventID: eventData.id,
                    eventDesc: eventData.description, frequency: eventData.frequency }
                  this.conditionFoundArr.push(reportObj);
            }
          }
     }
}
   
render() {
    this.eventList = this.conditionFoundArr.map((events, index) =>
        <EventListDisplayCard events={events}  key={index}  
            index={index}/>
              );
                
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="xl" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>List of Events for Specified Condition
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div className="text-left">
                  <p>Please complete the fields on this form and then click 
                     'Submit'.</p>
            </div>
            <div>
                  <label className="font-weight-bold" htmlFor="condName">
                        Condition Name:&nbsp;&nbsp;</label>
                  <input type="text" className="" id="condName" name="condName"></input> 
                  <div className="text-danger">
                          &nbsp;{this.state.condNameErrorMsg}
                  </div>
            </div>
            <Button variant="primary"  onClick={() => this.findCondName()}>
                   Submit
            </Button>
        
          
            {this.state.displayReport ?
                <span>
                    <div className="text-left mt-4"><b>Number of Events Found with This Condition:&nbsp;&nbsp;</b> {this.conditionFoundArr.length}</div>
               
                    <table className="table mt-2">
                      <tbody>
                          <tr>
                            <th>Event ID</th>
                            <th>Condition Type</th>
                            <th>Frequency</th>
                            <th>Event Title</th>
                            <th>Event Description</th>
                          </tr>
                            {this.eventList}
                      </tbody>
                    </table>
                </span>
              :   
                null
          }        
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
             
              <Button variant="primary"  onClick={() => this.props.onClose()}>
                   Close
              </Button>
              
        </Modal.Footer>
       
    </Modal>
    
  </>
  }
}

export default SelectSkipConditionCheckModal;