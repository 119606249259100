const DiscussionSavingsData =[
   {  //  student52
      "_id": {
        "$oid": "63d2f690efd24bab94ffeeda"
      },
      "gameID": "63cdd95d2904c3c3801b3dfe",
      "__v": 0,
      "currentIntRate": "0.02",
      "currentlyActive": false,
      "dateCreated": "2023/01/26",
      "dateUpdated": "2023/03/16",
      "intRateStartDate": "2025-04",
      "monthlySavingsAmt": "0",
      "savingsStartDate": null,
      "totalDeposits": "5000",
      "totalIntAmt": "234.2294",
      "totalOverDraftFees": "0",
      "totalSavings": "5234.23",
      "totalWithdrawals": "0.00"
    },
    {  // student59 
      "_id": {
        "$oid": "64011635b4f4b553e714cbe3"
      },
      "gameID": "64011299baeeb5722ca9db76",
      "__v": 0,
      "currentIntRate": "0.02",
      "currentlyActive": true,
      "dateCreated": "2023/03/2",
      "dateUpdated": "2023/03/2",
      "intRateStartDate": "2025-06",
      "monthlySavingsAmt": "11",
      "savingsStartDate": "2024-04",
      "totalDeposits": "19833",
      "totalIntAmt": "870.9596",
      "totalOverDraftFees": "0",
      "totalSavings": "20703.96",
      "totalWithdrawals": "0.00"
    }
   ]

export default DiscussionSavingsData