import carGameChoiceDialogDataset from '../DatasetsDialog/car_game_choice_dialog_dataset';
import homeGameChoiceDialogDataset from '../DatasetsDialog/home_game_choice_dialog_dataset';
import unlockedFeaturesDialogDataset from '../DatasetsDialog/unlocked_features_dialog_dataset';
import { findMatchingCarChangeDialog } from './find_matching_car_change_dialog';
import {findMatchingHomeChangeDialog} from './find_matching_home_change_dialog';
import {findMatchingUnlockedFeaturesDialog} from './find_matching_unlocked_features_dialog';

export const retrieveGameChoiceDialog = (parameters) => {
      
    let friendDialogTable = [];
    let dialogTable = [];
    switch (parameters.topic){
        case "carChange":
            dialogTable = carGameChoiceDialogDataset;
            friendDialogTable = findMatchingCarChangeDialog(parameters, dialogTable);
            break;
        case "homeChange":
            dialogTable = homeGameChoiceDialogDataset;
            friendDialogTable = findMatchingHomeChangeDialog(parameters, dialogTable);
            break;
        case "setupSavings":
            dialogTable = unlockedFeaturesDialogDataset;
            friendDialogTable = findMatchingUnlockedFeaturesDialog(parameters, dialogTable);
        case "setupCreditCard":
            dialogTable = unlockedFeaturesDialogDataset;
            friendDialogTable = findMatchingUnlockedFeaturesDialog(parameters, dialogTable);
    }            
    return friendDialogTable;
   
}
export default retrieveGameChoiceDialog;

