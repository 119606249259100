module.exports = global.config = {
    dataURL: {
        currentEnv: "test",
        production: "",   
        testUrlPrefix: "https://cvjb97clke.execute-api.us-east-1.amazonaws.com/",
        testUrlVersion: "latest", 
        prodUrlVersion: "", 
        prodUrlPrefix: "",
     }
    // other global config variables you wish
};