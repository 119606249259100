
export const calcRandomNumberWithMax = (maximum, includeMax, nbrDigits) => {
 
      let multiplier = 0;
      if (includeMax == undefined || includeMax == false){
            // Returns a random integer from 0 to maximum (maximum is a valid return value)
            multiplier = +maximum + 1;
      }else{
            // Returns a random integer from 0 to maximum minus 1 (maximum is not a valid return value)
            multiplier = maximum;
      }
      if (nbrDigits == undefined){
            nbrDigits = 1;
      }
      let randomArr = [];
      for (let i=0; i<nbrDigits; i++){
            let randomNbr = Math.floor(Math.random() * multiplier);
            randomArr.push(randomNbr);
      }
      let outRandomNbr = randomArr.join(", ");
      outRandomNbr = outRandomNbr.replaceAll(", ", "");
      outRandomNbr = +outRandomNbr;
                
      return outRandomNbr;
   
}
export default calcRandomNumberWithMax;





