
export const isNumeric = (value) => {
 
   var validNumeric = true;
   if (+value != parseFloat(value)) {
      validNumeric = false;
    }
            
   return validNumeric;
   
}
export default isNumeric;





