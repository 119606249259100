import {Component} from 'react';
import Button from 'react-bootstrap/Button';


class ImageCard extends Component {
       
    constructor(props) {
        super(props);
        this.state = {
            underscore: "./assets/Special/Underscore.png",
        }; 
        
    }
    
    clickImage(buttonType){
        if (this.props.imageInfo.onClick != "null"){
            if (buttonType != undefined){
                sessionStorage.setItem("funStuffButtonClicked", buttonType);
            }
            this.props.onClick(this.props.imageInfo);
        }
    }
       
    render() {
                
    return <>
          <span className="picture-container mt-1">
             {this.props.imageInfo.disable ?
                <img src={this.props.imageInfo.imageURL} className={this.props.imageInfo.imageClass} alt="image"/>
            :
                <img   onClick={() => this.clickImage()} disabled={this.props.imageInfo.disable}
                    src={this.props.imageInfo.imageURL} className={this.props.imageInfo.imageClass} alt="image"/>
             }
            {this.props.imageInfo.pictureText != undefined ?
                <div className="picture-text-bottom font-weight-bold">
                    {this.props.imageInfo.pictureText}
                </div>
            :
                null
            }
              {this.props.imageInfo.buttonType != undefined ?
                <span>
                    <div className="button-placement-bottom">
                        {this.props.imageInfo.buttonType == "AddDecline" ?
                            <span>
                                <Button variant="info" size="sm"
                                    onClick={() => this.clickImage("add")}>
                                    Add  
                                </Button>
                                <Button className="ml-2" variant="info" size="sm"
                                    onClick={() => this.clickImage("decline")}>
                                    Decline 
                                </Button>
                            </span>
                        :
                            null
                        }
                    </div>
                    <div className="button-placement-bottom2">
                        {this.props.imageInfo.buttonType == "ChangeRemove" ?
                            <span>
                                <Button variant="info" size="sm"
                                    onClick={() => this.clickImage("change")}>
                                    Change 
                                </Button>
                                <Button className="ml-2" variant="info" size="sm"
                                    onClick={() => this.clickImage("remove")}>
                                    Remove  
                                </Button>
                            </span>
                        :
                            null
                        }
                    </div>
                </span>
            :
                null
            }
        </span>
           
    </>
    }

    

}

export default ImageCard;