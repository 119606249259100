import ConfigFile from "../../../datasets/config_file";
import { createTableItem } from "../../../objects/AssetsExpenses/create_table_item";

export const createNewAssetItem = (nbrRoommates, gameDate, startGameDate, assetTable) => {
        let baseRentAmt = ConfigFile.homeItems.baseRentAmt;
        let totalRentAmt = +baseRentAmt * +nbrRoommates;
        let assetDesc = "Monthly amount roommate pays you for rent.";
        if (nbrRoommates > 1){
            assetDesc = "Monthly amount roommates pay you for rent.";
        }
        let assetObj = createTableItem("roommateRent", assetDesc, gameDate, totalRentAmt,
            startGameDate, true);
        assetTable.push(assetObj);
    const returnObj = {roommateShareOfMortgage: assetObj.assetAmt, assetTable: assetTable};
    return returnObj;
}

export default createNewAssetItem;