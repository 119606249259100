const ConditionTriggeredByList = 
        ["carChange",
         "homeChange",
         "event",
         "gameStart",
         "jobChange",
         "monthly",
         "career-change",
         "gameBoard",
         "funStuff",
         "unlockedFeatures" ]
      

export default ConditionTriggeredByList;