
export const setModalSize = (screenSize) => {

      let modalSize = ""
      if (screenSize == "narrow"){
          modalSize = "modal-90";
      }else{
          if (screenSize == "medium"){
              modalSize = "modal-80";
          }
      }
   
   return modalSize;
}

export default setModalSize;