const ResponseCategoryList =[
  {
    "value": "cash",
    "selection": "Update Current Cash"
  },
  {
    "value": "asset",
    "selection": "Update Asset Table"
  },
  {
    "value": "expense",
    "selection": "Update Expense Table"
  },
  {
    "value": "loan",
    "selection": "Update Loan Table"
  }
];

export default ResponseCategoryList;