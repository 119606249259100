const SavingsPolicyOptions =[
  {type: "new", optionList: [
    {index: 0, value: "none", selection: "No Savings Policy Selected"},
    {index: 1, value: "accept", selection: "Setup 401K Savings"},
    {index: 2, value: "decline", selection: "Decline 401K Savings"}
    ]},
    {type: "existing", optionList: [
      {index: 0, value: "none", selection: "No Savings Policy Selected"},
      {index: 1, value: "retain", selection: "No Change to Existing Savings Policy"},
      {index: 2, value: "change", selection: "Change Existing 401K Savings Policy"},
      {index: 3, value: "decline", selection: "Stop/Suspend 401K Savings"}
      ]},
   
  
];

export default SavingsPolicyOptions;