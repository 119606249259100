import GameChoiceMoodPoints from "../../datasets/game_choice_mood_points";
export const determineMoodPoints = (choiceValueArr ) => {
    let gameChoiceArr = GameChoiceMoodPoints;
    var moodPointReturnArr = [];
    for (let i=0; i<choiceValueArr.length; i++){
        const moodPointObj = gameChoiceArr.find(elem => elem.choiceType === choiceValueArr[i].choiceType);
        for (let j=0; j<moodPointObj.parameterList.length; j++){
            let parameterSlct = moodPointObj.parameterList[j];
            let selectValue = false;
            if (moodPointObj.number == true){
                if (+choiceValueArr[i].value >= +parameterSlct.lowValue &&
                    +choiceValueArr[i].value <= +parameterSlct.highValue){
                        selectValue = true;
                }
            }else{
                if (choiceValueArr[i].value >= parameterSlct.lowValue &&
                    choiceValueArr[i].value <= parameterSlct.highValue){
                        selectValue = true;
                }
            }
            if (selectValue == true){
                     let moodPointAdjust = parameterSlct.pointsAdjust;
                     let moodPointReturnObj = {choiceType: choiceValueArr[i].choiceType, 
                        item: gameChoiceArr[i].item,
                        label: parameterSlct.desc, number: moodPointAdjust};
                     moodPointReturnArr.push(moodPointReturnObj);
                     break;
               }
        }
    }

    return moodPointReturnArr;
   
}
export default determineMoodPoints;
