import ConfigFile from "../../../datasets/config_file";
import { monthlyLoanAmt } from "../../../objects/AssetsExpenses/loanAmtCalc_function";
import { calcInflationAmt } from "../../../objects/CommonUse/calc_inflation_amt";

export const checkIfPurchaseAHomeIcon = (conditionsTable, gameNbrMonths, currentCash, 
    monthlyIncome, savingsInfo, rentExpense ) => {
    let purchaseAHomeValid = false;
    let homeTypeApartment = conditionsTable.find(elem => elem.condName === "homeType-apartment");
    if (homeTypeApartment != undefined  & gameNbrMonths < 348){
        let minimumHomeCost = ConfigFile.homeItems.minHomeCost;
        let totalGameMonths = ConfigFile.gameStartItems.totalNbrGameMonths;
        let nbrMonthsPlayed = +totalGameMonths - gameNbrMonths;
        let nbrGameYears = (nbrMonthsPlayed / 12).toFixed(0);
        //  increase homecost by maximum inflation so will be sure not to display notification
        //  until person has enough cash to cover down payment.
        let homeCost = minimumHomeCost * (1 + (.10 * nbrGameYears));
        let downPayPct = ConfigFile.homeItems.downPayMinPct;
        let downPaymentAmt = +(homeCost * downPayPct).toFixed(2) + 5000;
        let totalCashAmt = +currentCash;
        if (savingsInfo.totalSavings != undefined){
            totalCashAmt = +totalCashAmt + +savingsInfo.totalSavings;
        }
        if (totalCashAmt > +downPaymentAmt){
            let interestRate = ConfigFile.homeItems.mortgageInterest;
            let loanTerm = ConfigFile.homeItems.mortgageLoanTerm;
            let mortgageAmt = monthlyLoanAmt(homeCost, interestRate, loanTerm);
            // add rent back to monthly income since it will be replaced by mortgage
            let monthlyIncomeMinusRent = +monthlyIncome + +rentExpense;
            if (monthlyIncomeMinusRent > mortgageAmt){
                purchaseAHomeValid = true
            }
        }
    }
    return purchaseAHomeValid;
   
}


