
export const endOfMonthSpendHistory = (monthlyExpenses, monthlyChargeExpenses, monthlyLoanAmts, spendHistory) => {
    spendHistory.currentMonthSpendAmt = (+spendHistory.currentMonthSpendAmt + +monthlyExpenses + 
            +monthlyChargeExpenses + +monthlyLoanAmts).toFixed(2);
    if (spendHistory.current10YearMonths >= 120){
        spendHistory.previous10YearSpendAmt = +spendHistory.current10YearSpendAmt;
        spendHistory.previous10YearMonths = +spendHistory.current10YearMonths;
        spendHistory.current10YearSpendAmt = 0;
        spendHistory.current10YearMonths = 0;
    }
    spendHistory.current10YearSpendAmt = +spendHistory.current10YearSpendAmt + +spendHistory.currentMonthSpendAmt;
    spendHistory.current10YearSpendAmt = +(+spendHistory.current10YearSpendAmt).toFixed(2);
    spendHistory.current10YearMonths += 1;
    spendHistory.currentMonthSpendAmt = 0;
    return spendHistory;
   
}
