import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { isNumeric } from '../../objects/CommonUse/checkIfNumeric';
import { editText } from './editEventCollectionProcesses/editText';
import { editCondName } from './editEventCollectionProcesses/editCondName';
import {editConditionConnector} from './editEventCollectionProcesses/editConditionConnector';
import { editSelectMonthArr } from './editEventCollectionProcesses/editSelectMonthArr';
import initializeErrorArray from './objects/initialize_error_array';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import findDataObject from '../../objects/CommonUse/find_data_object';
import DropDownOptionCard from '../shared-components/drop_down_option_card';
import BackgroundImageDataset from './datasets/background_image_dataset';
import EventsImageDirectoryDataset from './datasets/events_image_directory_dataset';

class EventsHeaderModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                dataValidSw: true,
                dateTime: getCurrentDateTime(),
              }  
              this.errArray  = initializeErrorArray(12);  
              this.dateTime = getCurrentDateTime();
     }
 
  editInput(){
    this.dataValid = true;
    this.errArray  = initializeErrorArray(12);
    this.getFormValues();
    this.editFormValues();
    if (this.dataValid === true){
        this.saveEventsMainInfo();
        this.props.onSubmit(this.eventHeaderObj);
        this.setState({dataValidSw: true});
    }else{
        this.setState({dataValidSw: false});
    }
  }

  getFormValues(){
      this.title = document.getElementById("title").value;
      this.description = document.getElementById("description").value;
      this.frequency = document.getElementById("frequency").value;
      this.conditionConnector = document.getElementById("conditionConnector").value;
      const conditionString = document.getElementById("condition").value;
      if (conditionString.length !== 0){
            this.condition = conditionString.split(', '); 
      }else{
            this.condition = [];
      }
      const skipConditionString = document.getElementById("skipCondition").value;
      if (skipConditionString.length !== 0){
          this.skipCondition = skipConditionString.split(', '); 
      }else{
          this.skipCondition = [];
      }
      this.backgroundType = document.getElementById("backgroundType").value;
      this.imageDir = document.getElementById("imageDir").value;
      this.careerTag = document.getElementById("careerTag").value;
      let selectMonthArr = document.getElementById("selectMonthArr").value;
      this.selectMonthArr = [];
      if (selectMonthArr !== ""){
        let monthArr = selectMonthArr.split(','); 
        for (let i=0; i<monthArr.length; i++){
            let month = monthArr[i];
            month = month.replace(/ /g, "");
            this.selectMonthArr.push(month);
        }
      }
      this.gamePhase = document.getElementById("gamePhase").value;
      this.category = document.getElementById("category").value;
           
  }
  
  editFormValues(){
    var errorChk;
    errorChk = editText(this.title, "Header", "Title", 50);
    this.errorMsg = errorChk.message;
    this.checkErrorArray(0);
    errorChk = editText(this.description, "Header", "Description", 300);
    this.errorMsg = errorChk.message
    this.checkErrorArray(1);
    this.editFrequency();
    this.editConditionConnector();
    this.editCondition();
    this.editSkipCondition();
    this.editBackgroundType();
    this.editCareerTag();
    errorChk = editSelectMonthArr(this.selectMonthArr, "Header", "Selected Months");
    this.errorMsg = errorChk.message;
    this.checkErrorArray(9);
    this.editGamePhase();
    this.editEventCategory();
  
  } 

  editFrequency(){
      const validFrequency = isNumeric(this.frequency);
      if (validFrequency === false){
        this.errorMsg = "Frequency must be numeric";
        this.checkErrorArray(2);
      }
   }

   editConditionConnector(){
       this.validConditionConnector = true;
       let returnObj=editConditionConnector(this.conditionConnector, this.condition, "Header");
       if (returnObj.errorArray !== ""){
            this.validConditionConnector = false; 
            this.errorMsg = returnObj.message;
            this.checkErrorArray(3);
       }
   }

   editCondition(){
      this.possibleCondNames = "";
      this.validConditionTitle = true;
      var i;
      var returnObj;
      for (i=0; i < this.condition.length && this.validConditionTitle === true; i++){
          returnObj=editCondName(this.condition[i], this.props.condNames, 
              "false", "Header", "online");
          if (returnObj.errorArray !== ""){
              this.validConditionTitle = false; 
              this.errorArray = returnObj.errorArray;
              this.possibleCondNames = this.getPossibleCondNames(returnObj.saveIdx, this.props.condNames);
              this.errorMsg = this.errorArray.fieldValue + " " + this.errorArray.message;
              this.checkErrorArray(4);
          }
      }
   }
   
   editSkipCondition(){
    this.possibleSkipCondNames = [];
    this.validSkipConditionTitle = true;
    var i;
    var returnObj;
    for (i=0; i < this.skipCondition.length && this.validSkipConditionTitle === true; i++){
        returnObj=editCondName(this.skipCondition[i], this.props.condNames, 
            "false", "Header", "online");
        if (returnObj.errorArray !== ""){
            this.validSkipConditionTitle = false;
            this.errorArray = returnObj.errorArray;
            this.possibleSkipCondNames = this.getPossibleCondNames(returnObj.saveIdx, this.props.condNames);
            this.errorMsg = this.errorArray.fieldValue + " " + this.errorArray.message;
            this.checkErrorArray(5);
        }
    }
 }

 editBackgroundType(){
   if (this.backgroundType === "none"){
      this.errorMsg = "Must Select Background Type"
      this.checkErrorArray(6);
   }
 }
 
 editCareerTag(){
  this.possibleCareerTags = "";
  this.validCareerTag = true;
  var returnObj=editCondName(this.careerTag, this.props.careerTags, 
          "false", "Header", "online");
  if (returnObj.errorArray !== ""){
      this.validCareerTag = false; 
      this.errorArray = returnObj.errorArray;
      this.possibleCareerTags = this.getPossibleCondNames(returnObj.saveIdx, this.props.careerTags);
      this.errorMsg = this.errorArray.fieldValue + " " + this.errorArray.message;
      this.checkErrorArray(8);
  }
}

editGamePhase(){
    if (this.gamePhase === "none"){
       this.errorMsg = "Must select a Game Phase";
       this.checkErrorArray(10);
    }
}

editEventCategory(){
    if (this.category === "none"){
        this.errorMsg = "Must select a category";
        this.checkErrorArray(11);
    }
}
    
 checkErrorArray(msgIndex){
    if (this.errorMsg !== undefined && this.errorMsg !== ""){
        this.errArray[msgIndex] = {textType: "text-danger", 
            message: this.errorMsg};
          this.dataValid = false;
    }
  }

getPossibleCondNames(saveIdx, conditionList){
  var stopIdx;
  var startIdx = 0;
  this.possibleCondNames = "";
  
  if (saveIdx !== undefined){
    startIdx = saveIdx - 3;
    stopIdx = saveIdx + 5;
    let nbrCondNames = conditionList.length
    if (stopIdx >= nbrCondNames){
          startIdx = startIdx - (stopIdx - nbrCondNames);
          stopIdx = nbrCondNames - 1;
    }
    if (startIdx < 0){
        startIdx = 0;
        stopIdx = startIdx + 8;
    }
    var i;
    for (i=startIdx; i<stopIdx; i++){
        this.possibleCondNames = this.possibleCondNames + ", " +
          conditionList[i];
    }
  }
  return this.possibleCondNames;
}

  saveEventsMainInfo(){
    let eventsObj = findDataObject("eventsHeader").eventHeaderInfo;
    eventsObj.title = this.title;
    eventsObj.description = this.description;
    eventsObj.frequency = this.frequency;
    eventsObj.conditionConnector = this.conditionConnector;
    eventsObj.conditionArr = this.condition;
    eventsObj.skipConditionArr = this.skipCondition;
    eventsObj.backgroundType = this.backgroundType;
    eventsObj.imageDir = this.imageDir;
    eventsObj.careerTag = this.careerTag;
    eventsObj.selectMonthArr = this.selectMonthArr;
    eventsObj.gamePhase = this.gamePhase;
    eventsObj.eventCategory = this.category;
    eventsObj.dateAdded = this.dateTime.date;
    eventsObj.dateUpdated = this.dateTime.date;
    this.eventHeaderObj = {dataType: "header", eventsObj: eventsObj};
  }
   
    render() {
        this.backgroundOptions = BackgroundImageDataset.map((optionsList,index) =>
          <DropDownOptionCard options={optionsList} key={index} />
        );
        this.imageDirOptions = EventsImageDirectoryDataset.map((optionsList,index) =>
          <DropDownOptionCard options={optionsList} key={index} />
        );

    return <>
         <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
            size="med"  scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title>Events Header Entry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           
            <div className="text-left">
                <p>Please complete all fields on this form and then click 
                   'Submit'.</p>
                <div >
                   <label className="font-weight-bold" htmlFor="title">
                        Title:&nbsp;&nbsp;</label>
                    <span className={this.errArray[0].textType}>
                          &nbsp;{this.errArray[0].message}</span>
                   <input type="text" className="col-lg" id="title" name="title"></input> 
                   
                </div>
                <div >
                  <label className="mt-2 font-weight-bold" htmlFor="description">
                    Description:&nbsp;&nbsp;</label>
                  <span className={this.errArray[1].textType}>
                          &nbsp;{this.errArray[1].message}</span>
                   <textarea className="form-control" rows="4" id="description"></textarea> 
                </div>
                <div >
                  <label className="mt-4 font-weight-bold" htmlFor="frequency">
                    Frequency:&nbsp;&nbsp;</label>
                  <span className={this.errArray[2].textType}>
                          &nbsp;{this.errArray[2].message}</span>
                  <input type="text" className="col-small" id="frequency" name="frequency"></input>   
                </div>

                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="conditionConnector">Condition Connector:&nbsp;&nbsp;
                   </label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="conditionConnector">
                        <option value="">no connector required</option>
                        <option value="AND">All conditions must be true</option>
                        <option value="OR">Only one condition must be true</option> 
                   </select>
                   <span className={this.errArray[3].textType}>
                          &nbsp;{this.errArray[3].message}</span>
                </div>
               
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="condition">
                        Condition:&nbsp;&nbsp;</label>
                    <span className={this.errArray[4].textType}>
                          &nbsp;{this.errArray[4].message}</span>
                   <input type="text" className="col-lg" id="condition" name="condition"></input> 
                  
                </div> 
                {this.validConditionTitle ?
                    <div></div>
                :
                    <div>
                        <div>Possible Options for Condition Names:</div>
                        <div>{this.possibleCondNames}</div>
                    </div>
                }

                
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="skipCondition">
                        Skip Condition:&nbsp;&nbsp;</label>
                    <span className={this.errArray[5].textType}>
                          &nbsp;{this.errArray[5].message}</span>
                   <input type="text" className="col-lg" id="skipCondition" name="skipCondition"></input> 
                  
                </div> 
                {this.validSkipConditionTitle ?
                    <div></div>
                :
                    <div>
                        <div>Possible Options for Skip Condition Names:</div>
                        <div>{this.possibleSkipCondNames}</div>
                    </div>
                }

                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="careerTag">
                        Career Tag:&nbsp;&nbsp;</label>
                    <span className={this.errArray[8].textType}>
                          &nbsp;{this.errArray[8].message}</span>
                   <input type="text" className="col-lg" id="careerTag" name="careerTag"></input> 
                  
                </div> 
                {this.validCareerTag ?
                    <div></div>
                :
                    <div>
                        <div>Possible Options for Career Tag:</div>
                        <div>{this.possibleCareerTags}</div>
                    </div>
                }

                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="backgroundType">Background Type? :&nbsp;&nbsp;</label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="backgroundType">
                        <option value= "none">No Background Type Selected</option>
                        {this.backgroundOptions}
                  </select>
                  <span className={this.errArray[6].textType}>
                          &nbsp;{this.errArray[6].message}</span>
                </div>   
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="imageDir">Image Directory:&nbsp;&nbsp;</label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="imageDir">
                        <option value="">no image Directory</option>
                        {this.imageDirOptions}
                  </select>
                  <span className={this.errArray[7].textType}>
                          &nbsp;{this.errArray[7].message}</span>
                </div>   
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="selectMonthArr">
                    Only Valid for These Months:&nbsp;&nbsp;</label>
                  <input type="text" className="col-small" id="selectMonthArr" name="selectMonthArr"></input>   
                  <span className={this.errArray[9].textType}>
                          &nbsp;{this.errArray[9].message}</span>
                </div>
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="gamePhase">Game Phase:&nbsp;&nbsp;</label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="gamePhase">
                        <option value= "none">No GamePhase Selected</option>
                        <option value="1">Phase 1</option>
                        <option value="2">Phase 2</option>
                        <option value="all">All Phases</option>
                  </select>
                  <span className={this.errArray[10].textType}>
                          &nbsp;{this.errArray[10].message}</span>
                </div>   
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="category">Event Category:&nbsp;&nbsp;</label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="category">
                        <option value= "none">No Category Selected</option>
                        <option value="car">Car</option>
                        <option value="home">Home</option>
                        <option value="job">Work/Job Related</option>
                        <option value="miscExpenses">Misc Expenses</option>
                        <option value="other">Other</option>
                  </select>
                  <span className={this.errArray[11].textType}>
                          &nbsp;{this.errArray[11].message}</span>
                </div>   
                    
            </div>
           </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
          {this.state.dataValidSw ?
            <div></div>
          :
            <div className="text-danger">Please Correct errors and try-again.</div>
          }
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="primary"  onClick={() => this.editInput()}>
              Submit
          </Button>
        </Modal.Footer>
        </Modal>
        </>
    }
}

export default EventsHeaderModal;