import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import VideoViewerModal from '../for-parents/video_viewer_modal';


class SkillsWelcomeModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         viewVideo: false,
    };    
  }
  
  render() { 
  return <>
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="xs" animation={false}>
          <Modal.Header className="gameModalHdrFtrBackground" closeButton>
            <Modal.Title>Welcome to the MoneyTrain Game</Modal.Title> 
          </Modal.Header>
          <Modal.Body >
                <div className="mr-2 ml-2">
                   <p> The object of this game is for you to
                    retire on easy street.  This can be done by maximizing your retirement savings and 
                    your mood score.</p>
                      
                    <p>Before the game begins,
                    you get to choose your job (career), your home, your car and furniture.
                    After these decisions are made, you can review the projected monthly
                    amounts and your starting cash.  If you wish, you can change any of your
                    decisions.  Once you are happy with the money and mood point calculations, 
                    you can start the game.  
                    </p>

                    <p>Click the "Watch Video" button to view a 5 minute video
                       that will give you some basic information (tips) about the game.
                    </p>

                    <p>Close out this screen to get started.</p>                    
                </div>
          </Modal.Body>
          <Modal.Footer className="gameModalHdrFtrBackground">
              <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"/>
              <Button  variant="info"   onClick={() => this.watchVideo()}>
                   Watch Video
              </Button>
              <Button  variant="info"   onClick={() => this.props.onClose()}>
                   Close
              </Button>
          </Modal.Footer>
         </Modal>  

         {this.state.viewVideo ?
                            <div className="video-display-size" >
                                <VideoViewerModal
                                    topicName={this.topicName}
                                    show={this.state.viewVideo}
                                    onClose={() => this.turnVideoOff()} 
                                />
                             </div>
                            :
                                null
                        }
    
    </>
  }

  watchVideo(){
    //this.powerPointVideo = "./assets/Discussion/" + "introduction.mp4";
    this.topicName="gameTrailer1";
    this.setState({viewVideo: true});
  }

 turnVideoOff(){
    this.setState({viewVideo: false});
 }
 
}
export default SkillsWelcomeModal;