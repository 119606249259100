import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ContactUsModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                callRender: false,
                 
              }    
      this.dataValidSw = true;
      this.initializeErrorArray();
  }
 
  editInput(){
    this.dataValidSw = true;
    this.initializeErrorArray();
    this.editFirstName();
    this.editEmailAddress();
    this.editSubject();
    this.editComment();
    if ( this.dataValidSw  === true){
        this.saveContactUsInfo();
        this.props.onSubmit(this.contactUsObj);
    }else{
        this.setState({callRender: true});
    }
  }

  initializeErrorArray(){
    this.errArray =[{textType: "", message:""},
        {textType: "", message:""},
        {textType: "", message:""},
        {textType: "", message:""}]
  }
  
  editFirstName(){
    var validFirstName = true;
    this.firstName = document.getElementById("firstName").value;
    if (this.firstName.length  === 0){
        validFirstName = false;
    }else{
        // edit to see if name is a string of same character
        const charArray = this.firstName.split('');
        const firstChar = charArray[0];
        var i;
        var counter = 1;
        for (i=1; i<charArray.length; i++){
            if (charArray[i]  === firstChar) {
                counter = counter + 1;
            }
        }
        if (counter  === charArray.length){
            validFirstName = false;
        }
    }
    if (validFirstName  === false){
        this.errArray[0] = {textType: "text-danger", message: "Required"}
         this.dataValidSw = false;
    }
  }

  editEmailAddress() {
    var validEmailAddress = true;
    this.emailAddress = document.getElementById("emailAddress").value;
    if (this.emailAddress.length  === 0){
         validEmailAddress = false;
    }else{
        if (this.emailAddress.indexOf('@') <= 0){
          validEmailAddress = false;
        }
    }
    if (validEmailAddress  === false){
       this.errArray[1] = {textType: "text-danger", message: "Enter a valid Email Address"};
        this.dataValidSw = false;
    }
  }

  editSubject(){
    var validSubject = true;
    this.subject = document.getElementById("subject").value;
    if (this.subject  === "No-Subject") {
      validSubject = false;
    }
    
    if (validSubject  === false){
      this.errArray[2] = {textType: "text-danger", message: "Select subject."};
       this.dataValidSw = false;
    }
  }

  editComment(){
    var validComment = true;
    this.comment = document.getElementById("comment").value;
    if (this.comment.length  === 0){
        validComment = false;
    }
    if (validComment  === false){
       this.errArray[3] = {textType: "text-danger", message: "Enter comment text"};
        this.dataValidSw = false;
    }
  }

  saveContactUsInfo(){
    this.contactUsObj = {rqstType: "email", firstName: this.firstName, emailAddress: this.emailAddress,
      subject: this.subject, messageText: this.comment};
    }
   
    render() {
                                        
    return <>
      
      <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
            size="med" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title>Contact Us Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           
            <div className="text-left">
                <p>Please complete all fields on this form and then click 
                   'Submit'.</p>
                <div >
                   <label className="font-weight-bold" htmlFor="firstName">
                        First Name:&nbsp;&nbsp;</label>
                   <input type="text" className="col-med" id="firstName" name="firstName"></input> 
                   <span className={this.errArray[0].textType}>
                          &nbsp;{this.errArray[0].message}</span>
                </div>
                <div >
                  <label className="font-weight-bold" htmlFor="emailAddress">Email Address:&nbsp;&nbsp;</label>
                  <span className={this.errArray[1].textType}>
                          &nbsp;{this.errArray[1].message}</span>
                  <input type="text" className="col-lg" id="emailAddress" name="emailAddress"></input>   
                </div>
                <div className="mt-4">
                  <label className="font-weight-bold" htmlFor="subject">Subject:&nbsp;&nbsp;</label>
                  
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="subject" >
                        <option value="No-Subject">no subject slctd</option>
                        <option value="Question-MoneyTrain-Game">Question about the MoneyTrain Game</option>
                        <option value="Comment-Regarding-Game">Comment regarding the Game</option>
                        <option value="Comment-Regarding-Site">Comment regarding the site</option>
                        <option value="Comment-General">General comment</option>
                  </select>
                  <span><br />{this.subjectMsg}</span>
                  <span className={this.errArray[2].textType}>
                          &nbsp;{this.errArray[2].message}</span>
                </div>
                <div className="form-group mt-4">
                  <label className="font-weight-bold" htmlFor="comment">Comment:</label>
                  <span className={this.errArray[3].textType}>
                          &nbsp;{this.errArray[3].message}</span>
                  <textarea className="form-control" rows="5" id="comment"></textarea>
                </div>
       
            </div>
           </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
          { this.dataValidSw ?
            <div></div>
          :
            <div className="text-danger">Please correct errors and try-again.</div>
          }
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="primary"  onClick={() => this.editInput()}>
              Submit
          </Button>
        </Modal.Footer>
        </Modal>
      
        </>
    }

   
   
}

export default ContactUsModal;