const RetirementSavingsDataset =[
  //   if player's age is less than the age in this dataset, then use the nbrYearsSalarySaved
  //   so if player is 45, then savings s/b 3 times current salary
  {
    "age": 25,
    "nbrYearsSalarySaved": .25,
  },
  {
    "age": 30,
    "nbrYearsSalarySaved": .50,
  },
  {
    "age": 40,
    "nbrYearsSalarySaved": 1,
  },
  {
    "age": 45,
    "nbrYearsSalarySaved": 3,
  },
  {
    "age": 50,
    "nbrYearsSalarySaved": 4,
  },
  {
    "age": 55,
    "nbrYearsSalarySaved": 8,
  },
  {
    "age": 60,
    "nbrYearsSalarySaved": 7,
  },
  {
    "age": 65,
    "nbrYearsSalarySaved": 8,
  },
  {
    "age": 67,
    "nbrYearsSalarySaved": 10,
  },

     //Age 30: 1x salary.
        //Age 35: 2x salary.
        //Age 40: 3x salary.
        //Age 45: 4x salary.
        //Age 50: 6x salary.
        //Age 55: 7x salary.
        //Age 60: 8x salary.
        //Age 67: 10x salary.
  
];

export default RetirementSavingsDataset;