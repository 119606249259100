import "./SharedComponents/sharedComponents.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {Component} from 'react';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';

class CarCard extends Component {
    photosArr = [];
   
    constructor(props) {
        super(props);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
        this.state = {open: false, selected: false};
    }

    toggleExpanded(e) {
        this.setState({open: !this.state.open});
    }

    toggleSelected(e) {
        this.setState({selected: !this.state.selected});
        this.props.onSelected(this.props.carInfo.id);
    }

    render() {
        const featuresList = this.props.carInfo.features.map((feature, key) =>
        <li key={key}>{feature}</li>
        );
        const defaultImgSrc = './assets/' + this.props.carInfo.directory;
        var half_length = Math.ceil(featuresList.length / 2);    
        /* The following splice command removes the first half of entries from the includedlist array.
           After this splice command, IncludedList contains only the second half of the entries.  */
        var leftSide = featuresList.splice(0,half_length);  
              
    return <>
        <div className=
        {this.props.selectedAutomobileId === this.props.carInfo.id ?
            "card mx-3 my-2 link-tab-selected"
            :
            "card mx-3 my-2 drop-shadow"
        }
        >
        <div className="card-header text-left">
        
        <span className=" m-0 p-0">
            <span className="row w-100 mx-0" onClick={this.toggleExpanded}>
                    <div className="col-sm-4 p-0 text-left">
                            <img src={defaultImgSrc} className="home_card_thumbnail_img" alt="car picture"/>
                     </div>
                     <div className="col-sm-6 ml-3">
                           <p className="mb-0 mt-4 font-weight-bold">{this.props.carInfo.ageName}</p>
                           <p className="mb-0 font-weight-bold">Listed Cost: {doubleToStringMonthly(this.props.carInfo.cost)}</p>
                     </div>
                     <div className="col-sm-1 p-0 text-right">
                        {this.state.open ?
                            <FontAwesomeIcon icon={faCaretDown} className="career_card_caret" />
                            :
                            <FontAwesomeIcon icon={faCaretRight} className="career_card_caret" />
                        }
                     </div>   
                </span>
        </span>
        </div>
        {this.state.open ?
            <div className="card-body">
                <span className="container text-center m-0 p-0">
                    <h6>Car Facts and Features List</h6>
                    <span className="row w-100 mx-0" onClick={this.toggleExpanded}>
                        <div className="col-sm-6 p-0 m-0">
                            <div className="text-left">
                                  <ul>{leftSide}</ul>
                             </div>
                        </div>
                        <div className="col-sm-6 p-0 m-0">
                            <div>
                                <div className="text-left">
                                     <ul>{featuresList}</ul>
                                </div>
                            </div>    
                        </div>
                    </span>
                </span>
                
                <span className="container text-right">
                    <div className="ml-2">
                        <button className="btn btn-primary mx-2" onClick={this.toggleSelected}>
                            Select Car
                        </button>
                    </div>                         
                </span>
                
            </div>
        : <span/>
        }
        </div>
    </>
    }
}

export default CarCard;