
export const savingsOverTimeAmt = (monthlyAmt, interest, years, initialAmt) => {
   /*  Formula for compounded savings is: 
               A=P(1 + r/n)^(n*t) + (PMT * ((1+r/n)^(n*t)-1)/ r/n.
       Where: 
              P is the amount in the account before beginning the regular deposit.  This is
                     equal to the monthly Amt (since that is put into the account on Day 1) 
                     plus the amount in the account before beginning monthly savings (initialAmt).
                     Note that the initialAmt can be 0.
              r is the annual interest rate.
              n is the compounding frequency (this will be 12 for all calculations - 
                     assume monthly compounding)
              t is the time period in years.
              PMT is amount deposited each month
  */
        if (initialAmt == undefined){
              initialAmt = 0;
        }
        var savingsAmountUnrounded = 0;
        var savingsAmountRounded = 0;
        var i;
        let deposit=+monthlyAmt;
        let initialDeposit = +monthlyAmt + +initialAmt;
        let nbrYears = +years;
        let yearlyInterest = +interest;
        if (!isNaN(deposit) && !isNaN(nbrYears) && !isNaN(yearlyInterest)) {
               var monthlyIntRate = (yearlyInterest / 12);
               var totalCompoundMonths = nbrYears * 12;
               var initialDepositWithInterest = initialDeposit *
                     (Math.pow((1 + monthlyIntRate), totalCompoundMonths));
               var partialNumerator = Math.pow((1 + monthlyIntRate), (totalCompoundMonths));
               var depositsWithInterest = (deposit * (partialNumerator - 1)/monthlyIntRate);
               var savingsAmountUnrounded = initialDepositWithInterest + depositsWithInterest - deposit;
        }else{
              savingsAmountUnrounded = 0;
        }
        
        savingsAmountRounded = savingsAmountUnrounded.toFixed(2);
        return savingsAmountRounded;
 }




