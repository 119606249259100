import addToAllConditionsArray from "./add_to_all_conditions_array";


export const createTableOfConditionEvents = (eventsData, primaryConditionArr ) => {
    var primaryEvent;
    var allConditionsArr = [];
        
    for (let i=0; i<eventsData.length; i++){
        let slctEvent = eventsData[i];
        if (slctEvent.conditionArr !== undefined && slctEvent.conditionArr.length > 0){
              
            for (let j=0; j<slctEvent.conditionArr.length; j++){
                let checkIdx = primaryConditionArr.indexOf(slctEvent.conditionArr[j]);
                if (checkIdx >= 0){
                    primaryEvent = true;
                }else{
                    primaryEvent = false;
                }
                let responses = slctEvent.responses;
                for (let k=0; k<responses.length; k++){
                    let possible = responses[k].possibleEffects;
                    for (let m=0; m < possible.length; m++){
                        let conditionObj = addToAllConditionsArray(slctEvent, slctEvent.conditionArr[j], slctEvent.skipConditionArr,
                            responses[k].btnText, possible[m].triggerCondition, possible[m].deleteCondition,
                            possible[m].deleteClass, primaryEvent);
                        allConditionsArr.push(conditionObj);    
                    }
                }
            }
        }
    }
    return allConditionsArr;
   
}
export default createTableOfConditionEvents;

