import React from "react";
import "./ContactUs/contactUs.css";
import { withRouter } from "react-router";
import { setHeadersForFetch } from "../../objects/CommonUse/set_headers_for_fetch";
import { setScreenSizeParameters } from "../../objects/CommonUse/set_screen_size_parameters";
import WaitTimeSpinner from "../shared-components/wait_time_spinner";
import buildUrl from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import PlayerCommentDisplayCard from "../admin/player_comment_display_card";

class PlayerCommentDisplay extends React.Component {
constructor(props) {
    super(props);
    this.state = {
        loading: true, 
        environment: Config.dataURL.currentEnv,
        subProcess: "PlayerComments",
    };
    this.commentsOnly = true;
    this.playerCommentArr = [];
    this.headers=setHeadersForFetch();
    this.loadPlayerComments(); 
}

render() { 
        
    let screenParms = setScreenSizeParameters(this.props.screenSize, "about");
    this.className = screenParms.columnClass;
    this.textClass = screenParms.textSize + " mt-2 mx-4";
    this.playerCommentInfo = this.playerCommentArr.map((playerFeedback, index) =>
        <PlayerCommentDisplayCard playerFeedback={playerFeedback}  key={index}  
            commentsOnly={this.commentsOnly}
            index={index}/>
            );
       
return <>
        <div className={this.className}> 
            <div className="card mt-3 rounded-corners-lg height-max happy-retirement-img">
            <button className="pages-back-button ml-4 mt-4" 
                        onClick ={()=> this.props.goBack()}>
                                Back
                    </button>
            <h3 className="mt-0 p-0 text-center">Contact Us</h3>
            <div className="overflow-scroll mr-3 ml-3 mb-5">
            <h5 className="text-center">Review Player Comments</h5>
            <div className={this.textClass}>
                        All comments that are entered (via the pencil/paper icon) are reviewed by game administrators.
                        As part of this review, a classification and review comment are added.  It generally takes 
                        about a week before the review information is updated.  The comments displayed
                        below are the comments that were made by the player that is currently logged on 
                        ({this.props.username}).  
                </div>      
            {this.state.loading ?
               <WaitTimeSpinner />
            :
            <span>
                {this.playerCommentArr.length ===  0  ?
                        <div className="text-center font-weight-bold mt-4">
                            No comments have been entered yet by this player.
                        </div>
                :
                <span>
                    <table className="table mt-4">
                        <tbody>
                        <tr>
                            <th>User</th>
                            <th>Date and Time of Comment</th>
                            <th>Thumb Chosen</th>
                            <th>Comment</th>
                        </tr>
                            {this.playerCommentInfo}
                        </tbody>
                    </table>
                </span>
            }
            </span>
        }
        </div>
        </div>
    </div>
</>
}

loadPlayerComments(){
    this.setState({waitState: true});
    const funcName = "loadPlayerFeedback";
    const urlParm = "?userList=" + this.props.username;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch (uri,
       {method:'GET', headers: this.headers})
       .then(response => {
        if (response.ok){
            response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode ===  200){
                    this.playerCommentArr = output.body;
                    this.setState({loading: false});
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
}

}

export default withRouter(PlayerCommentDisplay);