
export const editText = (text, fieldClass, fieldName, maxLength) => {

    var errorObj="";
  
    if (text !== undefined){
        if (text.length === 0){
            errorObj = {fieldClass: fieldClass, fieldName: fieldName, fieldValue: text.substr(0,50),
                message: fieldName + " cannot be blank."}
         }else{
            if (text.length > maxLength){
                errorObj = {fieldClass: fieldClass, fieldName: fieldName, fieldValue: text.substr(0,50),
                    message: fieldName + " is longer than " + maxLength + " characters. Length is " +
                text.length};
            }
        }
    }else{
        errorObj = {fieldClass: fieldClass, fieldName: fieldName, fieldValue: text,
            message: fieldName + " is undefined"};
    }
   
    return errorObj;
   
}