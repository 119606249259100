const RelatedPlayerText =[
       {type: "AccountInfo", 
       shortText0: "There are no players related to your signon name.", 
       shortText1: " There is one player related to your signon name. " +
          "The player related to you is listed below along with their current game status.",
       shortText2: "There is more than one player that is related to your signon name. " +
          "The players related to you are listed below along with their current game status  " +
          "(scroll down). ",
       text: " Players are related to you based on your account number. " +
          "If a player has not yet set up a userName with this account number, then, they will " +
          "not show up in your related players list.  The number of players allocated to your account and the " + 
          "number that have set up a userName (signon) are noted below.   "
        },
        {type: "AuthCodeInfo", 
        shortText: "A list of AuthCodes that are available for your account are listed below. ",
        text: "The AuthCode is used together with the account number to ensure that an individual " +
            "has been authorized to create a new signon id. To allow an individual to create a signon, " +
            "just give them the account number and an AuthCode.  Each AuthCode is unique for this " +
            "account and ensures that a single individual cannot create multiple signon ids (unless " +
            "the parent or teacher has provided them with multiple AuthCodes which is not recommended). " 
        },
       

]

export default RelatedPlayerText;