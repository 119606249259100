import {Component} from 'react';
import { doubleToStringAnnual } from '../../objects/CommonUse/double_functions';


class JobListDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }   
}
  
render() {
     this.avgSalary = (+this.props.jobInfo.avgSalary * 12).toFixed(2);
            
return <>
      <tr>
            <td className="font-weight-bold text-left">
                 {this.props.jobInfo.jobName}
            </td>
            <td className="font-weight-bold" >
                 {doubleToStringAnnual(this.props.jobInfo.minSalary)}
            </td>
            <td className="font-weight-bold" >
                 {doubleToStringAnnual(this.props.jobInfo.avgSalary)}
            </td>
            <td className="font-weight-bold" >
                 {doubleToStringAnnual(this.props.jobInfo.maxSalary)}
            </td>
            <td className="" >
                 {this.props.jobInfo.degreeType}
            </td>
            <td className="" >
                 {this.props.jobInfo.degreeSpecialty}
            </td>
           
      </tr>         
           
   </>
  }
}

export default JobListDisplayCard;