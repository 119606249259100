const ComedyClubDataset = {
        gameName: "ComedyClub",  jokes:
                [{question: "Why do French people like to eat snails?", answer: "They can’t stand fast food."},
                {question: "What did the envelope say to the stamp?", answer: "Stick with me and we’ll go places!"},
                {question: 'Two clairvoyants meet. One says to the other:', answer: "You are fine, and how am I?"},
                {question: "Why can’t your nose be twelve inches long?", answer: "Because then it would be a foot."},
                {question: "I hated my job as an origami teacher.", answer: "Too much paperwork." },
                {question: "I love pressing F5.", answer: "It's so refreshing."},
                {question: "What's the best part about living in Switzerland?", answer: "Not sure but the flag is a big plus."},
                {question: "Someone stole my mood ring", answer: "I'm not sure how I feel about that"},
                {question: "My new hobby is eating clocks.", answer: "It's rather time consuming."},
                {question: "Have you heard about corduroy pillows?", answer: "They're making headlines."},
                {question: "The past, present and future walked into a bar.", answer: "It was tense."},
                {question: "A lot of people cry when they cut an onion.", answer: "The trick is not to form an emotional bond."},
                {question: "Why do wrappers need umbrellas?", answer: "Fo’ drizzle."},
                {question: "What’s the difference between ignorance and apathy?", answer: "I don’t know, and I don’t care."},
                {question: "What did one DNA strand say to the other?", answer: "Does my bum look big in these genes?"},
                {question: "What did the grape say when he was pinched?", answer: "Nothing, he just gave a little wine."},
                {question: "I thought my neighbours were lovely people.", answer: "Then they went and put a password on their wi-fi."},
                {question: "A police recruit was asked during the exam, 'What would you do if you had to arrest your own mother?'",
                        answer: "He said, 'Call for backup.'"},
                {question: "I've just opened a new restaurant called Karma.", answer: "There's no menu, we just give you " +
                        "what you deserve."},
                {question: "I thought I’d tell you a brilliant time-travel joke...", answer: "but you didn’t like it."},
                {question: "My boss told me yesterday, 'You shouldn't dress for the job you have, dress for the job you want'.",
                        answer: "But when I turned up today in Ghostbusters clothes, he said I was fired."}
        ]
}
export default ComedyClubDataset;