import {Component} from 'react';

class EventListDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
   
    }   
}
  
render() {
return <>
      <tr>
          <td className="font-weight-bold text-left">
               {this.props.events.eventID}
          </td>
          <td className="" >
               {this.props.events.condType}
          </td>
           <td className="" >
               {this.props.events.frequency}
          </td>
          <td className="text-left" >
               {this.props.events.eventTitle}
          </td>
          <td className="text-left" >
               {this.props.events.eventDesc}
          </td>
     </tr>         
           
   </>
  }
}

export default EventListDisplayCard;