import GameCollectionFieldNameList from "../datasets/game_collection_field_name_dataset";

export const editConditionFieldName = (conditionFieldName, source, responseLit) => {
  var errorObj="";
  var conditionFieldNameValid = true;
  var errMessage = "";

  if (conditionFieldName === undefined){
    conditionFieldNameValid = false;
    errMessage = "Condition Field Name is Undefined";
  }else{
    if (source === "online"){
      //  currently there is no online entry for this so no edits required.
    }else{
      var conditionFieldNameFound = false;
      if (conditionFieldName === ""){
          conditionFieldNameFound = true;
      }else{
          if (GameCollectionFieldNameList.includes(conditionFieldName)){
                conditionFieldNameFound = true;
          }
      }
      
      if (conditionFieldNameFound === false){
          conditionFieldNameValid = false;
          errMessage = "Condition Field Name not found in dataset";
      }
    }
  }
  if (conditionFieldNameValid === false){
     errorObj = {fieldClass: responseLit, fieldName: "Primary Field Name", fieldValue: conditionFieldName, 
     message: errMessage};
  }
 
  return errorObj;
 
}