const updateHomeInfo = {
    "cash": 88888.88, 
    "homeInfo":  {
        "homeID": "88888888",
        "homeType": "house",
        "roommates": "1",
        "bedrooms": "2"
      },
    "assetTable": [ {
        "assetItem": "Job Salary",
        "assetDesc": "Electrical Engineer --> Annual Salary: $64,000.00",
        "assetAmt": "5333.33",
        "assetStartDate": "2022-10",
        "assetNbrMonths": "-1"
      },
      {
        "assetItem": "Rent Income",
        "assetDesc": "test for home info update",
        "assetAmt": "1333.33",
        "assetStartDate": "2024-02",
        "assetNbrMonths": "-1"
      }],
    "expenseTable":  [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": "2291.83",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Meadow Hills-1 bedroom, 0 roommates",
          "expAmount": 1751,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 99,
          "expStartDate": "2024-02",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "115.56",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 380,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
            "expItem": "test item",
            "expDesc": "test item for update home info",
            "expAmount": 999,
            "expStartDate": "2024-02",
            "expNbrMonths": "-1"
          }
      ],
    "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "Mid-Size Car - 3 to 5 Years Old",
          "loanAmt": "300.21",
          "loanStartDate": "2022-10",
          "loanNbrMonths": 72,
          "loanTotAmt": 16205,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2022-10",
          "loanNbrMonths": 120,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        },
        {
            "loanType": "Home Loan test",
            "loanDesc": "test for update home info",
            "loanAmt": "555.55",
            "loanStartDate": "2024-02",
            "loanNbrMonths": 360,
            "loanTotAmt": "500000.00",
            "loanIntRate": 0.035,
            "loanTerm": 30
          }
      ],
    "iconTable": [
        {
          "iconName": "gameBasicHelp",
          "iconCategory": "information",
          "className": "small_sign_thumbnail_img information-sign-position",
          "imageSrc": "gameHelp.png",
          "validInd": true,
          "firstTime": true,
          "additionalInfo": ""
        },
        {
            "iconName": "test-home-info",
            "iconCategory": "information",
            "className": "small_sign_thumbnail_img information-sign-position",
            "imageSrc": "gameHelp.png",
            "validInd": true,
            "firstTime": true,
            "additionalInfo": ""
          },

      ],
    "conditionsTable":  [
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "electrical-engineer",
          "conditionClass": "career"
        },
        {
            "condName": "test-condition-home-info",
            "conditionClass": "game"
          }
      ],
    "currentMoodPoints": 99999,
}

export default updateHomeInfo;