import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
//import "./Special/special.css";
import Button from 'react-bootstrap/Button';
import PlayerCommentDisplayCard from './player_comment_display_card';


class PlayerFeedbackDisplayModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      
      }    
   
}

   
render() {
        this.playerCommentInfo = this.props.playerFeedback.map((playerFeedback, index) =>
          <PlayerCommentDisplayCard playerFeedback={playerFeedback}  key={index}  
           commentsOnly={this.props.commentsOnly}
           index={index}/>
              );
        
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="xl" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Player Comments - Number of Player Comment Records: &nbsp;
              {this.props.playerFeedback.length}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <span>
              <table className="table">
                <tbody>
                  <tr>
                    <th>User</th>
                    <th>Date and Time of Comment</th>
                    <th>Thumb Chosen</th>
                    <th>Comment</th>
                  </tr>
                  {this.playerCommentInfo}
                </tbody>
              </table>
                             
              </span>
                  
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
             
              <Button variant="primary"  onClick={() => this.props.onClose()}>
                   Close
              </Button>
              
        </Modal.Footer>
       
    </Modal>
    
  </>
  }
}

export default PlayerFeedbackDisplayModal;