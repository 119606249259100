
export const determineTextMaxLength = (title, responseButtons) => {

    // count 2 lines for every button
    let titleLineLength = 1;
    if (title.length > 21){
         titleLineLength += 1;
    }
    let btnLineLength = responseButtons.length * 2;
    for (let i=0; i<responseButtons.length; i++){
         if (responseButtons[i].length > 22){
              btnLineLength += 1;
         }
    }

    let totalLineLength = titleLineLength + btnLineLength - 3;
    let descriptionMaxLength = 310 - (totalLineLength * 20);

    let closureTextMaxLength = 290;
    if (titleLineLength > 1){
           closureTextMaxLength = 280; 
    }

    let returnObj = {descriptionMaxLength: descriptionMaxLength, closureTextMaxLength: closureTextMaxLength};
   

    return returnObj;
   
}