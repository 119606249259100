
export const checkDialogConditions = (slctConditions, playerConditions, totalLoanAmt ) => {
    
    let conditionFound = true;
    for (let i=0; i<slctConditions.length; i++){
         conditionFound = playerConditions.some(elem => elem.condName === slctConditions[i])
         if (conditionFound == false){
                break;
         }
    }
         
    return conditionFound;
   
}


