import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import initializeErrorArray from './objects/initialize_error_array';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class EventTrackerDeleteModal extends Component {
        constructor(props) {
            super(props);
            this.state={
                headers: setHeadersForFetch(),
                subProcess: "EventTrackerDeleteModal",
                environment: Config.dataURL.currentEnv,
            }
            this.errArray= initializeErrorArray(1);
            this.dataValidSw = true;
     }
    
  editInput(){
    this.setState({dataValidSw: true});
    this.errArray= initializeErrorArray(1);
    this.getFormValues();
    if (this.dataValidSw === true){
        this.deleteEventTracker();
        this.props.onClose();
    }
  }

  getFormValues(){
      this.playerName = document.getElementById("playerName").value;
      if (this.playerName === ""){
            this.errorMsg = "Player Name is Required";
            this.checkErrorArray(0);
      }
  }
    
  checkErrorArray(msgIndex){
    if (this.errorMsg !== undefined && this.errorMsg !== ""){
        this.errArray[msgIndex] = {textType: "text-danger", 
            message: this.errorMsg};
        this.setState({dataValidSw: false});
    }
  }
     
    render() {
    return <>
         <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
            size="sm"  scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title>Event Tracker Delete Parameters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form className="text-left">
                <p>Please complete the fields on this form and then click 
                   'Submit'.</p>
                           
                     <div >
                        <label className="mt-2 font-weight-bold" htmlFor="playerName">
                            Player Name:&nbsp;&nbsp;</label>
                        <span className={this.errArray[0].textType}>
                            &nbsp;{this.errArray[0].message}</span>
                        <input type="text" className="col-lg" id="playerName" name="playerName"></input> 
                  
                    </div>
            </form>
           </Modal.Body>
        <Modal.Footer className="p-0" >
          {this.dataValidSw ?
            <div></div>
          :
            <div className="text-danger">Please Correct errors and try-again.</div>
          }
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="primary"  onClick={() => this.editInput()}>
              Submit
          </Button>
        </Modal.Footer>
        </Modal>
        </>
    }

deleteEventTracker(){
        const funcName = "deleteEventTracker";
        const urlParm = "?playerName=" + this.playerName;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch(uri,
            {
                method:'DELETE',
                headers: this.state.headers
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        alert("Event Tracker has been deleted!!");
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
    }

}

export default EventTrackerDeleteModal;