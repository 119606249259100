
import { calcBiasedRandomNbr } from "./randomBias";

export const calcInflationAmt = (amount, nbrYears) => {
 
   var inflationPct = 0;
   var newAmount = +amount;
   for (let i=0; i<nbrYears; i++){
         inflationPct = (calcBiasedRandomNbr(0, 9, 3, .65))/100;  // this is biased toward 3      
         let inflationAmt = +amount * inflationPct;
         newAmount = +amount + inflationAmt;
   }
   var newAmountRounded = newAmount.toFixed(2);
              
   return newAmountRounded;
   
}





