import ConfigFile from '../../datasets/config_file';
import { calcNbrMonthsBtwnDates } from '../CommonUse/nbr_months_btwn_dates_calc';
import { calcInflationAmt } from '../CommonUse/calc_inflation_amt';
import { createTableItem } from './create_table_item';

export const determineCollegeLoanObj = (nbrYearsCollege, collegeDesc, loanStartDate, gameStartDate,
        pctTuitionDiscount, costPerYearOverride) => {
    
        if (gameStartDate == undefined){
            gameStartDate = loanStartDate;
        }

        let inflCollegeCost = 0;
         
        if (costPerYearOverride == undefined || costPerYearOverride == 0){
            const monthDiff = calcNbrMonthsBtwnDates(gameStartDate, loanStartDate);
            const nbrYears = (monthDiff/12).toFixed(0);   
            let collegeCostPerYear = ConfigFile.collegeItems.collegeCostPerYear; 
            inflCollegeCost = calcInflationAmt(collegeCostPerYear, nbrYears);
        }else{
            inflCollegeCost = costPerYearOverride
        }

        let numericNbrYearsCollege = +nbrYearsCollege;
        var pctCalcDiscount = 0;
        if (pctTuitionDiscount != undefined){
               pctCalcDiscount = pctTuitionDiscount;
        }
        var totalCollegeAmt = (inflCollegeCost * numericNbrYearsCollege * (1 - pctCalcDiscount)).toFixed(2);
        
        //   The following statement is to adjust cost for associate degrees.
        //   Tuition at community colleges & trade schools is generally about
        //   1/2 of the amount at a 4 year college.   
        if (numericNbrYearsCollege == 2) {
            totalCollegeAmt = totalCollegeAmt * .5;
        }
       
        let collegeLoanObj = createTableItem("college", collegeDesc, loanStartDate, totalCollegeAmt, false);
       
        if (totalCollegeAmt == 0){
              collegeLoanObj.loanNbrMonths = 0;
              collegeLoanObj.loanDesc = "No college is required for the job that you selected";
              collegeLoanObj.loanTerm = 0;
        }
              
    return collegeLoanObj;
 }

