import {Component} from 'react';
//import Modal from 'react-bootstrap/Modal';
import {Button, Modal, ModalFooter, ModalHeader, ModalBody} from "reactstrap"
import Draggable from 'react-draggable';
//import Button from 'react-bootstrap/Button';
import '../../css/styles.css';

class DiscussionTextModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                
              }
            
    }

        
    render() {
    return <>
     <Draggable>
        <Modal isOpen={this.props.modalOpen} backdrop="static"
         >
            <ModalHeader className="backgroundTopBottom"  >
                    <div>Discussion Exercise
                    </div>
        </ModalHeader>
        <ModalBody>
            <div>
                Testing this will have text to define....

            </div>
        </ModalBody>
        <ModalFooter className="p-0 backgroundTopBottom">
            <Button variant="secondary"  className="mr-4" onClick={() => this.props.nextExercise()}>
                      Next Exercise
            </Button>
            <Button variant="primary"  className="mr-4" onClick={() => this.props.onClose()}>
                      Close
            </Button>
        </ModalFooter>
            </Modal>
      </Draggable>
        </>
    }
}
export default DiscussionTextModal;