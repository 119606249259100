import ConditionTriggeredByList from "../datasets/condition_triggered_by_dataset";

export const editConditionTriggeredBy = (triggeredBy, source, responseLit) => {
  var errorObj="";
  var triggeredByValid = true;
  var errMessage = "";

  if (triggeredBy === undefined){
    triggeredByValid = false;
    errMessage = "Triggered By is Undefined";
  }else{
    if (source === "online"){
      //  currently there is no online entry for this so no edits required.
    }else{
      var triggeredByFound = false;
      var i;
      for (i=0; i<ConditionTriggeredByList.length && triggeredByFound === false; i++){
          if (triggeredBy === ConditionTriggeredByList[i]){
              triggeredByFound = true;
          }
      }
      
      if (triggeredByFound === false){
          triggeredByValid = false;
          errMessage = "Triggered By not found in dataset";
      }
    }
  }
  if (triggeredByValid === false){
     errorObj = {fieldClass: responseLit, fieldName: "Triggered By", fieldValue: triggeredBy, 
     message: errMessage};
  }
 
  return errorObj;
 
}