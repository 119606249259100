import EventImageList from "../datasets/event_image_list";

export const determinePossiblePurchases = (purchaseArr, imageDir) => {

    if (purchaseArr === undefined){
        purchaseArr = [];
    }
    let itemObj =  EventImageList.find(elem => elem.imageDir === imageDir);   
    for (let i=0; i<purchaseArr.length; i++){
            //Remove any options that have this itemName, These items have already been purchased by the player.
            let maxIndx = itemObj.options.length - 1;
            for (let j=maxIndx; j>=0; j--){
                if (purchaseArr[i].itemName === itemObj.options[j].itemName){
                    itemObj.options.splice(j,1);
                }
            }
        
    }
          
    return itemObj.options;
   
}
export default determinePossiblePurchases;

