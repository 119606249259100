import {Component} from 'react';
import './Special/special.css';
import Button from 'react-bootstrap/Button';

class EventResponseCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      closureTextErrMsg: "",
    }    
}
  
checkMoodPoints(moodPoints){
      let newMoodPoints = moodPoints;
      let changeObj = {index: this.props.response.index, changeType: "moodPoints", newMoodPoints: newMoodPoints};
      this.props.processChange(changeObj);
}

checkClosureText(closureText){
      let newClosureText = closureText;
      let changeObj = {index: this.props.response.index, changeType: "closureText", newClosureText: newClosureText};
      this.props.processChange(changeObj);
}

selectResponse(){
      this.props.displayResponse(this.props.response);
}

render() {
      this.class = "font-weight-bold table-background-lightblue";
      this.classBtn = this.class + " text-right"
      this.class2="text-left px-0, py-0"
          
return <>
            <tr className="text-left">
                  <th className={this.class} colSpan="3">{this.props.response.btnText}&nbsp;
                  (length: {this.props.response.btnText.length}) </th>
                  <th className={this.classBtn} colSpan="3">{this.props.response.itemDesc}
                        <Button className="text-right" primary size="sm"
                            onClick={() => this.selectResponse()}>
                                Select
                        </Button></th>
            </tr>
            <tr>
                  <th className={this.class2}>Closure Text:</th>
                  <td className={this.class2} colSpan="5">{this.props.response.closureText}</td>
            </tr>
                
            <tr className="text-left">
                  <th className={this.class2}>Category:</th>
                  <td className={this.class2}>{this.props.response.category}</td>
                  <th className={this.class2}>On:</th> 
                  <td className={this.class2}>{this.props.response.on}</td>
                  <th className={this.class2}>Amt Type:</th>
                  <td className={this.class2}>{this.props.response.type}</td>
            </tr>
            <tr className="text-left">
                  <th className={this.class2}>Duration:</th> 
                  <td className={this.class2}>{this.props.response.duration}</td>
                  <th className={this.class2}>Repl Amt?:</th>
                  <td className={this.class2}>{this.props.response.replaceAmtSw}</td>
                  <th className={this.class2}>Mood:</th> 
                  <td className={this.class2}>{this.props.response.moodPoints}</td>
            </tr>
            <tr className="text-left">
                  <th className={this.class2}>Trigger Cond:</th>
                  <td className={this.class2}>{this.props.response.triggerCondition}</td>
                  <th className={this.class2}>Delete Cond:</th> 
                  <td className={this.class2}>{this.props.response.deleteCondition}</td>
                  <th className={this.class2}>Delete Class:</th>
                  <td className={this.class2}>{this.props.response.deleteClass}</td>  
            </tr>
            <tr>  <th className={this.class2}>Odds:</th>
                  <td className={this.class2}>{this.props.response.odds}</td>
                  <th className={this.class2}>Values:</th>
                  <td className={this.class2}>{this.props.response.valueList}</td> 
                  <th className={this.class2}>Text Length:</th>
                  <td className={this.class2}>{this.props.response.closureText.length}</td>

            </tr>
            {this.props.status == "update" ?
                  <tr>
                       <td>
                              <label className="font-weight-bold" htmlFor="moodPoints">
                                   Mood Pts:&nbsp;</label>
                              <input type="text" className="form-control col-sm" id="moodPoints" name="moodPoints"
                                    onChange={(val) => this.checkMoodPoints(val.target.value)} >
                                   </input>  
                              <div className="text-danger">
                                    {this.props.response.moodPointsErrorMsg}
                              </div>
                        </td>
                        <td className="text-white">
                              skip column
                        </td>
                        <td colSpan="4">
                              <label className="font-weight-bold" htmlFor="closureText">
                                    ClosureText:&nbsp;&nbsp;</label>
                              <span className="text-danger" >
                                    &nbsp;{this.props.response.closureTextErrorMsg}</span>
                              <textarea className="form-control"  rows="4" id="closureText"
                                    onChange={(val) => this.checkClosureText(val.target.value)} > 
                              </textarea>
                        </td> 
                  </tr>
             
            :
                  null
            }
                                  
           
   </>
  }
}

export default EventResponseCard;