import CreditCardChargesList from "../datasets/credit_card_charges_list";

export const applyToCreditCard = (creditCardInfo, cashExpense, cashDesc, cashCategory, cashType) => {
   
     if (cashType == undefined){
         cashType = "discretionary";
     }
     if (cashType == "discretionary"){ 
            cashExpense = cashExpense * -1;
     }
     creditCardInfo.totalOutstandingBalance = +creditCardInfo.totalOutstandingBalance +  +cashExpense;
     let indx = creditCardInfo.monthlyCreditCharges.length - 1;
     let category = cashCategory;
     if (cashCategory == undefined){
            category = "Other";
            let findCategory = CreditCardChargesList.find(elem=> elem.desc === cashDesc);
            if (findCategory != undefined){
                  category = findCategory.category;
            }
     };  
     let creditObj = {amount: cashExpense, desc: cashDesc, category: category};       
     creditCardInfo.monthlyCreditCharges[indx].creditChargesArr.push (creditObj);
              
    return creditCardInfo;
   
}
