import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import isNumeric from '../../objects/CommonUse/checkIfNumeric';
import retrieveGameDialog from '../game-views/objects/retrieve_game_dialog';

class DialogParmInputModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      dataValidSw: true,
      friendErrorMsg: "",
      dialogIdxErrorMsg: "",
      openResponsePossible: false,  
    }    
}

editInput(){
    this.state.dataValidSw = true;
    this.getFormValues();
    this.editFormFields();

    if (this.state.dataValidSw == true){
        this.dialogIdx = +this.dialogIdx - 1;
      let dialogParameters = {friendName: this.friendName, dialogIdx: this.dialogIdx}; 
      this.props.onSubmit(dialogParameters);  
    }
}

getFormValues(){
    this.friendName = document.getElementById("friendName").value;
    this.dialogIdx = document.getElementById("dialogIdx").value;
}

editFormFields(){
    this.state.dataValidSw = true;
    this.editFriendName();
    this.editDialogIdx();
 }
 
 editFriendName(){
    this.state.dataValidSw = true;
    this.state.friendErrorMsg = "";
    if (this.friendName == undefined || this.friendName == ""){
      this.state.friendErrorMsg = "Friend name cannot be blank";
      this.state.dataValidSw = false;
    }else{
      let friendList = this.props.friendList;
      const dialogInfo = friendList.find(elem => elem.name === this.friendName);
      if (dialogInfo != undefined){
           //  friend name is valid
      }else{
        this.state.friendErrorMsg = "Friend Name is not in list";
        this.state.dataValidSw = false;
      }
    }
  }

  editDialogIdx(){
    this.state.dataValidSw = true;
    this.state.dialogIdxErrorMsg = "";
    if (isNumeric(this.dialogIdx)){
         let dialogItem = retrieveGameDialog(this.friendName, this.dialogIdx, [], 0);
         if (this.dialogIdx > dialogItem.nbrDialogItems){
            this.state.dialogIdxErrorMsg = "Dialog Idx gt max table entries";
            this.state.dataValidSw = false;
         }
    }else{
            this.state.dialogIdxErrorMsg = "Dialog Idx must be numeric";
            this.state.dataValidSw = false;
     }
  }
   

render() {
                           
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="sm" scrollable="true">
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Enter Parameters to Display Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
 
            <div className="text-left">
                  <p>Please complete the fields on this form and then click 
                     'Submit'.</p>
            <div>
                  <label className="font-weight-bold" htmlFor="friendName">Friend Name:&nbsp;&nbsp;
                   </label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="friendName">
                        <option value="none">No Name Selected</option>
                        <option value="Cameron">Cameron</option>
                        <option value="Charlie">Charlie</option>
                        <option value="Cindy">Cindy</option>
                        <option value="Jennifer">Jennifer</option> 
                   </select>
                   <span className="text-danger">
                          &nbsp;{this.state.friendErrorMsg}</span>
                </div>
               
                <div>
                    <label className="font-weight-bold" htmlFor="dialogIdx">
                        Dialog Index:&nbsp;&nbsp;</label>
                    <input type="text" className="col-small" id="dialogIdx" name="dialogIdx"></input> 
                    <span className="text-danger">
                          &nbsp;{this.state.dialogIdxErrorMsg}</span>
                </div>
                    
            </div>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
              {this.state.dataValidSw ?
                <div></div>
              :
                <div className="text-danger">Correct errors and try-again.</div>
              }
                         
              <Button variant="primary"  onClick={() => this.editInput()}>
                   Submit
              </Button>
        </Modal.Footer>
    </Modal>
    
  </>
  }
}

export default DialogParmInputModal;