import {Component} from 'react';
import CareerCard from './career_card';
import { withRouter } from "react-router";
import {SingleSelectionContext} from '../../objects/Context/single_selection_context';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import {calcInflationAmt} from '../../objects/CommonUse/calc_inflation_amt';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import WaitTimeSpinner from './wait_time_spinner';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class CareerInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selection: null, 
            headers: setHeadersForFetch(),
            environment: Config.dataURL.currentEnv,
        };
        this.subProcess = "CareerInput";
        this.jobsArr = [];
        props.onOpen();
    }

    componentDidMount(){
        // render and return are done before it is mounted for the first time!!!
        this.getJobsList();
    }

    calcInflatedCost(){
        this.calcGameYears();
        var i;
        for (i=0; i< this.jobsArr.length; i++){
            this.jobsArr[i].minSalary = 
                +calcInflationAmt(this.jobsArr[i].minSalary, this.yearsDiff);
            this.jobsArr[i].maxSalary = 
                +calcInflationAmt(this.jobsArr[i].maxSalary, this.yearsDiff);
        }
        this.inflatedCostDoneSw = true;
    }  
       
    calcGameYears(){
        var yearMonthVals = [];
        yearMonthVals = this.props.currentGameDate.split('-'); 
        this.currentGameYear = +yearMonthVals[0];
        yearMonthVals = this.props.startGameDate.split('-'); 
        this.startGameYear = +yearMonthVals[0];
        this.yearsDiff = this.currentGameYear - this.startGameYear;
    }

    render() { 
        if (this.jobsArr.length != 0 && this.props.currentGameDate != undefined 
            && this.inflatedCostDoneSw != true){
                this.calcInflatedCost(); 
        }
        let selectedJob = sessionStorage.getItem("careerChoice");
        if (selectedJob !== undefined) {
            this.state.selection = selectedJob;
        }
        let careerList = this.jobsArr.map((job, key) =>
            <CareerCard key={key} selectedJobID={this.state.selection} jobProfile={job} 
                onSelected={() => this.onJobSelected({job})}/>
        );
        let screenParms = setScreenSizeParameters(this.props.screenSize, "gettingStarted2");
        this.className = screenParms.columnClass;
       
    return <>
            <div className={this.className}>
                <div className="card mt-3 mx-0 rounded-corners-lg text-center height-max">
                    <h3 className="mt-2">Choose a Job</h3>
                    <p>There are 10 jobs that are displayed.  These are the jobs that 
                       most closely match the skills/interests survey that you completed.
                    </p>
                    {this.state.loading  || this.props.waitState ?
                       <WaitTimeSpinner />
                    :
                        <div className="mt-2 mb-5 overflow-scroll">
                            <SingleSelectionContext.Provider value={this.state}>
                                {careerList}
                            </SingleSelectionContext.Provider>
                        </div>
                    }
                </div>
            </div>
        </>
    }

    getJobsList() {
        const skillsInput = sessionStorage.getItem("skillsInterestsInput");
        const funcName = "jobsList";
        const urlParm = "?skills=" + skillsInput;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        // sort by match score and job name
                        this.jobsArr = output.body;
                        this.setState({loading: false});
                    }else{
                        const errorObj={function: funcName, subProcess: this.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
    }


    onJobSelected(job) {
        job = job.job;
        sessionStorage.setItem("careerChoice", job.id);
        sessionStorage.setItem("careerName", job.jobName);
        sessionStorage.setItem("salary", job.minSalary);
        sessionStorage.setItem("jobNbrYearsCollege", job.nbrYearsCollege);
        sessionStorage.setItem("jobDegreeType", job.degreeType);
        sessionStorage.setItem("jobDegreeSpecialty", job.degreeSpecialty);
        sessionStorage.setItem("jobHealthInsInd", job.healthInsPaidInd);
        sessionStorage.setItem("jobMatchScore", job.matchScore);
        sessionStorage.setItem("jobRank", job.rank);
        sessionStorage.setItem("careerPictureImg", job.pictureImg);
        sessionStorage.setItem("careerBackgroundImg", job.backgroundImg);
        sessionStorage.setItem("careerMaxSalary", job.maxSalary);
        sessionStorage.setItem("careerLevel2Title", job.level2Title);
        sessionStorage.setItem("careerLevel3Title", job.level3Title);
        var relatedCareers = [];
        if (job.relatedCareers != undefined){
                relatedCareers = job.relatedCareers      
        }
        sessionStorage.setItem("careerRelatedCareers", JSON.stringify(relatedCareers));
        this.setState({selection: job.id});
        this.props.onSelected();  
        
    }
}
 
export default withRouter(CareerInput);