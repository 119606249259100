import LinkTab from '../components/shared-components/link_tab';
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import React from 'react';
import {Link} from "react-router-dom";
import SpecialProcessMain from '../components/special/special_process_main';
import TestActivities from '../components/special/test_activities';


class SpecialActivity extends React.Component {
    constructor(props) {
        super(props);
          
    this.inputCompleted = (inputID) => {
        this.state.inputArr[inputID].completed = true;
        this.setState({inputArr: this.state.inputArr}); 
    };

    this.inputSelected = (inputID) => {
        for (const input of this.state.inputArr) {
            input.selected = false;
        }
        this.state.inputArr[inputID].selected = true;
        this.setState({inputArr: this.state.inputArr});  
       
    };

     this.state = {
        inputArr: [
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
          
        ],
        inputCompleted: this.inputCompleted,
        inputSelected: this.inputSelected,
        gameData: null,
        gameDataSw: false,
        doneLoading: false,
        loading: true,
        data: null,
        fancyDragNDrop: true,
        openMapAnimation: true,
        screenSize: sessionStorage.getItem("GameScreenSize"),
        gameInfo: {
            loading: true,
            gameData: {},
            cardList: [],
            currentCard: {},
            eventConditions: [],
            pictureInfo: "",
            savingsInfo: "",
            creditCardInfo: {},
            friendInfo: {},
            displayFriendInfo: {},
            activityTracker: {},
            creditCardLoaded: false,
            nextEventNbr: "",
            displayType: "none",   
        },
        specialInfo: {
            loading: true,
            loadingConditions: true,
            gameData: {},
            conditionsData: [],
            currentCard: "",
            cardList: [],
            cardIdx: 0,
            cardResponses: []
        }
    };

    switch(props.selected) {
        case "specialProcessMain":
            this.inputSelected(0);
            break;
        case "testActivities":
            this.inputSelected(2);
            break;
     }
}

    render(){
       
    return <>
        <span className="container m-0 p-0">
                <span className="row w-100 mx-0">
        
                  
                        <TestActivities
                            screenSize={this.state.screenSize}
                            /> 
                                                    
                 
                                   
                </span>
        </span>   
                  
    </>
}

handleClick = index => {
    this.state.inputSelected(index);
    this.index = index;
}


getGameInfo()
{
    return this.state.gameInfo;
}

setGameInfo(value, propertyName = null)
{
    if (propertyName)
    {
    this.state.gameInfo[propertyName] = value;
    this.setState({gameInfo: this.state.gameInfo});
    }
    else{ this.setState({gameInfo: value});
    }
         
}

getSpecialInfo()
{
    return this.state.specialInfo;
}

setSpecialInfo(value, propertyName = null)
{
    if (propertyName)
    {
    this.state.specialInfo[propertyName] = value;
    this.setState({specialInfo: this.state.specialInfo});
    }
    else{ this.setState({specialInfo: value});
    }
         
}



}

export default SpecialActivity;