import { removeConditionsTable } from "./remove_Conditions_Table_function";
import determinePossiblePurchases from "./determine_possible_purchases";

export const processEventChangesToConditionsTable = (conditionsTable, triggerCondName, duration, deleteCondName,
        deleteClass, eventConditions, purchaseArr, imageDir ) => {

    if (conditionsTable == undefined){
        conditionsTable = []
    }

    // delete any conditions that have a class that matches the delete class on the event response
    // or if they have a condition name that matches the condition name on the event response
    if (deleteCondName != undefined && deleteCondName != "") 
       {
        // going thru conditionsTable backwards as it is the only way that the delete works
        // else, the splice resets the index 
        let startIdx = conditionsTable.length - 1
        for (let i=startIdx; i>=0; i--){
            if (conditionsTable[i].condName == deleteCondName){
                conditionsTable.splice(i,1)
            }
        }
    }   
    if (deleteClass!= undefined && deleteClass != ""){
        conditionsTable = removeConditionsTable(conditionsTable, deleteClass);
    }

    // add conditions for no more purchases if there are no available purchases for this purchaseType (imageDir)
    let noMorePurchaseCondition = "";
    if (imageDir !== undefined && imageDir !== ""){
        let possiblePurchasesArr = determinePossiblePurchases(purchaseArr,imageDir);
        if (possiblePurchasesArr.length === 0){
            noMorePurchaseCondition = 
                        eventConditions.find(elem => elem.primaryFieldName === imageDir);
            let conditionObj = {condName: noMorePurchaseCondition.condName, 
                    conditionClass: noMorePurchaseCondition.conditionClass,
                            duration: -1 }
                    conditionsTable.push(conditionObj);
        }
    }

    if (triggerCondName!= ""){
        if (duration <= 1){
            duration = -1;
        }
    // find the trigger condition on the conditions collection and add the condition that matches 
    // if it is not already on the conditionsTable
        let conditionFindObj = eventConditions.find(elem => elem.condName === triggerCondName);
        let conditionAlreadyThere = 
            conditionsTable.find(elem => elem.condName === triggerCondName);
        if (conditionFindObj != undefined && conditionFindObj != "" && 
            conditionAlreadyThere == undefined){
                let conditionObj = {condName: conditionFindObj.condName, 
                    conditionClass: conditionFindObj.conditionClass,
                    duration: duration }
                conditionsTable.push(conditionObj); 
        }
    }
                
    return conditionsTable;
   
}
