const updatePlayerFriendInfo = {
    friendList: [
        {
          "name": "Charlie",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Testing",
          "introDate": "2022-10",
          "dialogIndx": 0,
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        }
      ]
}

export default updatePlayerFriendInfo;