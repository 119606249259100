const CoatDriveDynamicDataset = [
        {gameName: "CoatDrive1",  column1Name: "Coat Donations", columns:
                  [{columnName: "Brown-Coat",
                        items: [{content: "", amount: "", picture: "brownCoat-001.png", order: 1},
                                {content: "", amount: "", picture: "brownCoat-002.png", order: 2}, 
                                {content: "", amount: "", picture: "brownCoat-003.png", order: 3}]
                   },
                         
                   {columnName: "Yellow-Green-Coat",
                        items: [{content: "", amount: "", picture: "yellowGreenCoat-001.png", order: 1},  
                                {content: "", amount: "", picture: "yellowGreenCoat-002.png", order: 2}, 
                                {content: "", amount: "", picture: "yellowGreenCoat-003.png", order: 3}]
                   }, 
                                
                   {columnName: "Red-Coat",
                        items: [{content: "", amount: "", picture: "redCoat-001.png", order: 1 },
                                {content: "", amount: "", picture: "redCoat-002.png", order: 2 },
                                {content: "", amount: "", picture: "redCoat-003.png", order: 3 }]
                   },
    
                   {columnName: "Orange-Coat",
                        items: [{content: "", amount: "", picture: "orangeCoat-001.png", order: 1 },
                                {content: "", amount: "", picture: "orangeCoat-002.png", order: 2 },
                                {content: "", amount: "", picture: "orangeCoat-003.png", order: 3 }]
                   },

                   {columnName: "Pink-Purple-Coat",
                   items: [{content: "", amount: "", picture: "purpleCoat-001.png", order: 1 },
                           {content: "", amount: "", picture: "purpleCoat-002.png", order: 2 },
                           {content: "", amount: "", picture: "purpleCoat-003.png", order: 3 }]
                   },

                   {columnName: "Green-White-Jacket",
                   items: [{content: "", amount: "", picture: "greenWhiteJacket-001.png", order: 1 },
                           {content: "", amount: "", picture: "greenWhiteJacket-002.png", order: 2 },
                           {content: "", amount: "", picture: "greenWhiteJacket-003.png", order: 3 }]
                   },

                   {columnName: "Blue-Coat",
                   items: [{content: "", amount: "", picture: "blueCoat-001.png", order: 1 },
                           {content: "", amount: "", picture: "blueCoat-002.png", order: 2 },
                           {content: "", amount: "", picture: "blueCoat-003.png", order: 3 }]
                   },

                     
                   {columnName: "Red-Coat-With-Fur-Collar",
                   items: [{content: "", amount: "", picture: "redCoatFurCollar-001.png", order: 1 },
                           {content: "", amount: "", picture: "redCoatFurCollar-002.png", order: 2 },
                           {content: "", amount: "", picture: "redCoatFurCollar-003.png", order: 3 }]
                   },
                ],
        }
        
]
 
export default CoatDriveDynamicDataset;