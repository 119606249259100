

export const determineYearsOfCollege = (newNbrYearsCollege, currentNbrYearsCollege, relatedCareers, 
        currentCareerName, careerNbrMonths) => {

   var reducedNbrYearsCollege = 0;
 
   for (let i=0; i<relatedCareers.length; i++){
        if (relatedCareers[i].jobName == currentCareerName){
            if (currentNbrYearsCollege > 0){ 
                reducedNbrYearsCollege = relatedCareers[i].collegeYears;
            }else{
                if (careerNbrMonths >= 24){
                    reducedNbrYearsCollege = relatedCareers[i].collegeYears;
                }
            }
        }
   }

   if (reducedNbrYearsCollege == 0){
        if (currentNbrYearsCollege <= newNbrYearsCollege){
            reducedNbrYearsCollege = (currentNbrYearsCollege / 2).toFixed(0);
        }else{
            reducedNbrYearsCollege = (newNbrYearsCollege / 2).toFixed(0);
        }
        if (reducedNbrYearsCollege > 2){
            reducedNbrYearsCollege = 2;
        }
   }
                 
   return reducedNbrYearsCollege;
   
}





