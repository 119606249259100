const friendVolunteerActivityGeneralDialogDataset = [
        {activityPreference: "1", commentTable: [
                "That would be a firm 'No'.  I have a lot of things I want to do and volunteering " +
                        "is certainly not one of them.",
        ]},

        {activityPreference: "2", commentTable: [
                "Hmmmm....  I guess I'll come along. Volunteer work is not my favorite. Can we at " +
                        "least go out for dinner afterward?" ,
        ]},
                    
        {activityPreference: "3", commentTable: [
                "Sure I'll go.  Maybe we can do something fun when we're done." ,
        ]},

        {activityPreference: "4", commentTable: [
                "Count me in!!  It is always fun to do something that helps other people." ,
        ]},
  
        
]
 
export default friendVolunteerActivityGeneralDialogDataset;