import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router";
import {InputCompletedContext} from '../objects/Context/input_completed_context';
import ContactUsMain from '../components/contactUs/contact_us_main';
import GameFeedbackPage from "../components/contactUs/game_feedback_page";
import CommentModal from '../components/shared-components/comment_modal';
import {Link} from "react-router-dom";
import React from 'react';
import LinkTab from "../components/shared-components/link_tab";
import { setHeadersForFetch } from "../objects/CommonUse/set_headers_for_fetch";
import {buildUrl } from '../objects/CommonUse/build_URL';
import PlayerCommentDisplay from '../components/contactUs/player_comment_display';
import TeacherParentSignup from "../components/contactUs/teacher_parent_signup";
import Config from '../config';

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
               
    this.inputCompleted = (inputID) => {
        this.state.inputArr[inputID].completed = true;
        this.setState({inputArr: this.state.inputArr}); 
    };

    this.inputSelected = (inputID) => {
        for (const input of this.state.inputArr) {
            input.selected = false;
        }
    this.state.inputArr[inputID].selected = true;
    this.setState({inputArr: this.state.inputArr});  
        
    };

    this.openCommentModal = this.openCommentModal.bind(this);
    this.closeCommentModal = this.closeCommentModal.bind(this);

     this.state = {
        inputArr: [
            {
                completed: false,
                selected: true
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
            {
                completed: false,
                selected: false
            },
          
        ],
        inputCompleted: this.inputCompleted,
        inputSelected: this.inputSelected,
        commentIcon: "./assets/ContactUs/pencilPaper.png",
        commentModalOpen: false,
        fromProcessName: "AboutPage",
        screenSize: sessionStorage.getItem("GameScreenSize"),
        gameInfo: {},
        environment: Config.dataURL.currentEnv,
        subProcess: "ContactUsMain",
        
    };

    switch(props.selected) {
        case "contactUsMain":
            this.inputSelected(0);
            break;
        case "teacherParentSignup":
            this.inputSelected(1);
            break;
        case "gameFeedback":
            this.inputSelected(2);
            break;
        case "playerComments":
            this.inputSelected(3);
            break;
                
    }
    this.getUsername();
}

getUsername(){
    this.username = sessionStorage.getItem("username");
}

    render(){

        if (this.state.screenSize == "wide"){
            this.commentIconClass = "comment-icon-position comment-img";
        }else{
            this.commentIconClass = "comment-icon-position-medium comment-img"; 
        }
    return <>
            <span className="container m-0 p-0">
                <span className="row w-100 mx-0">
                    {this.state.screenSize == "wide" ?
                        <div className="col-sm-2 mt-4 ml-3">
                            <div>
                                <img  className="train-track-img" src={'./assets/ExtraCredit.png'} 
                                    alt="MoneyTrain Picture"/>
                            </div>   
                        </div>
                    :
                        null
                    }
               
                    {this.props.selected === "contactUsMain" ? 
                            <ContactUsMain
                                screenSize={this.state.screenSize}
                                goBack = {() => this.goToHomePage()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                /> 
                    : 
                        <span/>
                    }

                    
                    {this.props.selected === "teacherParentSignup" ? 
                            <TeacherParentSignup
                                screenSize={this.state.screenSize}
                                goBack = {() => this.goToHomePage()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                                /> 
                    : 
                        <span/>
                    }

                    {this.props.selected === "gameFeedback" ?
                            <GameFeedbackPage
                                screenSize={this.state.screenSize}
                                username={this.username}
                                goBack = {() => this.goToHomePage()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                    :
                       <span/>
                    }

                    {this.props.selected === "playerComments" ?
                            <PlayerCommentDisplay
                                screenSize={this.state.screenSize}
                                username={this.username}
                                goBack = {() => this.goToHomePage()}
                                processError = {(errorObj) => this.processErrorReturn(errorObj)}
                            />
                    :
                       <span/>
                    }

                    {this.username == null ?
                        null
                    :
                        <div>
                            <a target="_blank" data-tip="Click to submit a comment about game." rel="noopener noreferrer">
                                <ReactTooltip globalEventOff="click" />
                                    <img src={this.state.commentIcon} 
                                        className={this.commentIconClass} alt="comment"
                                        onClick={this.openCommentModal}/>
                            </a>
                        </div>
                    }
                
                    {this.state.commentModalOpen ?
                        <CommentModal modalOpen={this.state.commentModalOpen} 
                            onClose={() => this.closeCommentModal()}
                            gamePart={this.state.fromProcessName}
                            processName={this.props.selected}
                            gameInfo = {() => this.getGameInfo()}
                            processError = {(errorObj) => this.processErrorReturn(errorObj)}
                        />
                    :
                        null
                    }
                            
                    <div className="col-sm-2 ml-5">   
                                                                                  
                        <Link to="contact-us-main" onClick={() => this.handleClick(0)}>    
                            <LinkTab inputArr={this.state.inputArr[0]} name="Contact Us" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>  
                        <Link to="teacher-parent-signup" onClick={() => this.handleClick(1)}>    
                            <LinkTab inputArr={this.state.inputArr[1]} name="Teacher Parent Signup" validToComplete="true" 
                                screenSize={this.state.screenSize}/>
                        </Link>  
                        {this.username != null ?
                            <Link to="game-feedback" onClick={() => this.handleClick(2)}>    
                                <LinkTab inputArr={this.state.inputArr[2]} name="Game Feedback" validToComplete="true" 
                                    screenSize={this.state.screenSize}/>
                            </Link>    
                        
                        :
                            <a target="_blank" data-tip="Click on the person icon (in the header area)
                                and logon to Provide Game feedback." rel="noopener noreferrer">
                            <ReactTooltip globalEventOff="click" />
                                <LinkTab inputArr={this.state.inputArr[2]} name="Game Feedback" 
                                    screenSize={this.state.screenSize}/>
                            </a>
                        } 
                        {this.username != null ?
                            <Link to="player-comments" onClick={() => this.handleClick(3)}>
                                <LinkTab inputArr={this.state.inputArr[3]} 
                                    name="Review My Game Comments" validToComplete="true" 
                                    screenSize={this.state.screenSize}/>
                            </Link>
                        :
                            <a target="_blank" data-tip="Click on the person icon (in the header area)
                                 and logon to Review Game Comments." rel="noopener noreferrer">
                            <ReactTooltip globalEventOff="click" />
                                <LinkTab inputArr={this.state.inputArr[3]} name="Review My Game Comments" 
                                    screenSize={this.state.screenSize}/>
                            </a>
                        }                
                    </div>    
             
            </span>
       </span>             
    </>
}

openCommentModal(){
    this.state.commentModalOpen = true;
}

closeCommentModal(){
    this.state.commentModalOpen = false;
    this.setState({commentModalopen: false});
}

handleClick = index => {
    this.state.inputSelected(index);
}


getGameInfo()
{
    return this.state.gameInfo;
}

goToHomePage(){
    console.log("gotoHOmePage in contact us....")
    this.props.history.push('/');
}


processErrorReturn(errorObj){
    this.addRecordToLogfile(errorObj);
    if (errorObj.silentAlert == undefined || errorObj.silentAlert == false){
        alert ("Uh oh!  Something unexpected has occurred " +
            "error has been logged and will be addressed. " +
            "For now click OK, then return to the process " +
            "where you were and try again.");
        this.props.history.push('/');
    }
}

addRecordToLogfile(errorObj){
    let urlParm = "";
    let headers = "";
    if (errorObj.errorObject.userName === "NotSignedIn"){
        headers = new Headers();
        headers.set('Authorization', 'Basic ' + Buffer.from(this.username + ":" + this.password).toString('base64'));
        urlParm = "?tokenType=TempToken"
    }else{
        headers=setHeadersForFetch();
    }
    if (errorObj.status == 250){
        errorObj.message = "No record found";
    }
    let subProcess = "";  
    if (errorObj.subProcess == undefined){
        subProcess = "unknown"
    }else{
        subProcess = errorObj.subProcess;
    }
    if (errorObj.errorObject == undefined){
        errorObj.errorObject = {};
    }
    const logFileBody =  {
        mainProcess: "About",
        subProcess: subProcess,
        function: errorObj.function,
        status: errorObj.status,
        message: errorObj.message,
        errorObj: errorObj.errorObject
    }
    console.log("logFileBody is: ", logFileBody)
    const uri = buildUrl(this.state.environment, "saveLogFile", urlParm);
    fetch (uri,  
        {method:'POST',
            headers: headers,
            body: JSON.stringify(logFileBody)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        console.log("logfile create was successful");
                    }else{
                        console.log("Response from save log file not valid!" +
                         " status is: ", output.statusCode,
                         " message is: ", output.body);
                        alert ("Response from save log file not valid!");
                           
                    }
            });
          
        }
    });
  
}

}
export default withRouter(ContactUs);