import ConditionClasses from "../../../datasets/condition_classes_dataset";

export const editConditionClass = (conditionClass, source, responseLit) => {
  var errorObj="";
  var conditionClassValid = true;
  var errMessage = "";

  if (conditionClass === undefined){
    conditionClassValid = false;
    errMessage = "Condition Class is Undefined";
  }else{
    if (source === "online"){
      //  currently there is no online entry for this so no edits required.
    }else{
      var conditionClassFound;
      conditionClassFound = ConditionClasses.includes(conditionClass);
          
      if (conditionClassFound === false){
          conditionClassValid = false;
          errMessage = "Condition Class not found in dataset";
      }
    }
  }
  if (conditionClassValid === false){
     errorObj = {fieldClass: responseLit, fieldName: "ConditionClass", fieldValue: conditionClass, 
     message: errMessage};
  }
 
  return errorObj;
 
}