export const editDate = (dateIn) => {
    let dateIsValid = true;
    let errorMsg = "";
    const thirtyDayMonths = ("04", "06", "09", "11");
    const dateArr = dateIn.split("/");
    if (dateArr.length < 3){
        dateIsValid = false;
        errorMsg = "Format of date must be YYYY/MM/DD."
    }else{
        const year = dateArr[0];
        const month = dateArr[1];
        const day = dateArr[2];
        if (+year < 2000 || +year > 3000){
            dateIsValid = false;
            errorMsg = "Year must be numeric and between 2000 and 3000";
        };
        if (+month < 1 || +month > 12){
            dateIsValid = false;
            errorMsg = "Month must be numeric and between 01 and 12";
        }
        let maxDay = 31;
        if (dateIsValid == true && thirtyDayMonths.includes(month)){
            maxDay = 30;
        }
        if (dateIsValid == true && month == "02"){
            maxDay = 29;
        }
        if (+day < 1 || +day > maxDay){
            dateIsValid = false;
            errorMsg = "Day must be numeric and between 01 and maximum day for the month.";
        }
    }

    const dateEditObj = {dateIsValid: dateIsValid, errorMsg: errorMsg};
    return dateEditObj;
}
export default editDate;