const updateGamePurchases = {
    "gameID":  "77777777",           
    "purchaseTable": [{
        "itemName": "testing item purchased",
        "itemType": "1000",
        "itemCost": "500.00",
        "enjoyWithFriendSw": true,
        "imageURL": "testThis",
        "imageDisplayHeight": "50%",
        "imageDisplayWidth": "50%",
        "nbrTimesPurchased": "2",
        "gameDateLastPurchased": "2024-08-12"
    }]
}

export default updateGamePurchases;