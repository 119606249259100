import {Component} from 'react';


class GameCardResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responseReceived: false, 
            responseInd: null};
    }

    render() {
        let left = 60;
        let bottom = 60;
       
        let cardClass= "faceup-game-card game-card-blue";
 
    return <>
            <div className={cardClass} style={{left: left, bottom: bottom}}>
                <h5>{this.props.title}</h5>
                {this.props.response.closureText.length < 180 ?
                    <p>{this.props.response.closureText}</p>
                    :
                    <p className="small-text">
                        {this.props.response.closureText}</p>
                }
                <div className="btn-sm btn-primary w-75 mt-2 ml-4" 
                    onClick={()=>this.props.onComplete(0)}>OK</div>
            </div>
        
    </>
    }

}

export default GameCardResponse;