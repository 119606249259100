import ConditionClasses from "../../../datasets/condition_classes_dataset";

export const editConditionClass = (condClass, responseLit) => {
  var errorObj="";
  var classValid = true;
  var errMessage = "";  

  if (condClass === undefined || condClass === ""){
    //    this is an option field
  }else{
    let validConditionClasses = ConditionClasses.sort();
    let classFound = validConditionClasses.includes(condClass);
    if (classFound === false){
          classValid = false;
          errMessage = "Condition Class not found in dataset";
    }
  }
  if (classValid === false){
      errorObj = {fieldClass: responseLit, fieldName: "Class", fieldValue: condClass, message: errMessage};
  }
  
  return errorObj;
 
}