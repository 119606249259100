import React from 'react'
import { useState, useEffect } from 'react';

export const TimerReturn = (props) => {   
    const {initialMinute = 0,initialSeconds = 0} = props;
    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);
    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                    props.clearInterval();
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
            let time = {minutes: minutes, seconds: seconds}
            props.postTime(time);
          };
    });
    
    return (
        <div>
             <h4>Time Remaining: &nbsp;&nbsp; {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h4> 
        </div>
    )
}

export default TimerReturn;