const FeedbackQuestionList ={
  version: "01-2022.1",
  questionList: [
    {question: "It is easy to determine how to play this game.", answer: ""},
	  {question: "I would like to play this game again.", answer: ""},
    {question: "I liked the pictures and the general 'feel' of the game.", answer: ""},
    {question: "I thought the game was fun to play.", answer: ""},
    {question: "I could improve my score if I played the game again.", answer: ""},
    {question: "The events that are on the cards seem to be mostly bad things.", answer: ""}
    ],
  commentList: [
    {question: "What did you like about playing the game?", answer: ""},
    {question: "What didn't you like about playing the game?", answer: ""},
    {question: "What did you learn from playing the game?", answer: ""},
    {question: "If you did not activate a credit card and/or did not setup savings (unlocked features), " +
      "can you explain why you made that decision?"},
    {question: "General Comment", answer:""}
  ]
}

export default FeedbackQuestionList;