import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EventCategoryList from '../game-views/datasets/event_category_list';
import DropDownOptionCard from '../shared-components/drop_down_option_card';
import isNumeric from '../../objects/CommonUse/checkIfNumeric';

class EventParmInputModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      dataValidSw: true,
      displayTypeErrorMsg: "",
      gamePhaseErrorMsg: "",
      categoryErrorMsg: "",
      jobErrorMsg: "",
      eventCategoryArr: EventCategoryList
    }    
}

componentWillMount(){
      this.categoryOptionArr = [];
      //  setting max to 1 less than the length to skip last entry
      let maxOptions = this.state.eventCategoryArr.length - 1;
      for (let i=0; i < maxOptions; i++){
           let eventCategory = this.state.eventCategoryArr[i];
           let optionObj = {index: i, value: eventCategory.category, selection: eventCategory.textName};
           if (i == 2){
               optionObj.selection = "Job Specific";
           }
           this.categoryOptionArr.push(optionObj);
      }
}

editInput(){
    this.state.dataValidSw = true;
    this.getFormValues();
    this.editFormFields();

    if (this.state.dataValidSw == true){
      let eventParameters = {displayType: this.displayType, gamePhase: this.gamePhase, 
        eventCategory: this.category, careerTag: this.jobName, eventNbr: this.eventNbr}; 
      this.props.onSubmit(eventParameters);  
    }
}

getFormValues(){
    this.displayType = document.getElementById("displayType").value;
    this.gamePhase = document.getElementById("gamePhase").value;
    this.category = document.getElementById("category").value;
    this.jobName = document.getElementById("jobName").value;
    this.eventNbr = document.getElementById ("eventNbr").value;
}

editFormFields(){
    this.state.dataValidSw = true;
    this.state.gamePhaseErrorMsg = "";
    this.state.categoryErrorMsg = "";
    this.state.jobErrorMsg = "";
    if (this.displayType == undefined || this.displayType == "none"){
       this.state.displayTypeErrorMsg = "Display Type cannot be blank";
       this.state.dataValidSw = false;
    }
    if (this.gamePhase == undefined || this.gamePhase == "none"){
        this.state.gamePhaseErrorMsg = "Game Phase cannot be blank";
        this.state.dataValidSw = false;
    }
    if (this.category == undefined || this.category == "none"){
      this.state.categoryErrorMsg = "Event Category cannot be blank";
      this.state.dataValidSw = false;
    }else{
       if ((this.category == "job" &&  this.jobName != "" ) ||
           (this.category != "job" &&  this.jobName == "")){
             // this is a valid combination
       }
       if (this.jobName != ""){
            if (this.props.careerTags.includes(this.jobName)){
              //  job name is valid
            }else{
                this.state.jobErrorMsg = "Job Name is not in the list of career tags."
                this.state.dataValidSw = false;
            }
       }
       if (this.eventNbr != ""){
            let validNumber = isNumeric(this.eventNbr);
            if (validNumber == false){
                this.state.eventNbrErrorMsg = "Event Number must be numeric."
                this.state.dataValidSw = false;
            }
       }
    }
  }
   

render() {
      this.categoryOptions = this.categoryOptionArr.map((optionsList,index) =>
            <DropDownOptionCard options={optionsList} key={index} />
            );
      
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="sm" scrollable="true">
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Enter Parameters to Display Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
 
            <div className="text-left">
                  <p>Please complete the fields on this form and then click 'Submit'.</p>
            <div>
                  <label className="font-weight-bold" htmlFor="displayType">Display Type:&nbsp;&nbsp;</label>
                    <select 
                      className="btn btn-sm btn-outline-secondary dropdown-toggle" id="displayType">
                          <option value= "none">No Display Type Selected</option>
                          <option value="cards">Card Display</option>
                          <option value="list">List Type Display</option>
                   </select>
                   <span className="text-danger">
                          &nbsp;{this.state.displayTypeErrorMsg}</span>
            </div>
            <div>
                  <label className="font-weight-bold" htmlFor="gamePhase">Game Phase:&nbsp;&nbsp;</label>
                    <select 
                      className="btn btn-sm btn-outline-secondary dropdown-toggle" id="gamePhase">
                          <option value= "none">No Game Phase Selected</option>
                          <option value="1">Game Phase 1</option>
                          <option value="2">Game Phase 2</option>
                          <option value="all">Game Phase All</option>
                   </select>
                   <span className="text-danger">
                          &nbsp;{this.state.gamePhaseErrorMsg}</span>
            </div>
            <div>
                  <label className="font-weight-bold" htmlFor="category">Event Category:&nbsp;&nbsp;</label>
                    <select 
                      className="btn btn-sm btn-outline-secondary dropdown-toggle" id="category">
                          <option value= "none">No Category Selected</option>
                            {this.categoryOptions}
                    </select>
                   <span className="text-danger">
                          &nbsp;{this.state.categoryErrorMsg}</span>
                </div>
                <div>
                    <label className="font-weight-bold" htmlFor="jobName">
                        Job Name:&nbsp;&nbsp;</label>
                    <input type="text" className="col-med" id="jobName" name="jobName"></input> 
                    <span className="text-danger">
                          &nbsp;{this.state.jobErrorMsg}</span>
                </div>
                <div>
                    <label className="font-weight-bold" htmlFor="eventNbr">
                        Event Number (optional):&nbsp;&nbsp;</label>
                    <input type="text" className="col-med" id="eventNbr" name="eventNbr"></input> 
                    <span className="text-danger">
                          &nbsp;{this.state.eventNbrErrorMsg}</span>
                </div>
                    
            </div>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
              {this.state.dataValidSw ?
                <div></div>
              :
                <div className="text-danger">Correct errors and try-again.</div>
              }
                         
              <Button variant="primary"  onClick={() => this.editInput()}>
                   Submit
              </Button>
        </Modal.Footer>
    </Modal>
    
  </>
  }
}

export default EventParmInputModal;