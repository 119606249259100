const updateGamePurchases2 = {
    gameID:  "88888888",           
    purchaseTable: [{
        itemName: "testing item purchased",
        itemType: "1000",
        itemCost: "500.00",
        enjoyWithFriendSw: true,
        imageURL: "testThis",
        imageDisplayHeight: "50%",
        imageDisplayWidth: "50%",
        canPurchaseMultipleSw: true,
        nbrTimesPurchased: "2",
        gameDateLastPurchased: "2024-08-12"
    }]
}

export default updateGamePurchases2;