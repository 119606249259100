import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


class AdminReportModal extends Component {
  constructor(props) {
    super(props);
 //   this.gameInfo = this.props.gameInfo();
    this.state={
         
    };    
  }


  render() { 
       
  return <>
   
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} 
            size="med" animation={false}>
        <Modal.Header className={this.modalBackground} closeButton>
            <Modal.Title>Administrator Reports</Modal.Title> 
          </Modal.Header>
          <Modal.Body className={this.modalClass}>
                 <span>    
                    <div>Display last date of logfile entry:</div>
                  </span>
            
          </Modal.Body>
          <Modal.Footer className={this.footerClass}>
              <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"
                alt="train"/>
              <Button  variant="info"   onClick={() => this.props.onClose()}>
                   Close
              </Button>
          </Modal.Footer>
          </Modal>
    
    </>
  }

  //  the following is used to make text into a link....  may want to support this after basic process is working
  //    <a className="" onClick ={()=>this.goToMainPage()}>Go to Main Menu-click on Extra Credit.</a> 
 
}
export default withRouter(AdminReportModal);