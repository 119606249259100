const CreditCardChargesList =[
  // this converts a list of charges to a category
  {desc: "Do Something Fun", category: "Entertainment"},
  {desc: "Feed the World Charity", category: "Charities"},
  {desc: "Save the Earth Charity", category: "Charities"},
  {desc: "Buy 1 New Tire", category: "Automobile"},
  {desc: "Full Service Oil Change", category: "Automobile"},
  {desc: "Go to a Movie and Dinner", category: "Entertainment"},
  {desc: "Play Frisbee and Dinner", category: "Entertainment"},
  {desc: "Camping Trip", category: "Entertainment"},
  {desc: "Grand Canyon", category: "Entertainment"},
  {desc: "Car Repair - mid", category: "Automobile"},
  {desc: "Play Golf and Dinner", category: "Entertainment"},
  {desc: "Car Repair - minor", category: "Automobile"},
  {desc: "Go Out to Dinner With Friend", category: "Entertainment"},
  {desc: "Automobile Body Work", category: "Automobile"},
  {desc: "Traffic Stop - Speeding Ticket", category: "Automobile"},
  {desc: "Had to get Stiches", category: "Health"},
  {desc: "Purchase New Tires for Car", category: "Automobile"},
  {desc: "Car Maintenance", category: "Automobile"},
  {desc: "Refrigerator Repair", category: "Home"},
  {desc: "Apartment Water Damage", category: "Home"},
  {desc: "Rent due to Roommate Change", category: "Home"},
  {desc: "New Furniture", category: "Home"},
  {desc: "Repair Furniture", category: "Home"},
  {desc: "Buy New Mattress", category: "Home"},
  {desc: "Traffic Ticket for Speeding", category: "Automobile"},
  {desc: "Towing and Car Maintenance", category: "Automobile"},
  {desc: "Dining Room Furniture", category: "Home"},
  {desc: "Bedroom Furniture", category: "Home"},
  {desc: "Reclining Chairs", category: "Home"},
  {desc: "Remove Clog in Drain", category: "Home"},
  {desc: "New Wiper Blades", category: "Automobile"},
  {desc: "Local Ice Cream Shop", cateogory: "Entertainment"},
  {desc: "Karaoke Palace", category: "Entertainment"}
  
];

export default CreditCardChargesList;