import {editEventsCollection} from '../editEventCollectionProcesses/editEventsCollection';

export const rptEditEvents = (eventsData, conditionsSortByCondName, careerConditionsSortByCondName ) => {
    var reportDataArr = [];
    var errorArray=[];
    var reportData = "";
    reportData = {title: "Event Title", rcdNbr: "Record Number", gamePhase: "Game Phase",
        category: "Event Category", careerTag: "Career Tag", fieldClass: "Field Class",
        fieldName: "Field Name", fieldValue: "Field Value", errorMsg: "Error Message"}
    reportDataArr.push(reportData);
        for(let i=0; i<eventsData.length; i++){
            errorArray=editEventsCollection(eventsData[i],
                conditionsSortByCondName, careerConditionsSortByCondName);
            if (errorArray.length > 0){
                let eventCategory = "Undefined";
                if (eventsData[i].eventCategory !==  undefined){
                    eventCategory = eventsData[i].eventCategory;
                }
                let gamePhase = "Undefined";
                if (eventsData[i].gamePhase !==  undefined){
                    gamePhase = eventsData[i].gamePhase;
                }
                let careerTag = "Undefined";
                if (eventsData[i].careerTag !==  undefined){
                    careerTag = eventsData[i].careerTag;
                }
                let mainLit = {title: eventsData[i].title, rcdNbr: (+i+1),
                    gamePhase: gamePhase,  category: eventCategory, careerTag: careerTag};
                for (let j=0; j<errorArray.length; j++){
                    reportData = {...mainLit, fieldClass: errorArray[j].fieldClass, fieldName: errorArray[j].fieldName,
                        fieldValue: errorArray[j].fieldValue, errorMsg: errorArray[j].message};
                    reportDataArr.push(reportData);
                }
                errorArray = [];
            }
        }

    return reportDataArr;
} 

export default rptEditEvents;

