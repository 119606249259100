

export const buildOneTimeCostArr = (profitFromHomeSale, oneTimeCosts, currentHome, newHome) => {
   
       let oneTimeCostArr = [];
       let oneTimeObj = "";
       oneTimeObj = {label: "Moving Cost", amount: oneTimeCosts.movingCost, order: 2, color: "red"};
       oneTimeCostArr.push(oneTimeObj);
       if (oneTimeCosts.newFurnitureCost > 0){
           oneTimeObj = {label: "New Furniture", amount: oneTimeCosts.newFurnitureCost, order: 3, color: "red"};
           oneTimeCostArr.push(oneTimeObj);
       }
       if (currentHome.HomeType === "apartment"){
           oneTimeObj = {label: "Damage Deposit Refund (current apt)", 
                   amount: oneTimeCosts.depositRefundAmt, order: 4, color: "black"};
           oneTimeCostArr.push(oneTimeObj);
       }
       if (profitFromHomeSale > 0){
           oneTimeObj = {label: "Profit From Sale of Current Home", 
               amount: profitFromHomeSale, order: 5, color: "black"};
           oneTimeCostArr.push(oneTimeObj);
       }
       if (newHome.HomeType === "apartment"){
           oneTimeObj = {label: "Damage Deposit New Apartment",
                amount: oneTimeCosts.damageDeposit, order: 6, color: "red"};
           oneTimeCostArr.push(oneTimeObj);
       }else{
           oneTimeObj = {label: "Down Payment on Home", amount: newHome.DownPaymentAmt, 
               order: 1, color: "red"};
           oneTimeCostArr.push(oneTimeObj);
       }
       if (oneTimeCosts.newTools > 0){
           oneTimeObj = {label: "New Tools (garden and home)", amount: oneTimeCosts.newTools, 
               order: 6, color: "red"};
           oneTimeCostArr.push(oneTimeObj);
       }
       if (oneTimeCosts.totalOneTimeCost > 0){
           oneTimeObj = {label: "Total One Time Cost", amount: oneTimeCosts.totalOneTimeCost, 
               order: 99, color: "red"};
       }else{
           let amtAddedToCash = +oneTimeCosts.totalOneTimeCost * -1;
           oneTimeObj = {label: "Total Amt Added to Cash", amount: +amtAddedToCash, 
                order: 99, color: "black"};
       }   
       oneTimeCostArr.push(oneTimeObj);
       oneTimeObj = {label: "Total Amt of Cash in Bank", amount: currentHome.CashAmt,
           order: 0, color: "black"}; 
       oneTimeCostArr.push(oneTimeObj);
       oneTimeCostArr.sort((a, b) => (a.order > b.order) ? 1 : -1);
              
    return oneTimeCostArr;
   
}
export default buildOneTimeCostArr;
