import {Component} from 'react';
import "./ContactUs/contactUs.css";
import FeedbackModal from './feedback_modal';
import {setHeadersForFetch} from '../../objects/CommonUse/set_headers_for_fetch'
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import Config from '../../config';
import {buildUrl} from '../../objects/CommonUse/build_URL';

class GameFeedbackPage extends Component {
    constructor(props) {
        super(props);
        this.state={
          openModal: false,
          headers: setHeadersForFetch(),
          subProcess: "ContactUs",
          environment: Config.dataURL.currentEnv,
                                   
        };   
        this.typeOfModal = "";
    }

    setTypeOfModal(modalName){
        this.typeOfModal = modalName;
        this.setState({openModal: true});
    }

    closeModals() {
        this.typeOfModal = "";
        this.setState({openModal: false});
     }
           
    
    render() {
        this.username = sessionStorage.getItem("username");
        let screenParms = setScreenSizeParameters(this.props.screenSize, "about");
        this.className = screenParms.columnClass;
        this.textClass = screenParms.textSize + " ml-4 mr-4 mt-4";
        this.imageClass = screenParms.imageSize;
        if (this.props.screenSize === "narrow"){
              this.column1Class = "col-sm-1 mt-4 ml-3"
              this.column2Class = "col-sm-9"
        }else{
              this.column1Class = "col-sm-3 mt-4 ml-5";
              this.column2Class = "col-sm-7 mt-4"
        }
                                         
    return <>
            <div className={this.className}>
                <div className="card mt-3 rounded-corners-lg height-max container"> 
                    <button className="pages-back-button ml-4 mt-4" 
                        onClick ={()=> this.props.goBack()}>
                                Back
                    </button>
                    <h3 className="mt-0 p-0 text-center">Contact Us</h3>
                    <h5 className="text-center">Game Feedback</h5>
                    <div className="overflow-scroll container mt-2">
                        <div className={this.textClass}>            
                            <div>
                               Your feedback is very important to us.  The feedback will be used to help improve
                               the game.  If you have played the game for a bit, please take a minute to let us
                               know what you think!  
                            </div>
                            <div className="mt-2">
                                If you would like to provide feedback on your game experience
                                that will be used to help improve the game, please click
                                on the <b>"Provide Game Feedback'</b> button below and complete the form.                           
                            </div>
                            <div className="mt-2"> 
                                If you are a parent or teacher, you can review the feedback that has been 
                                provided by your students/children.  Just go to the "For Parents/Teachers"
                                process, select the "Player Reports" button and then click on the "Player Game Feedback"
                                button.   
                            </div>
                        </div>
                       
                        <span className="row">
                            <div className={this.column1Class}>
                            <img  className={this.imageClass} src={'./assets/ContactUs/feedback.jpg'} 
                                alt="writingPic"/>

                            </div>
                            <div className={this.column2Class}>
                            
                                <span>
                                    {this.typeOfModal !== "Feedback" ?
                                        <div className="text-center mt-3 ml-3">
                                            <button className="good-looking-button" 
                                                onClick ={()=> this.setTypeOfModal("Feedback")}>
                                                    Provide Game Feedback 
                                            </button>
                                        </div>
                                    :
                                        <FeedbackModal openModal={this.state.openModal} 
                                            onClose={() => this.closeModals()} 
                                            onSubmit={(feedbackForm) => this.submitFeedbackForm(feedbackForm)}
                                            />
                                    }
                                </span>
                           
                            </div>

                        </span>
                    </div>
                </div>        
            </div>
        </>
    }

         
    submitFeedbackForm(returnVal) {
        let feedbackForm = JSON.parse(returnVal);
        const feedbackVersion = feedbackForm.feedbackVersion;
        const feedbackRole = feedbackForm.feedbackRole;
        const questionList = feedbackForm.questionList;
        let questionListOut = [];
        for (let i=0; i<questionList.length; i++){
             let questionListObj = {question: questionList[i].question,
                questionNbr: questionList[i].questionNbr, answer: questionList[i].answer};
             questionListOut.push(questionListObj);
        }
        const commentList =  feedbackForm.commentList;
        let commentListOut = [];
        for (let i=0; i<commentList.length; i++){
            let commentListObj = {question: commentList[i].question, answer: commentList[i].answer,
                questionNbr: commentList[i].questionNbr};
            commentListOut.push(commentListObj);
        }
        var emailAddress = feedbackForm.emailAddress;
        if (emailAddress === null){
            emailAddress = "not provided";
        }
        const currentDate = new Date();
        let cDay = currentDate.getDate();
        let cMonth = currentDate.getMonth() + 1;
        let cYear = currentDate.getFullYear();
        let commentDate = (cYear + "/" + cMonth + "/" + cDay);
        let feedbackObj = {
            "feedbackVersion": feedbackVersion,
            "role": feedbackRole,
            "emailAddress": emailAddress,
            "questionList": questionListOut,
            "commentList": commentListOut,
            "feedbackDate": commentDate,
        };
        const funcName = "saveFeedbackInfo";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch(uri,
                {
                method:'post',
                headers: this.state.headers,
                body: JSON.stringify(feedbackObj)
                })
                .then(response => {
                    if (response.ok){
                        response.json().then(data => {
                            const output = JSON.parse(data);
                            const statusCode = output.statusCode;
                            if (statusCode === 200){
                                alert("Your feedback has been saved.  Thank you!!");
                            }else{
                                const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                    status: statusCode, message: output.body.message, errorObject: feedbackObj};
                                this.props.processError(errorObj);
                            }
                    });
                };
        });
        this.typeOfModal = "";
        this.setState({openModal: false});
    }
 
}

export default GameFeedbackPage;