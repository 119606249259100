const DataObjectsTemplate = [
        {dataType: "activityScore", score: {type: "", points: 0, nbrIncorrect: 0, gamesRemaining: 0, nbrAttempted: 0,
                maxScore: 0, newLevelEarned: false, timeRemaining: {minutes: 0, seconds: 0}}},
        {dataType: "activityTracker", activityInfo: {activityName: "", nbrTimesPlayed: "",
                lastTimePlayed: "", level: ""}},
        {dataType: "friendList", friendInfo: {name: "", introDate: "",  dialogIndx: "firstTime", rltnshpScore: "0",
                totalLoanAmt: "0"}},
        {dataType: "creditCardInfo", creditInfo: {gameID: "", paymentPolicyCode: "", currentlyActive: false,
                creditCardStartDate: "", cardLevel: "", creditMaxAmount: "", totalOutstandingBalance: "0.00",
                totalIntBilled: "", lateFeeCount: "", monthlyCreditCharges: []
        }},
        {dataType: "monthlyCreditCharges", creditInfo: {date: "", monthlyOutstandingAmt: 0,
                monthlyAmtPaid: 0, monthlyIntAmt: 0, monthlyLateFee: 0, creditChargesArr: []}},
        {dataType: "spendingHistory", spendingHistoryInfo: {currentMonthSpendAmt: 0, current10YearSpendAmt: 0,
                current10YearMonths: 0, previous10YearSpendAmt: 0, previous10YearMonths: 0}},
        {dataType: "moodPointHistory", moodPointHistoryInfo: {currentMonthMoodPoints: 0, totalMoodPoints: 0, 
                moodPointMonths: 0}},
        {dataType: "eventsHeader", eventHeaderInfo: {title: "", description: "", frequency: "", 
                conditionConnector: "", conditionArr: [], skipConditionArr: [], backgroundType: "",
                imageDir: "", careerTag: "", selectMonthArr: [], gamePhase: "", eventCategory: "",
                dateCreated: "", dateAdded: "", dateUpdated: "", responses: []
        }},
        {dataType: "eventPossibleEffects", possibleObj: {category: "", closureText: "", on: "",
                type: "", duration: "", odds: "", replaceAmtSw: "", triggerCondition: "", 
                deleteCondition: "", deleteClass: "",  moodPoints: "", possibleValues: []
        }}
               
]
 
export default DataObjectsTemplate;