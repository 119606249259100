import {Component} from 'react';
//import Modal from 'react-bootstrap/Modal';
import {Button, Modal, ModalFooter, ModalHeader, ModalBody} from "reactstrap"
import Draggable from 'react-draggable';
//import Button from 'react-bootstrap/Button';
import '../../css/styles.css';
import termDictionary from './Datasets/term_dictionary';

class DictionaryModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                    termDictionary: termDictionary,
              }
        this.getTermDefinition();    
    }

    getTermDefinition(){
        let termName = this.props.termName.toLowerCase();
        this.termDefinitionObj = this.state.termDictionary.find(elem => elem.termName === termName);
        if (this.termDefinitionObj == undefined){
            this.termDefinitionObj = this.state.termDictionary.find(elem => elem.altName === termName);
        }
        if (this.termDefinitionObj == undefined){
                this.termDefinition = "Oops!! This term is not found in the dictionary.  Please add a comment " +
                "to note that the term is missing from the dictionary.  Thank you!!!";
        }else{
                this.termDefinition = this.termDefinitionObj.termDefinition;
        }
    }
        
    render() {

    return <>
     <Draggable>
        <Modal isOpen={this.props.modalOpen} animation="false">
            <ModalHeader className="backgroundTopBottom"  >
                                   
                Definition of Term: &nbsp;{this.props.termName}
             </ModalHeader>
            <ModalBody>
                <span>
                    <div className="my-2">
                        {this.termDefinition}
                    </div>
                  
            </span>
            </ModalBody>
            <ModalFooter className="p-0 backgroundTopBottom">
                <div className="row">
                    <div className="col-sm-10">
                            <b> Note:</b>  You can drag this box up, down, right, or left.
                    </div>
                    <div className="col-sm-1">
                        <Button variant="primary"  className="mr-4" onClick={() => this.props.onClose()}>
                            Close
                        </Button>
                    </div>
                </div>
            </ModalFooter>
            </Modal>
      </Draggable>
        </>
    }


}

export default DictionaryModal;