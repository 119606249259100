const financialAdvisorListStatus =[
  {name: "Fred", imageURL: "finAdvisor1.png", introText:  "Hello!  My name is Fred, I am happy to give you some advice " +
    "to help you improve your finances."},
  {name: "Janice", imageURL: "finAdvisor2.png", introText: "Hey!  I'm Janice, I've been reviewing your monthly status " +
    "I have some ideas that might help!"},
  {name: "Sam", imageURL: "finAdvisor3.png", introText: "Howdy!  I'm Sam.  I see you brought a spreadsheet of your " +
    "monthly finances.  Let's have a look."},
  {name: "Premjith", imageURL: "finAdvisor4.png", introText: "I'm Premjith but you can call me Prem.  I'm so happy to " +
     " to help with your monthly finances."},
  {name: "Cathy", imageURL: "finAdvisor5.png", introText: "Welcome to my office.  Let me take a look at your monthly status and see what " +
    "we can do to Help. I'm Cathy by the way."},
  {name: "Kim", imageURL: "finAdvisor6.png", introText: "Well hello there!  Kim is my name.  I have a masters degree " +
    "in Finance and I can certainly give you some ideas of ways to improve your money situation."},
  {name: "Thomas", imageURL: "finAdvisor7.png", introText: "Hi!  Glad you came in.  My name is Thomas, I have several ideas " +
    "that will help to improve your monthly status. " },
  {name: "James", imageURL: "finAdvisor8.png", introText: "You've come to the right person.  I am James and I'm really good " +
    "at helping people meet their financial goals.  You do have financial goals, right?"
  }
]

export default financialAdvisorListStatus;