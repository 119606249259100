import {Component} from 'react';

class IconDisplayCard extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false};
    }

    render() {
        this.imageSrc = "./assets/Icons/" + this.props.iconList.imageSrc;
        this.className = this.props.iconList.className + " cursor-is-pointer";
    return <>
       
        <div className="text-left">
            <img className={this.className} src={this.imageSrc} alt="icon"
                onClick={() => this.props.onClick(this.props.iconList.iconName,
                    this.props.iconList.iconCategory)} />
        </div>
           
    </>
    }
}

export default IconDisplayCard;