const SavingMoneyChallenge1InitialText ="Congratulations!!! You have just gotten your first full-time " +
    "job in a career that is something you think you will enjoy.  You make a good salary but there is " +
    "not a lot left after monthly expenses.  You are trying to create a basic savings plan.  You know " +
    "you can manage to save some money each month starting now.... OR.... you can wait and start saving " +
    "money when you get more experience and more years of working. You believe that if you wait to " +
    "start saving, you will be able to save more money each month even if it is for a shorter period " +
    "of time.  Which of the scenarios in the 'Savings Parameters' column will end up with more money " +
    "saved?  You can use the 'Savings Calculator to help make this assessment. Drag items from the " +
    "'Savings Factors' column to the correct column and then click 'Done'."
    
export default SavingMoneyChallenge1InitialText