import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { isNumeric } from '../../objects/CommonUse/checkIfNumeric';
import initializeErrorArray from './objects/initialize_error_array';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import TableRowCard from '../shared-components/table_row_card';
import getAuthCodes from './objects/get_auth_codes';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class ChangeAccountModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                headers: setHeadersForFetch(),
                accountNbrFound: false,
                accountLoaded: false,
                subProcess: "ChangeAccountModal",
                environment: Config.dataURL.currentEnv,
              }
              this.errArray = initializeErrorArray(5);
              this.dataValidSw = true;
              this.prevAccountNbr = "";
     }
  
  buildRowColumnArrs(){
        let rqstdUsersArr = this.accountInfo.rqstdUsersArr;
        this.rqstdUsersArr = [];
        for (let i=0; i< rqstdUsersArr.length; i++){              let reformatDateRqstd = reformatDateToDisplay(rqstdUsersArr[i].dateRqstd, "YYYY/MM/DD");
              let rqstdUserObj = {index: reformatDateRqstd, column1: rqstdUsersArr[i].role, 
                column2: rqstdUsersArr[i].nbrUsersRqstd};
              this.rqstdUsersArr.push(rqstdUserObj);
        }
        let authCodeArr = this.accountInfo.authCodeArr;
        this.authCodeArr = [];
        for (let i=0; i<authCodeArr.length; i++){
            let roleFound = false;
            let authIndx = null;
            for (let j=0; j<this.authCodeArr.length; j++){
                if (this.authCodeArr[j].column1 === authCodeArr[i].role && this.authCodeArr[j].index < 5){
                    authIndx = j;
                    roleFound = true;
                    break;
                }
            }
            if (roleFound === true){
                    this.authCodeArr[authIndx].index += 1;
                    this.authCodeArr[authIndx].column2 = this.authCodeArr[authIndx].column2 + ", " + authCodeArr[i].authCode;
            }else{
                let authCodeObj = {index: 1, column1: authCodeArr[i].role, column2: authCodeArr[i].authCode};
                this.authCodeArr.push(authCodeObj)
            }
           
        }
  }
 
  editInput(){
    this.dataValidSw = true;
    this.errArray = initializeErrorArray(5);
    this.getFormValues();
    if (this.dataValidSw === true && this.newAccountNbr === false){
        this.formatRqstdUsersArr();
        this.buildAuthCodeArr();
        this.accountObj={accountNbr: this.accountNbr, emailAddress: this.emailAddress,
          rqstdUsersArr: this.rqstdUsersArr, authCodeArr: this.authCodeArr};
        this.updateAccount();
        let accountObj = {accountNbr: this.accountNbr, emailAddress: this.emailAddress,
          primaryRole: this.primaryRole, nbrPrimary: this.nbrPrimary, nbrPlayers: this.nbrPlayers }
        this.props.onClose(accountObj);
    }
  }

  getFormValues(){
      this.accountNbr = document.getElementById("accountNbr").value;
      if (this.prevAccountNbr !== this.accountNbr){
          this.getAccountInfo();
          this.prevAccountNbr = this.accountNbr;
          this.newAccountNbr = true;
      }else{
          this.newAccountNbr = false;
          this.emailAddress = document.getElementById("emailAddress").value;
          this.primaryRole = document.getElementById("primaryRole").value;
          this.nbrPrimary = document.getElementById("nbrPrimary").value;
          this.nbrPlayers = document.getElementById("nbrPlayers").value;
          this.editFormValues();
      }
           
  }

  getAccountInfo(){
        this.accountInfo = this.props.accountList.find(elem => elem.accountNbr === this.accountNbr);
        if (this.accountInfo === undefined){
            this.errorMsg = "Account number does not exist.";
            this.checkErrorArray(0);
        }else{
            this.buildRowColumnArrs();
            this.setState({accountNbrFound: true});
        }
    
  }
  
  editFormValues(){
    //  let emailAddressValid = validator.isEmail(this.emailAddress);
    let emailAddressValid = true;
    if (this.emailAddress === ""){
        this.emailAddress = this.accountInfo.emailAddress;
    }else{
        if (this.emailAddress.indexOf('@') <= 0){
            emailAddressValid = false;
        }
    }
    if (emailAddressValid === false){
          this.errorMsg = "Invalid email address";
          this.checkErrorArray(1);
    }
    if (this.primaryRole === "none" && this.nbrPrimary !== ""){
          this.errorMsg = "Primary Role is required";
          this.checkErrorArray(2);
    }
    if (this.nbrPrimary !== ""){
        const validNbrPrimary = isNumeric(this.nbrPrimary);
        if (validNbrPrimary === false){
            this.errorMsg = "Primary Number must be numeric";
            this.checkErrorArray(3);
        }
    }
    if (this.nbrPlayers !== ""){
        const validNbrPlayers = isNumeric(this.nbrPlayers);
        if (validNbrPlayers === false){
            this.errorMsg = "Player Number must be numeric";
            this.checkErrorArray(4);
        }
    }
  } 
     
 checkErrorArray(msgIndex){
    if (this.errorMsg !== undefined && this.errorMsg !== ""){
        this.errArray[msgIndex] = {textType: "text-danger", 
            message: this.errorMsg};
        this.dataValidSw = false;
    }
  }

  formatRqstdUsersArr(){
      const dateObj =  getCurrentDateTime(); 
      this.rqstdUsersArr = this.accountInfo.rqstdUsersArr;
      if (this.nbrPrimary !== ""){
          let rqstUserObj = {role: this.primaryRole, dateRqstd: dateObj.date, nbrUsersRqstd: this.nbrPrimary};
          this.rqstdUsersArr.push(rqstUserObj);
      }
      if (this.nbrPlayers !== ""){
          let rqstUserObj = {role: "player", dateRqstd: dateObj.date, nbrUsersRqstd: this.nbrPlayers};
          this.rqstdUsersArr.push(rqstUserObj);
      }
     
  }

  buildAuthCodeArr(){
    let nbrAuthCodes = +this.nbrPrimary + +this.nbrPlayers;
    let currentAuthCodeArr = [];
    this.authCodeArr = this.accountInfo.authCodeArr;
    for (let i=0; i< this.accountInfo.authCodeArr.length; i++){
          let authCode = this.accountInfo.authCodeArr[i].authCode;
          currentAuthCodeArr.push(authCode);
    }
    if (nbrAuthCodes > 0){
        let authCodeList = getAuthCodes(nbrAuthCodes, currentAuthCodeArr);
        if (nbrAuthCodes !== authCodeList.length){
            alert("something did not work. Nbr auth codes returned not eq nbr rqstd.");
        }
        for (let i=0; i<+this.nbrPrimary; i++){
            let authCodeObj = {role: this.primaryRole, authCode: authCodeList[i]};
            this.authCodeArr.push(authCodeObj);
        }
        for (let i=0; i<+this.nbrPlayers; i++){
            let indx = i + +this.nbrPrimary;
            let authCodeObj = {role: "player", authCode: authCodeList[indx]};
            this.authCodeArr.push(authCodeObj);
        }
       
    }
}
   
    render() {
        if (this.state.accountNbrFound === true){
            this.rqstdUserList = this.rqstdUsersArr.map((tableRows,index) =>
                <TableRowCard tableInfo={tableRows} key={index}/> );
            this.authCodeList = this.authCodeArr.map((tableRows,key) =>
                <TableRowCard tableInfo={tableRows} key={key}/> );
        };
        
    return <>
         <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
            size="lg"  scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title>Account Change Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
            <form className="text-left">
                <p>Please complete all fields on this form and then click 
                   'Submit'.</p>
                <div>
                   <label className="font-weight-bold" htmlFor="accountNbr">
                        Account Number:&nbsp;&nbsp;</label>
                  <span className={this.errArray[0].textType}>
                          &nbsp;{this.errArray[0].message}</span>
                   <input type="text" className="col-med" id="accountNbr" name="accountNbr"></input> 
                </div>
                {this.state.accountNbrFound  ?
                <span>
                    <div className="mt-2">
                        <h4 className="text-center table-header-background">Current Account Information</h4>
                            <div className="row">
                            <div className="col-md font-weight-bold">Email Address:</div>
                            <div className="col-md">{this.accountInfo.emailAddress}</div>
                        </div>
                        <h6 className="mt-4 text-center font-weight-bold">Related Players List</h6>
                         <table className="mt-2 mx-4 ">
                            <tbody className="mt-4">
                              <tr className="text-center row-100">
                                 <th className="col-md-2 p-0">Date Rqstd</th>
                                 <th className="col-md-4 text-left p-0">Player Name</th>
                                 <th className="col-md-6 text-center p-0">Game Status</th>
                              </tr>
                                {this.rqstdUserList}
                            </tbody>
                          </table>
                          <h6 className="mt-4 text-center font-weight-bold">Auth Code List</h6>
                          <table className="mt-2 mx-4">
                            <tbody className="mt-4">
                              <tr className="text-center row-100">
                                  <th className="col-md-2"></th>
                                  <th className="col-md-4 text-left">Game Role</th>
                                  <th className="col-md-6 text-center">Auth Codes</th>
                              </tr>
                                {this.authCodeList}
                            </tbody>
                          </table>
                                               
                    </div>
                    <h4 className="text-center table-header-background mt-4">New Account Information</h4>
                    <div >
                        <label className="mt-2 font-weight-bold" htmlFor="emailAddress">
                            Email Address:&nbsp;&nbsp;</label>
                        <span className={this.errArray[1].textType}>
                            &nbsp;{this.errArray[1].message}</span>
                        <input type="text" className="col-lg" id="emailAddress" name="emailAddress"></input> 
                    </div>
                    <div className="mt-2">
                      <label className="font-weight-bold" htmlFor="primaryRole">Primary Role:&nbsp;&nbsp;</label>
                        <select 
                            className="btn btn-sm btn-outline-secondary dropdown-toggle" id="primaryRole">
                              <option value="none">primary role not selected</option>
                              <option value="parent">Parent</option>
                              <option value="teacher">Teacher</option> 
                        </select>
                        <span className={this.errArray[2].textType}>
                              &nbsp;{this.errArray[2].message}</span>
                    </div>
               
                    <div className="mt-2">
                        <label className="font-weight-bold" htmlFor="nbrPrimary">
                            Number of Primary Ids:&nbsp;&nbsp;</label>
                        <input type="text" className="col-small" id="nbrPrimary" name="nbrPrimary"></input> 
                        <span className={this.errArray[3].textType}>
                          &nbsp;{this.errArray[3].message}</span>
                    </div> 
                               
                    <div className="mt-2">
                        <label className="font-weight-bold" htmlFor="nbrPlayers">
                            Number of Player Ids:&nbsp;&nbsp;</label>
                        <input type="text" className="col-small" id="nbrPlayers" name="nbrPlayers"></input> 
                       <span className={this.errArray[4].textType}>
                          &nbsp;{this.errArray[4].message}</span>
                    </div> 
                </span>
                :
                    null
                }
               
            </form>
           </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
          {this.dataValidSw ?
            <div></div>
          :
            <div className="text-danger">Please Correct errors and try-again.</div>
          }
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="primary"  onClick={() => this.editInput()}>
              Submit
          </Button>
        </Modal.Footer>
        </Modal>
        </>
    }

updateAccount(){
    const currDateTime = getCurrentDateTime(); 
    // Update phase in events collection
    const bodyObj = {
        "accountNbr": this.accountObj.accountNbr,
        "emailAddress": this.accountObj.emailAddress,
        "authCodeArr": this.accountObj.authCodeArr,
        "rqstdUsersArr": this.accountObj.rqstdUsersArr,
        "dateUpdated": currDateTime.date};
    const funcName = "updateAccount";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
         {method:'PUT', headers: this.state.headers,
          body: JSON.stringify(bodyObj)})
          .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        alert("Update successful! Confirm events " +
                        "collection has been updated as expected.")
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: bodyObj};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
}

}

export default ChangeAccountModal;