const LivingBelowChallenge2Dataset = {
    items: {
        "item-1": { id: "item-1", content: "Put off buying things that are not essential until " +
                "you can afford them within your monthly budget.", 
                answer: "column-3" },
        "item-2": { id: "item-2", content: "Avoid or reduce expenses that must be paid every month " +
                "(such as home loans, car loans, and streaming services).", 
                answer: "column-3" },
        "item-3": { id: "item-3", content: "Buy whatever you want using your credit card instead of cash. " +
                "Pay only the minimum payment amount each month.",
                answer: "column-2" },
        "item-4": { id: "item-4", content: "Eat out whenever possible instead of cooking at home.",
                answer: "column-2" },
        "item-5": { id: "item-5", content: "Get a roommate to help with rent or the monthly home " +
                "loan (mortgage) payment.", 
                answer: "column-3" },
        "item-6": { id: "item-6", content: "Buy a home that is close to the maximum amount that the " +
                "lender will approve. You can afford the monthly payment but will not have much cash " +
                "left for other expenses.", 
                answer: "column-2" },   
        "item-7": { id: "item-7", content: "Consider going back to school to get a more advanced " +
                "degree (especially if tuition is paid for by your employer).", 
                answer: "column-3" },
        "item-8": { id: "item-8", content: "Make a very large investment in a high-risk stock that " +
                "promises a large pay-off.",
                answer: "column-2"},    
        "item-9": { id: "item-9", content: "Get a side-hustle or work a second job.",
                answer: "column-3"} 
    },
    columns: {
        "column-1": { id: "column-1", title: "Considerations", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7",
                "item-8", "item-9"] },
        "column-2": { id: "column-2", title: "Things to Avoid", itemIds: [] },
        "column-3": {id: "column-3", title: "Good Ideas", itemIds: []}
        
              
    },
    columnOrder: ["column-1", "column-2", "column-3"]
}

export default LivingBelowChallenge2Dataset