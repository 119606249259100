import {Component} from 'react';
import "./GameViews/gamePhase2.css"

class SpinnerLegendCard extends Component {
  constructor(props) {
    super(props);
    this.state={
   
    }    
}

render() {
      if (this.props.legendInfo.textName == "||jobName||"){
            this.textName = this.props.jobName;
      }else{
            this.textName = this.props.legendInfo.textName;
      }
      if (this.props.screenSize == "medium"){
              this.legendClass = "very-small-box py-0 very-small-text-size text-center";
              this.textClass = "very-small-text-size py-0 px-2";
      }else{
              this.legendClass = "small-box py-0 small-text-size text-center";
              this.textClass = "small-text-size py-0 px-2";
      }

return <>
    
            <tr className="text-left">
              <th className={this.legendClass}
              style={{backgroundColor: this.props.legendInfo.colorCode}}>{this.props.legendInfo.number}</th>
              <th></th>
              <th className={this.textClass}>{this.textName}</th>
            </tr>
     
   </>
  }
}

export default SpinnerLegendCard;