import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Loop from "@material-ui/icons/Loop";
 
const ImageSlider = (props) => {
    
    const [nbrSlide, setNbrSlide] = useState(0);
    const photoArr = props.photoArr;
    const photoArrLength = photoArr.length;
    const theme = useTheme();
    const [newIndex, setActiveStep] = React.useState(0);
    const [buttonName, setButtonName] = useState("Next");
    const [buttonIcon, setButtonIcon] = useState("ltr");
          
    const goToNextPicture = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (newIndex === (photoArrLength - 2)){
          theme.direction="rtl";
          setButtonIcon("rtl");
          setButtonName("Restart");
      }else{
          theme.direction="ltr";
          setButtonIcon("ltr");
          setButtonName("Next");
      }
      if (newIndex === (photoArrLength - 1)){
        setActiveStep((prevActiveStep) => prevActiveStep - photoArrLength)
      }
      setNbrSlide(newIndex);
    };

    
    const goToPreviousPicture = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      theme.direction = "ltr";
      setButtonName("Next");
      if (newIndex === (photoArrLength + 1)){
        setActiveStep((prevActiveStep) => prevActiveStep - photoArrLength)
      }
      setNbrSlide(newIndex);
    };


  return (   
               
    <div
    
      style={{
        marginLeft: "0%",
      }}
    >
        
       <div
        style={{
          maxWidth: 400,
          flexGrow: 1,
        }}
      >
      
        <Paper
          square
          elevation={0}
          style={{
            height: 50,
            display: "flex",
            paddingLeft: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            alignItems: "center",
          }}
        >
          <Typography className="text-left">{photoArr[newIndex].label}</Typography>
        </Paper>
        <img
          src={photoArr[newIndex].imgPath}
          style={{
            height: 255,
            width: "100%",
            maxWidth: 400,
            display: "block",
            overflow: "hidden",
          }}
          alt={photoArr[newIndex].label}
        />
        <MobileStepper
            variant="text"
            position="static"
            activeStep={newIndex}
            steps={photoArrLength}
            nextButton={
              <Button size="small" onClick={goToNextPicture} >
              {buttonName}
             {buttonIcon == "ltr" ? (
                <KeyboardArrowRight />
              
             ) : (
               <Loop />
               )}
               </Button>
            }
            backButton={
             
              <Button size="small"
              onClick={goToPreviousPicture}
              disabled={newIndex === 0}
                >
                 Prev
                <KeyboardArrowLeft />
             
              </Button>
            }
          />
        </div>
      </div>
      
    );
  
                     
   

  }

export default (ImageSlider);