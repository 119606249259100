
export const rptMoodPointAnalysis = (eventsData) => {
    let rptArr = [];
    
    let saveGamePhase = "";
    let saveCategory = "";
    let saveCareerTag = "";
    let totalEvents = 0;
    let totalNbrNegative = 0;
    let sumNegative = 0;
    let totalNbrPositive = 0;
    let sumPositive = 0;
    let totalNbrZero = 0;
    let moreNegative = false;
       
    (eventsData).sort((a, b) => ((a.eventCategory + a.careerTag + a.gamePhase) > 
            ( b.eventCategory + b.careerTag + b.gamePhase)) ? 1 : -1);
    for (let i=0; i<eventsData.length; i++){
        if (i === 0){
            const headingLine = {
                eventCategory: "Event Category", subCategory: "Event Sub Category",  gamePhase: "Game Phase",
                totalEvents:  "Total Nbr Events", totalNbrNegative: "Nbr Events W/ Negative", sumNegative: "Sum of Negative",
                totalNbrPositive: "Nbr Events W/ Positive", sumPositive: "Sum of Positive", totalNbrZero: "Nbr Events Zero",
                moreNegThanPos: "More Negative Than Positive"};
            rptArr.push(headingLine);
        }
        if (eventsData[i].gamePhase !== saveGamePhase ||
            eventsData[i].eventCategory !== saveCategory || eventsData[i].careerTag !== saveCareerTag){
            if (saveGamePhase !== "" && saveCategory !== ""){
                if (totalNbrNegative > totalNbrPositive){
                    moreNegative = true;
                }else{
                    moreNegative = false;
                }
                const detailRptLine = {
                    gamePhase: saveGamePhase, eventCategory: saveCategory, subCategory: saveCareerTag, 
                    totalEvents:  totalEvents, totalNbrNegative: totalNbrNegative, sumNegative: sumNegative,
                    totalNbrPositive: totalNbrPositive, sumPositive: sumPositive, totalNbrZero: totalNbrZero,
                    moreNegThanPos: moreNegative};
                rptArr.push(detailRptLine);
            }
            saveGamePhase = eventsData[i].gamePhase
            saveCategory = eventsData[i].eventCategory;
            saveCareerTag = eventsData[i].careerTag;
            totalEvents = 0;
            totalNbrNegative = 0;
            sumNegative = 0;
            totalNbrPositive = 0;
            sumPositive = 0;
            totalNbrZero = 0;
        }
        for (let j=0; j<eventsData[i].responses.length; j++){
            let response = eventsData[i].responses[j];
                for (let k=0; k<response.possibleEffects.length; k++){
                    let possible = response.possibleEffects[k];
                   
                    totalEvents += 1;
                    if (+possible.moodPoints < 0){
                        totalNbrNegative += 1;
                        sumNegative += +possible.moodPoints;
                    }
                    if (+possible.moodPoints > 0){
                        totalNbrPositive += 1;
                        sumPositive += +possible.moodPoints;
                    }
                    if (+possible.MoodPoints === 0){
                        totalNbrZero += 1;
                    }
                      
                }
        }
    }
         
    return rptArr;
   
}
export default rptMoodPointAnalysis;

