
export const splitActivityByFriend = (activityDialog, friendTable ) => {
    let initialComment = "I do not want to join you in this activity."
    
    for (let i=0; i<friendTable.length; i++){
        let activityPreference = friendTable[i].activityPreference;  
        let friendDialogObj = activityDialog.find(elem => elem.activityPreference === activityPreference);
        if (friendDialogObj == undefined){ 
                friendTable[i].comment = initialComment;
                friendTable[i].acceptDecline = "decline";
        }else{
                friendTable[i].comment =  friendDialogObj.comment;
                friendTable[i].acceptDecline = friendDialogObj.acceptDecline;
        }
       
    }
    return friendTable;
   
}
export default splitActivityByFriend;

