const LivingBelowChallenge2Dataset = {
    items: {
     "item-1": { id: "item-1", content: "Buy a brand new car every 3 years to keep up " +
            "with latest technology.",
            answer: "column-2" },
    "item-2": { id: "item-2", content: "Review amounts you spend each month and determine " +
            "ways to reduce the expense.", 
            answer: "column-3" },
    "item-3": { id: "item-3", content: "Keep credit card debt at a maximum and just pay " +
            "the minimum amount each month.",
            answer: "column-2" },
    "item-4": { id: "item-4", content: "Put together a monthly budget that identifies the " + 
            "total amount you make and the amount you spend.",
            answer: "column-3" },
    "item-5": { id: "item-5", content: "Consider ways to get a higher paying job (promotion, " +
            "going back to school).",
             answer: "column-3" },
    "item-6": { id: "item-6", content: "Buy a larger home to impress your friends and family.", 
            answer: "column-2" },   
    "item-7": { id: "item-7", content: "Eat meals at restaurants instead of cooking food at home.", 
            answer: "column-2" },
    "item-8": { id: "item-8", content: "Determine ways to reduce the monthly home payment (get a " + 
            "smaller home, get a roommate, etc.).",
            answer: "column-3"}     
},
columns: {
    "column-1": { id: "column-1", title: "Considerations", 
        itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7", "item-8"] },
    "column-2": { id: "column-2", title: "Things to Avoid", itemIds: [] },
    "column-3": {id: "column-3", title: "Good Ideas", itemIds: []}
    
          
},
columnOrder: ["column-1", "column-2", "column-3"]
}

export default LivingBelowChallenge2Dataset