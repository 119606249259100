import "./SharedComponents/sharedComponents.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {Component} from 'react';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import ImageSlider from "./image_slider";


class HomeCard extends Component {
    constructor(props) {
        super(props);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
        this.state = {open: false, selected: false};
    }

    toggleExpanded(e) {
        this.setState({open: !this.state.open});
    }

    toggleSelected(e) {
        this.setState({selected: !this.state.selected});
        this.props.onSelected(this.props.houseInfo.id);
    }
    
    render() {
        const featuresList = this.props.houseInfo.features.map((feature, key) =>
        <li key={key}>{feature}</li>
        );
        const defaultImgSrc = './assets/' + this.props.houseInfo.directory + '/default.jpg';
        this.photoArr = [];
        if (this.props.houseInfo.additionalPhotosArr !== undefined) 
        {
            for (let i = 0; i < this.props.houseInfo.additionalPhotosArr.length; ++i)
            {
                const photo = './assets/' + this.props.houseInfo.directory + '/' +
                    this.props.houseInfo.additionalPhotosArr[i].img;
                const photoObj = {label: this.props.houseInfo.additionalPhotosArr[i].label,
                    imgPath: photo}
                this.photoArr.push(photoObj);
            }
        }

        if (this.props.houseInfo.homeType == "apartment"){
             this.monthlyCostLabel = "Monthly Rent";
        }else{
             this.monthlyCostLabel = "Monthly HOA";
        }
       
     //   const photosList = photoArr.map((photo, key) =>
     //   <img key={key} src={photo} className="m-1 home_card_detail_img"/>
     //   );
        const roommates=sessionStorage.getItem("homeRoommates");
   
    return <>
        <div className=
        {roommates == this.props.houseInfo.roommates && this.props.selectedHomeID == this.props.houseInfo.id ?
            "card mx-3 my-2 link-tab-selected"
            :
            "card mx-3 my-2 drop-shadow"
        }
        >
        <div className="card-header text-left">
        <span className="container m-0 p-0">
            <span className="row w-100 mx-0" onClick={this.toggleExpanded}>
                <span className="container row">
                    <div className="col-sm-4 m-0 p-0">
                        <img src={defaultImgSrc} className="home_card_thumbnail_img"/>
                    </div>
                    <div className="col-sm-6 ml-3">
                        <p className="mb-0 mt-4 font-weight-bold">{this.props.houseInfo.title}</p>
                        {this.props.houseInfo.homeType == "house" ?
                            <div>
                                <p className="mb-0">Home Cost: &nbsp;&nbsp;
                                     {doubleToStringMonthly(this.props.houseInfo.homeCost)}</p>
                                <p className="mb-0">Monthly Mortgage: &nbsp;&nbsp;
                                     {doubleToStringMonthly(this.props.houseInfo.mortgageAmt)}</p>
                              
                            </div>
                            
                        :
                            <p className="mb-0">Monthly Rent: {doubleToStringMonthly(this.props.houseInfo.rent)}</p>
                        }
                    </div> 
                    <div className="col sm-1 text-right">
                        {this.state.open ?
                            <FontAwesomeIcon icon={faCaretDown} className="career_card_caret" />
                        :
                            <FontAwesomeIcon icon={faCaretRight} className="career_card_caret" />
                        }
                    </div>
                </span>
            </span>
        </span>
        </div>
        {this.state.open ?
            <div className="card-body">
                <span className="container m-0 p-0">
                    <span className="row w-100 mx-0">
                        <div className="col-sm p-0 m-0">
                            <div className="text-left"  onClick={this.toggleExpanded}>
                                <h6>{this.monthlyCostLabel}: ${this.props.houseInfo.rent}</h6>
                                <h6>Features and Amenities</h6>
                                <ul>{featuresList}</ul>
                            </div>
                        </div>
                            <div className="col-sm p-0 m-0">
                                <ImageSlider
                                    photoArr={this.photoArr} />
                               
                            </div>
                        
                    </span>
                </span>
                <div className="text-right">
                    <button className="btn btn-primary" onClick={this.toggleSelected}>
                        Select Home
                    </button>
                </div>
            </div>
        : <span/>}
        </div>
    </>
    }
}

export default HomeCard;