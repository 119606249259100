export const combinePlayerChoices = (gameChoiceInArr, jobChoiceData, homeChoiceData, carChoiceData) => {


    let allChoicesArr = [];
    for (let i=0; i<gameChoiceInArr.length; i++){
        const gameChoice = gameChoiceInArr[i];
        let jobSelectItem = jobChoiceData.find(elem => elem._id === gameChoice.jobID);
        let homeSelectItem = homeChoiceData.find(elem => elem._id === gameChoice.homeID);
        let carSelectItem = carChoiceData.find(elem => elem._id === gameChoice.carID);
        if (carSelectItem == undefined || jobSelectItem == undefined
            || homeSelectItem == undefined){
        }else{
            let gameChoiceInfo = {jobID: gameChoice.jobID, jobName: jobSelectItem.jobName,
                jobPictureImg: jobSelectItem.pictureImg, jobBackgroundImg: jobSelectItem.backgroundImg,
                jobLevel2Title: jobSelectItem.level2Title, jobLevel3Title: jobSelectItem.level3Title, 
                jobMaxSalary: jobSelectItem.maxSalary,
                homeID: gameChoice.homeID, homeName: homeSelectItem.title, 
                homeBedrooms: homeSelectItem.bedrooms, homePictureImg: homeSelectItem.directory, 
                homeAdditionalPhotosArr: homeSelectItem.additionalPhotosArr,
            
                carID: gameChoice.carID, carSizeName: carSelectItem.sizeName,
                carPictureImg: carSelectItem.directory, gameID: gameChoice.gameID};
                allChoicesArr.push(gameChoiceInfo);
        }
    };
    return allChoicesArr;
}

export default combinePlayerChoices;