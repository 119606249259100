import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import {Component} from 'react';

class LoginIcon extends Component {
    constructor(props) {
        super(props);
    }

    render() { 
        return <>
            <a href="#" onClick={() => this.handleClick()}>
                <FontAwesomeIcon icon={faUserCircle} className="login-icon"/>
            </a>
        </>
    }

    handleClick()
    {
        this.props.toggleLoginMenu();
    }
}

export default LoginIcon;