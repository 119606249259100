import {Component} from 'react';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';

class GameObjectives extends Component {
    
       render() {
            let screenParms = setScreenSizeParameters(this.props.screenSize, "about");
            this.className = screenParms.columnClass;
            this.textClass1 = screenParms.textSize + " text-left";
            this.textClass2 = screenParms.textSize;
                   
        return <>
            <div className={this.className}>
                <div className="card mt-3 rounded-corners-lg height-max">
                    <button className="pages-back-button ml-4 mt-4" 
                            onClick ={()=> this.props.goBack()}>
                                    Back
                    </button>
                    <h3 className="mt-0 p-0 text-center">About Page</h3>
                    <h6 className="text-center">Key Game Objectives</h6>
                    <div className="mt-0 mb-4 mx-3 overflow-scroll">
                        <ol className={this.textClass1} type="1">
                            <li className={this.textClass2}>Life choices that are made when a person is a young adult will likely 
                                have a long-term financial impact (career, college, housing).</li>
                            <li className={this.textClass2}>There is a life changing advantage of having a
                                positive net income. 
                                When people make more than they
                                spend, they will be able to increase their cash position
                                throughout their life. </li>  
                            <li className={this.textClass2}>There are many unplanned events that impact an individual's financial
                                position.  It is a good idea to have some extra cash on-hand to easily
                                handle these unexpected events.
                            </li>
                        </ol>
                        <h6 className="text-center mt-4">Additional Topics Introduced in the Game</h6>
                        <ol className={this.textClass1} type="1">
                            <li className={this.textClass2}>It is a good idea to plan for all of the expenses that 
                                will impact your monthly income and your cash position.</li>
                            <li className={this.textClass2}>Some expenses are not optional but there are often ways to
                                reduce the ongoing cost. For example:</li>
                                <ol type="a">
                            <li className={this.textClass2}>Housing – choose a cheaper apartment or consider a roommate</li>
                                    <li>Car – choose to buy a smaller car or a used car</li>
                            </ol>
                                <li className={this.textClass2}>Loans can spread the cost of a large 
                                expense over time, however, the total 
                                amount paid will exceed the amount borrowed due to interest. </li>
                        </ol>
                    </div>
                    </div>
                </div>         
            
        </>
    }

}

export default GameObjectives;