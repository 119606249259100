import {Component} from 'react';
import ReactTooltip from "react-tooltip";
import "./GameViews/gameViews.css";
import "../special/Special/special.css";
import { cashStrMask } from './objects/game_view_mask_functions';
import { dateStrToPlainText } from './objects/game_view_mask_functions';


class CashMoodStatusBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
           };
    }

    render() { 
                 
    return <>
        {dateStrToPlainText(this.props.gameDate)}<br />
        <a target="_blank" data-tip="This is your Current Cash Amount." rel="noopener noreferrer">
                <ReactTooltip globalEventOff="click" />
                    {cashStrMask(+this.props.currentCash)}<br />
        </a>

        <div>
        <a target="_blank" data-tip="This is your Mood Score." rel="noopener noreferrer">
            <ReactTooltip globalEventOff="click" />
                <img src={this.props.moodEmoji}
                    className="smiley-face-position smiley-game-img" alt="smileyFaceEmoji" />
                        {+this.props.currentMoodPoints}
                </a>
        </div>
    </>
    }
  
}
 
export default CashMoodStatusBox;