import {Component} from 'react';
import CommentProcessCodeDesc from './datasets/comment_process_code_desc';
import "./Admin/admin.css";


class PlayerCommentDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }    
    this.reviewCodeDescriptions = CommentProcessCodeDesc;
    this.addReviewCodeDescription();
    this.setThumbsUpURL();
}
  

addReviewCodeDescription(){
     if (this.props.playerFeedback.reviewed !== undefined){
          this.reviewCode = +this.props.playerFeedback.reviewed.code;
          const reviewCommentObj = this.reviewCodeDescriptions.find(elem => elem.code === this.reviewCode);
          this.reviewComment = this.props.playerFeedback.reviewed.comment;
          if (reviewCommentObj === undefined){
               this.codeDescription = "Code is not defined"
          }else{
               this.codeDescription = reviewCommentObj.description;
          }
     }else{
          this.codeDescription = "This comment has not yet been reviewed.";
          this.reviewCode = "99";
          this.reviewComment = "";
     }
 }

 setThumbsUpURL(){
     this.thumbURL = './assets/'
     switch (this.props.playerFeedback.thumbsChosen){
          case "thumbsDown":
               this.thumbURL += "thumbsDown.jpg";
               break;
          case "thumbsSideWays":
               this.thumbURL += "thumbsSideways";
               break;
          default:
               this.thumbURL += "thumbsUp.jpg";
               break;
     } 
 }
 


render() {
     this.displayReviewedInfo = false;
     if (this.props.commentsOnly === true){
          if (this.props.playerFeedback.reviewed !== undefined){
               this.displayReviewedInfo = true;
          }
     }
return <>
      <tr>
          <td className="font-weight-bold text-left">
               {this.props.playerFeedback.user}
          </td>
          <td>
               {this.props.playerFeedback.dateAdded} {this.props.playerFeedback.timeAdded}
          </td>
          <td className="text-center" >
               <img src={this.thumbURL} 
                    className="thumbs-img-feedback" alt={this.thumbURL}
               />
          </td>
           
          <td className="text-left" >
               {this.props.playerFeedback.comment}
          </td>           
      </tr>    

      {this.props.commentsOnly ?
          null
      :
          <tr>
               <td className="text-white pt-0" colSpan="3"> blank</td>
               <td className="text-left pt-0" colSpan="1">
                    <b>GamePart: </b> {this.props.playerFeedback.gamePart} &nbsp;&nbsp;
                    <b>Game Process: </b> {this.props.playerFeedback.process} &nbsp; &nbsp;
                    <b>Game Sub-Process: </b>{this.props.playerFeedback.subProcess} &nbsp;&nbsp;
               </td>
          </tr>
     }
      
          <tr>
               <td className="text-white pt-0" colSpan="3">blank</td>
               <td className="text-left pt-0" colSpan="1">
                    <b>Reviewed Code:</b> {this.reviewCode} &nbsp;&nbsp;
                    <b>Code Definition:</b> {this.codeDescription}
               </td>
          </tr>
    
          <tr>
               <td className="text-white pt-0" colSpan="3">blank</td>
               <td className="text-left pt-0" colSpan="1">
                    <b>Reviewer Comment:</b> {this.reviewComment}
               </td>
          </tr>   
          
              
   </>
  }
}

export default PlayerCommentDisplayCard;