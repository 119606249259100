const friend2GameDialogDataset ={friendName: "Charlie",  dialogTable: [
       {title: "Charlie Introduction",
              text: "Hi, my name is Charlie, I live nextdoor.  I thought I'd come over to meet you. " +
             "Maybe we can hang out sometime.",
             conditionList: [],  
             responses: [{rspText: "Hello! I'm pretty busy right now, but maybe another time.", 
                            moodPoints: -2, rltnPoints: -1},
                         {rspText: "Sure, you wanna come in?", moodPoints: 2, rltnPoints: +2}]},
       {title: "Nice Apartment",
             text: "I really like these apartments. I moved in just a few months ago. " + 
             "I was living with my parents before. It is great to be on my own now. " +
             "What do you think of your apartment?",
             conditionList: [],  
             responses: [{rspText: "It's OK. Nothing special that's for sure.", 
                            moodPoints: -1, rltnPoints: -1},
                         {rspText: "It's great!  Good to have my own place.", 
                            moodPoints: +2, rltnPoints: +1}]},
      {title: "Something smells good to eat",
             text: "I just dropped by to say hello!  Something smells really good, " +
             "mind if I hang out and eat with you?",
             conditionList: [],  
             responses: [{rspText: "Sure come in and sit down. It's almost ready.", 
                            moodPoints: 3, rltnPoints: +2},
                         {rspText: "No, I didn't make enough to share.", 
                            moodPoints: -2, rltnPoints: -2}]},
      {title: "Lost my job",
             text: "I just lost my job.  I worked really hard and everything, but they " +
             "fired me... argh! Oh well, I didn't like the job anyway. Mind if I stay for awhile?",
             conditionList:  [],  
             responses: [{rspText: "Sorry about that. Come in and we can talk.", 
                            moodPoints: 1, rltnPoints: +2},
                         {rspText: "Wow, sorry but I don't have time to talk right now.", 
                            moodPoints: -2, rltnPoints: -2}]},
        {title: "Join him for dinner",
              text: "I went to my parent's for dinner last night and have a bunch of " +
                     "leftovers. Wanna come over and share them with me?",
             conditionList:  [],  
             responses: [{rspText: "I don't think so, I already made my own dinner.", 
                            moodPoints: -2, rltnPoints: -1},
                         {rspText: "That sounds great.  I'll be right over.", 
                            moodPoints: 2, rltnPoints: +1}]},
        {title: "New job",
              text: "I'm so excited.  I just got a new job.  I'm going to work at " +
                     "a local restaurant as a waiter.",
             conditionList:  [],  
             responses: [{rspText: "Very cool!  Let's go out and celebrate.", 
                            moodPoints: 2, rltnPoints: +2},
                         {rspText: "A waiter, really?  Is that the best you can do?.", 
                            moodPoints: -2, rltnPoints: -2}]},
       // 5 items
                         
       {title: "Job as a Chef",
              text: "I've been going to culinary school for awhile. I am learning how to " +
              "be a chef. It's why I'm so excited about my new job.  They said that once I " +
              "graduate they will promote me to be a chef apprentice.",
              conditionList:  [],  
              responses: [{rspText: "That sounds like a great plan!", 
                            moodPoints: 2, rltnPoints: +2},
                          {rspText: "I'd believe it when it happens.", 
                            moodPoints: -1, rltnPoints: -2}]},
       {title: "Loaning money to Charlie",
              text: "I hate to ask, but is there a chance you could loan me $500? " +
                     "I just started my new job and I think I can pay you back fairly soon. " +
                     "But I'm short on cash right now.",
             conditionList:  [],   
             responses: [{rspText: "Sorry I don't have extra money right now.", 
                            moodPoints: -3, rltnPoints: -3, 
                            loanedMoney: false},
                         {rspText: "Yes, I can do that.",  on: "cashOnly",
                             moodPoints: -2, rltnPoints: +5, cashAmt: -500, 
                             triggerCondition: "loaned-charlie-money"}]},  
       {title: "Go for a walk",
              text: "I wondered if you wanted to go for a walk?  I don't have " +
                     "much money right now but it would be good to have a chance " +
                     "to talk and get some exercise.",
             conditionList:  [],  
             responses: [{rspText: "Sure, let's go.", moodPoints: 2, rltnPoints: +1},
                         {rspText: "I don't want to walk right now.", 
                            moodPoints: -2, rltnPoints: -2}]},  
        {title: "Charlie pays back loan",
              text: "I just stopped by to give you back the $500 that I borrowed. " +
                     "I really appreciate the loan. Can I take you out to dinner to say thank you?",
             conditionList:  ["loaned-charlie-money"], type: "in-person",
             responses: [{rspText: "Thanks for paying me back.  Yes, going out to " +
                            "dinner sounds nice.", moodPoints: 3, 
                            rltnPoints: +2, cashAmt: 500, deleteCondition: "loaned-charlie-money"},
                         {rspText: "I appreciate you paying me back.  I don't want to go out right now.", 
                            moodPoints: 2, rltnPoints: -2, cashAmt: 500, 
                            deleteCondition: "loaned-charlie-money"}]},  
          
       // 10 items
       {title: "401K Saving",
              text: "I just setup a 401K savings account.  I am hoping to save some money each month " +
                     "That way I'll have some money when I retire.  Best to start early you know.",
              conditionList: [],  
              responses: [{rspText: "That's a really good idea!", moodPoints: 1, rltnPoints: +1},
                          {rspText: "That sounds like a waste.", moodPoints: -1, rltnPoints: -2}] }, 
       {title: "Go back to school?",
              text: "How is your job going?  You are really smart.  Have you thought " +
                    "about going back to school to get a job that pays more?",
             conditionList:  ["no-college"], type: "in-person", deleteCondition: "",
             responses: [{rspText: "No, I don't want to go to college.", moodPoints: -2, rltnPoints: 0},
                         {rspText: "Yes, I have been thinking about it.", moodPoints: 2, rltnPoints: +1}]},  
       {title: "College paid off",
              text: "How is your job going?  You are really smart.  I guess your " +
                    "time at college is really paying off.",
             conditionList:  ["4-years-college"], type: "in-person", deleteCondition: "",
             responses: [{rspText: "Yes, I am glad I went to college.", moodPoints: 2, rltnPoints: 1},
                         {rspText: "Yeah, I guess.", moodPoints: -1, rltnPoints: -1}]},  
       {title: "401K Saving Too much",
              text: "Remember I started a 401K savings account? Well, I was saving too much " +
                    "each month.  I had to pull out some savings to pay for bills.  They charged me " +
                    "$400 to transfer $2000 to checking." ,
              conditionList: [],  
              responses: [{rspText: "Whoa!  Sorry about that!", moodPoints: 1, rltnPoints: +1},
                          {rspText: "Sounds like you made a dumb decision.", moodPoints: -1, rltnPoints: -2}] }, 
       {title: "401K Interest Earned",
              text: "After I settled accounts for having to withdraw money out of " +
                    "my 401K, I got some good news. I turns out that I earned about $200 " +
                    "in interest.  So the $400 that it cost to withdraw money really only " +
                    "cost me $200." ,
              conditionList: [],  
              responses: [{rspText: "Sounds like it all worked out OK!", moodPoints: 1, rltnPoints: +1},
                          {rspText: "I guess that's one way to justify your mistake.", 
                            moodPoints: 1, rltnPoints: -2}] },  
       
       // 15 items
       {title: "Graduating Soon",
              text: "This is my last semester at school.  Just 3 more months and I'll graduate... YAY",
              conditionList: [],  
              responses: [{rspText: "Took you a long time.  I doubt it will be worth it.", moodPoints: -1, rltnPoints: -2},
                          {rspText: "That's exciting.  I guess I'll have to start calling " +
                            "you 'Chef Charlie', right?", 
                            moodPoints: 1, rltnPoints: +1}] },   
       {title: "401K Saving Amount Adjusted",
              text: "I had to adjust the amount I was saving each month in my 401k.  Hopefully I have " +
                     "it setup now so I won't have to transfer money again. " ,
              conditionList: [],  
              responses: [{rspText: "So now it's setup the way it should have been from the beginning.", 
                            moodPoints: -1, rltnPoints: -1},
                          {rspText: "Good job!  I'm glad you got it fixed.", moodPoints: 2, rltnPoints: 2}] },  
       {title: "Fix you Dinner",
              text: "I thought I'd stop by to see if you wanted me to fix you some dinner. " +
                    "I wanted to try out something that I learned in culinary school... " +
                    "I added my own creative twist. " ,
              conditionList: [],  
              responses: [{rspText: "Hmmmm....  I don't think I want to be the first to " +
                            "try your 'creative twist'.", 
                            moodPoints: -2, rltnPoints: -1},
                          {rspText: "Sure, the kitchen is all yours!", moodPoints: 2, rltnPoints: 2}] },  
       {title: "Credit Card Setup",
              text: "I've been getting a bunch of credit card offers.  I finally decided to get a card. " +
                     "I set it up so I'm only paying the minimum each month.  It's like free money!!!",
              conditionList: [],  
              responses: [{rspText: "Great idea!!!", 
                            moodPoints: -2, rltnPoints: +1},
                          {rspText: "That's dumb, you'll end up paying a lot more!", 
                            moodPoints: 2, rltnPoints: -1}] },   
       {title: "Let's Go Out",
              text:  "I'm wondering if you wanna go out to dinner tonight.  My treat, I can put it " +
                     "on my credit card.",
              conditionList: [], 
              responses: [{rspText: "Absolutely, let's go.", moodPoints: +1, rltnPoints: +1},
                         {rspText:  "No, I'm too tired to go out.", moodPoints: -2, rltnPoints: -1}]}, 
      
       // 20 items
       {title: "Credit Card Trouble",
              text:  "The bank just froze my credit card. It seems I've maxed out.  Now I owe $5000 to the bank " +
                     "and over half of what I owe is interest charges.  I owe the bank more than I spent!",
              conditionList: [], 
              responses: [{rspText: "I knew that was a bad idea.", moodPoints: -1, rltnPoints: -1},
                         {rspText:  "Wow, that's rough.", moodPoints: -1, rltnPoints: +1}]}, 
       {title: "Credit Card Loan to Charlie",
              text: "Can you loan me $1000 so I can pay down my credit card? " +
                     "I'm set to finish school and get a promotion next month. " +
                     "I will pay you back as soon as I can.",
              conditionList:  [],   
              responses: [{rspText: "Do I look like a bank?  No, I won't loan you money.", 
                            moodPoints: -3, rltnPoints: -3 },
                          {rspText: "Yes, I can make that work.  Hope you can pay me back soon.", 
                            moodPoints: -2, rltnPoints: +5, cashAmt: -1000, 
                            triggerCondition: "loaned-charlie-money"}]}, 
       {title: "Promise to Pay Back Loan",
              text:  "I just stopped by to say Hi.  With the money you loaned me and money that I saved, " +
                     "I was able to pay down the balance on my credit card. " +
                     "I'm working to pay you back soon.",
              conditionList: ["loaned-charlie-money"], 
              responses: [{rspText: "Sounds good.  Thanks for the update.", moodPoints: -1, rltnPoints: 1},
                         {rspText: "I was hoping you would've paid back some of the loan by now.", 
                            moodPoints: -2, rltnPoints: -2}]},  
       {title: "Celebration dinner",
              text:  "Hello! I graduated from Cullinary School last week.  " +
                     "I talked with my boss at work.  She says I will get promoted in " +
                     "the next week or so. I can't afford to go anywhere expensive, " +
                     "but do you want to go out to celebrate?",
              conditionList: [], 
              responses: [{rspText: "Sure, there's a new place I wanted to try.", 
                             moodPoints: 1, rltnPoints: 1,
                             cashAmt: 37.56, itemDesc: "Food Bonanza Restaurant"},
                          {rspText: "No, I dton't feel like it right now.", 
                              moodPoints: -2, rltnPoints: -1}]}, 
       {title: "Pay Back Part of Loan",
              text:  "I just stopped by to pay you back some of what I owe.  Here is $500. " +
                     "I should have the rest of it next month.", 
              conditionList: ["loaned-charlie-money"], 
              responses: [{rspText: "I knew it was a bad idea to loan money to you.", 
                            moodPoints: -3, rltnPoints: -2, cashAmt: 500},
                         {rspText: "Great, thanks for paying me back when you can.", 
                            moodPoints: -1, rltnPoints: +1, cashAmt: 500}]},  
       {title: "Pay Back Rest of Loan",
              text:  "I wanted to give you the rest of your money.  Here is $500. " +
                     "I really appreciate the loan!!.", 
              conditionList: ["loaned-charlie-money"], 
              responses: [{rspText: "Glad I could help.", moodPoints: 1, rltnPoints: +2, 
                            cashAmt: 500, deleteCondition: "loaned-charlie-money"},
                         {rspText: "Well, better late then never I guess.", 
                            moodPoints: -2, rltnPoints: -2, 
                            cashAmt: 500, deleteCondition: "loaned-charlie-money"}]},
          {title: "Credit Card Change",
              text:  "I just changed my credit card policy.  Going forward I will pay the " +
                     "full balance every month.", 
              conditionList: [], 
              responses: [{rspText: "Sounds dumb, but I hope it works for you.", 
                            moodPoints: 1, rltnPoints: -1, 
                            cashAmt: 0, deleteCondition: ""},
                         {rspText: "That sounds like a good idea!", moodPoints: +2, rltnPoints: +1, 
                         cashAmt: 0, deleteCondition: ""}]},
          {title: "Let's Celebrate",
              text:  "I am so excited. I got promoted!!  They said I'm doing a great job " + 
                     "and now I'll be an apprentice chef.  Do you want to go to celebrate?", 
              conditionList: [], 
              responses: [{rspText: "Congratulations! Let's celebrate.", moodPoints: 2, rltnPoints: 2, 
                     cashAmt: -35.90, deleteCondition: ""}, 
                         {rspText: "No, I have other things I need to do.", 
                            moodPoints: -2, rltnPoints: -2, 
                            cashAmt: 0, deleteCondition: ""}]},
          
       // 30 dialog items
       {title: "Financial Plan",
              text:  "I decided to talk with a financial advisor. I learned a lot!  She said I should " +
                     "develop a long range financial plan. It will help me to know how much I need " +
                     "to save and when I can retire.",
              conditionList: [], 
              responses: [{rspText: "That seems like a great idea.", moodPoints: 1, rltnPoints: 1, 
                     cashAmt: 0, deleteCondition: ""},
                         {rspText: "Waste of time, you're too young for that.", moodPoints: +2, 
                         rltnPoints: -2, cashAmt: 0, deleteCondition: ""}]},
       {title: "Dinner Invite",
              text:  "I'm planning on hosting a dinner and will fix a gourmet 5 course " +
                     "meal.  I am inviting 4 to 5 of my good friends.  Do you wanna come?",
              conditionList: [], 
              responses: [{rspText: "No, not my thing", moodPoints: -2, rltnPoints: -3}, 
                         {rspText: "Absolutely, count me in!", moodPoints: +2, 
                         rltnPoints: +2, cashAmt: 0, deleteCondition: ""}]},
       {title: "Savings Goal",
              text:  "I'm so excited, I finished my long range money plan.  It seems that if I " +
                     "can just increase my savings by $100 per month, I will be able to retire " +
                     "when I'm 60... isn't that cool?",
              conditionList: [], cashAmt: 0, deleteCondition: "",
              responses: [{rspText: "Wow! That is cool.", moodPoints: +2, rltnPoints: +1, 
                     cashAmt: 0, deleteCondition: ""},
                         {rspText: "I wouldn't count on that happening.", moodPoints: -1, 
                         rltnPoints: -2, cashAmt: 0, deleteCondition: ""}]},
       
       //33 dialog items

       // goal is 40 dialog items
               
      ]
  
 };
 
 export default friend2GameDialogDataset