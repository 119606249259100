const ChangeHomeText =[
       {changeHomeType: "apartment-apartment",   
       text: "Your current apartment lease will be closed out and a new lease will be created. " +
             "Below is information about the change to the monthly rent amount and one-time " +
             "costs."
        },
        {changeHomeType: "apartment-house", 
        text: "Your current apartment lease will be closed out.  A new home loan (mortgage) " +
             "will be established.  Below is information regarding the home loan and the one-time " +
             "costs."
        },
        {changeHomeType: "house-house", 
         text: "Your current mortgage (home loan) will be closed out and rolled into " +
             "a new home loan.   Below is information regarding the home loan and the one-time " +
             "costs."
        },
        {changeHomeType: "house-apartment", 
         text: "Your current home loan (mortgage) will be paid off with the proceeds from " +
             "the sale of the home. " +
             "You will sign a new lease and begin paying rent (monthly). " +
             "Below is information regarding the monthly rent and the one-time " +
             "costs."
        },
        {changeHomeType: "roommate-change", 
        text: "Below are the changes to the monthly amounts that will result from the " +
            "change that you have requested.  " 
       },
]

export default ChangeHomeText;