import { calcInflationAmt } from "../CommonUse/calc_inflation_amt";
import { doubleToStringMonthly } from "../CommonUse/double_functions";
import { calcNbrMonthsBtwnDates } from "../CommonUse/nbr_months_btwn_dates_calc";

export const createSalaryAsset = (assetTable, monthlySalary, newCareerName, salaryStartDate, gameStartDate) => {
      for (let i=0; i<assetTable.length; i++){
            if (assetTable[i].assetItem == "Job Salary" && 
                assetTable[i].assetNbrMonths != "0") {
                  assetTable[i].assetNbrMonths = 0;
            }
      }
      let nbrYears = (calcNbrMonthsBtwnDates(gameStartDate, salaryStartDate)/12).toFixed(0);
      // remove current salary asset and add asset for new salary
      let inflMonthlySalary = calcInflationAmt(monthlySalary, nbrYears);
      let annualSalary = (+inflMonthlySalary * 12).toFixed(0)        
      let jobDescription = newCareerName + " --> Annual Salary: " +
             doubleToStringMonthly(annualSalary);
        
      let assetObj = {assetItem: "Job Salary", assetDesc: jobDescription,
            assetAmt: inflMonthlySalary, assetStartDate: salaryStartDate, assetNbrMonths: "-1"};
      assetTable.push(assetObj);
             
   return assetTable;
   
}
