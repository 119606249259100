
export const initializeErrorArray = (nbrEntries)=>{ 
    let errArray = [];
    for (let i=0; i<nbrEntries; i++){
        let errorObj =  {textType: "", message:""};
        errArray.push(errorObj);
    }
    return errArray;
}
export default initializeErrorArray;

