const VolunteerInformationText =[
    {activityName: "CoatDrive", rules: "Thank you for volunteering on the coat drive.  " +
    "It can get very cold during the winter months so this is very important to many " +
    "homeless people and those who cannot afford to buy warm clothes.  The coats have  " +
    "arrived in a bin.  Please sort them out and put them back in order. " +
    "You will earn bonus points if you can complete all of the sorting activities in the " +
    "time that is allotted.  You will have ||nbrTimes|| attempts to complete each sorting bin. " +
    "Thanks again for helping out!", rulesBrief: ""},
    {activityName: "BuildHome", rules: "Thank you for volunteering to help build homes for those " +
    "who cannot afford them.  To do this activity, you will need to help determine what tools and " +
    "building materials are required.  Everytime you complete a puzzle, progress is made on building " +
    "a home.  You will have ||nbrTimes|| minute to get as many words/phrases complete as possible.  " +
    "Thanks again for helping out!", rulesBrief: "Click the start button.  Then click on letters to " +
    "determine the word.  Read the hint for some extra help."},
    {activityName: "SoupKitchen", rules: "Thank you for volunteering to help in the soup kitchen. " +
    "To do this activity, you will need to help determine what ingredients and kitchen utensils " +
    "are required.  You will have ||nbrTimes|| minute to get as many words/phrases complete as possible.  " +
    "Thanks again for helping out!", rulesBrief: "Click the start button.  Then click on letters to " +
    "determine the word.  Read the hint for some extra help."},
    {activityName: "FoodDrive", rules: "You are working to sort the food that has been delivered  " +
    "to the foodbank.  The food has been delivered in large bins and needs to be sorted into baskets " +
    "based on the type of food.  You will have ||nbrTimes|| bins to sort " +
    "Thanks again for helping out!"},
    {activityName: "DogWalker", rules: "Thank you for volunteering.  You have decided to walk dogs for a " +
    "nearby animal shelter.  Your goal is to walk the dog to the home.  You should try to avoid the fire " +
    "hydrants that you encounter along the way. You will have ||nbrTimes|| attempts to complete the " +
    "walk. Thanks again for helping out!", rulesBrief: "Use the arrow keys to walk the dog on the path to " +
    "the house, avoid the fire hydrants."},
  
]
        
export default VolunteerInformationText 
 