import { editText } from '../editEventCollectionProcesses/editText';
import { editConditionClass } from './editConditionClass';
import { editConditionFieldName } from './editConditionFieldName';
import { editConditionRelationType} from './editConditionRelationType';
import { editConditionTriggeredBy} from './editConditionTriggeredBy';

export const editEventCondition = (conditionsData, eventSelectConditions, eventTriggerConditions) => {

    var editErrorArray=[];
    var errorObj="";

  
    //edit all values on the condition header data
   errorObj = editText(conditionsData.condName, "Header", "Condition Name", 50);
    if (errorObj.length !== 0){
      editErrorArray.push(errorObj);
    }
    errorObj = editConditionClass(conditionsData.conditionClass, "batch", "Header");
    if (errorObj.length !== 0){
      editErrorArray.push(errorObj);
    }
    if (conditionsData.triggeredBy !== "event"){
        errorObj = editConditionFieldName(conditionsData.primaryFieldName, "batch", "Header");
        if (errorObj.length !== 0){
          editErrorArray.push(errorObj);
        }
        errorObj = editConditionRelationType(conditionsData.relationType, "batch", "Header");
        if (errorObj.length !== 0){
          editErrorArray.push(errorObj);
        }
        if (conditionsData.relationType !== "EQ" && conditionsData.compareValues.length > 1){
          errorObj = {fieldClass:"Header", fieldName: "Relation Type", 
            fieldValue: conditionsData.relationType,
            message: "Relation Type is not EQ but there are multiple entries in Compare Value Array"};
          editErrorArray.push (errorObj);
        }
      }else{
        if (conditionsData.primaryFieldName !== undefined && conditionsData.primaryFieldName !== ""){
               errorObj = {fieldClass: "Header", fieldName: "Primary Field Name", 
                  fieldValue: conditionsData.primaryFieldName, message: "Value should be blank"};
               editErrorArray.push(errorObj);
        } 
        if (conditionsData.relationType !== undefined && conditionsData.relationType !== ""){
          errorObj = {fieldClass: "Header", fieldName: "Relationship Type", 
             fieldValue: conditionsData.relationType, message: "Value should be blank"};
          editErrorArray.push(errorObj);
        } 
        if (conditionsData.compareValues.length !== 0){
          errorObj = {fieldClass: "Header", fieldName: "Compare Values", 
             fieldValue: conditionsData.compareValues[0], message: "Value should be blank"};
          editErrorArray.push(errorObj);
        } 
    }
    errorObj = editConditionTriggeredBy(conditionsData.triggeredBy, "batch", "Header");
    if (errorObj.length !== 0){
        editErrorArray.push(errorObj);
    }
  
    //  check to see if this condition is used in either a skip or a select condition
    if (eventSelectConditions.includes(conditionsData.condName)){
      //  all is OK
    }else{
        errorObj = {fieldClass: "Complex Edit", fildName: "Condition Name", fieldValue: conditionsData.condName,
          message: "Condition is not used in any event as select or skip."};
    }
    
    //  check to see if this condition is triggered by event then is it in an event trigger field?
    if (conditionsData.triggeredBy === "event" && 
        eventTriggerConditions.includes(conditionsData.condName)){
      //  all is OK
    }else{
        errorObj = {fieldClass: "Complex Edit", fildName: "Condition Name", fieldValue: conditionsData.condName,
          message: "Condition is triggered by event but not in any event triggers."};
    }

    return editErrorArray;
   
}





