const DiscussionCreditCardData =[
   {  // student59
    "_id": {
      "$oid": "640118c7b4f4b553e719613f"
    },
    "gameID": "64011299baeeb5722ca9db76",
    "__v": 0,
    "cardLevel": "1",
    "creditCardStartDate": "2025-04",
    "creditMaxAmount": "2500",
    "currentlyActive": true,
    "dateCreated": "2023/03/2",
    "dateUpdated": "2023/03/2",
    "lateFeeCount": "",
    "monthlyCreditCharges": [
      {
        "date": "2025-04",
        "monthlyOutstandingAmt": 0,
        "monthlyAmtPaid": 0,
        "monthlyIntAmt": 0,
        "monthlyLateFee": 0,
        "creditChargesArr": []
      }
    ],
    "paymentPolicyCode": "autoPayMin",
    "totalIntBilled": "",
    "totalOutstandingBalance": ""
  },    
   
   ]

export default DiscussionCreditCardData