import {Component} from 'react';


class MoneyMgmtQuestionCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }   
}
  
render() {
       this.pctCorrect = ((+this.props.questionInfo.nbrCorrect / +this.props.totalNbrTests)* 100).toFixed(0);           
return <>
      <tr>
            <td className="text-center">
                 {this.props.questionInfo.questionNbr}
            </td>
            <td className="text-left" >
                 {this.props.questionInfo.questionText}
            </td>
            <td className="text-center" >
                 {this.props.questionInfo.nbrCorrect}
            </td>     
            <td className="text-center" >
                 {this.pctCorrect}%
            </td>              
      </tr>         
           
   </>
  }
}

export default MoneyMgmtQuestionCard;