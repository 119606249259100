const friendVacationActivityDialogDataset = [
        {activityPreference: "1", commentTable: [
                "Thanks for asking, but I have to say no.  I really don't " +
                "have enough money for this. ",
        ]},

        {activityPreference: "2", commentTable: [
                "Well, I guess I could come along.  I'm a little worried about the cost " +
                "but perhaps the break from work would be a good idea!",
        ]},
                    
        {activityPreference: "3", commentTable: [
                "That sounds like it might be fun!  I like going to different places " +
                "to see the sites.",
        ]},

        {activityPreference: "4", commentTable: [
                "Absolutely! I would love to go on vacation with you. " +
                "I may have to reschedule some things but it will be well worth it.  " +
                "Let's go!!!" ,
        ]},
       
                
       
        
]
 
export default friendVacationActivityDialogDataset;