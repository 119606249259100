import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {savingsOverTimeAmt} from '../../objects/CommonUse/savingsOverTimeCalc_function';
import SixColumnDisplayCard from '../shared-components/six_column_display_card';
import SavingsCalculatorText from './text/savings_calculator_text';
import NumberedListCard from '../game-views/numbered_list_card';
import SavingsExerciseDataset from './Datasets/savings_exercise_dataset';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';

class SavingsOverTimeModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         callRender: false,
         expandIntroText: false,
    };    
    this.savingsParmArr = [];
    this.introText = SavingsCalculatorText.introText;
    this.textArr = SavingsCalculatorText.numberedListArr;
    this.initialAmt = 0;
    this.exerciseText = "";
    this.message = "";
    this.entryTab = [];
    this.resetExerciseButtons();
  }

  
  calculateSavingsOverTime(entryName){
    this.errorFound = false;
    if (entryName === "Entered"){
          this.exerciseText = "";
          this.conclusionText = "";
          const savingsAmount = +this.savingsAmount.value;
          const nbrYears = +this.nbrYears.value;
          const intRate = +this.intRate.value;
          this.getInitialAmt();
          if (this.errorFound === false){
              this.calcValuesEntered(savingsAmount, intRate, nbrYears);
          }
    }else{
          this.exerciseText = "";
          this.initialAmt = 0;
          if (this.props.displayInitialAmt === true){
               document.getElementById("initialAmt").value = "";
          }
          this.calcExerciseValues(entryName);
    }
  }

  getInitialAmt(){
      this.initialAmtError = "";
      if (this.props.displayInitialAmt !== undefined && this.props.displayInitialAmt === true){
        this.initialAmt = document.getElementById("initialAmt").value;
        if (isNaN(+this.initialAmt)){
          this.errorFound = true;
          this.initialAmtError = "Initial Amount must be numeric. Correct entry and then " +
            "click the 'Calculate' button again."
        }
    }else{
          this.initialAmt = 0;
    }
  }

  calcExerciseValues(exerciseName){
      switch (exerciseName){
          case "SavingsAmount":
            this.savingsAmountDisabled = true;
            break;
          case "InterestRate":
             this.interestRateButtonDisabled = true;
             break;
          case "nbrYears":
             this.nbrYearsButtonDisabled = true;
             break;
          default:
             break;
      }
      this.processExerciseValues(exerciseName);
  }

  processExerciseValues(exerciseName){
      this.savingsParmArr = [];
      this.resetExerciseButtons();
      const exerciseObj = 
        SavingsExerciseDataset.find(elem => elem.exerciseName === exerciseName);
      this.exerciseText = exerciseObj.text;
      this.conclusionText = exerciseObj.conclusion;
      const savingsParmArr = exerciseObj.parametersArr;
      for (let i=0; i<savingsParmArr.length; i++){
           const savingsAmount = savingsParmArr[i].savingsAmount;
           const intRate = savingsParmArr[i].intRate;
           const nbrYears = savingsParmArr[i].nbrYears;
           this.calcValuesEntered(savingsAmount, intRate, nbrYears);
      }
      this.setState({callRender: true});
  }
  
  calcValuesEntered(savingsAmount, intRate, nbrYears){   
      var totalSavingsWithInterest = savingsOverTimeAmt(savingsAmount, intRate, 
          nbrYears, this.initialAmt) 
      let intRateLit = (+intRate * 100).toFixed(0);
      intRateLit = intRateLit + "%"
      var nbrYearsLit = (nbrYears + " years")
      var annualSavings = (savingsAmount * 12).toFixed(2);
      var totalSavingsAmount = annualSavings * nbrYears;
      var savingsInfoString={monthlySavingsAmt: savingsAmount, yearlySavingsAmt: +annualSavings,
          intRate: intRateLit, years: nbrYearsLit, totalSavingsAmt: totalSavingsAmount,
          totalSavingsWithInt: totalSavingsWithInterest}       
      this.entryTab.push(savingsInfoString);
      if (this.entryTab.length > 5){
          this.entryTab.splice(0,1);
          this.message = 
                "Max of 5 calculations, the first entry has rolled off.  Click on the 'Clear/Reset' " +
                  " button if you wish to clear all entries and start again."
      }
  }

  onClear(){
      this.resetExerciseButtons();
      var i;
      for (i=0; i<=this.entryTab.length; i++){
          delete this.entryTab[i];
      }
      this.entryTab = [];
      this.exerciseText = "";
  }
    
  resetExerciseButtons(){
    this.savingsAmountButtonDisabled = false;
    this.interestRateButtonDisabled = false;
    this.nbrYearsButtonDisabled = false;
    this.multipleFactorsButtonDisabled = false;
}

  expandIntroText(){
      this.setState({expandIntroText: true});
  }

  collapseIntroText(){
      this.setState({expandIntroText: false});
  }
 
  render() { 
    this.gutters = this.props.gutters;
    if (this.props.screenSize === "narrow" || this.props.screenSize === "medium"){
       this.gutters = "modal-95";
    }
    this.summaryRecordCounts = this.entryTab.map((counts,index) =>
      <SixColumnDisplayCard key={index} tableInfo={counts} index={index} tableType={"savings"}
          />
    );  
    if (this.state.expandIntroText === true){
        this.numberedList = this.textArr.map((textLine, key) =>
            <NumberedListCard key={key} text={textLine} />
      );
    }

  return <>
    <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="lg" scrollable="true"
         dialogClassName={this.gutters} animation={false}>
      <Modal.Header className="backgroundTopBottom" closeButton>
        <Modal.Title>Savings Over Time Calculator</Modal.Title> 
      </Modal.Header>
      <Modal.Body>
        
        {this.props.calculatorSw === false  ?
        <span>
          <div className="row-100 ml-2 mr-2 mt-1 mb-4">
               There are three factors that affect the amount that you save over time.  The factors are:  The
               amount that you save each month, the extra amount that you will get paid (interest rate) as
               an incentive, and how long you save the money.  As a general rule, the longer that you
               can save money, the more the money will earn in interest.  
               
               {this.state.expandIntroText ?
                    <div className="mt-2">
                        {this.introText}  
                        {this.numberedList}
                 
                        <span className="text-right">
                            <Button variant="outline-info" size="sm"
                                onClick={() => this.collapseIntroText()}>
                                    Display Less
                            </Button>
                        </span>
                    </div>
              :   
                    <span className="ml-4">
                        <Button variant="outline-info" size="sm"
                            onClick={() => this.expandIntroText()}>
                                Display More
                        </Button>
                    </span> 
              }
                
          </div>  
        </span>
        :
            null
         }
        {this.props.displayInitialAmt ?
                <div >
                  <label className="font-weight-bold" htmlFor="initialAmt">
                    Initial Deposit Amount:&nbsp;&nbsp;</label>
                  <input type="text" id="initialAmt" name="initialAmt" >
                    </input>
                    {this.initialAmtError === "" && this.initialAmt > 0 ?
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Initial Amount Is:</b>&nbsp;&nbsp;{doubleToStringMonthly(this.initialAmt)}</span>
                    :
                        null
                    }
                    <span className="text-danger">
                        &nbsp;{this.initialAmtError}</span>
                  
                </div>
                :
                null
        }

        <span className="row w-100 mt-1 ml-0 mr-0">
            <div className="p-0">
            
              <div><b>Monthly Savings Amt:&nbsp;</b> 
              <select id = "dropdown" ref = {(input)=> this.savingsAmount = input}>
                  <option value="50">$50</option>
                  <option value="100">$100</option>
                  <option value="200">$200</option>
                  <option value="250">$250</option>
                  <option value="300">$300</option>
                  <option value="400">$400</option>
                  <option value="500">$500</option>
                  <option value="750">$750</option>
                  <option value="800">$800</option>
                  <option value="1000">$1000</option>
                  <option value="1500">$1500</option>
                  <option value="2000">$2000</option>   
                  <option value="5000">$5000</option>
                  <option value="10000">$10000</option>
                  <option value="20000">$20000</option>               
              </select> 
              </div>
            </div>
              
            <div className="ml-5 p-0">  
              <p><b>&nbsp;&nbsp;Interest Rate:&nbsp;</b>
              <select id = "dropdown" ref = {(input)=> this.intRate = input}>
                  <option value=".01">1%</option>
                  <option value=".02">2%</option>
                  <option value=".03">3%</option>
                  <option value=".04">4%</option>
                  <option value=".05">5%</option>
                  <option value=".06">6%</option>
                  <option value=".07">7%</option>
                  <option value=".08">8%</option>
                  <option value=".10">10%</option>
                  <option value=".15">15%</option>
                  <option value=".20">20%</option>
                  <option value=".50">50%</option>
              </select></p>
            </div>

            <div className="ml-5 p-0">
              <p><b>Nbr Years:&nbsp;</b>
              <select id = "dropdown" ref = {(input)=> this.nbrYears = input}>
                  <option value="1">1 Year</option>
                  <option value="2">2 Years</option>
                  <option value="5">5 Years</option>
                  <option value="10">10 Years</option>
                  <option value="15">15 Years</option>
                  <option value="20">20 Years</option>
                  <option value="30">30 Years</option>
                  <option value="40">40 years</option>
              </select></p>
            </div>  
          </span> 
          <table className="table table-hover">
            <thead>
              <tr className="thead-light">
                <th></th>
                <th className="p-0 text-center" scope="col">Monthly Savings Amount</th>
                <th className="p-0 text-center" scope="col">Yearly Savings Amount</th>
                <th className="p-0 text-center" scope="col">Interest Rate</th>
                <th className="p-0 text-center" scope="col">Number of Years</th>
                <th className="p-0 text-center" scope="col">Total Savings Amount</th>
                <th className="p-0 text-center" scope="col">Total Savings With Interest</th>
              </tr>
            </thead>
          <tbody>
            {this.summaryRecordCounts}
          </tbody>
        </table>
        <div className="mt-2">
              <b>Learn more about the savings factors:</b>  Enter your own values (above) and click the Calculate button
               ... OR ... click on one of the buttons below.   Click the Close button when you are done.
              <div>
                <Button className="mt-2"
                    disabled={this.savingsAmountButtonDisabled} variant="info"  
                    onClick={() => this.calculateSavingsOverTime("SavingsAmount")}>
                        Savings Amount
                </Button>
                <Button className="mt-2 ml-2"
                    disabled={this.interestRateButtonDisabled} variant="info"  
                    onClick={() => this.calculateSavingsOverTime("InterestRate")}>
                        Interest Rate
                </Button>
                <Button className="mt-2 ml-2"
                    disabled={this.nbrYearsButtonDisabled} variant="info"  
                    onClick={() => this.calculateSavingsOverTime("NbrYears")}>
                        Number of Years
                </Button>
              </div>
          </div>
          <div className="text-left">
              {this.exerciseText}
         </div>
         {this.exerciseText !== "" ?
            <div><b>Conclusion:</b>&nbsp;{this.conclusionText}</div>
          :
            null
         }

        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom">
          <p className="text-right">{this.message}</p>
          <div>
            <Button className="ml-2" variant="primary"  onClick={() => this.props.onClose()}>
                Close
            </Button>
            <Button className="ml-2" variant="primary"   onClick={() => this.onClear()}>
                Clear/Reset
            </Button>
            <Button className="ml-2" variant="primary"  onClick={() => this.calculateSavingsOverTime("Entered")}>
                Calculate
          </Button>
          </div>
        </Modal.Footer>
      </Modal>

      
    </>
  }

 
}
export default SavingsOverTimeModal;