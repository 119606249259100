import {Component} from 'react';
import EventsHeaderModal from './events_header_modal';
import EventsResponseModal from './events_response_modal';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import EventInputDataset from './datasets/event_input_dataset';
import EventInputTemplateDataset from './datasets/event_input_template';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class EventsUpdate extends Component {
    constructor(props) {
        super(props);
        this.adminInfo = this.props.adminInfo();
        this.state={
          openEventsMainModal: false,
          openEventsResponseModal: false,
          allowEventsUpdate: true,
          openEventsUpdateModal: false,
          responseArr: [],
          currentDateTime: getCurrentDateTime(),
          headers: setHeadersForFetch(),
          subProcess: "EventsUpdate",
          environment: Config.dataURL.currentEnv,
         };   
         this.responseArr = [];
         this.eventsObj = "";
    }

    sortEventTitles(){
        var i;
        this.eventTitles=[];
        for (i=0; i<this.adminInfo.eventsData.length; i++){
            this.eventTitles.push(this.adminInfo.eventsData[i].title);
        }
        this.eventTitles = this.eventTitles.sort();
    }
        
    sortConditionsList() {
        var i;
        var condName="";
        var condNameArr=[];
        var careerCondNameArr=[];
        for (i=0; i<this.adminInfo.conditionsData.length; i++){
            // convert condName from index to string
            condName = JSON.parse(JSON.stringify(this.adminInfo.conditionsData[i].condName));
            if (this.adminInfo.conditionsData[i].conditionClass === "career"){
                careerCondNameArr.push(condName);
            }else{
                condNameArr.push(condName);
            }
        }
        this.sortedConditionsData = condNameArr.sort();
        this.sortedCareerTags = careerCondNameArr.sort();
    }
   
    openEventsMainModal(){
        this.setState({openEventsMainModal: true});
    }

    closeEventsMainModal() {
        this.setState({openEventsMainModal: false});
    }
    
    openEventsResponseModal(){
        this.setState({openEventsResponseModal: true});
    }

    closeEventsResponseModal() {
        this.setState({openEventsResponseModal: false});
    }

    
    submitEventsForm(dataObj){
        this.setState({openEventsMainModal: false});
        this.setState({openEventsResponseModal: false});
        if (dataObj.dataType === "header"){
              this.eventsObj = dataObj.eventsObj;
        }else{
              this.responseArr = dataObj.responses; 
        }
       
        if (this.eventsObj.title !== undefined && this.eventsObj.title !== ""){
            if (this.responseArr.length > 0 ){
                this.eventsObj.responses = this.responseArr;
                this.saveEventsCollection();
                this.eventsObj = {};
                this.responseArr = [];
                this.message="Updates completed successfully!"
            }else{
                this.message = "Responses have not been entered.  Click on the Events Collection Responses button."
            }
        }else{
            this.message = "Header has not been entered.  Click on the Events Collection Header button."
        }
    }

    processEventDataset(){
         this.eventTemplate = EventInputTemplateDataset;
         let eventInputDataset = EventInputDataset;
         
         if (eventInputDataset.alreadyProcessed === true){
             alert('Event Dataset has already been processed!');
         }else{
            this.newEventList = eventInputDataset.newEventList;
            for (let i=0; i<this.newEventList.length; i++){
                this.newEventList = eventInputDataset.newEventList;
                this.buildHeader(this.newEventList[i]);
                this.responseList = this.newEventList[i].responses;
                let responseArr = [];
                for (let j=0; j<this.responseList.length; j++){
                    this.buildResponse(this.responseList[j]);
                    responseArr.push(this.response);
                }
                this.eventsObj = {...this.eventData, "responses": responseArr};
                this.saveEventsCollection();
            }
         }
    }

    buildHeader(header){
        this.eventData = {"title": header.title, 
            "description": header.description,
            "frequency": this.eventTemplate.frequency,
            "conditionConnector": this.eventTemplate.conditionConnector, 
            "conditionArr": this.eventTemplate.conditionArr,
            "skipConditionArr": this.eventTemplate.skipConditionArr,
            "backgroundType": this.eventTemplate.backgroundType,
            "imageDir": this.eventTemplate.imageDir,
            "careerTag": this.eventTemplate.careerTag,
            "selectMonthArr": this.eventTemplate.selectMonthArr,
            "gamePhase": this.eventTemplate.gamePhase,
            "eventCategory": this.eventTemplate.eventCategory,
            "dateAdded": this.state.currentDateTime.date,
            "dateUpdated": this.state.currentDateTime.date,
        }
    }

    buildResponse(responseItem){
        this.response = {"btnText": responseItem.btnText,
            "rltnshpScore": this.eventTemplate.rltnshpScore,
            "itemDesc": this.eventTemplate.itemDesc,
            "possibleEffects": [{
                "closureText": responseItem.closureText,
                "category": this.eventTemplate.category,
                "on": this.eventTemplate.on,
                "type": this.eventTemplate.type,
                "duration": this.eventTemplate.duration,
                "odds": this.eventTemplate.odds,
                "replaceAmtSw": this.eventTemplate.replaceAmtSw,
                "triggerCondition": this.eventTemplate.triggerCondition,
                "deleteCondition": this.eventTemplate.deleteCondition,
                "deleteClass": this.eventTemplate.deleteClass,
                "possibleValues": this.eventTemplate.possibleValues,
                "moodPoints": responseItem.moodPoints
            }]
        }
    }
    
    saveEventsCollection() {
        const funcName = "saveEventsCollection";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch(uri,
            {
            method:'POST',
            headers: this.state.headers,
            body: JSON.stringify(this.eventsObj)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode === 200){
                            alert("The new event has been saved!!");
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: this.eventsObj};
                            this.props.processError(errorObj);
                        }
                    });
                };
            })
    }
             
    render() {
        this.sortEventTitles();
        this.sortConditionsList();
    return <>
        {this.state.loadingEventsList  ?
            <span></span>
        :
            <div className="col-sm-6 mb-5 input-page-base-card">
                <div className="card mt-3 mr-5 rounded-corners-lg height-max">
                    <h1 className="mt-2 p-0 text-center">Game Administration</h1>
                    <h4 className="text-center">Add to the Events Collection in MongoDB</h4>
                    <div className="mt-0 mb-0 overflow-scroll">
                        <span className="mt-4">
                        {this.state.openEventsMainModal === false ?
                            <div className="text-center mt-5">
                                <button className="btn btn-primary w-50 mt-6" 
                                    onClick ={()=> this.openEventsMainModal()}>
                                         Events Collection Header
                                </button>
                            </div>
                        :
                            <EventsHeaderModal openModal={this.state.openEventsMainModal} 
                                onClose={() => this.closeEventsMainModal()} 
                                onSubmit={(eventObj) => this.submitEventsForm(eventObj)}
                                eventTitles={this.eventTitles}
                                condNames={this.sortedConditionsData}
                                careerTags={this.sortedCareerTags}
                                />
                        }
                        {this.state.openEventsResponseModal === false ?
                            <div className="mt-4 text-center">
                                <button className="btn btn-primary w-50 mt-6" 
                                    onClick ={()=> this.openEventsResponseModal()}>
                                         Events Collection Responses
                                </button>
                            </div>
                        :
                            <EventsResponseModal openModal={this.state.openEventsResponseModal}
                                onClose={() => this.closeEventsResponseModal()} 
                                onDone={(responseObj) => this.submitEventsForm(responseObj)}
                                eventTitles={this.eventTitles}
                                condNames={this.sortedConditionsData}
                                />
                        }
 
                        {this.state.allowEventsUpdate ?
                            <div className="mt-4 text-center">
                                <button className="btn btn-primary w-50 mt-6" 
                                    onClick ={()=> this.processEventDataset()}>
                                        Process Event Dataset
                                </button>
                            </div>
                        :
                            null
                        }

                        </span>

                        <div className="text-danger text-font-bold text-center mt-5 ml-5 mr-5">
                            {this.message}</div>
                       
                                   
                    </div>
                   
                </div>        
            </div>
        }
        </>
    }

}

export default EventsUpdate;