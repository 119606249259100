const SavingMoneyChallenge2Dataset = {
    /*  spaces between content in items ensures that content is lined up correctly on the modal
        the column property is used to indicate the correct answer.   */
    items: {
        "item-1": { id: "item-1", content: "If at all possible, begin saving when you are young.", 
                answer: "column-2" },
        "item-2": { id: "item-2", content: "High-risk investments that can earn 50% or more " + 
                "are always a good idea.  ",
                answer: "column-3" },
        "item-3": { id: "item-3", content: "Avoid withdrawing cash from a 401K savings " + 
                "account due to interest penalties and income tax requirements",
                answer: "column-2" },
        "item-4": { id: "item-4", content: "Risky investments are OK if you can afford to lose the money." , 
                answer: "column-2" },
        "item-5": { id: "item-5", content: "If you get an unexpected cash amount, invest the money " + 
                "before you pay off outstanding credit card debt.", 
                answer: "column-3" },   
        "item-6": { id: "item-6", content: "You should always have an ‘emergency fund’ of " + 
                "savings that are not invested (not in a 401K). This can be used for unexpected " + 
                "expenses.",
                answer: "column-2" }, 
        "item-7": { id: "item-7", content: "There is no need to save for retirement because social " + 
                "security will cover everything ", 
                answer: "column-3" },   
    },

    columns: {
        "column-1": { id: "column-1", title: "Saving Advice", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7"] },
        "column-2": { id: "column-2", title: "Advice that is Good", itemIds: [] },
        "column-3": { id: "column-3", title: "Advice that is Not Good", itemIds: []},
       
    },

    columnOrder: ["column-1", "column-2", "column-3"]
}

export default SavingMoneyChallenge2Dataset