const termDictionary =[
    {termName: "income", altName: "",
        termDefinition: "Income is money that you receive from different sources. " +
            "Income can be earned or unearned.  Earned income comes from work that have done.  Unearned " +
            "income comes from sources such as interest from a savings account or money gained from investments " +
            "such as stocks or bonds." },
    {termName: "expense", altName: "expenses",
        termDefinition: "An expense is money that you have to pay for things that you have " +
            "purchased or from services that you receive. Expenses can be essential or discretionary.  Essential " +
            "expenses are things that you must pay such as car payments, rent, or insurance payments. "  +
            "Discretionary expenses are for things that you want " +
            "such as a new pair of shoes, a mobile phone, or eating out at a restaurant." }, 
    {termName: "money management", altName: "",
        termDefinition: "Money management refers to the processes of creating a budget, identifying savings and " +
            "investment goals, and overall directing the money of an individual or group."  }, 
    {termName: "investment", altName: "investments",
        termDefinition:  "An investment is something where money is spent on an object with the hope that, over time, " +
            "the value of the object will increase.  Typical investments include stocks, bonds and property " +
            "such as a home."},
    {termName: "minimum payment amount", altName: "minimum payment",
        termDefinition: "The minimum payment is the smallest amount of the credit card balance that " +
            "an individual can pay in a month.  This is calculated by the institution that provides the " +
            "credit card.  The minimum payment is usually 1% to 2% of the credit card balance plus any  " +
            "interest that is owed."},
    {termName: "interest rate", altName: "",
        termDefinition: "The percent that is charged each month for money that has been borrowed. " +
            "You can think about it as paying 'rent' for money that you have borrowed."},
    {termName: "loan term", altName: "",
        termDefinition: "This is the length of time that you will be allowed to borrow the money."},
    {termName: "monthly budget", altName: "",
        termDefinition: "This is a worksheet that is put together that identifies dollars that you expect to " +
            "earn (income) and dollars that you know you will need to spend (expenses) each month. " +
            "This helps determine how much money you have available for 'wants' and unexpected bills."},
    {termName: "salary", altName: "salaries",
        termDefinition:  "The amount of money that is paid for work that is done.  This can be an hourly amount, " +
            "a monthly amount, or a yearly amount."},
    {termName: "fixed expense", altName: "fixed expenses",
        termDefinition:  "A fixed expense is an amount owed that does not change from month to month. " + 
            "Generally, it is also an amount that must be paid each month (it is not optional)."},
    {termName: "loan payment", altName: "loan payments",
        termDefinition: "The amount that you are required to pay each month for money that you have borrowed " +
            "from a lending institution such as a bank."},
    {termName: "monthly savings amount", altName: "",
        termDefinition:  "The amount of money that an individual deposits in their savings account(s) each month."},
    {termName: "401k savings account", altName: "",
        termDefinition:  "This is a special type of a savings account that is set up by your employer (the place " +
            "where an individual works).  The amount is deducted from your paycheck.  The money in this savings " +
            "account is used to provide funds for retirement."}

]

export default termDictionary;