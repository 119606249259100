const MoneyMgmtChallenge2Dataset = {
   
items: {
    "item-1": { id: "item-1", content: "It helps to understand how much money you have " +
                "to spend or save each month.", 
            answer: "column-2" },
    "item-2": { id: "item-2", content: "Preparing a monthly budget gives you a chance to " +
                "practice your basic math skills.",
            answer: "column-1" },
    "item-3": { id: "item-3", content: "It will keep you fairly busy for several hours " +
                "on a rainy day. ", 
            answer: "column-1" },
    "item-4": { id: "item-4", content: "It helps to know how you are spending your money " +
                "and may give you ideas on ways you can reduce your monthly spending.",
            answer: "column-2" },
    "item-5": { id: "item-5", content: "It will help you to know if you are on track to " +
                "meet your savings goals.",
            answer: "column-2" },
    "item-6": { id: "item-6", content: "It will increase the amount of money that you make " +
                "each month.", 
            answer: "column-1" },   
    "item-7": { id: "item-7", content: "If you want to purchase a high-cost item, having a " +
                "monthly budget will help you to know how long it will take to save enough " +
                "money to purchase the item. ",
            answer: "column-2" },  
},

columns: {
    "column-1": { id: "column-1", title: "Considerations", 
        itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7"] },
    "column-2": { id: "column-2", title: "Advantages of Having a Monthly Plan", itemIds: [] },
},
columnOrder: ["column-1", "column-2",]
}

export default MoneyMgmtChallenge2Dataset