
const EventInputDataset = {
    alreadyProcessed: false,
    newEventList:
// largest bill ever circulated by the federal reserve - a $10,000 note.
// US govmt first released currency in 1792
// Biggest loss of NY stock exchange - 89% 1929 crash of stock market
// $2 bill last printed in 2014
// oldest currency in exhistence is the British pound

// by law living presidents cannot be featured on currency - George Washington - only Kings are on bills
// there is a $100,000 bill that was created but never circulated publically - it is a gold certificate and used
//   to circulate money between fed reserve banks - Woodrow Wilson
// only 8% of the world's currency exists as physical cash - electronic payments, credit cards, etc.
// US is one of only ??? countries that still prints it's money on a paper-like product instead of plastic


    [ 
      // $5 bill all 50 states are listed across top of th Lincoln Memorial (need magnifying glass to read them all)
      { "title": "Fun Fact",
      "description": "What is printed across the top of the Lincoln Memorial on a $5 bill? " ,           
      "responses": [ 
          {
            "btnText": "All 50 States",
            "closureText": "You are right!! You will likely need a magnifying glass to see them but all 50 states are listed. ", 
            "moodPoints": "2",
          },
          {
            "btnText": "13 Original Colonies",
            "closureText": "Good guess but it isn't quite right.  The correct answer is all 50 states." ,
            "moodPoints": "-1",
          },
          {
            "btnText": "An Eagle",
            "closureText": "This might look cool, but it isn't right. The correct answer is all 50 states." ,
            "moodPoints": "+2",
          },
          {
            "btnText": "In God we Trust",
            "closureText": "While this phrase does appear on US currency, it is not printed above the Lincoln " +
              "Memorial on the $5 bill.  The correct answer is all 50 states.",
            "moodPoints": "-1",
          },
          ]
      },
      // 13 appears 8 times on dollar bill, 13 leaves, 13 berries, 13 arrows, 13 steps in pyramid, 13 stars above key, 
     //    bars on shield, 13 horizontal stripes on, 13 stars on the eagle
       {
        "title": "Fun Fact",
        "description": "How many times is the number 13 represented on a $1.00 bill? " ,           
        "responses": [ 
          {
            "btnText": "3 Times",
            "closureText": "Not quite, but close.  The correct answer is 8 times. " , 
            "moodPoints": "-1",
          },
          {
            "btnText": "8 times",
            "closureText": "That's correct.   13 appears 8 times on the $1 dollar bill, 13 leaves, 13 berries, " +
            "13 arrows, 13 steps in the pyramid, 13 stars above key, 13 bars on shield, 13 horizontal " +
            "stripes on the flag, and 13 stars on the eagle " ,
            "moodPoints": "+2",
          },
        
          {
            "btnText": "10 times",
            "closureText": "10 is a good guess but it is not right. The correct answer is 8 times. " ,
            "moodPoints": "-1",
          },
          {
            "btnText": "13 times",
            "closureText": "Logical guess, but not quite right.  The correct answer is 8 times. " ,
            "moodPoints": "-1",
          },
          ]
      },
     // first country to use printed paper money was China, used so they could burn it when ancestors died so could take
     //     it with them
      {
        "title": "Fun Fact",
        "description": "Do you know why the first paper money was printed?",
         "responses": [ 
          {
            "btnText": "Easier to carry larger amounts of money.",
            "closureText": "This is true but it is not why the first money was printed.  The correct answer is " +
              "because it could be burned.", 
            "moodPoints": "-1",
          },
          {
            "btnText": "Cheaper to Create Paper Money",
            "closureText": "Good guess, but this isn't correct.  The correct answer is because it could be burned." ,
            "moodPoints": "-1",
          },
          {
            "btnText": "Because it Can Be Burned",
            "closureText": "Totally right!  Hard to believe, but the first paper money was printed in China " +
              "because they wanted to burn it when ancestors died so the ancestors could take it with them.",
            "moodPoints": "+2",
          },
        
          ]
      },
     //  Benjamin Franklin only non-president on a bill - appears on the $100 bill
      {
        "title": "Fun Fact",
        "description": "Benjamin Franklin is the only person to appear on a US bill who is not " +    
          "an ex-president. Do you know what bill he is on?" ,   
        "responses": [
          {
            "btnText": "$10 Bill",
            "closureText": "Not a bad guess, but it is not correct.  The correct answer is $100 bill.", 
            "moodPoints": "-1",
          },
          {
            "btnText": "$20 Bill",
            "closureText": "This is not correct.  The correct answer is $100 bill. " +
              "of the bill." ,
            "moodPoints": "-1",
          },
          {
            "btnText": "$50 Bill",
            "closureText": "Good guess, but it is not correct.  The correct answer is $100 Bill. " ,
            "moodPoints": "-1",
          },
          {
            "btnText": "$100 Bill",
            "closureText": "Great job!  This is correct.  " ,
            "moodPoints": "+2",
          },
          ]
      },
     // pennies in garden 
      {
        "title": "Fun Fact",
        "description": "Why would a person bury pennies in there garden?" ,          
        "responses": [ 
          {
            "btnText": "Reduce Garden Pests",
            "closureText": "Absolutely right. Pennies buried in a garden will repel slugs, " +
               "which get electric shocks from touching copper and zinc.", 
            "moodPoints": "+2",
          },
          {
            "btnText": "To Bring Good Luck",
            "closureText": "Not quite right.  The correct answer is to reduce garden pests." ,
            "moodPoints": "-1"
          },
          {
            "btnText": "Food for Plants",
            "closureText": "Good thought, but this is not correct.  The correct answer is to reduce garden pests. " ,
            "moodPoints": "-1",
          },
          {
            "btnText": "It's a Way to Grow Money",
            "closureText": "Wouldn't that be nice if it worked? But this is not correct.    The correct answer " + 
              "is to reduce garden pests."   ,
            "moodPoints": "-1",
          },
          ]
      },
    ]
}

export default EventInputDataset;