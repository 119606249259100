import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FeedbackQuestionList from './datasets/feedback_question_list_dataset';
import QuestionsDisplayCard from './questions_display_card';
import CommentsDisplayCard from './comments_display_card';

class FeedbackModal extends Component {
            constructor(props) {
              super(props);
              this.state={
                callRender: false,
                nbrSurveyItems: 11,
                feedbackVersion: FeedbackQuestionList.version,
               
            }  
          this.errArray = [];
          this.questionText = FeedbackQuestionList.questionList;
          this.commentText = FeedbackQuestionList.commentList;
          this.dataValidSw = true;
          this.initializeErrorArray();
  }

  
  initializeErrorArray(){
    if (this.errArray.length > 0){
        this.errArray.splice(0);
    }
    for(let i=0; i<this.questionText.length; i++){
        this.questionText[i] = {...this.questionText[i], errorMsg: "",
          textType: "", questionNbr: (i+1)};
    }
    let startNbr = this.questionText.length;
    for (let i=0; i<this.commentText.length; i++){
        
        let questionNbr = startNbr + i;
        //  set the last comment ("general comment") question number to 99
        if (i ===this.commentText.length - 1){
            questionNbr = 99;
        }
        this.commentText[i] = {...this.commentText[i], errorMsg: "", 
          textType: "", questionNbr: questionNbr};
    }
    this.errArray = [];
    for (let i=0; i<3; i++){
        let errorObj = {errorMsg: "", textType: ""};
        this.errArray.push(errorObj);
    }
  }
    
  editInput(){
    this.dataValidSw = true;
    this.initializeErrorArray();
    this.editQuestions();
    this.editEmailAddress();
    this.editComments();
    this.editGameRole();
    this.editEmailAddress();
    if (this.dataValidSw === true){
        this.saveFeedbackInfo();
        this.props.onSubmit(JSON.stringify(this.returnObj));
    }
  }
  
  editQuestions(){
      var i;
      let numberQuestions = this.questionText.length;
      for (i=0; i<numberQuestions; i++){
          if (this.questionText[i].answer ===""){
             this.questionText[i].textType = "text-danger";
             this.questionText[i].errorMsg = "Please Select Value";
             this.dataValidSw = false;
          }
      }
  }

  editComments(){
    var i;
    // general comment (last comment) is optional
    let numberComments = this.commentText.length;
    for (i=0; i < numberComments; i++){
        let commentId = "comment" + (i+1);
        let commentText = document.getElementById(commentId).value;
        // general comment (last comment) can be blank
        if (commentText.length ===0 && i !== numberComments- 1){
            this.commentText[i].textType =  "text-danger";
            this.commentText[i].errorMsg = "Please enter comment text";
            this.dataValidSw = false;
        }else{
            this.commentText[i].answer = commentText;
        }
    }
  }
  
  editGameRole(){
    var validRole = true;
    this.role = document.getElementById("gameRole").value;
    if (this.role ==="No-Role") {
      validRole = false;
    }
    if (validRole ===false){
      this.errArray[0] = {textType: "text-danger", message: "Please select a role."};
      this.dataValidSw = false;
    }
  }
  
  editEmailAddress() {
    var validEmailAddress = true;
    this.emailAddress = document.getElementById("emailAddress").value;
    if (this.emailAddress.length ===0){
        // email address is optional so no error if length is 0
    }else{
        if (this.emailAddress.indexOf('@') <= 0)
          validEmailAddress = false;
    }
    if (validEmailAddress ===false){
       this.errArray[1] = {textType: "text-danger", message: "Enter a valid Email Address"};
       this.dataValidSw = false;
    }
  }

  saveFeedbackInfo(){
        this.returnObj = {feedbackVersion: this.state.feedbackVersion, feedbackRole: this.role,
          questionList: this.questionText, commentList: this.commentText,
          emailAddress: this.emailAddress};
  }
   
    render() {
       this.feedbackSurveyQuestions = this.questionText.map((questionText, index) =>
          <QuestionsDisplayCard questionText={questionText}  key={index}  
            index={index} onSelected={(index) => this.saveButtonAnswer(index)}
            />
            );

       this.feedbackSurveyComments = this.commentText.map((commentText, index) => 
          <CommentsDisplayCard commentText={commentText}  key={index}  
          index={index}/>
          );
                                       
    return <>
      
      <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
            size="lg" scrollable="true" dialogClassName="modal-80" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton> 
            <Modal.Title>Provide Feedback Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           
            <div className="text-left">
                <p>Please complete all fields on this form and then click 
                   'Submit'.</p>
                {this.feedbackSurveyQuestions}
                         
                {this.feedbackSurveyComments}

                <div className="mt-4">
                  <label className="font-weight-bold" htmlFor="gameRole">Role/Perspective:&nbsp;&nbsp;</label>
                    <select 
                      className="btn btn-sm btn-outline-secondary dropdown-toggle" id="gameRole">
                        <option value="No-Role">no role selected</option>
                        <option value="4th Grade">Elementary School Student - 4th Grade</option>
                        <option value="5th Grade">Elementary School Student - 5th Grade</option>
                        <option value="6th Grade">Elementary School - 6th Grade</option>
                        <option value="middleSchool">Middle or Junior High School Student</option>
                        <option value="highSchool">High School Student</option>
                        <option value="adult">Adult</option>
                     </select>
                  <span className={this.errArray[0].textType}>
                          &nbsp;{this.errArray[0].message}</span>
                </div>
                <div className="mt-4">
                  <label className="font-weight-bold" htmlFor="emailAddress">Email Address (optional):&nbsp;&nbsp;</label>
                  <span className={this.errArray[1].textType}>
                          &nbsp;{this.errArray[1].message}</span>
                  <input type="text" className="col-lg" id="emailAddress" name="emailAddress"></input>   
                </div>
       
            </div>

           </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
          {this.dataValidSw ?
            <div></div>
          :
            <div className="text-danger">Please Correct errors and try-again.</div>
          }
          <div className="text-right mr-3">
              <Button variant="secondary"   onClick={() => this.props.onClose()}>Close</Button>&nbsp;&nbsp;
              <Button variant="primary"  onClick={() => this.editInput()}>Submit</Button>
          </div>
        </Modal.Footer>
        </Modal>
      
        </>
    }

    saveButtonAnswer(returnObj){
      let qstnIndx = returnObj.questionNbr - 1;
      let answer = returnObj.answer;
      this.questionText[qstnIndx].answer = answer;
      
    }
     
}

export default FeedbackModal;