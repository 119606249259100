const LoanChallenge1HintsText =
    ["You can use the loan calculator to determine  " +
        "the monthly payment amount and the amount of overpayment",
     "Generally, loans that have a longer term and higher interest rates, result " +
        "in a greater overpayment amount.",
     "The monthly loan amount will usually be less for loans that have a longer " +
        "loan term and a lower interest rates.", 
     "The loan for $200,000 that has a term of 5 years and interest rate of 8% " +
        "is the highest monthly payment.",
     "The loan for $5,000 that has a term of 2 years and interest rate of 3% " +
        "has the least amount of overpayment."
    ]
   

export default LoanChallenge1HintsText;