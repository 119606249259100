const EventsImageDirectoryDataset = [
        {
                "value": "friends",
                "selection": "friends"
        },
        {
                "value": "friends",
                "selection": "friends"
        },
        {
                "value": "puppies",
                "selection": "puppies"
        },
        {
                "value": "funStuff-Cat",
                "selection": "funStuff cat"
        },
        {
                "value": "funStuff-Dog",
                "selection": "funStuff dog"
        },
        {
                "value": "item-1Thousand",
                "selection": "purchase item $1000 with friends"
        },
        {
                "value": "item-1Thousand-noFriend",
                "selection": "purchase item $1000 w/o friends"
        },
        {
                "value": "item-5Thousand",
                "selection": "purchase item $5000 with friends"
        },
        
        {
                "value": "item-5Thousand-noFriend",
                "selection": "purchase item $5000 w/o friends"
        },
        
        ]
      

export default EventsImageDirectoryDataset;