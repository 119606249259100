import {Component} from 'react';
import "./ForParents/forParents.css";
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';
import MoneyTrain_Video_Discussion_Topics from './ForParents/Video_Presentations_Discussion_Guide.pdf'
import VideoViewerModal from './video_viewer_modal';


class DiscussionTopics extends Component {
    constructor(props) {
        super(props);
        this.state={
           modalOpen: false,
          
        };   
    }

    
    render() {
        this.tableElementClass = "col-med-4 text-center";
        let screenParms = setScreenSizeParameters(this.props.screenSize, "forParents");
        this.className = screenParms.columnClass;
                                
    return <>
           <div className={this.className}>
                <div className="card mt-3 mr-5 rounded-corners-lg height-max">
                    <button className="pages-back-button ml-4 mt-4" 
                        onClick ={()=> this.props.goBack()}>
                                Back
                    </button>
                    <h1 className="mt-0 p-0 text-center">Discussion Presentations</h1>
                    <h4 className="text-center">Video Presentations</h4>
                    <div className="mx-4 mt-2">
                          This is a list of videos that cover important Money Management concepts.
                          These are intended to be viewed by players (students).
                          They
                          reinforce content and supplement the MoneyTrain game.  These videos include 
                          sound so make sure your microphone is not muted.
                    </div>
                    <table className="mt-2 overflow-scroll">
                    <tbody className="row-100" >
                        <tr>
                            <td className={this.tableElementClass}>
                                <button className="btn-group-medium mt-2" 
                                    onClick ={()=> this.topicSelected("introduction")}>
                                           Before You Play 
                                           <div className="small-text">(5 minutes)</div>
                                </button>
                            </td>
                            <td className={this.tableElementClass}>
                                <button className="btn-group-medium mt-2" 
                                    onClick ={()=> this.topicSelected("theBasics")}>
                                           The Basics 
                                           <div className="small-text">(Not Yet Available)</div>
                                </button>
                            </td>
                            <td className={this.tableElementClass}>
                                <button className="btn-group-medium mt-2" 
                                    onClick ={()=> this.topicSelected("needsVsWants")}>
                                           Needs vs. Wants 
                                           <div className="small-text">(7 minutes)</div>
                                </button>
                            </td>
                        </tr>  
                        <tr className="mt-2">
                            <td className={this.tableElementClass}>
                                <button className="btn-group-medium mt-3" 
                                    onClick ={()=> this.topicSelected("jobChoice")}>
                                            Job Choice 
                                            <div className="small-text">(6 minutes)</div>
                                </button>
                            </td>
                            <td className={this.tableElementClass}>
                                <button className="btn-group-medium mt-3" 
                                    onClick ={()=> this.topicSelected("homeChoice")}>
                                           Home Choice
                                           <div className="small-text"> (6 minutes)</div>
                                </button>
                            </td>
                            <td className={this.tableElementClass}>
                                <button className="btn-group-medium mt-3" 
                                    onClick ={()=> this.topicSelected("carChoice")}>
                                           Car Choice
                                           <div className="small-text"> (6 minutes)</div>
                                </button>
                            </td>
                        </tr>
                        
                                           
                    </tbody>

                    </table>
                    {this.props.playerRole === "parent"  || this.props.playerRole === "teacher" ?
                        <div className="mt-4">
                           <div className="mt-4 text-center">
                                <a className="decorative light-blue-color text-pointer" 
                                    href={MoneyTrain_Video_Discussion_Topics} target="_blank" 
                                    rel="noreferrer">
                                        View Video Presentations Discussion Guide (PDF)
                                </a>
                            </div>
                        </div>
                    :
                        null
                    }               
                                                 
                    {this.state.modalOpen ?
                        <div className="video-display-size" >
                            <VideoViewerModal
                                topicName={this.topicName}
                                show={this.state.modalOpen}
                                onClose={() => this.turnVideoOff()} 
                            />
                        </div>
                    :
                        null
                    }

                                      
                </div>        
            </div>
        </>
    }
    
    topicSelected(topicName){
        this.topicName = topicName;
        this.setState({modalOpen: true});
    }

    turnVideoOff(){
        this.powerPointVideo = "";
        this.setState({modalOpen: false});
    }


}

export default DiscussionTopics;