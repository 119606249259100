import {Component} from 'react';

class GameBoxDisplay extends Component {
    constructor(props) {
        super(props);
    this.determineEventCategoryText();
    }

    
determineEventCategoryText(){
    const eventCategoryObj = this.props.eventCategories.find(elem => elem.number === this.props.cardDetails.eventNumber);
    this.eventText = eventCategoryObj.textName;
}


// <img src="./assets/GameEnvelope/CardFlipBack1.png" alt="envelope"></img>
    render() {
        this.color = {"background-color":  this.props.cardDetails.color, color: "white"};
    return <>
 
        <div class="flip-card"  >
        <div class="flip-card-inner">
            <div class="flip-card-front" style={this.color} >
                 <div className="very-large-size-font">   
                 {this.props.cardDetails.eventNumber}
                 </div>
                 <div className="text-center medium-size-font">
                    {this.eventText}
                 </div>
            </div>
            <div class="flip-card-back game-card-blue">
            <div className="d-flex align-items-center h-100">
                <img src="./assets/sidebar_icon.png" className="w-100"/>
            </div>
            </div>
        </div>
        </div>

 
    </>
    }
}

export default GameBoxDisplay;    