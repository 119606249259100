const assetExpLoanShortnameDataset =[
  {shortName: "salary", type: "asset", fullName: "Job Salary", desc: ""},
  {shortName: "BadgeBonus", type: "asset", fullName: "Salary Bonus - Badges", 
    desc: "Badges Earned - "},
  {shortName: "ExtraCreditBonus", type: "asset", fullName: "Salary Bonus - Extra Credit", 
    desc: "Challenges Completed - "},
  {shortName: "roommateRent", type: "asset", fullName: "Roommate Rental Amt", 
    desc: "Monthly amount roommate pays you for rent."},
  {shortName: "roommatesRent", type: "asset", fullName: "Roommate Rental Amt", 
    desc: "Monthly amount roommates pay you for rent."},
  {shortName: "taxes",  type: "expense", fullName: "Payroll taxes",
    desc: "Federal, FICA (Social Security)", chargeCreditCard: false},
  {shortName: "healthIns", type: "expense", fullName: "Health Insurance", desc: "", chargeCreditCard: false},
  {shortName: "carIns", type: "expense", fullName: "Automobile Insurance",
    desc: "Insurance for car repair or replacement", chargeCreditCard: false},
  {shortName: "rentersIns", type: "expense", fullName: "Renter's Insurance",
    desc: "Insurance for furniture and other personal items", chargeCreditCard: false},
  {shortName: "homeIns", type: "expense", fullName: "Home Owners Insurance",
    desc: "Insurance to repair or replace home, furniture, and personal items in the event of damage.",
    chargeCreditCard: false},
  {shortName: "transport",  type: "expense", fullName: "Transportation",
    desc: "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
    chargeCreditCard: true},
  {shortName: "misc", type: "expense", fullName: "Misc Expenses",
    desc: "Food, Clothes, Personal Items, etc.", chargeCreditCard: true},
  {shortName: "rent", type: "expense", fullName: "Apartment Rent", desc: "", chargeCreditCard: false},
  {shortName: "homeHOA", type: "expense", fullName: "HOA Monthly Dues", desc: "Home Owner's Association Dues",
    chargeCreditCard: true},
  {shortName: "saveEarth", type: "expense", fullName: "Charities", desc: "Save the Earth Charity",
    chargeCreditCard: true},
  {shortName: "saveAnimals", type: "expense", fullName: "Charities", desc: "Save the Animals Charity",
    chargeCreditCard: true},
  {shortName: "feedWorld", type: "expense", fullName: "Charities", desc: "Feed the World Charity",
    chargeCreditCard: true},
  {shortName: "car", type: "loan", fullName: "Car Loan", desc: ""},
  {shortName: "college", type: "loan", fullName: "College Loan", desc: ""},
  {shortName: "mortgage", type: "loan", fullName: "Home Loan (Mortgage)", desc: ""},
  {shortName: "mortgagePMI", type: "loan", fullName: "Mortgage Insurance (PMI)"}       
 
];

export default assetExpLoanShortnameDataset;