const HomeDownPaymentText =
         "A down payment is the amount of cash that you plan to pay for the home " + 
        "(a loan will be created for the remaining amount of the home value).  If the " +
        "down payment " +
        "amount is less than 20% of the total value of the home, then the lender is " +
        "likely to add a mortgage insurance fee (aka PMI).  This fee is paid in addition " +
        "to the monthly mortgage and will go away when the amount of principle owed is " +
        "less than 80%. The downpayment must be at least 5% of the home value and less " +
        "than the 'Amount Available for Down Payment'."
    
export default HomeDownPaymentText;