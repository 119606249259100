const LoanChallenge2Dataset = {
        /* the answer property is used to indicate the correct column for this item.  */
    items: {
        "item-1": { id: "item-1", 
                content: "Home Improvement Loan: Individual requesting a $5000 loan which will be " +
                "paid back over 2 years with an interest rate of 5%.  They have a good job and currently" +
                " have a disposable income of $2000 after monthly expenses.", 
                answer: "column-2" }, 
        "item-2": { id: "item-2", 
                content: "Car Loan: Individual requesting a $18,000 loan for a new car to be paid back " +
                "over 5 years with an 8% interest. They have a high paying job. They have three other " +
                "outstanding loans. Their monthly disposable income is $300.", 
                answer: "column-3" },
        "item-3": { id: "item-3", 
                content: "Home Loan: Individual requesting $150,000 loan for a new condo. The term of " +
                "this loan will be 30 years and an interest rate of 6%. They just graduated from college " +
                "and have started their first job.  They have a disposable income of $1000 each month.",
                answer: "column-3" },
        "item-4": { id: "item-4", 
                content: "College Loan: Individual is requesting $10,000 loan for their college tuition. " +
                "The term of this loan will be 10 years with an interest rate of 3%. They do not have a " +
                "job but the loan has been guaranteed by the federal government.",
                answer: "column-2" },
        "item-5": { id: "item-5", 
                content: "General Loan: Individual is requesting $20,000 loan which will be paid back " +
                "over 5 years and an interest rate of %10. The individual has a good job and their monthly " +
                "disposable income is $2000.  The individual has a history of 'forgetting' to pay the " +
                "monthly credit card bill.", 
                answer: "column-3" },
        "item-6": { id: "item-6", 
                content: "PayDay Loan Alternative: Individual has been considering a payday loan. The " +
                "payday loan will charge 25% interest for 1 year. They are hoping your bank can offer a " +
                "better option. They need $2000 to be paid over 1 year and interest rate of 10%. The "+
                "individual has a job and a monthly disposable income of $1000.", 
                answer:  "column-2" },
        "item-7": { id: "item-7", 
                content: "General Loan: Individual requesting a loan to cover their monthly expenses. " +
                "They believe that they will be getting a raise in a few months but right now they " +
                "are barely making enough to cover expenses. They need $5000 to be paid over 5 years " +
                "and interest rate of 10%. The individual declared bankruptcy last year.", 
                answer:  "column-3" },
      },
    columns: {
        "column-1": { id: "column-1", title: "Loan Request Information", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7"] },
        "column-2": { id: "column-2", title: "Loan Approved", itemIds: [] },
        "column-3": { id: "column-3", title: "Loan Denied", itemIds: [] },
                     
    },
    columnOrder: ["column-1", "column-2", "column-3",]
}

export default LoanChallenge2Dataset