const ChoosingCareerChallenge2Dataset = {
   
    items: {
        "item-1": { id: "item-1", content: "You think you will look really good in the uniform.", 
            answer: "column-1", order: 6 },
        "item-2": { id: "item-2", content: "Your parents and friends think this would be a good career for you.", 
            answer: "column-1", order: 5 },
        "item-3": { id: "item-3", content: "Your skills and interests make this career a good fit for you..", 
            answer: "column-1", order: 1 },
        "item-4": { id: "item-4", content: "Your best friend also chose this career.", 
            answer: "column-1", order: 7 },
        "item-5": { id: "item-5", content: "You think you would really enjoy this career.", 
            answer: "column-1", order: 2 },
        "item-6": { id: "item-6", content: "The salaries for this career are really high.", 
            answer: "column-1", order: 4 },
        "item-7": { id: "item-7", content: "There is a lot of demand for individuals in this career.",
            answer: "column-1", order: 3 }
    },

    columns: {
        "column-1": { id: "column-1", title: "Considerations", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7"] },
        
        
    },
    columnOrder: ["column-1"]
}

export default ChoosingCareerChallenge2Dataset