import BadgeCardText from "../text/badge_card_text";

export const buildBadgeCard = (badgeNbr) => {
    let badgeCardObj = "";
    let responseArr = [];
    let badgeText = BadgeCardText;
        
    // build response
    let cashAmt = 0;
    let responseObj =  {btnText: "Earn a Badge", rltnshpScore: +0,
        closureText: "", category: 'cash', on: 'cash', type: 'flat', 
        itemDesc: "",  duration: '1', replaceAmtSw: 'false', triggerCondition: "", deleteCondition: "",
        deleteClass: "", moodPoints: +1, value: cashAmt, linkTo: "earn-badge"};
    responseArr.push(responseObj); 
    responseObj =  {btnText: "Maybe Another Time", rltnshpScore: +0,
        closureText: "", category: 'cash', on: 'cash', type: 'flat', 
        itemDesc: "",  duration: '1', replaceAmtSw: 'false', triggerCondition: "", deleteCondition: "",
        deleteClass: "", moodPoints: +1, value: cashAmt};
    responseArr.push(responseObj); 
    badgeCardObj = {cardType: "badge", imageArr: [], selectMonthArr: [], _id: '',
        responses: responseArr, title: "Do you want to Earn a Badge?", description: badgeText,
        frequency: "83", conditionConnector: "", conditionArr: [],  skipConditionArr: [], 
        backgroundType: 'home', imageDir: "", dateUpdated: '2022/10/29', 
        gamePhase: "all", careerTag: '', eventCategory: 'other'};
           
    return badgeCardObj;
   
}
export default buildBadgeCard;

