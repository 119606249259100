import { send } from 'emailjs-com';

export const SendEmail = (emailParms, templateName, serviceID, publicID) => {
        send(
            serviceID,
            templateName,    // template id
            emailParms,
            publicID        // public key
            )
            .then((response) => {
                console.log("Success!!!... email was sent")
            })
            .catch((err) => {
              console.log('FAILED...', err);
            });
     
      //  initial info for email.js for clathey123@gmail account
      // service ID from Email.js:   service_1oxzcap
      // template id is: template_2zkyp3t

      // template id for playmoneytrain@gmail account ==>   'template_apd5yd8', (forgot password)
      // template id for playmoneytrain@gmail.account ==>   'template_6uj2w2c'  (contact us)


  
  return 
}


export default SendEmail