import {Component} from 'react';
import './Special/special.css'
import EventListResponseCard from './event_list_response_card';

class EventListDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }    
}
  

render() {
   this.lineNbr = this.props.index + 1;
   this.responseInfo = this.props.cardList.responses.map((responseList, index) =>
        <EventListResponseCard response={responseList} key={index} index={index}
         />
    );
          
return <>
      <span className="text-left">
            <div className="mt-4 mx-2">
                 <b>{this.lineNbr}. Event Title: </b>  {this.props.cardList.title}
            </div>
            <div className="mx-4">
                 <b> Description:</b> {this.props.cardList.description} &nbsp;&nbsp;&nbsp;&nbsp;
                     <b>Text Length:</b>&nbsp;&nbsp;{this.props.cardList.description.length}
            </div>
            <div className="mx-4">
                  <b>Responses:</b>
            </div>
            <div className="mx-4">
                  {this.responseInfo}
            </div>
      </span>         
           
   </>
  }
}

export default EventListDisplayCard;