const PurchaseItemEventText =[

  //  ||purchaseEventDesc|| is replaced by 'descText'
  //  ||purchaseEventResponse|| is replaced by 'responseText'

  //  these are purchase items that create the "friend-purchases" condition
  //  descText begins with "do you want to...."
  //  responseText begins with "||friendName|| and I have a great time ...."
  {itemName: "small drone with camera", 
    descText: "go to the park and fly your drone",  
    responseText: " flying your drone at the park " +
      "and you were able to take some great pictures"},
  {itemName: "all-terrain-vehicle (ATV)", 
    descText: "go to a wilderness area and ride around on your ATV",
    responseText: " riding around on the ATV.  It was fun to explore " +
      "a bunch of trails"},
  {itemName: "virtual reality headset and gaming setup",
    descText: "play some games.  I'd love to try out your virtual reality headset",
    responseText: " playing with your virtual reality headset"  },
  {itemName: "arcade game",
    descText: "play with the arcade game",
    responseText: " playing the arcade game"  },
       
];

export default PurchaseItemEventText;