const financialAdvisorText =[
  {topic: "income", condition: "level1", text: "You may want to select a job that makes a higher salary. " +
      "While college is expensive it can lead to a better job." },
  {topic:  "income", condition: "level2", text: "You made a good salary but next time you might want to " +
      "consider a side hustle at least once in a while to help with unexpected expenses or special vacations.  "},
  {topic: "income", condition: "level3", text: "You did a good job of selecting a good job with a salary that is " +
      "above average." },
  {topic: "income", condition: "default", text: "Remember that in the game you can increase your salary by earning some " +
      "badges or completing some of the extra credit activities."},
  {topic: "income", condition: "pendingJobMoreSalary", text: "It looks like you were going to school " +
      "to earn a degree so that you can become a ||jobName||.  That was a good idea. When you got the degree and the new job, " +
      "your salary would have increased."},
  {topic: "income", condition: "pendingJobLessSalary", text: "It looks like you were going to school " +
      "to earn a degree to become a ||jobName||.  It was a good idea to go back to school, but when you got the degree and " +
      "the new job, your salary would have been less."},
  {topic: "expenses", condition: "rentOver30", text: "Your monthly rent was more than 30 percent of your " +
      "takehome pay (salary minus taxes). In general, this is not a good idea.  When you play again, look for " +
      "a place to live that has a lower monthly rent amount. "  },
  {topic: "expenses", condition: "roommate", text: "....OR.... You could consider getting a roommate to " +
      "help pay the rent."},
  {topic: "expenses", condition: "bedrooms", text: "Of course, if you are going to get a roommate, you would need " +
      "to find a place that has more than 1 bedroom."}, 
  {topic: "expenses", condition: "miscExpLvl1", text: "You spent too much on basic items such as food " +
      "clothing, and such.  Consider eating at home more often, shopping at less expensive stores."},
  {topic: "expenses", condition: "miscExpLvl2", text: "You spent a little too much on basic items such as food, " +
      "clothing, and such.  Consider eating at home more often, and shopping at less expensive stores. "  +
      "Also, you should really consider using coupons!"},
  {topic: "expenses", condition: "miscExpLvl3", text: "You did a good job of managing the amount you spend " +
      "monthly on food and clothing."},
  {topic: "expenses", condition: "sellHorse", text: "Your monthly net income was less than $500.  I do not "  +
      "think you should have bought a horse. "},
  {topic: "expenses", condition: "sellBoat", text: "I do not think you should have purchased a boat. " +
      "It cost you a lot to buy and maintain the boat. "},
  {topic: "expenses", condition: "default", text: "I reviewed all of your expenses.  It seems that you " +
      "did a good job of managing your expenses."  },
  {topic: "loans", condition: "mortgageTooHigh", text: "A house is a great investment, however, your monthly " +
      "mortgage payment was more than 30 percent of your " +
      "takehome pay (salary minus taxes). When you play again, consider finding a place to live that will have " +
      "a lower monthly amount. "  },
  {topic: "loans", condition: "mortgageWithRoommate", text: "Your mortgage amount was more than 30% of your " +
      "take-home pay (salary minus taxes) but, it looks like you had a roommate.  That was smart because " +
      "it helped you to afford your monthly mortgage amount. "},
  {topic: "loans", condition: "sportsCar", text: "I see you owned a sports car.  Sports cars are very cool " +
      "... but... it was more expensive than what you can could really afford. "  },
  {topic: "loans", condition: "carLoanTooMuch", text: "You spent more for your car each month than " +
      "I would recommend.  When you play again, find a car that is not so expensive."  },
  {topic: "loans", condition: "default", text: "I reviewed your outstanding loans and I don't see " +
      "anything that I could recommend.  "  },
  {topic: "general", condition: "savingForHome", text: "If you were saving money in your cash account for a " +
      "downpayment on a home, that was a good idea....However...." 
  },
  {topic: "general", condition: "increaseSavings", text: "When you play the game again, you might want to increase " +
     "the amount that you save each month in your 401K account."  },
  {topic: "general", condition: "goodJobSavings", text: "You were doing a great job of saving money " +
     "each month.  It looks like you had enough left over for unexpected amounts and for extra things " +
     "that you may have wanted to purchase."  },
  {topic: "general", condition: "beginSavings", text: "When you play the game again, you might want to consider " +
      "starting a savings (401K) account."  },
  {topic: "general", condition: "startSavingYoung", text: "Remember, the sooner you begin saving money, the more money " +  
      "you will have put aside when you are ready to retire."  },
  {topic: "general", condition: "decreaseSavings", text: "OK, while I generally suggest that a person save as " +
      "much as they can, in your case, you may want to rethink this a bit.  You were saving so much that you " +
      "didn't have a lot left over for unexpected expenses or taking a vacation of some kind."  },
  {topic: "general", condition: "creditCardMin", text: "I noticed that you were paying the " +
      "minimum amount each month on your credit card."
      },
  {topic: "general", condition: "creditCardMinOK", text: "It looks like the total outstanding balance was not too large. "  },
  {topic: "general", condition: "creditCardMinIssue", text: "You had a fairly large outstanding balance on your card " +
      "That would have ended up costing you a lot of extra money. "  },
  {topic: "general", condition: "ccManual", text: "I think you said you were paying your credit card manually  " +  
      "each month.  That is a really good idea because you can review your charges to ensure they are OK before " +
      "you pay. "},
  {topic: "general", condition: "ccManualOK", text: "Your outstanding credit card balance was pretty low so you were " +
      "doing a good job of paying the balance each month."  },
  {topic: "general", condition: "ccManualIssue", text: "Your outstanding credit card balance was fairly large.  This " +
      "is very expensive in the long run. " },
  {topic: "general", condition: "ccIntPenalty", text: "As you likely know, you were being charged ||cclatefee|| on " +
      "the amount that you carried over from month to month."  },
  {topic: "general", condition: "incomeGreat", text: "You were doing a great job overall of making money!  Your net income " +
      "was enough to allow you to cover unexpected expenses and still save money each month. " +
      "Bravo!!!"  }, 
  {topic: "general", condition: "incomeGood", text: "You were doing a good job of managing monthly expenses.  Your net income " +
      "was enough to allow you to cover monthly expenses.  When you play the game again, " +
      "You may want to consider taking action on some of the suggestions I've made in the other areas." },
  {topic: "general", condition: "incomeOK", text: "Your overall status was OK.  Your net income may " +
      "be enough for day-to-day, but it was not really large enough to cover unexpected expenses or save money each month. " +
      "When you play the game again, I hope you will take note of some of the suggestions that I've made in other topics."  },
  {topic: "general", condition: "incomeNotOK", text: "You were in a pretty scary place when the game ended in terms of " +
      "your net income.  It would have been hard for you to pay for unexpected expenses and you " +
      "really weren't able to save money.  When you play the game again, I hope you will consider doing some of the things " +
      "that I've suggested in the other topics. "  },     
  {topic: "general", condition: "default", text: "You know, I don't really have anything to advise other than " +
      "what you can find in the other topics." },
  {topic: "general", condition: "tooManyPurchases", text: "You told me about the 'cool things' that you " +
      "purchased.  While I am sure these were fun, you actually spent ||totalCost|| which is more than " +
      "the amount of cash that you had when you finished the game.  I would suggest you think hard before buying " +
      "any of these high-cost items the next time you play." },
  {topic: "general", condition: "watchPurchases", text: "You gave me a list of the 'cool things' that you " +
      "purchased.  I'm not sure if you realize that the total amount of these special purchases is ||totalCost||. "  +
      "Although it seems you could afford these items, it might be better, when you play the game again, to save " +
      "the money instead."
   },
   {topic: "general", condition: "oneTimeSavings", text: "You have more than $10,000 in the bank at this time. " +
       "When you play again, if you have this much in the bank, you might want to put some of this money in a 401K savings " +
       "account so that it will increase due to interest earned."
}
]

export default financialAdvisorText;