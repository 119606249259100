const ClassHierarchy =[
  {
    "mainClass": "home",
    "subClass": "roommate",
  },
  {
    "mainClass": "career",
    "subClass": "job",
  },
  {
    "mainClass": "career",
    "subClass": "careerRelated",
  },
  {
    "mainClass": "career",
    "subClass": "manager",
  },
  {
    "mainClass": "career",
    "subClass": "college"
  },
  {
    "mainClass": "job",
    "subClass": "manager",
  },
  
];

export default ClassHierarchy;