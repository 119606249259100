
export const findMatchingHomeChangeDialog = (parameters, dialogTable) => {
      
    let friendCombinedDialogTable = [];
    let friendDialogTable1 = [];
    let friendDialogTable2 = [];
    for (let i=0; i<dialogTable.length; i++){
         let checkDialogObj = dialogTable[i];  
         if (checkDialogObj.homeType === parameters.homeType && checkDialogObj.homeLevel === parameters.homeLevel &&
             checkDialogObj.bedRooms === +parameters.bedRooms){
              friendDialogTable1 = dialogTable[i].commentTable;
         } 
         if (checkDialogObj.roommates === +parameters.roommates){
              friendDialogTable2 = dialogTable[i].commentTable;    
         }
    }
    for (let i=0; i<friendDialogTable1.length; i++){
        let dialogObj = friendDialogTable2.find(elem => elem.friendName === friendDialogTable1[i].friendName);
        let combinedObj = "";
        if (dialogObj == undefined){
            combinedObj = friendDialogTable1[i];
        }else{
            let dialogComment = friendDialogTable1[i].comment + " " + dialogObj.comment;
            combinedObj = {friendName: friendDialogTable1[i].friendName, comment: dialogComment}
        }
        friendCombinedDialogTable.push(combinedObj);
    }

    if (friendDialogTable1.length === 0 && friendDialogTable2.length !== 0){
        friendCombinedDialogTable = friendDialogTable2;
    }
      
    return friendCombinedDialogTable;
   
}
export default findMatchingHomeChangeDialog;

