const newGameDataObj = 
{
  "username": "studentX",
  "badgesEarned": [],
  "assetTable": [
    {
      "assetItem": "Job Salary",
      "assetDesc": "Electrical Engineer --> Annual Salary: $64,000.00",
      "assetAmt": "5333.33",
      "assetStartDate": "2022-10",
      "assetNbrMonths": "-1"
    },
  ],
  "expenseTable": [
    {
      "expItem": "Payroll taxes",
      "expDesc": "Federal, FICA (Social Security)",
      "expAmount": "2291.83",
      "expStartDate": "2022-10",
      "expNbrMonths": "-1"
    },
    {
      "expItem": "Apartment Rent",
      "expDesc": "Meadow Hills-1 bedroom, 0 roommates",
      "expAmount": 1751,
      "expStartDate": "2022-10",
      "expNbrMonths": "-1"
    },
    {
      "expItem": "Health Insurance",
      "expDesc": "Health Insurance 70% Paid by Employer",
      "expAmount": "111.00",
      "expStartDate": "2022-10",
      "expNbrMonths": "-1"
    },
    {
      "expItem": "Automobile Insurance",
      "expDesc": "Insurance for car repair or replacement",
      "expAmount": 75,
      "expStartDate": "2022-10",
      "expNbrMonths": "-1"
    },
    {
      "expItem": "Renter's Insurance",
      "expDesc": "Insurance for furniture and other personal items",
      "expAmount": 30,
      "expStartDate": "2022-10",
      "expNbrMonths": "-1"
    },
    {
      "expItem": "Transportation",
      "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
      "expAmount": "115.56",
      "expStartDate": "2022-10",
      "expNbrMonths": "-1"
    },
    {
      "expItem": "Misc Expenses",
      "expDesc": "Food, Clothes, Personal Items, etc.",
      "expAmount": 380,
      "expStartDate": "2022-10",
      "expNbrMonths": "-1"
    }
  ],
  "loanTable": [
    {
      "loanType": "Car Loan",
      "loanDesc": "Mid-Size Car - 3 to 5 Years Old",
      "loanAmt": "300.21",
      "loanStartDate": "2022-10",
      "loanNbrMonths": 72,
      "loanTotAmt": 16205,
      "loanIntRate": 0.1,
      "loanTerm": 6
    },
    {
      "loanType": "College Loan",
      "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
      "loanAmt": "474.65",
      "loanStartDate": "2022-10",
      "loanNbrMonths": 120,
      "loanTotAmt": "48000.00",
      "loanIntRate": 0.035,
      "loanTerm": 10
    }
  ],
  "skillSurvey": [
    "5",
    "5",
    "5",
    "5",
    "5",
    "5",
    "5",
    "5",
    "5",
    "5",
    "5",
    "5"
  ],
  "conditionsTable": [
    {
      "condName": "homeType-apartment",
      "conditionClass": "home"
    },
    {
      "condName": "electrical-engineer",
      "conditionClass": "career"
    }
  ],
  "pendingEventsArr": [],
  "unlockedFeatures": [],
  "friendList": [
    {
      "name": "Charlie",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    },
    {
      "name": "Cameron",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    },
    {
      "name": "Jennifer",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    },
    {
      "name": "Cindy",
      "introDate": "2022-10",
      "dialogIndx": 0,
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    }
  ],
   "jobInfo": {
    "careerID": "61310cff2afc7f761668ac09",
    "healthInsInd": "true",
    "jobRank": "8",
    "nbrYearsCollege": "4",
    "nbrSkillSurvey": "1",
    "jobStartGameMonth": 360
  },
  "homeInfo": {
    "homeID": "61323f3e97d929c9a8cf8708",
    "homeType": "apartment",
    "roommates": "0",
    "bedrooms": "1"
  },
  "furnitureID": "6132a4c097d929c9a8cf8719",
  "carInfo": {
    "carID": "6132c4a797d929c9a8cf8727",
    "carAge": 48,
    "carYear": "2018",
    "carType": "200"
  },
  "startingCash": "3434.05",
  "currentCash": "3434.05",
  "currentMoodPoints": "25000",
  "startGameDate": "2022-10",
  "currentGameDate": "2022-10",
  "gameNbrMonths": "360",
  "nbrMonthsNegative": "0",
  "spendingHistory": {
    "currentMonthSpendAmt": 0,
    "current10YearSpendAmt": 4441,
    "current10YearMonths": 1,
    "previous10YearSpendAmt": 0,
    "previous10YearMonths": 0
  },
  "moodPointHistory": {
    "currentMonthMoodPoints": 0,
    "totalMoodPoints": 25000,
    "moodPointMonths": 1
  },
  "iconTable": [
    {
      "iconName": "gameBasicHelp",
      "iconCategory": "information",
      "className": "small_sign_thumbnail_img information-sign-position",
      "imageSrc": "gameHelp.png",
      "validInd": true,
      "firstTime": true,
      "additionalInfo": ""
    }
  ],
  "dateAdded": "2022/10/23",
  "dateUpdated": "2022/10/23",
  "hand": [
    {
      "conditionArr": [],
      "skipConditionArr": [],
      "imageArr": [],
      "_id": "6178133140bc3c4a83fac971",
      "title": "Unexpected Overtime",
      "description": "One of your co-workers is sick. Your manager asks if you will work extra time in order to cover their absence.",
      "frequency": "167",
      "responses": [
        {
          "btnText": "Accept Overtime",
          "closureText": "You decided you could use some extra money.  You earned $value as a result of the extra hours.  It also made your manager happy.",
          "category": "asset",
          "on": "Job Salary",
          "type": "percent",
          "duration": "1",
          "replaceAmtSw": null,
          "triggerCondition": null,
          "deleteCondition": null,
          "deleteClass": null,
          "moodPoints": "-10",
          "value": "3"
        },
        {
          "btnText": "Decline Overtime",
          "closureText": "You really did not want to work extra hours.  Your manager seems disappointed.",
          "category": "cash",
          "on": "cash",
          "type": "flat",
          "duration": "1",
          "replaceAmtSw": null,
          "triggerCondition": null,
          "deleteCondition": null,
          "deleteClass": null,
          "moodPoints": "10",
          "value": "0"
        }
      ],
      "backgroundType": "work",
      "value": null
    },
    {
      "conditionArr": [],
      "skipConditionArr": [
        "good-manager"
      ],
      "imageArr": [],
      "_id": "617813d640bc3c4a83fac972",
      "title": "Manager Trouble",
      "description": "You have had a long week trying to complete the work that has been assigned. You feel tired but happy that you were able to get the work done.",
      "frequency": "83",
      "responses": [
        {
          "btnText": "OK",
          "closureText": "Your manager calls you into their office.  They are upset because you forgot to get some paperwork turned in on time.",
          "category": "asset",
          "on": "Job Salary",
          "type": "percent",
          "duration": "1",
          "replaceAmtSw": null,
          "triggerCondition": null,
          "deleteCondition": null,
          "deleteClass": null,
          "moodPoints": "-10",
          "value": "0"
        }
      ],
      "conditionConnector": "",
      "backgroundType": "work",
      "value": null
    },
    {
      "conditionArr": [],
      "skipConditionArr": [],
      "imageArr": [],
      "_id": "619d5fedc57f3e5a98216251",
      "responses": [
        {
          "btnText": "Full Service Oil Change",
          "closureText": "Several issues were found as part of the inspection.   You elect to have these problems fixed.  Total cost of the oil change and the fixes is $value.",
          "category": "cash",
          "on": "cash",
          "type": "flat",
          "duration": "1",
          "replaceAmtSw": null,
          "triggerCondition": null,
          "deleteCondition": null,
          "deleteClass": null,
          "moodPoints": "-5",
          "value": "-186"
        },
        {
          "btnText": "Oil Change Only",
          "closureText": "The oil change was completed.  Cost of the service was $value.",
          "category": "cash",
          "on": "cash",
          "type": "flat",
          "duration": "1",
          "replaceAmtSw": null,
          "triggerCondition": null,
          "deleteCondition": null,
          "deleteClass": null,
          "moodPoints": "-1",
          "value": "-85"
        }
      ],
      "title": "Car Oil Change",
      "description": "Your car needs to have an oil change.  You take it to a place that does basic car maintenance.  They suggest that you opt for a 'full service oil change' which includes a 25 point inspection.  Which option do you choose?",
      "frequency": "167",
      "backgroundType": "home",
      "__v": 0,
      "value": null
    },
    {
      "conditionArr": [],
      "skipConditionArr": [],
      "imageArr": [],
      "_id": "61d233146a82333ba09dab58",
      "responses": [
        {
          "btnText": "Go to a Movie",
          "rltnshpScore": "+3",
          "closureText": "You decide to grab something to eat and then go to a movie.  The meal and movie cost $value.",
          "category": "cash",
          "on": "cash",
          "type": "flat",
          "duration": "1",
          "replaceAmtSw": "false",
          "triggerCondition": "",
          "deleteCondition": "",
          "deleteClass": "",
          "moodPoints": "10",
          "value": "-35"
        },
        {
          "btnText": "Play Frisbee",
          "rltnshpScore": "+3",
          "closureText": "You head to a nearby park to play some frisbee.  After you're done, you decide to grab a bite to eat at a nearby restaurant.  This cost you $value.",
          "category": "cash",
          "on": "cash",
          "type": "flat",
          "duration": "1",
          "replaceAmtSw": "false",
          "triggerCondition": "",
          "deleteCondition": "",
          "deleteClass": "",
          "moodPoints": "10",
          "value": "-15"
        },
        {
          "btnText": "Play Golf",
          "rltnshpScore": "+3",
          "closureText": "It was fun to go golfing but you didn't do that well.  You decide to go out to dinner with ||friendName||.  The total cost for golfing and dinner was $value.",
          "category": "cash",
          "on": "cash",
          "type": "flat",
          "duration": "1",
          "replaceAmtSw": "false",
          "triggerCondition": "",
          "deleteCondition": "",
          "deleteClass": "",
          "moodPoints": "10",
          "value": "-107"
        },
        {
          "btnText": "Decide Not to Go",
          "rltnshpScore": "-3",
          "closureText": "You apologize to ||friendName|| and tell them that you don't have enough money to go anywhere right now.  Perhaps another time.",
          "category": "cash",
          "on": "cash",
          "type": "flat",
          "duration": "1",
          "replaceAmtSw": "false",
          "triggerCondition": "",
          "deleteCondition": "",
          "deleteClass": "",
          "moodPoints": "-10",
          "value": "0"
        }
      ],
      "title": "Do Something Fun",
      "description": "||friendName|| stops by and wants to know if you'd like to do something.  You have several options.  What do you do?",
      "frequency": "167",
      "conditionConnector": "",
      "backgroundType": "home",
      "imageDir": "friends",
      "value": null
    }
  ],
  "nbrMonthsMoodNegative": "0",
  "prevGameMonths": "33"
}
      
export default newGameDataObj;