const MoneyMgmtChallenge2HintsText =
    ["There are 4 items that should be moved from the 'Considerations' column  " +
        "to the 'Advantages of having a Monthly Plan' column.",
     "A monthly plan lists your monthly income and all of the  " +
        "items (expenses) that you expect to spend money on each month.",
     "Listing all of your monthly expenses gives you a way to review " +
        "the amounts and determine ways to reduce costs if needed."
    ]
   

export default MoneyMgmtChallenge2HintsText;