const friend1GameDialogDataset ={friendName: "Jennifer",  dialogTable: [
      {title: "Jennifer Introduction",
            text: "Hi, I don't know if you remember me, but my name is Jennifer.  I saw you moving in. "  +
            "I think we went to the same high school and had a lot of friends in common.  I met you before " +
            "and thought you might like to catch up.", 
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Sure, you want to come in for a bit?.", moodPoints: 2, rltnPoints: +1}, 
                        {rspText: "Ok, I really don't remember you. But I'm pretty busy right now.", moodPoints: -2, rltnPoints: -1}] },
      {title: "Career Choice",
            text: "I went to college for a while and ended up studying to be an electrician.  I really like the job.",
            conditionList: [], type: "inPerson",
            responses: [{rspText: "That's cool.  I'll bet its hard work sometimes.", moodPoints: 1, rltnPoints: +1},
                        {rspText: "OK.... That's interesting I guess.", moodPoints: -1, rltnPoints: -2}] },
      {title: "Nice Compliment",
            text: "You seem really nice.  I like hanging out with you.",
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Thanks. ", moodPoints: 1, rltnPoints: -2},
                        {rspText: "Thanks, I like doing things with you as well.", moodPoints: 2, rltnPoints: +2}] },
      {title: "Spending Habit",
            text: "Darn, it seems like I've spent my paycheck before I even get it. I'm thinking that I'll start " +
            "using my credit card a lot more.",
            conditionList: [], type: "inPerson",
            responses: [{rspText: "That helps in the short term, but don't they charge a lot of interest?.", 
                              moodPoints: 1, rltnPoints: +1},
                        {rspText: "That might be the best thing to do.", moodPoints: -1, rltnPoints: 0}] },
      {title: "Did not have a father",
            text: "My mom raised me alone.  My dad left when I was a baby. I've never even met him. " +
                  "I used to imagine he'd come back and bring us lots of money but that never happened.",
            conditionList: [], type: "inPerson",
            responses: [{rspText: "That must have been kind of hard.", 
                              moodPoints: 1, rltnPoints: +2},
                        {rspText: "(shrug) Life's like that sometimes.", moodPoints: -1, rltnPoints: -1}] },
      {title: "Grew up poor",
            text: "We never had any money when I was growing up.  I worked some and got a bunch of student " +
                  "loans or I would never have been able to go to college. ",
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Well at least you are putting your education to use.", 
                                          moodPoints: -1, rltnPoints: 0},
                        {rspText: "I can totally relate.", moodPoints: +1, rltnPoints: +1}] },
      {title: "Job Situation",
            text: "I had a really tough day at work today.  The person that I was working with took " +  
             "forever to do their part and my manager thought I was the problem.", 
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Do you think your manager was right?", moodPoints: -2, rltnPoints: -2},
                        {rspText: "That sounds frustrating.", moodPoints: 2, rltnPoints: +2}] },

      {title: "Karaoke with Jennifer",
            text: "You wanna go out and do something fun?  We could " +
             "try an escape room, or karaoke, or play some pool, or whatever." ,   
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Sure, that sounds like fun!", moodPoints: 2, rltnPoints: +2, cashAmt: -63.50,
                              itemDesc: "Karaoke Palace"},
                        {rspText: "No, I don't really want to go out.", moodPoints: -2, rltnPoints: -2}] },
      
      {title: "Dinner out with Jennifer",
            text: "I just stopped by to say hello.  I really wanted to go out to dinner tonight " +
             "but I don't have the money.  I guess I'll just find something at home to eat. " ,   
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Yeah, I know that feeling", moodPoints: -1, rltnPoints: -1},
                        {rspText: "We can go out to eat... my treat.", moodPoints: +2, rltnPoints: +2, cashAmt: -68.59,
                              itemDesc: "Chinese Tavern"}] },
      {title: "Loaning money to Jennifer",
            text: "I am really short of cash right now.  Is there any chance that you could loan me " +
                  "a couple hundred dollars? ",
            conditionList:  [], type: "inPerson", 
            responses: [{rspText: "I don't have any extra money right now.", moodPoints: -3, rltnPoints: -3, 
                              loanedMoney: false},
                        {rspText: "Sure, I can do that.  Hope you can pay me back soon.", 
                              moodPoints: -2, rltnPoints: +5, cashAmt: -200,  on: "cashOnly",
                              triggerCondition: "loaned-jennifer-money"}]},  
      {title: "Car Mechanic Assist",
            text: "I have a favor to ask. I had to take my car in to get it fixed, the mechanic just called to say it's " +
             "ready, can you give me a ride to pick it up?", 
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Sure, let's go.", moodPoints: -1, rltnPoints: +2},
                        {rspText: "Sorry, I am too busy right now.", moodPoints: -2, rltnPoints: -1}] },
        
      {title: "New Car Purchase",
            text: "I want to buy a new car.  I've been looking but the cars that I want are " +
             "very expensive.  If I get a new car, I might not have enough for groceries.",  
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Sounds like you should buy a car that isn't so expensive.", moodPoints: 2, rltnPoints: -1},
                        {rspText: "I'd say go for it.  You can make it work somehow.", moodPoints: -1, rltnPoints: 0}] },
      {title: "Electric Car Purchase",
            text: "You know, I was really hoping to buy an electric car.  Much better for the environment "  +
                  "and I think it would be kind of fun. But they are very expensive!",                 
            conditionList: [], type: "inPerson",
            responses: [{rspText: "They are expensive but it is a good idea.", moodPoints: 2, rltnPoints: 2},
                        {rspText: "Electric cars are just a hassle.", moodPoints: 1, rltnPoints: -2}] },              
      {title: "New Bicycle",
            text: "I decided to wait awhile on buying a new car.  So I bought a new bike instead. " +
                  "Do you want to go for a bike ride?",  
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Absolutely, that sounds like fun.", moodPoints: 1, rltnPoints: 2},
                        {rspText: "No, I'm too busy.", moodPoints: -2, rltnPoints: -2}] }, 
      {title: "Jennifer pays back part of loan",
            text: "I just stopped by to give you back $100 that I borrowed.  Thanks for the loan. ", 
                   conditionList:  ["loaned-jennifer-money"], type: "in-person", 
            responses: [{rspText: "Thanks, but you still owe me $100.", moodPoints: -2, 
                              rltnPoints: -2, cashAmt: 100},
                        {rspText: "Thanks for paying me back.", 
                              moodPoints: -4, rltnPoints: +2, cashAmt: 100}]},  
      {title: "New Fun Place",
            text: "I had the best time this weekend. I went to a new restaurant. Very fancy.  It cost me over " +
                   "$100 for dinner.  But it was really good food.  You ought to go sometime.",  
            conditionList: [], type: "inPerson",
            responses: [{rspText: "That sounds like a waste of money to me.", moodPoints: -1, rltnPoints: -1},
                        {rspText: "I would love to try it sometime!", moodPoints: 1, rltnPoints: 1}] }, 
      {title: "You have Old Car",
            text: "Your car is really old.  Are you thinking of buying a new one?", 
            conditionList: ["car-gt-10-years-old"], type: "inPerson",
            responses: [{rspText: "It might be old, but it still runs well.", moodPoints: -1, rltnPoints: -1},
                        {rspText: "Maybe soon, I've been thinking about it.", moodPoints: 2, rltnPoints: 1} ] },

      {title: "Starting Own Business",
            text: "I've been thinking of starting my own electrician contracting company. Of course, I would need " +
                  "to save a lot of money first.  It is so hard to save money!!", 
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Sounds like a great idea to me!", moodPoints: 1, rltnPoints: +2},
                        {rspText: "I think it's easy to save if you really want to.", moodPoints: 2, rltnPoints:1} ] },
      {title: "Bought New TV",
            text: "I just bought a new TV.  It has a really big screen.  Now I can watch football and " +
                  "feel like I'm almost at the game!!", 
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Very Cool!", moodPoints: -1, rltnPoints: +1},
                        {rspText: "I thought you were saving money?  What was wrong with your old TV?", 
                              moodPoints: -1, rltnPoints: -1} ] },
      {title: "Expensive Dinner",
            text: "Just stopped by to see if you want to go out to dinner.  I'm meeting another friend at that " +
                  "place I told you about.  It is fairly pricey (around $100 for dinner) but it is SOOOO good!" ,
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Sorry, too rich for my blood.", moodPoints: +1, rltnPoints: -1},
                        {rspText: "Sure, sounds like a good idea!", cashAmt: -125.37, itemDesc: "Dinner with Jennifer",
                                    moodPoints: -2, rltnPoints: +2} ] },
      //20
     
       {title: "Hard to Save Moeny",
            text: "I am having such a difficult time saving money.  When I get my paycheck, I try to put some money " +
                  "in savings, but something always comes up.  I think I only have $100 in savings right now.",  
            conditionList: [], type: "inPerson",
            responses: [{rspText: "I can relate, it can be hard to save money.", moodPoints: -1, rltnPoints: +1},
                        {rspText: "Maybe you should try not to spend so much money.", cashAmt: 0, itemDesc: "",
                                    moodPoints: -1, rltnPoints: -1} ] },
      {title: "Monthly Budget",
            text: "I was talking with a person at work about saving money.  They suggested that " +
                  "I put together a monthly budget.  That way I can see where my money is being spent.  That might " +
                  "be a good idea, but it would be such a pain.",  
            conditionList: [], type: "inPerson",
            responses: [{rspText: "It might take some time, but I think it would be a good idea..", 
                                    moodPoints: 1, rltnPoints: -2},
                        {rspText: "Yeah, I know what you mean.", cashAmt: 0, itemDesc: "",
                                    moodPoints: -1, rltnPoints: +1} ] },
      {title: "Joined a Gym",
            text: "I was noticing that I am getting winded walking up and down stairs at work, so I decided to " +
                  "join a gym.  It is a great place to workout and it only costs $70 per month.",  
            conditionList: [], type: "inPerson",
            responses: [{rspText: "That sounds really expensive.  That's over $800 per year.", moodPoints: +1, rltnPoints: -2},
                        {rspText: "Good idea! I'm sure that'll help with your job.", cashAmt: 0, itemDesc: "",
                                    moodPoints: -1, rltnPoints: +1} ] },
       
      {title: "New Furniture",
            text: "I just bought some new furniture for my living room.  They delivered it earlier today. " +                 
                  "The pieces were a bit expensive but they look amazing.  You want to come over and " +
                  "to check it out? ",
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Absolutely, I would love to see the new furniture!", 
                        moodPoints: -1, rltnPoints: +1},
                        {rspText: "No, I'm too busy right now.  Also, weren't you trying to save money?", cashAmt: 0, itemDesc: "",
                        moodPoints: -2, rltnPoints: +5, on: "cashOnly",
                        triggerCondition: ""}]},  
      {title: "Loan Money",
            text: "It seems I messed up.  I shouldn't have bought that living room furniture. " +
                  "I'm running really low on cash this month.  Can i borrow $100 from you? ",
           conditionList: [], type: "inPerson",
            responses: [{rspText: "Uh, no!  You still owe me $100 from the last time I loaned you money.", 
                        moodPoints: -1, rltnPoints: -2},
                        {rspText: "Yes, I can help you out.", cashAmt: -500.00, itemDesc: "Loaned Jennifer Money",
                        moodPoints: -2, rltnPoints: +5, on: "cashOnly",
                        triggerCondition: "loaned-jennifer-money"}]},  
      // 25 items

      {title: "Game Night",
      text: "I'm having a game night next week.  I'm thinking of inviting 3 friends and I " +
            "have a bunch of games.  You wanna come?  You're the first one I thought to ask.",
      conditionList: [], type: "inPerson",
      responses: [{rspText: "Count me in.  That sounds like fun!", 
                  moodPoints: +2, rltnPoints: +2},
                  {rspText: "I don't think so.  I'm no good at games.", cashAmt: 0, itemDesc: "",
                  moodPoints: -2, rltnPoints: -2, on: "",
                  triggerCondition: ""}]},  
      {title: "Difficulty Saving Money",
      text: "I'm so frustrated.  I've been trying to save money for the past two months and I only have $100 " +
            "in my savings account. ",
      conditionList: [], type: "inPerson",
      responses: [{rspText: "That's because you've also been spending more than you should.", 
                  moodPoints: +1, rltnPoints: -1},
                  {rspText: "I can totally relate and agree, it is really hard to save money.", cashAmt: 0, itemDesc: "",
                  moodPoints: -2, rltnPoints: +1, on: "",
                  triggerCondition: ""}]},  
      {title: "Sky Diving Anyone?",
            text: "I'm going to try sky diving.  It's been on my 'bucket list' for a long time. " +
                  "I found a place that does tandem diving and it's not terribly expensive. It " +
                  "looks like it only costs around $300.",  
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Count me out.  That's expensive and not my idea of fun!", 
                                    moodPoints: -1, rltnPoints: -2},
                        {rspText: "Wow, that would be so cool!  I'm in.", cashAmt: -359, itemDesc: "Sky Diving Adventure",
                                    moodPoints: +2, rltnPoints: +2} ] },
      {title: "Another Electrician Started Own Business",
            text: "I just found out that one of the other electricians on our team quit and is starting their own " +
                  "contracting business.  That's what I want to do eventually.  It will be interesting to see how " +
                  "it goes for them.",  
            conditionList: [], type: "inPerson",
            responses: [{rspText: "Being your own boss sounds really cool!!", 
                                    moodPoints: +1, rltnPoints: +1},
                        {rspText: "That sounds like a stupid thing to do.", cashAmt: 0, itemDesc: "",
                                    moodPoints: -1, rltnPoints: -2} ] },
       {title: "New Job Advice",
            text: "You remember someone I know just started their own company?  They asked me " +
                  "if I want to work with them.  They said I might not get paid very much for the " +
                  "first couple of months, but after that, it should be gravy.  What do you think? ", 
            conditionList: [], type: "inPerson",
            responses: [{rspText: "That could be sort of fun, right?", 
                                    moodPoints: +1, rltnPoints: +1},
                        {rspText: "I wouldn't think you could afford to say 'Yes'.", cashAmt: 0, itemDesc: "",
                                    moodPoints: -1, rltnPoints: -2} ] },
      {title: "Want to Go Out?",
            text: "I'm just wondering if you would like to go out and do something fun? " +
                  "We could get some dinner and drinks.  I know a couple of places that are really fun. ",
            conditionList: [], type: "inPerson",
            responses: [{rspText: "I've got so much to do.  I don't want to go out.", 
                                    moodPoints: -1, rltnPoints: -2},
                        {rspText: "Absolutely!  That sounds like fun.", cashAmt: -73, itemDesc: "Dinner and Drinks with Jennifer",
                                    moodPoints: +2, rltnPoints: +2} ] },
      {title: "Got a New Job",
            text: "I decided to go to work at that new company I told you about.  I am going to start next week. " +
                  "I think it will be really fun.  Plus, if it doesn't work out, I can always find another company " +
                  "to work for. ", 
            conditionList: [], type: "inPerson",
            responses: [{rspText: "I have a feeling that is not going to work out well for you.", 
                                    moodPoints: -1, rltnPoints: -2},
                        {rspText: "Very cool!  I think that sounds like a great idea.", cashAmt: 0, itemDesc: "",
                                    moodPoints: +1, rltnPoints: +2} ] },

   //(32)
   // working at start-up does not work well for Jennifer and she ends up having to go back to a larger company

   // goal is 40 dialog items
]};

export default friend1GameDialogDataset