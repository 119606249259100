import { calcTaxAmt } from './taxAmtCalc_function';

export const createTaxAmtExpense = (expenseTable, assetTable, startTaxDate) => {
      // remove current tax expense (set current payroll tax expense item to 0 months)
      for (let i=0; i<expenseTable.length; i++){
            if (expenseTable[i].expItem == "Payroll taxes" &&
                expenseTable[i].expNbrMonths == "-1" &&
                  expenseTable[i].expStartDate <= startTaxDate){
                        expenseTable[i].expNbrMonths = 0;
            }
      }
   
      let newTaxAmt = calcTaxAmt(assetTable);
      let taxAmtExpense = {expItem: "Payroll taxes", expDesc: "Federal, FICA (Social Security)", 
            expStartDate: startTaxDate, expAmount: newTaxAmt, expNbrMonths: "-1", chargeCreditCard: false};
      expenseTable.push(taxAmtExpense);
                
   return expenseTable;
   
}
