import {Component} from 'react';
import CareerInput from '../shared-components/career_input';
import { withRouter } from "react-router";

class CareerSelect extends Component {
  
    constructor(props) {
        super(props);
        props.onOpen();
        this.inputSelected = (inputID) => {
           
        };
        this.state = {
           
        }
        
        this.inputSelected(1);
    }    

    render() {
    
    return <>
            <CareerInput 
                screenSize={this.props.screenSize}
                onOpen ={()=> this.inputSelected(1)} 
                onSelected={() => this.onCareerSelected()}
                processError = {(errorObj) => this.props.processError(errorObj)} />
    </>
    }

    onCareerSelected() {
        this.props.completed();
        this.props.history.push('/choose-home');
    }

}
export default withRouter(CareerSelect);