const newLeaderBoardObj = 
{
    "account": "72458639",
    "user": "studentX",
    "combinedScore": "24424",
    "dateUpdated": "2023/02/9",
    "moodScore": "14952",
    "nbrGameMonths": "355",
    "retirementScore": "33895",
    "startGameDate": "2023-02"
  }

  export default newLeaderBoardObj;