import {Component} from 'react';
import './Special/openEnvelope.css';

class EnvelopeLegendCard extends Component {
  constructor(props) {
    super(props);
    this.state={
   
    }    
}

render() {
      if (this.props.legendInfo.textName == "||jobName||"){
            this.textName = this.props.jobName;
      }else{
            this.textName = this.props.legendInfo.textName;
      }
      
      this.style = {backgroundColor: this.props.legendInfo.colorCode}
                  
return <>
      <div style={this.style2}>
            <div className="font-weight-bold medium-text-size" style={this.style}>
                  {this.textName}
            </div>
      </div>
     
   </>
  }
}

export default EnvelopeLegendCard;