const newAccount = 

{
    "emailAddress": "emailadd@gmail.com",
    "accountNbr": "88888888",
    "rqstdUsersArr": [
      {
        "role": "teacher",
        "dateRqstd": "2023/10/16",
        "nbrUsersRqstd": "1"
      },
      {
        "role": "player",
        "dateRqstd": "2023/10/16",
        "nbrUsersRqstd": "10"
      }
    ],
    "authCodeArr": [],
}

 export default newAccount;