const MoneyMgmtChallenge1HintsText =
    ["There are 4 items in the Considerations column " +
        "that should be moved to the Good Money Management Practices column.",
     "Planning for your future and creating a monthly budget " +
        "will help you to manage your money more effectively.",
     "It is a good idea to ensure that you have enough money " +
        "for monthly expenses before buying non-essential items.",
     "It is a good idea to consider the salary and future " +
        "earning potential when choosing a career." 
    ]
   

export default MoneyMgmtChallenge1HintsText;