
export const createWiderPath = (currentPath) => {
    var newPathColumn = 0;
    let newPath = [];
    let rowColumn = [];
    let rowColObj = {};
    for (let i=0; i<currentPath.length; i++){
        rowColumn = currentPath[i].split('|');
        //  expand path to the left
        let pathRow = +rowColumn[0];
        let pathColumn = +rowColumn[1];  
        rowColObj = {row: pathRow, column: pathColumn};
        newPath.push(rowColObj);
        if (pathColumn > 1){
            newPathColumn = pathColumn - 1;
        }else{
            newPathColumn = pathColumn + 1;
        }
        rowColObj = {row: pathRow, column: newPathColumn};
        if (!newPath.includes(rowColObj)){
            newPath.push(rowColObj);
        }
       
    }

    newPath.sort((a, b) => ((a.row) > (b.row) )  ? 1 : -1);
    let outPath = [];
    for (let i=0; i<newPath.length; i++){
         rowColumn = newPath[i].row + "|" + newPath[i].column;
         if (!outPath.includes(rowColumn)){
             outPath.push(rowColumn);
         } 
    }

return outPath;
}

