import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import { doubleToStringMonthly } from '../../objects/CommonUse/double_functions';
import NotificationTextDataset from './datasets/notification_text_dataset';
import WaysToIncreaseCash from './text/ways_to_increase_cash';
import GameElementHelp from './text/game_element_help';
import NumberedListCard from './numbered_list_card';
import {replaceTextInString} from '../../objects/CommonUse/replace_text_in_string';

class NotificationModal extends Component {
  constructor(props) {
    super(props);
    this.gameInfo = this.props.gameInfo();
    this.state={
         modalText: "",
         imageSrc: "",
         modalTitle: "",
         textArr: [],
         notificationText: NotificationTextDataset,
         link: "",
         displayModalLink: false,
    };    
  }

  preProcess(){
      this.determineModalClass();
      this.determineModalText();
      this.buildModalLinks();
  }

  determineModalClass(){
      if (this.props.notifyInfo.category == "goodNews"){
            this.modalClass = "goodNewsModal";
            this.modalBackground = "goodNewsModalBackground";
            this.modalType = "Good News";
            this.headerStyle = {
              backgroundColor: '#97FFBA'
            }
           
      }
      if (this.props.notifyInfo.category == "warning"){
            this.modalClass = "gameWarningModal"
            this.modalBackground = "gameWarningModalBackground"
            this.modalType = "Warning";
            this.headerStyle = {
              backgroundColor: '#FFFF99'
            }
      }
      if (this.props.notifyInfo.category == "information"){
        this.modalClass = "gameInfoModal"
        this.modalBackground = "gameInfoModalBackground"
        this.modalType = "Information";
        this.headerStyle = {
          backgroundColor: 'white'
        }
  }
      this.headerClass = this.modalBackground;
      this.footerClass = this.modalBackground + " text-right"
  }

  determineModalText(){
       this.state.textArr = [];
       let notificationText = this.state.notificationText;
       for (let i=0; i<notificationText.length; i++){
            if (notificationText[i].notificationType == this.props.notifyInfo.type){
                this.modalText1 = notificationText[i].text[0];
                this.modalText2 = notificationText[i].text[1];
                if (this.props.notifyInfo.firstTime == true &&
                    this.props.notifyInfo.additionalInfo != undefined &&
                    this.props.notifyInfo.additionalInfo != "" &&
                    this.props.notifyInfo.additionalInfo.firstTimeText != undefined){ 
                      this.modalText1 = notificationText[i].firstTimeText + this.modalText1;
                }
                if (notificationText[i].notificationImg != ""){ 
                    this.state.imageSrc = "./assets/Icons/" + notificationText[i].notificationImg;
                    this.modalImg = true;
                }else{
                      this.modalImg = false;
                }
                this.state.modalTitle = notificationText[i].modalTitle;
                if (notificationText[i].textArr != ""){
                    //  can use this for other things in the future but right now
                    //  this is only used for the warning modals.
                      this.determineTextArr(notificationText[i].textArr);
                }
                if (notificationText[i].modalSize == undefined){
                    this.modalSize = "sm";
                }else{
                    this.modalSize = notificationText[i].modalSize;
                }
                break;
            }
       } 
       this.modalText1 = this.replaceTextInString(this.modalText1);
       if (this.modalText2 != undefined){
          this.modalText2 = this.replaceTextInString(this.modalText2);
       }
  }

  replaceTextInString(textString){
       if (textString.includes("||")){
            let replaceObj = {};
            if (this.gameInfo.gameData.pendingJob != undefined){
                 replaceObj = {...this.props.notifyInfo.additionalInfo, 
                  jobStartDate: this.gameInfo.gameData.pendingJob.jobStartDate};
            }else{
                  replaceObj = this.props.notifyInfo.additionalInfo;
            }
            textString = replaceTextInString(textString, replaceObj);
       }
       return textString;
  }
   
  determineTextArr(arrayName){
      switch (arrayName){
        case "WaysToIncreaseCash":
          this.state.textArr = WaysToIncreaseCash;
           break;
        case "GameElementHelp":
          this.state.textArr = GameElementHelp;
           break;
        
      }
  }

  buildModalLinks(){
      const notificationObj = this.state.notificationText.find(elem => elem.notificationType === this.props.notifyInfo.type);
      if (notificationObj.link != undefined && notificationObj.link != "") {
        this.state.link = notificationObj.link;
        this.state.displayModalLink = true;
      }
    
    
  }

  render() { 
        this.preProcess();     
        if (this.state.textArr){
            this.numberedList = this.state.textArr.map((textLine, key) =>
                <NumberedListCard key={key} text={textLine} />
          );
        }
  return <>
   
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} 
            size={this.modalSize} animation={false}>
        <Modal.Header className={this.modalBackground} closeButton>
            <Modal.Title>{this.modalType}</Modal.Title> 
          </Modal.Header>
          <Modal.Body className={this.modalClass}>
                 <span>    
                    {this.modalImg ?
                      <div className="text-center">
                          <img className="medium_thumbnail_img" src={this.state.imageSrc} alt="modal image"/>
                      </div>
                    :
                      null
                    }
                    <h4 className="text-center">{this.state.modalTitle}</h4>   
                    <div className="mt-2 mx-0 small-text">
                          {this.modalText1}
                    </div>
                    <div className= "mt-1 mx-0 small-text">
                          {this.modalText2}
                    </div>
                    <div className="mt-1 mx-0 small-text">
                          <ol className="text-left mr-0 small-text" type="1">
                              {this.numberedList}
                          </ol>
                    </div>  
                    {this.state.displayModalLink ?
                        <div
                           className="decorative light-blue-color text-center text-pointer "
                              onClick={() => this.goToLink(this.state.link)}><u>Link</u>
                        </div>
                    :
                    null
                    }
                  </span>
            
          </Modal.Body>
          <Modal.Footer className={this.footerClass}>
              <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"/>
              <Button  variant="info"   onClick={() => this.props.onClose()}>
                   Close
              </Button>
          </Modal.Footer>
          </Modal>
    
    </>
  }

  goToLink(){
       let linkProcess = "/" + this.state.link;
       this.props.history.push(linkProcess); 
  }

  //  the following is used to make text into a link....  may want to support this after basic process is working
  //    <a className="" onClick ={()=>this.goToMainPage()}>Go to Main Menu-click on Extra Credit.</a> 
 
}
export default withRouter(NotificationModal);