export const createConditionsTable = (conditionClass, primaryFieldName, fieldID, 
   additionalFieldArr, conditionsList) => {
 
// conditions table is the conditionsTable that is currently on the player
// conditionClass is the class that is being processed (ex: car, home, etc)
// primaryFieldName is the id field for the class (carID, homeID, etc)
// fieldID is the value of the id field
// additionalFieldArr is an array that contains a list of fields/values that a   re valid for this condition
// conditionsList is the full conditionsList collection

   var i;
   var j;
   var k;
   var compareValuesArr=[];
   var createCondition = false;
   var fieldIDFound = false;
   var conditionsTable = [];
   // determine what entries should be added 
   for (i=0; i<conditionsList.length; i++){
         if (conditionsList[i].conditionClass == conditionClass){ 
            compareValuesArr = conditionsList[i].compareValues; 
            if (conditionsList[i].primaryFieldName == primaryFieldName ){
               for (j=0; j<compareValuesArr.length; j++){
                  //   create entries in condition table if they match the ID of the chosen item 
                  if (compareValuesArr[j] == fieldID){
                     fieldIDFound = true;
                     let conditionObj = {condName: conditionsList[i].condName, 
                        conditionClass: conditionsList[i].conditionClass }
                     conditionsTable.push(conditionObj); 
                  }
               }
            }
         }
   }         
   createCondition = false;
   for (j=0; j<additionalFieldArr.length && createCondition == false; j++){
      // additionalFieldArr is an object with fieldName, gameValue 
      
      for (i=0; i<conditionsList.length; i++){
         if (conditionsList[i].conditionClass == conditionClass){  
                  compareValuesArr = conditionsList[i].compareValues; 
                  if (conditionsList[i].primaryFieldName == additionalFieldArr[j].fieldName){
                     if (conditionsList[i].relationType == "EQ"){
                        for (k=0; k<compareValuesArr.length; k++){
                           if (additionalFieldArr[j].fieldValue == compareValuesArr[k]){
                              createCondition = true;
                           }
                        }
                     }else{
                        switch(conditionsList[i].relationType) {
                           case "GE":
                                 if (+additionalFieldArr[j].fieldValue >= +compareValuesArr[0]){
                                    createCondition = true;
                                 }
                                 break;
                           case "GT":
                                 if (+additionalFieldArr[j].fieldValue > +compareValuesArr[0]){
                                    createCondition = true;
                                 }
                                 break;
                           case "LE":
                                 if (+additionalFieldArr[j].fieldValue <= +compareValuesArr[0]){
                                    createCondition = true;
                                 }
                                 break;
                           case "LT":
                                 if (+additionalFieldArr[j].fieldValue < +compareValuesArr[0]){
                                    createCondition = true;
                                 }
                                 break;
                           case "NQ":
                                 if (additionalFieldArr[j].fieldValue != compareValuesArr[0]){
                                       createCondition = true;
                                 }
                                 break;
                        }
                        
                     }
                     if (createCondition == true){
                        let conditionObj = {condName: conditionsList[i].condName, 
                           conditionClass: conditionsList[i].conditionClass }
                        conditionsTable.push(conditionObj);
                        createCondition = false; 
                     }
                  }
            }
            
         }
   }
            
   return conditionsTable;
   
}





