import React from 'react';
import LinkCard from '../shared-components/link_card';
import SavingsOverTimeModal from './savings_over_time_modal';
import LoanCalculatorModal from './loan_calculator_modal';
import CreditCardCalculatorModal from './credit_card_calculator_modal';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';


class MoneyCalculators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           openCalculatorSw: false,
           calculateOnly: false,
        }    
        this.calculatorType = "";
       
    }

determineCalculatorType(buttonSlctd){
    this.calculatorType = buttonSlctd;
    this.gutters = "";
    if (this.props.screenSize === "narrow" || this.props.screenSize === "medium") {
        this.gutters = "modal-95";
    }
    this.setState({openCalculatorSw: true});
}


closeCalculator(){
    this.setState({openCalculatorSw: false});
}

  render() {
           this.className = "input-page-base-card";
           let screenParms = setScreenSizeParameters(this.props.screenSize, "extraCredit");
           this.className = screenParms.columnClass;
           this.linkSize = screenParms.linkSize;
              
  return <>
           <div className={this.className}>
           <div className="card mt-2 rounded-corners-lg text-center height-max">
                <button className="pages-back-button ml-4 mt-4" 
                    onClick ={()=> this.props.goBack()}>
                                    Back
                </button>

            <h3 className="mt-0 text-center">Money Calculators</h3>
            <h6 className="mt-2, mx-5 text-left">You cannot earn extra cash or increase your salary by using these money
                calculators, but working with these will certainly help your understanding of loans, savings and
                credit cards.
            </h6>
            <span className="row w-70 mx-5 my-0">
                <div className="mx-4">
                    <div className="col-sm-8 ml-2" onClick={() => this.determineCalculatorType("Loan")}>
                        <LinkCard name="Loan Calculator" imgSrc='./assets/ExtraCredit/learningAboutLoans.png' 
                                inSize={this.linkSize} alt=""/>
                    </div>
                    <div className="col-sm-8 mx-2" onClick={() => this.determineCalculatorType("CreditCard")}>
                        <LinkCard name="Credit Card Calculator" imgSrc='./assets/ExtraCredit/creditCard.png'
                            inSize={this.linkSize} alt=""
                            />
                    </div>
                </div>
                <div className="mx-4">  
                    <div className="col-sm-8 mx-2" onClick={() => this.determineCalculatorType("Savings")}>
                        <LinkCard name="Savings Calculator" imgSrc='./assets/ExtraCredit/saveMoney.png'
                            inSize={this.linkSize} alt=""
                            />
                    </div>
                </div>
            </span>

        </div>
        
        {this.calculatorType === "Loan" ?
            <LoanCalculatorModal show={this.state.openCalculatorSw} 
                screenSize={this.props.screenSize}
                onClose={() => this.closeCalculator()} 
                loanChallenges={false}
                calculatorSw ={this.state.calculateOnly}/>
        :
            null
        }
         {this.calculatorType === "Savings" ?
            <SavingsOverTimeModal show={this.state.openCalculatorSw}
                gutters={this.gutters}
                onClose={() => this.closeCalculator()}
                calculatorSw={this.state.calculateOnly} 
                displayInitialAmt={true}/>
        :
            null
        }
        {this.calculatorType === "CreditCard"  ?
            <CreditCardCalculatorModal show={this.state.openCalculatorSw} 
                screenSize={this.props.screenSize}
                onClose={() => this.closeCalculator()} 
            />
        :
            null
        }
       
    </div>   
</>
  }
  

}

export default MoneyCalculators;