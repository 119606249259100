const LivingBelowChallenge2HintsText =
    ["There are a total of 5 items that should be moved to the 'Good Ideas' column.",
     "Maxing out your credit card and paying only the minimum " +
        "amount each month, may seem like a good plan in the short term, " +
        "but the interest paid on a credit card will makes this an expensive " +
        "option over time and it will be hard to dig out of the debt " +
        "that you accumulate.",
     "Going back to school may take some time, but if your employer pays has a " +
        "tuition reimbursement program, it is a good idea to consider this option.",
     "This will make it easier to get a loan if you need one.",
     "Although investing in a high-risk option might result in a large " +
        "payoff, it is more likely that you will just lose the money " +
        "that you have invested." ,
    ]
   

export default LivingBelowChallenge2HintsText;