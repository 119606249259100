const SavingMoneyChallenge2HintsText =
    ["There are 4 items in the advice column that would be considered " +
        "good advice.",
     "It is not a good idea to withdraw money from a 401K before reaching the " +
        "age of 59 and 1/2.  The fees and taxes can mean that you could withdraw $10,000 " +
        "and only end up with $7000 in savings.",
     "If you wait to start saving until you are close to retirement, it will be much harder to save " +
        "enough to make a comfortable retirement.  Social Security amounts are often not " +
        "adequate to cover all of your regular living expenses." 
    ]
   

export default SavingMoneyChallenge2HintsText;