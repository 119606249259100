

export const setEventTrackerObj = (gameData, response, eventTitle, eventCount) => {
   let eventTrackerObj = {eventCount: eventCount,
         event: eventTitle,
         gameDate: gameData.currentGameDate,
         response: response,
         afterCash: gameData.currentCash,
         afterSalary: gameData.assetTable[0].assetAmt,
         afterMoodPoints: gameData.currentMoodPoints    
   };
              
   return eventTrackerObj;
   
}





