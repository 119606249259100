import { shuffleArray } from "../CommonUse/shuffleArray";

export const dragNDropCreateDataset = (nbrColumns, dataset, pictureDir, maxItems) => {     

    // pictureDir is the library name (directory) where the pictures of items are stored
    // maxItems is an optional parameter but indicates the maximum number of items that should end up in column 1.

    var randomNbrArr=[];
    var columnArr=[];
    var columnNameArr=[];
    var column1Items = 999;
    if (maxItems != undefined){
         column1Items = maxItems;
    }
   
    let gameData = dataset.columns;

    // create an array of numbers (indexes) with length equal to the number of columns
    var columnNbrs = [];
    for (let i=0; i < gameData.length; i++){
          columnNbrs.push(i);
    }
    let columnNbrsShuffled = shuffleArray(columnNbrs);
    for (let i=0; i< nbrColumns; i++){
          randomNbrArr.push(columnNbrsShuffled[i]);  
    }

     // create an array of random columns and all items for the selected column
    for (let i=0; i < nbrColumns; i++){
         let columnIdx = randomNbrArr[i];
         columnNameArr.push(gameData[columnIdx].columnName);
         let itemList = gameData[columnIdx].items;
         for (let j=0; j < itemList.length; j++){
            let columnObj = {columnName: gameData[columnIdx].columnName, itemContent: itemList[j].content,
                itemAmt: itemList[j].amount, itemPicture: pictureDir + itemList[j].picture, itemOrder: itemList[j].order};
            columnArr.push(columnObj);
        }
    }
    var columnArrId = [];
    let columnItemArray = shuffleArray(columnArr);
    var datasetItemArr= [];
    var itemArr = [];
   
    columnArrId.push({columnName: dataset.column1Name, outColumnName: dataset.column1Name, columnId: "column-1",
        columnIdQuotes: "'column-1'" });
    for (let i=0; i < columnItemArray.length && itemArr.length <= column1Items; i++){
            let columnIdx = columnNameArr.indexOf(columnItemArray[i].columnName);
            let columnId = "column-" + (columnIdx + 2) ;
            let columnIdQuotes = "'" + columnId + "'";
            let columnNameWithSpace = columnItemArray[i].columnName.replace(/-/g, " ");
            let columnObj = {columnName:  columnItemArray[i].columnName,  outColumnName: columnNameWithSpace,   
                    columnId: columnId, columnIdQuotes: columnIdQuotes}
            columnArrId.push(columnObj);
             
            let itemId = "item-" + (i+1) ; 
            itemArr.push(itemId);
            let itemLine = { [itemId]: { id: itemId,   content: columnItemArray[i].itemContent, 
                amount: columnItemArray[i].itemAmt , picture: columnItemArray[i].itemPicture, answer: columnId, 
                order: columnItemArray[i].itemOrder }};     
            datasetItemArr.push(itemLine);
    }
   
    // remove dups in the columnArrId array
    columnArrId.sort((a, b) => (a.columnId > b.columnId) ? 1 : -1);
    var columnNoDups = [];
    columnNoDups.push(columnArrId[0]);
    for (let i=1; i<columnArrId.length; i++){
        if (columnArrId[i].columnId != columnArrId[i-1].columnId){
               columnNoDups.push(columnArrId[i]);
        }
    }

    var columnItemLine = "";
    var datasetColumnArr = [];
    let emptyArr = [];
    for (let i=0; i < columnNoDups.length; i++){
           if (columnNoDups[i].columnId == "column-1"){
                 columnItemLine = {[columnNoDups[i].columnId]: { id: columnNoDups[i].columnId, 
                     title: columnNoDups[i].outColumnName ,  itemIds: itemArr}}; 
           }else{
                columnItemLine = {[columnNoDups[i].columnId]: { id: columnNoDups[i].columnId, 
                     title: columnNoDups[i].outColumnName ,  itemIds: emptyArr}}; 
                        
           }
           datasetColumnArr.push(columnItemLine);
   }

   //  need to find a way to do the following without having to hard-code!!!
    let itemsObj = {...datasetItemArr[0], ...datasetItemArr[1], ...datasetItemArr[2], ...datasetItemArr[3],
        ...datasetItemArr[4], ...datasetItemArr[5], ...datasetItemArr[6], ...datasetItemArr[7],
        ...datasetItemArr[8], ...datasetItemArr[9], ...datasetItemArr[10], ...datasetItemArr[11],
        ...datasetItemArr[12], ...datasetItemArr[13], ...datasetItemArr[14], ...datasetItemArr[15],
        ...datasetItemArr[16], ...datasetItemArr[17], ...datasetItemArr[18], ...datasetItemArr[19],
        ...datasetItemArr[20], ...datasetItemArr[21], ...datasetItemArr[22], ...datasetItemArr[23],
        ...datasetItemArr[24], ...datasetItemArr[25], ...datasetItemArr[26], ...datasetItemArr[27],
        ...datasetItemArr[28], ...datasetItemArr[29], ...datasetItemArr[30], ...datasetItemArr[31],
        ...datasetItemArr[32], ...datasetItemArr[33], ...datasetItemArr[34], ...datasetItemArr[35],
    }
           
     let columnsObj = {...datasetColumnArr[0], ...datasetColumnArr[1], ...datasetColumnArr[2], 
        ...datasetColumnArr[3],  ...datasetColumnArr[4], ...datasetColumnArr[5]};
        
     var columnIdArr = []; 

    for (let i=0; i<columnNoDups.length; i++){
        columnIdArr.push(columnNoDups[i].columnId);
    }
    
    let columnIdObj = {columnOrder: columnIdArr};
     
    let dragNDropDataset = {items: itemsObj, columns: columnsObj, ...columnIdObj}; 
                  
    return dragNDropDataset; 
}


