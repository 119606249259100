import ConditionClasses from "../../../datasets/condition_classes_dataset";
import { createConditionsTable } from "./create_Conditions_Table_function";

export const setupConditionsUpdate = (gameData, conditionsList, conditionClassesIn) => {
   // this creates an extract that is processed by the createConditionsTable function
   //     primaryFieldName & primaryFieldID --> this is for conditions that are triggered by the "_id" field
   //     additionalFieldArr --> this is for conditions that are triggered by all other (non "_id" values)
   //        the fieldName in the array is set equal to the fieldName in the event condition and the fieldValue
   //        in the array is equal to the value in the game.  If the value in the array (game) has the right
   //        relationship (EQ, GT, LT, LE, GE) as that specified in the event condition, then the condition
   //        will be created.
   let conditionClassTable = [];
   let conditionsTableArr = [];
   let classConditionsArr = [];
   var fieldIDValue = "";
   var primaryFieldName = "";
   let selectItem = false;
   let randomNbr = Math.random();
   if (randomNbr <= 4){
         selectItem = true;
   }
   if (conditionClassesIn == undefined || conditionClassesIn.length == 0){
        conditionClassTable = ConditionClasses;
   }else{
        conditionClassTable = conditionClassesIn;
   }
   for (var i=0; i<conditionClassTable.length; i++){
      var additionalFieldArr = [];
      var arrayObj = "";
      var classFound = false;
      switch(conditionClassTable[i]){
         case "car":
            primaryFieldName = "carID";
            fieldIDValue = gameData.carInfo.carID;
            arrayObj={fieldName: "carAge", fieldValue: gameData.carInfo.carAge};
            additionalFieldArr.push(arrayObj);
            arrayObj={fieldName: "carType", fieldValue: gameData.carInfo.carType};
            additionalFieldArr.push(arrayObj);
            classFound = true;
            break;
         case "home":  
            primaryFieldName = "homeID";
            fieldIDValue = gameData.homeInfo.homeID;
            arrayObj={fieldName: "roommates", fieldValue: gameData.homeInfo.roommates}
            additionalFieldArr.push(arrayObj);
            arrayObj={fieldName: "homeType", fieldValue: gameData.homeInfo.homeType};
            additionalFieldArr.push(arrayObj);
            classFound = true;
            break;
         case "roommate":
            primaryFieldName = "roommates";
            // setting the field value to 99 will skip the primary field in the create conditions table function 
            fieldIDValue = "99";
            arrayObj={fieldName: "roommates", fieldValue: gameData.homeInfo.roommates};
            additionalFieldArr.push(arrayObj);
            classFound = true;
            break;
         case "career":
            primaryFieldName = "careerID";
            fieldIDValue = gameData.jobInfo.careerID;
            classFound = true;
         case "job":
            classFound = true;
            arrayObj={fieldName: "nbrYearsCollege", fieldValue: gameData.jobInfo.nbrYearsCollege};
            additionalFieldArr.push(arrayObj);
            let nbrMonthsInJob = gameData.jobInfo.jobStartGameMonth - gameData.gameNbrMonths;
            arrayObj={fieldName: "nbrMonthsInJob", fieldValue: nbrMonthsInJob}
            additionalFieldArr.push(arrayObj);
            break;
         case "furniture":
            primaryFieldName = "furnitureID";
            fieldIDValue = gameData.furnitureID;
            classFound = true
            break;
         case "game":
             primaryFieldName = "";
             arrayObj={fieldName: "gameNbrMonths", fieldValue: gameData.gameNbrMonths};
             fieldIDValue = gameData.gameNbrMonths;
             additionalFieldArr.push(arrayObj);
             classFound = true;
             break;
         case "cash":
             primaryFieldName = "";
             arrayObj={fieldName: "currentCash", fieldValue: gameData.currentCash};
             fieldIDValue = gameData.currentCash;
             additionalFieldArr.push(arrayObj);
             classFound = true;
             break;
         default:
            break;
      }
      if (classFound == true){
         classConditionsArr = createConditionsTable(conditionClassTable[i], primaryFieldName, 
            fieldIDValue, additionalFieldArr, conditionsList);
         for (var j=0; j<classConditionsArr.length; j++){
            conditionsTableArr.push(classConditionsArr[j]);
         }
      }
   }

   return conditionsTableArr;
   
}
export default setupConditionsUpdate;





