
export const displayFriendComment = (category, friendInfo) => {
    
    let displayComment = false;
    if (category !== "SideHustle" &&
        friendInfo.friendName !== undefined){
            displayComment = true;
        }
  
    return displayComment;
}

