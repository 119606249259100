export const dateStrToPlainText = (dateStr) => {
    const yearMonthVals = dateStr.split('-');
    switch (yearMonthVals[1])
    {
        case '01':
            return `January ${yearMonthVals[0]}`;
        case '02':
            return `February ${yearMonthVals[0]}`;
        case '03':
            return `March ${yearMonthVals[0]}`;
        case '04':
            return `April ${yearMonthVals[0]}`;
        case '05':
            return `May ${yearMonthVals[0]}`;
        case '06':
            return `June ${yearMonthVals[0]}`;
        case '07':
            return `July ${yearMonthVals[0]}`;
        case '08':
            return `August ${yearMonthVals[0]}`;
        case '09':
            return `September ${yearMonthVals[0]}`;
        case '10':
            return `October ${yearMonthVals[0]}`;
        case '11':
            return `November ${yearMonthVals[0]}`;
        case '12':
            return `December ${yearMonthVals[0]}`;
    }
}

export const cashStrMask = (cashStr) => {
    return `$${format(cashStr)}`;
}

const format = num => 
    String(parseFloat(num).toFixed(2)).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
;