const ExtraCreditConfigFile =
  {
      challengePoints: [{level: "total", points: 6}, {level: "1", points: 3}, {level: "2", points: 3}],
      exercises:  [{category: "MoneyMgmt", level: "1", type: "cash", amt: 1000}, 
                   {category: "Priorities", level: "1", type: "cash", amt: 1500}, 
                   {category: "ChoosingCareer", level: "1", type: "salary", amt: 250},
                   {category: "LivingBelowYourMeans", level: "2", type: "salary", amt: 350},
                   {category: "SavingMoney", level: "2", type: "salary", amt: 400},
                   {category: "LearningAboutLoans", level: "2", type: "cash", amt: 2000},
                  ],
      dragNDropDefaults: {modalSize: "xl", buttonType: "Done"},
      textReplacements: {maxScore: 700},
      general: {defaultSalary: 45000, 
        title: "Not Quite Right, Please Try Again - Here are some helpful hint(s)"}
  };

export default ExtraCreditConfigFile;