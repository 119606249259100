import {Component} from 'react';
//import './Special/special.css';
import OneThruFiveButtons from './one-thru-five-buttons';


class QuestionsDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }    
}

render() {
           
return <>
       <div>
          <OneThruFiveButtons
               questionNbr={this.props.questionText.questionNbr} 
               questionText={this.props.questionText.question}
               selected={ this.props.questionText.answer}
               errorMsg={this.props.questionText.errorMsg}
               onSelected={(returnObj) => this.props.onSelected(returnObj)}/>
      </div>
   </>
  }
}

export default QuestionsDisplayCard;