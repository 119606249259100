import './ExtraCredit/extraCredit.css';
import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import DragNDropColumn from './drag_n_drop_column'
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AlertTextPopup from '../shared-components/alert_text_popup';

//*  This modal has been developed to be a generic drag-n-drop process.  You can supply any
//*  number of columns and items and this will work as expected.  There are several variables
//*  which you can provide via "props".  These are defined below.  Most of these are not
//*  required (unless noted as required below).  You can call the modal without providing any 
//*  values and/or leave the values blank and the process will work without the variable.
//*  
//*  Prop variables are as follows:
//*   inputDataset (required) - This provides the data for the columns and items that is to be
//*     used in the drag-n-drop boxes.  Refer to other 'dataset' components for format.
//*   buttonType (required) - This is used to indicate which buttons should be displayed on the modal.  
//*   cookieNamePfx (required-conditional) - This is required if the results from the 'drag
//*     and drop' are going to be graded.
//*
//*   message - This is text that is displayed next to the buttons.  It is usually used to
//*     note if errors have been found.
//*   maxNbr - This indicates the maximum number of items for each column.  This number is 
//*     applied to all columns except the first column.  (EX: each column can only have 1 item)
//*   introText - This is text that should be displayed before the drag-n-drop boxes.
//*   tableNbrRows - This indicates whether or not a table should be displayed.  If this is 1, then
//*     a table with 1 row will be displayed.  If this is 2, then a table with 2 rows will be
//*     displayed (currently only 2 row tables are supported). 
//*   tableObjectRow1 - This is an object that contains the columns that are to be displayed in
//*       this modal.  The table displayed will have 5 columns.  All items in the table will be 
//*       displayed on one row within the modal.
//*   tableObjectRow2 - This is the same as TableObject1 but supports a second row in the table.

const Container = styled.div`display : flex;`

const DragNDropModal = (props) => {
  const [data, setData] = useState(props.inputDataset)
  //const [alertText] = useState(props.hintTable.map((hintText,key) =>
    //      <AlertTextPopup key={key} hintText={hintText} />
      //   )); 

  
  const onDragEnd = result => {
    
    //if freeze flag is true, then no moves are allowed
    if (props.freezeFlag !== undefined && props.freezeFlag === true){
          return
    }
   
    const { destination, source, draggableId, type } = result;
    //If there is no destination
    if (!destination) { return }

    //If source and destination is the same
    if (destination.droppableId === source.droppableId && destination.index === source.index) { return }

    //Anything below this happens if you're dragging loan description
    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    //reset if dragging a column
    if (type === 'column') {
      return;
    }
    
    //If dropped inside the same column
    if (start === finish) {
      const newTaskIds = Array.from(start.itemIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...start,
        itemIds: newTaskIds
      }
      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn
        }
      }
      setData(newState)
      return;
    }

    //If dropped in a different column
    const startTaskIds = Array.from(start.itemIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      itemIds: startTaskIds
    }

    const finishTaskIds = Array.from(finish.itemIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      itemIds: finishTaskIds
    }

    //if more than 'maxNbr' items dragged to a column then return (except column 1)
    let maxNbr = parseFloat(props.maxNbr);
    if (!isNaN(maxNbr) &&
        finishTaskIds !== undefined && finish.id !== "column-1" && finishTaskIds.length > maxNbr) {
          return; 
    } 

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    setData(newState)
  }


  return (
        <Modal show={props.show}  onHide={() => props.onClose()} size={props.modalSize} scrollable="true"
            dialogClassName={props.gutters} animation={false} >
            <Modal.Header closeButton>
                <Modal.Title>{props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{props.introText}</p>
                    <AlertTextPopup
                        show={props.displayAlert}  
                        gutters={props.gutters}
                        alertText={props.hintTable} 
                        hintNbr={props.hintNbr}
                        title={props.title}
                        closeAlert={() => props.closeAlert()} 
                      />
                          
              {props.tableNbrRows !== undefined && props.tableNbrRows >= 1 ?
                <span className="row=100">
                  <table className="ml-3 table table-hover">
                    <tbody>
                      <tr>
                        <th className="p-0 text-center" scope="row"></th>
                        <th className="p-0 text-center" scope="row">
                              {props.tableObjectRow[0].colHeading}</th>
                        <th className="p-0 text-center" scope="row">
                              {props.tableObjectRow[1].colHeading}</th>
                        <th className="p-0 text-center" scope="row">
                              {props.tableObjectRow[2].colHeading}</th>
                        <th className="p-0 text-center" scope="row">
                              {props.tableObjectRow[3].colHeading}</th>
                    </tr>
                    <tr>
                        <th className="text-left p-0">
                              {props.tableCol1Text[0]}</th>
                        <td className="text-center p-0">
                              {doubleToStringMonthly(props.tableObjectRow[0].value[0])}</td>
                        <td className="text-center p-0">
                              {doubleToStringMonthly(props.tableObjectRow[1].value[0])}</td>
                        <td className="text-center p-0">
                              {doubleToStringMonthly(props.tableObjectRow[2].value[0])}</td>
                        <td className="text-center p-0">
                              {doubleToStringMonthly(props.tableObjectRow[3].value[0])}</td>  
                    </tr>
                    {props.tableNbrRows === 2  ?
                    <tr>
                        <th className="text-left p-0">
                          {props.tableCol1Text[1]}</th> 
                        <td className="text-center p-0">
                             {doubleToStringMonthly(props.tableObjectRow[0].value[1])}</td>
                        <td className="text-center p-0">
                             {doubleToStringMonthly(props.tableObjectRow[1].value[1])}</td>
                        <td className="text-center p-0">
                             {doubleToStringMonthly(props.tableObjectRow[2].value[1])}</td>
                        <td className="text-center p-0">
                             {doubleToStringMonthly(props.tableObjectRow[3].value[1])}</td>  
                      </tr>
                    :
                      null
                    }        
                    </tbody>
                  </table>
                </span>
                :
                  null
              }  
                              
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='all-columns' direction='horizontal' type='column'>
                      {(provided) => (
                        <Container {...provided.droppableProps} ref={provided.innerRef}>
                          {data.columnOrder.map((id, index) => {
                            const column = data.columns[id]
                            const items = column.itemIds.map(itemId => data.items[itemId])

                            return <DragNDropColumn key={column.id} column={column} items={items} 
                            index={index} cookieNamePfx={props.cookieNamePfx} 
                            screenSize={props.screenSize}
                            amountSw={props.amountSw} pictureSw={props.pictureSw}/>
                            })}
                          {provided.placeholder}
                        </Container>
                   )}
                  </Droppable>
                </DragDropContext>
            </Modal.Body>
            <Modal.Footer>
            <div>{props.message}</div>
            {props.buttonType === "Done" ?           
                 <span>    
                     <Button variant="primary"  onClick={() => props.onSave()}>
                         Done
                     </Button>
                  </span> 
            :
                <div></div>
            }   
            {props.buttonType === "DoneCalculator" ?    
                <span>
                    <Button className="mr-2" variant="secondary" onClick={() => props.onCalculator()}>
                         {props.calcType} Calculator
                    </Button>
                    <Button variant="primary" onClick={() => props.onSave()}>
                        Done
                    </Button>
                 </span>  
            :    
                 <div></div>
            }
            {props.buttonType === "Continue" ?           
                 <span>    
                     <Button variant="primary"  onClick={() => props.onContinue()}>
                         Continue
                      </Button>
                  </span> 
            :
                <div></div>
            }   
            </Modal.Footer>
        </Modal>      
  );
    }
  

export default DragNDropModal