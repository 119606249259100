import {Component} from 'react';
//import './Special/special.css'

class CommentTextDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }    
}
  


render() {
           
return <>
            <div className="text-left">
                 {this.props.commentText}
            </div>
          
                
   </>
  }
}

export default CommentTextDisplayCard;