const PrioritiesChallenge2Dataset = {
    //extra spaces added before amounts end up lining up the amounts on the display
    minAmount: 1300,
    maxAmount: 1700,
    items: {
        "item-1": { id: "item-1", content: "Pay a friend back money you borrowed.", 
                amount: 200, answer: "column-2" },
        "item-2": { id: "item-2", content: "Plan to eat out three times a week.",
                amount: 300, answer: "column-0"},
        "item-3": { id: "item-3", content: "Purchase tickets to a concert for you and " +
                "a friend.",
                amount: 250, answer: "column-0" },
        "item-4": { id: "item-4", content: "Car maintenance required.  ",
                amount: 750, answer: "column-2"},
        "item-5": { id: "item-5", content: "Purchase equipment for a home gym.",    
                amount: 200, answer: "column-0" },
        "item-6": { id: "item-6", content: "Drone with camera for taking pictures.",
                amount: 100, answer: "column-0"},
        "item-7": { id: "item-7", content: "Buy some new clothes for work.",
                amount: 200, answer: "column-0"},   
        "item-8": { id: "item-8", content: "Buy a paddle board.",
                amount: 300, answer: "column-0"},
        "item-9": { id: "item-9", content: "Purchase groceries for the month.", 
                amount: 300, answer: "column-2"},
        "item-10": { id: "item-10", content: "Save money for vacation and travel.", 
                amount: 200, answer: "column-0"},
    },
    columns: {
        "column-1": { id: "column-1", title: "Monthly Expenses", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7",
            "item-8", "item-9", "item-10"] },
        "column-2": { id: "column-2", title: "Items Selected", itemIds: [] },
        
    },
    columnOrder: ["column-1", "column-2"]
}

export default PrioritiesChallenge2Dataset