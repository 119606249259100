import {Component} from 'react';
import { withRouter } from "react-router";
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';

class ParentsGameInfo extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            userName: "",
            modalOpen: true,
        }
    }
   
      
    render() {
        let screenParms = setScreenSizeParameters(this.props.screenSize, "forParents");
        this.className = screenParms.columnClass;
        this.headerClass = screenParms.textSize + " font-weight-bold mt-3";
        this.textClass = screenParms.textSize + "mr-4";
                   
    return <>
        <div className={this.className}>
        <div className="card mt-3 rounded-corners-lg height-max">
            <button className="pages-back-button ml-4 mt-4" 
                        onClick ={()=> this.props.goBack()}>
                                Back
            </button>
            <h3 className="mt-0 p-0 text-center">For Parents and Teachers</h3>
            <h5 className="text-center">Game Information</h5>
                            
            <div className="overflow-scroll mr-3 ml-3 mb-5">
                           
                <p className={this.textClass}>     
                  The main objective of the MoneyTrain game is to teach some basic concepts
                  of money management.   In addition to tracking the students’ cash position,  
                  we have also introduced the concept of “Mood Points” to help simulate some of 
                  the work/life balance decisions that result from monetary and working decisions. 
                  There are three parts to the game:
                </p>
                <h6 className={this.headerClass}>Getting Started:</h6>  
                <p className={this.textClass}>  
                  During the getting started part of the game, players
                  will make choices that impact their game experience.  These choices involve
                  key decisions such as the job (career) that they will pursue, their home 
                  choice and what car they wish to purchase.  These decisions determine their
                  monthly wages and their starting cash position.  After the key decisions are complete, 
                  the player moves to the Playing the Game part of the game. 
                </p>
                <h6 className={this.headerClass}>Playing the Game:</h6>  
                <p className={this.textClass}>  
                  During this part of the game, players will encounter life events and 
                  must decide what action that they will take in response to the events.  Their cash position
                  and mood points are updated to reflect the decisions that they have made.  This part of the game also
                  supports the ability to change some of the key decisions that were made in the
                  Getting Started part of the game to take advantage of increased salary and/or reduce monthly
                  expenses.  The object of the game is to maximize the cash/savings amount and the mood points.
                  The game ends in one of three ways:  the player's mood points or amount of cash become 0,
                  the player clicks on the "Finish Game' button, or 30 game-years
                  have elapsed.
                </p>
                <h6 className={this.headerClass}>End Game:</h6> 
                <p className={this.textClass}>  
                  During the End Game part of the game, the final cash position and mood points for the player 
                  will be determined.  In the event that the player ends before 30 game-years have elapsed, the 
                  final cash position and mood points will be projected using average game amounts.
                </p>
          
            </div>
        </div>    
       </div>
    </>
    }

}

export default withRouter (ParentsGameInfo);