const MazeActivityParameters = [
   {gameName: "DeliveryDriver", parms: {avatar: "car", blockOutImg: "blackSquare", pointsObj: "house", finishObj: "",
      plainCell: "cell", topToBottom: false, bumpRestart: false}}, 
   {gameName: "DogWalker", parms: {avatar: "dogWalker", blockOutImg: "greenSquare", pointsObj: "fireHydrant", 
      finishObj: "house", plainCell: "path", topToBottom: true, bumpRestart: false }}, 
   {gameName: "Skiing", parms: {avatar: "skier", blockOutImg: "trees", pointsObj: "pineTree", 
      finishObj: "skiLift", plainCell: "snow", topToBottom: true, bumpRestart: true }},
   {gameName: "Rafting", parms: {avatar: "raft", blockOutImg: "rocks", pointsObj: "largeRock", 
      finishObj: "beach", plainCell: "river", topToBottom: true, bumpRestart: true }}, 
   
   
   
]

export default MazeActivityParameters;