import ConfigFile from "../../../datasets/config_file";

export const setNbrMonthsNegative = (currentCash, nbrMonthsCashNegative, currentMoodPoints, nbrMonthsMoodNegative ) => {
    let endGameReason = "";
  
    if (+currentMoodPoints < 0)
    {
        ++nbrMonthsMoodNegative;
        if (+nbrMonthsMoodNegative >= ConfigFile.gameBoard.nbrMonthsMoodNegative)
        {
            endGameReason =  "NegativeMoodPoints";
        }
    }
    else
    {
        nbrMonthsMoodNegative = "0";   
    }
    if (currentCash < 0)
    {
        ++nbrMonthsCashNegative;
        if (+nbrMonthsCashNegative >= ConfigFile.gameBoard.nbrMonthsNegative)
        {
            endGameReason = "NegativeCash";
        }
    }
    else
    {
        nbrMonthsCashNegative = "0";   
    }

    let monthsNegativeReturnObj = {nbrMonthsCashNegative: nbrMonthsCashNegative, nbrMonthsMoodNegative: nbrMonthsMoodNegative,
        endGameReason: endGameReason};
         
    return monthsNegativeReturnObj;
   
}


