const StatusDisplayItemOrder =[
    {type: "asset", item: "Job Salary", order: 100},
    {type: "asset", item: "Salary Bonus - Badges", order: 110},
    {type: "asset", item: "Salary Bonus - Extra Credit", order: 120},
    {type: "expense", item: "Payroll taxes", order: 200},
    {type: "expense", item: "Apartment Rent", order: 220},
    {type: "expense", item: "Renter's Insurance", order: 240},
    {type: "expense", item: "Home Owners Insurance", order: 260},
    {type: "expense", item: "Health Insurance", order: 280},
    {type: "expense", item: "Automobile Insurance", order: 300},
    {type: "expense", item: "Transportation", order: 320},
    {type: "expense", item: "Phone", order: 340},
    {type: "expense", item: "Streaming Movies", order: 360},
    {type: "expense", item: "Charities", order: 380},
    {type: "expense", item: "Pet Expenses", order: 400},
    {type: "expense", item: "Horse Expenses", order: 420},
    {type: "expense", item: "Misc Expenses", order: 440},
    {type: "loan", item: "Home Loan (Mortgage)", order: 500},
    {type: "loan", item: "Mortgage Insurance (PMI)", order: 510},
    {type: "loan", item: "Car Loan", order: 520},
    {type: "loan", item: "College Loan", order: 530}

];

export default StatusDisplayItemOrder