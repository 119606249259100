const StepStoneText =[
       {type: "College",  
       shortText: "The ||StepCareerName|| career would be a logical step toward " +
            "the career you have chosen.",
       text: "The ||StepCareerName|| career would be a logical step toward the career " +
            "you have chosen.  If you choose ||StepCareerName|| instead of ||CareerName|| it would take less " +
            "college. After you begin work as a ||StepCareerName|| you can transition to become a(n) " +
            "||CareerName||.  All of the college required to be a ||StepCareerName|| will be applied to the " +
            "||CareerName||.  If this sounds like a good idea to you, close out this screen " +
            "and select the ||StepCareerName|| career option."
        },
        {type: "JobExperiencePartial", 
        shortText: "Working as a ||StepCareerName|| is a logical step toward the ||CareerName|| career.",
        text: "Working as a ||StepCareerName|| is a logical step toward the " +
            "||CareerName|| career.  You need to work as a ||StepCareerName|| for at least 2 years before " +
            "the college will be reduced.  Currently, you have worked ||NbrMonths|| months as a " +
            "||StepCareerName||.  If the reduced college sounds like a good idea to you, close " +
            "out this screen for now and return to change careers after you have completed 2 years "+
            "in your current career."
        },
        {type: "JobExperienceNone", 
        shortText: "The ||StepCareerName|| career would be a logical step toward ||CareerName||.",
        text: "The ||StepCareerName|| career would be a logical step toward " +
             "||CareerName||.   If you work as a ||StepCareerName|| for 2 years or more the college " +
             "required to be a ||CareerName|| will be reduced.  If this alternate career choice sounds " +
             "like a good initial step to you, close out this screen and then select the ||StepCareerNam|| " +
             "career option. "
        }

]

export default StepStoneText;