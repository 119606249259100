
export const calcMonthlyIncome = (assetTable, expenseTable, loanTable) => {
 
   var monthlyIncome = 0;
   var monthlyAssets = 0;
   var monthlyExpenses = 0;
   var monthlyLoans = 0;
      
   for (let i=0; i<assetTable.length; i++){
         if (assetTable[i].assetNbrMonths > 0 ||
             assetTable[i].assetNbrMonths == -1){
              monthlyAssets += +assetTable[i].assetAmt;
         }
   }

   for (let i=0; i<expenseTable.length; i++){
         if (expenseTable[i].expNbrMonths > 0 ||
             expenseTable[i].expNbrMonths == -1){
               monthlyExpenses += +expenseTable[i].expAmount;
         }
   }
   
   for (let i=0; i<loanTable.length; i++){
         if (loanTable[i].loanNbrMonths > 0 ||
             loanTable[i].loanNbrMonths == -1){
               monthlyLoans += +loanTable[i].loanAmt;
         }
   }
   monthlyIncome = (monthlyAssets - monthlyExpenses - monthlyLoans).toFixed(2);
   let incomeObj = {income: monthlyIncome, assets: monthlyAssets, expenses: monthlyExpenses, 
      loans: monthlyLoans}
             
   return incomeObj;
   
}





