const MoneyMgmtChallenge1Dataset = {
    items: {
        "item-1": { id: "item-1", content: "If you are not able to afford the new item you want, " +
                                "you can always buy it using a credit card. " +
                                "Then you can pay off the amount over the next several months.  ", 
                        answer: "column-1" },
        "item-2": { id: "item-2", content: "Know how much money you make and how much you spend " +
                                "each month (create a monthly budget).", 
                        answer: "column-2" },
        "item-3": { id: "item-3", content: "Wait and plan for retirement when you are older and " +
                                "retirement is just a few years away.",
                        answer: "column-1" },
        "item-4": { id: "item-4", content: "Invest in high-risk opportunities because the pay-off " +
                                "is very large and you might just hit it big..",
                        answer: "column-1" },
        "item-5": { id: "item-5", content: "Plan for your future, when do you want to retire and " + 
                                "how much will you need in savings to retire at this age?", 
                        answer: "column-2" },   
        "item-6": { id: "item-6", content: "Consider purchases carefully to ensure they " +
                                "are within your budget.", 
                        answer: "column-2" },  
        "item-7": { id: "item-7", content: "Save some money each month and start saving  " +
                                "when you’re young.", 
                        answer: "column-2" },
        "item-8": { id: "item-8", content: "Spend all of the money that you earn each month. " +  
                                "After all, you only live once, right?",
                        answer: "column-1" }, 
        "item-9": { id: "item-9", content: "Buy a new car every 3 years.  It will impress your " +
                                "family and friends and you can check out all of the new features/technology.",
                answer: "column-1" },   
    },

    columns: {
        "column-1": { id: "column-1", title: "Considerations", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7", "item-8", "item-9"] },
        "column-2": { id: "column-2", title: "Good Money Management Practices", itemIds: [] },
    },
    columnOrder: ["column-1", "column-2",]
}

export default MoneyMgmtChallenge1Dataset