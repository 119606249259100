
export const editType = (type, source, responseLit) => {

  var errorObj="";
  var typeValid = true;
  var errMessage = "";

  if (type === undefined){
    typeValid = false;
    errMessage = "Type is undefined";
  }else{
    if (source === "online"){
      if (type === "No-Type") {
        typeValid = false;
        errMessage = "Select Type";
      }
    }else{
      if (type === "flat" || type === "percent"){
        // type is valid
      }else{
        typeValid = false;
        errMessage = "Type must be 'flat' or 'percent'";
      }
    }
  }
  if (typeValid === false){
    errorObj = {fieldClass: responseLit, fieldName: "Type", fieldValue: type, message: errMessage}
  }
 
  return errorObj;
 
}