import calcRandomNumberWithMax from "../../../objects/CommonUse/calc_random_number_with_max";

export const determineItemName = (purchaseArr) => {

    if (purchaseArr === undefined){
        purchaseArr = [];
    }
    let itemNameArr = [];
      
    for (let i=0; i<purchaseArr.length; i++){
        if (purchaseArr[i].enjoyWithFriend === true){
            itemNameArr.push(purchaseArr[i].itemName);
        }
    }
  
    let itemIndx = 0
    let itemName = "";
    if (itemNameArr.length > 1){
        const nbrItems = itemNameArr.length;
        itemIndx = calcRandomNumberWithMax(nbrItems, true);
    }
    itemName = itemNameArr[itemIndx];
    return itemName;
   
}
export default determineItemName;

