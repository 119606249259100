import addToMonthlyCostArr from "./add_to_monthly_cost_arr";
import { mortgageCostsForHome } from "./mortgage_costs_for_home";
import monthlyRentAmtPaidByRoommate from "./monthly_rent_amt_paid_by_roommate";
import currentLoanRolledIntoNewLoan from "./current_loan_rolled_into_new_loan";

export const buildMonthlyAmountsArr = (changeType, currentHome, newHome, homeInflationCost) => {
   
       let monthlyCostArr = [];
       let totalMonthlyCost = 0;
       let monthlyCostItem = {};
       if ((changeType === "roommate-change" && currentHome.HomeType === "apartment") ||
           changeType === "apartment-apartment"){
              monthlyCostItem = addToMonthlyCostArr("Monthly Rent Amt", currentHome.FullRentAmt,
                   newHome.FullRentAmt, 1, "red", monthlyCostArr);
              if (currentHome.Roommates > 0 || newHome.Roommates > 0){
                     monthlyCostArr = addToMonthlyCostArr("Amount Roommate(s) Pays For Rent", 
                       currentHome.RoommateRentAmt, newHome.RoommateRentAmt, 
                       3, "black", monthlyCostArr);
                     monthlyCostArr = addToMonthlyCostArr("Rent Amount Paid by You",
                       currentHome.RentAmt, newHome.RentAmt, 4, "red", monthlyCostArr);
               }
               totalMonthlyCost = +newHome.RentAmt;
       }
       if (changeType === "roommate-change" && currentHome.HomeType === "house"){
              monthlyCostArr = addToMonthlyCostArr( "Monthly Mortgage Amt",  currentHome.MortgageAmt,
                     currentHome.MortgageAmt, 1, "red", monthlyCostArr);
              monthlyCostArr = monthlyRentAmtPaidByRoommate(currentHome, newHome, monthlyCostArr);
              totalMonthlyCost = +newHome.MortgageAmt - +newHome.RoommateShareOfMortgage;
       }
            
       if (changeType === "apartment-house"){
               monthlyCostArr = addToMonthlyCostArr("Monthly Rent Amt", currentHome.RentAmt,
                    "", 1, "red", monthlyCostArr);
               monthlyCostArr = mortgageCostsForHome(currentHome, newHome, monthlyCostArr, homeInflationCost);
               monthlyCostArr = addToMonthlyCostArr("Monthly HOA Amount",  "",
                    newHome.HomeHOAAmt, 10, "red", monthlyCostArr);
               monthlyCostArr = addToMonthlyCostArr("Renter's/Home Insurance Amount", 
                    currentHome.RentInsAmt, newHome.HomeInsAmt,  11, "red", monthlyCostArr); 
               let currentTotalMonthlyCost = +currentHome.RentAmt +
                   + +currentHome.RentInsAmt;
               totalMonthlyCost = +newHome.MortgageAmt + 
                   +newHome.HomeInsAmt + +newHome.PMIMonthly + 
                   +newHome.HomeHOAAmt - +newHome.RoommateShareOfMortgage;
               monthlyCostArr = addToMonthlyCostArr("Total Monthly Costs or Income", currentTotalMonthlyCost,
                   totalMonthlyCost,  30, "red", monthlyCostArr);
       }
       
       if (changeType === "house-house"){
                monthlyCostArr = currentLoanRolledIntoNewLoan(currentHome, newHome, monthlyCostArr);
                monthlyCostArr = addToMonthlyCostArr( "Home HOA Amount", currentHome.HomeHOAAmt,
                       newHome.HomeHOAAmt, 6, "red", monthlyCostArr);  
                monthlyCostArr = addToMonthlyCostArr("Home Insurance Amount", currentHome.HomeInsAmt,
                   newHome.HomeInsAmt,  7,  "red", monthlyCostArr);  
                monthlyCostArr = mortgageCostsForHome(currentHome, newHome, monthlyCostArr, homeInflationCost);
               
                totalMonthlyCost = +newHome.MortgageAmt +  +newHome.PMIMonthly + +newHome.HomeHOAAmt - 
                   +newHome.RoommateShareOfMortgage +  +newHome.HomeInsAmt;
           //     monthlyCostArr = addToMonthlyCostArr("Total Monthly Costs or Income", currentTotalMonthlyCost,
           //        totalMonthlyCost,  30, "red", monthlyCostArr);
       }
       if (changeType === "house-apartment"){
               newHome.RoommateShareOfMortgage = +newHome.RentAmt *
                   +newHome.Roommates;
               monthlyCostArr = mortgageCostsForHome(currentHome, newHome, monthlyCostArr, homeInflationCost);
               console.log("monthly Cost Arr #1: ", monthlyCostArr);
               if (+currentHome.HomeHOAAmt > 0){
                   monthlyCostArr = addToMonthlyCostArr("Home HOA Amount", 
                       currentHome.HomeHOAAmt, "",  7, "red", monthlyCostArr);  
               }   
               monthlyCostArr = addToMonthlyCostArr("Monthly Rent Amt", "",
                   newHome.FullRentAmt, 20, "red", monthlyCostArr);
               console.log("monthly Cost Arr #2: ", monthlyCostArr);
               monthlyCostArr = addToMonthlyCostArr("Insurance Amount", currentHome.HomeInsAmt,
                    newHome.RentInsAmt, 21, "red", monthlyCostArr);
               console.log("monthly Cost Arr #3: ", monthlyCostArr);
               let totalMonthlyAptAmt = +newHome.RentAmt + +newHome.RentInsAmt;
               monthlyCostArr = addToMonthlyCostArr("Total Monthly Amount", 
                   currentHome.RentAmt, totalMonthlyAptAmt, 30, "red", monthlyCostArr);
               console.log("monthly Cost Arr #4: ", monthlyCostArr);
                             
               totalMonthlyCost = +newHome.RentAmt + +newHome.RentInsAmt;
       }
       monthlyCostArr.sort((a, b) => (a.order > b.order) ? 1 : -1);
       const returnObj = {monthlyCostArr: monthlyCostArr, totalMonthlyCost: totalMonthlyCost};
       return returnObj;
}
export default buildMonthlyAmountsArr;
