import {Component} from 'react';
import "./GameViews/gameViews.css";


class GameBoardImageDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
           };
    }


    render() {
 
    return <>
        <span>
                {this.props.imageName != undefined && this.props.imageName != ""  ?
                    <div className={this.props.imageNameClass}>
                            {this.props.imageName}</div>
                :
                    null
                }
                <div className={this.props.imageClass}>
                    <img src={this.props.pictureSrc}
                        className={this.props.imageClass2} alt={this.props.pictureSrc} />
                </div>
        </span>
    </>
    }

    
}

export default GameBoardImageDisplay;