const BackgroundDataset =[
  // condName is the value of the condName in the event condition table - when this is "none", it is not a conditional backgrnd
  // backgroundURL: Value of the address in the 'asset' folder
  // pictureLabel: name of the value in the pictureInfoObj in gameInfo
  // backgroundType: value of backgroundType in the eventsCollection 
  {
      "backgroundType": "work",
      "pictureLabel": "jobBackground",
  },
  {
      "backgroundType": "home",
      "pictureLabel": "livingRoomImg",
    },
  {
    "condName": "own-a-dog",
    "backgroundURL": "/conditionalBackground/Back-Yard-Dog.jpg",
    "pictureLabel": "dogBackground",
    "backgroundType": "dogHouse",     
  },
  {
    "condName": "own-a-cat",
    "backgroundURL": "/conditionalBackground/Family-Room-Cat.jpg",
    "pictureLabel": "catBackground",
    "backgroundType": "catScratchPost",     
  },
  {
    "condName": "own-horse-apaloosa",
    "backgroundURL": "/conditionalBackground/horse-apaloosa.jpg",
    "pictureLabel": "horseBackground",
    "backgroundType": "horse",     
  },
  {
    "condName": "own-horse-bay",
    "backgroundURL": "/conditionalBackground/horse-bay.jpg",
    "pictureLabel": "horseBackground",
    "backgroundType": "horse",     
  },
  {
    "condName": "own-horse-patches",
    "backgroundURL": "/conditionalBackground/horse-patches.jpg",
    "pictureLabel": "horseBackground",
    "backgroundType": "horse",     
  },
  {
    "condName": "own-horse-brown",
    "backgroundURL": "/conditionalBackground/horse-brown.jpg",
    "pictureLabel": "horseBackground",
    "backgroundType": "horse",     
  },
  {
    "condName": "own-a-fishing-boat",
    "backgroundURL": "/conditionalBackground/Lake-FishingBoat.png",
    "pictureLabel": "boatBackground",
    "backgroundType": "fishingBoat",     
  },
  {
    "condName": "own-a-sail-boat",
    "backgroundURL": "/conditionalBackground/Lake-SailBoat.png",
    "pictureLabel": "boatBackground",
    "backgroundType": "sailBoat",     
  },
  {
      "condName": "own-a-speed-boat",
      "backgroundURL": "/conditionalBackground/Lake-SpeedBoat.png",
      "pictureLabel": "boatBackground",
      "backgroundType": "speedBoat",     
  },
  
  {
      "condName": "own-a-horse",
      "backgroundURL": "/conditionalBackground/Horse-Corral.jpg",
      "pictureLabel": "horseBackground",
      "backgroundType": "horse",  
  },
  
];

export default BackgroundDataset;