const ZooAnimalsDynamicDataset = [
        {gameName: "GoToZoo1",  column1Name: "Zoo Animals", columns:
                  [{columnName: "Aligator",
                        items: [{content: "", amount: "", picture: "aligator-001.png", order: 1},
                                {content: "", amount: "", picture: "aligator-002.png", order: 2}, 
                                {content: "", amount: "", picture: "aligator-003.png", order: 3}]
                   },
                         
                   {columnName: "Camel",
                        items: [{content: "", amount: "", picture: "camel-001.png", order: 1},  
                                {content: "", amount: "", picture: "camel-002.png", order: 2}, 
                                {content: "", amount: "", picture: "camel-003.png", order: 3}]
                   }, 
                                
                   {columnName: "Deer",
                        items: [{content: "", amount: "", picture: "deer-001.png", order: 1 },
                                {content: "", amount: "", picture: "deer-002.png", order: 2 },
                                {content: "", amount: "", picture: "deer-003.png", order: 3 }]
                   },
    
                   {columnName: "Elephant",
                        items: [{content: "", amount: "", picture: "elephant-001.png", order: 1 },
                                {content: "", amount: "", picture: "elephant-002.png", order: 2 },
                                {content: "", amount: "", picture: "elephant-003.png", order: 3 }]
                   },

                   {columnName: "Flamingo",
                   items: [{content: "", amount: "", picture: "flamingo-001.png", order: 1 },
                           {content: "", amount: "", picture: "flamingo-002.png", order: 2 },
                           {content: "", amount: "", picture: "flamingo-003.png", order: 3 }]
                   },

                   {columnName: "Giraffe",
                   items: [{content: "", amount: "", picture: "giraffe-001.png", order: 1 },
                           {content: "", amount: "", picture: "giraffe-002.png", order: 2 },
                           {content: "", amount: "", picture: "giraffe-003.png", order: 3 }]
                   },
                
                ]
        },


        {gameName: "GoToZoo2",  column1Name: "Zoo Animals", columns:
                  [{columnName: "Gorilla",
                        items: [{content: "", amount: "", picture: "gorilla-001.png", order: 1},
                                {content: "", amount: "", picture: "gorilla-002.png", order: 2},
                                {content: "", amount: "", picture: "gorilla-003.png", order: 3}, 
                                {content: "", amount: "", picture: "gorilla-004.png", order: 4}]
                }, 
                   {columnName: "Kangaroo",
                        items: [{content: "", amount: "", picture: "kangaroo-001.png", order: 1},
                                {content: "", amount: "", picture: "kangaroo-002.png", order: 2},
                                {content: "", amount: "", picture: "kangaroo-003.png", order: 3}, 
                                {content: "", amount: "", picture: "kangaroo-004.png", order: 4}]
                   },
                   {columnName: "Koala",
                   items: [{content: "", amount: "", picture: "koala-001.png", order: 1},
                           {content: "", amount: "", picture: "koala-002.png", order: 2},
                           {content: "", amount: "", picture: "koala-003.png", order: 3}, 
                           {content: "", amount: "", picture: "koala-004.png", order: 4}]
                 }, 
                   {columnName: "Ostrich",
                        items: [{content: "", amount: "", picture: "ostrich-001.png", order: 1},
                                {content: "", amount: "", picture: "ostrich-002.png", order: 2},
                                {content: "", amount: "", picture: "ostrich-003.png", order: 3}, 
                                {content: "", amount: "", picture: "ostrich-004.png", order: 4}]
                   },
                {columnName: "Panda",
                   items: [{content: "", amount: "", picture: "panda-001.png", order: 1},
                           {content: "", amount: "", picture: "panda-002.png", order: 2},
                           {content: "", amount: "", picture: "panda-003.png", order: 3}, 
                           {content: "", amount: "", picture: "panda-004.png", order: 4}]
              },
                {columnName: "Pelican",
                   items: [{content: "", amount: "", picture: "pelican-001.png", order: 1},
                      {content: "", amount: "", picture: "pelican-002.png", order: 2},
                      {content: "", amount: "", picture: "pelican-003.png", order: 3}, 
                      {content: "", amount: "", picture: "pelican-004.png", order: 4}]
                },
                {columnName: "Rhinocerus",
                        items: [{content: "", amount: "", picture: "rhinocerus-001.png", order: 1},
                                {content: "", amount: "", picture: "rhinocerus-002.png", order: 2},
                                {content: "", amount: "", picture: "rhinocerus-003.png", order: 3}, 
                                {content: "", amount: "", picture: "rhinocerus-004.png", order: 4}]
                   },
                {columnName: "Turtle",
                   items: [{content: "", amount: "", picture: "turtle-001.png", order: 1},
                           {content: "", amount: "", picture: "turtle-002.png", order: 2},
                           {content: "", amount: "", picture: "turtle-003.png", order: 3}, 
                           {content: "", amount: "", picture: "turtle-004.png", order: 4}]
                },  
                {columnName: "Kangaroo",
                        items: [{content: "", amount: "", picture: "zebra-001.png", order: 1},
                                {content: "", amount: "", picture: "zebra-002.png", order: 2},
                                {content: "", amount: "", picture: "zebra-003.png", order: 3}, 
                                {content: "", amount: "", picture: "zebra-004.png", order: 4}]
                   }, 

                ]}
        
]
 
export default ZooAnimalsDynamicDataset;