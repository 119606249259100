
const BackgroundImageDataset = [
  {
    "value": "home",
    "selection": "Background is Home"
  },
  {
    "value": "work",
    "selection": "Background is Work"
  },
  {
    "value": "funStuff-Dog",
    "selection": "Background is Yard w/ Dog House"
  },
  {
    "value": "funStuff-Cat",
    "selection": "Background is Family Room w/ Scratch Post"
  },
  {
    "value": "funStuff-Horse",
    "selection": "Background is Horse"
  },
  {
    "value": "funStuff-FishingBoat",
    "selection": "Background is Fishing Boat"
  },
  {
    "value": "funStuff-SailBoat",
    "selection": "Background is Sail Boat"
  },
  {
    "value": "funStuff-SpeedBoat",
    "selection": "Background is Speed Boat"
  },
 
]
   
export default BackgroundImageDataset;