import ConfigFile from "../../datasets/config_file";
import { createTableItem } from "../AssetsExpenses/create_table_item";

export const transportCostCalcAmt = (carMPG, currentYYYYMM) => {
     const commuteYearlyMiles = ConfigFile.carItems.commuteYearlyMiles;
     const weekendYearlyMiles = ConfigFile.carItems.weekendYearlyMiles;
     const gasCostPerGallon = ConfigFile.carItems.gasCostPerGallon;
     var expenseString=" ";
             
     var carMPGNbr = parseFloat(carMPG); 
     var commuteYearlyMilesNbr = parseFloat(commuteYearlyMiles);
     var totalWorkMilesPerMonth = (commuteYearlyMilesNbr/12);
     var totalWeekendMilesPerMonth = (weekendYearlyMiles/12);
     var totalGasAmtPerMonth = ((totalWorkMilesPerMonth + totalWeekendMilesPerMonth)/ carMPGNbr)
                * gasCostPerGallon;
     totalGasAmtPerMonth = totalGasAmtPerMonth.toFixed(2);
  
     expenseString = createTableItem("transport", "", currentYYYYMM, totalGasAmtPerMonth, "", false);
    
     return expenseString;
 }

