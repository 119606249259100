
export const getEventsInfo = (eventTitle, eventsData)=>{ 

   let eventFrequency = "";
   let eventCondition = "";
   let eventSkipCondition = "";
   let eventNbr = 0;
   for (let i=0; i<eventsData.length; i++){
       if (eventTitle === eventsData[i].title){
           eventFrequency = eventsData[i].frequency;
           eventCondition = eventsData[i].condition;
           eventSkipCondition = eventsData[i].skipCondition;
           eventNbr = i+1;
           break;
       }
   }
   
   let eventInfoObj = {eventNbr: eventNbr, eventFrequency: eventFrequency, eventCondition: eventCondition, 
      eventSkipCondition: eventSkipCondition};
   return eventInfoObj;
   
}
export default getEventsInfo;

