const UnlockedFeatureText =[
    {feature: "funStuff", 
        text: "This feature allows you to add fun things to your game such as " +
              "a pet, horse, boat and other items."  },
    {feature: "savings",
        text: "Saving money is an important part of money management.  If you " +
               "activate this feature, you can save money each month and " +
               "the game will add money (interest) each month to increase " +
               "your savings amount...watch your money grow!!"  },
    {feature: "creditCard",
        text: "Credit cards are almost essential in today's world.  If you " +
              "activate a credit card, then you can charge amounts paid in the " +
              "game to the credit card and pay off the card at the end of the month " +
              "or when you can."    },
    {feature: "investments",
        text: "This feature is not yet active in the game." },
]

export default UnlockedFeatureText;    