const FunStuffDataset =[
       {level: "1",  type: "Adopt a Pet", imageURL: "UnlockedFeatures/FunStuffLevel1.jpg", input: "name", 
              expItem: "Pet Expenses", titleType: "animal",
        options:  [{ category: "Dog", imageURL: "UnlockedFeatures/dogs.jpg", 
                     
                     list: [{type: "Black Lab", image: "UnlockedFeatures/dogLabrador.png", imageClass: "friend-game-img",
                             condName: "own-a-dog", backgroundURL: "/conditionalBackground/Back-Yard-Dog.jpg",
                             label: "Dog",
                             text: "This dog is a fun-loving dog that loves people, especially children.  The dog has a lot " +
                                   "of energy.  This dog loves to swim, especially on a hot day.  " +
                                   "The dog does sheds quite a bit, especially at certain times of the year. ",
                            ongoingCosts: [{desc: "Dog Food", amt: "60"}],
                            onetimeCosts: [{desc: "Adoption Fee", amt: "300"},
                                           {desc: "Dog Bed, Leash, Collar, Tag, Comb/Brush, Toys", amt: "120"}],
                            returnAmounts: [{desc: "Return Dog to a shelter", amt: "100", type: "expense"},
                                          {desc: "Excess Dog Food", amt: "60", type: "expense"}],
                            },
                            {type: "Mixed Breed", image: "UnlockedFeatures/dogMixedBreed.png", imageClass: "friend-game-img",
                            condName: "own-a-dog", backgroundURL: "/conditionalBackground/Back-Yard-Dog.jpg",
                            label: "Dog",
                            text: "This dog is a mix of several different breeds.  As you may guess " +
                                   "by the bone in it's mouth, this dog loves to play. The dog has a fairly " +
                                   "easy temperament and enjoys being around people.",
                            ongoingCosts: [{desc: "Dog Food", amt: "40"}],
                            onetimeCosts: [{desc: "Adoption Fee", amt: "300"},
                                           {desc: "Dog Bed, Leash, Collar, Tag, Comb/Brush, Toys", amt: "120"}],   
                            returnAmounts: [{desc: "Return Dog to a shelter", amt: "100", type: "expense"},
                                           {desc: "Excess Dog Food", amt: "60", type: "expense"}],
                            
                            },
                            {type: "Beagle", image: "UnlockedFeatures/dogBeagle.png", imageClass: "friend-game-img",
                            condName: "own-a-dog", backgroundURL: "/conditionalBackground/Back-Yard-Dog.jpg",
                            label: "Dog",
                            text: "This dog is so cute!  It would be a great pet for a family.  The beagle has a lot of " +
                                   "energy and will enjoy play time.  If left alone, this dog may become destructive " +
                                   "or howl.  This dog will bark at strangers but will likely end up greeting the " +
                                   "strangers with a tail wag",
                            ongoingCosts: [{desc: "Dog Food", amt: "40"}],
                            onetimeCosts: [{desc: "Adoption Fee", amt: "300"},
                                           {desc: "Dog Bed, Leash, Collar, Tag, Comb/Brush, Toys", amt: "120"}],
                            returnAmounts: [{desc: "Return Dog to a shelter", amt: "100", type: "expense"},
                                          {desc: "Excess Dog Food", amt: "60", type: "expense"}],
                            },
                            {type: "Chihuahua", image: "UnlockedFeatures/dogChihuahua.png", imageClass: "friend-game-img",
                            condName: "own-a-dog", backgroundURL: "/conditionalBackground/Back-Yard-Dog.jpg",
                            label: "Dog",
                            text: "This dog is small but mighty.  The dog enjoys company and attention. " +
                                  "They are a great lap-dog but also have a lot of energy and need time " +
                                  "to run around and play.  This dog tends to bark at strangers and may " +
                                  "snip at people if they become frightened.",
                            ongoingCosts: [{desc: "Dog Food", amt: "35"}],
                            onetimeCosts: [{desc: "Adoption Fee", amt: "300"},
                                           {desc: "Dog Bed, Leash, Collar, Tag, Comb/Brush, Toys", amt: "120"}],
                            returnAmounts: [{desc: "Return Dog to a shelter", amt: "100", type: "expense"},
                                          {desc: "Excess Dog Food", amt: "60", type: "expense"}],
                            } 
                           ],
                   },
                   { category: "Cat", imageURL: "UnlockedFeatures/cats.jpg", 
                     list:  [{type: "Siamese", image: "UnlockedFeatures/catSiamese.png", imageClass: "friend-game-img",
                            condName: "own-a-cat", backgroundURL: "/conditionalBackground/Family-Room-Cat.jpg",
                            label: "Cat",
                            text: "This siamese cat is very intelligent and has been taught to walk on a " +
                                   "leash.  The cat enjoys being around people and likes being groomed. " +
                                   "This cat tends to over-eat so be sure to monitor the amount that it is fed.",
                            ongoingCosts: [{desc: "Cat Food and Litter", amt: "60"}],
                            onetimeCosts: [{desc: "Adoption Fee", amt: "100"},
                                          {desc: "Vaccinations, Initial Veterinarian Services", amt: "360"},                                           
                                          {desc: "Bedding, Litter Box,  Carry Case, Food & Water Bowls, Toys", amt: "115"}],
                            returnAmounts: [{desc: "Return Cat to a shelter", amt: "100", type: "expense"},
                                          {desc: "Excess Cat Food and Litter", amt: "60", type: "expense"}],
                            },
                            {type: "Black Cat", image: "UnlockedFeatures/catBlack.png", imageClass: "friend-game-img",
                            condName: "own-a-cat", backgroundURL: "/conditionalBackground/Family-Room-Cat.jpg",
                            label: "Cat",
                            text: "This cat is very friendly and playful. It enjoys being outside but is also " +
                                  "very happy to be indoors.  The cat enjoys cuddling sometimes but also " +
                                  "likes to just rest in a sunny spot.",
                            ongoingCosts: [{desc: "Cat Food and Litter", amt: "53"}],
                            onetimeCosts: [{desc: "Adoption Fee", amt: "100"},
                                          {desc: "Vaccinations, Initial Veterinarian Services", amt: "360"},
                                          {desc: "Bedding, Litter Box,  Carry Case, Food & Water Bowls, Toys", amt: "115"}],
                            returnAmounts: [{desc: "Return Cat to a shelter", amt: "100", type: "expense"},
                                          {desc: "Excess Cat Food", amt: "60", type: "expense"}],
                            },
                            {type: "Persian", image: "UnlockedFeatures/catPersian.png", imageClass: "friend-game-img",
                            condName: "own-a-cat", backgroundURL: "/conditionalBackground/Family-Room-Cat.jpg",
                            label: "Cat",
                            text: "This persian cat is very gentle and loving.  The cat loves to be petted while  " +
                                  "sitting on your lap.  They do not enjoy rough play.  They are an indoor cat " +
                                  "as they tend to get overheated when outdoors.", 
                            ongoingCosts: [{desc: "Cat Food and Litter", amt: "53"}],
                            onetimeCosts: [{desc: "Adoption Fee", amt: "100"},
                                           {desc: "Vaccinations, Initial Veterinarian Services", amt: "360"},
                                           {desc: "Bedding, Litter Box,  Carry Case, Food & Water Bowls, Toys", amt: "115"}],
                            returnAmounts: [{desc: "Return Cat to a shelter", amt: "100", type: "expense"},
                                          {desc: "Excess Cat Food", amt: "60", type: "expense"}],
                            },
                            {type: "Tabby", image: "UnlockedFeatures/catTabby.png", imageClass: "friend-game-img",
                            condName: "own-a-cat", backgroundURL: "/conditionalBackground/Family-Room-Cat.jpg",
                            label: "Cat",
                            text: "This tabby cat is intelligent and playful.  It is a tad picky about people.  " +
                                  "It may be more affecitionate with some people than others.  It needs plenty of " +
                                  "exercise and enjoys playtime." ,
                            ongoingCosts: [{desc: "Cat Food and Litter", amt: "70"}],
                            onetimeCosts: [{desc: "Adoption Fee", amt: "100"},
                                           {desc: "Vaccinations, Initial Veterinarian Services", amt: "360"},
                                           {desc: "Bedding, Litter Box,  Carry Case, Food & Water Bowls, Toys", amt: "115"}],
                            returnAmounts: [{desc: "Return Cat to a shelter", amt: "100", type: "expense"},
                                           {desc: "Excess Cat Food", amt: "60", type: "expense"}],
                            } 
                           ],
                     },
              ]
       },  
       {level: "2",  type: "Buy a Horse", imageURL: "UnlockedFeatures/FunStuffLevel2.jpg", input: "none", 
              expItem: "Horse Expenses", titleType: "animal",
              options:  [{ category: "Horse", imageURL: "UnlockedFeatures/horses.jpg", 
              
                     list: [{type: "Apaloosa", image: "UnlockedFeatures/horse1.png", imageClass: "horse-game-img",
                            condName: "own-horse-apaloosa", backgroundURL: "/conditionalBackground/horse-appaloosa.jpg",
                            label: "Horse",
                            text: "",
                            ongoingCosts: [{desc: "Boarding Cost (Feed, Stable, Grooming)", amt: "500"}],
                            onetimeCosts: [{desc: "Purchase Price", amt: "5500"},
                                           {desc: "Saddle, Bridle, Blanket, Comb/Brush, Hoof Pick", amt: "390"},
                                           {desc: "Pre-Purchase Vet Exam", amt: "300"}],
                            returnAmounts: [{desc: "Sell Horse", amt: "5500", depPct: ".10", type: "income"},
                                            {desc: "Sell Tackle (saddle, bridle, etc.)", amt: "200", type: "income"}],
                            },
                            {type: "Bay", image: "UnlockedFeatures/horse2.png", imageClass: "horse-game-img",
                            condName: "own-horse-bay", backgroundURL: "/conditionalBackground/horse-bay.jpg",
                            label: "Horse",
                            text: "",
                            ongoingCosts: [{desc: "Boarding Cost (Feed, Stable, Grooming)", amt: "560"}],
                            onetimeCosts: [{desc: "Purchase Price", amt: "6000"},
                                           {desc: "Saddle, Bridle, Blanket, Comb/Brush, Hoof Pick", amt: "390"},
                                           {desc: "Pre-Purchase Vet Exam", amt: "336"}],
                            returnAmounts: [{desc: "Sell Horse", amt: "6000", depPct: ".10", type: "income"},
                                            {desc: "Sell Tackle (saddle, bridle, etc.)", amt: "200", type: "income"}],
                            },
                            {type: "Patches", image: "UnlockedFeatures/horse3.png", imageClass: "horse-game-img",
                            condName: "own-horse-patches", backgroundURL: "/conditionalBackground/horse-patches.jpg",
                            label: "Horse",
                            text: "",
                            ongoingCosts: [{desc: "Boarding Cost (Feed, Stable, Grooming)", amt: "475"}],
                            onetimeCosts: [{desc: "Purchase Price", amt: "4750"},
                                           {desc: "Saddle, Bridle, Blanket, Comb/Brush, Hoof Pick", amt: "380"},
                                           {desc: "Pre-Purchase Vet Exam", amt: "300"}],
                            returnAmounts: [{desc: "Sell Horse", amt: "4750", depPct: ".10", type: "income"},
                                            {desc: "Sell Tackle (saddle, bridle, etc.)", amt: "200", type: "income"}],
                            },
                            {type: "Brown", image: "UnlockedFeatures/horse4.png", imageClass: "horse-game-img",
                            condName: "own-horse-brown", backgroundURL: "/conditionalBackground/horse-brown.jpg",
                            label: "Horse",
                            text: "",
                            ongoingCosts: [{desc: "Boarding Cost (Feed, Stable, Grooming)", amt: "700"}],
                            onetimeCosts: [{desc: "Purchase Price", amt: "7000"},
                                           {desc: "Saddle, Bridle, Blanket, Comb/Brush, Hoof Pick", amt: "450"},
                                           {desc: "Pre-Purchase Vet Exam", amt: "380"}],
                            returnAmounts: [{desc: "Sell Horse", amt: "7000", depPct: ".10", type: "income"},
                                            {desc: "Sell Tackle (saddle, bridle, etc.)", amt: "225", type: "income"}],
                            },
                            ],
                     },
       
              ]
       },
       {level: "3",  type: "Buy a Boat", imageURL: "UnlockedFeatures/FunStuffLevel3.jpg", input: "",
       expItem: "Boat Expenses", titleType: "object", loanType: "Boat Loan",
       options:  [{ category: "Boat", imageURL: "UnlockedFeatures/boats.jpg", 
       
              list: [{type: "Fishing Boat", image: "UnlockedFeatures/boatFishing.png", imageClass: "",
                     condName: "own-a-fishing-boat",  "backgroundURL": "/conditionalBackground/Lake-FishingBoat.png",
                     label: "FishingBoat",
                     text: "",
                     ongoingCosts: [{desc: "Dry Stack Storage", amt: "150"},
                                    {desc: "Boat Insurance", amt: "43.75"}],
                     onetimeCosts: [{desc: "Purchase Price", amt: "35000"},
                                    {desc: "Boat Safety Equipment (life jacket, fire extinguisher, etc)", amt: "1160"},
                                    {desc: "Deck Lines", amt: '225'}],
                     returnAmounts: [{desc: "Sell Current Boat", amt: "35000", depPercent: ".10", type: "income"},
                                     ],
                     },
                     {type: "Sail Boat", image: "UnlockedFeatures/boatSail.png", imageClass: "",
                     condName: "own-a-sail-boat",  "backgroundURL": "/conditionalBackground/Lake-SailBoat.png",
                     label: "SailBoat",
                     text: "",
                     ongoingCosts: [{desc: "Dry Stack Storage", amt: "150"},
                                   {desc: "Boat Insurance", amt: "47.50"}],
                     onetimeCosts: [{desc: "Purchase Price", amt: "38000"},
                                   {desc: "Boat Safety Equipment (life jacket, fire extinguisher, etc)", amt: "1160"},
                                   {desc: "Deck Lines", amt: '225'}],
                     returnAmounts: [{desc: "Sell Your Current Boat", amt: "38000", depPercent: ".10", type: "income"},
                                   ],
                     },
                     {type: "Speed Boat", image: "UnlockedFeatures/boatSpeed.png", imageClass: "",
                     condName: "own-a-speed-boat",  "backgroundURL": "/conditionalBackground/Lake-SpeedBoat.png",
                     label: "SpeedBoat",
                     text: "",
                     ongoingCosts: [{desc: "Dry Stack Storage", amt: "150"},
                                   {desc: "Boat Insurance", amt: "48.75"}],
                     onetimeCosts: [{desc: "Purchase Price", amt: "39000"},
                                   {desc: "Boat Safety Equipment (life jacket, fire extinguisher, etc)", amt: "1160"},
                                   {desc: "Deck Lines", amt: '225'}],
                     returnAmounts: [{desc: "Sell Your Current Boat", amt: "39000", depPercent: ".10", type: "income"},
                                ],                    
                     },
                     ],
              },

       ]
}              

]
export default FunStuffDataset;