const unlockedFeaturesDialogDataset = [
// treat these as separate attributes
      {featureName: "setupSavings", policy: "new", commentTable: [
            {friendName:"Jennifer",  comment:"I don't know what to tell you. " +
            "I can't really see any reason to save a lot of money but it might be a good idea...?"},
            {friendName:"Charlie", comment: "I think you should save all of your salary. " +
            "The more you save the better, right?"}, 
            {friendName:"Cindy", comment: "You should save as much as you can... but... you need " +
            "to have enough to cover unexpected expenses each month.  " },
            {friendName:"Cameron", comment: "Why would you want to save money? " +
            "Just think of all the fun things you can do or the things you can buy if you spend " +
            "the money instead of saving it." }]
      },
      
      {featureName: "setupCreditCard", level: "", commentTable: [
            {friendName:"Jennifer",  comment:"I don't know much about credit cards " +
            "except they let you buy a lot of things and put off paying for them."},
            {friendName:"Charlie", comment: "I think you should definitely sign up for a credit card. " +
            "I have one and I just pay the minimum amount each month."}, 
            {friendName:"Cindy", comment: "Credit cards are great, but you really " +
            "should pay off the full balance each month.  Otherwise, you end up paying a lot " +
            "extra in interest on the unpaid amounts." },
            {friendName:"Cameron", comment: "Now we're talking!!  With a credit card you can buy " +
            "things you can't really afford.  As long as they cost less than your maximum " +
            "credit amount, you can just put it on the card!!!  Ya Hoo!!! "}]
      },
   
   
];

export default unlockedFeaturesDialogDataset;