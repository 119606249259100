import {Component} from 'react';


class MoneyMgmtStudentTestCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }   
}
  
render() {     
     this.pctCorrect = ((this.props.testInfo.nbrCorrect / this.props.testInfo.nbrQuestions) * 100).toFixed(0); 
return <>
      <tr>
            <td className="text-center">
                 {this.props.testInfo.dateAdded}
            </td>
            <td className="text-left" >
                 {this.props.testInfo.user}
            </td>
            <td className="text-center" >
                 {this.props.testInfo.nbrGames}
            </td>
            <td className="text-center" >
                 {this.props.testInfo.gameNbrMonths}
            </td>
            <td className="text-center" >
                 {this.props.testInfo.nbrCorrect}
            </td>     
            <td className="text-center" >
                 {this.pctCorrect}%
            </td>              
      </tr>         
           
   </>
  }
}

export default MoneyMgmtStudentTestCard;