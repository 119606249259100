const updateFunStuffData = {
    gameID: "888888888",
    itemArr: [
        {
          "levelName": "Testing fun stuff item",
          "category": "Dog",
          "type": "Chihuahua",
          "name": "dygbhtghghnm",
          "condName": "own-a-dog",
          "pictureLabel": "Dog",
          "pictureURL": "UnlockedFeatures/dogChihuahua.png",
          "backgroundURL": "/conditionalBackground/Back-Yard-Dog.jpg",
          "pictureClass": "friend-game-img",
          "startDate": "2024-03",
          
        }
      ],
      "dateAdded": "2024-02",
      "dateUpdated": "2024-02"
}

export default updateFunStuffData;