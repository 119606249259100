const creditCardParameters =[
  // this defines the credit card levels, and eligibility rqmts for each level
  {maxCreditCardAmt: 2500}, 
  {maxCreditCardAmt: 5000},
  {maxCreditCardAmt: 7500}, 
  {maxCreditCardAmt: 10000},
  {maxCreditCardAmt: 15000}, 
  {maxCreditCardAmt: 20000},
  {maxCreditCardAmt: 25000}

];

export default creditCardParameters;