const WaysToIncreaseCash =[
        "Click on the 'How are you Doing' button to the right and " +
            "review your 'Monthly Status'.  Are there expenses that you " +
            "can reduce by making different choices (ex: home and car)?",
        "Click on the 'Extra Credit' button (from main menu). " + 
            "This will give you an opportunity to earn more cash or increase " +
            "your salary. ",
        "Choose the 'Side Hustle' option on a game-card when it becomes " +
            "available.  This. will give you a chance to earn more cash."

]
export default WaysToIncreaseCash;