import ConfigFile from "../../../datasets/config_file";
import unlockedFeaturesDataset from "../datasets/unlocked_features_dataset";

export const checkForNewUnlockedFeatures = (gameMonths, cashAmt, monthlyIncome, unlockedFeatures) => {
    
    let unlockedFeaturesList = unlockedFeaturesDataset;
    let totalGameMonths = ConfigFile.gameStartItems.totalNbrGameMonths;  
    let gameMonthsPlayed = +totalGameMonths - +gameMonths;
    let newUnlockedFeature = {};
    let changed = false;  
    let newFeatureName = "";
    for (let i=0; i<unlockedFeaturesList.length; i++){
        let featureName = unlockedFeaturesList[i].featureName; 
        let unlockedFeature = unlockedFeatures.find(elem => elem.featureName === featureName);
       
        if (gameMonthsPlayed >= unlockedFeaturesList[i].minNbrMonths &&
            cashAmt >= unlockedFeaturesList[i].minCashAmt &&
            monthlyIncome >= unlockedFeaturesList[i].minMonthIncome){
                if (unlockedFeature == undefined){
                    newUnlockedFeature = {featureName: unlockedFeaturesList[i].featureName, 
                        setup: "none", level: unlockedFeaturesList[i].level}
                    changed = true;
                    unlockedFeatures.push(newUnlockedFeature);
                    newFeatureName = featureName;    
                }else{
                    if (unlockedFeaturesList[i].level > unlockedFeature.level){
                        changed = true;
                        // if the previous level of the feature has never been accepted, then change level number
                        //      but no new notification will happen
                        // if the previous level of the feature has been accepted, the level will change and it will 
                        //      setup will be set to "none" - this will force a notification but will not affect the
                        //      previous level's information.
                        unlockedFeature.level = unlockedFeaturesList[i].level;
                        unlockedFeature.setup = "none";
                    }
                }
        }
    }
   
    let featureObj = {unlockedFeatures: unlockedFeatures, changed: changed, newFeature: newFeatureName};
    return featureObj;
   
}





