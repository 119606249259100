import homeInsAmountsDataset from "../datasets/home_ins_amounts";
import { createTableItem } from "../../../objects/AssetsExpenses/create_table_item";

export const createNewHomeInsObj = (homeFullCost, gameDate, expenseTable) => {
        const homeInsRates = homeInsAmountsDataset;
        let homeInsAmt = 0;
        for (let i=0; i < homeInsRates.length; i++){
            if (homeInsRates[i].homeCost >= homeFullCost){
                homeInsAmt = homeInsRates[i].monthInsAmt;
                break;
            }
        }
        let homeInsObj = createTableItem("homeIns", "", gameDate, 
            homeInsAmt, "", false);
        expenseTable.push(homeInsObj);
        const returnObj = {homeInsAmt: homeInsAmt, expenseTable: expenseTable};
        return returnObj;
    }

export default createNewHomeInsObj;