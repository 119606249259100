import React from 'react'
import styled from 'styled-components'
import DragNDropItemsCommon from './drag_n_drop_item_common'
import { Droppable } from 'react-beautiful-dnd'

//const Container = styled.div`
//    margin: 1rem;
//    border: 1px solid black
//    border-radius: 5px;
//    width:500px;
//    display:flex;
//    flex-direction: column;
//    white-space: pre-wrap;
//    background-color: #002F8E;
//`;
//const Title = styled.h3`
//    padding: 0 1rem;
//    margin: 1rem 0; 
//`;

const ItemList = styled.div`
    padding: 0rem;
    background-color: ${props => (props.isDraggingOver ? '#d5f3ff' : 'inherit')};
    min-height:50px;
`;

function DragNDropColumnCommon(props) {
    var cookieName = props.cookieNamePfx + props.column.id
    var columnObject = {process: props.cookieNamePfx, column: props.column.id, items: props.items}
    sessionStorage.setItem(cookieName, JSON.stringify(columnObject));
            
    return (
       
        <div className="container" id={props.column.id} index={props.index}> 
         
            <div className={props.columnClass}>
                    <h6 className={props.columnTextClass}>{props.column.title}</h6>
                    <Droppable droppableId={props.column.id} type='item'>
                        {(provided, snapshot) => (
                            <ItemList className={props.multipleColumns}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                isDraggingOver={snapshot.isDraggingOver}
                            >
                                {props.items.map((item, index) => <DragNDropItemsCommon
                                    key={item.id} item={item} amountSw={props.amountSw}
                                    pictureSw={props.pictureSw} itemClass={props.itemClass}
                                    index={index} />)}
                                {provided.placeholder}
                            </ItemList>
                        )}
                    </Droppable>
            </div>
         
        </div>
              
       
    )
}

export default DragNDropColumnCommon