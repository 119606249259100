import {Component} from 'react';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';

class AboutForParents extends Component {
    
       render() {
            let screenParms = setScreenSizeParameters(this.props.screenSize, "forParents");
            this.className = screenParms.columnClass;
            this.textClass = screenParms.textSize + " mt-2";
       
        return <>
            <div className={this.className}>
                <div className="card mt-0 rounded-corners-lg height-max">
                     <button className="pages-back-button ml-4 mt-4" 
                            onClick ={()=> this.props.goBack()}>
                                    Back
                    </button>
                    <h3 className="mt-2 p-0 text-center">About Page</h3>
                    <h5 className="text-center">Parent Perspective</h5>
              
                    <div className="mt-2 mb-4 mx-4 overflow-scroll">
                        <div className="text-center">
                            <img className="medium_size_img" src={'./assets/About/moneyBag.png'}  alt="moneyBag"/>
                        </div>
                        <div className={this.textClass}>
                           Have you ever thought "I wish I had known that when I was young"?  If so,
                           perhaps that has been especially true with regard to money management.  
                           For many, the skills for managing salary and expenses and the active practice
                           of saving are learned as a consequence of tough life lessons; learned when it
                           is too late to correct the situation or too late to take full advantage of
                           the benefits.
                        </div>
                        <div className={this.textClass}>
                           This money management game is directed to students in 4th grade through high school.  While
                           it may seem that this is too young to start teaching these skills, we believe this 
                           is a critical age.  Students at this age begin making decisions and developing
                           habits that will have a significant influence on their long-term success.  For example,
                           at this age, individuals are already 
                           making decisions about which subjects they like and which ones they don't.              
                           These decisions will likely impact the careers/jobs that they can get. 
                        </div>
                        <div className={this.textClass}>                   
                           The MoneyTrain game teaches basic money management skills such as needs vs. wants, 
                           the importance of
                           ensuring that your salary is greater than monthly expenses, and the value of saving
                           money throughout your working life.  Players will also be introduced to
                           various job/career choices and the idea that an individual's skills and interests will
                           drive the job choice. 
                        </div>
                        <div className={this.textClass}>
                           This basic knowledge is intended to start individuals thinking about money
                           and about their future.  This will help them to make decisions now that will
                           lead to more success as they grow into adults.
                        </div>
                        <div className={this.textClass}>
                            Click on <b>"Contact Us"</b> in the game header to get access to request
                            a parent/teacher signon....OR.... click the person icon and the "Create Individual
                            Signon" button to get a player signon for you or your child (access to the game is free).
                            Once you have a parent/teacher signon, we encourage you to explore all of the processes
                            in the "Parent/Teacher" area.  There are several valuable resources that will help you
                            review your student/child's progress and supplement their learning in the game.
                        </div>
                    </div>
                   
                </div>        
            </div>
        </>
    }

}


export default AboutForParents;