import {Component} from 'react';

class HomeRoommateCard extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false};
    }

    render() {
        this.optionText = "no Roommate"
        if (this.props.slctHome.maxRoommates == 1){
            this.optionText = this.props.slctHome.maxRoommates + " Roommate";
         }else{
             this.optionText = this.props.slctHome.maxRoommates + " Roommates"
         }
    return <> 
           
           <option value={this.props.slctHome.maxRoommates}>{this.optionText}</option>
           
    </>
    }
}

export default HomeRoommateCard;