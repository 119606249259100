const MoneyMgmtJokes =
    [   {joke: "Why do goalkeepers have so much money in the bank?",
            response: "They are really good at saving."},
        {joke: "What did one penny say to the other penny?",
            response: "Let’s get together and make some cents."},
        {joke: "Where did the frog put his money?",
            response: "It’s in the river bank."},
        {joke: "Why didn’t I report my stolen credit card?",
            response: "The thief was spending less than me."},
        {joke: "What would you do if a bull charged you?",
            response: "I’d pay whatever it charged."},
        {joke: "How much money does a skunk have?",
            response: "One scent."},
        {joke: "Why did the woman put her money in her freezer?",
            response: "Because she wanted some cold hard cash."},
        {joke: "Why do wallets make so much noise?",
            response: "Because money talks."},
        {joke: "What did the football coach say to the broken vending machine?",
            response: "Give me my quarterback."},
        {joke: "What did the duck say after he went shopping?",
            response: "Put it on my bill!"},
        {joke: "What should you eat to become rich?",
            response: "Fortune cookies."},
        {joke: "What type of money do crabs use?",
            response: "Sand dollars."},
        {joke: "Why didn’t the quarter roll down the hill with the nickel?",
            response: "Because it had more cents."},
        {joke: "Why did the ghost get evicted from the graveyard?", 
            response: "Because he was dead broke."},
        {joke: "Why did the fishermen get such a great discount on a boat?",
            response: "Because it was on sail."},
        {joke: "Why did the Irish investor always have so much money?",
            response: "Because his money was always Dublin."},
        {joke: "How did the scarecrow get a raise?",
            response: "By standing out in his field."},
        {joke: "Why is money called dough?",
            response: "Because everybody kneads it."},
        {joke: "Why isn't ten cents worth as much as it used to be?",
            response: "The dimes have changed. "},
        {joke: "Why didn't the person want to donate to the local sushi shop?",
            response: "Because something about it just felt fishy."},
        {joke: "Did you hear about the clown who gave away all his money to charity?",
            response: "Talk about a nice jester."},
        {joke: "Why did the bank teller get fired?",
            response: "A customer asked if they could check their balance and the teller pushed them over."},
        {joke: "Why was the person reluctant to sign up for the 401K?",
            response: "Because there’s no way they could run that far."},
        {joke: "Why should you borrow money from pessimists?",
            response:  "Because they don’t expect to get it back."},
        {joke: "Did you hear about the coin shortage in America?",
            response: "We’re running out of common cents."},
        

    ]
  ;

export default MoneyMgmtJokes;