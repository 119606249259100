import ResponseCategoryList from "../datasets/response_category_dataset";

export const editResponseCategory = (category, source, responseLit) => {
  var errorObj="";
  var categoryValid = true;
  var errMessage = "";

  if (category === undefined){
    categoryValid = false;
    errMessage = "Category Undefined";
  }else{
    if (source === "online"){
      if (category === "No-Category") {
        categoryValid = false;
        errMessage = "Select Category";
      }
    }else{
      let categoryFound = ResponseCategoryList.some(elem => elem.value === category)
      if (categoryFound === false){
          categoryValid = false;
          errMessage = "Category not found in dataset";
      }
    }
  }
  if (categoryValid === false){
     errorObj = {fieldClass: responseLit, fieldName: "Category", fieldValue: category, message: errMessage};
  }
 
  return errorObj;
 
}