const GameElementHelp =[
        "Click on the 'Game Choices' button to change your career, " +
            "your home, or your car.",
        "Click on the 'Unlocked Features' button to activate " +
            "game features that you have earned. " +
            "You will be notified when features become " +
            "available to you.",
        "Click on the 'How are you Doing' button to the right and " +
            "review your 'Monthly Status'.  This displays " +
            "your monthly salary and expenses.",
        "Click on 'Finish Game' if you want to end this game " +
            "and start over."
]
export default GameElementHelp;