const updateConditionsData = {
    conditionsTable:
    [
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "electrical-engineer",
          "conditionClass": "career"
        },
        {
          "condName": "test-condition-car-info",
          "conditionClass": "game"
        },
        {
            "condName": "test-update-condition",
            "conditionClass": "game"
          }
      ],
}

export default updateConditionsData;