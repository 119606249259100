
export const calculateExpenseAmtByType = (expenseTable, expType) => {
      let totalExpAmt = 0;
      for (let i=0; i<expenseTable.length; i++){
            if (expenseTable[i].expItem === expType){
                  totalExpAmt += +expenseTable[i].expAmount;
            }
      }

   return totalExpAmt;
   
}

export default calculateExpenseAmtByType;
