import {Component} from 'react';
import './Special/special.css'

class EventListResponseCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      alphaArr: ["a.", "b.", "c.", "d.", "e.", "f.", "g.", "h.", "i.", "j."]
    }    
}
  

render() {
      if (this.props.index < 10){
            this.lineNbr = this.state.alphaArr[this.props.index];
      }else{
            this.lineNbr = "z.";
      }     
return <>
      <span className="text-left">
            <div>
                  <div className="mx-2 mt-2"> <b> {this.lineNbr} Button Text:</b>&nbsp; {this.props.response.btnText}</div>
                  <div className="mx-4"> <b> Closure Text:</b>&nbsp;{this.props.response.closureText}</div>
                  <div className="mx-4"> <b> Response Odds:</b>&nbsp;{this.props.response.odds}&nbsp;&nbsp;&nbsp;&nbsp;
                     <b> Mood Points:</b>&nbsp;{this.props.response.moodPoints}&nbsp;&nbsp;&nbsp;&nbsp;
                     <b>Text Length:</b>&nbsp;{this.props.response.closureText.length}</div>
                  
            </div>
      </span>     
           
   </>
  }
}

export default EventListResponseCard;