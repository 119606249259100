import "./GameBoardActivities/puzzleimg.css";
import {Component} from 'react';
import Button from 'react-bootstrap/Button';
import LetterListDataset from '../../datasets/letter_list_dataset';
import AlphabetCard from './alphabet_card';
import ImageCard from "../shared-components/image_card";
import LetterCard from "./letter_card";
import HouseBuildDataset from "../../datasets/house_build_dataset";
import { displayFriendComment } from "./objects/display_friend_comment";
import {determineFriendComment} from "./objects/determine_friend_comment";

class WordPuzzleModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         puzzleComplete: true,
         imgDir: "./assets/Activities/" + this.props.activityCategory + "/",
         startHintTimer: false,
         disableStartButton: false,
         displayHint: false,
         displayFriend: false,
         commentNbr: 0,
         startButtonType: {type: "start"},
        };  
        this.wordLetterArr = [];
        this.indexList = [];
        this.underscoreArr = [];
        this.housesBuiltArr = [];
        this.letterFoundText = "How many words can you get in the time allowed?";
        this.hintText = "";
        this.wordSelected = "";
        this.houseURL = "";
        this.gameScore = 0;
        this.nbrHousesComplete = 0;
        this.score = this.props.score;
        this.nbrWordsComplete = 0;
        this.nbrAttempted = 0;
        this.letterClass = "mx-2 px-1 font-size-very-large use-font-comic-sans text-white set-width-100";
        this.wordList = JSON.parse(JSON.stringify(this.props.wordList.wordListArr));
        this.initialSetup();  

  }

 initialSetup(){
    this.getAlphabet();
    this.disableAlphabetButtons();
    switch (this.props.modalType){
         case "TutorGrammar":
            this.backgroundClass = "tutor_chalkboard_background mt-0";
            this.scoreTextClass = "col-sm-5 mt-4 text-left text-white";
            this.hintTextClass = "ml-4 text-white use-font-comic-sans text-center";
            this.blank = "blankTutor.png";
            this.rectangle = "rectangleTutor";
            break;
        case "BuildHome":
            this.backgroundClass = "build_home_background mt-0";
            this.scoreTextClass = "col-sm-5 mt-4 text-left";
            this.hintTextClass = "ml-4 use-font-comic-sans text-center";
            this.blank = "blankBuildHome.png";
            this.rectangle = "rectangleBuildHome";
            break;
        case "SoupKitchen":
            this.backgroundClass = "soup_kitchen_background mt-0";
            this.scoreTextClass = "col-sm-5 mt-4 text-left";
            this.hintTextClass = "ml-4 use-font-comic-sans text-center";
            this.blank = "blankSoupKitchen.jpg";
            this.rectangle = "rectangleSoupKitchen";
            break;
        default:
            break;
    }

    let score = this.props.score;
    if (score !== undefined){
        if (this.props.score.points === ""){
             this.gameScore = 0;
         }else{
            this.gameScore = this.props.score.points;
        }
        if (isNaN(this.props.score.gamesRemaining)){
            this.nbrWordsComplete = 0;
            this.nbrAttempted = 0;
        }else{
            this.nbrWordsComplete = this.props.score.nbrCompleted;
            this.nbrAttempted = this.props.score.nbrAttempted;
        }
    }
    if (this.props.modalType === "BuildHome"){
        this.determineHouseURL();
    }
    this.setState({setupComplete: true});
 }

 getAlphabet(){
      let fullAlphabet = LetterListDataset;
      this.letterCharArr = [];
      for (let i=0; i<fullAlphabet.length; i++){
          let letterObj = {type: "letter", index: i, letter: fullAlphabet[i].letter, disable: false,
            letterClass: this.letterClass };
          this.letterCharArr.push(letterObj);
      }
  }
  
  disableAlphabetButtons(){
     for (let i=0; i< this.letterCharArr.length; i++){
            this.letterCharArr[i].disable = true;
     }
  }

  checkTimer(gameInfo){
    if (this.props.stopActivity === true){
          this.setScoreValues();
          this.score.type = "finish";
          this.props.onGameOver(this.score); 
    }else{
          if (gameInfo.type === "start"){
            this.getNewWord();
          }else{
              this.checkLetter(gameInfo);
          }
    }
  }

  getNewWord(){
      this.setState({disableStartButton: true});
      this.wrongLetterCount = 0;
      this.wordLetterArr = [];
      this.underscoreArr = [];
      let wordChoice = Math.floor((Math.random() * this.wordList.length));
      if (this.wordList.length === 0){
        this.wordList = JSON.parse(JSON.stringify(this.props.wordList.wordListArr));
      }
      this.wordSelected = this.wordList[wordChoice].word;
      this.wordSlctLowerCase = this.wordSelected.toLowerCase();
      this.wordLength = this.wordSelected.length;
      let letterClass = ("very_very_small_img2");
      var underscoreURL = "";
      this.actualWordLength = 0;
      for (let i=0; i<this.wordSelected.length; i++){
          let found = false;
          if (this.wordSelected[i] === " "){
                found = true;
                underscoreURL = this.state.imgDir + this.blank;
          }else{
                underscoreURL = this.state.imgDir + "Underscore.png"
                this.actualWordLength += 1;
          }
          let wordObj = {found: found, letter: " ", letterClass: this.rectangle};
          this.wordLetterArr.push(wordObj);
          let underscoreObj = {imageURL: underscoreURL, imageClass: letterClass};
          this.underscoreArr.push(underscoreObj);
      }
      this.score.maxScore = +this.score.maxScore + +this.actualWordLength;
      this.hintText = this.wordList[wordChoice].hint;
      this.enableAlphabetButtons();
      this.wordList.splice(wordChoice,1);
      this.hintCompleteText = "Can you guess which letters are in this word?"
      this.props.startTimer();
      this.setState({displayHint: true, displayFriend: false});
    }

  enableAlphabetButtons(){
    for (let i=0; i< this.letterCharArr.length; i++){
         this.letterCharArr[i].disable = false;
    }
  }

  displayHintText(){
      this.setState({displayHint: true, startHintTimer: false});
  }
 

  checkLetter(alphabetLetter){
        let letterIndex = alphabetLetter.index;
        this.letterCharArr[letterIndex].disable = true;
        this.letterCharArr[letterIndex].buttonClass = "button-for-disabled-letters very_very_small_img";
        let letterSelected = this.letterCharArr[letterIndex].letter;
        if (this.wordSlctLowerCase.includes(letterSelected)){
            this.findLetter(letterSelected)
            this.letterFound = true;
            for (let i=0; i<this.indexList.length; i++){
                let wordIdx = this.indexList[i].index;
                this.wordLetterArr[wordIdx].found = true;
                this.wordLetterArr[wordIdx].letterClass = this.letterClass;
                if (this.indexList[i].case === "lowerCase"){
                    this.wordLetterArr[wordIdx].letter = this.letterCharArr[letterIndex].letter;
                }else{
                    let testLetter = this.letterCharArr[letterIndex].letter;
                    let UCLetter = testLetter.toUpperCase();
                    this.wordLetterArr[wordIdx].letter = UCLetter;
                }
            }
            let someLettersNotFound = this.wordLetterArr.some(elem => elem.found === false)
            if (!someLettersNotFound){
                this.hintCompleteText= "Congratulations!!!   You solved the puzzle.";
                this.letterFoundText = "Click the 'Start' button to select another word."
                this.disableAlphabetButtons();
                this.wordComplete();
                this.setState({dislpayText: false, startHintTimer: false});
            }
        }else{
            this.wrongLetterCount += 1;
            this.letterFoundText = "letter selected not found in this word";
        }
  }

  wordComplete(){
     let percentCorrect = (this.actualWordLength - this.wrongLetterCount)/this.actualWordLength;
     this.gameScore = +percentCorrect.toFixed(0) * this.actualWordLength + +this.gameScore;
     this.nbrWordsComplete = this.nbrWordsComplete + 1;
     this.setState({disableStartButton: false});
     if (this.props.modalType === "BuildHome"){
        this.determineHouseURL();
     }
     let remainder = +this.nbrWordsComplete % 3;
     let displayFriendSw = false;
     if (remainder === 0){
         displayFriendSw = 
             displayFriendComment(this.props.activityCategory, this.props.friendInfo);
         this.setState({displayFriend: displayFriendSw});
     }
     if (displayFriendSw === true){
          this.getFriendComment();
     }
  }


  determineHouseURL(){
    let houseBuildDataset = HouseBuildDataset;
    let houseIdx = this.nbrWordsComplete - (this.nbrHousesComplete * HouseBuildDataset.length) - 1;
    if (houseIdx >= houseBuildDataset.length){
       let houseBuiltObj = {imageURL: (this.state.imgDir + "houseComplete.png"), 
           imageClass: "very_very_small_img2"}
       this.housesBuiltArr.push(houseBuiltObj);
       this.nbrHousesComplete += 1;
       this.houseURL = this.state.imgDir + houseBuildDataset[0];
    }else{
       this.houseURL = this.state.imgDir + houseBuildDataset[houseIdx];
    }
  }

  getFriendComment(){
    let commentTable = this.props.friendInfo.commentTable;
    let commentObj = determineFriendComment(commentTable, this.state.commentNbr);
    this.comment = commentObj.comment;
    this.setState({commentNbr: commentObj.commentNbr});
  }  

  findLetter(letter){
      this.indexList = [];
      var caseType = "";
      //  LC - lower case -->  MC - Mixed Case
      let wordArrayLC = this.wordSlctLowerCase.split("");
      let wordArrayMC = this.wordSelected.split("");
      for (let i=0; i < wordArrayLC.length; i++){
           if (letter === wordArrayLC[i]){
               if (wordArrayLC[i] === wordArrayMC[i]){
                    caseType = "lowerCase";
               }else{
                    caseType = "upperCase";
               }
               this.indexList.push({index: i, case: caseType});
           }
      }
  }

  closeGame(){
    this.setScoreValues();
    if (this.state.stopActivity === true){
        this.score.type = "finish";
    }else{
        this.score.type = "done";
    }
    this.props.onGameOver(this.score); 
  }

  setScoreValues(){
    this.score.type = "finish";
    this.score.points = this.gameScore;
    this.score.nbrCompleted = this.nbrWordsComplete;
    this.score.nbrAttempted = this.score.nbrCompleted;
    this.score.gamesRemaining = this.score.gamesRemaining - this.score.nbrCompleted;
    if (this.score.gamesRemaining < 0){
        this.score.gamesRemaining = 0;
    }
    if (this.score.gamesRemaining === 0){
        this.score.newLevelEarned = true;
    }
  }

  render() { 
     
      this.alphabetList = this.letterCharArr.map((alphabetLetter,index) =>
          <AlphabetCard alphabetInfo={alphabetLetter} key={index}
              onClick={() => this.checkTimer(alphabetLetter)}  />
             );
    
      this.wordDisplay = this.wordLetterArr.map((letterList, index) =>
              <LetterCard imageInfo={letterList} key={index}/> 
            );
      this.underScores= this.underscoreArr.map((underScoreList,index)  =>
              <ImageCard imageInfo={underScoreList} key={index}/> 
          );
           
      if (this.props.modalType === "BuildHome"){
            this.housesBuilt= this.housesBuiltArr.map((housesList)  =>
              <ImageCard imageInfo={housesList}/> 
            );
      }else{
            this.housesBuilt = "";
      }
      
  return <>
    
    <div className="row">
            <div className="col-sm-6 ml-4 mt-0 mb-0">{this.letterFoundText}</div>
            <div className="col-sm-4 mx-0 very-very-small-img2">{this.housesBuilt}</div>
    </div>
    
    <div className={this.backgroundClass}>
        <div className="row">
            
            <div className="col-sm-3 ml-4">  
                <div className="row">
                    <div className="col-sm-3 mt-2 ml-1">
                        <Button variant="success" disabled={this.state.disableStartButton}
                            onClick={() => this.checkTimer(this.state.startButtonType)}>Start</Button>
                    </div>
                    <div className="col-sm-3 ml-4 mt-2">
                        <Button  variant="success" 
                                onClick={() => this.closeGame()}>Done</Button>
                    </div>
                </div>
             
            </div> 
            <div className="col-sm-3 mt-2">
                    {this.props.modalType === "BuildHome" && this.nbrWordsComplete > 0 ?
                        <div className="mx-4 mt-4 text-right">
                            <img src={this.houseURL} className="house_build_img" alt="house"/>
                        </div>
                    :
                       null
                    }
            </div>

            <div className={this.scoreTextClass}>
                <div className="ml-4 text-left font-weight-bold">{this.props.wordList.titleText}</div> 
                <div className="ml-4 text-left"><b>Nbr Words Complete: &nbsp;&nbsp;&nbsp;&nbsp; </b>
                    {this.nbrWordsComplete} </div>
                  
            </div>
        </div>

              
        {this.state.displayHint ? 
              <div className={this.hintTextClass}><b>Hint:</b>{this.hintText}</div>
        :
              <div className={this.hintTextClass}>{this.hintCompleteText}</div>
        }
        <div className="p-0" >
            {this.wordDisplay.length === 0 ?
                <div className="skip-space-wordList"></div>
            :    
                <div className="text-center">
                    {this.wordDisplay}
                </div>
            }
            {this.underScores.length === 0 ?
                <div className="skip-space-underScore"></div>
            :
                <div className="text-center">  
                    {this.underScores}
                </div>
            }
            <div className="ml-4">
                {this.alphabetList}
            </div>
        </div>

        {this.state.displayFriend   ?
                  <span>
                      <div>
                          <img src={this.props.friendInfo.friendImgURL}  className="friend-puzzle-positionWordPuzzle-img" 
                              alt="friendPicture"/>
                      </div>  
                      <div className="speech-wordPuzzle-activity right" >
                          {this.comment}
                      </div>
                  </span>
                :
                    null
                }
        
     </div>
    </>
  }

 
}
export default WordPuzzleModal;