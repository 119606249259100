import {Component} from 'react';
import QuestionListDisplayCard from '../admin/question_list_display_card';
import PlayerReviewDetailCard from './player_review_detail_card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


class DisplayGameReviewsModal extends Component {
  constructor(props) {
    super(props);
    this.state={
        questionArr: [],  
        commentArr: [],
      }    
      this.feedbackProcessed = false;
      this.questionArr = [];
      this.commentArr = [];
      this.roleArr = [];
      this.allQuestionTotal = 0;
      this.allQuestionCount = 0;
      this.playerScoreArr = [];
}

processGameFeedback() {
     for (let i=0; i<this.props.gameFeedback.length; i++){
        // currently not displaying roles and not sure it would be meaningful...
        // may want to separate displays by role (or have a role parameter)
          let ratingDate = this.determineRatingDate(this.props.gameFeedback[i].dateAdded, this.props.gameFeedback[i].feedbackDate);
          this.processQuestionList(this.props.gameFeedback[i].questionList, this.props.gameFeedback[i].username,
             ratingDate);
          this.processCommentList(this.props.gameFeedback[i].commentList, this.props.gameFeedback[i].username);
     }
     this.avgRating = (this.allQuestionTotal / this.allQuestionCount).toFixed(1);
     this.sortComments("rating");
}

determineRatingDate(dateAdded, feedbackDate){
      let ratingDate = dateAdded;
      if (feedbackDate !== undefined){
          ratingDate = feedbackDate
      }
      const dateArr = ratingDate.split("/");
      //  make sure date has leading zeros in month and day
      if (+dateArr[1].length < 2){
            dateArr[1] = "0" + dateArr[1];
      }
      if (+dateArr[2].length < 2){
            dateArr[2] = "0" + dateArr[2];
      }
      ratingDate = dateArr.join('/');
      return ratingDate;
}

processQuestionList(questions, playerName, ratingDate){
    for (let i=0; i<questions.length; i++){
        // skipping question about whether there are too many negative things on cards
        if (questions[i].questionNbr !== 6){
          let questionIndx = 99;
          for (let j=0; j<this.questionArr.length; j++){
              if (questions[i].question === this.questionArr[j].question ){
                  questionIndx = j;
                  break;
              }
              
          }
          //  if questionIndx is 99, then this is a new question
          if (questionIndx === 99){
              let questionObject = {question: questions[i].question,
                score1Count: 0, score2Count: 0, score3Count: 0, score4Count: 0, score5Count: 0,
                scoreTotal: 0, totalScoreCount: 0 }
              this.questionArr.push(questionObject);
              questionIndx = this.questionArr.length - 1;
          }
          this.setScoresInQuestionArr(questions[i], this.questionArr[questionIndx]);
          this.updatePlayerScores(questions, playerName, ratingDate);
        }
    }
  
}

setScoresInQuestionArr(questionReply, questionArr){
      switch (+questionReply.answer){
          case 1: 
             questionArr.score1Count += 1;
             break;
          case 2:
              questionArr.score2Count += 1;
              break;
          case 3:
              questionArr.score3Count += 1;
              break;
          case 4:
              questionArr.score4Count += 1;
              break;
          case 5:
              questionArr.score5Count += 1;
              break;
          default:
              break;
      }
      questionArr.scoreTotal += +questionReply.answer;
      questionArr.totalScoreCount += 1;
      this.allQuestionTotal += +questionReply.answer;
      this.allQuestionCount += 1;
}

updatePlayerScores(questions, playerName, ratingDate){
    let totalPlayerScore = 0;
    let totalPlayerCount = 0;
    for (let i=0; i<questions.length; i++){
        totalPlayerScore += +questions[i].answer;
        totalPlayerCount += 1;
    }
    let avgPlayerScore = (totalPlayerScore / totalPlayerCount).toFixed(1);
    let playerScoreObj = {playerName: playerName, avgRating: avgPlayerScore, ratingDate: ratingDate};
    this.playerScoreArr.push(playerScoreObj);
}

processCommentList(comments, playerName){
    const playerIndx = 
      this.playerScoreArr.findIndex(elem => elem.playerName === playerName);
    let playerCommentArr = [];
    for (let i=0; i<comments.length; i++){
        let playerCommentObj = {questionText: comments[i].question, questionAnswer: comments[i].answer};
        playerCommentArr.push(playerCommentObj); 
    } 
    let commentObj = {playerName: playerName, avgRating: this.playerScoreArr[playerIndx].avgRating,
      playerCommentArr: playerCommentArr, ratingDate: this.playerScoreArr[playerIndx].ratingDate};
   
    this.commentArr.push(commentObj);
}

sortComments(sortParm){
    switch (sortParm){
        case "rating":
            (this.commentArr).sort((a, b) => ((a.avgRating + a.ratingDate) > (b.avgRating + b.ratingDate)) ? -1 : 1);
            break;
        case "date":
            (this.commentArr).sort((a, b) => ((a.ratingDate + a.avgRating) > (b.ratingDate + b.avgRating)) ? -1 : 1);
            break;
        default:
            break;
    }
     
}

getSortParameter(){
    const sortParm = document.getElementById("sortParm").value;
    this.sortComments(sortParm);
}
   
render() {
      if (this.feedbackProcessed === false){
        this.processGameFeedback();
        this.feedbackProcessed = true;
      }
        this.questionsInfo = this.questionArr.map((questions, index) =>
          <QuestionListDisplayCard questions={questions}  key={index}  
           index={index}/>
              );
        this.playerComments = this.commentArr.map((comments, index) =>
            <PlayerReviewDetailCard comment={comments}  key={index}  
            index={index}/>
              );
                
return <>
   <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="lg" scrollable="true">
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Player Reviews - Summarized Game Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="mx-4 mt-2 text-center">
                The information displayed on this page reflects the player/student feedback.
            </div>
            <h6 className="mx-4 mt-4"><b>Total Rating:&nbsp;&nbsp;</b> {this.avgRating}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b>Total Nbr Reviews:&nbsp;&nbsp;</b>{this.props.gameFeedback.length}</h6>
            <div className="divider-line"></div>
            <table className="mx-4 mt-2 text-center">
                <tbody>
                  <tr>
                    <th>Question</th>
                    <th>&nbsp;&nbsp;1&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;2&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;3&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;4&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;5&nbsp;&nbsp;</th>
                    <th>&nbsp;Nbr Responses&nbsp;</th>
                    <th>&nbsp;Avg Score&nbsp;</th>
                  </tr>
                  {this.questionsInfo}
                </tbody>
            </table>
            <div className="divider-line"></div>
            <h6 className="text-center mt-4 font-weight-bold">   Detail Reviews &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label className="ml-5 font-weight-bold" htmlFor="player">Sort Reviews By:&nbsp;&nbsp;</label>
                      <select className="btn btn-sm btn-outline-secondary dropdown-toggle" id="sortParm"
                            onChange={() => this.getSortParameter()}>
                        <option value="rating">Rating (default)</option>
                        <option value="date">Rating Date</option>
                  </select>
            </h6> 
            <div className="mt-2">
                <table className="table table-hover">
                  <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Rating</th>
                        <th>Rating Date</th>
                        <th></th>
                    </tr>
                        {this.playerComments} 
                  </tbody>
                </table>
            </div>
            </Modal.Body>
          <Modal.Footer className="backgroundTopBottom">
              <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"
                alt="train"/>
              <Button  variant="info"   onClick={() => this.props.onClose()}>
                   Close
              </Button>
          </Modal.Footer>
          </Modal>
  </>
  }


}

export default DisplayGameReviewsModal;