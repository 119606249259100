const FoodBankDynamicDataset = {

        //  each column must contain at least 1/2 of items that are required in parameter
        gameName: "FoodDrive",  column1Name: "Food Donations", columns:
                  [{columnName: "Canned Vegetables",
                        items: [{content: "", amount: "", picture: "canOfPeas.png", order: 1},
                                {content: "", amount: "", picture: "corn.png", order: 1},
                                {content: "", amount: "", picture: "beans.png", order: 1},
                                {content: "", amount: "", picture: "canOfPeas.png", order: 1},
                                {content: "", amount: "", picture: "beans.png", order: 1},
                                {content: "", amount: "", picture: "beans.png", order: 1},
                                {content: "", amount: "", picture: "corn.png", order: 1},
                ]
                   },
                         
                   {columnName: "Canned Soup",
                        items: [{content: "", amount: "", picture: "ChickenNoodleSoup.png", order: 1},
                                {content: "", amount: "", picture: "tomatoSoup.png", order: 1},
                                {content: "", amount: "", picture: "ChickenNoodleSoup.png", order: 1},
                                {content: "", amount: "", picture: "beefBarleySoup.png", order: 1},
                                {content: "", amount: "", picture: "soup1.png", order: 1},
                                {content: "", amount: "", picture: "tomatoSoup.png", order: 1},
                                {content: "", amount: "", picture: "tomatoSoup.png", order: 1},
                ]
                   }, 
                                
                   {columnName: "Canned Fruit",
                        items: [{content: "", amount: "", picture: "mixedFruit.png", order: 1 },
                                {content: "", amount: "", picture: "canOfPeaches.png", order: 1 },
                                {content: "", amount: "", picture: "canOfPeaches.png", order: 1 },
                                {content: "", amount: "", picture: "canOfPeaches.png", order: 1 },
                                {content: "", amount: "", picture: "pineApple.png", order: 1 },
                                {content: "", amount: "", picture: "mixedFruit.png", order: 1 },
                                {content: "", amount: "", picture: "pineApple.png", order: 1 },
                                {content: "", amount: "", picture: "mixedFruit.png", order: 1 },
                        ]
                   },
    
                   {columnName: "Other",
                        items: [{content: "", amount: "", picture: "macaroni.png", order: 1 },
                                {content: "", amount: "", picture: "peanutButter.png", order: 1 },
                                {content: "", amount: "", picture: "spaghetti.png", order: 1 },
                                {content: "", amount: "", picture: "spaghetti.png", order: 1 },
                                {content: "", amount: "", picture: "peanutButter.png", order: 1 },
                                {content: "", amount: "", picture: "spaghetti.png", order: 1 },
                                {content: "", amount: "", picture: "spaghetti.png", order: 1 },

                        ]
                   },

                {columnName: "Baking Items",
                        items: [{content: "", amount: "", picture: "flour.png", order: 1 },
                                {content: "", amount: "", picture: "sugar.png", order: 1 },
                                {content: "", amount: "", picture: "salt.png", order: 1 },
                                {content: "", amount: "", picture: "bakingSoda.png", order: 1 },
                                {content: "", amount: "", picture: "bakingPowder.png", order: 1}
                        ]
                },

                {columnName: "Snacks",
                        items: [{content: "", amount: "", picture: "candy.png", order: 1 },
                                {content: "", amount: "", picture: "chips.png", order: 1 },
                                {content: "", amount: "", picture: "peanuts.png", order: 1 },
                                {content: "", amount: "", picture: "crackers.png", order: 1 },
                                {content: "", amount: "", picture: "crisps.png", order: 1},
                                {content: "", amount: "", picture: "cookies.png", order: 1}
                        ]
                }

                ],
     
}
 
export default FoodBankDynamicDataset;