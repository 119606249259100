import {Component} from 'react';
import './GameViews/gamePhase2.css';
import React, { useState } from "react";
     
    const GameSpinner = (props) => {
      const [arrowRotation, setArrowRotation] = useState(0);
      const [buttonDisabled, setButtonDisabled] = useState(props.spinnerDisabled);
      const [spinArrowFullCircle, setArrowFullCircle] = useState(false);
            
      const setFullCircle = () => {
        setArrowFullCircle(true);
        setArrowRotation(0);
      }

      const spinArrow = () => {
        setArrowFullCircle(false);
        let spinNbr = props.nextEventNbr;
        let arrowRotation = (spinNbr - 1) * 45 + 22.5;
        setArrowRotation(arrowRotation);
        setButtonDisabled(true);
        props.onSpin();
        }
      
      return (
       
        <div className="wheelContainer">
                <div className="one">1</div>
                <div className="two">2</div>
                <div className="three">3</div> 
                <div className="four">4</div>
                <div className="five">5</div>
                <div className="six">6</div>
                <div className="seven">7</div>
                <div className="eight">8</div>
                <button id="spin" 
                    onClick={ 
                        () => setFullCircle()} disabled={props.spinnerDisabled}
                    >Spin
                </button>
                {spinArrowFullCircle ?
                     <img src={'./assets/arrow.png'} 
                        className="spinnerArrow spinArrowAround"  alt={'arrow'}
                        onAnimationEnd={() => spinArrow()}
                        style={{transform: `rotate(${arrowRotation}deg)`,
                         }}
                     />
                :
                    <img src={'./assets/arrow.png'} 
                        className="spinnerArrow"  alt={'arrow'}
                        style={{transform: `rotate(${arrowRotation}deg)`,
                            }}
                        />
                }
           
        </div>
      )
    
   
}   

export default GameSpinner;    