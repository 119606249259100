import {Component} from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';


class TestActivities extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            previousProcess:  "",
            allCompleteText: "",
            processingCompleteSw: false,
            
        }
    }

    goToActivities(activityName){
        this.props.history.push({
            pathname: '/select-activity',
            search: '?query=abc',
            state: { detail: activityName}
          })
    }
   
  
    render() {
        let screenParms = setScreenSizeParameters(this.props.screenSize, "activity");
        this.className = screenParms.columnClass;
       
               
    return <>
        <div className={this.className}>
        <div className="card mt-3 rounded-corners-lg text-center game-board"
            style={{backgroundImage: `url(${process.env.PUBLIC_URL + this.state.backgroundURL})`}}>
            <h3 className="mt-2 text-center">Special Processes</h3>
            <h5 className="text-center">Test Activities</h5>
            <span className="mt-2 ml-4 mr-4">
                <div className="my-2">
                    <Button variant="primary"  onClick={() => this.goToActivities("SideHustle")}>
                       SideHustle
                    </Button>
                </div>
                <div className="my-4">
                    <Button variant="primary"  onClick={() => this.goToActivities("Volunteer")}>
                       Volunteer
                    </Button>
                </div>
                <div className="my-4">
                    <Button variant="primary"  onClick={() => this.goToActivities("HaveFun")}>
                       HaveFun
                    </Button>
                </div>
                <div className="my-4">
                    <Button variant="primary"  onClick={() => this.goToActivities("Vacation")}>
                       Vacation
                    </Button>
                </div>
               
            </span>
           
           </div>        
        </div>
     </>
    }

}


export default withRouter (TestActivities);