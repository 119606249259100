import {useRef} from 'react';

export const screenSizeFunction = () => {
 
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      let returnSizeObj = {screenWidth: windowWidth, screenHeight: windowHeight};
                
      return returnSizeObj;
   
}
export default screenSizeFunction;





