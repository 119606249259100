import TaxTableArray from "../../datasets/tax_table_dataset"

export const calcTaxAmt = (assetTable) => {
  
   var totalTaxAmt = 0;
   var sortTaxTableArr = [];
   var taxTableArray = TaxTableArray;
   let monthlySalaryAmt = 0;
   for (let i=0; i<assetTable.length; i++){
       if (assetTable[i].assetNbrMonths != 0){
            monthlySalaryAmt += +assetTable[i].assetAmt; 
       }
   }
   const annualSalary = (monthlySalaryAmt * 12).toFixed(2);

   let annualSalaryNbr = +annualSalary;
   sortTaxTableArr = taxTableArray.sort(
      (taxA, taxB) => taxA.salary - taxB.salary);    
    
   for (let i=0; (i < sortTaxTableArr.length && 
         annualSalaryNbr >= sortTaxTableArr[i].salary); ++i)
   {
      if (annualSalaryNbr >= sortTaxTableArr[i].salary)
        {
           totalTaxAmt = (+sortTaxTableArr[i].federal + +sortTaxTableArr[i].fica) / 12;
        }    
   }      
          
   return totalTaxAmt.toFixed(2);
                 
}





