export const checkNotifications = (iconTable) => {
 
    var notificationArr = [];
    for (let i=0; i<iconTable.length; i++){
         if (iconTable[i].firstTime == true){
                let notificationObj = JSON.parse(JSON.stringify({category: iconTable[i].iconCategory, 
                    type: iconTable[i].iconName, additionalInfo: iconTable[i].additionalInfo,
                    firstTime: iconTable[i].firstTime, imageSrc: iconTable[i].imageSrc}));
                notificationArr.push(notificationObj);
                iconTable[i].firstTime = false;
                if (iconTable[i].additionalInfo != undefined &&
                    iconTable[i].additionalInfo.firstTimeText != undefined){
                        iconTable[i].additionalInfo.firstTimeText = false;
                }
         }
    }
    return notificationArr;
   
}





