const updateSpendingHistoryData = {
   spendingHistory: { 
        currentMonthSpendAmt: 777,
        current10YearSpendAmt: 7777,
        current10YearMonths: 1,
        previous10YearSpendAmt: 7777,
        previous10YearMonths: 77
   }
}

export default updateSpendingHistoryData;