const updateBadgesEarnedData = {
    assetTable:[
        {
          "assetItem": "Job Salary",
          "assetDesc": "Electrical Engineer --> Annual Salary: $64,000.00",
          "assetAmt": "5333.33",
          "assetStartDate": "2022-10",
          "assetNbrMonths": "-1"
        },
        {
          "assetItem": "Job Salary test of update badges earned",
          "assetDesc": "Testing new entry",
          "assetAmt": "8888.88",
          "assetStartDate": "2024-02",
          "assetNbrMonths": "-1"
        }
      ],
     expenseTable: [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": "2291.83",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Meadow Hills-1 bedroom, 0 roommates",
          "expAmount": 1751,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "115.56",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 380,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Test Expense",
          "expDesc": "This expense is added using the update badges earned.",
          "expAmount": 9999,
          "expStartDate": "2024-02",
          "expNbrMonths": "-1"
        }
      ], 
    currentCash: "44444.44",
    currentMoodPoints: "444444", 
    badgesEarned: ["test1", "test2", "test3"]
    
}

export default updateBadgesEarnedData;