const updateCarInfo = 
{
    "cash": 99999.99, 
    "carInfo": {
        "carID": "88888888",
        "carAge": 5,
        "carYear": "2023",
        "carType": "test"
      }, 
    "loanTable": [
        {
          "loanType": "Car Loan",
          "loanDesc": "test for update car info",
          "loanAmt": "999.99",
          "loanStartDate": "2024-02",
          "loanNbrMonths": 72,
          "loanTotAmt": 20000,
          "loanIntRate": 0.1,
          "loanTerm": 6
        },
        {
          "loanType": "College Loan",
          "loanDesc": "Bachelors Degree-Electrical Engineering(4 years)",
          "loanAmt": "474.65",
          "loanStartDate": "2022-10",
          "loanNbrMonths": 120,
          "loanTotAmt": "48000.00",
          "loanIntRate": 0.035,
          "loanTerm": 10
        }
      ],
    "expenseTable": [
        {
          "expItem": "Payroll taxes",
          "expDesc": "Federal, FICA (Social Security)",
          "expAmount": "2291.83",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Apartment Rent",
          "expDesc": "Meadow Hills-1 bedroom, 0 roommates",
          "expAmount": 1751,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Health Insurance",
          "expDesc": "Health Insurance 70% Paid by Employer",
          "expAmount": "111.00",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Automobile Insurance",
          "expDesc": "Insurance for car repair or replacement",
          "expAmount": 75,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Renter's Insurance",
          "expDesc": "Insurance for furniture and other personal items",
          "expAmount": 30,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Transportation",
          "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
          "expAmount": "115.56",
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
          "expItem": "Misc Expenses",
          "expDesc": "Food, Clothes, Personal Items, etc.",
          "expAmount": 380,
          "expStartDate": "2022-10",
          "expNbrMonths": "-1"
        },
        {
            "expItem": "New Expense Item",
            "expDesc": "Test expense item for update car info",
            "expAmount": 999,
            "expStartDate": "2024-02",
            "expNbrMonths": "-1"
          }
      ],
    "conditionsTable":  [
        {
          "condName": "homeType-apartment",
          "conditionClass": "home"
        },
        {
          "condName": "electrical-engineer",
          "conditionClass": "career"
        },
        {
            "condName": "test-condition-car-info",
            "conditionClass": "game"
          }
      ],
    "currentMoodPoints": 88888,
};

export default updateCarInfo;