export const calcColumnAmt = (columnArr, selectColumn) => {     
    let totalColumnAmt = 0;
     
    for (let i=0; i<columnArr.length; i++){
        if (columnArr[i].column != selectColumn){
            let columnItems=columnArr[i].items;
            for (let j=0; j<columnItems.length; j++){
                totalColumnAmt += +columnItems[j].amount;
            }
        }
    }
    return totalColumnAmt;
 }




