import {Component} from 'react';
//import './Special/special.css'

class PlayerCommentCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }    
}
  


render() {
 
      
return <>
  {this.props.comment.questionAnswer !== "" ?
     <span>
            <li>
                <div className="text-left mx-4">
                    {this.props.comment.questionText}
                </div>
                <div className="text-left mx-4 decorative-not-bold">
                    {this.props.comment.questionAnswer}
                </div>
              </li>
         
      </span>
  :
    null
}
                
   </>
  }
}

export default PlayerCommentCard;