
export const makeTextPlural = (text, pluralRule) => {
 
   var newText;
   let currentText = text.toString();
   if (pluralRule == 2 || pluralRule == 3){
         let endOfFirstWord = currentText.indexOf(" ");
         let firstOfString = currentText.substring(0, endOfFirstWord);
         let nbrChars = +currentText.length;
         let endOfString = currentText.substring(endOfFirstWord, nbrChars);
         if (pluralRule == 2){
               newText = firstOfString + "s" + endOfString; 
         }else{
               newText = firstOfString + "es" + endOfString;
         }
   }else{
         newText = currentText + "s";
   }
              
   return newText;
   
}





