import { rollForwardDate } from "./roll_forward_date_function";
import ConfigFile from "../../../datasets/config_file";
import findDataObject from "../../../objects/CommonUse/find_data_object";
import { applyMonthlyMiscExpToCreditCard } from "./apply_monthly_misc_exp_to_credit_card";

export const payMonthlyCreditCardAmount = (creditCardInfo, currentCash, currentGameDate, nbrMonths, expenseTable) => {
   
     if (nbrMonths == undefined){
          nbrMonths = 1
     }
    
     creditCardInfo = applyMonthlyMiscExpToCreditCard(expenseTable, 
          creditCardInfo, +nbrMonths);
     let newCashAmt = +currentCash;
     // saving outstanding amount before late fee (if any) is applied 
     // late fee is included 100% in minimum payment and therefore not part of min pay calc,
     let outstandingAmt = +creditCardInfo.totalOutstandingBalance;
        
     // update late fee in previous period 
     let lateFee = 0;
     let indx = creditCardInfo.monthlyCreditCharges.length - 2;
     if (indx > 0){
          if (creditCardInfo.monthlyCreditCharges[indx].monthlyOutstandingAmt > 0 &&
              creditCardInfo.monthlyCreditCharges[indx].monthlyAmtPaid == 0){
               lateFee = (+ConfigFile.unlockedFeatures.creditCardLateFee * +nbrMonths);
          }
          creditCardInfo.monthlyCreditCharges[indx].monthlyLateFee = lateFee;
     }
     if (lateFee > 0){
          creditCardInfo.lateFeeCount = +creditCardInfo.lateFeeCount + 1;
     }else{
          creditCardInfo.lateFeeCount = 0;
     }
  
     let interestCharge = 0;
     let totalPeriodCharges = 0;
     let monthlyAmount = 0;
     let monthIndx = creditCardInfo.monthlyCreditCharges.length - 1;
   
     let periodCharges = creditCardInfo.monthlyCreditCharges[monthIndx].creditChargesArr;
     for (let i=0; i<periodCharges.length; i++){
          totalPeriodCharges = totalPeriodCharges + +periodCharges[i].amount;
     }
     // when the credit card is being billed monthly then the totalPeriodCharges = monthlyAmount
     // this is done to spread the total charges for a quarter across multiple months
     monthlyAmount = (totalPeriodCharges / +nbrMonths).toFixed(2);    

     //  if it is NOT autopayfull then add interest amount before comparing to max credit amt
     //  only pay interest on unpaid balance so if balance is paid in full then no interest is calculated
     if (+creditCardInfo.totalOutstandingBalance > 0 && creditCardInfo.paymentPolicyCode != "autoPayFull"){
          let creditCardIntRate = +(ConfigFile.unlockedFeatures.creditCardInterest/12).toFixed(6);
          for (let i=0; i<nbrMonths; i++){
               let monthInterestCharge = (+creditCardInfo.totalOutstandingBalance - +totalPeriodCharges +
                    +monthlyAmount) * creditCardIntRate;
               interestCharge = +interestCharge + +monthInterestCharge;
               creditCardInfo.totalIntBilled = (+creditCardInfo.totalIntBilled + +interestCharge).toFixed(4);
          }
          creditCardInfo.totalOutstandingBalance += interestCharge;
     }
  
     // calculate the minimum payment    
     let minimumPaymentAmt = 0 ;
     let minPaymentPercent = ConfigFile.unlockedFeatures.creditCardMinimumPayPct;
     if (creditCardInfo.paymentPolicyCode == "autoPayMin"){
          minimumPaymentAmt = (outstandingAmt * minPaymentPercent).toFixed(2);
          if (minimumPaymentAmt > 0){
               if (+creditCardInfo.totalOutstandingBalance > +creditCardInfo.creditMaxAmount){
                    let differenceAmt = +creditCardInfo.totalOutstandingBalance - +creditCardInfo.creditMaxAmount;
                    // difference amount is the amount over the max credit card allowed 
                    if (differenceAmt > minimumPaymentAmt){
                       minimumPaymentAmt = differenceAmt + lateFee;
                    }
               }
               // determine previous and current outstanding balance, and new cash amount
               minimumPaymentAmt = minimumPaymentAmt * +nbrMonths;
          }
     }else{
         if(creditCardInfo.paymentPolicyCode == "autoPayFull"){
             minimumPaymentAmt = outstandingAmt;
         }
     }
     if (minimumPaymentAmt > 0){
          newCashAmt = newCashAmt - minimumPaymentAmt;
          creditCardInfo.totalOutstandingBalance = (+creditCardInfo.totalOutstandingBalance - minimumPaymentAmt).toFixed(2);
     }
        
     // update outstanding amt, interest amt and amount paid in the current month
     indx = creditCardInfo.monthlyCreditCharges.length - 1;
     if (indx >= 0){
          // outstanding amount is amount before current month's charges-adding lateFee since that is
          // for the previous month
          creditCardInfo.monthlyCreditCharges[indx].monthlyOutstandingAmt = outstandingAmt + lateFee;
          creditCardInfo.monthlyCreditCharges[indx].monthlyIntAmt = interestCharge.toFixed(4);
          // adding to monthly amt paid in case player has made manual payments to card that were
          // applied in the unlocked features part of the game.
          creditCardInfo.monthlyCreditCharges[indx].monthlyAmtPaid = (minimumPaymentAmt + 
               +creditCardInfo.monthlyCreditCharges[indx].monthlyAmtPaid).toFixed(2);
     }
     
     // create new month values
     let newMonthCreditObj = JSON.parse(JSON.stringify(findDataObject("monthlyCreditCharges"))).creditInfo;
     newMonthCreditObj.date = currentGameDate;
     newMonthCreditObj.monthlyLateFee = lateFee;
     creditCardInfo.monthlyCreditCharges.push(newMonthCreditObj);

     // save # of periods of credit card history as specified in the config table
     let nbrPeriodsHistory = ConfigFile.unlockedFeatures.periodsCreditHistory;
     if (creditCardInfo.monthlyCreditCharges.length > nbrPeriodsHistory){
         let nbrToRemove = creditCardInfo.monthlyCreditCharges.length - nbrPeriodsHistory; 
         creditCardInfo.monthlyCreditCharges.splice(0,nbrToRemove);
     }
         
    let creditCardInfoObj = {creditCardInfo: creditCardInfo, cashAmt: newCashAmt};
    
    return creditCardInfoObj;
   
}





