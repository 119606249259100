import {Component} from 'react';
import { withRouter } from "react-router";
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';


class BackgroundPictureTest extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            previousProcess:  "",
            allCompleteText: "",
            processingCompleteSw: false,
            backgroundURL: "./assets/conditionalBackground/Lake-SailBoat.png",
           // backgroundURL: "./assets/homes/4br_home_option3/office.png"
            friendImgURL: "./assets/friends/jennifer.png",
            friendName: "Jennifer",
        }
    }
   
  
    render() {
        let screenParms = setScreenSizeParameters(this.props.screenSize, "gameBoard");
        this.className = screenParms.columnClass;
       
    return <>
        <div className={this.className}>
        <div className="card mt-3 mr-5 rounded-corners-lg text-center game-board"
            style={{backgroundImage: `url(${process.env.PUBLIC_URL + this.state.backgroundURL})`}}>
             <h3 className="mt-2 text-center">Special Processes</h3>
            <h5 className="text-center">Main Page</h5>
            <span className="mt-2 ml-4 mr-4">
                <div>This is a test of the background pictures. </div>
                <div>{this.state.backgroundURL}</div>
               
            </span>
            <div className="friend-position">
                            <img src={this.state.friendImgURL} 
                                className="mx-auto d-block friend-game-img" alt={this.altFriendImg}/>
            </div>
           
           </div>        
        </div>
     </>
    }

}


export default withRouter (BackgroundPictureTest);