import {Component} from 'react';

class NumberedListFancyCard extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false};
    }

    render() {
        this.class = "";
        if (this.props.text.textColor === "green"){
            this.class = "text-success list-margin";
        }
    return <> 
           
       
         <li  className={this.class} style={{ listStyleType: "none" }}>
            {this.props.text.textColor === "green" ?
                <img src="./assets/checkMark.png"
                    className="mr-3 teeny_tiny_img" alt="check" />
            :
                null
            }
         
            {this.props.text.textLine}
        
        </li>
    </>
    }
}

export default NumberedListFancyCard;