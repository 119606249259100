export const calculateResponseAmount = (currentCard, response, gameData) => {
   
    if (currentCard.responses[response].type === "flat")
        return currentCard.responses[response].value;
      else
      {
        switch(currentCard.responses[response].category)
        {
            case "cash":
                return gameData.currentCash * 
                    +currentCard.responses[response].value;  
            case "asset":
                for (const asset of gameData.assetTable)
                {
                    if (asset.assetItem === currentCard.responses[response].on)
                        return (asset.assetAmt * currentCard.responses[response].value / 100).toFixed(2);
                }
                break;
            case "expense":
                for (const expense of gameData.expenseTable)
                {
                    if (expense.expItem === currentCard.responses[response].on)
                        return (expense.expAmount * currentCard.responses[response].value / 100).toFixed(2);
                }
                break;
            case "loan":
                for (const loan of gameData.loanTable)
                {
                    if (loan.loanType === currentCard.responses[response].on)
                        return (loan.loanAmt * currentCard.responses[response].value / 100).toFixed(2);
                }
                break;
            default:
                for (const asset of gameData.assetTable)
                {
                    if (asset.assetItem === currentCard.responses[response].on)
                        return (asset.assetAmt * currentCard.responses[response].value / 100).toFixed(2);
                }
                for (const expense of gameData.expenseTable)
                {
                    if (expense.expItem === currentCard.responses[response].on)
                        return (expense.expAmount * currentCard.responses[response].value / 100).toFixed(2);
                }
                for (const loan of gameData.loanTable)
                {
                    if (loan.loanType === currentCard.responses[response].on)
                        return (loan.loanAmt * currentCard.responses[response].value / 100).toFixed(2);
                }
                break;
            }
        }
        if (currentCard.responses[response].value)
            return currentCard.responses[response].value;
         else return 0
   
}
export default calculateResponseAmount;
