const LoanExerciseDataset =[
      {exerciseName: "LoanAmount", 
              text:  "In this example, only the Loan Amount has been changed.  The Interest Rate and Loan Term " +
                     "are the same for each of the lines.  Note that the Monthly Amount increases as the Loan Amount " +
                     "is increased.  The Monthly Amount is the amount that the borrower has to pay each month.  Also, " +
                     "the Total Amount Paid increases as the Loan Amount increases.",
              conclusion: "Loans where the loan amount is larger will result in a larger monthly payment amount.",       
              parametersArr: [{intRate: .05, loanAmount: 5000, nbrYears: 10},
                                   {intRate: .05, loanAmount: 10000, nbrYears: 10},
                                   {intRate: .05, loanAmount: 20000, nbrYears: 10},
                                   {intRate: .05, loanAmount: 40000, nbrYears: 10},
                                   {intRate: .05, loanAmount: 100000, nbrYears: 10}
                            ]   
      },
      {exerciseName: "InterestRate", 
              text:  "In this example, only the Interest Rate has been changed.  The Loan Amount and Loan Term " +
                     "are the same for each of the lines.  Note that the Monthly Amount increases as the Interest Rate " +
                     "is increased.  The Monthly Amount is the amount that the borrower has to pay each month until the " +
                     "loan is fully paid back.  Also, the Total " +
                     "Amount Paid increases as the Interest Rate increases.  Line 5 shows that the individual got a " +
                     "loan for $5000 but ended up having to pay $8,608.80.  This means they had to pay $3,608.80 more " +
                     "than they borrowed in order to fully pay back the loan.",
              conclusion: "Loans that have a lower interest rate, will result in lower monthly payments and " +
                     "the amount overpaid will be reduced.",
              parametersArr: [{intRate: .02, loanAmount: 5000, nbrYears: 10},
                                   {intRate: .05, loanAmount: 5000, nbrYears: 10},
                                   {intRate: .07, loanAmount: 5000, nbrYears: 10},
                                   {intRate: .10, loanAmount: 5000, nbrYears: 10},
                                   {intRate: .12, loanAmount: 5000, nbrYears: 10}
                            ]   
      },
      {exerciseName: "LoanTerm", 
              text:  "In this example, only the Loan Term has been changed.  The Loan Amount and Interest Rate " +
                     "are the same for each of the lines.  Note that the Monthly Amount decreases as the Loan Term " +
                     "is increased.  So one way to reduce the amount you have to pay each month, is to get a loan " +
                     "that has a longer Loan Term.  However, it is " +
                     "important to understand that the longer Loan Term will increase the Total " +
                     "Amount Paid (this is because the Interest Rate is applied for a longer period of time).",
              conclusion: "Increasing the Loan Term will reduce the monthly loan payment amount but will " +
                     "increase the overpayment amount.",
              parametersArr: [{intRate: .05, loanAmount: 500000, nbrYears: 5},
                                   {intRate: .05, loanAmount: 500000, nbrYears: 10},
                                   {intRate: .05, loanAmount: 500000, nbrYears: 15},
                                   {intRate: .05, loanAmount: 500000, nbrYears: 20},
                                   {intRate: .05, loanAmount: 500000, nbrYears: 30}
                                   ]   
              },
     
      
]

export default LoanExerciseDataset;