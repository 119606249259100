import {Component} from 'react';
import CommentTextDisplayCard from './comment_text_display_card';

class CommentListDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }    
}
  


render() {
   this.commentText = this.props.comments.commentTextArr.map((commentText, index) =>
            <CommentTextDisplayCard commentText={commentText}  key={index}  
            index={index}/>
              );
 
          
return <>
      <tr>
            <td className="col-sm-3 align-top font-weight-bold text-left">
                 {this.props.comments.question}
            </td>
            <td className="col-sm-4" >
                 {this.commentText}
            </td>
           
      </tr>         
           
   </>
  }
}

export default CommentListDisplayCard;