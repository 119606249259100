import "./GameBoardActivities/gameBoardActivities.css";
import React from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';
import SideHustleInformationText from './text/side_hustle_information_text';
import VolunteerInformationText from "./text/volunteer_information_text";
import HaveFunInformationText from "./text/have_fun_information_text";
import VacationInformationText from "./text/vacation_information_text";
import TimerDisplayModal from './timer_display_modal';
import WordPuzzleDataset from './Datasets/word_puzzle_dataset';
import ActivityLevelsDataset from "./Datasets/activity_levels";
import {findDataObject} from "../../objects/CommonUse/find_data_object";
import CircusJigsawPuzzleDataset from "./Datasets/circus_jigsaw_puzzle_dataset";
import ComedyClubDataset from "./Datasets/comedy_club_dataset";
import VacationImageDataset from "./Datasets/vacation_image_dataset";
import ZooAnimalsDynamicDataset from "./Datasets/zoo_animals_dynamic_dataset";
import CoatDriveDynamicDataset from "./Datasets/coat_drive_dynamic_dataset";
import shuffleArray from "../../objects/CommonUse/shuffleArray";
import { setScreenSizeParameters } from "../../objects/CommonUse/set_screen_size_parameters";


class StartActivity extends React.Component {
    constructor(props) {
        super(props);
        props.onOpen();
        this.activityInfo = this.props.activityInfo();
        this.state = {
            openModal: false,
          
        }    
        this.activityCategory = "";
        this.activityName = "";
        this.endActivity = "";
        this.maxNbrGames = "";
        this.modalType = "";
        this.activityType = "";
        this.timerInfo = {};
        this.wordListArr = [];
        this.imageSrc = "";
        this.dndDataset = "";
        this.score = findDataObject("activityScore").score;
        this.preDisplayProcess();
    }

  preDisplayProcess(){
       this.getBasicActivityInfo();
       this.getGameParameters();
       this.updateInformationText();
  }  

   
  getBasicActivityInfo(){
      this.activityCategory = this.activityInfo.slctActivity.activityCategory;
      this.activityName = this.activityInfo.slctActivity.activityName;
      this.defaultImgSrc = "./assets/Activities/" + this.activityInfo.slctActivity.activityCategory + "/" +
        this.activityInfo.slctActivity.imageName;
  }
    
  getGameParameters(){
    let activityLevels = ActivityLevelsDataset;
    this.activityParameters = activityLevels.find(elem => elem.activityName === this.activityInfo.slctActivity.shortName);
    if (this.activityParameters === undefined){
         alert ("something happened... not able to find the activity parameter.");
    }else{
           this.determineActivityLevel();
           this.setActivityParameters();
           this.setActivityscoreInfo();
    }
}

determineActivityLevel(){
    let activityTracker = JSON.parse(JSON.stringify(this.activityInfo.activityTracker));
    if (activityTracker === "noData"){
        this.levelNumber = 1;
    }else{
        let activityName = this.activityInfo.slctActivity.shortName;
        const activityObj = activityTracker.activityTable.find(elem => elem.activityName === activityName);
        if (activityObj === undefined){
            this.levelNumber = 1;
        }else{
            this.levelNumber = +activityObj.level;
        }
    }
    let maxLevels = this.activityParameters.levels.length;
    let levelInfo = {levelNbr: this.levelNumber, maxLevels: maxLevels};
    this.props.setActivityInfo(levelInfo, 'levelInfo');
}

setActivityParameters(){
    let slctIndx=this.levelNumber - 1;
    this.parameters = this.activityParameters.levels[slctIndx];
    this.maxNbrGames = this.parameters.maxNbrGames;
    this.score.nbrCompleted = 0;
    this.score.points = 0;
    this.score.gamesRemaining = this.parameters.maxNbrGames;
              
    if (this.activityParameters.timerValid === true){
        let minutes = 0;
        if (this.parameters.minutesAllowed !== undefined){
             minutes = +this.parameters.minutesAllowed;
        }
        this.timerInfo = {timerValid: true, nbrMinutes: +minutes, 
                    nbrSeconds: +this.parameters.secondsAllowed}
    }else{
         this.timerInfo = {timerValid: false, nbrMinutes: 0, nbrSeconds: 0}
    }
}

setActivityscoreInfo(){
    if (this.score.timeRemaining === undefined){
          this.score.timeRemaining = {minutes: 0, seconds: 0};
    }
    this.score.timeRemaining.minutes = this.timerInfo.nbrMinutes;
    this.score.timeRemaining.seconds = this.timerInfo.nbrSeconds;
    this.score.maxScore = 0;
    this.props.setActivityInfo(this.score, 'activityScore');
}    

  updateInformationText(){
    switch(this.activityCategory) {
        case "SideHustle":
            this.informationTextArr = SideHustleInformationText;
            break;
        case "Volunteer":
            this.informationTextArr = VolunteerInformationText;
            break;
        case "HaveFun":
            this.informationTextArr = HaveFunInformationText;
            break;
        case "Vacation":
            this.informationTextArr = VacationInformationText;
            break;
        default:
            break;
    }
    this.getTextForActivitySelected();
  
  }  
  
  getTextForActivitySelected(){
        this.endActivity = this.activityInfo.slctActivity.endActivity;
        for (let i=0; i<this.informationTextArr.length; i++){
            if( this.informationTextArr[i].activityName === this.activityInfo.slctActivity.shortName){
                  this.informationText = this.informationTextArr[i].rules;
                  this.rulesText = this.informationTextArr[i].rulesBrief;
                  break;
            }
        }
        let replaceText = "";
        if (this.endActivity === "repetitions"){
            replaceText = this.maxNbrGames;
        }else{
            let zeroFillNbrSeconds = "";
            if (this.parameters.secondsAllowed < 10){
                  zeroFillNbrSeconds = "0" + this.parameters.secondsAllowed;
            }else{
                  zeroFillNbrSeconds = this.parameters.secondsAllowed;
            }
            replaceText = this.parameters.minutesAllowed + ":" + zeroFillNbrSeconds;
        }
        if (this.informationText !== undefined) {
            this.informationText = this.informationText.replace("||nbrTimes||", replaceText);
        }
  }

  startActivity(){
        this.props.setActivityInfo(true, 'activityStarted');
        this.modalType = this.activityInfo.slctActivity.shortName;
        this.activityType = this.activityInfo.slctActivity.activityType;
        this.modalSize = this.parameters.modalSize;
        // if screen size is narrow, then upsize the modal by 1 size
        if (this.props.screenSize === "narrow"){
             switch (this.modalSize){
                 case "sm":
                    this.modalSize = "md";
                    break;
                case "md":
                    this.modalSize = "lg";
                    break;
                default:
                    this.modalSize = "xl";
                    break;
             };
        }
        if (this.activityInfo.slctActivity.smallImageName !== undefined &&
            this.activityInfo.slctActivity.smallImageName !== ""){
            this.imageSrc = "./assets/Activities/" + this.activityInfo.slctActivity.activityCategory + "/" + 
            this.activityInfo.slctActivity.smallImageName;
        }
        
        let nbrColumns = 0;
        switch(this.activityType) {
            case "maze":
                let width = +this.parameters.gridWidth;
                let height = +this.parameters.gridHeight;
                let pctBlocked = +this.parameters.pctBlocked;
                this.mazeParameters = {width: width, height: height, pctBlocked: pctBlocked};
                break;
            case "wordPuzzle":
                this.getWordArr();
                break;
            case "dragNdrop":
                nbrColumns = +this.parameters.nbrColumns;
                let nbrItems = +this.parameters.nbrItems;
                let nbrInList = +this.parameters.nbrInList;
                let dataset = "";
                if (this.modalType === "GoToZoo" ){
                    dataset = ZooAnimalsDynamicDataset;
                }
                if (this.modalType === "CoatDrive"){
                    dataset = CoatDriveDynamicDataset;
                }
                if (dataset !== ""){
                    this.dndDataset = dataset.find(elem => elem.gameName === this.parameters.gameName);
                }
                this.DNDParameters = {nbrColumns: nbrColumns, nbrItems: nbrItems, nbrInList: nbrInList};
                break;
            case "jigsawPuzzle":
                nbrColumns = +this.parameters.nbrColumns;
                let pictureDir = this.activityParameters.pictureDir;
                this.getPuzzleOptions();
                this.jigsawParameters = {pictureDir: pictureDir, puzzleArr: this.puzzleArr };
                break;
            case "comedyClub":
                this.comedyClubJokes = ComedyClubDataset;
                break;
            case "pictureReveal":
                this.getPictureDatasetForChosenItem();
                break;
            default:
                break;
        }
        this.setState({openModal: true});
  } 

  getWordArr(){
        let wordListDataset = WordPuzzleDataset;
        const wordListObj = wordListDataset.find(elem => elem.listType === this.parameters.wordList);
        if (wordListObj !== undefined){
             this.wordListArr = wordListObj;
        }
  }

  getPuzzleOptions(){
         this.puzzleArr = [];
         switch (this.modalType){
            case "Circus":
                 this.puzzleDataset = CircusJigsawPuzzleDataset;
                 break;
            default:
                break;
         }
         let puzzleArr = []
         for (let i=0; i<this.puzzleDataset.length; i++){
             if (this.puzzleDataset[i].level === this.levelNumber){
                 puzzleArr.push(this.puzzleDataset[i]);
             }
         }
         this.puzzleArr = shuffleArray(puzzleArr);
  }

  getPictureDatasetForChosenItem(){
        let vacationImageDataset = VacationImageDataset;
        let vacationImageObj = vacationImageDataset.find(elem => elem.vacationName === this.activityInfo.slctActivity.shortName);
        this.imageList = vacationImageObj.imageList;
        for (let i=0; i<this.imageList.length; i++){
                this.imageList[i].selected = false;
        }
    
  }

  render() {
        let screenParms = setScreenSizeParameters(this.props.screenSize, "activity");
        this.className = screenParms.columnClass;
        this.textClass = screenParms.textSize + " m-4 text-left";
             
  return <>
           <div className={this.className}>
           <div className="card mt-3 rounded-corners-lg text-center activity-board height-max">
            <h3 className="mt-2 text-center">Activities</h3>
            {this.activityCategory === "Vacation" ?
                <h5 className="mx-2 mt-0 text-center">{this.activityName}</h5>
            :
                <h5 className="mx-2 mt-0 text-center">{this.activityName} - Level Nbr {this.levelNumber}</h5>
            }
            <span className="ml-4 row-80">
                    <div className="p-0 text-center">
                            <img src={this.defaultImgSrc} className="activity_start_page_img" alt="activityPicture"/>
                    </div>
                    <div className={this.textClass}>
                            {this.informationText} 
                    </div>
                          
                <span> 
                    <div className="mt-4 row mb-4" >
                        <div className="ml-4 col-sm-5">
                            <Button variant="primary"  onClick={() => this.startActivity()}>
                                Begin
                            </Button>
                        </div>
                        <div className="mr-4 col-sm-5">
                            <Button variant="primary"  onClick={() => this.finishActivity()}>
                                Finish Early
                            </Button>
                        </div>
                    
                    </div>
                </span>
            </span>
            {this.state.openModal ?
                <TimerDisplayModal
                     screenSize={this.props.screenSize}
                     levelNbr={this.levelNumber}
                     activityName={this.activityName}
                     show ={this.state.openModal}
                     modalType={this.modalType}
                     rulesText={this.rulesText}
                     image={this.imageSrc}
                     activityType={this.activityType}
                     activityCategory={this.activityCategory}
                     wordList={this.wordListArr}
                     endActivity={this.endActivity}
                     dataset={this.groceryShopperDataset}
                     mazeParameters={this.mazeParameters}
                     DNDParameters={this.DNDParameters}  
                     dndDataset={this.dndDataset}
                     jigsawParameters={this.jigsawParameters}
                     timerInfo={this.timerInfo}
                     nbrGames={this.maxNbrGames}
                     score={this.score}
                     modalSize={this.modalSize}
                     friendInfo={this.activityInfo.friendInfo}
                     comedyClubJokes={this.comedyClubJokes}
                     imageList={this.imageList}
                     finishActivity={(cumulativeScore) => this.finishActivity(cumulativeScore)}
                     onClose={(scores) => this.closeModal(scores)}
                    /> 
            :
               null
            }
        </div>
        </div>
    </>
  }

  closeModal(score){
    this.score = score;
    if (score.type === "finish"){
        this.finishActivity();
    }else{
        if (score.type === "done"){
            if (score.timeRemaining === undefined){
              //  if undefined then do not need to reset the 'this.state' variables                
            }else{
                this.timerInfo.nbrMinutes = score.timeRemaining.minutes;
                this.timerInfo.nbrSeconds = score.timeRemaining.seconds - 1;
            }
        }
    }
    let endActivity = false;
    if (this.activityInfo.slctActivity.endActivity === "repetitions" &&
        score.gamesRemaining <= 0) {
            endActivity = true;
    } 
    if (this.activityInfo.slctActivity.endActivity === "timer" &&
       (this.timerInfo.nbrMinutes === 0 && this.timerInfo.nbrSeconds === 0)){
            endActivity = true;
    }
    if (endActivity === true){
        this.props.setActivityInfo(score, 'activityScore');
        this.finishActivity();
    }
    this.setState({openModal: false});
  }

 finishActivity(){
    this.props.onComplete();
    this.props.history.push('/finish-activity');
 }

}

export default withRouter(StartActivity);