import {Component} from 'react';
import { setResponseResultText } from '../game-views/objects/set_response_result_text';
import ConfigFile from '../../datasets/config_file';

class FriendDialogDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {responseReceived: false, responseInd: null};
    }

    componentDidMount()
    {
        this.setState({responseReceived: false, responseInd: null});
    }

    render() {
        let btnClass = "button_response_color text-white w-90 mt-2";
        if (this.props.screenSize == "narrow"){
            btnClass = "btn-small-dialog-response " + btnClass;
        }else{
            btnClass = "btn-sm " + btnClass;
        }
        let btnList = this.props.currentCard.responses.map((key, index) =>
            <div className={btnClass} key={index}  color="#ff5c5c"
                onClick={()=>this.onSelected(index)}> 
                {this.props.currentCard.responses[index].btnText}
            </div>
        );
        if (this.props.currentCard.description.length < 170){
              this.class = "speech right display-on-top";
        }else{
              this.class = "speech right small-text-2 display-on-top";
        }
        this.replyClass = "text-left text-white";
        this.responseClass = "response response-result-position mt-1 display-on-top"
        this.friendNameClass = "friend-name-container color-white";
        switch (this.props.screenSize){
            case "narrow":
                this.class =  "speech right small-text";
                this.replyClass = this.replyClass + " small-text";
                this.responseClass = this.responseClass + " small-text";
                this.friendNameClass = this.friendNameClass + " friend-name-position-narrow";
                break;
            case "medium":
                this.friendNameClass = this.friendNameClass + " friend-name-position-medium";
                break;
            default:
                this.friendNameClass = this.friendNameClass + " friend-name-position";
        }
            
    return <>
        <div className={this.friendNameClass}>{this.props.currentCard.friendName}</div>
            <div className="friend-dialog-position display-on-top">
                    <img src={this.props.currentCard.friendImgURL} 
                            className="mx-auto d-block friend-game-img" alt={this.props.currentCard.friendName}/>
        </div>
        {!this.state.responseReceived ? 
                <span>
                    <div className={this.class}>
                        <div>
                            {this.props.currentCard.description}
                        </div>
                    </div>
                    <div className="response response-initial-position display-on-top mt-1">
                        <div className={this.replyClass}>Your Reply:</div>
                        <div>
                            {btnList} 
                        </div>
                    </div>
                </span>
        :
                <span>
                    <div className={this.responseClass}>
                        <h5>{this.props.currentCard.title}</h5>
                        <p>{this.props.currentCard.responses[this.state.responseInd].closureText}</p>
                        <div className="btn-sm text-center button_response_color text-white w-20 m-4 mt-2" 
                                onClick={()=>this.props.onComplete(this.state.responseInd)}>
                                                OK
                        </div>
                    </div>
                </span>
        }
      
    </>
    }

    onSelected(index)
    {   
        if (!this.state.responseReceived)
        {
            this.props.onResultDisplay();
            let replaceValue = +this.props.calcResponseValue(index);
            let response = this.props.currentCard.responses[index];
            let moodPointMultiplier = ConfigFile.gameBoard.moodPointMultiplier;
            let fullMoodPoints = +response.moodPoints * moodPointMultiplier;
            this.props.currentCard.responses[index].closureText = 
            setResponseResultText(fullMoodPoints, response.rltnshpScore, this.props.currentCard.friendName,
                    this.props.currentCard.title, replaceValue);
            this.setState({responseReceived: true, responseInd: index});
        }
    }
}

export default FriendDialogDisplay;