const newPlayerFeedback =
{
    "thumbsChosen": "thumbsUp",
    "comment": "I love this game ",
    "gamePart": "GameView",
    "process": "gameBoard",
    "subProcess": "card",
    "user": "studentX",
    "cardTitle": "Phone Plan",
    "description": "Your current phone plan has expired. What new plan do you choose?",
    "btnText": "noButtonSelected",
    "additionalData": {
      "text": "noAdditionalData"
    },
}

export default newPlayerFeedback;