import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';


class DollarAmountCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false, 
          selected: false};
    }

    render() {
          if (this.props.amtType == "asset"){
              this.lineItem = this.props.amountInfo.assetItem;
              this.lineDesc = this.props.amountInfo.assetDesc;
              this.lineAmt = this.props.amountInfo.assetAmt;
              this.nbrMonths = this.props.amountInfo.assetNbrMonths;
              this.startDate = this.props.amountInfo.assetStartDate;
          } 
          if (this.props.amtType == "expense"){
               this.lineItem = this.props.amountInfo.expItem;
               this.lineDesc = this.props.amountInfo.expDesc;
               this.lineAmt = this.props.amountInfo.expAmount;   
               this.nbrMonths = this.props.amountInfo.expNbrMonths;
               this.startDate = this.props.amountInfo.expStartDate;

          } 
          if (this.props.amtType == "loan"){
               this.lineItem = this.props.amountInfo.loanType;
               this.lineDesc = this.props.amountInfo.loanDesc;
               this.lineAmt = this.props.amountInfo.loanAmt;
               this.nbrMonths = this.props.amountInfo.loanNbrMonths;
               this.startDate = this.props.amountInfo.loanStartDate;
          }   
          
          if (this.props.amtType == "oneTime"){
               this.lineItem = this.props.amountInfo.oneTimeItem;
               this.lineDesc = this.props.amountInfo.oneTimeDesc;
               this.lineAmt = this.props.amountInfo.oneTimeAmt;
               this.nbrMonths = -1;
               this.startDate = this.props.gameDate;
          }  
          if (this.props.amtType == "moodPoints"){
            this.lineItem = this.props.amountInfo.item;
            this.lineDesc = this.props.amountInfo.label;
            this.lineAmt = this.props.amountInfo.number;
            this.nbrMonths = -1;
            this.startDate = this.props.gameDate;
       }  
          if (this.props.amtType == "general"){
              this.lineItem = this.props.amountInfo.item;
              this.lineDesc = this.props.amountInfo.desc;
              this.lineAmt = this.props.amountInfo.amount;
              this.nbrMonths = -1;
              this.startDate = this.props.gameDate;
            }  
      return <>
        {this.nbrMonths != 0 ?
            <tr className={this.props.className}>
              <th className="col-sm-4 p-0" scope="row">{this.lineItem}</th>
              <td className="col-sm-6 p-0">{this.lineDesc}</td>
              {this.props.amtType == "moodPoints" ?
                <td className="col-sm-2 text-right p-0">{this.lineAmt}</td>
              :
                <td className="col-sm-2 text-right p-0">{doubleToStringMonthly(this.lineAmt)}</td>
              }
            </tr>
        :
            null
        }
        
    </>
    }
}

export default DollarAmountCard;