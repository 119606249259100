import {Component} from 'react';
import { Button } from "react-bootstrap";

class CarSizeSelectCard extends Component {
    constructor(props) {
        super(props);
       
    }

    render() {
        let defaultImgSrc = "./assets/cars/sizeOptions/" +  this.props.sizeInfo.imageName;
           
    return <>
        <div className="card mx-2 my-2 drop-shadow" >
        <div className="card-header text-left">
        
            <span className=" m-0 p-0">
                <span className="row w-100 mx-0">
                    <div className="col-sm-6 p-0 text-left">
                            <img src={defaultImgSrc} className="car_group_thumbnail_img" alt="car grp picture"/>
                     </div>
                     <div className="col-sm-4">
                           <p className="mb-0 mt-2 font-weight-bold">{this.props.sizeInfo.sizeName}</p>
                     </div>
                     <div className="col-sm-1 ">
                        <Button type="btn-primary btn-primary-small mx-2 " 
                            onClick={this.props.onSelected.bind(this, this.props.sizeInfo)}> 
                                Select 
                        </Button>
                    </div>   
                </span>                      
            </span>
                
        </div>
        </div>
    </>
    }
}

export default CarSizeSelectCard;