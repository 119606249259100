import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import { jsPDF } from "jspdf";
//import "./Special/special.css";
import Button from 'react-bootstrap/Button';
import QuestionListDisplayCard from './question_list_display_card';
import CommentListDisplayCard from './comment_list_display_card';

class GameFeedbackDisplayModal extends Component {
  constructor(props) {
    super(props);
    this.state={
        questionArr: [],  
        commentArr: [],
      }    
      this.feedbackProcessed = false;
      this.questionArr = [];
      this.commentArr = [];
}

processGameFeedback() {
     this.roleArr = [];
     for (let i=0; i<this.props.gameFeedback.length; i++){
        // currently not displaying roles and not sure it would be meaningful...
        // may want to separate displays by role (or have a role parameter)
          this.processQuestionList(this.props.gameFeedback[i].questionList);
          this.processCommentList(this.props.gameFeedback[i].commentList);
     }
}

processQuestionList(questions){
    for (let i=0; i<questions.length; i++){
        let questionIndx = 99;
        for (let j=0; j<this.questionArr.length; j++){
            if (questions[i].question === this.questionArr[j].question){
                questionIndx = j;
                break;
            }
            
        }
        if (questionIndx === 99){
            let questionObject = {question: questions[i].question,
               score1Count: 0, score2Count: 0, score3Count: 0, score4Count: 0, score5Count: 0,
               scoreTotal: 0, totalScoreCount: 0 }
            this.questionArr.push(questionObject);
            questionIndx = this.questionArr.length - 1;
        }

        this.setScoresInQuestionArr(questions[i], this.questionArr[questionIndx] )
    }
   
}

setScoresInQuestionArr(questionReply, questionArr){
      switch (+questionReply.answer){
          case 1: 
             questionArr.score1Count += 1;
             break;
          case 2:
              questionArr.score2Count += 1;
              break;
          case 3:
              questionArr.score3Count += 1;
              break;
          case 4:
              questionArr.score4Count += 1;
              break;
          case 5:
              questionArr.score5Count += 1;
              break;
          default:
              break;
      }
      questionArr.scoreTotal += +questionReply.answer;
      questionArr.totalScoreCount += 1;

}

processCommentList(comments){
  for (let i=0; i<comments.length; i++){
      let commentIndx = 99;
      for (let j=0; j<this.commentArr.length; j++){
          if (comments[i].question === this.commentArr[j].question){
              commentIndx = j;
              break;
          }
      }
      if (commentIndx === 99){
          let commentObject = {question: comments[i].question, commentTextArr: [],
            questionNbr: comments[i].questionNbr }
          this.commentArr.push(commentObject);
          commentIndx = this.commentArr.length - 1;
      }

      this.commentArr[commentIndx].commentTextArr.push(comments[i].answer); 
  } 
  (this.commentArr).sort((a, b) => (a.questionNbr > b.questionNbr) ? 1 : -1)
}

createPDF () {
  const pdf = new jsPDF("legal", "pt", "a4");
  const data = document.querySelector("#pdf");
    pdf.html(data).then(() => {
    pdf.save("game_feedback.pdf");
    });
}; 
   
render() {
      if (this.feedbackProcessed === false){
        this.processGameFeedback();
        this.feedbackProcessed = true;
      }
        this.questionsInfo = this.questionArr.map((questions, index) =>
          <QuestionListDisplayCard questions={questions}  key={index}  
           index={index}/>
              );
        this.commentInfo = this.commentArr.map((comments, index) =>
            <CommentListDisplayCard comments={comments}  key={index}  
            index={index}/>
              );
                
return <>
  
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="lg" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Game Feedback - Number of Game Feedback Responses: &nbsp;
              {this.props.gameFeedback.length}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div id="pdf">
              {this.questionArr.length === 0 ?
                <span className="large-text">
                    No Game Feedback has been submitted.
                </span>
              :
              <span>    
              <table>
                <tbody>
                  <tr>
                    <th>Question</th>
                    <th>&nbsp;&nbsp;1&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;2&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;3&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;4&nbsp;&nbsp;</th>
                    <th>&nbsp;&nbsp;5&nbsp;&nbsp;</th>
                    <th>&nbsp;Nbr Responses&nbsp;</th>
                    <th>&nbsp;Avg Score&nbsp;</th>
                  </tr>
                  {this.questionsInfo}
                </tbody>
              </table>
              <div className="my-2">
                 ( <b>1</b>-Strongly Disagree, <b>2</b>-Disagree, <b>3</b>-Not Sure, <b>4</b>-Agree, &nbsp;
                    <b>5</b>-Strongly Agree )
              </div>

              <table className="mt-4 table">
                <tbody>
                    <tr>
                      <th>Question</th>
                      <th>&nbsp;&nbsp;Comment Text&nbsp;&nbsp;</th>
                    </tr>
                    {this.commentInfo}
                </tbody>
              </table>
                  
              </span>
          }   
          </div>     
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
              <Button variant="primary"  onClick={() => this.createPDF()}>
                   Download to PDF
              </Button>
              <Button variant="primary"  onClick={() => this.props.onClose()}>
                   Close
              </Button>
              
        </Modal.Footer>
       
    </Modal>
    
  </>
  }
}

export default GameFeedbackDisplayModal;