
export const addToAllConditionsArray = (slctEvent, condition, skipArr, btnText, triggerCondition, deleteCondition, 
    deleteClass, primaryEvent ) => {
   
    var conditionObj = "";
    conditionObj={title: slctEvent.title, connector: slctEvent.conditionConnector, condition: condition, 
         skipArr: skipArr, btnText: btnText, triggerCondition: triggerCondition, deleteCondition: deleteCondition,
         deleteClass: deleteClass, level: 99, primaryEvent: primaryEvent};

   return conditionObj;
   
}
export default addToAllConditionsArray;

