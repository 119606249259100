import ClassHierarchy from "../datasets/class_hierarchy_dataset";

export const removeConditionsTable = (conditionsTable, conditionClass) => {
 
// conditions table is the conditionsTable that is currently on the player
// conditionClass is the class that is being processed (ex: car, home, etc)


   let classHierarchy = ClassHierarchy;
   //  determine if entries in conditionsTable can be deleted
   var i;
   var j;
   var indexTable = [];
    
   for (i=conditionsTable.length - 1; i>=0; i--){
         if (conditionsTable[i].conditionClass == conditionClass){
               indexTable.push(i);

         }
         for (j=0; j < classHierarchy.length; j++){
            if (conditionClass == classHierarchy[j].mainClass && 
                conditionsTable[i].conditionClass == classHierarchy[j].subClass){
                    indexTable.push(i);
            }
         }   
        
   }
   for (i=0; i<indexTable.length; i++){
       conditionsTable.splice(indexTable[i],1);
   }
            
   return conditionsTable;
   
}





