import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {monthlyLoanAmt} from '../../objects/AssetsExpenses/loanAmtCalc_function';
import SixColumnDisplayCard from '../shared-components/six_column_display_card';
import LoanCalculatorText from './text/loan_calculator_text';
import NumberedListCard from '../game-views/numbered_list_card';
import LoanExerciseDataset from './Datasets/loan_exercise_dataset';

class LoanCalculatorModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         loanType: "General",
         expandIntroText: false,
    };    
    this.loanParmArr = [];
    this.introText = LoanCalculatorText.introText;
    this.textArr = LoanCalculatorText.numberedListArr;
    this.exerciseText = "";
    this.message = "";
    this.resetExerciseButtons();
  }

  calculateLoanAmounts(entryName){
    if (entryName === "Entered"){
          const loanAmount = +this.loanAmount.value;
          const nbrYears = +this.nbrYears.value;
          const intRate = +this.intRate.value;
          this.exerciseText = "";
          this.conclusionText = "";
          this.calcValuesEntered(loanAmount, intRate, nbrYears);
    }else{
          this.exerciseText = "";
          this.calcExerciseValues(entryName);
    }
  }

  calcExerciseValues(exerciseName){
      switch (exerciseName){
          case "LoanAmount":
            this.loanAmountDisabled = true;
            break;
          case "InterestRate":
             this.interestRateButtonDisabled = true;
             break;
          case "LoanTerm":
             this.loanTermButtonDisabled = true;
             break;
          default:
             break;
      }
      this.processExerciseValues(exerciseName);
  }

  processExerciseValues(exerciseName){
      this.loanParmArr = [];
      this.resetExerciseButtons();
      const exerciseObj = 
        LoanExerciseDataset.find(elem => elem.exerciseName === exerciseName);
      this.exerciseText = exerciseObj.text;
      this.conclusionText = exerciseObj.conclusion;
      const loanParmArr = exerciseObj.parametersArr;
      for (let i=0; i<loanParmArr.length; i++){
           const loanAmount = loanParmArr[i].loanAmount;
           const intRate = loanParmArr[i].intRate;
           const nbrYears = loanParmArr[i].nbrYears;
           this.calcValuesEntered(loanAmount, intRate, nbrYears);
      }
  }
  
  calcValuesEntered(loanAmount, intRate, nbrYears){   
   
    var monthlyLnAmt = monthlyLoanAmt(loanAmount, intRate, nbrYears, 
           this.state.loanType)
    var totalLoanMonths = nbrYears * 12;
    var totalAmtPaid = monthlyLnAmt * totalLoanMonths;
    let intRateLit = (+intRate * 100).toFixed(0);
    intRateLit = intRateLit + "%"
    var loanTermLit = (nbrYears + " years")
    var loanInfoString={loanAmt: loanAmount, intRate: intRateLit, loanTerm: loanTermLit,
               monthlyAmt: monthlyLnAmt, totalPaid: totalAmtPaid}       
    this.loanParmArr.push(loanInfoString);
    if (this.loanParmArr.length > 5){
      this.loanParmArr.splice(0,1);
      this.message = 
        "Max of 5 calculations, the first entry has rolled off.  Click on the 'Clear/Reset' " +
          " button if you wish to clear all entries and start again."
}
  }

  clearLoanParameters(){
    this.resetExerciseButtons();
    this.exerciseText = "";
    var i;
    for (i=0; i<=this.loanParmArr.length; i++){
        delete this.loanParmArr[i];
    }
    this.loanParmArr.length = 0;
  }

  resetExerciseButtons(){
      this.loanAmountButtonDisabled = false;
      this.interestRateButtonDisabled = false;
      this.loanTermButtonDisabled = false;
      this.multipleFactorsButtonDisabled = false;
  }
  
  expandIntroText(){
      this.setState({expandIntroText: true});
  }

  collapseIntroText(){
      this.setState({expandIntroText: false});
  }
 
  render() { 
     this.gutters = "";
     if (this.props.screenSize === "narrow" || this.props.screenSize === "medium"){
        this.gutters = "modal-95";
     }
     this.summaryRecordCounts = this.loanParmArr.map((counts,index) =>
     <SixColumnDisplayCard key={index} tableInfo={counts} index={index} tableType={"loans"}
         />
   );  
   if (this.state.expandIntroText === true){
    this.numberedList = this.textArr.map((textLine, key) =>
        <NumberedListCard key={key} text={textLine} />
  );
}
  return <>
    <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="lg" scrollable="true"
       dialogClassName={this.gutters} animation={false}>
      <Modal.Header className="backgroundTopBottom" closeButton>
        <Modal.Title>Loan Calculator</Modal.Title> 
      </Modal.Header>
      <Modal.Body>
        {this.props.calculatorSw === false  ?
        <span>
          <div className="row-100 ml-2 mr-2 mt-1">
            There are 3 key factors that impact what you have to pay on loans.
               The factors are the total amount of the loan, the cost of the loan (interest rate) 
               and the length of the loan (also called the loan term).  Interest rates
               are the amount that the lender charges in order to make the loan.  This is always
               a percentage of the total loan amount.  These three factors affect
               the amount you have to pay each month and the total amount that you will pay
               over the life of the loan. 
                  
               {this.state.expandIntroText ?
                    <div className="mt-2">
                        {this.introText}  
                        {this.numberedList}
                 
                        <span className="text-right">
                            <Button variant="outline-info" size="sm"
                                onClick={() => this.collapseIntroText()}>
                                    Display Less
                            </Button>
                        </span>
                    </div>
              :   
                    <span className="ml-4">
                        <Button variant="outline-info" size="sm"
                            onClick={() => this.expandIntroText()}>
                              Display More
                        </Button>
                    </span> 
              }  
              {this.props.loanChallenges   ?
              <div className="ml-2 font-weight-bold">
                Click the 'Close' button when you are done and ready to tackle the loan challenges.
              </div>
              :
                null
              }
          </div>  
        </span>
        :
                <p></p>
         }
        <span className="row w-80 mt-2 ml-4 mr-0">
            <div className="col-sm-4 p-0">
              <div>Loan Amount: &nbsp;
              <select id = "dropdown" ref = {(input)=> this.loanAmount = input}>
                  <option value="2000">$2000</option>
                  <option value="5000">$5000</option>
                  <option value="10000">$10,000</option>
                  <option value="15000">$15,000</option>
                  <option value="20000">$20,000</option>
                  <option value="50000">$50,000</option>
                  <option value="75000">$75,000</option>
                  <option value="85000">$85,000</option>
                  <option value="100000">$100,000</option>
                  <option value="150000">$150,000</option>
                  <option value="200000">$200,000</option> 
                  <option value="250000">$250,000</option>
                  <option value="300000">$300,000</option>
                  <option value="350000">$350,000</option>
                  <option value="400000">$400,000</option>
                  <option value="450000">$450,000</option>
               </select> </div>
            </div>
              
            <div className="col-sm-4 p-0">  
              <p>Interest Rate: &nbsp;
              <select id = "dropdown" ref = {(input)=> this.intRate = input}>
                  <option value=".03">3%</option>
                  <option value=".04">4%</option>
                  <option value=".05">5%</option>
                  <option value=".06">6%</option>
                  <option value=".07">7%</option>
                  <option value=".08">8%</option>
                  <option value=".10">10%</option>
                  <option value=".20">20%</option>
              </select></p>
            </div>

            <div className="col-sm-4 p-0">
              <p>Loan Term: &nbsp;
              <select id = "dropdown" ref = {(input)=> this.nbrYears = input}>
                  <option value="1">1 Year</option>
                  <option value="2">2 Years</option>
                  <option value="5">5 Years</option>
                  <option value="10">10 Years</option>
                  <option value="15">15 Years</option>
                  <option value="20">20 Years</option>
                  <option value="30">30 Years</option>
              </select></p>
            </div>  
          </span> 
          <table className="table table-hover">
            <thead>
              <tr className="thead-light">
                <th></th>
                <th className="p-0 text-right" scope="col">Loan Amount</th>
                <th className="p-0 text-right" scope="col">Interest Rate</th>
                <th className="p-0 text-right" scope="col">Loan Term</th>
                <th className="p-0 text-right" scope="col">Monthly Amount</th>
                <th className="p-0 text-right" scope="col">Total Amount Paid</th>
              </tr>
            </thead>
          <tbody>
              {this.summaryRecordCounts}
          </tbody>
        </table>
        <div className="mt-2">
              <b>Learn more about the loan factors: </b> Enter your own values (above) and click on the Calculate button
              ...OR... click on one of the buttons below.  When you are done, click the Close button.
              <div>
                <Button className="mt-2"
                    disabled={this.loanAmountButtonDisabled} variant="info"  
                    onClick={() => this.calculateLoanAmounts("LoanAmount")}>
                        Loan Amount
                </Button>
                <Button className="mt-2 ml-2"
                    disabled={this.interestRateButtonDisabled} variant="info"  
                    onClick={() => this.calculateLoanAmounts("InterestRate")}>
                        Interest Rate
                </Button>
                <Button className="mt-2 ml-2"
                    disabled={this.loanTermButtonDisabled} variant="info"  
                    onClick={() => this.calculateLoanAmounts("LoanTerm")}>
                        Loan Term
                </Button>
              </div>
          </div>
          <div className="text-left">
              {this.exerciseText}
         </div>
         {this.exerciseText !== "" ?
            <div><b>Conclusion:</b>&nbsp;{this.conclusionText}</div>
          :
            null
         }
  
        </Modal.Body>
        
        <Modal.Footer className="backgroundTopBottom">
              <div  className="text-right font-weight-bold">{this.message}</div>
              <Button variant="primary"  onClick={() => this.props.onClose()}>
                  Close
              </Button>
              <Button variant="primary"   onClick={() => this.clearLoanParameters()}>
                  Clear/Reset
              </Button>
              <Button variant="primary"  onClick={() => this.calculateLoanAmounts("Entered")}>
                  Calculate
              </Button>
         </Modal.Footer>
      </Modal>

      
    </>
  }

 
}
export default LoanCalculatorModal;