import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ExtraCreditMainText from './text/extra_credit_main_text';
import TwoColumnTableCard from '../game-views/two_column_table_card';

class ExtraCreditResultsModal extends Component {
  constructor(props) {
    super(props);
    this.gameInfo = this.props.gameInfo();
    this.state={
         processingCompleteSw: false,
         callRender: false,
        
    };    
    this.totalCashDollars = 0;
    this.totalSalaryDollars = 0;
    this.cashAmtArr = [];
    this.salaryAmtArr = [];
    this.congratsText = "";
    this.calculateTotalExtraCreditPoints();
}
  
calculateTotalExtraCreditPoints() {
    if (this.gameInfo.extraCredit.extraCreditTable === undefined){
        this.noExtraCreditPoints = true;
    }else{
        this.noExtraCreditPoints = false;  
        this.processExtraCreditTable();
        this.setCongratsText();
    }
}
  
processExtraCreditTable(){
    // this reviews the extra credit items that have already been completed.
    var i;
    var extraCreditTable = [];
    
    extraCreditTable = this.gameInfo.extraCredit.extraCreditTable;
     this.totalCashDollars  = 0;    
     this.totalSalaryDollars  = 0;
    this.totalChallengesCompleted = extraCreditTable.length;
        
    for (i=0; i < extraCreditTable.length; i++){
        this.extraCreditObject = extraCreditTable[i];
        this.setExtraCreditCategory();
        this.buildOutputTable();
    }
    let tableObj = {label: "Total Added to Cash", amount:  this.totalCashDollars, bold: true };
    this.cashAmtArr.push(tableObj);
    tableObj = {label: "Total Added to Salary", amount:  this.totalSalaryDollars, bold: true };
    this.salaryAmtArr.push(tableObj);
    this.setState({callRender: true});
}

setExtraCreditCategory(){

    switch (this.extraCreditObject.category) {
        case "MoneyMgmt":
            this.extraCreditCategory = "Money Management";
            break;
        case "Priorities":
            this.extraCreditCategory = "Priorities";
            break;
        case "ChoosingCareer":
            this.extraCreditCategory = "Choosing Career";
            break;
        case "LivingBelowYourMeans":
            this.extraCreditCategory = "Living Below Your Means";
            break;
        case "LearningAboutLoans": 
            this.extraCreditCategory = "Learning About Loans";
            break;
        case "SavingMoney":
            this.extraCreditCategory = "Saving Money";
            break;
        default:
            break;
    }
   
}

 buildOutputTable(){
    let tableObj = {label: this.extraCreditCategory + " Challenge", amount: +this.extraCreditObject.amount};
    if (this.extraCreditObject.assetType === "cash"){
        this.cashAmtArr.push(tableObj);
         this.totalCashDollars  =  this.totalCashDollars  + +this.extraCreditObject.amount;
        
    }else{
        this.salaryAmtArr.push(tableObj);
         this.totalSalaryDollars  =  this.totalSalaryDollars  + +this.extraCreditObject.amount;
    }
 }

  setCongratsText(){
    const text = ExtraCreditMainText;
    let previousProcessName = sessionStorage.getItem("previousExtraCreditProcess");
    if (previousProcessName !== null){
        this.congratsText = text.replace("<InsertChallengeName>", previousProcessName);
        sessionStorage.removeItem("previousExtraCreditProcess");
        sessionStorage.setItem("extraCreditChallengeCompleted", "completed");
    }
  }
  
  render() { 
      if ( this.totalCashDollars  > 0){ 
        this.cashAmtList = this.cashAmtArr.map((cashAmts,key) =>
            <TwoColumnTableCard key={key} infoType="label-amount" outInfo={cashAmts}  />
                );  
      }
      if ( this.totalSalaryDollars  > 0){ 
        this.salaryAmtList = this.salaryAmtArr.map((salaryAmts,key) =>
            <TwoColumnTableCard key={key} infoType="label-amount" outInfo={salaryAmts}  />
                );  
      }
 
  return <>
   
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true"
            animation={false}>
          <Modal.Header  closeButton>
            <Modal.Title>Extra Credit Results</Modal.Title> 
          </Modal.Header>
          <Modal.Body >
            <div className="mr-2 ml-2">
                  {this.noExtraCreditPoints ?
                      <div>
                          You have not earned any Extra Credit Points yet. Close this pop-up and then 
                          click on one of the buttons to the right to get started earning points.  
                      </div>
                  :
                      <div></div>
                  }
                  {this.congratsText !== "" ?
                    <div>
                        <h6 className="mt-2 text-center font-weight-bold">Congratulations!</h6>  
                        <div className="text-bold">{this.congratsText}</div>
                    </div>
                  :
                    <div></div>
                  }
      

                  { this.totalCashDollars  > 0    ?
                        <span>
                            <div>
                                {!this.gameInfo.gameExists ?
                                    <div className="mt-2">You do not currently have an active game.  If you
                                        click on the "Game Icon" (from the home page) 
                                        and complete the "Getting Started" part of the game, 
                                        the extra credit amounts that you have earned will be 
                                        added to your starting amounts.
                                    </div>    
                                :
                                    null
                                }
                            </div>
                            <div className="table-light-grey-background row-100 ml-5 mr-5 mt-2">
                            <h6 className="text-center">Amounts Added to Cash</h6>
                            <table className="table">
                            <tbody>
                               {this.cashAmtList}
                            </tbody>
                            </table>
                            </div>
                        </span>
                        :
                                null
                    }
                    <div className="mt-5"></div>
                        
                    { this.totalSalaryDollars  > 0    ?
                        <span>
                            <div className="table-light-grey-background row-100 ml-5 mr-5 mt-2">
                            <h6 className="text-center">Amounts Added to Monthly Salary</h6>
                            <table className="table">
                                <tbody>
                                   {this.salaryAmtList}
                                </tbody>    
                            </table>
                        </div>
                        </span>
                 
                :
                    <div></div>
                }
                </div>
              </Modal.Body>
          <Modal.Footer className="text-right">
          <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"
            alt="train"/>
          <Button className="mr-2" variant="info"   onClick={() => this.props.onClose()}>
               Close
            </Button>
          </Modal.Footer>
          </Modal>
    
    </>
  }

 
}
export default ExtraCreditResultsModal;