import BackgroundImageDataset from "../datasets/background_image_dataset";

export const editHeaderBackground = (background, source, responseLit) => {
  var errorObj="";
  var backgroundValid = true;
  var errMessage = "";

  if (background === undefined){
    backgroundValid = false;
    errMessage = "Category Undefined";
  }else{
    if (source === "online"){
      if (background === "No-Background") {
        backgroundValid = false;
        errMessage = "Select Background";
      }
    }else{
      let backgroundFound = BackgroundImageDataset.some(elem => elem.value === background)
      if (backgroundFound === false){
          backgroundValid = false;
          errMessage = "Background not found in dataset";
      }
    }
  }
  if (backgroundValid === false){
     errorObj = {fieldClass: responseLit, fieldName: "Background", fieldValue: background, message: errMessage};
  }
 
  return errorObj;
 
}