const EventCategoryList =[
  {
    "number": 1, "category": "car", "textName": "Car", "colorName": "green", "colorCode": "#22B14C"
  },
  {
    "number": 2, "category": "home", "textName": "Home", "colorName": "purple", "colorCode": "#A349A4"
  },
  {
    "number": 3, "category": "job", "textName": "Work Related", "colorName": "red", "colorCode": "#ED1C24"
  },
  {
    "number":  4, "category": "friend", "textName": "Friend Drops In", "colorName": "turquoise", "colorCode": "#00A2E8"
  },
  {
    "number": 5, "category": "miscExpenses", "textName": "Spending Money", "colorName": "orange", "colorCode": "#FF7F27"
  },
  {
    "number": 6, "category": "joke", "textName": "Just for Fun", "colorName": "rose", "colorCode": "#FFAEC9"
  },
  {
    "number": 7, "category": "other", "textName": "Grab Bag", "colorName": "yellow", "colorCode": "#FFF200"
  },
  {
    "number": 8, "category": "endTurn", "textName": "End of Quarter", "colorName": "grey", "colorCode": "#C3C3C3"
  },
  
  
];

export default EventCategoryList;