import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import { determineCollegeLoanObj } from '../../objects/AssetsExpenses/determine_college_loan_obj';
import {monthlyLoanAmt} from '../../objects/AssetsExpenses/loanAmtCalc_function';
import { monthlyExpenseAmt } from '../../objects/monthlyCalc/monthlyAmt_function';
import {calcTaxAmt} from '../../objects/AssetsExpenses/taxAmtCalc_function';
import ConfigFile from '../../datasets/config_file';
import DollarAmountCard from './dollar_amount_card';
import {findDataObject} from '../../objects/CommonUse/find_data_object';
import { addChangeSalaryAsset } from '../../objects/AssetsExpenses/add_change_salary_asset';
import determineMoodPoints from '../../objects/CommonUse/determine_mood_points';
import { createMoodPointArr } from '../shared-components/objects/create_mood_point_arr';

class FinalConfirmModal extends Component {
  carInterest=ConfigFile.carItems.carInterest;
  carLoanTerm=ConfigFile.carItems.carLoanTerm;
  kitchenCost = ConfigFile.homeItems.kitchenCost;
  miscSupplies = ConfigFile.homeItems.miscSupplies;

  constructor(props) {
    super(props);
    this.state={
      modalReady: false,
      buttonDisabled: false,
    };   
    this.prepareFinalModal(); 
  }
  
  prepareFinalModal() {
    this.retrieveUserInputs();
   
    // Need to review from below on
    this.buildLoanObject();
    this.buildExpenseObject();
    this.calculateMonthlyTotal();
    this.calculateStartingTotal();
    this.determineStartingMoodPoints();
  }
  
  retrieveUserInputs()
  {
      this.jobName = sessionStorage.getItem("careerName");
      this.previousJobName = sessionStorage.getItem("previousJobName");
      if (this.jobName === 'undefined' || this.jobName !== this.previousJobName)
      {  
          this.jobChanged = true;
      } else {
          this.jobChanged = false;
          this.totalTaxAmt = parseFloat(sessionStorage.getItem("jobTaxAmt"));
      };
      this.salary = (+sessionStorage.getItem("salary") * 1).toFixed(2);
       // Set current Year and Month (will serve as game start date)
      this.setCurrentYYYYMM();
      this.updateJobData();
      if (this.props.extraCreditData.user != undefined){
          this.createExtraCreditAssets();
      }
      if (this.props.badgesEarned != undefined &&
          this.props.badgesEarned.length != 0){
          this.createBadgesEarnedAssets();
      }
      sessionStorage.setItem("assetObjects", JSON.stringify(this.assetArr));
      this.totalAnnualSalary = ((+this.salary + +this.totalSalaryIncrease) * 12).toFixed(0);
      this.getTaxAmount();
      this.homeName = sessionStorage.getItem("homeName");
      this.homeCost = sessionStorage.getItem("homeCost");
      this.roommates = sessionStorage.getItem("homeRoommates");
      this.bedrooms = sessionStorage.getItem("homeBedrooms");
      
      this.furnitureName = sessionStorage.getItem("furnitureName");
      this.furnitureCost = sessionStorage.getItem("furnitureCost");
      this.carName = sessionStorage.getItem("carName");
      this.carCost=Number.parseFloat(sessionStorage.getItem("carCost"));
      this.carMPG=Number.parseFloat(sessionStorage.getItem("carMPG"));
      this.carAgeNbr=sessionStorage.getItem("carAgeNbr");
      this.carName=sessionStorage.getItem("carName");
      this.jobHealthInsInd = sessionStorage.getItem("jobHealthInsInd");
   }

  
  setCurrentYYYYMM()
  {
    var today = new Date();
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.currentYYYYMM = `${yyyy}-${mm}`;
      sessionStorage.setItem("currentYYYYMM", this.currentYYYYMM);
  }

  async updateJobData()
  {
    this.assetArr = [];
    this.annualSalary = (+this.salary * 12).toFixed(2);
    this.jobDescription = this.jobName + " --> Annual Salary: " +
      doubleToStringMonthly(this.annualSalary);
      
    let assetObj = {assetItem: "Job Salary", assetDesc: this.jobDescription,
      assetAmt: this.salary, assetStartDate: this.currentYYYYMM, assetNbrMonths: "-1"};
    this.assetArr.push(assetObj);
  }

  createExtraCreditAssets(){
    let extraCreditArr = this.props.extraCreditData.extraCreditTable;
    this.extraCreditArr = [];
    if (extraCreditArr != undefined){
      for (let i=0; i<extraCreditArr.length; i++){
          if (extraCreditArr[i].assetType == "salary"){
            let processName = extraCreditArr[i].category;
            let salaryIncrease = (+extraCreditArr[i].amount).toFixed(2);
            salaryIncrease = salaryIncrease.toString();
            let assetArrObj = addChangeSalaryAsset(this.assetArr, "ExtraCreditBonus", this.currentYYYYMM,
              salaryIncrease, processName); 
            this.totalSalaryIncrease = (+this.totalSalaryIncrease + +salaryIncrease).toFixed(2);
            this.assetArr = assetArrObj.assetTable;
            const salaryChangeObj = {processName: processName, assetType: "salary", 
              amount: salaryIncrease};
            this.extraCreditArr.push(salaryChangeObj);
          }
      }
    }
  }

  createBadgesEarnedAssets(){
    let badgesEarnedArr = this.props.badgesEarned;
    this.badgesEarnedArr = [];
    for (let i=0; i<badgesEarnedArr.length; i++){
        if (badgesEarnedArr[i].awardType == "salary"){
            let processName = badgesEarnedArr[i].badgeName;
            let salaryIncrease = +badgesEarnedArr[i].awardAmount;
            let assetArrObj = addChangeSalaryAsset(this.assetArr, "BadgeBonus", this.currentYYYYMM,
              salaryIncrease, processName); 
            this.assetArr = assetArrObj.assetTable;
            this.totalSalaryIncrease = +this.totalSalaryIncrease + +salaryIncrease;
            const salaryChangeObj = {processName: processName, assetType: "salary", 
              amount: salaryIncrease};
            this.badgesEarnedArr.push(salaryChangeObj);
        }
    }
  }

  getTaxAmount() {
      this.totalTaxAmt = calcTaxAmt(this.assetArr);
      sessionStorage.setItem("previousJobName", this.jobName);
      this.taxObj = {expItem: "Payroll taxes", expDesc: "Federal, FICA (Social Security)", 
            expAmount: this.totalTaxAmt, expStartDate: this.currentYYYYMM, expNbrMonths: "-1",
            chargeCreditCard: false};
  }
 
 buildLoanObject() {
      this.loanData = [];
      this.setCarLoanAmt();
      this.calculateCollegeCostAmt();
      sessionStorage.setItem("loanDataObjects", JSON.stringify(this.loanData));
  }
 
  setCarLoanAmt() {
        this.carLoanAmt = monthlyLoanAmt(this.carCost, this.carInterest, this.carLoanTerm, "car");
        let nbrMonths = this.carLoanTerm * 12;
        let loanObj = {loanType: "Car Loan", loanDesc: this.carName,
          loanAmt: this.carLoanAmt, loanStartDate: this.currentYYYYMM,
          loanNbrMonths: nbrMonths, loanTotAmt: this.carCost, loanIntRate: this.carInterest,
          loanTerm: this.carLoanTerm};
      this.loanData.push(loanObj);  
  }
   
  calculateCollegeCostAmt(){
        this.nbrYearsCollege = sessionStorage.getItem("jobNbrYearsCollege");
        this.degreeType = sessionStorage.getItem("jobDegreeType");
        this.degreeSpecialty = sessionStorage.getItem("jobDegreeSpecialty")
        let loanDesc = this.degreeType + "-" + this.degreeSpecialty + "(" + this.nbrYearsCollege + " years)";
        let collegeLoanObj = determineCollegeLoanObj(this.nbrYearsCollege, loanDesc, this.currentYYYYMM)
        this.loanData.push(collegeLoanObj);   
  }

  buildExpenseObject(){
        this.expenseObj = [];
        this.expenseObj = monthlyExpenseAmt(this.homeCost, this.roommates, this.bedrooms, this.homeName, 
          this.jobHealthInsInd, this.carMPG, this.currentYYYYMM, this.currentYYYYMM);
        this.expenseObj.splice(0, 0, this.taxObj);
        sessionStorage.setItem("expenseObjects", JSON.stringify(this.expenseObj));
   }
 
  calculateMonthlyTotal() {
    let assets = 0;
    for (let i=0; i<this.assetArr.length; i++){
       assets += +this.assetArr[i].assetAmt;
    }
    let liabilities = 0;
    for(let i=0; i<this.loanData.length; i++){
        liabilities += parseFloat(this.loanData[i].loanAmt);
    }
    for (let i=0; i< this.expenseObj.length; ++i) 
    {
        liabilities += parseFloat(this.expenseObj[i].expAmount);
    }  
   
    let totalAmt = (assets - liabilities).toFixed(2);
    this.monthlyTotalAmt = totalAmt;
   }

  calculateStartingTotal() {
    this.determineStartingCash();
    this.buildOneTimeCostArr();
    let assets = 0;
    for (let i=0; i<this.startingCashArr.length; i++){
        assets += +this.startingCashArr[i].amount;
    }

    let liabilities = 0;

    for (let i=0; i < this.oneTimeExpenseArr.length; i++){
        liabilities += this.oneTimeExpenseArr[i].oneTimeAmt; 
    }
  
    this.startingTotalAmt = assets - liabilities;
    sessionStorage.setItem('startingCash', (this.startingTotalAmt).toFixed(2));
    let spendingHistory = findDataObject("spendingHistory").spendingHistoryInfo;
    spendingHistory.current10YearSpendAmt = liabilities;
    spendingHistory.current10YearMonths = 1;
    sessionStorage.setItem("spendingHistory", JSON.stringify(spendingHistory));
  }

  buildOneTimeCostArr(){
     this.oneTimeExpenseArr = [];
     var oneTimeExpObj = {};
     if (this.props.extraCreditData.result == undefined){
          this.checkForExtraCreditCashAmts();
     }
     if (this.props.badgesEarned != undefined &&
         this.props.badgesEarned.length != 0){
          this.checkForBadgesEarnedCashAmts();
     }
     this.damageDeposit = parseFloat(this.homeCost);
     if (+this.roommates >= 1){
         this.damageDeposit = this.damageDeposit / (+this.roommates + 1);
     }
     oneTimeExpObj = {oneTimeItem: "Apartment Damage Deposit", oneTimeDesc: "1 month's rent", oneTimeAmt: this.damageDeposit};
     this.oneTimeExpenseArr.push(oneTimeExpObj);
   
     oneTimeExpObj = {oneTimeItem: "Furniture", oneTimeDesc: this.furnitureName, oneTimeAmt: +this.furnitureCost};
     this.oneTimeExpenseArr.push(oneTimeExpObj);

     oneTimeExpObj = {oneTimeItem: "Kitchen Supplies", oneTimeDesc: "Pots and Pans, Mixing Bowls, Dishes, Silverware, " +
      "Kitchen Towels", oneTimeAmt: +this.kitchenCost};
     this.oneTimeExpenseArr.push(oneTimeExpObj);
 
     oneTimeExpObj = {oneTimeItem: "Misc Supplies", oneTimeDesc: "Bath Towels, Bed Sheets, Cleaning Supplies", 
      oneTimeAmt: +this.miscSupplies};
     this.oneTimeExpenseArr.push(oneTimeExpObj);
  } 

  determineStartingCash(){
    this.startingCashArr = [];
    this.nbrYearsWithParents = 4 - this.nbrYearsCollege;
    this.startingCash = (this.nbrYearsWithParents * 5000) + 3000;
    let startingCashObj = {item: "Starting Cash", desc: "Personal Savings Amount", 
            amount: +this.startingCash};
    this.startingCashArr.push(startingCashObj);
  }

  determineStartingMoodPoints(){
      this.startingMoodPoints = ConfigFile.gameStartItems.startingMoodPoints;
      let gameChoiceArr = createMoodPointArr("all");
      this.moodPointStartArr = determineMoodPoints(gameChoiceArr);
      this.totalMoodPoints = +this.startingMoodPoints;
      for (let i=0; i<this.moodPointStartArr.length; i++){
           this.totalMoodPoints += +this.moodPointStartArr[i].number;
      }
      let moodPointHistory = findDataObject("moodPointHistory").moodPointHistoryInfo;
      moodPointHistory.moodPointMonths = 1;
      sessionStorage.setItem("startingMoodPoints", this.totalMoodPoints);
      sessionStorage.setItem("moodPointHistory", JSON.stringify(moodPointHistory));
  }

  checkForExtraCreditCashAmts(){
    let extraCreditArr = this.props.extraCreditData.extraCreditTable;
    let oneTimeDesc = "Extra Credit - "
    let processName = "";
    let extraCreditAmt = 0;
    if (extraCreditArr != undefined){
      for (let i=0; i<extraCreditArr.length; i++){
        if (extraCreditArr[i].assetType == "cash"){
           processName = extraCreditArr[i].category;
           oneTimeDesc +=  processName + ", ";
           extraCreditAmt = +extraCreditAmt + +extraCreditArr[i].amount;
        }
      }
      if (extraCreditAmt > 0){
          let startingCashObj = {item: "Cash Earned", desc: oneTimeDesc, 
              amount: extraCreditAmt};
          this.startingCashArr.push(startingCashObj);
      }
    }
  }

    checkForBadgesEarnedCashAmts(){
    let badgesEarnedArr = this.props.badgesEarned;
    let oneTimeDesc = "Badges Earned - ";
    let badgesAmt = 0;
    if (badgesEarnedArr != undefined){
      for (let i=0; i<badgesEarnedArr.length; i++){
        if (badgesEarnedArr[i].awardType == "cash"){
          let processName = badgesEarnedArr[i].badgeName;
          oneTimeDesc +=  processName + ", ";
          badgesAmt = +badgesAmt + +badgesEarnedArr[i].awardAmount;
        }
      }
      if (badgesAmt > 0){
          let startingCashObj = {item: "Cash Earned", desc: oneTimeDesc, 
            amount: +badgesAmt};
          this.startingCashArr.push(startingCashObj);
      }
    }
  }

  render() {
                
            this.assetList = this.assetArr.map((assetAmt,key) =>
              <DollarAmountCard  key={key}  amtType="asset" amountInfo={assetAmt} className = ""
                  gameDate={this.currentYYYYMM}
              />
              );
            this.startingCashList = this.startingCashArr.map((startingCash,key) =>
              <DollarAmountCard  key={key}  amtType="general" amountInfo={startingCash} className = ""
                  gameDate={this.currentYYYYMM}
              />
            );
            this.expenseList = this.expenseObj.map((expenseAmt,key) =>
              <DollarAmountCard  key={key}  amtType="expense" amountInfo={expenseAmt} className = "text-danger"
                  gameDate={this.currentYYYYMM}
              />
            );
            this.loanList = this.loanData.map((loanAmt,key) =>
                <DollarAmountCard  key={key}  amtType="loan" amountInfo={loanAmt} className = "text-danger"
                  gameDate={this.currentYYYYMM}   
                />
            );

            this.oneTimeExpenseList = this.oneTimeExpenseArr.map((oneTimeAmt,key) =>
                <DollarAmountCard  key={key}  amtType="oneTime" amountInfo={oneTimeAmt} className = "text-danger"
                    gameDate={this.currentYYYYMM}
                />
            );

            this.moodPointList = this.moodPointStartArr.map((moodPoints,key) =>
            <DollarAmountCard  key={key}  amtType="moodPoints" amountInfo={moodPoints} className = ""
                gameDate={this.currentYYYYMM}
            />
        );
    return <>
      <Modal show={this.props.modalOpen}  onHide={() => this.props.onClose()} size="xl" scrollable="true"
          dialogClassName={this.props.gutters} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Review Selections - Monthly Budget and Starting Cash (Scroll Down to See Action Buttons)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>Review the information below. If you wish to change any of the decisions that you have made in this
             part of the game, click the 'Close' button and use the buttons on the right to change any of your decisions.
             When the financial information looks good to you, click the 'Start' button.  Scroll down to view and click
             on the 'Close' and 'Start' buttons. </div>
        <h5 className="text-center">Monthly Budget Amounts</h5>
        <table className="table table-hover">
          <thead>
            <tr className="thead-light">
              <th className="p-0" scope="col">Item</th>
              <th className="p-0" scope="col">Description</th>
              <th className="p-0 text-right" scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {this.assetList}
            {this.expenseList}
            {this.loanList}
            {this.monthlyTotalAmt < 0 ?           
              <tr className="text-danger">
                 <th className="p-0" scope="row" colSpan="2">Monthly Budget Total</th>
                 <th className="p-0 text-right">- {doubleToStringMonthly(this.monthlyTotalAmt * -1)}</th>
               </tr>
            :
              <tr>
                <th className="p-0" scope="row" colSpan="2">Monthly Total</th>
                <th className="p-0 text-right">{doubleToStringMonthly(this.monthlyTotalAmt)}</th>
              </tr>
            }
        </tbody>
    </table>

    <h5 className="text-center">Starting Cash</h5>   
    {this.nbrYearsCollege < 4 ?
        <p className="ml-4 mr-4">After you got your first job, you decided to live with your parents 
          for {this.nbrYearsWithParents} years so that you could save some money.  You paid your parents a
          nominal amount for rent but you were able to save quite a bit of money.  Now you have 
          decided it is time to move-out to live on your own.</p>
    :
        <p className = "ml-4 mr-4">Since you decided to go to college, you were not able to save much
          money for the past 4 years, however, you did have a part-time job over the previous summers and 
          have managed to save some money before moving out on your own. </p>
    }     
    <table className="table table-hover">
        <thead>
            <tr className="thead-light">
              <th className="p-0" scope="col">Item</th>
              <th className="p-0" scope="col">Description</th>
              <th className="p-0 text-right" scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {this.startingCashList} 
            {this.oneTimeExpenseList}
            {this.startingTotalAmt < 0 ?
                <tr className="text-danger">
                    <th className="p-0" scope="row" colSpan="2">Starting Cash Total</th>
                    <th className="p-0 text-right">- {doubleToStringMonthly(this.startingTotalAmt * -1)}</th>
                 </tr>
              :
                 <tr>
                    <th className="p-0" scope="row" colSpan="2">Starting Cash Total</th>
                    <th className="p-0 text-right">{doubleToStringMonthly(this.startingTotalAmt)}</th>
                 </tr>
              }
              </tbody>
    </table>
    <h5 className="text-center">Starting Mood Points</h5>   
      <p className="ml-4 mr-4">The choices that you made not only impact your cash amounts,
         but also determine the mood points that you will have when you start the
         next part of the game.</p>
      
    <table className="table table-hover">
        <thead>
            <tr className="thead-light">
              <th className="p-0" scope="col">Item</th>
              <th className="p-0" scope="col">Description</th>
              <th className="p-0 text-right" scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
              <tr className="">
                    <th className="p-0" scope="row" colSpan="2">Mood Point Base</th>
                    <th className="p-0 text-right">{this.startingMoodPoints}</th>
              </tr>
              {this.moodPointList}
              <tr className="">
                    <th className="p-0" scope="row" colSpan="2">Total Starting Mood Points</th>
                    <th className="p-0 text-right">{this.totalMoodPoints}</th>
              </tr>
          </tbody>
    </table>
            <div className="text-right">
                <Button className="mr-2" variant="secondary"  onClick={() => this.props.onClose()}>
                    Close
                </Button>
                <Button variant="primary"  disabled={this.state.buttonDisabled} 
                    onClick={() => this.disableButton()}>
                    Start
                </Button>
            </div>
        </Modal.Body>
      </Modal>
     
    </>
  }

  componentDidMount()
  {
    this.prepareFinalModal();
  }

  disableButton(){
    this.state.buttonDisabled = true;
    this.props.onSave();
  }

 
}
export default FinalConfirmModal;