const TaxTableArray =[  {"salary": 10000,"federal": 0, "fica": 765},
                        {"salary": 11000, "federal": 0, "fica": 842},
                        {"salary": 12000, "federal": 0, "fica": 918},
                        {"salary": 13000, "federal": 60, "fica": 995},
                        {"salary": 14000, "federal": 160, "fica": 1071},
                        {"salary": 15000, "federal": 260, "fica": 1148},
                        {"salary": 16000, "federal": 360, "fica": 1224},
                        {"salary": 17000, "federal": 460, "fica": 1301},
                        {"salary": 18000, "federal": 560, "fica": 1377},
                        {"salary": 19000, "federal": 660, "fica": 1454},                       
                        {"salary": 20000, "federal": 760, "fica": 1530},
                        {"salary": 25000, "federal": 1315, "fica": 1913},
                        {"salary": 30000, "federal": 1915, "fica": 2295},
                        {"salary": 35000, "federal": 2515, "fica": 2678},
                        {"salary": 40000, "federal": 3115, "fica": 3060},
                        {"salary": 45000, "federal": 3715, "fica": 3443},
                        {"salary": 50000, "federal": 4315, "fica": 3825},
                        {"salary": 55000, "federal": 5162, "fica": 4208},
                        {"salary": 60000, "federal": 6262, "fica": 4590},
                        {"salary": 65000, "federal": 7362, "fica": 4973},
                        {"salary": 70000, "federal": 8462, "fica": 5355},
                        {"salary": 75000, "federal": 9562, "fica": 5738},
                        {"salary": 80000, "federal": 10662, "fica": 6120},
                        {"salary": 85000, "federal": 11762, "fica": 6503},
                        {"salary": 90000, "federal": 12862, "fica": 6885},
                        {"salary": 95000, "federal": 13962, "fica": 7268},
                        {"salary": 100000, "federal": 15104, "fica": 7650},
                        {"salary": 105000, "federal": 16304, "fica": 8033},
                        {"salary": 110000, "federal": 17504, "fica": 8415},
                        {"salary": 115000, "federal": 18704, "fica": 8798}

];
export default TaxTableArray   