const EventOnLitList =[
  //  creditCard switch indicates whether or not this charge can be billed to a credit card (if a card is active)
  //  negativeAmts switch indicates if a negative amount is an expense (only used for the edit report)
  {
    "category": "cash",
    "value": "cash",
    "selection": "Update Current Cash",
    "creditCard": true,
    "negativeAmts": true, 
  },
  {
    "category": "cash",
    "value": "cashOnly",
    "selection": "Update Cash-do not apply to Credit Card",
    "creditCard": false,
    "negativeAmts": true,
  },
  {
    "category": "asset",
    "value": "Job Salary",
    "selection": "Update Asset Salary",
   },
  {
    "category": "expense",
    "value": "Apartment Rent",
    "selection": "Update Expense-Rent",
    "creditCard": false,
  },
  {
    "category": "expense",
    "value": "Health Insurance",
    "selection": "Expense-Health Ins",
    "creditCard": false,
  },
  {
    "category": "expense",
    "value": "Renter's Insurance",
    "selection": "Expense-Rent Insurance",
    "creditCard": false,
  },
  {
    "category": "expense",
    "value": "Home Owners Insurance",
    "selection": "Expense-Home Insurance",
    "creditCard": false,
  },
  {
    "category": "expense",
    "value": "Automobile Insurance",
    "selection": "Expense-Auto Insurance",
    "creditCard": false,
  },
  {
    "category": "expense",
    "value": "Transportation",
    "selection": "Expense-Transportation",
    "creditCard": true,
  },
  {
    "category": "expense",
    "value": "Pet Expenses",
    "selection": "Expense-Pet Expenses",
    "creditCard": true,
  },
  {
    "category": "expense",
    "value": "Boat Expenses",
    "selection": "Expense-Boat Expenses",
    "creditCard": true,
  },
  {
    "category": "expense",
    "value": "Horse Expenses",
    "selection": "Expense-Horse Expenses",
    "creditCard": true,
  },
  {
    "category": "expense",
    "value": "Misc Expenses",
    "selection": "Expense-Misc Expense",
    "creditCard": true,
  },
  {
    "category": "expense",
    "value": "Charities",
    "selection": "Donate to a Charity",
    "creditCard": true,
  },
  {
    "category": "expense",
    "value": "Phone",
    "selection": "Update Expense-Phone",  
    "creditCard": true,
  },
  {
    "category": "expense",
    "value": "Streaming Movies",
    "selection": "Update Expense-Movies",
    "creditCard": true,
  },
  {
    "category": "loan",
    "value": "College Loan",
    "selection": "Update Loan-College",
  },
  {
    "category": "loan",
    "value": "Car Loan",
    "selection": "Update Loan-Car",
  },
  {
    "category": "loan",
    "value": "Other Loan",
    "selection": "Update Loan-Other",
  },
  
];

export default EventOnLitList;