import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {Component} from 'react';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import ImageCard from '../shared-components/image_card';


class FurnitureCard extends Component {
    photosArr = [];
    
    constructor(props) {
        super(props);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
        this.state = {open: false, selected: false}; 
    }

    toggleExpanded(e) {
        this.setState({open: !this.state.open});
    }

    toggleSelected(e) {
        this.setState({selected: !this.state.selected});
        this.props.selected();
        }
       
    render() {
        const defaultImgSrc = './assets/' + this.props.furnitureInfo.directory  + '/default.png';
        const photoArr = [];
        if (this.props.furnitureInfo.additionalPhotosArr !== undefined) 
        {
            for (let i = 0; i < this.props.furnitureInfo.additionalPhotosArr.length; ++i)
            {                
                const photo = {imageURL: './assets/' + this.props.furnitureInfo.directory + '/' +
                    this.props.furnitureInfo.additionalPhotosArr[i], imageClass: "",
                    onClick: "null"};
                photoArr.push(photo);
            }
        }
       
        this.photosList= photoArr.map((picturesList, index)  =>
            <ImageCard imageInfo={picturesList} key={index}
                onClick={() => this.nothingHappens()}/>  
        );
       
        const includedList = this.props.furnitureInfo.included.map((included, key) =>
            <li key={key}>{included}</li>
        );
        /*
        var half_length = Math.ceil(includedList.length / 2);    
         The following splice command removes the first half of entries from the includedlist array.
           After this splice command, IncludedList contains only the second half of the entries.  
        var leftSide = includedList.splice(0,half_length);  
        */
    return <>
        <div className=
            {this.props.selectedFurnitureId == this.props.furnitureInfo.id ?
                "card mx-3 my-2 link-tab-selected"
                :
                "card mx-3 my-2 drop-shadow"
            }
        >
        <div className="card-header text-left">
        <span className="container m-0 p-0">
            <span className="row w-100 mx-0" onClick={this.toggleExpanded}>
                <div className="col-sm-7">
                    <img src={defaultImgSrc} className="furniture_thumbnail_img" alt="Furniture Picture"/>
                </div>    
                <div className="col-sm-4 text-left">
                      <p className="mb-0 mt-4 font-weight-bold">{this.props.furnitureInfo.title}</p>
                      <p className="mb-0">Cost: {doubleToStringMonthly(this.props.furnitureInfo.cost)}</p>
                 </div>
                           
                <div className="text-right col-sm-1">
                {this.state.open ?
                    <FontAwesomeIcon icon={faCaretDown} className="career_card_caret" />
                    :
                    <FontAwesomeIcon icon={faCaretRight} className="career_card_caret" />
                }
                </div>
            </span>
        </span>
        </div>
        {this.state.open ?
            <div className="card-body">
                <span className="container m-0 p-0">
                    
                    <span className="row w-100 mx-0" onClick={this.toggleExpanded}>
                        <div className="col text-left p-0 m-0">
                           {this.photosList}
                        </div>
                        <div>
                            <h6>Items Included</h6>
                            <div className="col p-0 text-left">
                                <ul>{includedList}</ul>
                             </div>
                        </div>
                    </span>
                </span>
                <div className="text-right">
                    <button className="btn btn-primary" onClick={this.toggleSelected}>
                        Select Furniture
                    </button>
                </div>
            </div>
        : <span/>}
        </div>
    </>
    }
}

export default FurnitureCard;