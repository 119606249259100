import replaceTextInString from "../../../objects/CommonUse/replace_text_in_string";
import ConfigFile from "../../../datasets/config_file";
import PurchaseItemEventText from "../datasets/PurchaseItemEventText";
import { cashStrMask } from "./game_view_mask_functions";


export const setupTextReplace = (fullText, replaceValue, friendName, itemName) => {
   
       let returnText = "";
       let additionalInfo = {};
       let searchString = "";
       if (friendName != undefined && fullText.includes("||friendName||")){
              additionalInfo = {friendName: friendName};
              searchString = "friendName";
       }
       if (fullText.includes("||downPayment||")){
              let downPaymentPct = +ConfigFile.gameBoardLoans.pctDownPayment;
              let downPayment = downPaymentPct * +replaceValue;
              let downPaymentText = cashStrMask(downPayment);
              additionalInfo = {...additionalInfo, string: downPaymentText};
              searchString = "downPayment";
       }
     
       if (itemName != undefined && fullText.includes("||purchaseEventResponse||")){
              let textObj =  PurchaseItemEventText.find(elem => elem.itemName === itemName);
              if (textObj !== undefined){
                     additionalInfo = {...additionalInfo, string: textObj.responseText};
                     searchString = "purchaseEventResponse";
              }
       }

       if (fullText.includes("||itemName||")){
              additionalInfo = {...additionalInfo, string: itemName};
              searchString = "itemName";
       }
       
       if (searchString === ""){
              returnText = fullText;
       }else{
              returnText =
                     replaceTextInString(fullText, additionalInfo, searchString, "string");
       }
                  
    return returnText;
   
}
export default setupTextReplace;
