import {Component} from 'react';
//import './Special/special.css'

class QuestionListDisplayCard extends Component {
  constructor(props) {
    super(props);
    this.state={
      cardNbr: 0
    }    
}
  


render() {
   if(this.props.questions.totalScoreCount !== 0){
      this.avgScore = (this.props.questions.scoreTotal / this.props.questions.totalScoreCount).toFixed(2);
   }else{
      this.avgScore = 0;
   }
 
          
return <>
      <tr>
            <td className="text-left font-weight-bold">
                 {this.props.questions.question}
            </td>
            <td className="mx-2">
                 {this.props.questions.score1Count}
            </td>
            <td className="mx-2">
                 {this.props.questions.score2Count}
            </td>
            <td>
                 {this.props.questions.score3Count}
            </td>
            <td>
                 {this.props.questions.score4Count}
            </td>
            <td>
                 {this.props.questions.score5Count}
            </td>
            <td>
                 {this.props.questions.totalScoreCount}
            </td>
            <td>
                 {this.avgScore}
            </td>
           
      </tr>         
           
   </>
  }
}

export default QuestionListDisplayCard;