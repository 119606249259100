import { editText } from './editText';

export const editClosureText = (closureText, possibleValues, responseLit, closureTextMaxLength) => {

    var errorObj="";
    if (closureText === undefined){
        errorObj = {fieldClass: responseLit, fieldName: "Closure Text", 
                fieldValue: closureText, message: "Closure Text undefined."}
    }else{
        errorObj = editText(closureText, "Response", "Closure Text", closureTextMaxLength, responseLit);
        if (errorObj === "" || possibleValues === undefined){
            if (possibleValues.length === 1 && 
                (possibleValues[0] === "0" || possibleValues[0] === 0)){
                // $value text not required if amount is 0
            }else{
                let position = closureText.search("value");
                let prevChar = closureText.substr(position-1,1);
                if (position === -1 || prevChar !== "$"){
                    errorObj = {fieldClass: responseLit, fieldName: "Closure Text", 
                        fieldValue: closureText, message: "Must include '$value' string in text."}
                }
            }
        }
    }

    return errorObj;
   
}