const friend3GameDialogDataset ={friendName: "Cindy",  dialogTable: [
       {title: "Introduce Cindy",
              text: "Hi, my name is Cindy it is great to meet you.  My mom has told " +
              "me a lot about you.  It seems we have a lot in common.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Good to meet you. You wanna come in and talk?", moodPoints: 2, rltnPoints: +2},
                          {rspText: "Hmmmm... I have some things I have to do, " +
                                    "maybe another time.", moodPoints: -1, rltnPoints: -1}]},
       {title: "Cindy Job",
              text: "I have a job as an accountant.  I really like putting dollar amounts into the right buckets " + 
              "and resolving problems when things aren't in balance.  Do you like to work with numbers?",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Yes, I like math too!!", moodPoints: 1, rltnPoints: +2},
                          {rspText: "Not at all!!!  It's such a pain.", moodPoints: -2, rltnPoints: -1}]},
       {title: "College Experience",
              text: "Yeah, both of my parents are really smart.  They insisted that I go to college. " + 
                     "I didn't want to go to college at first but it ended up being a good idea. ",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "I'm glad it worked out for you.", moodPoints: 1, rltnPoints: 1},
                          {rspText: "Sorry, I can't relate.", moodPoints: -1, rltnPoints: -2}]},
       {title: "Parents Fought about Money",
              text: "When I was growing up, my parents fought a lot about money. " +
                    "They made good money but they never saved anything. " + 
                    "I am trying hard not to follow that path.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "That makes sense to me.", moodPoints: 1, rltnPoints: 1},
                          {rspText: "That sound silly to me.", moodPoints: +2, rltnPoints: -2}]}, 
       {title: "College Recommended",
              text: "I think you said that you didn't go to college.  You seem smart.  Are " + 
                     "you thinking about going to college at some point?  It might help you to get a job " +
                     "that pays more.",
              conditionList: ["no-college"], type: "inPerson",
              responses: [{rspText: "I really don't want to go to college.", moodPoints: -1, rltnPoints: -1},
                          {rspText: "Yes, I think college would be a good idea.", moodPoints: +2, rltnPoints: +1}]},
       // 5 items

       {title: "College Recommended",
              text: "I think you said that you went to college.  Are you glad that you did? ", 
              conditionList: ["2-years-college"], type: "inPerson",
              responses: [{rspText: "No, seemed like a big waste of time.", moodPoints: -1, rltnPoints: -2},
                          {rspText: "Yes, I am glad that I went to college.", moodPoints: +1, rltnPoints: +1}]},
       {title: "College Recommended",
              text: "I think you said that you went to college.  Are you glad that you did? ", 
              conditionList: ["4-years-college"], type: "inPerson",
              responses: [{rspText: "No, seemed like a big waste of time.", moodPoints: -1, rltnPoints: -2},
                          {rspText: "Yes, I am glad that I went to college.", moodPoints: +1, rltnPoints: +1}]},                  
       {title: "Monthly budget",
               text: "Do you know about how much you make each month and how much you spend?",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Yes, I have figured out a monthly budget.", moodPoints: 2, rltnPoints: +2},
                          {rspText: "No I don't have any idea.  But I think I am making more than I spend.", moodPoints: -1,
                           rltnPoints: -1}]},
       {title: "401K Account",
              text: "I setup a 401k account awhile ago.  I think I can retire earlier if I set aside some  " +
              "money each month.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Yay for you (quiet sigh).", moodPoints: -1, rltnPoints: -1}, 
                          {rspText: "That sounds like a good idea.", moodPoints: 1, rltnPoints: +1}]},
       {title: "Recommend sidehustle",
              text: "Do you have a sideHustle?  I've been doing some work on the side.  I have some extra " +
              "money now and it helps me to save a bit more.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Yes, that is a really good idea.", moodPoints: 1, rltnPoints: +2},
                          {rspText: "No, I work hard enough and make enough money with my regular job.", 
                            moodPoints: -2, rltnPoints: -1}]},
       // 10 items
         
       {title: "Credit Card Management",
              text: "I have a credit card. I have it setup to pay off the full balance each month.  That way " +
            "I don't have to pay any interest.  Do you know banks charge 20% interest if you don't pay the " +
            "balance each month?",
              conditionList: [], type: "inPerson",
              responses: [{rspText:"Good to know!! Thanks for the heads up.", moodPoints: 1, rltnPoints: +1},
                          {rspText: "OK.....?", moodPoints: -1, rltnPoints: 0}]},
       {title: "Accounting problem solved",
              text: "I probably told you before...I am an accountant.  I really like to work with numbers. " +
              "One of our accounts was really out of balance and nobody knew why.  I was able to figure " +
              "it out which made my boss really happy.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "That's great!", moodPoints: 1, rltnPoints: +1},
                          {rspText: "Sorry, I can't relate, I don't like math at all.", moodPoints: -2, rltnPoints: 0}]},
        {title: "Life philosophy",
              text: "Have you ever noticed that a lot of people seem to buy things they don’t need, with money " +
             "they don’t have to impress people they don’t even know, Seems silly to me!",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Wow, that is a sad truth, right?", moodPoints: 1, rltnPoints: +1},
                          {rspText: "I don't think that's true.", moodPoints: -1, rltnPoints: -1}]},
       {title: "Plan to Save Money to buy Home",
              text: "I've been setting aside money so I can buy a house.  Did you know that you need to " +
              "have 20% of the cost of the home as a down payment?  Are you thinking of getting a " +
              "home at some point?",
              conditionList: ["homeType-apartment"], type: "inPerson",
              responses: [{rspText: "No, I like living in an apartment.", moodPoints: -1, rltnPoints: 0},
                          {rspText: "Yes, I'd like to get my own place.", moodPoints: 1, rltnPoints: +1}]},
       {title: "Plan to Save Money to buy Home",
              text: "I've been setting aside money so I can buy a house.  Did you know that you need to " +
                    "have 20% of the cost of the home as a down payment?  Do you like owning a home?",
              conditionList: ["homeType-home"], type: "inPerson",
              responses: [{rspText: "I don't really care, it's just a place to live.", moodPoints: -1, rltnPoints: 0},
                          {rspText: "Yes, I like having my own place.", moodPoints: 1, rltnPoints: +1}]},
       // 15 items
       
       {title: "Home Good Investment",
              text: "I figure a home is a really good investment.  Home prices usually go up over time " +
              "Plus, the money you pay each month goes toward something you wn instead of just " +
              "going into the pocket of some landlord.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "I suppose that makes sense for you.", moodPoints: -1, rltnPoints: -1},
                          {rspText: "I agree.  Owning a home is a good plan.", moodPoints: 1, rltnPoints: +1}]},
        {title: "Want to Go Out to Eat?",
              text: "I just stopped by to see if you want to go out to eat.  I have a BOGO coupon " +
              "for a restaurant I've been wanting to try.  It looks like the restaurant is not too pricey " +
              "and we can split the BOGO coupon.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Sure, let's go!", moodPoints: +2, rltnPoints: +2, cashAmt: -16.35},
                          {rspText: "No, I don't want to go out tonight.", moodPoints: -1, rltnPoints: -2}]},
       {title: "401K Savings Change",
              text: "I've been maxing out my 401K and just realized that I need to reduce the amount I deposit each month. " +
              "That way I can pay for the down payment for a home without having to pull money out of my 401K savings. " +
              "They charge a lot to withdraw money out of a 401K.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "That sounds smart!", moodPoints: 1, rltnPoints: +2},
                          {rspText: "Whatever.... ", moodPoints: -1, rltnPoints: -1}]},
       {title: "Promotion at Work",
              text: "I just got a promotion at work!!  It was a really nice surprise. " +
              "I got a good raise and a bump in my bonus!  Now I can save for a home a lot " +
              "faster.  ",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Guess now you'll have to work extra hours.", moodPoints: -2, rltnPoints: -1},
                          {rspText: "Congratulations!  Let's go out to celebrate. My treat!", 
                          moodPoints: -1, rltnPoints: +2, cashAmt: -63.57, itemDesc: "Dinner with Cindy"}]},
        {title: "New Furniture",
              text: "I just bought some new furniture for my living room.  I managed to get the furniture from" +
              "a consignment store. " +
              "The furniture is slightly used but it looks amazing and was a lot cheaper to buy.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Used furniture?  That sounds lame.", moodPoints: -1, rltnPoints: -1},
                          {rspText: "Used Furniture is a great idea.  I'd love to see it!", moodPoints: 1, rltnPoints: +1}]},
       // 20 items

        {title: "Go for a Hike",
              text: "Want to go on a hike?  I found this area that looks like it would be reallycool to explore. " +
              "There are a bunch of hiking trails in the area and it looks like some pretty scenery.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Count me in, we can get some exercise and it won't cost us anything.",
                                   moodPoints: +2, rltnPoints: +1},
                          {rspText: "Nah, I'm not much on hiking.  Too much work!", 
                                   moodPoints: -1, rltnPoints: -1}]},
        {title: "How are you Doing?",
              text: "I just stopped by to say hi. How are things going for you? ",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "I'm doing really well. Thanks for asking!", moodPoints: 1, rltnPoints: +1},
                          {rspText: "I'm really struggling to make ends meet.", moodPoints: -2, rltnPoints: +1}]},
        {title: "Interest Rates Going Up",
              text: "The Feds are talking about raising interest rates this year. " +
              "If they do, then the mortgage rates will increase.  I'm going to start looking for my new home " +
              "now.  I don't want to wait any longer.",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Why rush (shrug), it probably won't make a difference anyway.", moodPoints: -1, rltnPoints: -1},
                          {rspText: "That seems like a good idea!", moodPoints: -1, rltnPoints: +1}]},
        {title: "New Home Offer Rejected",
              text: "I found the perfect home.  I put in an offer but someone beat me out. " +
              "Oh well, after I thought about it, that probably wasn't the best house for me. " ,
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Your offer was probably way too low.", moodPoints: -2, rltnPoints: -2},
                          {rspText: "Bummer, hopefully you will have better luck next time!", moodPoints: -1, rltnPoints: +1}]},
        {title: "If Interest Rates Go Up",
              text: "I did some checking.  It turns out that if a home costs $350,000 and interest rates increase just 1%, " +
              "then the monthly mortgage amount increases over $200 per month. That's a lot!! ",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Wow!  That is more than I would have thought.", moodPoints: -1, rltnPoints: +1},
                          {rspText: "Not a big deal, it doesn't sound like that much of a difference.", 
                          moodPoints: -1, rltnPoints: +1}]},
       // 25 items

        {title: "Found a new Home",
              text: "Yay!  I found a home that I really like and they accepted my offer!! " +
              "I'm so excited.  Do you want me to show you the pictures?",
              conditionList: [], type: "inPerson",
              responses: [{rspText: "Absolutely!  I'm excited for you.", moodPoints: +1, rltnPoints: +2},
                          {rspText: "Nah, I'll see it at some point after you've moved in.", moodPoints: -1, rltnPoints: -2}]},
       // 26 items


       // 26 items

       // goal is 40 dialog items
]
};

export default friend3GameDialogDataset