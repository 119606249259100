import GroceryStoreShopperDataset from '../Datasets/grocery_store_shopper_dataset';
import {dragNDropInsertItems} from '../../../objects/DragNDrop/drag_n_drop_insert_items';

export const createNewGroceryDataset = (DNDParameters) => {
    const groceryShopperDataset = dragNDropInsertItems(DNDParameters,  
        GroceryStoreShopperDataset.items, GroceryStoreShopperDataset.columns);
    return groceryShopperDataset;
   
}

