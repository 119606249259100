const VacationImageDataset = [

   // picture dimensions:  480 x 1100 (with whitespace on right); 480 x 715 (without whitespace)
   {vacationName: "Paris", imageList: [
      {selected: false, answer: "Tower", label: "Eiffel Tower", imageSrc: "bg_eiffel_tower.jpg" }, 
      {selected: false, answer: "Arc", label: "Arc De Triomphe", imageSrc: "bg_arc_de_triomphe.jpg"},
      {selected: false, answer: "Castle", label: "Castle (Château de Chantilly)", imageSrc: "bg_castle.jpg"},
      {selected: false, answer: "Restaurant", label: "Restaurant (Cafe de Paris)", imageSrc: "bg_restaurant.jpg"},
      {selected: false, answer: "Museum", label: "The Leuvre (Museum)", imageSrc: "bg_louvre.jpg"},
      {selected: false, answer: "River", label: "Seine River", imageSrc: "bg_river.jpg"}
   ]},
   {vacationName: "SanDiego", imageList: [
      {selected: false, answer: "Beach", label: "Spend the Day at the Beach", imageSrc: "SD_beach.jpg" }, 
      {selected: false, answer: "Midway", label: "Visit the USS Midway Aircraft Carrier", imageSrc: "SD_midway.jpg"},
      {selected: false, answer: "Parasail", label: "Go Parasailing", imageSrc: "SD_parasailing.jpg"},
      {selected: false, answer: "Seaworld", label: "Go to Sea World to see the Dolphins", imageSrc: "SD_seaworld.jpg"},
      {selected: false, answer: "Whale", label: "Go on a Whale Watching cruise", imageSrc: "SD_whale.jpg"},
      {selected: false, answer: "Zoo", label: "Visit the San Diego Zoo", imageSrc: "SD_zoo.jpg"}
   ]},
   {vacationName: "Yellowstone", imageList: [
      {selected: false, answer: "Bear", label: "Run into a Brown Bear", imageSrc: "YL_bear.jpg" }, 
      {selected: false, answer: "Waterfall", label: "Visit Lower Yellowstone Falls", imageSrc: "YL_waterfall.jpg"},
      {selected: false, answer: "Lake", label: "Go Fishing at Yellowstone Lake", imageSrc: "YL_lake.jpg"},
      {selected: false, answer: "Paint Pots", label: "Visit the hot pools on paint pot trail.", imageSrc: "YL_hotPools.jpg"},
      {selected: false, answer: "Geyser", label: "Watch Old Faithful Geyser", imageSrc: "YL_geyser.jpg"},
      {selected: false, answer: "GrandTeton", label: "Go to Grand Teton National Park", imageSrc: "YL_GrandTeton.jpg"}
   ]},
   {vacationName: "WashingtonDC", imageList: [
      {selected: false, answer: "Capital", label: "Visit the national capital building.", imageSrc: "DC_capital.jpg" }, 
      {selected: false, answer: "Jefferson", label: "See the Jefferson Memorial", imageSrc: "DC_jefferson.jpg"},
      {selected: false, answer: "Lincoln", label: "See the Lincoln Memorial", imageSrc: "DC_lincoln.jpg"},
      {selected: false, answer: "Pentagon", label: "Go to the Pentagon - 5 sided building", imageSrc: "DC_pentagon.jpg"},
      {selected: false, answer: "Washington", label: "See the Washington Monument", imageSrc: "DC_washington.jpg"},
      {selected: false, answer: "WhiteHouse", label: "Go to the WhiteHouse", imageSrc: "DC_whitehouse.jpg"}
   ]},


   

   ];
export default VacationImageDataset;