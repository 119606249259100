import "./SharedComponents/sharedComponents.css"
import {Component} from 'react';

class FriendAdviceDisplayCard extends Component {
    photosArr = [];

    constructor(props) {
        super(props);
    }
           
    render() {
             
    return <>
        <div className={this.props.friendDisplay.className1}>
                <img src={this.props.friendDisplay.friendImgURL} className="ask-a-friend-img"
                 onClick={() => this.props.onSelected()}/>
        
        <div className={this.props.friendDisplay.className2}>
                {this.props.friendDisplay.friendName}
        </div>
        </div>
        {this.props.friendDisplay.opinionSw ?
            <div className={this.props.friendDisplay.opinionClass}>
                {this.props.friendDisplay.comment}
                {this.props.friendDisplay.acceptDecline == "accept" ?
                    <button className="btn-sm btn-info w-10 mt-2" 
                        onClick ={()=> this.props.onFriendActivitySelected()}>
                             select
                    </button>
                :
                    null
                }
            </div>
        :
            null
        }
     </>
    }
}


export default FriendAdviceDisplayCard;