const friend4GameDialogDataset ={friendName: "Cameron",  dialogTable: [
     
      {title: "Introduce Cameron",
            text: "Hello!  I heard from a mutual friend that you just moved here.  I'm Cameron, I met you at a party " +
            "a while ago and live close by so I thought I'd drop by and welcome you to the neighborhood.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Good to meet you.  Want to come in for a bit?", moodPoints: 2, rltnPoints: +2},
                        {rspText: "Hello... I'm busy right now.  See you around.", moodPoints: -1, rltnPoints: -2}]},
      {title: "Live at parent's house",
            text: "Yeah, I live at my parent's house.  I live in the basement, I even have my own " +
            "entrance so I can come and go without them knowing.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "That sounds nice.", moodPoints: 1, rltnPoints: +1},
                       {rspText: "Really? You still live at home?", moodPoints: -1, rltnPoints: -1}]},
      {title: "Out to Dinner, my treat",
            text: "I wondered if you wanted to go out for dinner.  My parents just gave me my allowance " +
             "so I'll be glad to pay for your meal if you'd like.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "No, I don't want to go out tonight.", moodPoints: -1, rltnPoints: -2},
                        {rspText: "Absolutely, that sounds great.", moodPoints: 1, rltnPoints: +1}]},
      {title: "Roommate bummer",
            text: "You have a roommate?  That's a bummer.  I don't think I could live with a roommate.",
            conditionList:  ["roommate"], type: "inPerson",
            responses: [{rspText: "Actually, I like having a roommate.  Someone to talk to you know?", 
                              moodPoints: -2, rltnPoints: -1},
                        {rspText: "Yeah, sometimes it's a pain.", moodPoints: 1, rltnPoints: +1}]},
      {title: "Job options",
            text: "No, I don't have a job right now.  I was thinking I might start looking, but no real rush.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Hmmmm... I wish I didn't have to work!!", moodPoints: 1, rltnPoints: +1},
                        {rspText: "Must be nice.", moodPoints: -2, rltnPoints: -1}]},
      // 5 dialog items
      
      {title: "Out for fun with Cameron",
            text: "I was wondering if you wanted to go out for a bit.  I know it's really late, but..." +
             "I thought we could have some fun.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "No, it is late and I have to work tomorrow.", moodPoints: -1, rltnPoints: -1},
                        {rspText: "Sure, let's go.", moodPoints: 1, rltnPoints: +2, costAmt: -83.27}]},
      {title: "College degrees",
            text: "Yeah, I went to college.  Double-majored in psychology and sociology, hard to find a " +
            "job with those degrees.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Hmmm... I guess some degrees just aren't in demand.", moodPoints: 1, rltnPoints: +1},
                        {rspText: "There must be some job that you could find.", moodPoints: -2, rltnPoints: -2}]}, 
       {title: "Trip to France",
            text: "Have you ever been to France?  I went with my parents a few times.  I really like Paris.  " +
            "The food there is really good.  I speak a little French too which definitely helps. ",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "That sounds really cool!!", moodPoints: 1, rltnPoints: +1},
                        {rspText: "Not really something I care to do.", moodPoints: -2, rltnPoints: -2}]}, 
      {title: "Living in Apartment",
            text: "I can't believe you are still living in an apartment.  I think you should look into " +
            "getting a house. ",
            conditionList:  ["homeType-apartment"], type: "inPerson",
            responses: [{rspText: "I've been thinking the same thing!", moodPoints: 1, rltnPoints: +1},
                        {rspText: "No way! I like it here.", moodPoints: -1, rltnPoints: -1}]}, 
      {title: "Hockey game and dinner",
            text: "Hello!  I got tickets for a hockey game.  You wanna come along?  I'll even pay for a quick " +
            "dinner before the game.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Count me in!!", moodPoints: 1, rltnPoints: +1},
                        {rspText: "Nah, I've got a lot of things I need to do at home.", moodPoints: -2, rltnPoints: -2}]},
      // 10 dialog items 
    
      {title: "New car",
            text: "I just got a new car, want to come see it?  BTW...what kind of car do you have? " +
            "I'll bet you could afford a nicer car.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "No, I'm too busy right now.", moodPoints: -1, rltnPoints: -2},
                        {rspText: "Sure, what kind of car is it?", moodPoints: 1, rltnPoints: +1}]},
      {title: "Parents tell him to move out",
            text: "I am so bummed.  My parents told me they have decided to sell our house and 'downsize'. " +
            "They said I needed to get a job and move out within the next month.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Whoa! That's pretty tough news.", moodPoints: -1, rltnPoints: 1},
                        {rspText: "Seems like it's about time, don't you think?", moodPoints: 2, rltnPoints: +1}]},
      {title: "Job Hunting",
            text: "I've been looking for a job.  It is really hard.  Most of them really don't pay very much. " +
            "I mean, I do have a college degree you know.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "No duh... you said that your degrees aren't very much in demand, right?", 
                              moodPoints: -1, rltnPoints: -2},
                        {rspText: "Job hunting is hard.  I'm sure you'll find something that works.", 
                              moodPoints: 1, rltnPoints: +1}]},
      {title: "Found a Job",
            text: "I found a job.  I'm going to sell insurance.  " +
            "It doesn't pay much at the beginning, but if I can get enough people signed up, I'll " +
            "be on easy street.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Very cool.  I hope it works well for you!", moodPoints: -1, rltnPoints: +1},
                        {rspText: "I guess that's OK but selling insurance is very hard.", moodPoints: 1, rltnPoints: +1}]},
      {title: "Friend List",
            text: "I just stopped by to ask you if I could have a list of all of your friends and their " +
            "phone numbers.  I am collecting a list of contacts for my job.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "No, I don't think any of my friends need insurance.", moodPoints: 1, rltnPoints: -2},
                        {rspText: "OK, I can just go through my phone.", moodPoints: -2, rltnPoints: +2}]},   
      // 15 dialog items

      {title: "Want to go Out?",
            text: "I just talked my parents into giving me my allowance.  Do you want to go " +
             "out for some dinner.  It can be my treat.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "No, you really should be saving that money.", moodPoints: 1, rltnPoints: -2},
                        {rspText: "Sure, sounds like fun!", moodPoints: -1, rltnPoints: +2}]},
      {title: "Job is Difficult",
            text: "My new job is really hard.  I call people all day and they just keep saying 'No'. " +
                  "Some of them are really rude.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "You really didn't think it would be easy did you?", moodPoints: -1, rltnPoints: -2},
                        {rspText: "I'm really sorry to hear that.", moodPoints: -1, rltnPoints: +1}]},
      {title: "Thinking of Quitting",
            text: "I hate my job.  I might just quit.  But my parents said I have to move out " +
                  "within the next two weeks.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "It might be a good idea to quit.", moodPoints: -1, rltnPoints: -2},
                        {rspText: "Quitting already? You've only been working there for two months.",
                         moodPoints: -1, rltnPoints: +1}]},
      {title: "Tennis Anyone?",
            text: "My parents are members of a tennis club and they said I can bring a friend and play " +
                  "tennis this weekend.  I'm not great at the game but it is sort of fun.  Do you want to go?",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Sure, I'll come along.", moodPoints: 2, rltnPoints: +2},
                        {rspText: "Nah, I don't even like tennis.",
                         moodPoints: -1, rltnPoints: -2}]},
      {title: "Quit my Job",
            text: "I quit my job today.  My parents said they'd help out with " +
                  "my first month's rent and damage deposit.  So now all I need to do is find an apartment. " +
                  "Got any ideas? " ,                
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Did you try searching online for an apartment?",
                         moodPoints: -1, rltnPoints: +1},
                        {rspText: "No, I don't have any ideas to help you find " +
                        "an apartment.",
                         moodPoints: -1, rltnPoints: -1}]},
      // 20 dialog items

      {title: "Found an Apartment",
            text: "I found an apartment to rent.  It isn't too far from this place.  It's a little small " +
                  "but it has a great game room and pool.  You'll have to come over after I move in.",
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "That sounds great!", moodPoints: -1, rltnPoints: +1},
                        {rspText: "Nice to have rich parents I guess, eh?",
                         moodPoints: -2, rltnPoints: -1}]},
      {title: "Moving Into Apartment",
            text: "I am moving into my new apartment next week.  Can you help me move? " +
                  "I can take you out to dinner after we move." ,
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Sorry, I am too busy to help,", moodPoints: -1, rltnPoints: -2},
                        {rspText: "Sure, I'd be happy to help!",
                         moodPoints: -2, rltnPoints: +4}]},
       {title: "Finding a New Job",
            text: "I've been looking for a new job and thinking of becoming a " +
                  "real estate agent but you have to get a license.  Each state is different but in this " +
                  "state it will take about 4 weeks of classes and then I have to pass an exam." ,
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Being a Real Estate Agent takes a lot of work.", moodPoints: -1, rltnPoints: -2},
                        {rspText: "That could be kind of a fun job!",
                         moodPoints: +1, rltnPoints: +1}]},
       {title: "Party in New Apartment",
            text: "I'm going to have a party to celebrate my new apartment.  " +
                  "I can reserve the game room but it costs $150 per hour.  Do you think " +
                  "it would be OK to ask guests to contribute $20 each?" ,
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Why not, as long as they know why you're asking.", moodPoints: -1, rltnPoints: +1},
                        {rspText: "No way! I think that would be rude.",
                         moodPoints: -2, rltnPoints: -1}]},
       {title: "Party Planned",
            text: "I decided to have the party but I'm not going to reserve the game room. " +  
                  "We can still go over if we want and might be able to play some pool or ping pong but if " +
                  "it is busy, then we can just hang out. You want to come?" ,
            conditionList:  [], type: "inPerson",
            responses: [{rspText: "Maybe, if I can find the time.", moodPoints: -1, rltnPoints: -2},
                        {rspText: "Absolutely! It sounds like fun.",
                         moodPoints: 1, rltnPoints: +2}]},

      // 25 dialog items

      // goal is 40 dialog items
]
 
};

export default friend4GameDialogDataset