const midGameChoiceData = 
{
    "user": "student6",
    "jobInfo": {
    "careerID": "612bca22600d610c45b6e56a",
    "healthInsInd": "false",
    "jobRank": "1",
    "nbrYearsCollege": "2",
    "nbrSkillSurvey": "1",
    "jobStartGameMonth": 360
  },
  "homeInfo": {
    "homeID": "61323ec697d929c9a8cf8706",
    "homeType": "apartment",
    "roommates": "0",
    "bedrooms": "1"
  },
  "furnitureID": "6132a4c097d929c9a8cf8719",
  "carInfo": {
    "carID": "6132c4a797d929c9a8cf8727",
    "carAge": 48,
    "carYear": "2020",
    "carType": "200"
  },
  "startingCash": "9425.00",
  "currentCash": "9425.00",
  "currentMoodPoints": "21000",
  "startGameDate": "2024-12",
  "currentGameDate": "2024-12",
  "assetTable": [
    {
      "assetItem": "Job Salary",
      "assetDesc": "Auto Mechanic --> Annual Salary: $33,999.96",
      "assetAmt": "2833.33",
      "assetStartDate": "2024-12",
      "assetNbrMonths": "-1"
    }
  ],
  "expenseTable": [
    {
      "expItem": "Payroll taxes",
      "expDesc": "Federal, FICA (Social Security)",
      "expAmount": "350.83",
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": false
    },
    {
      "expItem": "Apartment Rent",
      "expDesc": "Sunnyside-1 bedroom, 0 roommates",
      "expAmount": 885,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": true
    },
    {
      "expItem": "Health Insurance",
      "expDesc": "Health Insurance 100% paid by employee",
      "expAmount": 370,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": false
    },
    {
      "expItem": "Automobile Insurance",
      "expDesc": "Insurance for car repair or replacement",
      "expAmount": 75,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": false
    },
    {
      "expItem": "Renter's Insurance",
      "expDesc": "Insurance for furniture and other personal items",
      "expAmount": 30,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": false
    },
    {
      "expItem": "Transportation",
      "expDesc": "Gas cost to/from work and weekend miles (based on MPG for car of choice)",
      "expAmount": "115.56",
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": true
    },
    {
      "expItem": "Misc Expenses",
      "expDesc": "Food, Clothes, Personal Items, etc.",
      "expAmount": 380,
      "expStartDate": "2024-12",
      "expNbrMonths": "-1",
      "chargeCreditCard": true
    }
  ],
  "loanTable": [
    {
      "loanType": "Car Loan",
      "loanDesc": "Mid-Size Car - 3 to 5 Years Old",
      "loanAmt": "300.21",
      "loanStartDate": "2024-12",
      "loanNbrMonths": 72,
      "loanTotAmt": 16205,
      "loanIntRate": 0.1,
      "loanTerm": 6
    },
    {
      "loanType": "College Loan",
      "loanDesc": "Associates Degree-Auto Repair/Tech(2 years)",
      "loanAmt": "118.66",
      "loanStartDate": "2024-12",
      "loanNbrMonths": 120,
      "loanTotAmt": 12000,
      "loanIntRate": 0.035,
      "loanTerm": 10
    }
  ],
  "skillSurvey": [
    "4",
    "3",
    "4",
    "3",
    "4",
    "3",
    "4",
    "5",
    "4",
    "4",
    "5",
    "4"
  ],
  "conditionsTable": [
    {
      "condName": "homeType-apartment",
      "conditionClass": "home"
    },
    {
      "condName": "auto-mechanic",
      "conditionClass": "career"
    },
    {
      "condName": "no-december-holiday-selected",
      "conditionClass": "game"
    },
    {
      "condName": "no-friend-purchases",
      "conditionClass": "game"
    }
  ],
  "pendingEventsArr": [],
  "gameNbrMonths": "360",
  "nbrMonthsNegative": "0",
  "nbrMonthsMoodNegative": "0",
  "unlockedFeatures": [],
  "friendList": [
    {
      "name": "Charlie",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    },
    {
      "name": "Cameron",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    },
    {
      "name": "Jennifer",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    },
    {
      "name": "Cindy",
      "introDate": "",
      "dialogIndx": "firstTime",
      "rltnshpScore": "0",
      "totalLoanAmt": "0"
    }
  ],
  "spendingHistory": {
    "currentMonthSpendAmt": 0,
    "current10YearSpendAmt": 3575,
    "current10YearMonths": 1,
    "previous10YearSpendAmt": 0,
    "previous10YearMonths": 0
  },
  "moodPointHistory": {
    "currentMonthMoodPoints": 0,
    "totalMoodPoints": 0,
    "moodPointMonths": 1
  },
  "iconTable": [
    {
      "iconName": "gameBasicHelp",
      "iconCategory": "information",
      "className": "small_sign_thumbnail_img information-sign-position",
      "imageSrc": "gameHelp.png",
      "validInd": true,
      "firstTime": true,
      "additionalInfo": ""
    }
  ],
  "badgesEarned": [],
  "prevGameMonths": "0",
  "prevNbrGames": "0",
  "hand": [],
     
}
export default midGameChoiceData;