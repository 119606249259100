import {Component} from 'react';
import HomeInput from '../shared-components/home_input';
import { withRouter } from "react-router";

class HomeSelect extends Component {
  
    constructor(props) {
        super(props);
        props.onOpen();
        this.inputSelected = (inputID) => {
           
        };
        this.state = {
            openHomeChangeModal: false,
            newRentAmt: 0,
            newRoommates: "",
            newBedrooms: "",
            newHomeName: "",
        }
        
        this.inputSelected(1);
    }    

    render() {
    
    return <>
            <HomeInput 
                screenSize={this.props.screenSize}
                onOpen ={()=> this.inputSelected(1)} 
                onSelected={() => this.onHomeSelected()}
                processError = {(errorObj) => this.props.processError(errorObj)} />
    </>
    }

    onHomeSelected() {
        this.props.completed();
        this.props.history.push('/choose-furniture');
    }

}
export default withRouter(HomeSelect);