import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class SkillsSurveyFoundModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         gameHelp: false
    };    
  }
  
  render() { 
      // setting animation={false} in modal to prevent the "findDOMNode is deprecated" warning msg
  return <>
        <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="xs"
            animation={false}>
          <Modal.Header className="gameModalHdrFtrBackground" closeButton>
            <Modal.Title>Skills Survey Already Complete</Modal.Title> 
          </Modal.Header>
          <Modal.Body >
                <div className="mr-2 ml-2">
                    <div>
                          You have previously completed the skills survey.  Do you want to use 
                          the results of the survey that you did previously? 
                    </div>
                    {this.props.fromProcess == "GameView" ?
                        <div>
                            You can retake the skills survey 1 time during this game.
                            This will be the only time that you can retake the survey.
                        </div>                  
                    :
                    <div/>
                    }
                </div>
          </Modal.Body>
          <Modal.Footer className="gameModalHdrFtrBackground text-right">
          
            <Button className="mr-4" variant="success"   onClick={() => this.props.onUsePreviousSurvey()}>
                   <div>Use Previous</div>
                   <div>Survey</div>
            </Button>
            <Button className="mr-4" variant="primary"   onClick={() => this.props.onDoNotUsePreviousSurvey()}>
                   <div>Retake Skills</div>
                   <div>Survey</div>
            </Button>
            <Button className="mr-4" variant="secondary"   onClick={() => this.props.displayGameHelp()}>
                    <div>Display Game</div>
                    <div>Help</div>
            </Button>
                    <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mx-auto d-block"/>
            </Modal.Footer>
          </Modal>  
    
    </>
  }

 
}
export default SkillsSurveyFoundModal;