import { findRemoveTableItem } from "../../../objects/AssetsExpenses/find_remove_table_item";

export const getCurrentRoommateRent = (nbrRoommates, assetTable) => {
    let roommateShareOfMortgage = 0;
    if (+nbrRoommates > 0){
        let shortName = "roommateRent";
        if (+nbrRoommates > 1){
            shortName = "roommatesRent";
        }
        let currentRentObj = findRemoveTableItem(assetTable, shortName, true);
        roommateShareOfMortgage = currentRentObj.assetAmt;        
    }

    const returnObj={roommateShareOfMortgage: roommateShareOfMortgage, assetTable: assetTable};
    return returnObj;
}

export default getCurrentRoommateRent;