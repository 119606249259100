import {Component} from 'react';
import { withRouter } from "react-router";
import "./GameViews/gameViews.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import {rollForwardCash} from './objects/roll_forward_cash_function';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import { rollForwardDate } from "./objects/roll_forward_date_function";
import TwoColumnTableCard from './two_column_table_card';
import { moveFromCashToSavings } from './objects/move_from_cash_to_savings';

class FutureViewModal extends Component {
            constructor(props) {
              super(props);
              this.gameInfo = this.props.gameInfo();
              this.state={
                data: null,
                loading: true,
                gameAmounts: "",
                savingsInfo: "",
                monthAmtObj: {},
                assetTable: [],
                rollForwardSw: false,
                endingCashAmt: 0,
                endDate: 0,
                currentGameDate: "",
                currentCash: 0,
                newGameDate: "",
                startDate: "",
                value: "",
                displaySavings: false,
                loanArray: [],
              };    
     }
    
    componentWillMount(){
        this.getAmtData();
    } 

    getAmtData() {
        this.state.gameAmounts = JSON.parse(JSON.stringify(this.gameInfo.gameData));
        this.state.savingsInfo = JSON.parse(JSON.stringify(this.gameInfo.savingsInfo));
        this.state.startDate = this.state.gameAmounts.startGameDate;
        this.state.gameDate = this.state.gameAmounts.currentGameDate;
        this.state.currentGameDate = this.state.gameAmounts.currentGameDate;
        this.state.currentCash = this.state.gameAmounts.currentCash;
    }

    handlePeriodChange(nbrYears) {
        this.nbrMonths = parseFloat(nbrYears) * 12;
    }

    rollForward() {
        if (this.nbrMonths == undefined || this.nbrMonths == 0){
            this.nbrMonths = 0;
            this.startingCashDisplay = 0;
        }
        this.calculateAmounts();
    }

    calculateAmounts(){
        this.startingCashDisplay = this.state.currentCash;
        this.assetTable = this.state.gameAmounts.assetTable;
        this.expenseTable = this.state.gameAmounts.expenseTable;
        this.loanTable = this.state.gameAmounts.loanTable;
        this.totalAmtObj = rollForwardCash(this.assetTable, this.expenseTable, 
            this.loanTable, this.nbrMonths, this.state.currentGameDate);
        this.state.newGameDate = this.totalAmtObj.newGameDate;
        this.state.endingCashAmt = parseFloat(this.totalAmtObj.totalNetAmt) +
                parseFloat(this.state.currentCash);
        if (this.nbrMonths != 0){
            this.setLoanDisplayInfo();
        }else{
            this.state.loanArray = [];
        }
        this.processSavingsInfo();
    }

    setLoanDisplayInfo() {
        var loanString = {};
        var i;
        for (i=0; i< this.loanTable.length; i++) {
            if (this.loanTable[i].loanNbrMonths != 0) {
                let loanNbrMonths = this.loanTable[i].loanTerm * 12;
                let loanEndDate = rollForwardDate(this.loanTable[i].loanStartDate, loanNbrMonths);
                if (loanEndDate >= this.state.newGameDate){
                    loanString = {label: this.loanTable[i].loanDesc, loanEndDate: loanEndDate,
                        class: "font_color_white"}
                    this.state.loanArray.push(loanString);
                }
            }
        }
    }

    processSavingsInfo(){
        let savingsStatus = "active";
        if (this.state.savingsInfo.gameID != undefined && +this.nbrMonths > 0){
            let savingsObj = moveFromCashToSavings(this.state.savingsInfo, this.state.endingCashAmt,
                this.state.gameAmounts.currentGameDate, savingsStatus, +this.nbrMonths);
            this.state.endingCashAmt = savingsObj.cashAmt;
            this.savingsDeposits = savingsObj.savingsInfo.totalDeposits;
            this.savingsIntAmt = savingsObj.savingsInfo.totalIntAmt;
            this.savingsAmt = savingsObj.savingsInfo.totalSavings;
            this.state.displaySavings = true;
        }else{
            this.state.displaySavings = false;
        }
    }

    render() {
        this.rollForward();
        this.collegeLoanList = this.state.loanArray.map((loanArr,key) =>
            <TwoColumnTableCard  key={key} infoType="label-date" outInfo={loanArr}  />
        );
                                     
    return <>
        <Modal className="text-center" show={this.props.modalOpen}  onHide={() => this.props.onClose()} 
            size="med" scrollable="true">
        <Modal.Header className="futureModalHdrFooterBackground" closeButton>
          <Modal.Title>Look Into the Future</Modal.Title>
        </Modal.Header>
        <Modal.Body className="futureModalBackgroundImage">
             <span>
            <table className="table table-hover text-white">
                <div className="row w-100 mx-0">
                <div className="col-sm-10 text-left">Nbr of Years to Roll Forward:</div>
                    <div className="col-sm-2 text-right">
                        <select onChange={(val) => this.handlePeriodChange(val.target.value)} 
                            className="text-white btn btn-sm btn-outline-secondary dropdown-toggle">
                                <option value="0">no option slctd</option>
                                <option value="1">1 Year</option>
                                <option value="2">2 Years</option>
                                <option value="5">5 Years</option>
                                <option value="10">10 Years</option>
                                <option value="20">20 Years</option>
                                <option value="30">30 Years</option>
                        </select>
                    </div>
                </div>
                <div className="mt-4"></div>
                <tbody>
                    <tr>
                        <td className="text-left col-sm-8 p-0" scope="row">Starting Game Date</td>
                        <td className="text-right col-sm-4 p-0">{reformatDateToDisplay(this.state.startDate)}</td>
                    </tr>
                    <tr>
                        <td className="text-left col-sm-8 p-0" scope="row">Current Game Date</td>
                        <td className="text-right col-sm-4 p-0">{reformatDateToDisplay(this.state.currentGameDate)}</td>
                    </tr>
                    <tr>
                        <td className="text-left col-sm-8 p-0" scope="row">Current Cash Amount</td>
                        <td className="text-right col-sm-4 p-0"> {doubleToStringMonthly(this.startingCashDisplay)}</td>
                    </tr>
                    <tr>
                        <td className="text-left col-sm-8 p-0" scope="row">Total Revenues (Salary and Other)</td>
                        <td className="text-right col-sm-4 p-0"> {doubleToStringMonthly(this.totalAmtObj.totalAssetAmt)}</td>
                    </tr>
                    <tr> 
                        <td className="text-left col-sm-8 p-0" scope="row">Total Estimated Expenses</td>
                        <td className="text-right col-sm-4 p-0">- {doubleToStringMonthly(this.totalAmtObj.totalExpenseAmt)}</td>
                    </tr>
                    <tr> 
                        <td className="text-left col-sm-8 p-0" scope="row">Total Loan Payments</td>
                        <td className="text-right col-sm-4  p-0">- {doubleToStringMonthly(this.totalAmtObj.totalLoanPayments)}</td>
                    </tr>
                    <tr> 
                        <td className="text-left col-sm-8 p-0" scope="row">Monthly Net Amount</td>
                        <td className="text-right col-sm-4 p-0"> {doubleToStringMonthly(this.totalAmtObj.totalNetAmt)}</td>
                    </tr>
                    </tbody>
            </table>
            <table className="text-white table table-hover">
                    <tbody>
                        <tr> 
                        <td className="text-left p-0" scope="row">FutureGameDate</td>
                        <td className="p-0 text-right"> {reformatDateToDisplay(this.state.newGameDate)}</td>
                    </tr>
                    <tr> 
                        <td className="text-left p-0" scope="row">Estimated Future Cash Amount</td>
                        <td className="p-0 text-right"> {doubleToStringMonthly(this.state.endingCashAmt)}</td>
                    </tr>
           
                </tbody>
            </table>
            {this.state.displaySavings ?
            
                <table className="text-white table table-hover">
                    <tbody>
                        <tr>
                            <td className="text-center p-0" colSpan="2">Total Savings Amounts</td>
                        </tr>
                        <tr>
                            <td className="text-left col-sm-8 ml-3 p-0">Total Amt Deposited</td>
                            <td className="text-right p-0">{doubleToStringMonthly(this.savingsDeposits)}</td>
                        </tr>
                        <tr>
                            <td className="col-sm-8 p-0 text-left">Total Interest Earned</td>
                            <td className="col-sm-4 p-0 text-right">{doubleToStringMonthly(this.savingsIntAmt)}</td>
                        </tr>
                        <tr>
                            <td className="col-sm-8 p-0 text-left">Total Savings Amt</td>
                            <td className="col-sm-4 p-0 text-right">{doubleToStringMonthly(this.savingsAmt)}</td>
                        </tr>
                    </tbody>
                </table>
            :
                null
            }
            <table className="text-white table table-hover">
                <tbody>
                    {this.state.loanArray.length > 0 ?   
                        <div className="row-100"> 
                            <theader className="p-0">Outstanding Loans</theader>
                            <tr>
                                <td className="col-sm-8 text-left p-0">Loan Type</td>
                                <td className="col-sm-4 text-right p-0">End Date</td>
                            </tr>
                            {this.collegeLoanList}
                        </div>
                    :
                        null
                        }
                </tbody>
            </table>
                  
            </span>
        </Modal.Body>
        <Modal.Footer className="futureModalHdrFooterBackground">
            <Button variant="primary"  onClick={() => this.props.onClose()}>
                      Close
            </Button>
        </Modal.Footer>
        </Modal>
        </>
    }

   
    backToOptionSelection() {
        this.props.history.push('/statusDsply');
    }
    
}

export default FutureViewModal;