import {Component} from 'react';

class TopicListCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            };
    }

    render() {
    return <> 
        <dt>{this.props.textInfo.topic}</dt>
        <dd>{this.props.textInfo.text}</dd>
    </>
    }
}

export default TopicListCard;