
import Config from "../../config";

export const buildUrl = (type, functionName, parameter) => {
      let urlVersion = "";
      let urlPrefix = "";
      if (type == "test"){
          urlVersion = Config.dataURL.testUrlVersion;
          urlPrefix = Config.dataURL.testUrlPrefix;
      }else{
          urlVersion = Config.dataURL.prodUrlVersion;
          urlPrefix = Config.dataURL.prodUrlPrefix;
      }
      let outURL = "";
      if (parameter == undefined || parameter == "" ){
            outURL = urlPrefix + urlVersion + "/" + functionName;
      }else{
            outURL = urlPrefix + urlVersion + "/" + functionName + parameter;
      }
                    
   return outURL;
   
}
export default buildUrl;




