import { isNumeric } from '../../../objects/CommonUse/checkIfNumeric';

export const editPossibleValues = (possibleValues, responseLit) => {

  var errorObj="";
  var validPossibleValues = true;
  var i;

  if (possibleValues === undefined){
     errorObj = {fieldClass: responseLit, fieldName: "possible Values", fieldValue: possibleValues,
      message: "Possible values undefined"};
   }else{
   for (i=0; i<possibleValues.length && validPossibleValues === true; i++){
        validPossibleValues = isNumeric(possibleValues[i]);
   
      if (validPossibleValues === false){
          errorObj = {fieldClass: responseLit, fieldName: "possible Values", fieldValue: possibleValues.join(),
            message: "Possible values m/b array of numerics"};
          break;
      }
   }
}
   
  return errorObj;
 
}