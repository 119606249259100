import {homeRentAmt} from './homeRentCalc';
import {healthInsCalcAmt} from './healthInsCalc';
import {transportCostCalcAmt} from './transportCostCalc';
import ConfigFile from '../../datasets/config_file';
import { createTableItem } from '../AssetsExpenses/create_table_item';

export const monthlyExpenseAmt = (homeCost, roommates, bedrooms, homeName, jobHealthInsInd, carMPG,
            currentGameDate, startGameDate) => {
          
     const expenseArr = [];
     const miscCost=ConfigFile.gameStartItems.monthlyMiscCost;
     const carInsAmt = ConfigFile.insurance.carInsAmt;
     const rentersInsAmt = ConfigFile.insurance.rentersInsAmt;
     var expenseString = " ";
    
     expenseString = homeRentAmt(homeCost, roommates, bedrooms, homeName, currentGameDate);
     expenseArr.push(expenseString);
     
     expenseString = healthInsCalcAmt(jobHealthInsInd, currentGameDate, startGameDate);
     expenseArr.push(expenseString);

     expenseString = createTableItem("carIns", "", currentGameDate, carInsAmt, "", false)
     expenseArr.push(expenseString);

     expenseString = createTableItem("rentersIns", "", currentGameDate, rentersInsAmt, "", false)
     expenseArr.push(expenseString);

     expenseString = transportCostCalcAmt(carMPG, currentGameDate)
     expenseArr.push(expenseString);
     
     expenseString = {expItem: "Misc Expenses", expDesc: "Food, Clothes, Personal Items, etc.",
           expAmount: miscCost, expStartDate: currentGameDate, expNbrMonths: "-1", chargeCreditCard: true};   ; 
     expenseArr.push(expenseString);
    
     return expenseArr;
 }

