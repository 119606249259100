const PrioritiesChallenge2HintsText =
    ["The total for the items you selected must be between ||minAmount|| and ||maxAmount||. " +
        "Your current total is ||dollarAmt||.",
     "There are 3 items that are considered essential, you must choose these 3 items " + 
        "and then you can choose any of the other items as long as the total amount is " +
        "within the dollar range noted by the previous hint."
    
    ]
   

export default PrioritiesChallenge2HintsText;