import {Component} from 'react';
import {
    Link
  } from "react-router-dom";

class LinkCard extends Component {
    constructor(props) {
        super(props);
        
    }

setSizeValues(){
    this.textClass = "text-center primary-blue"
    this.screenOK = true;
    switch (this.props.inSize){
        case "tooSmall":
            this.screenOK = false;
            this.initialClassValue = "card mt-4 mr-5 rounded-corners-small link-card-small";
            this.cardImgClassValue = "card-img-top-small";
            this.textClass = this.textClass + " medium-text";
            break;
        case "verySmall":
            this.initialClassValue = "card mt-4 mr-5 rounded-corners-small link-card-very-small";
            this.cardImgClassValue = "card-img-top-very-small";
            this.textClass = this.textClass + " small-text";
            break;
        case "small":
            this.initialClassValue = "card mt-4 mr-5 rounded-corners-small link-card-small";
            this.cardImgClassValue = "card-img-top-small";
            this.textClass = this.textClass + " medium-text";
            break;
        case "medium":
            this.initialClassValue = "card mt-4 mr-5 rounded-corners-md2 link-card-med";
            this.cardImgClassValue = "card-img-top-med";
            break;
        case "mediumLarge":
            this.initialClassValue = "card mt-5 mr-5 rounded-corners-md2 link-card-med-large";
            this.cardImgClassValue = "card-img-top-med-large";
            break;
        default:
            this.initialClassValue = "card mt-3 mr-5 rounded-corners-lg link-card";
            this.cardImgClassValue = "card-img-top";
            break;
     }
     if (this.props.name.length <= 15){
          this.cardTextClassValue = "card-body p-2"
     }else{
          this.cardTextClassValue = "card-body p-0"
     }
 }   

    render() {
        this.setSizeValues();
    return <>
            <div className={this.initialClassValue}>
                {this.props.destination != undefined ?
                    <Link to = 
                    { this.props.allowed === undefined && this.screenOK ?
                        this.props.destination
                    :
                        (this.props.allowed  && this.screenOK ?
                            this.props.destination
                        :
                            ""
                        )
                    }
                       onClick=
                            {this.props.allowed === false || this.screenOK === false ?
                                () => this.displayDeniedWarning()
                            :
                                this.doNothing()
                            }>
                    <img className={this.cardImgClassValue} src={this.props.imgSrc} alt="Card cap" />
                        <div className={this.cardTextClassValue}>
                            <h5 className={this.textClass}>{this.props.name}</h5>
                        </div>
                    </Link>
                :
                    <div>
                        <img className={this.cardImgClassValue} src={this.props.imgSrc} alt="Card cap" />
                        <div className={this.cardTextClassValue}>
                            <h5 className={this.textClass}>{this.props.name}</h5>
                        </div>
                    </div>
                }
            </div>
        </>
    }

    displayDeniedWarning(){
        if (this.props.allowed == false)
        {
            this.props.openLoginMenu();
        }else{
            alert ("Screen Size is too small to play this game.")
        }
    }

    doNothing()
    {}
}
export default LinkCard;