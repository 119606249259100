const HomeSelectText =[
       {type: "Initial",  
        shortText: "Choose to have a roommate to reduce monthly costs.",
        text: "Since you are just starting out, you do not have enough cash saved to purchase a home. " +
          "As a result, you have decided it will be best to rent an apartment until you can save " +
          "enough money for a down-payment on a home.  One thing you can do to reduce the cost of an " +
          "apartment is to have a roommate.  The roommate will pay for half of the monthly costs for " +
          "the apartment."
        },
        {type: "GameInProgress",
         shortText: "You have approximately ||Savings|| dollars available for a down payment and your monthly " +
            "net income minus current home costs " +
            "is ||MonthlyIncome||. These amounts affect the home choices that you can make.",  
         text: "You have approximately ||Savings|| dollars available for a down payment. This amount includes " +
            "your current cash, total 401K savings and the estimated profit that you will make if you sell " +
            "your current home.  In order to get approved for a mortgage (home loan) the amount " +
            "available for your down payment must be at least ||Number||% of the cost of the home.   In addition to the down " +
            "payment, banks also review your monthly net income and all outstanding loan amounts. " +
            "This monthly net income review helps ensure that you can afford the mortgage payments. " +
            "Your current income minus all monthly home costs is ||MonthlyIncome||. " +
            "Once you make your roommate choice, the home choices displayed will be those that you " +
            "might be able to afford based on your savings and adjusted monthly net income. You can " +
            "choose to have one or more roommates.  If you choose to have roommate(s), the monthly cost " +
            "of the home or apartment will be reduced but this will not change the home choices." 
        },
        
]

export default HomeSelectText;