const SavingMoneyChallenge1ResultsText ="Wow! This is a surprise.  If you start when you are young, " +
"even if you can only save $200 a month, you will end up saving over 2 times more than if you "  +
"wait until you can afford to save $2000 a month.  Also, if you save $200 a month when you are young, you end up " +
"having to put only $72,000 in the bank, but if you save $2000 a month for 20 years, you will have to put $120,000 " +
"in the bank. " +
"Is this what you would have expected?  Sounds like it is smart to begin saving as much as you can " +
"when you are young so that you can save it over a longer period of time. Please review the other savings amounts. " +
"Then click the <Continue> button to finish this challenge."

export default SavingMoneyChallenge1ResultsText 
