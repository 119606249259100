import {Component} from 'react';
import CareerInput from '../shared-components/career_input';
import { withRouter } from "react-router";
import CareerChangeModal from './change_career_modal';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { resetPicturesForChanges } from './objects/reset_pictureInfo_for_changes';
import {setEventTrackerObj} from '../../objects/CommonUse/set_event_tracker_obj';
import {createIconTableObj} from './objects/create_icon_table_obj';

import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';

class ChangeCareer extends Component {
  
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            headers: setHeadersForFetch(),
            openCareerChangeModal: false,
            conditionLoading: true,
            conditionsData: null,
            openNotificationModal: false,
            notificationArr: [],
            conditionList: null,
            careerObj: {},
            subProcess: "changeCareer",
            environment: Config.dataURL.currentEnv,
        }
        this.loadConditions();
        this.getSkillSurvey();
    }    
        
    loadConditions()
    {
        const funcName = "eventConditionsList";
        const urlParm = "?triggeredByList=monthly,jobChange";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.state.conditionList = output.body;
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        };
    });
    }
    
    getSkillSurvey(){
        let skillsSurveyString = sessionStorage.getItem('skillsInterestsInput');
        let skillsSurveyArr = [];
        if (skillsSurveyString == null){
             skillsSurveyArr = this.gameInfo.gameData.skillSurvey;
             skillsSurveyString = skillsSurveyArr.join();
             sessionStorage.setItem('skillsInterestsInput', skillsSurveyString);
                }
    }
   
    render() {
        
    return <>
            {this.state.openCareerChangeModal ?
                <CareerChangeModal 
                    show={this.state.openCareerChangeModal} 
                    conditionList = {this.state.conditionList}
                    onClose={() => this.closeCareerChangeModal()} 
                    onConfirmCareerChange={(careerObj) => this.onConfirmCareerChange(careerObj)}
                    gameInfo = {() => this.props.gameInfo()}
                    setGameInfo = {(value, propertyName) => this.props.setGameInfo(value, propertyName)}
                    processError = {(errorObj) => this.props.processError(errorObj)}
                   />
            :
                <div></div>
            }
           
            <CareerInput 
                screenSize={this.props.screenSize}
                onSelected={() => this.onCareerSelected()} 
                onOpen={() => this.onOpen()}
                currentGameDate = {this.gameInfo.gameData.currentGameDate}
                startGameDate = {this.gameInfo.gameData.startGameDate}
                fromProcess = "GameView"
                processError = {(errorObj) => this.props.processError(errorObj)}
                />
    </>
    }

    onOpen(){
        //  this is not required for this process but is required in getting started 
    }

    onCareerSelected() {
        this.state.openCareerChangeModal = true;
    }
   
    closeCareerChangeModal() {
        this.state.openCareerChangeModal = false;
    }
    
    onConfirmCareerChange(careerObj) {
        this.state.careerObj = careerObj;
        this.state.openCareerChangeModal = false;
        this.collegeRequired = careerObj.requiresCollege;
        this.updatePictureInfo();
        if (this.collegeRequired == true){
            this.updateIconTable();
        }
        this.updateEventTracker();
        this.setBodyObj();
        this.updateCareerInfo();
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
           
        if (this.collegeRequired == true){
            this.props.history.push('/game-choices');
        }else{
            this.props.onChangeComplete();
            this.props.history.push("/status-display");
        }
    }

    updateIconTable(){
        let careerName = sessionStorage.getItem("careerName");
        let firstTimeInd = {firstTimeText: true, careerName: careerName};
        if (this.gameInfo.gameData.iconTable == undefined){
            this.gameInfo.gameData.iconTable = [];
        }
        this.gameInfo.gameData.iconTable = createIconTableObj("pendingJob", 
            this.gameInfo.gameData.iconTable, firstTimeInd); 
    }
      
    setBodyObj(){
        if (this.collegeRequired == true){
            this.gameInfo.gameData.conditionsTable = this.state.careerObj.conditionsTable;
            const newPendingJobObj = this.state.careerObj.pendingJobObj;
            newPendingJobObj.pictureInfo = this.newJobPictureInfo;
            this.addToPrevJobArr();
            newPendingJobObj.jobInfo = {...newPendingJobObj.jobInfo, prevJobArr: this.prevJobArr};
            this.gameInfo.gameData.pendingJob = newPendingJobObj;
        }else{
            this.changeGameData();
        }
        let moodPointsIncrease = this.state.careerObj.moodPoints;
        this.gameInfo.gameData.currentMoodPoints = 
            +this.gameInfo.gameData.currentMoodPoints + +moodPointsIncrease;
       
        this.bodyObj = {
            "jobInfo": JSON.parse(JSON.stringify(this.gameInfo.gameData.jobInfo)), 
            "loanTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.loanTable)),
            "assetTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.assetTable)),
            "expenseTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.expenseTable)),
            "conditionsTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.conditionsTable)),
            "currentMoodPoints": this.gameInfo.gameData.currentMoodPoints,
            "pendingJob": JSON.parse(JSON.stringify(this.gameInfo.gameData.pendingJob)),
            "iconTable": JSON.parse(JSON.stringify(this.gameInfo.gameData.iconTable)),
        }
    }

    changeGameData(){
        const newCareerID = sessionStorage.getItem("careerChoice");
        const newHealthInsInd = sessionStorage.getItem("jobHealthInsInd");
        const newJobRank = sessionStorage.getItem("jobRank");
        const newNbrYearsCollege = this.state.careerObj.nbrYearsCollege;
        this.addToPrevJobArr();
        const jobInfo = {careerID: newCareerID, healthInsInd: newHealthInsInd, 
            jobRank: newJobRank, nbrYearsCollege: newNbrYearsCollege, 
            nbrSkillSurvey: this.gameInfo.gameData.jobInfo.nbrSkillSurvey,
            jobStartGameMonth: this.gameInfo.gameData.gameNbrMonths,
            prevJobArr: this.prevJobArr};
        this.gameInfo.gameData.jobInfo = jobInfo;
        this.gameInfo.gameData.assetTable = this.state.careerObj.assetTable;
        this.gameInfo.gameData.expenseTable = this.state.careerObj.expenseTable;
        this.gameInfo.gameData.loanTable = this.state.careerObj.loanTable;
        this.gameInfo.gameData.conditionsTable = this.state.careerObj.conditionsTable;
        this.gameInfo.gameData.pendingJob = {};

    }

    addToPrevJobArr(){
        this.prevJobArr = [];
        if (this.gameInfo.gameData.jobInfo.prevJobArr != undefined){
            this.prevJobArr = this.gameInfo.gameData.jobInfo.prevJobArr;
        };
        const previousCareerName = sessionStorage.getItem("previousJobName");
        let previousJobObj = {previousJobName: previousCareerName, 
            gameMonth: this.gameInfo.gameData.gameNbrMonths};
        this.prevJobArr.push(previousJobObj);
    }
            
    updateCareerInfo()
    {  
        const funcName = "updateCareerInfo";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'PUT', headers: this.state.headers,
            body: JSON.stringify(this.bodyObj)})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            // "update of job info was successful";
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                            this.props.processError(errorObj);
                        }
                    });
                };
            });
    }

    updatePictureInfo(){
        let careerID = sessionStorage.getItem("careerChoice");
        let careerName = sessionStorage.getItem("careerName");
        let jobPictureImg = sessionStorage.getItem("careerPictureImg");
        let careerPictureImg = './assets/Jobs/' + jobPictureImg;
        let careerBackground = sessionStorage.getItem("careerBackgroundImg")
        let careerBackgroundImg = "./assets/jobBackground/" + careerBackground;
        let jobMaxSalary = sessionStorage.getItem("careerMaxSalary");
        let level2Title = sessionStorage.getItem("careerLevel2Title");
        let level3Title = sessionStorage.getItem("careerLevel3Title");
        let jobPictureInfo={jobMaxSalary: jobMaxSalary, jobLevel3: level2Title, jobLevel3: level3Title};
        this.newJobPictureInfo = "";
        if (this.collegeRequired == true){
            this.newJobPictureInfo = {careerID: careerID, careerName: careerName, 
                careerPictureImg: careerPictureImg, careerBackgroundImg: careerBackgroundImg,
                ...jobPictureInfo};
        }else{
            let pictureInfo = {...this.gameInfo.pictureInfo, ...jobPictureInfo}
            this.gameInfo.pictureInfo = resetPicturesForChanges("career", pictureInfo,
                careerID, careerName, careerPictureImg, careerBackgroundImg);
            this.props.setGameInfo(this.gameInfo.pictureInfo, 'pictureInfo');
        }
    }

    updateEventTracker(){
        const previousCareerName = sessionStorage.getItem("previousJobName");
        const careerName = sessionStorage.getItem("careerName");
        let response = {changeMade: "job", previous: previousCareerName, new: careerName};
        let eventObj = setEventTrackerObj(this.gameInfo.gameData, response, "Change to a New Job", 3000);
        this.eventArr=[eventObj];
        let currDateTime = getCurrentDateTime();
        let dataObject = {
            player: this.gameInfo.gameData.user,
            gameID: this.gameInfo.gameData._id,
            eventType: "ChangeGameChoices",
            date: currDateTime.date,
            time: currDateTime.time,
            process: "ChangeJob",
            events: this.eventArr,
        };
        const funcName = "saveEventTracker";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {
            method:'post',
            headers: this.state.headers,
            body: JSON.stringify(dataObject)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                           // update of event tracker was successful
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: dataObject};
                            this.props.processError(errorObj);
                        }
                    });
                };
            });
    }

    
}
export default withRouter(ChangeCareer);