import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {initializeErrorArray} from './objects/initialize_error_array';

class EventReportsInputModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      dataValidSw: true,
      openResponsePossible: false,  
    }    
    this.errArray = initializeErrorArray(2);
}

editInput(){
    this.setState({dataValidSw: true});
    this.errArray = initializeErrorArray(2);
    this.getFormValues();
    this.editFormFields();
     
    if (this.state.dataValidSw === true){
       let reportParameters = {player: this.player, gameID: this.gameID}; 
       this.props.onSubmit(reportParameters);  
    }
}

getFormValues(){
    this.player = document.getElementById("player").value;
    this.gameID = document.getElementById("gameID").value;
}

editFormFields(){
    this.setState({dataValidSw: true});
    this.editPlayer();
 }
 
 editPlayer(){
    if (this.player === undefined || this.player === ""){
      this.errorMsg = "Player name cannot be blank";
      this.checkErrorArray(0);
    }
  }
   
  checkErrorArray(msgIndex){
    if (this.errorMsg !== undefined && this.errorMsg !== ""){
        this.errArray[msgIndex] = {textType: "text-danger", 
            message: this.errorMsg.message};
          this.setState({dataValidSw: false});
    }
 }

render() {
                           
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="sm" scrollable="true">
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Enter Parameters for {this.props.rptTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
 
            <div className="text-left">
                  <p>Please complete the fields on this form and then click 
                     'Submit'.</p>
                <div>
                    <label className="font-weight-bold" htmlFor="player">
                        Player Name:&nbsp;&nbsp;</label>
                        <span className={this.errArray[0].textType}>
                        &nbsp;{this.errArray[0].message}</span>
                    <input type="text" className="col-lg" id="player" name="player">
                        </input> 
               </div>
               <div className="mt-2">
                   <label className="font-weight-bold" htmlFor="gameID">
                        Game ID (optional):&nbsp;&nbsp;</label>
                   <input type="text" className="col-small" id="gameID" name="gameID"></input> 
                   <span className={this.errArray[1].textType}>
                          &nbsp;{this.errArray[1].message}</span>
                </div>
                    
            </div>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
              {this.state.dataValidSw ?
                <div></div>
              :
                <div className="text-danger">Correct errors and try-again.</div>
              }
                         
              <Button variant="primary"  onClick={() => this.editInput()}>
                   Submit
              </Button>
        </Modal.Footer>
    </Modal>
    
  </>
  }
}

export default EventReportsInputModal;