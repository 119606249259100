import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { isNumeric } from '../../objects/CommonUse/checkIfNumeric';
import initializeErrorArray from './objects/initialize_error_array';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import calcRandomNbrWithMax from '../../objects/CommonUse/calc_random_number_with_max';
import getAuthCodes from './objects/get_auth_codes';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class AddAccountModal extends Component {
      constructor(props) {
          super(props);
          this.state={
              headers: setHeadersForFetch(),
              subProcess: "adminMain",
              environment: Config.dataURL.currentEnv,
          }    
          this.errorArray = initializeErrorArray(10);
          this.dataValidSw = true;
          
}

  componentDidMount(){
      let accountFound = true;
      this.buildAccountNbrArr();
      while (accountFound === true){
        this.accountNbr = calcRandomNbrWithMax(9,false, 6);
        accountFound = this.accountNbrArr.includes(this.accountNbr);
      };
  }

  buildAccountNbrArr(){
       this.accountNbrArr = [];
       for (let i=0; i<this.props.accountList.length; i++){
            let accountNbr = this.props.accountList[i].accountNbr;
            this.accountNbrArr.push(accountNbr);
       }
  }
 
  editInput(){
    this.dataValidSw = true;
    this.errorArray = initializeErrorArray(4);
    this.getFormValues();
    this.editFormValues();
    if (this.dataValidSw === true){
        this.formatRqstdUsersArr();
        this.buildAuthCodeArr();
        this.saveAccount();
        let accountObj = {accountNbr: this.accountNbr, primaryRole: this.primaryRole, 
          nbrPrimary: this.nbrPrimary, nbrPlayers: this.nbrPlayers, primaryAuthCodes: this.primaryAuthCodes}
        this.props.onClose(accountObj);
    }
  }

  getFormValues(){
      this.emailAddress = document.getElementById("emailAddress").value;
      this.primaryRole = document.getElementById("primaryRole").value;
      this.nbrPrimary = document.getElementById("nbrPrimary").value;
      this.nbrPlayers = document.getElementById("nbrPlayers").value;
  }
  
  editFormValues(){
   //  let emailAddressValid = validator.isEmail(this.emailAddress);
    let emailAddressValid = true;
    if (this.emailAddress === ""){
        emailAddressValid = false;
    }else{
        if (this.emailAddress.indexOf('@') <= 0){
            emailAddressValid = false;
        }
    }
    if (emailAddressValid === false){
          this.errorMsg = "Invalid email address";
          this.checkErrorArray(0);
    }
    if (this.primaryRole === "none"){
          this.errorMsg = "Primary Role is required";
          this.checkErrorArray(1);
    }
    
    const validNbrPrimary = isNumeric(this.nbrPrimary);
    if (validNbrPrimary === false){
        this.errorMsg = "Primary Number must be numeric";
        this.checkErrorArray(2);
    }
    const validNbrPlayers = isNumeric(this.nbrPlayers);
    if (validNbrPlayers === false){
        this.errorMsg = "Player Number must be numeric";
        this.checkErrorArray(3);
    }
  } 
     
 checkErrorArray(msgIndex){
    if (this.errorMsg !== undefined && this.errorMsg !== ""){
        this.errorArray[msgIndex] = {textType: "text-danger", 
            message: this.errorMsg};
        this.dataValidSw = false;
    }
  }

  formatRqstdUsersArr(){
      const dateObj =  getCurrentDateTime(); 
      this.rqstdUsersArr = [{role: this.primaryRole, dateRqstd: dateObj.date, nbrUsersRqstd: this.nbrPrimary},
          {role: "player", dateRqstd: dateObj.date, nbrUsersRqstd: this.nbrPlayers}];
    
  }

  buildAuthCodeArr(){
        let nbrAuthCodes = +this.nbrPrimary + +this.nbrPlayers;
        let authCodeList = getAuthCodes(nbrAuthCodes);
        this.primaryAuthCodes = [];
        this.authCodeArr = [];
        if (nbrAuthCodes !== authCodeList.length){
            alert("something did not work. Nbr auth codes returned not eq nbr rqstd.");
        }
        for (let i=0; i<+this.nbrPrimary; i++){
            let authCodeObj = {role: this.primaryRole, authCode: authCodeList[i]};
            this.authCodeArr.push(authCodeObj);
            this.primaryAuthCodes.push(authCodeList[i]);
        }
        for (let i=0; i<+this.nbrPlayers; i++){
            let indx = i + +this.nbrPrimary;
            let authCodeObj = {role: "player", authCode: authCodeList[indx]};
            this.authCodeArr.push(authCodeObj);
        }
                   
  }
   
    render() {
    return <>
         <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
            size="med"  scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
          <Modal.Title>Add New Account Entry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           
            <form className="text-left">
                <p>Please complete all fields on this form and then click 
                   'Submit'.</p>
                <div>
                   <label className="font-weight-bold" htmlFor="accountNbr">
                        Account Number:&nbsp;&nbsp;</label>
                   <input type="text" className="col-med" id="accountNbr" name="accountNbr"
                      value={this.accountNbr}></input> 
                </div>
                <div >
                  <label className="mt-2 font-weight-bold" htmlFor="emailAddress">
                    Email Address:</label>
                  <span className={this.errorArray[0].textType}>
                          &nbsp;{this.errorArray[0].message}</span>
                  <input type="text" className="col-med" id="emailAddress" name="emailAddress"></input> 
                </div>
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="primaryRole">Primary Role:</label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="primaryRole">
                        <option value="none">primary role not selected</option>
                        <option value="parent">Parent</option>
                        <option value="teacher">Teacher</option> 
                   </select>
                   <span className={this.errorArray[1].textType}>
                          &nbsp;{this.errorArray[1].message}</span>
                </div>
               
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="nbrPrimary">
                        Number of Primary Ids:</label>
                    <span className={this.errorArray[2].textType}>
                          &nbsp;{this.errorArray[2].message}</span>
                   <input type="text" className="col-small" id="nbrPrimary" name="nbrPrimary"></input> 
                </div> 
                               
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="nbrPlayers">
                        Number of Player Ids:</label>
                    <span className={this.errorArray[3].textType}>
                          &nbsp;{this.errorArray[3].message}</span>
                   <input type="text" className="col-small" id="nbrPlayers" name="nbrPlayers"></input> 
                  
                </div> 
               
            </form>
           </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
          {this.dataValidSw ?
            <div></div>
          :
            <div className="text-danger">Please Correct errors and try-again.</div>
          }
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
              Close
          </Button>
          <Button variant="primary"  onClick={() => this.editInput()}>
              Submit
          </Button>
        </Modal.Footer>
        </Modal>
        </>
    }

  
    saveAccount() {
      const currDateTime = getCurrentDateTime(); 
      this.accountObj = {
              "accountNbr": this.accountNbr,
              "emailAddress": this.emailAddress,
              "rqstdUsersArr": this.rqstdUsersArr,
              "authCodeArr": this.authCodeArr,
              "dateAdded": currDateTime.date,
              "dateUpdated": currDateTime.date,    
      };
      const funcName = "saveAccountCollection";
      const urlParm = "";
      const uri = buildUrl(this.state.environment, funcName, urlParm);
      fetch (uri,  
          {
          method:'POST',
          headers: this.state.headers,
          body: JSON.stringify(this.accountObj)
          })
          .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        alert("Your account has been saved!")
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.accountObj};
                        this.props.processError(errorObj);
                    }
                });
            };
        });
  }

}



export default AddAccountModal;