import {Component} from 'react';
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DragNDropModal from './drag_n_drop_modal';
import MoneyMgmtChallenge1InitialText from '../../dragNDropText/money_mgmt_challenge1_initial_text';
import MoneyMgmtChallenge1Dataset from './Datasets/money_mgmt_challenge1_dataset';
import MoneyMgmtChallenge2InitialText from '../../dragNDropText/money_mgmt_challenge2_initial_text';
import MoneyMgmtChallenge1HintsText from './text/money_mgmt_challenge1_hints_text';
import MoneyMgmtChallenge2Dataset from './Datasets/money_mgmt_challenge2_dataset';
import { createColumnArray } from '../../objects/DragNDrop/createColumnArray_function';
import { calcColumnScore } from '../../objects/DragNDrop/calcColumnScore_function';
import { dragNDropBefore } from '../../objects/DragNDrop/drag_n_drop_before_function';
import ExtraCreditConfigFile from './Datasets/extra_credit_config_file';
import MoneyMgmtChallenge2HintsText from './text/money_mgmt_challenge2_hints_text';

class MoneyMgmtBasics extends Component {
    
    constructor(props) { 
        super(props);
        this.state = {
            callRender: false,
            openSw: false,
            title: ExtraCreditConfigFile.general.title,
        }
        this.currentStatus = "DisplayChallenge1";
        this.dragNDrop = [];
        this.challenge1Score = 0;
        this.challenge1Complete = false;
        this.modalMessage = "";
        this.challenge2Score = 0;
        this.challenge2Complete = false;
        this.averageScore = 0;
        this.hintTable = [];
        this.hintNbr = 0;
        this.displayAlert = false;
        this.getMoneyMgmtScores();
    }
    
    getMoneyMgmtScores() {
        var challenge1Score = parseFloat(sessionStorage.getItem("moneyMgmtChallenge1Score"));
        if (!isNaN(challenge1Score)) {
           this.challenge1Complete = true;
           this.challenge1Score = challenge1Score;           
        }
    }
      
    openMoneyMgmtChallenge1() {
        this.currentStatus = "OpenChallenge1";
        this.dragNDrop = dragNDropBefore("Good Practices for Managing Money",
            MoneyMgmtChallenge1InitialText, "", "", "",
            MoneyMgmtChallenge1Dataset, "MoneyMgmtChallenge1", 0); 
        this.hintTable = MoneyMgmtChallenge1HintsText;
        this.setState({openSw: true});

    }
    
    closeMoneyMgmtChallenge1() {
        this.currentStatus = "DisplayChallenge1"
        this.setState({openSw: false});
    }
    
    saveMoneyMgmtChallenge1() {
        var columnArray=createColumnArray(this.dragNDrop.cookieNamePfx, 2);
        this.challenge1Score = calcColumnScore(columnArray);
        // Getting 1 wrong results in a score of 60...
        if (+this.challenge1Score >= 100) {
            this.modalMessage = "";
            sessionStorage.setItem("moneyMgmtChallenge1Score", this.challenge1Score);
            this.currentStatus = "DisplayChallenge2";
            this.setState({openSw: false});
        } else {
            this.modalMessage= "Not quite right... Try again.";
            this.displayAlert = true;
            if (this.hintNbr < this.hintTable.length){
                 this.hintNbr+=1;
            }
            this.setState({callRender: true});
        } 
     }
  
    openMoneyMgmtChallenge2() {
        this.currentStatus = "OpenChallenge2";
        this.modalMessage="";
        this.hintNbr = 0;
        this.dragNDrop = dragNDropBefore("Why Should You Develop a Monthly Budget (Plan)?",
            MoneyMgmtChallenge2InitialText, "", "", "", 
            MoneyMgmtChallenge2Dataset, "MoneyMgmtChallenge2", 0, "");
        this.hintTable = MoneyMgmtChallenge2HintsText;
        this.setState({openSw: true});
     
    }

    closeMoneyMgmtChallenge2() {
        this.currentStatus = "DisplayChallenge2"
        this.setState({openSw: false});
    }
    
    saveMoneyMgmtChallenge2() {
        var columnArray=createColumnArray(this.dragNDrop.cookieNamePfx, 2);
        this.challenge2Score = calcColumnScore(columnArray);
        if (this.challenge2Score > 85) {
            this.modalMessage = "";
            this.finishMoneyMgmt();
            this.setState({openSw: false});
        } else {
            this.modalMessage= "Not quite right... Try again.";
            this.displayAlert = true;
            if (this.hintNbr < this.hintTable.length){
                 this.hintNbr+=1;
            }
            this.setState({callRender: true});
        } 
     }

    closeAlert(){
        this.displayAlert = false;
        this.setState({callRender: false});
    }
    
    
    render() {
        if (this.challenge2Complete ===  true) {
            this.finishMoneyMgmt()
        }
        this.gutters = "";
        if (this.dragNDrop.modalSize ===  "xl" && 
            (this.props.screenSize ===  "narrow" || this.props.screenSize ===  "medium")){
             this.gutters = "modal-95";
        }
    
    return <>
   
     <Modal show={this.props.show}  onHide={() => this.props.onClose()} size="med" scrollable="true"
        animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Extra Credit - Money Management</Modal.Title> 
          </Modal.Header>
          <Modal.Body>
          
            {this.currentStatus ===  "DisplayChallenge1" ?
                <span>
                <div className="text-center">
                    <p className="text-left m-4">There are several basic concepts that are
                      essential to being successful with money management. Although these
                      are fairly basic, it is suprising how many people don't know how much 
                      money they make and how much money they spend each month.  Click on the
                      button below to accept this challenge.</p>
                </div>         
                <div className="text-center">
                    <button className="btn btn-primary w-75 mt-4" 
                        onClick ={()=> this.openMoneyMgmtChallenge1()}>
                          MoneyMgmt Challenge #1
                    </button>
                </div>
                </span> 
            :
                <div></div>
            }

            {this.currentStatus ===  "OpenChallenge1"  ?
                <DragNDropModal show={this.state.openSw} 
                    gutters={this.gutters}
                    onClose={() => this.closeMoneyMgmtChallenge1()} 
                    onSave={() => this.saveMoneyMgmtChallenge1()}
                    closeAlert={() => this.closeAlert()}
                    inputDataset={this.dragNDrop.inputData}
                    modalTitle={this.dragNDrop.modalTitle}
                    modalSize={this.dragNDrop.modalSize}
                    introText={this.dragNDrop.introText}
                    cookieNamePfx={this.dragNDrop.cookieNamePfx}
                    buttonType={this.dragNDrop.buttonType}
                    message={this.modalMessage}
                    hintTable={this.hintTable}
                    hintNbr={this.hintNbr}
                    displayAlert={this.displayAlert}
                    title={this.state.title}
                />
            :
                <div></div>
            }
            
            {this.currentStatus ===  "DisplayChallenge2" ?
                <span>
                <p className="mt-4 ml-4 p-2">Congratulations, you have successfully completed the first
                       challenge, would you like to try another one?  Your score in the game will be updated
                       when you complete the second challenge.
                </p>
                <div className="text-center">
                    <button className="btn btn-primary w-75 mt-6" 
                        onClick ={()=> this.openMoneyMgmtChallenge2()}>
                            MoneyMgmt Challenge #2
                    </button>
                </div>
                </span> 
                :
                    <p></p>
            }
         
            {this.currentStatus ===  "OpenChallenge2"  ?
                <DragNDropModal show={this.state.openSw} 
                    gutters={this.gutters}
                    onClose={() => this.closeMoneyMgmtChallenge2()} 
                    onSave={() => this.saveMoneyMgmtChallenge2()}
                    closeAlert={() => this.closeAlert()}
                    inputDataset={this.dragNDrop.inputData}
                    modalTitle={this.dragNDrop.modalTitle}
                    modalSize={this.dragNDrop.modalSize}
                    introText={this.dragNDrop.introText}
                    cookieNamePfx={this.dragNDrop.cookieNamePfx}
                    buttonType={this.dragNDrop.buttonType}
                    amountSw={this.dragNDrop.amountSw}
                    message={this.modalMessage}
                    hintTable={this.hintTable}
                    hintNbr={this.hintNbr}
                    displayAlert={this.displayAlert}
                    title={this.state.title}
                />
            :
                <div></div>
            }
           
         </Modal.Body>
          <Modal.Footer className="text-right">
          <img src="./assets/sidebar_icon.png" className="train_icon_thumbnail_img mr-5"
                alt="train"/>
          <Button variant="secondary"   onClick={() => this.props.onClose()}>
               Close
            </Button>
          </Modal.Footer>
          </Modal>
        </>
    }

    finishMoneyMgmt() {
        this.averageScore = (+this.challenge1Score + +this.challenge2Score) / 2; 
        this.calculateMoneyMgmtPoints();
        sessionStorage.setItem("previousExtraCreditProcess", "MoneyMgmt");
        this.props.onCompleted();
    }

    calculateMoneyMgmtPoints(){
        var earnedAmt = 0;
        let processName = "MoneyMgmt";
        let earningsObj = ExtraCreditConfigFile.exercises.find(elem => elem.category === processName);
        earnedAmt = (this.averageScore/100 * +earningsObj.amt).toFixed(2);
                  
        const moneyMgmtObj = {processName: processName, score: this.averageScore,
            assetType: earningsObj.type, amount: earnedAmt, level: earningsObj.level};
        sessionStorage.setItem("extraCreditCompleted", JSON.stringify(moneyMgmtObj));
    }
}

export default withRouter(MoneyMgmtBasics);