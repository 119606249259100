const SavingMoneyChallenge2Dataset = {
    /*  spaces between content in items ensures that content is lined up correctly on the modal
        the column property is used to indicate the correct answer.   */
    items: {
        "item-1": { id: "item-1", content: "Most of your savings should be invested in a portfolio that " + 
                "has a fairly low risk and allows you to earn a moderate interest.", 
                answer: "column-2" },
        "item-2": { id: "item-2", content: "You can begin saving when you are within 5 years of retiring " +
                "as long as you can save at least $500 per month.", 
                answer: "column-3" },
        "item-3": { id: "item-3", content: "When you are close to retirement, the last thing " +
                "you should do is invest in some high-risk situation where you are likely to " +
                "lose your money.  ",
                answer: "column-2" },
        "item-4": { id: "item-4", content: "If you get an unexpected cash amount, pay off credit card " + 
                "debt before investing (interest amounts earned on the investment is likely less than " + 
                "credit card interest).",
                answer: "column-2" },
        "item-5": { id: "item-5", content: "Emergency funds are not necessary if you have a 401K." + 
                "You can always withdraw the money from the 401K, even if you do have to pay an interest " +
                "penalty.  " , 
                answer: "column-3" },
        "item-6": { id: "item-6", content: "If at all possible, begin saving when you are young.", 
                answer: "column-2" },   
        "item-7": { id: "item-7", content: "It is OK to withdraw from a 401K before the age of 59 1/2, " +
                "the  interest penalty is only 10% and of course you have to pay income tax on the amount " +
                "you withdraw.  ", 
                answer: "column-3" },   
    },

    columns: {
        "column-1": { id: "column-1", title: "Saving Advice", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4", "item-5", "item-6", "item-7"] },
        "column-2": { id: "column-2", title: "Advice that is Good", itemIds: [] },
        "column-3": { id: "column-3", title: "Advice that is Not Good", itemIds: []},
       
    },

    columnOrder: ["column-1", "column-2", "column-3"]
}

export default SavingMoneyChallenge2Dataset