import {Component} from 'react';
import Button from 'react-bootstrap/Button';

class AlphabetCard extends Component {
       
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false}; 
    }
 
    render() {
    return <>
        <Button className="mt-1 m-1"variant="success"
            disabled={this.props.alphabetInfo.disable} onClick={() => this.props.onClick()}> 
                <p className="font-size-very-large use-font-comic-sans very_very_small_img" > 
                   {this.props.alphabetInfo.letter} </p>
       </Button>
           
    </>
    }

}

export default AlphabetCard;