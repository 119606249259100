import { makeTextPlural} from "../CommonUse/make_text_plural";

export const dragNDropInsertItems = (games, items, columns) => {   
        
     var itemNbrArr = [];
     var newItemArr = [];
     var itemList = [];
     var allItems = [];
     let nbrColumns = games.nbrColumns;
    
     allItems = JSON.parse(JSON.stringify(items.itemList));
     //   random select items until max nbr items has been complete (up to 2 of some items)
  
    do {   
        let nbrItems = allItems.length;
        let itemIndx = Math.floor(Math.random() * nbrItems); 
        let slctItem = allItems[itemIndx];
        let itemObj = {sortKey: slctItem.category + "|" + slctItem.picture, ...slctItem}
        itemList.push(itemObj);
        let duplicateItem = Math.floor(Math.random() * 2); 
        if (duplicateItem == 0){
            
           itemList.push(itemObj);
        }
        allItems.splice(itemIndx,1);
    }
    while (itemList.length <= games.nbrItems && allItems.length > 0);

    // derive answer array
    let answerArr = [];
    let itemListCopy = JSON.parse(JSON.stringify(itemList))
    var nbrAnswerColumns = 0;
 
    nbrAnswerColumns = (nbrColumns - 1)/2;
    var answerColArr = [];
       
    for (let i=0; i<nbrAnswerColumns; i++){
        let itemsSelected = games.nbrInList;
        let columnNbr = (i + 1) * 2;
        let answerCol = "column-" + columnNbr;
        let listCol = "column-" + (columnNbr + 1);
        let answerColObj = {answer: answerCol, list: listCol};
        answerColArr.push(answerColObj);
        let nbrItemsSelected = 0;
        
        do {
          let nbrItems = itemListCopy.length;
          let itemIndx = Math.floor(Math.random() * nbrItems);
          let slctPicture = itemListCopy[itemIndx].picture;
          let slctItem = itemListCopy[itemIndx];
          itemListCopy.splice(itemIndx,1)
          var howMany = 1;
          var deleteIdxArr = [];
          for (let i=0; i<itemListCopy.length; i++){
              if (slctPicture == itemListCopy[i].picture){
                howMany += 1;
                deleteIdxArr.push(i);
              }
          }
          let answerItem = {column: answerCol, count: howMany, ...slctItem};
          answerArr.push(answerItem);
          for (let i=deleteIdxArr.length - 1; i>=0; i--){
              let indx = deleteIdxArr[i]
              itemListCopy.splice(indx,1);
          }
          nbrItemsSelected += 1;
        }
        while (nbrItemsSelected < itemsSelected);
    }
  
    itemList.sort((a, b) => (a.sortKey > b.sortKey) ? 1 : -1)
   
    var testItemList = [];
    //  need to transpose items due to way they appear on the display (reverse column-row)
    for (let i=0; i<itemList.length; i++){
         let position = i + 1;
         let row = +(Math.floor(position/3)) + 1;  
         let column = (position % 3);
         if (column == 0){
             row = row - 1;
             column = 3;
         }
         if (row < 10){
            row = "0" + row;
       } 
         let columnRow = column + row;

         let newItemObj = {columnRow: columnRow, ...itemList[i]};
         testItemList.push(newItemObj);
    }
   
    testItemList.sort((a, b) => (a.columnRow > b.columnRow) ? 1 : -1);
    itemList.splice(0);
    itemList = testItemList;

    //  create items for grocery store items (column 1 items)

    var itemNbrArr = [];
    let answerCol = "";
    var itemAnswerArr = [];
    for (let i=0; i<itemList.length; i++){
         let newItemId = "item-" + (i+1);
         itemNbrArr.push(newItemId);
         let pictureURL = items.directory + itemList[i].picture;
         let answerIdx = answerArr.findIndex( element => {
            if (element.picture === itemList[i].picture) {
                return true;
            }
         });
         if (answerIdx != -1){
               answerCol=answerArr[answerIdx].column;
         }else{
               answerCol="column-1";
         }
         let itemAnswer = {id: newItemId, answer: answerCol};
         itemAnswerArr.push(itemAnswer);
         let itemInfo = {id: newItemId, picture: pictureURL, answer: answerCol, type: items.type,
            className: items.className, disabled: false };
        let itemObj = {[newItemId]: itemInfo};
        newItemArr.push(itemObj);
     }
   
     // insert items for the shopping list column(s) --> column 3, 5, etc.
     let startItemNbr = itemList.length + 20;
     let listColArr = [];
     for (let i=0; i<answerArr.length; i++){
          let newItemId = "item-" + (startItemNbr + i + 1);
          let newText = answerArr[i].name;
          if (answerArr[i].count > 1){
              newText = makeTextPlural(answerArr[i].name, answerArr[i].pluralRule);
          }
          let orderText = answerArr[i].count + " " + newText;
          let slctIdx = answerColArr.findIndex( element => {
            if (element.answer === answerArr[i].column) {
                return true;
            }
         });
          let itemObj =  {id: newItemId, content: orderText, answer: answerColArr[slctIdx].list, 
            type: "content", disabled: true};
          listColArr.push(itemObj);
          let fullItemObj = { [newItemId]: itemObj}
          newItemArr.push(fullItemObj);
     }
  
  // create column entries 
  
     var newColumnArr = [];
     var columnNbrArr = [];
       
     let columnList = columns.columnList;
    
     for (let i=0; i<nbrColumns; i++){
          let newColumnId = "column-" + (i+1);
          columnNbrArr.push(newColumnId);  
          let answerItemNbrArr = [];
          if (newColumnId == "column-1"){
            answerItemNbrArr = itemNbrArr;
          }else{
            let columnFound = answerColArr.some(elem => elem.list === newColumnId)
            if (columnFound == true){
                for (let j=0; j<listColArr.length; j++){
                    if (listColArr[j].answer == newColumnId){
                        let itemId = listColArr[j].id;
                        answerItemNbrArr.push(itemId);
                    }
                }
            }
          }
           
          let fullObj = {id: newColumnId, title: columnList[i].title, className: columnList[i].className, itemIds: 
              answerItemNbrArr,  multColumns: columns.columnList[i].multColumns, 
              columnSize: columns.columnList[i].columnSize};
          let columnObj = {[newColumnId]: fullObj};
          newColumnArr.push(columnObj);
     }
  
     let allItemsObj = {...newItemArr[0], ...newItemArr[1], ...newItemArr[2], ...newItemArr[3], ...newItemArr[4],
        ...newItemArr[5],  ...newItemArr[6],  ...newItemArr[7],  ...newItemArr[8],  ...newItemArr[9],
        ...newItemArr[10], ...newItemArr[11], ...newItemArr[12], ...newItemArr[13], ...newItemArr[14],
        ...newItemArr[15], ...newItemArr[16], ...newItemArr[17], ...newItemArr[18], ...newItemArr[19],
        ...newItemArr[20], ...newItemArr[21], ...newItemArr[22], ...newItemArr[23], ...newItemArr[24],
        ...newItemArr[25], ...newItemArr[26], ...newItemArr[27], ...newItemArr[28], ...newItemArr[29],
        ...newItemArr[30], ...newItemArr[31], ...newItemArr[32], ...newItemArr[33], ...newItemArr[34],
        ...newItemArr[35], ...newItemArr[36], ...newItemArr[37], ...newItemArr[38], ...newItemArr[39],
        ...newItemArr[40], ...newItemArr[41], ...newItemArr[42], ...newItemArr[43], ...newItemArr[44],
        ...newItemArr[45], ...newItemArr[46], ...newItemArr[47], ...newItemArr[48], ...newItemArr[49],
     };
    
     let allColumnsObj = {...newColumnArr[0], ...newColumnArr[1], ...newColumnArr[2], ...newColumnArr[3], ...newColumnArr[4]}
    
    let columnIdObj = {columnOrder: columnNbrArr};
     
    let dragNDropDataset = {items: allItemsObj, columns: allColumnsObj, ...columnIdObj}; 
              
return dragNDropDataset; 
}
