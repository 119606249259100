
export const determineDecorationImageURL = (conditionsList) => {

    let decorationConditions = [
        {condName: "celebrate-christmas", imageName: "ChristmasTree.png"},
        {condName: "celebrate-chanukah", imageName: "manora.png"}];
    let holidayImageObj = {decorationFound: false};
    for (let i=0; i<decorationConditions.length; i++){
        const conditionObj = 
            conditionsList.find(elem => elem.condName === decorationConditions[i].condName)
        if (conditionObj != undefined){
            const imageClass = "holiday-image-class";
            const imagePosition = "holiday-position";
            const imageURL = "./assets/Events/" + decorationConditions[i].imageName;
            holidayImageObj = {decorationFound: true, imageClass: imageClass, imagePosition: imagePosition,
                imageURL: imageURL};
            break;
        }
    }
          
    return holidayImageObj;
   
}
export default determineDecorationImageURL;

