import iconTableInfoDataset from "../datasets/icon_table_info_dataset";

export const createIconTableObj = (iconName, iconTable, additionalInfo) => {

    var newIconTable = [];
    if (iconTable != undefined){
        newIconTable = iconTable;
    }

    let iconAlreadySet = false;
    //  check to see if this item is already in the iconTable
    for (let i=0; i<iconTable.length; i++){
        if (iconName == iconTable[i].iconName){
            newIconTable[i].firstTime = false;
            newIconTable[i].validInd = true;
            iconAlreadySet = true;
            break;
        }
    }

    // if iconName not already in the table then create new object
    
    if (iconAlreadySet == false){
        let iconTableInfo = iconTableInfoDataset;
        for (let i=0; i<iconTableInfo.length; i++){
            if (iconTableInfo[i].iconName == iconName){
                let iconTableObj = JSON.parse(JSON.stringify(iconTableInfo[i]));
                if (additionalInfo != undefined){
                    iconTableObj.additionalInfo = additionalInfo;
                }
                newIconTable.push(iconTableObj);
                break;
            }
        }
    }

    return newIconTable;
   
}





