const LoanChallenge2HintsText =
    ["You can use the loan calculator to determine  " +
        "the monthly payment amount for the requested loan.",
      "Loans are not approved if the individual ",
         "cannot afford the monthly loan payments.",
      "People who have missed payments for credit cards or loans and " +
         "individuals who have declared bankruptcy will have a difficult " +
         "time getting a loan approved.",
      "College loans are often guaranteed by the government so these are " +
         "usually approved."
     
    ]
   

export default LoanChallenge2HintsText;