import EventOnLitList from "../datasets/event_onLit_dataset";

export const editOnLit = (onLit, category, source, responseLit) => {

  var errorObj="";
  var onLitValid = true;
  var errMessage = "";
  
  if (onLit === undefined){
        onLitValid = false;
        errMessage = "OnLit is undefined";
  }else{
    if (source === "online"){ 
      if (onLit === "No-OnLit") {
           onLitValid = false;
           errMessage = "Select a valid On value";
      }
    }else{
      if (onLit === ""){
            onLitValid = false;
            errMessage = "OnLit cannot be blank";
      }
    }

    if (errorObj === ""){
      let onLitFindObj = EventOnLitList.find(elem => elem.value === onLit);
      if (onLitFindObj === undefined){
            onLitValid = false;
            errMessage = "OnLit not found in dataset";
      }else{
          if (onLitFindObj.category !== category){
            onLitValid = false;
            errMessage = "Onlit invalid for category";
           }
      }
    }
  }
  if (onLitValid === false){
    errorObj = {fieldClass: responseLit, fieldName: "OnLit", fieldValue: onLit, message: errMessage}
  }
   
  return errorObj;
 
}