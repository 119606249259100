export const resetPicturesForChanges = (changeReason, pictureInfo, newID, newName, newImg, 
        newSecondaryImg) => {

    var newJobID = pictureInfo.jobID;
    var newJobImg = pictureInfo.jobImg;
    var newJobName = pictureInfo.jobName;
    var newJobBackground = pictureInfo.jobBackground;
    var newJobLevel2Title = pictureInfo.jobLevel2;
    var newJobLevel3Title = pictureInfo.jobLevel3;
    var newJobMaxSalary = pictureInfo.maxSalary;
    var newHomeID = pictureInfo.homeID;
    var newHomeImg = pictureInfo.homeImg; 
    var newHomeName = pictureInfo.homeName; 
    var newLivingRoomImg = pictureInfo.livingRoomImg;
    var newCarID = pictureInfo.carID; 
    var newCarImg = pictureInfo.carImg; 
    var newCarName = pictureInfo.carName;
  
    switch(changeReason) {
        case "career":
            newJobID = newID;
            newJobImg = newImg;
            newJobName = newName;
            newJobBackground = newSecondaryImg;
            newJobMaxSalary = pictureInfo.maxSalary;
            newJobLevel2Title = pictureInfo.level2Title;
            newJobLevel3Title = pictureInfo.level3Title;
            break;
        case "home":
            newHomeID = newID;
            newHomeImg = newImg;
            newHomeName = newName;
            newLivingRoomImg = newSecondaryImg;
            break;
        case "car":
            newCarID = newID;
            newCarImg = newImg;
            newCarName = newName;
            break;
    }

    let newPictureInfo = {
        jobID: newJobID,
        jobImg: newJobImg, 
        jobName: newJobName, 
        jobBackground: newJobBackground,
        jobMaxSalary: newJobMaxSalary,
        jobLevel3Title: newJobLevel2Title,
        jobLevel3Title: newJobLevel3Title,
        homeID: newHomeID,
        homeImg: newHomeImg, 
        homeName: newHomeName, 
        livingRoomImg: newLivingRoomImg,
        carID: newCarID, 
        carImg: newCarImg, 
        carName: newCarName};

    return newPictureInfo;
   
}





