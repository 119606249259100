const VacationInformationText =[
    {activityName: "Paris", rules: "OOO-La-La... I'm sure you have always wanted to go to Paris! " +
    "This is your chance to enjoy the sites. " +
    "See if you can guess the site that you are seeing before the picture is fully revealed! " +
    "There are ||nbrTimes|| sites for you to see.  Have a great time on your vacation! ",
    rulesBrief: ""
    },
    {activityName: "SanDiego", rules: "If you love the sun and the beach, this is the place for you! " +
    "This is a great place to visit.  There are a lot of things to see and do. " +
    "See if you can guess the site that you are seeing before the picture is fully revealed! " +
    "There are ||nbrTimes|| sites for you to see.  Have a great time on your vacation! ",
    rulesBrief: ""
    },
    {activityName: "Yellowstone", rules: "Ah, there is nothing like getting into the out-doors and " +
    "there is no better place than Yellowstone.  See the wildlife, or travel around the park to see " +
    "the natural phenomena. " +
    "See if you can guess the site that you are seeing before the picture is fully revealed! " +
    "There are ||nbrTimes|| sites for you to see.  Have a great time on your vacation! ",
    rulesBrief:  ""
    },
   
  
]
        
export default VacationInformationText 
 