import { calcMonthlyIncome } from "../../../objects/CommonUse/calcMonthlyIncome";
import { findRemoveTableItem } from "../../../objects/AssetsExpenses/find_remove_table_item";

export const calcMonthlyIncomeMinusHomeCosts = (homeType, assetTable, expenseTable, loanTable) => {
       let incomeObj = calcMonthlyIncome(assetTable, expenseTable, loanTable);
       let monthlyIncome = +incomeObj.income;
       let monthlyHOAAmount = 0;
       let mortgageRentAmt = 0;
       let mortgagePMIAmt = 0
       let roommateRent = 0;
       let monthlyInsAmount = 0;
       if (homeType.condName == "homeType-apartment"){
              //  if current home is apartment, add rent back into monthly income as the rent 
              //      amount will be replaced by new home choice.
              let currentRentObj = findRemoveTableItem(expenseTable, "rent", false);
              if (currentRentObj.expAmount != undefined){
                  mortgageRentAmt = currentRentObj.expAmount;
              } 
              let currentRentInsObj = findRemoveTableItem(expenseTable, "rentersIns", false)
              if (currentRentInsObj.expAmount != undefined){
                  monthlyInsAmount = currentRentInsObj.expAmount;
              }
          }else{
              let roommateRentObj = findRemoveTableItem(assetTable, "roommateRent", false);
              if (roommateRentObj.assetAmt != undefined){
                     roommateRent = +roommateRentObj.assetAmt;
              }
              let currentHOAObj = findRemoveTableItem(expenseTable, "homeHOA", false);
              if (currentHOAObj.expAmount != undefined){
                  monthlyHOAAmount = +currentHOAObj.expAmount;
              }
              let currentMortgageObj = findRemoveTableItem(loanTable, "mortgage", false);
              if (currentMortgageObj.loanAmt != undefined){
                  mortgageRentAmt = +currentMortgageObj.loanAmt;
              }
              let currentPMIObj = findRemoveTableItem(loanTable, "mortgagePMI", false);
              if (currentPMIObj.loanAmt != undefined){
                  mortgagePMIAmt = +currentPMIObj.loanAmt;
              }
              let currentHomeInsObj = findRemoveTableItem(expenseTable, "homeIns", false);
              if (currentHomeInsObj.expAmount != undefined){
                  monthlyInsAmount = currentHomeInsObj.expAmount;
              }
          }
          let monthlyIncomeMinusHomeCosts = (+monthlyIncome + +mortgageRentAmt + +monthlyHOAAmount +
             +mortgagePMIAmt - +roommateRent + +monthlyInsAmount).toFixed(2);
          let monthlyIncomeMinusRoommateAmt = (+monthlyIncome + +roommateRent).toFixed(2);
          let returnMonthlyIncomeObj = {fullMonthlyIncome: monthlyIncome, 
            monthlyIncomeMinusRoommateAmt: monthlyIncomeMinusRoommateAmt, 
            monthlyIncomeMinusHomeCosts: monthlyIncomeMinusHomeCosts }    
    return returnMonthlyIncomeObj;
   
}
