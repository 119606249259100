import calcRandomNumberWithMax from "../../../objects/CommonUse/calc_random_number_with_max";

export const getFriendImageURL = (friendList, playerFriendInfo, currentCard, currentGameDate) => {
    let friendName= "Your friend";
    let newFriendList = [];
    for (let i=0; i<playerFriendInfo.length; i++){
        for (let j=0; j<friendList.length; j++){
            if (playerFriendInfo[i].friendName == friendList[j].name){
                if (friendList[j].introDate != "" &&
                    friendList[j].introDate < currentGameDate){
                        if (currentCard.eventCategory == "joke"){
                            for (let k=0; k<+playerFriendInfo[i].weightedOdds; k++){
                                 newFriendList.push(friendList[j]);
                            }
                        }else{
                            newFriendList.push(friendList[j]);
                        }
                    break;
                }
            }
        }
    }   
    
    if (newFriendList.length > 1){
        let nbrFriends = newFriendList.length;
        let friendIndx = calcRandomNumberWithMax(nbrFriends, true);
        friendName = newFriendList[friendIndx].name;
    }else{
        if (newFriendList.length == 1){
            friendName = newFriendList[0].name;
        }
    }
     
    let cardImage = false;
    let cardImageURL = "";
    if (friendName != "Your friend"){
        const playerFriend = playerFriendInfo.find(elem => elem.friendName === friendName);
        cardImageURL = playerFriend.friendImgURL;
    }
   
    let friendObj = {friendName: friendName, cardImageURL: cardImageURL}
    return friendObj;
}
export default getFriendImageURL;

