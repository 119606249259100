import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import "./Admin/admin.css";
import Button from 'react-bootstrap/Button';
import MoneyMgmtStudentTestCard from './money_mgmt_student_test_card';
import removeDupsFromMoneyMgmtTest from './objects/remove_dups_from_money_mgmt_test';

class MoneyMgmtStudentTestModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      
      }    
    this.prepareMoneyMgmtTestReport();
}

prepareMoneyMgmtTestReport(){
  //  hopefully this will not be required in the future!!! -- need to figure out how/why duplicates are created
    this.newTestData =  removeDupsFromMoneyMgmtTest(this.props.moneyMgmtTestArr) 
  }
 
sortStudentTestReport(){
    this.sortParm = document.getElementById("sortParm").value;
    switch (this.sortParm){
        case "default":
          this.newTestData.sort((a, b) => (a.user + a.dateAdded > b.user + a.dateAdded) ? 1 : -1)
          break;
        case "nbrGameMonths":
          this.newTestData.sort((a, b) => (+a.gameNbrMonths < +b.gameNbrMonths) ? 1 : -1)
          break;
        case "nbrCorrect":
          this.newTestData.sort((a, b) => (+a.nbrCorrect < +b.nbrCorrect) ? 1 : -1)
          break;
        default:
          break;
    }
    this.setState({waitState: false});
}
   
render() {
        this.studentTestResults = this.newTestData.map((testInfo, index) =>
          <MoneyMgmtStudentTestCard testInfo={testInfo}  key={index} 
           />
              );
              
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="lg" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Money Management Test - Player Results 
              <div>
                  <label className="font-weight-bold" htmlFor="player">Sort by:&nbsp;&nbsp;</label>
                        <select className="btn btn-sm btn-outline-secondary dropdown-toggle" id="sortParm"
                            onChange={() => this.sortStudentTestReport()}>
                        <option value="default">Player Name and Test Date (default)</option>
                        <option value="nbrGameMonths">Number Game Months (descending)</option>
                        <option value="nbrCorrect">Number Correct (descending)</option>
                      </select>
              </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
              <span>
              <table className="table m-0">
                <tbody>
                  <tr className="header-sticky">
                    <th>Test Date</th>
                    <th>Player Name</th>
                    <th>Number of Games</th>
                    <th>Nbr Game Months</th>
                    <th>Number Correct</th>
                    <th>Percent Correct</th>
                  </tr>
                  {this.studentTestResults}
                </tbody>
              </table>
                             
              </span>
                  
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
             
              <Button variant="primary"  onClick={() => this.props.onClose()}>
                   Close
              </Button>
              
        </Modal.Footer>
       
    </Modal>
    
  </>
  }
}

export default MoneyMgmtStudentTestModal;