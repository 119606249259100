
export const getHomePicture = (photoDir, photoArr, photoName, textType) => {

      let pictureURL = "";
      for (let i=0; i<photoArr.length; i++){
           if (photoArr[i].label == photoName){
              pictureURL = './assets/' + photoDir +  "/" + photoArr[i].img;
              if (textType != undefined && textType == true){
                  if (photoArr[i].text != undefined){
                        pictureURL = pictureURL + "|" + photoArr[i].text;
                  }else{
                      pictureURL = pictureURL + "|" + "undefined";
                  }
              }
              break;
            }
        }
  
               
   return pictureURL;
   
}
