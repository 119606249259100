import {Component} from 'react';

class TableRowCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
     
    return <> 
           <tr className="row-100">
                <td className="col-sm-2 text-center mx-4">{this.props.tableInfo.index}</td>
                <td className="col-sm-4 text-left">{this.props.tableInfo.column1}</td>
                <td className="col-sm-6 text-left">{this.props.tableInfo.column2}</td>
            </tr>
           
    </>
    }
}

export default TableRowCard;