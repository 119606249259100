import ConfigFile from '../../datasets/config_file';
import assetExpLoanShortnameDataset from '../../datasets/asset_exp_loan__shortname_dataset';
import {monthlyLoanAmt} from './loanAmtCalc_function';
import { calcNbrMonthsBtwnDates } from '../CommonUse/nbr_months_btwn_dates_calc';
import { calcInflationAmt } from '../CommonUse/calc_inflation_amt';

export const createTableItem = ( shortName, description, startDate, amount, gameDate, 
        calcInflationAmtSw ) => {
    
    let assetExpLoanType = assetExpLoanShortnameDataset;
    let itemType = "";
    let itemName = "";
    let newTableObj = {};
    let itemDesc = "";
    let loanTerm = "";
    let chargeCreditCard = false;
    for (let i=0; i<assetExpLoanType.length; i++){
        if (shortName == assetExpLoanType[i].shortName){
            itemType = assetExpLoanType[i].type;
            itemName = assetExpLoanType[i].fullName;
            itemDesc = assetExpLoanType[i].desc;
            if (assetExpLoanType[i].chargeCreditCard !== undefined){
                chargeCreditCard = assetExpLoanType[i].chargeCreditCard;
            };
            break;
        }
    }

    if (itemDesc == "" || (description != undefined && description != "")){
        itemDesc = description
    }

    let itemAmount = amount;
    if (calcInflationAmtSw != undefined && calcInflationAmtSw == true){
        let nbrMonths = calcNbrMonthsBtwnDates(startDate, gameDate);
        let nbrYears = (nbrMonths/12).toFixed(2);
        itemAmount = calcInflationAmt(amount, nbrYears);
    }

    let loanMonths = 0;
    let intRate = 0;
    if (itemType == "loan"){
        switch (shortName){
            case "mortgage":
                loanTerm = +(ConfigFile.homeItems.mortgageLoanTerm) 
                loanMonths = loanTerm * 12;
                intRate = +(ConfigFile.homeItems.mortgageInterest);
                break;
            case "car":
                loanTerm = +(ConfigFile.carItems.carLoanTerm) 
                loanMonths = loanTerm * 12;
                intRate = +(ConfigFile.carItems.carInterest);
            case "college":
                loanTerm = +(ConfigFile.collegeItems.collegeLoanTerm)
                loanMonths = loanTerm * 12;
                intRate = +(ConfigFile.collegeItems.collegeInterest);
        }
    }

    switch (itemType){
         case "asset":
            newTableObj = {assetItem: itemName, assetDesc: itemDesc, assetAmt: itemAmount,
                assetStartDate: startDate, assetNbrMonths: "-1"}
            break;
         case "expense":
            newTableObj = {expItem: itemName, expDesc: itemDesc, expAmount: itemAmount,
                expStartDate: startDate, expNbrMonths: "-1", chargeCreditCard: chargeCreditCard};
            break;
         case "loan":
            // when item type is "loan", the amount is the total loan amount (not a monthly amount)
            let loanAmt = monthlyLoanAmt(itemAmount, intRate, loanTerm);
            newTableObj = {loanType: itemName, loanDesc: itemDesc, loanAmt: loanAmt,
                loanStartDate: startDate, loanNbrMonths: loanMonths, loanTotAmt: itemAmount, 
                loanIntRate: intRate, loanTerm: loanTerm};
            break;
    }

    return newTableObj;
      
 }

