import {Component} from 'react';
import {Button, Modal, ModalFooter, ModalHeader, ModalBody} from "reactstrap";
import Draggable from 'react-draggable';


class AlertTextPopup extends Component {
            constructor(props) {
              super(props);
              this.state={
                hintTable: [],
              }
    }

    buildHintTable(){
        this.state.hintTable = [];
        for (let i=0; i<this.props.hintNbr; i++){
            this.state.hintTable.push(this.props.alertText[i]);
        }
    }
        
    render() {
        this.buildHintTable();
        this.alertText = this.state.hintTable.map((hint,key) => <li key={key}>{hint}</li>);

    return <>    
        <Draggable>
            <Modal isOpen={this.props.show} size="lg" >
                <ModalHeader className="alert">
                    <span >
                       {this.props.title}
                        <Button color="success" className="display-btn-right" onClick={() => this.props.closeAlert()} >
                            Close
                        </Button>
                    </span> 
                </ModalHeader>
                <ModalBody>
                    
                    <div className="alert">
                        {this.alertText}
                    </div>
                    <div className="text-center font-weight-bold">
                        You can drag this box out of the way in order to read the underlying text.
                    </div>
                </ModalBody>
            </Modal>
        </Draggable>
        </>
    }
}

export default AlertTextPopup;