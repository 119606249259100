const ChoosingCareerChallenge1Dataset = {
     /*  spaces between content in items ensures that content is lined up correctly on the modal
        the column property is used to indicate the correct answer.   */
        items: {
           
            "item-1": { id: "item-1", content: "Sam works hard to be on time for appointments. " +
                    "He enjoys time alone. He is good at getting around in large cities and  " +
                    "has excellent hand-eye coordination.", 
                    answer: "column-4" },
            "item-2": { id: "item-2", content: "Kathy is good at understanding other people's " +
                    "situation and likes to help people when possible. " +
                    "She runs over 10 miles each week. She enjoys reading mystery novels and  " +
                    "trying to figure out who did the crime before the end of the book.  ", 
                    answer: "column-2"},
            "item-3": { id: "item-3", content: "Pam likes math and science.  She enjoys taking things " +
                    "apart and using the parts to make new things.  She prefers to work alone but can " +
                    "work as a team member when needed", 
                    answer: "column-5" },
            "item-4": { id: "item-4", content: "Stan does not like to do paperwork.  He is good at following " +
                    "detailed instructions.  He likes putting together large Lego models. " +
                    "He is good at juggling multiple things and moving quickly from one task to the next.",
                    answer: "column-3" },
           
        },
        columns: {
            "column-1": { id: "column-1", title: "Job Placement Individuals", 
                itemIds: ['item-1', 'item-2', 'item-3', "item-4"] },
            "column-2": { id: "column-2", title: "Police Officer: people skills, " +
                "ability to ‘read people’, physical strength, empathy", itemIds: [] },
            "column-3": { id: "column-3", title: "Chef: dexterity, multi-tasking, " +
                "detail oriented, organized, physical stamina", itemIds: [] },
            "column-4": { id: "column-4", title: "Truck driver: time management, dexterity/coordination, " +
                "verbal communication ",  itemIds: [] },
            "column-5": { id: "column-5", title: "Electrical Engineer: problem solving, math skills, " +
                "multi-tasking, detail oriented", itemIds: [] },
                  
        },
        columnOrder: ["column-1", "column-2", "column-3", "column-4", "column-5"]
    }

export default ChoosingCareerChallenge1Dataset