import {Component} from 'react';
import {standardNumberFormat} from '../../objects/CommonUse/double_functions';



class FourColumnDisplayCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, 
            selected: false
        };
    }


    render() {
         
    return <>
        <tr className="">
            <th className="text-left">{this.props.tableInfo.column1}</th>
            <th className="text-right p-0">{standardNumberFormat(this.props.tableInfo.column2)}</th>
            <th className="text-right p-0">{standardNumberFormat(this.props.tableInfo.column3)}</th>
            <th className="text-right p-0">{standardNumberFormat(this.props.tableInfo.column4)}</th>
        </tr>

    </>
    }
}

export default FourColumnDisplayCard;