import {Component} from 'react';
import { withRouter } from "react-router";
import Button from 'react-bootstrap/Button';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import FunctionListDataset from './datasetsLambda/function_list';
import initializeErrorArray from './objects/initialize_error_array';
import buildUrl from '../../objects/CommonUse/build_URL';
//import {getCurrentDateTime} from '../../objects/CommonUse/get_current_date_time';
import Config from '../../config';
import buildLambdaTestData from './objects/build_lambda_test_data';


class AdminMain extends Component {
    constructor(props) { 
        super(props);
        this.adminInfo = this.props.adminInfo();
        this.state = {
            userName: "",
            modalOpen: true,
            configurationAvailable:  null,
            headers: setHeadersForFetch(),
            functionList: FunctionListDataset,
            displayData: false,
            environment: Config.dataURL.currentEnv,
            subProcess: "adminMain"        
        };   
        this.errArray = initializeErrorArray(3);
}
       
    render() {
        
  
    return <>
        <div className="col-sm-6 mb-5 input-page-base-card">
        <div className="card mt-3 mr-5 rounded-corners-lg height-max">
            <h1 className="mt-2 p-0 text-center">Game Administration</h1>
            <h4 className="text-center">Main Page</h4>
                <span className="mr-5 ml-5 mt-2">
                  <h5 className='text-large text-center'>Test a Function</h5>
                  <div >
                    <label className="font-weight-bold" htmlFor="funcName">
                        Function Name:&nbsp;&nbsp;</label>
                    <input type="text" className="col-med" id="funcName" name="funcName"></input> 
                    <span className={this.errArray[0].textType}>
                          &nbsp;{this.errArray[0].message}</span>
                </div>
                <div >
                   <label className="font-weight-bold" htmlFor="testCase">
                        Test Case:&nbsp;&nbsp;</label>
                    <input type="text" className="col-sm-1" id="testCase" name="testCase"></input> 
                    <span className={this.errArray[0].textType}>
                          &nbsp;{this.errArray[1].message}
                          <b>Nbr of Test Cases for this function: &nbsp;</b>{this.nbrTestCases}</span>
                   
                </div>
                <div className="mt-2">
                  <label className="font-weight-bold" htmlFor="validToken">Condition Connector:&nbsp;&nbsp;
                   </label>
                  <select 
                    className="btn btn-sm btn-outline-secondary dropdown-toggle" id="validToken">
                        <option value="true">yes token s/b valid</option>
                        <option value="false">set token to invalid</option>
                   </select>
                   <span className={this.errArray[2].textType}>
                          &nbsp;{this.errArray[2].message}</span>
                </div>
                <Button variant="primary"  onClick={() => this.editInput()}>
                    Submit
                </Button>
                {this.state.displayData  ?
                    <div>
                        <div className="font-weight-bold"> Response from Function:  </div>
                        <div><b>Status is:</b>  {this.status} </div>
                        <div><b>Nbr Records:</b> {this.nbrRecords}</div>
                        <div><b>Body is:</b>  {this.body} </div>
                    </div>
                :
                    null
                }
                </span>
            </div>    
       </div>
    </>
    }

    
    editInput(){
        this.setState({displayData: false});
        this.dataValidSw = true;
        this.errArray = initializeErrorArray(3);
        this.getFormValues();
        this.editFormValues();
        if (this.dataValidSw === true){
            this.getTestRecord();
        }
    }

    getFormValues(){
        this.funcName = document.getElementById("funcName").value;
        this.testCase = document.getElementById("testCase").value;
        this.validToken = document.getElementById("validToken").value;
    }

    editFormValues(){
        const functionObj = this.state.functionList.find(elem => elem.funcName === this.funcName);
        if (functionObj === undefined){
            this.errorMsg = "Function Name is not valid.";
            this.checkErrorArray(0);
        }else{
            const testObj = functionObj.testCases.find(elem => elem.testNbr === this.testCase);
            if (testObj === undefined){
                this.errorMsg = "Test Case is not valid for this function";
                this.checkErrorArray(1);
            }else{
                this.nbrTestCases = functionObj.testCases.length;
            }
        }
    }
      
    checkErrorArray(msgIndex){
        if (this.errorMsg !== undefined && this.errorMsg !== ""){
            this.errArray[msgIndex] = {textType: "text-danger", 
                message: this.errorMsg};
            this.dataValidSw = false;
        }
    }

    async getTestRecord()
    {
     //   let currentDateTime = getCurrentDateTime();
     //   let currentTime = currentDateTime.time;
        this.setHeaders();  
        let testData = buildLambdaTestData(this.funcName, this.state.functionList, this.testCase);
        this.urlParm = testData.urlParm;
        console.log("function is: ", this.funcName);
        console.log("urlParm is: ", this.urlParm);
        this.body = testData.testBody;
        this.method = testData.method;
      //  this.setTestParameters();
        const uri = buildUrl(this.state.environment, this.funcName, this.urlParm);
        this.setInputObject();
        console.log("uri is: ", uri);
        fetch (uri,  this.inputObject )             
    //    fetch (uri,
    //        {method: "POST",
    //            headers: this.headers,
    //            body: JSON.stringify(this.body),
      //          mode: "no-cors"
     //       })
            .then(response => {
       //         let currentDateTime = getCurrentDateTime();
       //         let currentTime = currentDateTime.time;
                if (response.ok)
                {
                    response.json().then(data => {
                        console.log("data from find: ", data);
                        this.output = JSON.parse(data);
                        this.status = this.output.statusCode;
                        if (this.status === 200){
                            this.body=this.output.body;
                            if (this.body.length >= 1){
                                this.nbrRecords = this.body.length;
                                this.body=JSON.stringify(this.body[0]);
                            }else{
                                this.nbrRecords = 0;
                                this.body = JSON.stringify(this.output.body); 
                            }
                        }else{
                            this.body = this.output.body.message;
                        }
                        console.log("output from fetch is: ", this.output);
                        console.log("body from output is: ", this.output.body);
                        this.setState({displayData: true});
                        // this returns the object that is in the 'body' of the lambda function return
                    });
                }
            });
            
    }

    setHeaders(){
        let token = "";
        if (this.validToken === "true"){
            if (this.funcName === "loginChk"){
                let headers2 = new Headers();
                headers2.set("Authorization", "Basic " + Buffer.from("student4:Test1234%").toString("base64"));
                this.headers = headers2;
            }else{
                this.headers = this.state.headers;
            }

        }else{
            token = JSON.stringify({iv: "bd2687b132600fd44bfc1b21dab25b57",
                content: "ad0b8d4fc92f6417a90bbd12d33c5728f5e75c4bb5146b5358ece8e64e223a4aa09914"});
            this.headers= {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                };
        }
    }

    setInputObject(){
         switch (this.method){
            case "GET":
                this.inputObject = {method:'GET', headers: this.headers};
                break;
            case "PUT":
                this.inputObject = {method: 'PUT', headers: this.headers, body: this.body};
                break;
            case "POST":
                this.inputObject = {method: 'POST', headers: this.headers, body: this.body};
                break;
            case 'DELETE':
                this.inputObject = {method: "DELETE", headers: this.headers};
                break;
            default:
                break;
         }
         console.log("this inputObject is: ", this.inputObject);
    }
      

}

export default withRouter (AdminMain);