import assetExpLoanShortnameDataset from "../../datasets/asset_exp_loan__shortname_dataset";


export const addChangeSalaryAsset = (assetTable, shortName, salaryStartDate, salaryIncrease,
      descAddLit) => {
   let totalSalary = salaryIncrease;
   let shortNameObj = assetExpLoanShortnameDataset.find(elem => elem.shortName === shortName);
   let bonusIndx = -1;
   for (let i=0; i<assetTable.length; i++){
      if (assetTable[i].assetNbrMonths != 0 ){
         if (assetTable[i].assetItem == shortNameObj.fullName){
             bonusIndx = i;
         }
         totalSalary = +totalSalary + +assetTable[i].assetAmt;
      }
   }
   if (bonusIndx == -1){
       let assetObj = {assetItem: shortNameObj.fullName, 
           assetDesc: shortNameObj.desc + descAddLit, 
           assetAmt: salaryIncrease, assetStartDate: salaryStartDate, assetNbrMonths: -1};
       assetTable.push(assetObj);
   }else{
      assetTable[bonusIndx].assetDesc +=  ", " + descAddLit;
      let newSalaryAmt =  +assetTable[bonusIndx].assetAmt +  +salaryIncrease;
      assetTable[bonusIndx].assetAmt = newSalaryAmt.toString();
   }
   
   let returnObj = {assetTable: assetTable, totalSalary: totalSalary}; 
   return returnObj;
   
}
