const financialAdvisorList =[
  {name: "Fred", imageURL: "finAdvisor1.png", introText:  "Hello!  My name is Fred, I am happy to give you some advice " +
    "so that you can do better the next time you play the game."},
  {name: "Janice", imageURL: "finAdvisor2.png", introText: "Hey!  I'm Janice, I've been reviewing your monthly status " +
    "I have some ideas that might help the next time you play the game!"},
  {name: "Sam", imageURL: "finAdvisor3.png", introText: "Howdy!  I'm Sam.  I see you brought a spreadsheet of your " +
    "monthly finances.  Let's have a look. Oh yes, I see several things that will help you do better the next time " +
    "you play the game."},
  {name: "Premjith", imageURL: "finAdvisor4.png", introText: "I'm Premjith but you can call me Prem.  I'm so happy to " +
     " to help with your finances.  I'm sure I can help you improve your score the next time that you play the game."},
  {name: "Cathy", imageURL: "finAdvisor5.png", introText: "Welcome to my office.  Let me take a look at your monthly " +  
   "status and see what I can suggest to improve your game. I'm Cathy by the way."},
  {name: "Kim", imageURL: "finAdvisor6.png", introText: "Well hello there!  Kim is my name.  I have a masters degree " +
    "in Finance and I can certainly give you some ideas of ways to improve the score in your game for the next time " +
    "I mean."},
  {name: "Thomas", imageURL: "finAdvisor7.png", introText: "Hi!  Glad you came in.  My name is Thomas, I have several ideas " +
    "that will help you do better the next time you play the game. " },
  {name: "James", imageURL: "finAdvisor8.png", introText: "You've come to the right person.  I am really good at helping " +
    "people meet their financial goals.  You do have financial goals, right?  Let's see what you can do to improve " +
    "your score the next time you play the game."
  }
]

export default financialAdvisorList;