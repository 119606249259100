import {Component} from 'react';
import {doubleToStringMonthly} from '../../objects/CommonUse/double_functions';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import {rollForwardDate} from './objects/roll_forward_date_function';

class TwoColumnTableCard extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, selected: false};
    }

    reformatDatesAmounts(){
        switch (this.props.infoType){
        case "date-amount":
            this.reformatStartDate = reformatDateToDisplay(this.props.outInfo.loanStartDate);
            this.endDate = rollForwardDate(this.props.outInfo.loanStartDate, this.props.outInfo.loanNbrMonths);
            this.reformatEndDate = reformatDateToDisplay(this.endDate);
            this.outField1 = this.reformatStartDate + "-" + this.reformatEndDate;
            if (this.props.outInfo.amount == undefined){
                this.outField2 = this.props.outInfo.loanAmt;
            }else{
                this.outField2 = this.props.outInfo.amount;
            }
            break;
        case "label-date":
            this.reformatEndDate = reformatDateToDisplay(this.props.outInfo.loanEndDate);
            this.outField1 = this.props.outInfo.label;
            this.outField2 = this.reformatEndDate;
            break;
        case "label-amount":
            this.outField1 = this.props.outInfo.label;
            this.outField2 = doubleToStringMonthly(this.props.outInfo.amount);
              break;
        case "label-number":
            this.outField1 = this.props.outInfo.label;
            this.outField2 = this.props.outInfo.number;
              break;
        default: 
            break;
      }
    }

    determineTextClass(){        
        this.className1 = "text-left p-0";
        if (this.props.outInfo.class !== undefined){
            this.className1 = this.className1 + this.props.outInfo.class;
        }
        this.className2 = "p-0 text-right" ;

        if (this.props.className !== undefined){
          this.className2 =  this.props.className;
        } 
        if (this.props.outInfo.bold === true){
            this.className1 = this.className1 + " font-weight-bold ";
            this.className2 = this.className2 + " font-weight-bold";
        }
        if (this.props.outInfo.color === "red"){
            this.className2 = this.className2 + " text-danger";  
        }
    }

    render() {
        this.reformatDatesAmounts();
        this.determineTextClass();
                     
    return <>
        <tr>
              <td className={this.className1}>{this.outField1}</td>
              <td className={this.className2}>{this.outField2}</td>
        </tr>
           
    </>
    }
}

export default TwoColumnTableCard;