const LoanChallenge1Dataset = {
    /*  spaces between content in items ensures that content is lined up correctly on the modal
        the column property is used to indicate the correct answer.   */
    items: {
        "item-1": { id: "item-1", content: "Amount: $100,000  Int Rate: 8%       Term: 30 years", 
                answer: "column-5", result: {colHeading: "Overpay Amount", value: [264153.60]} },
        "item-2": { id: "item-2", content: "Amount: $2000        Int Rate: 3%       Term: 10 years", 
                answer: "column-2", result: {colHeading: "Monthly Amount", value: [19.31]} },
        "item-3": { id: "item-3", content: "Amount: $100,000  Int Rate: 8%.      Term: 10 years",
                answer: "column-3", result: {colHeading: "Monthly Amount", value: [1213.28]} },
        "item-4": { id: "item-4", content: "Amount: $2000        Int Rate: 3%       Term:   2 years", 
                answer: "column-4", result: {colHeading: "Overpay Amount", value: [2063.04]} },
       
    },
    columns: {
        "column-1": { id: "column-1", title: "Loan Factors", 
            itemIds: ['item-1', 'item-2', 'item-3', "item-4"] },
        "column-2": { id: "column-2", title: "Lowest Monthly    Payment", itemIds: [] },
        "column-3": { id: "column-3", title: "Highest Monthly    Payment", itemIds: [] },
        "column-4": { id: "column-4", title: "Overpay Amount Is Smallest", itemIds: [] },
        "column-5": { id: "column-5", title: "Overpay Amount is Greatest", itemIds: [] },
              
    },
    columnOrder: ["column-1", "column-2", "column-3", "column-4", "column-5"]
}

export default LoanChallenge1Dataset