import {Component} from 'react';
import { withRouter } from "react-router";
import { setScreenSizeParameters } from '../../objects/CommonUse/set_screen_size_parameters';

class ForParentsMain extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            openDiscussionTopicsPDF: false,
                     
        }
    }
    
    componentDidMount(){
        sessionStorage.removeItem("GameViewSubProcess");
    }

    openDiscussionTopics(){
        this.setState({openDiscussionTopicsPDF: true});
    }
 
    render() {
        let screenParms = setScreenSizeParameters(this.props.screenSize, "forParents");
        this.className = screenParms.columnClass;
                   
    return <>
        <div className={this.className}>
        <div className="card mt-3 rounded-corners-lg height-max">
            <button className="pages-back-button ml-4 mt-4" 
                onClick ={()=> this.props.goBack()}>
                                Back
            </button>
            <h3 className="mt-0 p-0 text-center">For Parents and Teachers</h3>
            <h5 className="text-center">Main Page</h5>
                            
            <div className="overflow-scroll mx-4 mb-5">
                   
                <h6 className="text-center ">Welcome to the "Money Train Game"!!</h6> 
                <h6 className="mt-2 font-weight-bold">Review Game Status</h6>
                <p className={this.textClass}>     
                    This game is intended to teach students (10 years old and up) about money management. 
                     Click the "Review Related Players" button to check 
                    the list of players related to your account.  In addition to listing the players 
                    related to your account, this will also indicate which players have an active game.  
                    The Parents/Teachers area of the Money Train application provides lots of resources
                    that you can use to supplement and reinforce the concepts that players learn when they
                    play the Money Train Game.
                </p>
                <h6 className="font-weight-bold mt-2">Extra Credit</h6>
                <p className={this.textClass}>    
                    You may want to try out the
                    challenges in the "Extra Credit" section on this site.  You can navigate to
                    extra credit by clicking on the link below (or clicking on the "Extra Credit" 
                    square that is on the main menu). The extra credit challenges are fun and may 
                    provide some ideas for
                    interesting follow-up discussions with your child(ren) or student(s).
                    <span
                           className="decorative light-blue-color ml-4 text-pointer "
                              onClick={() => this.goToLink()}><u>Link to Extra Credit</u>
                    </span>
                </p>
                <h6 className="font-weight-bold mt-2">Video Presentations</h6>
                <p>Money management concepts that are presented in the game can be reinforced with follow-up 
                    discussions on key topics.  Several videos have been developed which are 
                    good supplements for the game.  These can be accessed (viewed) by you or by your 
                    children/students by clicking on the
                    "Video Presentations" button that is to the right.  
                </p>

                <h6 className="font-weight-bold mt-2">Player Reports</h6>
                <p>As a parent you can check-out the status of your child's progress in the game by clicking
                    on the "Player Reports" button (on the right).  Teachers can use this same button
                    to review the status of student's games.  After your students/children have had a chance
                    to play the game a bit, we encourage you to review all of the reports and tools that 
                    are provided on the player reports page. A written (PDF) discussion guide is provided 
                    to give some additional guidance
                    about how the various reports can be used to engage players in discussion. 
                </p>
            </div>
        </div>    
       </div>
    </>
    }

    goToLink(){
        this.props.history.push('extra-credit-main'); 
   }
    
}

export default withRouter (ForParentsMain);