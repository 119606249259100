
export const removeClearPath = (gameBoard, goodPath) => {
    
    let startIndx = gameBoard.length - 1;
    for (let i=startIndx; i>= 0; i--){
          let cell = gameBoard[i];
          if (goodPath.includes(cell)){
              gameBoard.splice(i,1);
          }
    } 
    
    return gameBoard;
}

