import {Component} from 'react';
import {shuffleArray} from '../../objects/CommonUse/shuffleArray';
import calcRandomNumberWithMax from '../../objects/CommonUse/calc_random_number_with_max';
import ComicListDataset from './Datasets/comic_list_dataset';
import Button from 'react-bootstrap/Button';
import { displayFriendComment } from './objects/display_friend_comment';
import { determineFriendComment } from './objects/determine_friend_comment';


class ComedyClubModal extends Component {
  constructor(props) {
    super(props);
    this.state={
         displayPunchLine: false,
         activityStarted: false,
         buttonType: "start",
         commentNbr: 0,
        };    
        this.comicURL = "";
        this.jokeCount = 0;
        this.finishText = "";
        this.jokeIndx = 999;
        this.score = this.props.score;
        this.buttonDisabled = false;
        this.displayPicture = false;
        this.displayPunchLine = false;
        this.commentNbr = 0;
        this.comedyClubSetup();
  }

  comedyClubSetup(){
    let jokeList = this.props.jokeList.jokes;
    this.jokeList = shuffleArray(jokeList);
    let comicList = ComicListDataset;
    let maxNbr = comicList.length - 1;
    let comicIndx = calcRandomNumberWithMax(maxNbr);
    this.comicURL = "./assets/Activities/HaveFun/" + comicList[comicIndx];
  }
 
 newJoke(){
      if (this.props.stopActivity === true){ 
           this.finishText = "Time has runout for this activity.  Click the Finish button to return to the game."
          this.finishThisActivity(); 
      }else{
          this.selectJoke();
      }
 } 

 selectJoke(){
      this.jokeCount += 1;
      this.jokeIndx =  this.jokeIndx + 1;
      if ( this.jokeIndx > this.jokeList.length - 1){
            this.jokeIndx = 0;
      }
      this.startLine = this.jokeList[ this.jokeIndx].question;
      this.punchLine = this.jokeList[ this.jokeIndx].answer;
       this.buttonDisabled = true;
      this.displayPunchLine = false;
      this.props.startTimer();
      if ( this.jokeCount > 5){
          this.displayPicture = displayFriendComment(this.props.activityCategory, this.props.friendInfo);
          if (this.displayPicture === true){
             this.friendComment();
          }
      }else{
           this.displayPicture = false;
      }
      this.setState({activityStarted: true});
    }

    friendComment(){
       this.jokeCount = 0;
      let commentTable = this.props.friendInfo.commentTable;
      let commentObj = determineFriendComment(commentTable, this.commentNbr);
      this.comment = commentObj.comment;
      this.commentNbr = +commentObj.commentNbr;
    }

    updateDisplayPunchLine(){
      this.displayPunchLine = true;
       this.buttonDisabled = false;
    }
    
    finishThisActivity(){
      this.setScoreValues();
      this.props.onGameOver( this.score);
    }

    doneButtonClicked(){
       this.score.type = "done";
      this.setScoreValues();
      this.props.onGameOver( this.score);
    }

    setScoreValues(){
       this.score.type = "finish";
       this.score.nbrAttempted =  this.jokeCount;
       this.score.points = 100;
       this.score.gamesRemaining =  this.score.gamesRemaining -  this.jokeCount;
      if ( this.score.gamesRemaining < 0){
           this.score.gamesRemaining = 0;
      }
       this.score.maxScore = 100;
       this.score.newLevelEarned = false;
    }

 
  render() { 
    
  return <>
   <span>
        <div>{ this.finishText}</div>             
        <div className="row">
          <div className="col-md-4">
             {this.state.buttonType === "start" ?
                <Button className=" p-0 text-right" variant="primary"  disabled={ this.buttonDisabled} 
                    onClick={() => this.newJoke()}>
                        New Joke   
                </Button>
              :
              <span>
                <Button className="p-0 text-right" variant="primary" 
                    onClick={() => this.finishThisActivity()}>
                            Finish
                </Button>
              </span>
            }
          </div>
          <div className="col-md-2">
              <Button className="p-0 text-right" variant="primary" 
                  onClick={() => this.doneButtonClicked()}>
                    Done
              </Button>
          </div>
         
        </div>
        <div className="comedy_club_background mt-2">
        
            {this.state.activityStarted ?
                <span>
                    <div className="m-4 speech-comedy-club-activity right text-center use-font-comic-sans">
                        <div>{this.startLine}</div>               
                   
                    
                    {this.displayPunchLine ?
                      <div className="m-4  text-center use-font-comic-sans">
                        {this.punchLine}
                      </div>
                    :
                      <div className="text-center">
                        <Button className="m-4 " size="sm" variant="primary" 
                            onClick={() => this.updateDisplayPunchLine()}>
                              Punch Line
                        </Button>
                      </div>
                    }
                    </div>
                </span>
            :
              <div className="mt-4 mx-4 text-white">Click the "New Joke" button to get started.  
                  Then click on the punch line button to finish the joke.</div>
            }

            <div>
                <img src={this.comicURL} className="comic-position-img" alt="comicPicture"/>
            </div>

        
            {this.displayPicture   ?
                  <span>
                      <div>
                          <img src={this.props.friendInfo.friendImgURL}  className="friend-puzzle-position-img" 
                              alt="friendPicture"/>
                      </div>  
                      <div className="speech-puzzle-activity right" >
                          {this.comment}
                      </div>
                  </span>
                :
                    null
                }
           </div>
      </span>
    </>
  }

 
}
export default ComedyClubModal;