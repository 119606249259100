export const monthlyLoanAmt = (baseAmt, interest, loanTerm) => {
   /*  A = P (r (1+r)^n) / ( (1+r)^n -1 )
        When you plug in your numbers, it would shake out as this:
        P = $10,000
        r = 7.5% per year / 12 months = 0.625% per period (0.00625 on your calculator)
        n = 5 years x 12 months = 60 total periods  */
        
        var loanAmtUnrounded = 0;
        if (baseAmt !== undefined)
        {    let monthlyInterest = parseFloat(interest) / 12;
             let nbrMonths = parseFloat(loanTerm) * 12;
             let interimCalc = Math.pow((1 + monthlyInterest), nbrMonths);
             let numerator = monthlyInterest * interimCalc;
             let denominator = interimCalc - 1;
             loanAmtUnrounded = parseFloat(baseAmt) * (numerator / denominator);
        }
        var loanAmtRounded = loanAmtUnrounded.toFixed(2);
        return loanAmtRounded;
 }




