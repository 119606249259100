const updateCashMoodFriendData = {
    cash: "777777.77",
    moodPoints: "777777",
    friendList: [
        {
          "name": "Charlie",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Cameron",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "Jennifer",
          "introDate": "",
          "dialogIndx": "firstTime",
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        },
        {
          "name": "TestCashMoodFriend",
          "introDate": "2022-10",
          "dialogIndx": 0,
          "rltnshpScore": "0",
          "totalLoanAmt": "0"
        }
      ],
    moodPointHistory: {
        "currentMonthMoodPoints": 777,
        "totalMoodPoints": 77777,
        "moodPointMonths": 1
    }, 
    iconTable:[
        {
          "iconName": "gameBasicHelp2",
          "iconCategory": "information",
          "className": "small_sign_thumbnail_img information-sign-position",
          "imageSrc": "gameHelp.png",
          "validInd": true,
          "firstTime": false,
          "additionalInfo": "",
          "dateAdded": "2023/07/27"
        },
        {
          "iconName": "funStuffNewLevel",
          "iconCategory": "goodNews",
          "className": "small_sign_thumbnail_img good-news-funStuff-position",
          "imageSrc": "FunStuff.jpg",
          "validInd": true,
          "firstTime": false
        },
        {
          "iconName": "testCashMoodEndPoints",
          "iconCategory": "goodNews",
          "className": "small_sign_thumbnail_img good-news-funStuff-position",
          "imageSrc": "FunStuff.jpg",
          "validInd": true,
          "firstTime": false
        }
      ] 

}

export default updateCashMoodFriendData;