import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import "./Admin/admin.css";
import Button from 'react-bootstrap/Button';
import MoneyMgmtQuestionCard from './money_mgmt_question_card';
import removeDupsFromMoneyMgmtTest from './objects/remove_dups_from_money_mgmt_test';

class MoneyMgmtTestModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      
      }    
    this.prepareMoneyMgmtTestReport();
}

prepareMoneyMgmtTestReport(){
    this.newTestData =  removeDupsFromMoneyMgmtTest(this.props.moneyMgmtTestArr) //  hopefully this will not be required in the future!!!
    this.summarizeResultsByQuestion();
}

summarizeResultsByQuestion(){
    this.questionArr = [];
    for (let i=0; i<this.newTestData.length; i++){
        var questionAnswerArr = this.newTestData[i].questionAnswerArr;
        questionAnswerArr = questionAnswerArr.sort((a, b) => (a.questionNbr > b.questionNbr) ? 1 : -1);
        for (let j=0; j<questionAnswerArr.length; j++){
            this.addToNbrCorrect(questionAnswerArr[j]);
        }
    }
}

addToNbrCorrect(questionAnswerObj){
     let questionFound = false;
     let questionIndx = 999;
     for (let i=0; i<this.questionArr.length; i++){
        if (this.questionArr[i].questionNbr === questionAnswerObj.questionNbr){
            questionFound = true;
            questionIndx = i;
            break;
        }
     }
     if (questionFound === true){
        if (questionAnswerObj.correct > 0){
          //  the 'correct' field indicates the pct of the question that was correct (partial credit given
          //  for multiple choice questions)
          this.questionArr[questionIndx].nbrCorrect += +questionAnswerObj.correct;
        }
     }else{
        const questionObj = {questionNbr: questionAnswerObj.questionNbr, questionText: questionAnswerObj.text,
          nbrCorrect: 0};
        if (questionAnswerObj.correct === true){
           questionObj.nbrCorrect = 1;
        }
        this.questionArr.push(questionObj);
    }
}
   
render() {
        this.moneyMgmtQuestions = this.questionArr.map((questionInfo, index) =>
          <MoneyMgmtQuestionCard questionInfo={questionInfo}  key={index} 
            totalNbrTests={this.newTestData.length} />
              );
              
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="lg" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Money Managment Test Summary - Total Number of Tests: &nbsp;
                {this.newTestData.length}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
              <span>
              <table className="table m-0">
                <tbody>
                  <tr className="header-sticky">
                    <th>Question Nbr</th>
                    <th>Question Text</th>
                    <th>Nbr Correct</th>
                    <th>Pct Students Correct</th>
                  </tr>
                  {this.moneyMgmtQuestions}
                </tbody>
              </table>
                             
              </span>
                  
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
             
              <Button variant="primary"  onClick={() => this.props.onClose()}>
                   Close
              </Button>
              
        </Modal.Footer>
       
    </Modal>
    
  </>
  }
}

export default MoneyMgmtTestModal;