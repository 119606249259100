const SavingMoneyChallenge2HintsText =
    ["There are 4 items in the advice column that would be considered " +
        "good advice.",
     "Paying off credit card debt before investing is usually a good idea because " +
        "the interest penalty charged for credit card debt is often greater than " +
        "the interest that can be earned in a typical investment.",
     "It is a good idea to start saving money when you are young, because it will " + 
        "have a longer time time to gain interest.  In the end this will result in much " +
        "higher savings then if you wait to start adding money to a savings account.",
      "Even though high-risk investments often have a possibility of earning a larger " +
        "return on your money, it is also quite possible that the money invested is " +
        "simply lost.  For this reason, it is generally not a good idea to invest " +
        "money in a high-risk option unless it is money that you can afford to lose." 
    ]
   

export default SavingMoneyChallenge2HintsText;