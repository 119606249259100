const SavingMoneyChallenge1Dataset = {
     /*  spaces between content in items ensures that content is lined up correctly on the modal
        the column property is used to indicate the correct answer.   */
        items: {
           
            "item-1": { id: "item-1", content: "Monthly: $400     Int Rate: 8%.      Term: 20 years",
                    answer: "column-4", result: {colHeading: "Next Highest Savings", value: [96000, 237178.89]} },
            "item-2": { id: "item-2", content: "Monthly: $2000     Int Rate: 8%       Term: 5 years", 
                    answer: "column-2", result: {colHeading: "Least Savings", value: [120000, 147933.40]} },
            "item-3": { id: "item-3", content: "Monthly: $200        Int Rate: 8%       Term:  30 years", 
                    answer: "column-5", result: {colHeading: "Highest Savings", value: [72000, 300059.04]} },
            "item-4": { id: "item-4", content: "Monthly: $1000        Int Rate: 8%       Term: 10 years", 
                    answer: "column-3", result: {colHeading: "Next Least Savings", value: [120000, 184165.68]} },
           
        },
        columns: {
            "column-1": { id: "column-1", title: "Savings Factors", 
                itemIds: ['item-1', 'item-2', 'item-3', "item-4"] },
            "column-2": { id: "column-2", title: "Least Total Savings", itemIds: [] },
            "column-3": { id: "column-3", title: "Next Least Total Savings", itemIds: [] },
            "column-4": { id: "column-4", title: "Nxt Highest Total Savings", itemIds: [] },
            "column-5": { id: "column-5", title: "Highest Total Savings", itemIds: [] },
                  
        },
        columnOrder: ["column-1", "column-2", "column-3", "column-4", "column-5"]
    }

export default SavingMoneyChallenge1Dataset