import {Component} from 'react';
import parseTextForDictionary from '../../objects/CommonUse/parse_text_for_dictionary';
import TestQuestionsAnswerCard from './test_question_answers_card';
import DictionaryModal from '../shared-components/dictionary_modal';

class TestQuestionAnswers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            render: false,
            jokeResponse: false,
        };
        this.openDictionary = false;
        this.answerArr = [];
        this.questionArr = [];
    }

    addDictionaryLinks(){
        this.questionAnswerArr = [];
        const questionArr = parseTextForDictionary(this.props.testQuestion.questionText);
        const questionObj = {textType: "question", textArr: questionArr, checked: false, answer: ""};
        this.questionAnswerArr.push(questionObj);
        let answerObj = "";
        for (let i=0; i<this.props.testQuestion.answerList.length; i++){
            const testAnswer = this.props.testQuestion.answerList[i].label;
            const answerArr = parseTextForDictionary(testAnswer);
            answerObj = {textType: "answer", textArr: answerArr, checked: this.props.testQuestion.answerList[i].checked,
                answer: this.props.testQuestion.answerList[i].answer};
            this.questionAnswerArr.push(answerObj);
        }
    }
    
    openDictionaryModal(dictionaryText){
        this.dictionaryText = dictionaryText;
        this.openDictionary = true;
    }
    
    closeDictionaryModal(){
        this.openDictionary = false;
        this.setState({render: true});
    }

    render() {
            this.addDictionaryLinks();
            this.answerList = this.questionAnswerArr.map((answerList, index) =>
                <TestQuestionsAnswerCard key={index} dataset={answerList} 
                    answerType={this.props.testQuestion.answerType}
                    screenSize={this.props.screenSize}
                    onSelect={(slctObj)=> this.props.onSelect(slctObj)}  
                    onClicked={(dictionaryText)=> this.openDictionaryModal(dictionaryText)}      
                />        
            );
       
    return <>
        <div className="">
                <div className="mx-4 mb-0">
                    {this.answerList}
                </div>
                {this.props.displayJoke  ?
                    <div className="mt-4 text-right">
                        {!this.props.jokeResponse ?
                            <button className="monsterSpeech right text-left display-on-top"
                                onClick={() => this.props.getJokeResponse()} > 
                                {this.props.jokeObj.joke} <u>(click here)</u>
                            </button>
                        :
                            <div className="monsterSpeech right text-left display-on-top"
                                onClick={() => this.props.getJokeQuestion()}> 
                                {this.props.jokeObj.response}
                            </div>
                        }
                            <img className="joke-monster" 
                                    src="./assets/testPictures/jokeMonster.png"  alt="jokeMonster"
                                        />
                    </div>
                :
                    null
                }
        </div>
          
        {this.openDictionary ?
            <DictionaryModal
                modalOpen={this.openDictionary}
                termName={this.dictionaryText}
                onClose={() => this.closeDictionaryModal()}
            />
        :
            null
        }
  
    </>
    }

}

export default TestQuestionAnswers;