import {Component} from 'react';
import CarInput from '../shared-components/car_input';
import { withRouter } from "react-router";
import CarChangeModal from '../game-views/car_change_modal';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import { removeConditionsTable } from './objects/remove_Conditions_Table_function';
import { setupConditionsUpdate } from './objects/set_up_conditions_update_function';
import { resetPicturesForChanges } from './objects/reset_pictureInfo_for_changes';
import {setEventTrackerObj} from '../../objects/CommonUse/set_event_tracker_obj';
import {getCurrentDateTime} from '../../objects/CommonUse/get_current_date_time';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';

class CarChange extends Component {
  
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            openCarChangeModal: false,
            headers: setHeadersForFetch(),
            conditionList: null,
            subProcess: "carChange",
            environment: Config.dataURL.currentEnv,
            previousCarName: this.gameInfo.pictureInfo.carName,
        }
        
    }    

    componentDidMount(){
        this.loadConditions();
    }
    
    loadConditions()
    {
        const funcName = "eventConditionsList";
        const urlParm = "?triggeredByList=monthly,carChange&conditionClass=car";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {method:'GET', headers: this.state.headers})
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    this.state.conditionList = output.body;
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message};
                    this.props.processError(errorObj);
                }
            });
        }
        });
    }

    render() {
    return <>
            {this.state.openCarChangeModal == true ?
                <CarChangeModal show={this.state.openCarChangeModal} 
                    onClose={() => this.closeCarChangeModal()} 
                    onConfirmCarChange={() => this.onConfirmCarChange()}
                    gameInfo = {() => this.props.gameInfo()}
                    setGameInfo = {(value, propertyName) => this.props.setGameInfo(value, propertyName)}
                    processError = {(errorObj) => this.props.processError(errorObj)}
                   />
            :
                <div></div>
            }
            <CarInput onSelected={() => this.onCarSelected()} 
                screenSize={this.props.screenSize}
                currentGameDate = {this.gameInfo.gameData.currentGameDate}
                startGameDate = {this.gameInfo.gameData.startGameDate}
                currentCarID = {this.gameInfo.gameData.carInfo.carID}
                processError = {(errorObj) => this.props.processError(errorObj)}
                />
    </>
    }

    onCarSelected() {
        this.state.openCarChangeModal = true;
    }
   
    closeCarChangeModal() {
        this.state.openCarChangeModal = false;
    }

    onConfirmCarChange() {
        this.state.openCarChangeModal = false;
        this.setBodyObj();
        this.updateConditionTable();
        this.updateCarInfo();
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        this.props.setGameInfo(0, "carChangeNbrMonths");
        this.updatePictureInfo();
        this.updateEventTracker();
        this.props.onChangeComplete();
        this.props.history.push('/status-display');
    }
    
    setBodyObj(){
        const newCashAmt = sessionStorage.getItem("changeCarNewCashAmt");
        this.chgCashAmt = (this.gameInfo.gameData.currentCash - newCashAmt) * -1;
        const newCarID = sessionStorage.getItem("carChoice");
        const carAgeNbr = sessionStorage.getItem("changeCarAgeNbr");
        const carYear = sessionStorage.getItem("changeCarYear");
        const carType = sessionStorage.getItem("carSize");
        const newExpenseObj = JSON.parse(sessionStorage.getItem("changeCarExpenseDataObjects"));
        const newLoanObj = JSON.parse(sessionStorage.getItem("changeCarLoanDataObjects"));
        this.gameInfo.gameData.currentCash = newCashAmt;
        this.gameInfo.gameData.carInfo = {carID: newCarID, carAge: carAgeNbr, carYear: carYear, carType: carType};
        this.gameInfo.gameData.loanTable = newLoanObj;
        this.gameInfo.gameData.expenseTable = newExpenseObj;
        let moodPointsIncrease = sessionStorage.getItem("carChangeMoodPoints");
        this.gameInfo.gameData.currentMoodPoints = 
            +this.gameInfo.gameData.currentMoodPoints + +moodPointsIncrease;
        this.bodyObj = {
            "cash": newCashAmt, 
            "carInfo": this.gameInfo.gameData.carInfo, 
            "loanTable": JSON.parse(JSON.stringify(newLoanObj)),
            "expenseTable": JSON.parse(JSON.stringify(newExpenseObj)),
            "conditionsTable": "",
            "currentMoodPoints": this.gameInfo.gameData.currentMoodPoints,
        };
    }
    
    updateConditionTable(){
        this.conditionsTable = removeConditionsTable(this.gameInfo.gameData.conditionsTable, "car");
        let conditionClasses = ["car"]
        let newCarConditionsTable = 
            setupConditionsUpdate(this.gameInfo.gameData, this.state.conditionList, conditionClasses);
        for (let i=0; i<newCarConditionsTable.length; i++){
            this.conditionsTable.push(newCarConditionsTable[i]);
        }
        this.gameInfo.gameData.conditionsTable = this.conditionsTable;
        this.bodyObj.conditionsTable = this.conditionsTable;
    }
         
    updateCarInfo()
    {  
        const funcName = "updateCarInfo";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
                {method:'PUT', headers: this.state.headers,
            body: JSON.stringify(this.bodyObj)})
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                            //  "update of car info was successful";
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                            this.props.processError(errorObj);
                        }
                    });
                };
            });
    }

    updatePictureInfo(){
        let carID = sessionStorage.getItem("carChoice");
        let carSizeName = sessionStorage.getItem("carSizeName");
        let carName = carSizeName + " - " + this.gameInfo.gameData.carInfo.carYear;
        let carDirectory = sessionStorage.getItem("carDirectory");
        let carPictureImg = './assets/' + carDirectory;
        this.gameInfo.pictureInfo = resetPicturesForChanges("car", this.gameInfo.pictureInfo,
            carID, carName, carPictureImg, "");
        this.props.setGameInfo(this.gameInfo.pictureInfo, 'pictureInfo');
    }

    updateEventTracker(){
        const carName = sessionStorage.getItem("carName");
        let response = {changeMade: "job", previous: this.state.previousCarName, new: carName};
        let eventObj = setEventTrackerObj(this.gameInfo.gameData, response, "Buy Different Car", 2000);
        this.eventArr=[eventObj];
        let currDateTime = getCurrentDateTime();
        let dataObject = {
            player: this.gameInfo.gameData.user,
            gameID: this.gameInfo.gameData._id,
            eventType: "ChangeGameChoices",
            date: currDateTime.date,
            time: currDateTime.time,
            process: "ChangeCar",
            events: this.eventArr,
        };
        const funcName = "saveEventTracker";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {
            method:'post',
            headers: this.state.headers,
            body: JSON.stringify(dataObject)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                        const output = JSON.parse(data);
                        const statusCode = output.statusCode;
                        if (statusCode == 200){
                           // update of event tracker was successful
                        }else{
                            const errorObj={function: funcName, subProcess: this.state.subProcess, 
                                status: statusCode, message: output.body.message, errorObject: dataObject};
                            this.props.processError(errorObj);
                        }
                    });
                };
            });
    }

    
    
}
export default withRouter(CarChange);