import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import editDate from '../../objects/CommonUse/edit_date';

class ActivityAnalysisParmInputModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      errorsFound: false,
    }  
    this.dataValidSw = true;  

}

getEditInput(){
    this.getFormValues();
    this.editFormFields();
}

getFormValues(){
    this.beginDate = document.getElementById("beginDate").value;
    this.endDate = document.getElementById("endDate").value;
}

editFormFields(){
    this.dataValidSw = true;
    this.errorMsg = "";
    if (this.beginDate !== "0000/00/00"){
        const checkDate = editDate(this.beginDate);
        if (checkDate.dateIsValid === false){
            this.errorMsg1  = "Begin Date must be 0000/00/00 or a valid date.";
            this.dataValidSw = false;
        }
    }
    if (this.endDate !== "9999/99/99"){
        const checkDate = editDate(this.endDate);
        if (checkDate.dateIsValid === false){
            this.errorMsg2  = "End Date must be 99999999 or a valid date.";
            this.dataValidSw = false;
        }
    }
    if (this.dataValidSw === true){
         const dateRangeObj = {beginDate: this.beginDate, endDate: this.endDate};
         this.props.onSubmit(dateRangeObj);
    }else{
        this.setState({errorsFound: true});
    }
   }
  

render() {
                               
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="md" scrollable="true" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Enter Parameters for the Analyze Activity Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
 
            <div className="text-left">
                  <p>Please complete the fields on this form and then click 
                     'Submit'.</p>
            </div>
            <div>
                <div>
                    <label className="font-weight-bold" htmlFor="beginDate">
                        Begin Date (YYYY/MM/DD format):&nbsp;&nbsp;</label>
                    <input type="text" className="input-sm" id="beginDate" name="beginDate"></input> 
                    <div className="text-danger">
                          &nbsp;{this.errorMsg1}</div>
                </div>
                <div>
                    <label className="mt-4 font-weight-bold" htmlFor="endDate"> 
                        End Date (YYYY/MM/DD format):&nbsp;&nbsp;&nbsp;</label>
                    <input type="text" className="input-sm" id="endDate" name="endDate"></input> 
                    <div className="text-danger">
                          &nbsp;{this.errorMsg2}</div>
                </div>
                    
            </div>
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
              {this.state.dataValidSw ?
                <div></div>
              :
                <div className="text-danger">{this.errorMsg }</div>
              }
                         
              <Button variant="primary"  onClick={() => this.getEditInput()}>
                   Submit
              </Button>
        </Modal.Footer>
    </Modal>
    
  </>
  }
}

export default ActivityAnalysisParmInputModal;