import {Component} from 'react';
import CarInput from '../shared-components/car_input';
import { withRouter } from "react-router";

class CarSelect extends Component {
  
    constructor(props) {
        super(props);
        props.onOpen();
        this.inputSelected = (inputID) => {
           
        };
                
        this.inputSelected(1);
    }    

    render() {
    
    return <>
            <CarInput 
                screenSize={this.props.screenSize}
                onOpen ={()=> this.inputSelected(1)} 
                onSelected={() => this.onCarSelected()} 
                processError = {(errorObj) => this.props.processError(errorObj)}/>
    </>
    }

    onCarSelected() {
        this.props.completed();
    }

}
export default withRouter(CarSelect);