import { createTableItem } from "../../../objects/AssetsExpenses/create_table_item";
import { calculateMortgageInsurance } from "./calculate_mortgage_insurance";

export const createNewLoanObj = (downPaymentAmt, gameDate, mortgageAmt, homeFullCost, 
            homeName, loanTable) => {
        const totLoanAmt = (+homeFullCost - +downPaymentAmt).toFixed(2);
        let loanObj = createTableItem("mortgage", homeName, 
                gameDate, totLoanAmt, 0, false);
        loanTable.push(loanObj);
        const newMortgageAmt = +loanObj.loanAmt;
        const loanIntRate = +loanObj.loanIntRate;
                  
        let downPaymentPct = 0;
        let PMIMonthly = 0;
        if (downPaymentAmt !== 0 && !isNaN(downPaymentAmt)){
            downPaymentPct = ((downPaymentAmt / +homeFullCost) * 100).toFixed(1);
            if (+downPaymentPct < 20){
                const PMIObj = calculateMortgageInsurance(loanIntRate, totLoanAmt, mortgageAmt,
                    gameDate, homeFullCost, homeName, loanTable);
                PMIMonthly = PMIObj.PMIMonthly;
        //      loanTable = PMIObj.loanTable;
            }
        }
        const returnObj = {totLoanAmt: totLoanAmt, newMortgageAmt: newMortgageAmt, 
            PMIMonthly: PMIMonthly, downPaymentPct: downPaymentPct, loanTable: loanTable};
        return returnObj;
}

export default createNewLoanObj;