const JokeListDatasetPhase1 = {jokes:
                [{question: "Why don't you give Elsa (from the 'Frozen' movie) a baloon filled with helium?", 
                  response: "Because she would just let it go."},
                 {question: "Why shouldn't you write with a broken pencil?",
                  response: "Because it's pointless."},
                 {question: "What's the difference between the bird flu and the swine flu?",
                  response: "One requires tweetment and the other an oinkment."},
                 {question: "Why do seaguls fly over the sea?",
                  response: "Because if they flew over a bay, they would be bagels."},
                 {question: "Why did the mushroom go to the party?",
                  response: "Because he was a fungi (fun-guy)."},
                 {question: "What do sea monsters eat?",
                  response: "Fish and ships."},
                 {question: "Why can't your nose be 12 inches long?",
                  response: "Because then it would be a foot."},
                 {question: "What did the ocean say to the shore?",
                  response: "Nothing, it just waved."},
                 {question: "Why did the golfer bring two pairs of pants?",
                  response: "In case he got a hole in one."},
                 {question: "What do you get when you drop a piano down a mine shaft?",
                  response: "A flat minor."},
                 {question: "Why did the Clydesdale give the pony a glass of water?",
                  response: "Because he was a little horse."},
                 {question: "What do you call a fish without eyes?",
                  response: "Fsh"},
                 {question: "What do you call an alligator detective?",
                  response: "An investi-gator."},
                 {question: "Why did the farmer win an award?",
                  response: "Because he was outstanding in his field."},
                 {question: "There are two muffins baking in the oven. One muffin says to the other, " + 
                  "Phew, is it getting hot in here or is it just me?", 
                  response: "The other muffin says, 'AAAAHHH!! A TALKING MUFFIN!'"}, 
                 {question: "What lights up a soccer stadium?",
                  response: "A soccer match."},
                 {question: "What's brown and sticky?",
                  response: "A stick."},
                 {question: "What did the policeman say to his bellybutton?",
                  response: "You're under a vest."},
                 {question: "Why do people say 'break a leg' when you go on stage?",
                  response: "Because every play has a cast."},
                 {question: "What do you call a pig that does karate?",
                  response: "A pork chop."},
                 {question: "What kind of ghost has the best hearing?",
                  response: "The eeriest."},
                 {question: "What kind of music do planets like?",
                  response: "Neptunes."},
                 {question: "Why do bees have sticky hair?",
                  response: "Because they use honeycombs."},
                 {question: "How do rabbits travel?",
                  response: "By hareplanes."},
                 {question: "What do you call fake spaghetti?",
                  response: "An im-pasta."},
                 {question: "How do you stop a bull from charging?",
                  response: "Cancel its credit card."},
                 {question: "What did the tomato say to the other tomato during a race?",
                  response: "Ketchup."},
                 {question: "What do you call a factory that sells good products?",
                  response: "A satisfactory."},
                 {question: "How did the barber win the race?",
                  response: "He knew a shortcut."},
                 {question: "What do cows most like to read?",
                  response: "Cattle-logs."}, 
                 {question: "What do you call a sleeping dinosaur?",
                  response: "A dino-snore."},
                 {question: "What do you call a pile of cats?",
                  response: "A meow-tain."},
                 {question: "What has four wheels and flies?", 
                  response: "A garbage truck."},
                 {question: "Why don’t they play poker in the jungle?",
                  response: "Too many cheetahs."},
                 {question: "Have you heard about the corduroy pillow? No? Really?",
                  response: "It’s making headlines!"},
                 {question: "Why are frogs are so happy?",
                  response: "They eat whatever bugs them."},
                 {question: "What did one hat say to the other?",
                  response: "You stay here. I’ll go on ahead."},
                 {question: "Why did the picture go to jail?",
                  response: "Because it was framed."},
                 {question: "Why did the robber jump in the shower?",
                  response: "He wanted to make a clean getaway."},
                 {question: "What do you call shoes made of banana peels?",
                  response: "Slippers."},
                 {question: "Why did the bicycle collapse?",
                  response: "It was two tired."},
                 {question: "What do you call a bear with no teeth?",
                  response: "A gummy bear."},
                 {question: "Did you hear about the man who got hit by the same bike every morning?",
                  response: "It was a vicious cycle."},
                 {question: "Why did the poor man stock up on yeast?",
                  response: "To make some dough."},
                 {question: "Why wouldn’t the sesame seed leave the casino?",
                  response: "He was on a roll."},
                 {question: "Why don’t eggs tell jokes?", 
                  response: "They’d crack each other up."},
                 {question: "Did you hear about the restaurant on the moon?",
                  response: "Great food, no atmosphere."},
                 {question: "Why did the invisible man turn down the job offer?",
                  response: "He couldn’t see himself doing it."}, 
                 {question: "What do lawyers wear to work?",
                  response: "Lawsuits."}, 
                 {question: "When is a door not a door?",
                  response: "When it’s ajar."},
                 {question: "I told my doctor that I broke my arm in two places.",
                  response: "He told me to stop going to those places."}, 
                 {question: "What’s the best way to burn 1000 calories?",
                  response: "Leave the pizza in the oven."}, 
                 {question: "What has more lives than a cat?",
                  response: "A frog, because it croaks every night."}, 
                 {question: "If a parsley farmer is sued...",
                  response: "can they garnish his wages?"}, 
                ]}
 
       
export default JokeListDatasetPhase1;