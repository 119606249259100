import {Component} from 'react';
import { reformatDateToDisplay } from '../../objects/CommonUse/reformatDateToDisplay_function';
import {standardNumberFormat} from '../../objects/CommonUse/double_functions';
import ConfigFile from '../../datasets/config_file';


class FourColumnTableCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, 
            selected: false
        };
    }


    render() {
        if (this.props.accountNbr == "game"){
              this.column2Data = reformatDateToDisplay(this.props.tableInfo.startGameDate);
        }else{
              let totalNbrMonths = ConfigFile.gameStartItems.totalNbrGameMonths;
              this.column2Data =  +totalNbrMonths - this.props.tableInfo.nbrGameMonths;
        }
        if (this.props.currentUser == this.props.tableInfo.user){
            this.rowClass = "myTable-row-highlight";
        }else{
            this.rowClass = "";
        }
                           
    return <>
        <tr className={this.rowClass}>
            <th className="p-0" scope="row">{this.props.tableInfo.user}</th>
            <th className="p-0 text-center" scope="row">
                                {this.column2Data}</th>
            <th className="text-right p-0">{standardNumberFormat(this.props.tableInfo.moodScore)}</th>
            <th className="text-right p-0">{standardNumberFormat(this.props.tableInfo.retirementScore)}</th>
            <th className="text-right p-0">{standardNumberFormat(this.props.tableInfo.combinedScore)}</th>
        </tr>

    </>
    }
}

export default FourColumnTableCard;