
export const findMatchingUnlockedFeaturesDialog = (parameters, dialogTable) => {
    let friendDialogTable = [];
    for (let i=0; i<dialogTable.length; i++){
         let checkDialogObj = dialogTable[i];   
         if (checkDialogObj.featureName == parameters.topic){
              friendDialogTable =  dialogTable[i].commentTable;   
              break; 
        }
    }
      
    return friendDialogTable;
   
}
export default findMatchingUnlockedFeaturesDialog;

