import { homeRentAmt } from "../../../objects/monthlyCalc/homeRentCalc";

export const createNewRentExpense = (currentHome, newHome, expenseTable) => {
    let newExpObj = homeRentAmt(newHome.FullRentAmt, newHome.Roommates,
        newHome.Bedrooms, newHome.HomeName, currentHome.GameDate);
    expenseTable.push(newExpObj);
    newHome.RentAmt = newExpObj.expAmount;
    newHome.RoommateRentAmt = +newHome.FullRentAmt - +newHome.RentAmt;
    return expenseTable;
    }

export default createNewRentExpense;