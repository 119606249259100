import {Component} from 'react';
import './GameViews/gamePhase2.css';
import SpinnerLegendCard from './spinner_legend_card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

class SpinnerLegend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayLegend: true,
        };
    }
    
    toggleLegend(){
        this.setState({displayLegend: !this.state.displayLegend});
    }

   
    render() {
        this.spinnerLegend = this.props.eventCategories.map((eventList, index) =>
            <SpinnerLegendCard key={index} legendInfo={eventList} 
                screenSize={this.props.screenSize}
                jobName={this.props.jobName}/>
        );
        if (this.props.screenSize == "narrow"){
              this.titleClass = "font-weight-bold small-text-size";
              this.legendClass = "legend-position-narrow legend-background-color";
        }else{
            if (this.props.screenSize == "medium"){
                this.titleClass = "font-weight-bold small-text-size";
                this.legendClass = "legend-position-medium legend-background-color";
            }else{
                this.titleClass = "font-weight-bold";
                this.legendClass = "legend-position legend-background-color";
            }        
        }
             
    return <>
       <span>
            {this.state.displayLegend ?
                <div className={this.legendClass}> 
                    <div className="small-text-size font-weight-bold">Number of Spins in Turn: &nbsp; {this.props.nbrSpins}</div>
                    <div className={this.titleClass}>Spinner Legend&nbsp;&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faCaretDown} 
                            onClick={() => this.toggleLegend()}/> </div>
                            <table>
                                <tbody>
                                    {this.spinnerLegend}
                                </tbody>
                            </table>
                    </div>
            :
                    <div className={this.legendClass}>
                        <div className="small-text-size font-weight-bold mx-2">Number of Spins in Turn: &nbsp;
                             {this.props.nbrSpins}</div>
                        <div className={this.titleClass}>Spinner Legend&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faCaretRight}
                                onClick={() => this.toggleLegend()} /> 
                        </div>
                    </div>
            }
        </span>
    </>
    }

}

export default SpinnerLegend;